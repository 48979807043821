import cx from 'classnames';
import {get, map, set, some, startsWith} from 'lodash';
import {observer} from 'mobx-react';
import {FC, useCallback, useMemo, useState} from 'react';
import {Icon, Step} from 'semantic-ui-react';

import {ConnectorsCanvas} from './ConnectorsCanvas';
import {getStageByName, processorCanRaiseAnomalies} from '../../../stageUtils';
import {ProcessorStep} from './ProcessorStep';
import {StageStep} from './StageStep';
import ProcessorModal from '../../ProcessorModal';
import {Probe, ProbeProcessorType, ProcessorDefinition} from '../../../types';
import {ProbeError, ProbeHighlights} from '../types';

export type FullProbeGraphProps = {
  actionInProgress: boolean;
  connectorsCanvasWidth: number;
  currentProcessor: ProbeProcessorType | null;
  currentStageName: string | null;
  editable: boolean;
  errors: ProbeError[];
  highlights: ProbeHighlights;
  highlightCurrentEntity: () => void;
  highlightProcessorAndRelatedStages: (processorName: string) => void;
  highlightStageAndRelatedProcessors: (stageName: string) => void;
  probe: Probe;
  processorDefinitionsByName: Record<string, ProcessorDefinition>;
  processorSpacing: number;
  processorStepHeight: number;
  setCurrentProcessorName: (processorName: string) => void;
  setCurrentStageName: (stageName: string) => void;
  stageStepHeight: number;
  warningCountByStageName: Record<string, number>;
}

export const FullProbeGraph: FC<FullProbeGraphProps> = observer(({
  probe, warningCountByStageName, currentStageName, currentProcessor,
  highlights, highlightProcessorAndRelatedStages, highlightStageAndRelatedProcessors, highlightCurrentEntity,
  setCurrentProcessorName, setCurrentStageName, editable, actionInProgress,
  connectorsCanvasWidth, processorStepHeight, stageStepHeight, processorSpacing,
  errors, processorDefinitionsByName
}) => {
  const [processorModalOpen, setProcessorModalOpen] = useState(false);

  const openProcessorModal = useCallback(
    () => setProcessorModalOpen(true),
    []
  );

  const closeProcessorModal = useCallback(
    () => setProcessorModalOpen(false),
    []
  );

  const processorsWithErrors = useMemo(() => {
    return errors.reduce((result, error) => {
      if ('processorName' in error && startsWith(error.type, 'processor')) {
        result[error.processorName!] = true;
      }
      return result;
    }, {});
  },
  [errors]
  );

  const processorStagesWithErrors = useMemo(() => {
    return errors.reduce((result, error) => {
      if (error.type === 'stageProperty') set(result, [error.processorName!, error.stageName!], true);
      return result;
    }, {});
  },
  [errors]
  );

  const hasConnections = some(probe.processors, (processor) => some(processor.inputs));

  return (
    <>
      <ConnectorsCanvas
        width={connectorsCanvasWidth}
        processorStepHeight={processorStepHeight}
        stageStepHeight={stageStepHeight}
        processorSpacing={processorSpacing}
        processors={probe.processors}
        highlights={highlights}
      />
      <div className='navigation-container' style={{paddingLeft: hasConnections ? connectorsCanvasWidth : 0}}>
        {probe.processors.map((processor, index) => {
          const processorDefinition = processorDefinitionsByName[processor.name];
          const hasWarnings = some(processor.outputs, (stageName) => !!warningCountByStageName[stageName]);
          const hasAnomalies = some(processor.outputs, (stageName) => {
            const {anomaly_count: anomalyCount} = getStageByName({probe, stageName});
            return anomalyCount > 0;
          });
          const hasProcessorError = get(probe, ['last_error', 'processor']) === processor.name ||
              processorsWithErrors[processor.name];
          return (
            <Step.Group
              key={processor.name}
              className={cx({
                'has-warnings': hasWarnings,
                'has-errors': hasAnomalies || hasProcessorError || !!processorStagesWithErrors[processor.name],
              })}
              style={{marginTop: index ? processorSpacing : 0}}
              vertical
              fluid
            >
              <ProcessorStep
                processorStepHeight={processorStepHeight}
                processor={processor}
                active={!currentStageName && currentProcessor && processor.name === currentProcessor.name}
                highlights={highlights}
                highlightProcessorAndRelatedStages={highlightProcessorAndRelatedStages}
                highlightCurrentEntity={highlightCurrentEntity}
                setCurrentProcessorName={setCurrentProcessorName}
                hasProcessorError={hasProcessorError}
              />
              {map(processorDefinition.outputs, (metadata, outputName) => {
                const stageName = processor.outputs[outputName];
                const stage = getStageByName({probe, stageName});
                return <StageStep
                  key={stageName}
                  stageStepHeight={stageStepHeight}
                  stageName={stageName}
                  active={stageName === currentStageName}
                  editable={editable}
                  highlights={highlights}
                  highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
                  highlightCurrentEntity={highlightCurrentEntity}
                  setCurrentStageName={setCurrentStageName}
                  anomalyCount={stage.anomaly_count}
                  warningCount={warningCountByStageName[stageName]}
                  hasStageError={!!get(processorStagesWithErrors, [processor.name, stageName])}
                  canRaiseAnomalies={processorCanRaiseAnomalies(processor)}
                  hasPersistedData={stage.enable_metric_logging}
                  retentionDuration={stage.retention_duration}
                  streamingEnabled={processor.properties.enable_streaming}
                  isDynamic={stage.dynamic}
                />;
              })}
            </Step.Group>
          );
        })}
        {editable && [
          <button
            key='new-processor-button'
            className={cx('new-processor dashed-add-button', {disabled: actionInProgress})}
            style={{height: processorStepHeight + stageStepHeight}}
            onClick={openProcessorModal}
          >
            <div>
              <Icon circular name='plus' color='grey' />
              {' '}
              {'Add Processor'}
            </div>
          </button>,
          <ProcessorModal
            key='processor-modal'
            open={processorModalOpen}
            onClose={closeProcessorModal}
            probe={probe}
            onSuccess={({result: processorName}) => setCurrentProcessorName(processorName)}
          />
        ]}
      </div>
    </>
  );
});
