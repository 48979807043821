import {get, find} from 'lodash';

import humanizeString from '../humanizeString';
import {getBaseRenderingStrategy} from './stageUtils';
import {STAGE_DATA_SOURCE} from './consts';

export default function makePrecedingStageColumnDefinition(name, stageIndex = 0, columnSchemaProps = {}) {
  return function({probe, fetchDataForRelatedStages}) {
    const precedingStageName = get(fetchDataForRelatedStages, [stageIndex, 'stageName'], null);
    const precedingStage = precedingStageName ? find(probe.stages, {name: precedingStageName}) : null;
    const precedingStageValueSchema = precedingStage?.values?.[name] ?? null;
    const {renderValueAs} = getBaseRenderingStrategy(precedingStage, STAGE_DATA_SOURCE.real_time);
    return {
      name,
      label: precedingStageValueSchema?.title ?? humanizeString(name),
      type: precedingStageValueSchema?.type ?? null,
      value: ({item: {preceding_items: precedingItems} = {}}) => {
        return precedingStageName ? get(precedingItems, [precedingStageName, 0, name], null) : null;
      },
      renderValueAs,
      valueSchema: precedingStageValueSchema,
      overrideFormatter(formatter) {
        return function({item: {preceding_items: precedingItems} = {}, ...args}) {
          return formatter({
            ...args,
            ...columnSchemaProps,
            name,
            item: get(precedingItems, [precedingStageName, 0], {}),
          });
        };
      },
      ...columnSchemaProps
    };
  };
}
