import {useMemo} from 'react';
import {map} from 'lodash';

import {DatacenterTopologyWithRouter} from './DatacenterTopology';

export const RackPreview = (props) => {
  const racks = useMemo(() => [{
    rack_id: 'rack',
    label: '',
  }], []);
  const nodes = useMemo(() => map(props.nodes, (node) => ({
    ...node,
    rack_id: 'rack',
  })), [props.nodes]);
  return <DatacenterTopologyWithRouter
    rackPreview
    {...props}
    racks={racks}
    nodes={nodes}
  />;
};
