import React from 'react';
import {map} from 'lodash';
import {Label} from 'semantic-ui-react';
import {natsort} from 'apstra-ui-common';

type TagsProps = {
  tags: string[],
  size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive'
};

export const Tags: React.FC<TagsProps> = ({tags = [], size}) => {
  // array.sort mutates the array thus destructuring to the new one
  const orderedTags = React.useMemo(() => [...tags].sort(natsort), [tags]);
  const optionalSize = size ? {size} : {};
  return (
    <>
      {map(
        orderedTags,
        (tag) => <Label key={tag} icon='tag' content={tag} {...optionalSize} />
      )}
    </>
  );
};
