import {forEach, map, max, sumBy} from 'lodash';

import {Layout} from '../types';
import {TopologyConfigType} from '../consts';
import {LayoutAlgorithm} from './LayoutAlgorithm';

export class StackLayout extends LayoutAlgorithm {
  recalculateOwn = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    const {gap = 0} = config;
    const childrenCount = children.length;
    if (!childrenCount) return false;

    return this.assignValue(own, {
      width: max(map(children, 'width')) ?? 0,
      height: sumBy(children, 'height') + (childrenCount - 1) * gap,
    });
  };

  recalculateChildren = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    let wereChildrenUpdated = false;
    const {gap = 0} = config;
    let y = 0;
    const stackWidth = own.width;

    forEach(children, (child) => {
      if (this.assignValue(child, {width: stackWidth})) {
        wereChildrenUpdated = true;
      }
      this.assignValue(child, {
        left: 0,
        top: y,
      });
      y += child.height + gap;
    });
    return wereChildrenUpdated;
  };
}
