import {CopyButton, onEnterKeyHandler} from 'apstra-ui-common';
import cx from 'classnames';
import {find, map} from 'lodash';
import {observer} from 'mobx-react';
import {FC, useRef} from 'react';
import {Accordion, AccordionContent, AccordionTitle, Icon, Label} from 'semantic-ui-react';

import {ProbeProcessor} from '../ProbeProcessor';
import ProbeStage from '../ProbeStage';
import {ProbeProcessorType, ProbeStageType, ProcessorDefinition} from '../../types';

import {ProbeDetailsProps} from './ProbeDetails';
import {ProbeDetailsStore} from './ProbeDetailsStore';
import {ScrollAnchor} from './ScrollContext';

import './ProbeItems.less';

type ProbeStageAccordionProps = {
  active?: boolean;
  editable?: boolean;
  hasErrors: boolean;
  processor: ProbeProcessorType;
  processorDefinition: ProcessorDefinition;
  stage: ProbeStageType;
  store: ProbeDetailsStore;
}

const ProbeStageAccordion: FC<ProbeStageAccordionProps> = ({
  active,
  editable,
  hasErrors,
  processor,
  processorDefinition,
  stage,
  store
}) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref}>
      <ScrollAnchor entityName='stage details' id={stage.name} />
      <Accordion
        fluid
        styled
        className={cx('stage', hasErrors && 'has-errors')}
      >
        <AccordionTitle
          active={active}
          onClick={() => store.setCurrentStageName(active ? null : stage.name)}
          onKeyDown={onEnterKeyHandler(() => store.setCurrentStageName(active ? null : stage.name))}
          tabIndex={0}
        >
          <Label className='stage-label'>
            <i className='icon apstra-icon apstra-icon-stage' />
            {stage.name}
            <CopyButton textToCopy={stage.name} />
          </Label>
          <Icon
            name={active ? 'angle down' : 'angle right'}
            size='large'
          />
        </AccordionTitle>
        {active &&
          <AccordionContent active>
            <div className='scrollable-content'>
              <ProbeStage
                key={stage.name}
                compact={store.probeNavigationExpanded}
                editable={editable}
                probe={store.probe}
                stage={stage}
                processor={processor}
                actionInProgress={!!store.currentActionInProgress}
                errors={store.errors}
                processorDefinition={processorDefinition}
              />
            </div>
          </AccordionContent>
        }
      </Accordion>
    </div>
  );
};

type ProbeItemsProps = {
  editable?: boolean;
  store: ProbeDetailsStore;
  telemetryServiceRegistryItems: ProbeDetailsProps['telemetryServiceRegistryItems'];
};

export const ProbeItems: FC<ProbeItemsProps> = observer(({
  editable,
  store,
  telemetryServiceRegistryItems
}) => {
  return (
    <div className='probe-items'>
      {map(store.probe.processors, (processor) => {
        const isActiveProcessor = store.currentProcessorName === processor.name;
        const processorDefinition = store.processorDefinitionsByName[processor.name];
        const outputsDefinition = processorDefinition.outputs;
        const stages = map(
          outputsDefinition,
          (_, outputId: string) => find(store.probe.stages, {name: processor.outputs[outputId]})!,
        );
        return (
          <div key={processor.name} className='processor-accordions'>
            <ProbeProcessor
              active={isActiveProcessor}
              editable={editable}
              hasErrors={store.checkProcessorHasErrors(processor.name)}
              probe={store.probe}
              processor={processor}
              telemetryServiceRegistryItems={telemetryServiceRegistryItems}
              setCurrentProcessorName={store.setCurrentProcessorName}
              highlightStage={store.highlightStage}
              actionInProgress={!!store.currentActionInProgress}
              errors={store.errors}
              processorDefinitionsByName={store.processorDefinitionsByName}
            />
            {map(stages, (stage) => (
              <ProbeStageAccordion
                key={stage.name}
                active={store.currentStageName === stage.name}
                editable={editable}
                hasErrors={store.checkStageHasErrors(stage.name)}
                processor={processor}
                stage={stage}
                store={store}
                processorDefinition={processorDefinition}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
});
