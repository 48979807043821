import {Icon, Popup} from 'semantic-ui-react';

import GraphQueriesModal from './GraphQueriesModal';

import './SavedGraphQueryButton.less';

const SavedGraphQueryButton = ({resourceName, onChange}) => (
  <GraphQueriesModal
    resourceName={resourceName}
    onChange={onChange}
    trigger={
      <span className='saved-graph-query-button'>
        <Popup
          trigger={
            <Icon
              link
              role='button'
              name='file alternate'
              aria-label='Select a predefined graph query'
            />
          }
          content='Select a predefined graph query'
          position='right center'
        />
      </span>
    }
  />
);

export default SavedGraphQueryButton;
