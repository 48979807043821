import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Group} from '@visx/group';
import {scaleSqrt} from 'd3';

import {anomalyPropTypes} from '../shared-prop-types';
import AnomalyCircle from './AnomalyCircle';

export default class AnomalyCircleList extends PureComponent {
  static propTypes = {
    anomaly: anomalyPropTypes.isRequired,
    anomalyTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    innerTextMin: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    rowHeight: PropTypes.number.isRequired,
    rowTextdy: PropTypes.string.isRequired
  };

  rScale = (value) => {
    return scaleSqrt().domain([0, this.props.maxValue]).range([0, 1]).clamp(true)(value);
  };

  getAnomalyCircleScale = (anomalyType) => {
    const {anomaly} = this.props;
    if (!anomaly || !anomalyType) return 0;
    return this.rScale(anomaly[anomalyType.toLowerCase()]) ?? 0;
  };

  render() {
    const {
      anomaly, anomalyTypes, innerTextMin, onClick, onMouseEnter, onMouseLeave,
      rowHeight, rowTextdy, left, xScale
    } = this.props;

    return (
      <Group left={left}>
        {anomalyTypes.map((anomalyType) =>
          <AnomalyCircle
            key={anomalyType}
            value={anomaly[anomalyType.toLowerCase()]}
            left={xScale(anomalyType.toLowerCase())}
            scale={this.getAnomalyCircleScale(anomalyType)}
            innerTextMin={innerTextMin}
            rowTextdy={rowTextdy}
            rowHeight={rowHeight}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={(e) => onClick(e, anomaly, anomalyType)}
          />
        )}
      </Group>
    );
  }
}
