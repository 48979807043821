export enum NODE_ROLES {
  SUPERSPINE = 'superspine',
  SPINE = 'spine',
  LEAF = 'leaf',
  LEAF_PAIR = 'leaf_pair',
  ACCESS_GROUP = 'access_group',
  RACK = 'rack',
  PLANE = 'plane',
  SUMMARY = 'summary',
  REMOTE_GATEWAY = 'remote_gateway',
  ACCESS = 'access',
  ACCESS_PAIR = 'access_pair',
  GENERIC = 'generic',
  EXTERNAL = 'external',
}

export enum LOCAL_NODE_ROLES {
  EXTERNAL_GENERIC = 'external_generic'
}

export const ALL_NODE_ROLES = {...NODE_ROLES, ...LOCAL_NODE_ROLES};
export type ALL_NODE_ROLES = NODE_ROLES | LOCAL_NODE_ROLES;
// export type ALL_NODE_ROLES = (typeof NODE_ROLES) & (typeof LOCAL_NODE_ROLES);

export enum LINK_ROLES {
  SPINE_SUPERSPINE = 'spine_superspine',
  SPINE_LEAF = 'spine_leaf',
  LEAF_LEAF = 'leaf_leaf',
  LEAF_ACCESS = 'leaf_access',
  TO_GENERIC = 'to_generic',
  ACCESS_GENERIC = 'access_generic',
  TO_EXTERNAL_ROUTER = 'to_external_router',
}
