import {useState, useEffect} from 'react';
import {Accordion, Button, Icon, Card, Message} from 'semantic-ui-react';
import {map, reduce, isEmpty, countBy, keyBy, find, every, values} from 'lodash';

import PrimitiveSelector, {makeMainPropertiesSchema} from './PrimitiveSelector';
import PrimitiveParameters from './PrimitiveParameters';
import './CtSearch.less';

// Makes human-readable summary from the selection
const makeQuerySummary = (selection, logic) => {
  if (isEmpty(selection)) return 'All';

  return 'Parameterized primitives: ' + reduce(
    countBy(selection, 'schema.label'),
    (result, count, label) => {
      if (count) {
        const multiplier = count > 1 ? `${count} \u00d7 ` : '';
        result.push(`${multiplier}${label}`);
      }
      return result;
    },
    []
  ).join(` ${logic} `);
};

// Transform selection into the format supported by backend
const buildQuery = (selection, logic) => {
  return {
    operator: logic || 'or',
    policy_type_matchers: map(selection, ({schema, criteria}) => ({
      type: schema.name || null,
      attributes: reduce(criteria, (result, conditions, parameter) => {
        const matchers = map(conditions, ({condition: operator, value}) => {
          // For cases when condition contains value in it (e.g. boolean)
          if (!value && operator && operator.includes(':')) {
            const [_operator, _value] = operator.split(':', 2);
            return {
              operator: _operator,
              value: _value
            };
          }
          return {operator, value};
        });
        if (matchers?.length) {
          // Avoid sending empty matchers set
          result.push({
            attribute_name: parameter,
            operator: conditions?.[0]?.logic ?? 'or',
            value_matchers: matchers
          });
        }
        return result;
      }, [])
    }))
  };
};

// Restores control state from the stored query
const parseQuery = ({operator, policy_type_matchers: policies}, eptSchemas, allTags) => {
  const primitiveTypes = keyBy(eptSchemas, 'name');

  return {
    operator,
    primitives: map(policies, ({type, attributes}) => {
      const schema = type ?
        primitiveTypes[type] :
        makeMainPropertiesSchema(allTags);
      if (!schema) throw `Unable to find schema ${type}`;

      return {
        schema,
        criteria: reduce(attributes, (result, {attribute_name: name, operator: logic, value_matchers: conditions}) => {
          const parameter = find(schema.arguments, {name});
          if (!parameter) throw `Unknown parameter ${name} in schema ${type}`;
          const isBoolean = parameter.type === 'boolean';
          return {
            ...result,
            [name]: map(conditions, ({operator: condition, value}) => ({
              logic,
              condition: isBoolean ? `${condition}:${value}` : condition,
              value: isBoolean ? '' : value
            }))
          };
        }, {})
      };
    })
  };
};

const isPrimitiveValid = ({criteria}) => {
  return every(
    values(criteria),
    (conditions) => every(
      conditions,
      ({value, condition}) => ( // Valid if:
        (condition ?? '').indexOf(':') >= 0 || // condition is boolean (eq:true, eq:false)
        ((!!value || value === 0) && !!condition) // both condition and value are set
      )
    )
  );
};

const isValid = (primitives) => {
  return every(primitives, (primitive) => isPrimitiveValid(primitive));
};

const CtSearch = ({eptSchemas, nodeGetByType, onSubmit, onReset, allTags, searchInProgress, initialQuery}) => {
  const [primitives, setPrimitives] = useState([]);
  const [primitivesLogic, setPrimitivesLogic] = useState('or');
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (initialQuery) {
      try {
        const {operator, primitives} = parseQuery(initialQuery, eptSchemas, allTags);
        setPrimitivesLogic(operator);
        setPrimitives(primitives);
      } catch (error) {
        // console.log(`Unable to parse initial query: "${error}"`);
      }
    }
  }, [initialQuery, allTags, eptSchemas]);

  const insertEmpty = (schema) => {
    setPrimitives([...primitives, {schema}]);
  };

  const updateParameters = (index, parameters) => {
    const result = [...primitives];
    if (parameters) {
      result[index] = parameters;
    } else {
      result.splice(index, 1);
    }
    setPrimitives(result);
  };

  const removePrimitive = (index) => {
    updateParameters(index);
  };

  const togglePrimitivesLogic = () => {
    setPrimitivesLogic(primitivesLogic === 'or' ? 'and' : 'or');
  };

  const primitivesLogicControl = (
    <Button
      className='ct-search-logic'
      primary
      circular
      size='small'
      content={primitivesLogic}
      onClick={togglePrimitivesLogic}
    />
  );

  const submitHandler = (primitives = []) => {
    if (onSubmit) onSubmit(buildQuery(primitives, primitivesLogic));
    setExpanded(false);
  };

  const resetHandler = () => {
    setPrimitives([]);
    if (onReset) onReset();
    setExpanded(false);
  };

  const queryIsIncomplete = !isValid(primitives);

  return (
    <Accordion styled className='ct-searchbox'>
      <Accordion.Title active={isExpanded} onClick={() => setExpanded(!isExpanded)}>
        <Icon name='dropdown' />
        {'Advanced Search: '}
        {makeQuerySummary(primitives, primitivesLogic)}
      </Accordion.Title>
      <Accordion.Content active={isExpanded}>
        <Card.Group itemsPerRow={4}>
          {
            primitives.map((parameters, index) => (
              <PrimitiveParameters
                key={index}
                parameters={parameters}
                update={(parameters) => updateParameters(index, parameters)}
                remove={() => removePrimitive(index)}
                logicButton={index > 0 && primitivesLogicControl}
                nodeGetByType={nodeGetByType}
              />
            ))
          }
          <PrimitiveSelector
            eptSchemas={eptSchemas}
            update={insertEmpty}
            selection={primitives}
            allTags={allTags}
          />
        </Card.Group>

        <div className='bottom-buttons'>
          <Button
            primary
            icon='search'
            content='Apply'
            disabled={searchInProgress || queryIsIncomplete}
            onClick={() => submitHandler(primitives)}
          />
          <Button
            className='ghost'
            icon='undo'
            content='Clear'
            onClick={resetHandler}
          />
        </div>
      </Accordion.Content>
      {queryIsIncomplete &&
        <Message attached='bottom' negative>{'Please make sure all required fields are filled'}</Message>}
    </Accordion>
  );
};

export default CtSearch;
