import {STATE, STATUS} from '../constants';

export const STATE_CLASSES = {
  [STATE.UP]: 'state-up',
  [STATE.DOWN]: 'state-down',
  [STATE.NOT_AVAILABLE]: 'state-not-available'
};

export const STATUS_CLASSES = {
  [STATUS.SUCCESS]: 'status-success',
  [STATUS.ERROR]: 'status-error',
  [STATUS.WARNING]: 'status-warning',
  [STATUS.INFO]: 'status-info',
  [STATUS.NOT_AVAILABLE]: 'status-not-available'
};

export const STATE_ICONS = {
  [STATE.UP]: 'arrow up',
  [STATE.DOWN]: 'arrow down',
};

export const LAG_MODE_LABELS = {
  lacp_active: 'LACP (Active)',
  lacp_passive: 'LACP (Passive)',
  static_lag: 'Static LAG (no LACP)',
  no_lag: 'No LAG'
};
