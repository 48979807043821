import {countBy, groupBy, keys, map, size} from 'lodash';
import {observer} from 'mobx-react';
import cx from 'classnames';

import Port from './Port';
import './Ports.less';

const Ports = ({ports, onClick, selectedSpeed, error, showEmpty}) => {
  const classNames = cx('re-ports', {error});

  const portsBySpeed = groupBy(ports, 'speedString');

  return (
    <div className={classNames}>
      {
        map(portsBySpeed, (ports, speedString) => {
          const amount = size(ports);

          const portsGrouped = countBy(ports, 'rolesList');
          const groupsOrdered = keys(portsGrouped).sort();
          const showCounts = size(groupsOrdered) > 1;

          const speedPortsRoles = map(groupsOrdered, (group) =>
            <li key={group}>
              {showCounts && <b>{portsGrouped[group]}</b>}
              {group}
            </li>
          );
          const listClass = cx({'no-count': !showCounts});

          return (showEmpty || !!amount) &&
            <div key={speedString}>
              <Port
                key={speedString}
                speedString={speedString}
                amount={amount}
                onClick={amount ? onClick : undefined}
                selected={selectedSpeed === speedString}
              />
              <ul className={listClass}>{speedPortsRoles}</ul>
            </div>;
        })
      }
    </div>
  );
};

export default observer(Ports);
