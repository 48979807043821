/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';

ace.define(
  'ace/mode/cypher',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
    const {Mode: TextMode} = require('ace/mode/text');
    const {BaseCompleter} = require('ace/base_completer');

    const KEYWORDS = ['match', 'return'];

    const KEYWORD_COMPLETIONS = KEYWORDS.map((name, index) => ({
      caption: name,
      snippet: name,
      meta: 'keyword',
      score: 100 - index,
    }));

    class CypherCompleter extends BaseCompleter {
      async getCustomCompletions(state, session, pos, prefix) {
        return [
          ...KEYWORD_COMPLETIONS,
          ...await this.getTextCompletions(state, session, pos, prefix)
        ];
      }
    }

    class CypherHighlightRules extends TextHighlightRules {
      constructor() {
        super();

        const keywordMapper = this.createKeywordMapper({
          'keyword.control': KEYWORDS.join('|'),
        }, 'text');

        this.$rules = {
          start: [
            {
              token: 'comment',
              regex: /\/\/.*$/
            },
            {
              token: keywordMapper,
              regex: /[a-z]+\b/,
            },
            {
              token: 'keyword.operator',
              regex: /(?:=|==|=~|-|->|<-|!|\+|\*)/,
            },
            {
              token: 'punctuation',
              regex: ',|;|\\.'
            },
            {
              token: 'paren.lparen',
              regex: /[[({]/
            },
            {
              token: 'paren.rparen',
              regex: /[\])}]/
            },
            {
              token: 'text',
              regex: /\s+/
            },
            {
              include: 'constants'
            }
          ],
          comments: [{
            token: 'comment',
            regex: /\/\*/,
            push: [{
              token: 'comment',
              regex: /\*\//,
              next: 'pop'
            }, {
              defaultToken: 'comment'
            }]
          }],
          constants: [
            {
              token: 'constant.numeric',
              regex: /-?(0|[1-9]\d*)(\.\d+)?([eE][+-]?\d+)?/
            },
            {
              token: 'constant.lanugage',
              regex: /(true|false|null)\b/
            }
          ]
        };
        this.normalizeRules();
      }
    }

    class CypherExpressionMode extends TextMode {
      $id = 'ace/mode/cypher';
      $behaviour = this.$defaultBehaviour;
      HighlightRules = CypherHighlightRules;
      completer = new CypherCompleter();
    }

    exports.Mode = CypherExpressionMode;
    exports.HighlightRules = CypherHighlightRules;
  }
);

ace.require(['ace/mode/cypher']);
