import {useMemo, useState} from 'react';
import {castArray, map, isEmpty, omit} from 'lodash';
import Ajv from 'ajv';
import {Button, Form, Grid} from 'semantic-ui-react';
import {CodeEditorControl, GenericErrors, SingleFileInput} from 'apstra-ui-common';

import configletSchema from './configletSchema';
import configTemplateSchema from './configTemplateSchema';
import humanizeString from '../../humanizeString';

const IMPORT_TYPES = ['configlet', 'config_template'];
const [CONFIGLET_TYPE, CONFIG_TEMPLATE_TYPE] = IMPORT_TYPES;

const ConfigletImportForm = ({
  accept = {'application/json': [], 'text/plain': []}, onSuccess, onCancel, type = CONFIGLET_TYPE,
}) => {
  const [errors, setErrors] = useState([]);
  const [config, setConfig] = useState('');
  const ajv = new Ajv({allErrors: true});

  const schema = useMemo(() => {
    return type === CONFIGLET_TYPE ?
      configletSchema :
      type === CONFIG_TEMPLATE_TYPE ?
        configTemplateSchema :
        null;
  }, [type]);

  const validateAndNormalizeConfig = (content) => {
    ajv.validate(schema, content);
    return map(ajv.errors, ({message, instancePath, keyword, params}) => {
      const info = (keyword === 'additionalProperties' && params.additionalProperty) ?
        ` (property: ${params.additionalProperty})` : '';
      return `${type}${instancePath}: ${message}` + info;
    });
  };

  const onContentUpdate = (content) => {
    if (!isEmpty(errors)) setErrors([]);
    setConfig(content);
  };

  const onFileChange = (file) => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = () => onContentUpdate(reader.result);
      reader.readAsText(file);
    }
  };

  const parseConfig = () => {
    let parsed = null;
    try {
      parsed = JSON.parse(config);
    } catch (error) {
      setErrors(castArray(`JSON parse error: ${error.toString()}`));
      return;
    }
    if (parsed) {
      let errors = [];
      try {
        errors = validateAndNormalizeConfig(parsed);
      } catch (error) {
        setErrors(castArray(`Validation error: ${error.toString()}`));
        return;
      }
      if (errors.length) {
        setErrors(errors);
        return;
      }
    }
    onSuccess(omit(parsed, ['id', 'created_at', 'last_modified_at']));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Form>
            <SingleFileInput
              description={
                'Drag and drop config file here, choose it by clicking the button or ' +
                'paste its contents in the field below.'
              }
              onChange={onFileChange}
              accept={accept}
            />
            <Form.Field>
              <CodeEditorControl
                mode='json'
                aria-label={`${humanizeString(type)} Import Form`}
                maxLines={15}
                value={config}
                multiLine
                showGutter
                onChange={onContentUpdate}
              />
            </Form.Field>
          </Form>
          {!!errors.length &&
            <GenericErrors errors={[...errors]} />
          }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className='center aligned'>
          <Button content='Cancel' onClick={onCancel} className='ghost' />
          <Button
            content={`Import ${humanizeString(type)}`}
            className='primary'
            disabled={isEmpty(config)}
            onClick={parseConfig}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ConfigletImportForm;
