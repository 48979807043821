import React from 'react';
import {Label} from 'semantic-ui-react';

import './SystemType.less';

type SystemTypeProps = {
  systemType: string;
}

export const SystemType: React.FC<SystemTypeProps> = ({systemType}) => {
  if (!systemType) {
    return null;
  }

  const colorMap = {
    internal: 'teal',
    external: 'purple',
  };

  return <Label className='system-type-value' color={colorMap[systemType]}>{systemType}</Label>;
};
