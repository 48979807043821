import {filter, first, intersection, map, size, sortBy, transform, uniq} from 'lodash';
import {action, computed, makeObservable, observable, toJS} from 'mobx';

import {generateLocalId} from '../../cablingMapEditor/utils';
import {
  DUAL_ATTACHMENT_TYPE, FIRST_SWITCH_PEER, LAG_MODES, PEER_LINKS_TYPES, PORT_ROLES, SECOND_SWITCH_PEER,
  SINGLE_ATTACHMENT_TYPE, draftLinksGroupId
} from '../const';
import {stringFromSpeed} from '../utils';
import Port from './Port';
import {ctrlDirections, ctrlEntityTypes, ctrlHalfNodeHeight, ctrlHalfNodeWidth, ctrlIfcPositions,
  ctrlNodeHeight, ctrlNodeWidth, ctrlPortSpacing} from '../../cablingMapEditor/const';

class LinksGroup {
  @observable id = generateLocalId();
  @observable label = '';
  @observable fromName = '';
  @observable toName = '';
  @observable count = 0;
  @observable speed = null;
  @observable isAttachedToFirst = true;
  @observable isAttachedToSecond = true;
  @observable tags = [];
  @observable peeringType = null;

  @observable _lagMode = LAG_MODES.NO_LAG;

  @observable.ref rackStore = null;

  @observable stage = null;

  get type() {
    return ctrlEntityTypes.LINKS_GROUP;
  }

  @computed
  get quickState() {
    return [
      this.id,
      this.speedString,
      this.count,
      this.isAttachedToFirst,
      this.isAttachedToSecond,
      this.isToPair
    ].join(':');
  }

  @computed
  get isPeer() {
    return this.fromName === this.toName;
  }

  @computed
  get isL3Peer() {
    return this.isPeer && this.peeringType === PEER_LINKS_TYPES.L3_PEER;
  }

  @computed
  get isDualAttached() {
    return this.isAttachedToFirst && this.isAttachedToSecond && this.isToPair;
  }

  @computed
  get speedString() {
    return stringFromSpeed(this.speed);
  }

  @computed
  get fromRole() {
    return this.isPeer ? PORT_ROLES.PEER : this.fromNode?.role;
  }

  @computed
  get toRole() {
    return this.isPeer ? PORT_ROLES.PEER : this.toNode?.role;
  }

  @computed
  get fromNode() {
    return first(this.rackStore.nodesByName[this.fromName]);
  }

  @computed
  get toNode() {
    return first(this.rackStore.nodesByName[this.toName]);
  }

  @computed
  get nodesInvolved() {
    return this.isPeer ?
      this.fromNode.nodesInvolved :
      [...this.fromNode.nodesInvolved, ...this.toNode.nodesInvolved];
  }

  @computed
  get isToPair() {
    return this.toNode?.isPaired;
  }

  @computed
  get isBetweenPairs() {
    return this.fromNode?.isPaired && this.isToPair && !this.isPeer;
  }

  @computed
  get portsPositions() {
    return transform(
      map(this.nodesInvolved),
      (acc, node) => {
        acc[node.id] = findPortPosition(this, node);
      },
      {}
    );
  }

  @computed
  get lagMode() {
    return ((this.count > 1 || this.isDualAttached) && this._lagMode === LAG_MODES.NO_LAG) ?
      LAG_MODES.LACP_ACTIVE : this._lagMode;
  }

  set lagMode(mode) {
    this.setProperty('_lagMode', mode);
  }

  @computed
  get hasAggregation() {
    return this.lagMode !== LAG_MODES.NO_LAG;
  }

  @computed
  get isEmpty() {
    return !(this.count > 0);
  }

  @computed
  get isDraft() {
    return this.id === draftLinksGroupId;
  }

  constructor(rackStore) {
    makeObservable(this);
    this.rackStore = rackStore;
  }

  @action
  setProperty(name, value) {
    this[name] = value;
  }

  // True if some/all of the nodes are contained in this links group
  contains(nodes, all) {
    const names = uniq(map(nodes, 'name'));
    return this.containsNames(names, all);
  }

  // Makes sure that the link connects given two nodes
  connects(node1, node2) {
    if (!this.contains([node1, node2], true)) return false;
    if (this.isDualAttached) return true;
    const [source, destination] = node1.name === this.fromName ? [node1, node2] : [node2, node1];
    return !destination.isPaired || (
      source.isPaired ?
        source.isFirstInPair === destination.isFirstInPair :
        (
          (this.isAttachedToFirst && destination.isFirstInPair) ||
          (this.isAttachedToSecond && !destination.isFirstInPair)
        )
    );
  }

  // True if some/all of the names are contained in this links group
  containsNames(names, all) {
    if (all && size(names) === 1) {
      return this.fromName === names[0] && this.isPeer;
    }
    const matches = size(intersection([this.fromName, this.toName], names));
    return all ? matches === size(names) : matches > 0;
  }

  oppositeTo(name) {
    if (this.fromName === name) return this.toName;
    if (this.toName === name) return this.fromName;
  }

  oppositeToNode({name}) {
    if (this.fromName === name) return this.toNode;
    if (this.toName === name) return this.fromNode;
  }

  // Calculates how many ports the link consumes from each of its nodes
  getSizeOnNodes(nodesByName) {
    const fromNodes = nodesByName[this.fromName];
    const toNodes = nodesByName[this.toName];
    const [fromPair, toPair] = [size(fromNodes) === 2, size(toNodes) === 2];

    // Calculate for source nodes
    const result = transform(fromNodes, (acc, {id}) => {
      acc[id] = this.count * ((toPair && this.isDualAttached) ? 2 : 1);
    }, {});

    // Calculate for destination nodes
    return transform(toNodes, (acc, {id, isFirstInPair}) => {
      acc[id] = this.count * (
        fromPair ?
          (this.isDualAttached ? 2 : 1) :
          (toPair ?
            ((isFirstInPair ? this.isAttachedToFirst : this.isAttachedToSecond) ? 1 : 0) :
            1
          )
      );
    }, result);
  }

  getPortsFor(name) {
    const role = this.fromName === name ? this.toRole : this.fromRole;
    return Port.generate(this.count, this.speed, role);
  }

  isSamePeerWith({fromName, isPeer}) {
    return isPeer && this.containsNames([fromName], true);
  }

  @action
  fillWith(data) {
    Object.assign(this, data);
  }

  cloneFor(namesMap) {
    return LinksGroup.createFilledWith({
      fromName: namesMap?.[this.fromName] ?? this.fromName,
      toName: namesMap?.[this.toName] ?? this.toName,
      label: this.label,
      speed: {...this.speed},
      count: this.count,
      peeringType: this.peeringType,
      lagMode: this.lagMode,
      tags: [...this.tags],
      isAttachedToFirst: this.isAttachedToFirst,
      isAttachedToSecond: this.isAttachedToSecond
    }, this.rackStore);
  }

  serialize(withInternals = true) {
    return toJS({
      label: this.label,
      tags: toJS(this.tags),
      link_speed: toJS(this.speed),
      link_per_switch_count: this.count,
      ...(this.hasAggregation ?
        {lag_mode: this.lagMode} :
        // All links from access switches to leafs must have 'lacp_active' LAG mode
        (this.fromNode?.isAccessSwitch ?
          {lag_mode: LAG_MODES.LACP_ACTIVE} : {}
        )
      ),
      attachment_type: this.isDualAttached ? DUAL_ATTACHMENT_TYPE : SINGLE_ATTACHMENT_TYPE,
      ...((this.isDualAttached || !this.toNode?.isPaired || this.fromNode?.isPaired) ?
        {} :
        {switch_peer: this.isAttachedToFirst ? FIRST_SWITCH_PEER : SECOND_SWITCH_PEER}
      ),
      ...(withInternals ?
        {
          internals: {
            id: this.id,
            fromName: this.fromNode?.name,
            toName: this.toNode?.name,
            peeringType: this.peeringType,
            isAttachedToFirst: this.isAttachedToFirst,
            isAttachedToSecond: this.isAttachedToSecond
          }
        } :
        {
          target_switch_label: this.toNode?._label
        }
      )
    });
  }

  static deserialize(jsonValue, rackStore) {
    const {
      label,
      tags,
      node_label: fromName,
      target_switch_label: toName,
      attachment_type: attachmentType,
      switch_peer: switchPeer,
      link_per_switch_count: count,
      lag_mode: lagMode = LAG_MODES.NO_LAG,
      link_speed: speed,
      internals
    } = jsonValue;

    const isDoubleAttached = attachmentType === DUAL_ATTACHMENT_TYPE;
    const isFirst = switchPeer === FIRST_SWITCH_PEER;

    return LinksGroup.createFilledWith({
      label,
      tags,
      speed,
      lagMode,
      fromName,
      toName,
      count,
      isAttachedToFirst: isDoubleAttached || isFirst,
      isAttachedToSecond: isDoubleAttached || !isFirst,
      ...(internals ? internals : {})
    }, rackStore);
  }

  static createPeerLinks(nodesName, speed, count, rackStore, peeringType = PEER_LINKS_TYPES.L2_PEER) {
    return LinksGroup.createFilledWith({
      label: `${peeringType}_peer_links`,
      fromName: nodesName,
      toName: nodesName,
      lagMode: LAG_MODES.LACP_ACTIVE,
      speed,
      count,
      peeringType
    }, rackStore);
  }

  static createFilledWith(data, rackStore) {
    const result = new LinksGroup(rackStore);
    result.fillWith(data);
    return result;
  }
}

export default LinksGroup;

const findPortPosition = ({id}, node) => {
  const {interfacesPositions, position: {x, y}} = node;

  const placement = interfacesPositions[id]?.side;
  if (!placement) {
    return {
      x: x + ctrlHalfNodeWidth,
      y: y + ctrlHalfNodeHeight
    };
  }

  const sidePorts = map(
    sortBy(
      filter(interfacesPositions, {side: placement}),
      'node.topologicOrder'
    ),
    'id'
  );

  const index = sidePorts.indexOf(id);
  const sidePortsCount = size(sidePorts);
  const onShortSide = placement === ctrlIfcPositions.RIGHT || placement === ctrlIfcPositions.LEFT;
  const sideSize = onShortSide ? ctrlNodeHeight : ctrlNodeWidth;
  const spacer = sidePortsCount * ctrlPortSpacing > sideSize ?
    (sideSize - 8) / sidePortsCount :
    ctrlPortSpacing;
  const portsHalfWidth = ((sidePorts.length - 1) * spacer) / 2;

  return onShortSide ?
    {
      x: x + (placement === ctrlIfcPositions.LEFT ? 0 : ctrlNodeWidth),
      y: y + ctrlHalfNodeHeight - portsHalfWidth + index * spacer,
      direction: ctrlDirections.HORIZONTAL,
      placement
    } :
    {
      x: x + ctrlHalfNodeWidth - portsHalfWidth + index * spacer,
      y: y + (placement === ctrlIfcPositions.TOP ? 0 : ctrlNodeHeight),
      direction: ctrlDirections.VERTICAL,
      placement
    };
};
