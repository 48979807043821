import {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {get, map, forEach, some, includes, noop, isEmpty, transform, mergeWith, add, filter, sum} from 'lodash';
import {Label, Popup} from 'semantic-ui-react';
import cx from 'classnames';

import humanizeString from '../humanizeString';

import './LiveIndicator.less';

const OPERATION_MODE_NORMAL = 'normal';
const OPERATION_MODE_MAINTENANCE = 'maintenance';
const OPERATION_MODE_READ_ONLY = 'read_only';

const NODE_ERROR_AGENT_MISSING = 'agentMissing';
const NODE_ERROR_DISK_IN_READY_ONLY_MODE = 'diskInReadOnlyMode';
const NODE_ERROR_AGENT_REBOOT = 'agentReboot';
const NODE_ERROR_STATE_FAILED = 'failed';
const NODE_WARNING_DISK_USAGE_THRESHOLD = 'diskUsageThreshold';
const NODE_WARNING_STATE_MISSING = 'missing';
const NODE_STATE_ACTIVE = 'active';
const NODE_STATE_MAINTENANCE = 'maintenance';

const CONTAINER_LABEL = {
  iba: 'IBA Services',
  offbox: 'Offbox Agents',
};
const getContainersStatus = (containerMap, {errorColor, warningColor, activeColor}) => {
  return containerMap.failed > 0 ?
    ['Failed', errorColor] :
    containerMap.queued > 0 ?
      ['Queued', warningColor] :
      containerMap.launched > 0 ?
        ['Launched', activeColor] :
        ['Unknown', null];
};
const linkProps = {
  role: 'link',
  tabIndex: 0,
};

@observer
export default class LiveIndicator extends Component {
  static defaultProps = {
    warningColor: 'orange',
    errorColor: 'red',
    activeColor: 'green',
    generateNodeDetailUrl: noop,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get clusterNodesByRole() {
    const {clusterHealth} = this.props;
    const controllers = [];
    const services = [];
    forEach(clusterHealth?.nodes, (node) => {
      if (includes(node.roles, 'controller')) controllers.push(node);
      if (includes(node.roles, 'worker')) services.push(node);
    });
    return {controllers, services};
  }

  @computed
  get clusterHealthShortList() {
    const {controllers, services} = this.clusterNodesByRole;
    return [
      this.getOperationModeIndicator(),
      ...this.getNodeIndicatorsShortList(controllers, {label: 'Controller Node'}),
      ...this.getNodeIndicatorsShortList(services, {label: 'Service Nodes'}),
      ...this.containersIndicatorList,
    ];
  }

  @computed
  get containersIndicatorList() {
    const {clusterHealth, warningColor, errorColor, activeColor} = this.props;
    return filter(map(transform(clusterHealth?.nodes, (result, {application_summary: summary = {}}) => {
      const {iba = {}, offbox = {}} = result;
      result.iba = mergeWith({}, iba, summary.iba, add);
      result.offbox = mergeWith({}, offbox, summary.offbox, add);
    }, {}),
    (containerMap = {}, key) => {
      const [state, health] = getContainersStatus(containerMap, {warningColor, errorColor, activeColor});
      return {
        key,
        label: CONTAINER_LABEL[key],
        health,
        state,
        containerMap,
      };
    }),
    ({containerMap}) => sum(map(containerMap)) > 0);
  }

  getNodeIndicators(nodes, options = {}) {
    const {generateNodeDetailUrl} = this.props;
    return map(nodes, ({id, label, errors, state}) => {
      const health = new Set(errors);
      const isError = some([
        NODE_ERROR_AGENT_MISSING,
        NODE_ERROR_DISK_IN_READY_ONLY_MODE,
        NODE_ERROR_AGENT_REBOOT
      ], (error) => health.has(error)) || state === NODE_ERROR_STATE_FAILED || state === NODE_STATE_MAINTENANCE;
      const isWarning = health.has(NODE_WARNING_DISK_USAGE_THRESHOLD) || state === NODE_WARNING_STATE_MISSING;
      const isActive = isEmpty(errors) && state === NODE_STATE_ACTIVE;
      return {
        href: generateNodeDetailUrl(id),
        label: humanizeString(label),
        health: isError ?
          this.props.errorColor :
          isWarning ?
            this.props.warningColor :
            isActive ?
              this.props.activeColor :
              null,
        state: humanizeString(state),
        key: id,
        note: health.has(NODE_ERROR_AGENT_MISSING) ?
          'Some agents have crashed and not able to come back up' :
          health.has(NODE_ERROR_AGENT_REBOOT) ?
            'Some agents have crashed and have been restarted recently' :
            null,
        ...options,
      };
    });
  }

  getNodeIndicatorsShortList(nodes, options = {}) {
    if (nodes.length <= 1) return this.getNodeIndicators(nodes, options);
    const indicators = this.getNodeIndicators(nodes, options);
    let warningIndicator = null;
    for (const indicator of indicators) {
      if (!warningIndicator && indicator.health === this.props.warningColor) warningIndicator = indicator;
      if (indicator.health === this.props.errorColor) return [indicator];
    }
    return [warningIndicator || indicators[0]];
  }

  getOperationModeIndicator() {
    const {clusterHealth, clusterManagementUrl: href, errorColor, warningColor, activeColor} = this.props;
    const operationMode = get(clusterHealth, ['config', 'operation_mode'], '');
    return {
      health: operationMode === OPERATION_MODE_NORMAL ?
        activeColor :
        operationMode === OPERATION_MODE_READ_ONLY ?
          warningColor :
          operationMode === OPERATION_MODE_MAINTENANCE ?
            errorColor :
            null,
      key: 'operationMode',
      label: 'Operation Mode',
      state: humanizeString(operationMode) || 'Unknown',
      href,
    };
  }

  renderCompactIndicators = () => {
    return map(this.clusterHealthShortList, ({health, key, label}) =>
      <Popup
        key={key}
        content={label}
        trigger={
          <div className={cx('indicator', health)} />
        }
        offset={[-9, 0]}
        size='mini'
      />
    );
  };

  renderIndicators = (indicators) => {
    return map(indicators, ({health, href, key, label, state, note}) =>
      <Fragment key={key}>
        <a href={href} className='block expanded-indicator' {...linkProps}>
          <span>{label}</span>
          <Label size='small' content={state} className={health} />
        </a>
        {note &&
          <div className='block note'>
            <i>{note}</i>
          </div>
        }
      </Fragment>
    );
  };

  renderApplicationContainerIndicators = () => {
    const {nodesUrl} = this.props;
    if (this.containersIndicatorList.length === 0) return null;
    return (
      <>
        <div className='header'>{'Application containers'}</div>
        <hr />
        {map(this.containersIndicatorList, ({key, health, state, label, containerMap}) => (
          <Popup
            key={key}
            trigger={
              <a href={nodesUrl} className='block expanded-indicator' {...linkProps}>
                <span>{label}</span>
                <Label size='small' color={health} content={state} />
              </a>
            }
            size='small'
            position='top center'
            content={
              <div className='ui bulleted list'>
                {map(containerMap, (value, key) =>
                  <div key={key} className='item'>
                    <b>{key}</b>{': '}{value}
                  </div>
                )}
              </div>
            }
          />
        ))}
      </>
    );
  };

  render() {
    const {
      renderCompactIndicators, renderIndicators, renderApplicationContainerIndicators,
      props: {expanded}
    } = this;
    const {controllers, services} = this.clusterNodesByRole;
    if (expanded) {
      return (
        <div className='live-indicator'>
          <div className='header'>{'Configuration'}</div>
          <hr />
          {renderIndicators([this.getOperationModeIndicator()])}
          {controllers.length > 0 &&
            <Fragment>
              <div className='header'>{'Controller Node'}</div>
              <hr />
              {renderIndicators(this.getNodeIndicators(controllers))}
            </Fragment>
          }
          {services.length > 0 &&
            <>
              <div className='header'>{'Service Nodes'}</div>
              <hr />
              {renderIndicators(this.getNodeIndicators(services))}
            </>
          }
          {renderApplicationContainerIndicators()}
        </div>
      );
    }
    const indicators = renderCompactIndicators();
    return (
      <div className='live-indicator'>
        <div className={cx('compact-indicator', `children-${indicators.length}`)}>
          {indicators}
        </div>
      </div>
    );
  }
}
