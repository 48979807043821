import {useCallback, useMemo} from 'react';
import {flatMap, transform, compact, keys} from 'lodash';
import {Field, StringListDropdownControl} from 'apstra-ui-common';
import {Icon} from 'semantic-ui-react';

import {NODE} from '../../pythonExpression/consts';

export default function QueryGroupByInput({
  name, value, required, schema, disabled, errors, onChange, fieldProps,
  namedNodesInGraphQueries, graphNodesStyles
}) {
  const knownItemsWithStyles = useMemo(
    () => transform(
      compact(flatMap(namedNodesInGraphQueries, 'namedNodes')),
      (result, {functionName, nodeName, nodeType}) => {
        result[nodeName] = functionName === NODE ? graphNodesStyles[nodeType] : null;
      },
      {}
    ),
    [graphNodesStyles, namedNodesInGraphQueries]
  );

  const knownItems = useMemo(
    () => keys(knownItemsWithStyles),
    [knownItemsWithStyles]
  );

  const formatItem = useCallback((nodeName) => {
    const {color, shape} = knownItemsWithStyles[nodeName] ?? {};
    return (
      <>
        <Icon name={shape ?? 'circle outline'} color={color} />
        {nodeName}
      </>
    );
  }, [knownItemsWithStyles]);

  return (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <StringListDropdownControl
        allowAdditions
        noResultsMessage='Start typing to add a new name'
        placeholder='No names specified'
        value={value}
        knownItems={knownItems}
        formatItem={formatItem}
        onChange={onChange}
      />
    </Field>
  );
}
