import {ActionsMenu, CodeEditorControl, DataFilteringLayout, DataTable} from 'apstra-ui-common';
import {Label} from 'semantic-ui-react';

const tableSchema = [
  {
    name: 'label',
    label: 'Label',
    value: ['label'],
  },
  {
    name: 'query',
    label: 'Query',
    value: ['query'],
    formatter: ({value}) =>
      <CodeEditorControl
        value={value}
        mode='graph-query'
        multiLine
        disabled
      />,
  },
  {
    name: 'predefined',
    label: 'Predefined',
    value: ['predefined'],
    formatter: ({value}) =>
      <Label>{value ? 'Yes' : 'No'}</Label>
  },
  {
    name: 'actions',
    label: 'Actions',
    formatter: ({item, params: {onExecute, onSaveEditableQuery, onDeleteQuery}}) =>
      <ActionsMenu
        items={[
          {
            icon: 'play circle',
            title: 'Execute',
            onClick: () => onExecute(item.query),
          },
          {
            icon: 'edit',
            title: 'Edit',
            hasPermissions: !item.predefined,
            notPermittedMessage: 'You can\'t edit predefined queries',
            onClick: () => onSaveEditableQuery(item),
          },
          {
            icon: 'trash',
            title: 'Delete',
            hasPermissions: !item.predefined,
            notPermittedMessage: 'You can\'t delete predefined queries',
            onClick: () => onDeleteQuery(item),
          },
        ]}
        size='tiny'
      />,
  }
];

export const searchBoxSchema = [
  {
    name: 'label',
    match: 'regex',
    schema: {
      type: 'string',
      title: 'Label',
    }
  },
  {
    name: 'predefined',
    schema: {
      type: 'string',
      title: 'Predefined',
      oneOf: [
        {const: true, title: 'Yes'},
        {const: false, title: 'No'},
      ]
    }
  },
];

const QueryCatalog = ({
  queries, loaderVisible, fetchDataError, totalCount, activePage, pageSize, filters,
  updatePagination, updateFilters, onExecute, onSaveEditableQuery, onDeleteQuery,
}) =>
  <DataFilteringLayout
    loaderVisible={loaderVisible}
    fetchDataError={fetchDataError}
    paginationProps={{
      totalCount,
      activePage,
      pageSize,
      onChange: updatePagination
    }}
    searchProps={{
      filters,
      schema: searchBoxSchema,
      onChange: updateFilters
    }}
  >
    <DataTable
      items={queries}
      schema={tableSchema}
      getCellProps={({name}) => name !== 'query' ?
        {collapsing: true, verticalAlign: 'top'} : {}
      }
      params={{onExecute, onSaveEditableQuery, onDeleteQuery}}
      size='small'
    />
  </DataFilteringLayout>;

export default QueryCatalog;
