import {observer} from 'mobx-react';
import {action} from 'mobx';
import {Field} from 'apstra-ui-common';

import TagsControl from '../../../components/TagsControl';
import './AggregateProperties.less';
import {useCablingMapStore} from '../../store/useCablingMapStore';

const updateTags = action((link, tags) => {
  link.tags = [...tags];
});

const AggregateProperties = ({aggregateLink}) => {
  const {cablingMap: {tags: knownTags}} = useCablingMapStore();
  const {tags} = aggregateLink;
  return (
    <div className='ctrl-aggregate-properties'>
      <Field label='Tags'>
        <TagsControl
          fluid
          knownTags={knownTags}
          onChange={(tags) => updateTags(aggregateLink, tags)}
          value={tags}
        />
      </Field>
    </div>
  );
};

export default observer(AggregateProperties);
