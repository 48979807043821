import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Group} from '@visx/group';
import {Text} from '@visx/text';

export default class AnomalyMatrixHeader extends PureComponent {
  static propTypes = {
    anomalyTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    left: PropTypes.number,
    top: PropTypes.number,
    xScale: PropTypes.func.isRequired
  };

  render() {
    const {anomalyTypes, top, left, xScale} = this.props;
    return (
      <Group className='header' top={top} left={left}>
        {anomalyTypes.map((type) => (
          <Text key={type} x={xScale(type.toLowerCase())}>
            {type}
          </Text>
        ))}
      </Group>
    );
  }
}
