import cx from 'classnames';
import pluralize from 'pluralize';

import BaseGauge from '../Gauge';

import './AnomalyGauge.less';

const AnomalyGauge = ({
  label,
  maxValue,
  onClick,
  tooltip,
  value
}) => {
  const anomaliesText = pluralize('anomaly', value, true);
  return (
    <BaseGauge
      className='anomaly-gauge'
      height={85}
      hideLabels
      margin={{top: 7.5, right: 0, bottom: 0, left: 0}}
      maxValue={maxValue}
      onClick={onClick}
      radius={50}
      tooltip={tooltip}
      value={value}
      width={100}
    >
      <text
        className='gauge-inner-title'
        dy='-.3em'
      >
        {label}
      </text>
      <text
        className={cx('gauge-inner-value', {highlight: !!value})}
        dy='1em'
      >
        {anomaliesText}
      </text>
    </BaseGauge>
  );
};

export default AnomalyGauge;
