import {Component} from 'react';
import {observer} from 'mobx-react';
import {computed, reaction, makeObservable} from 'mobx';
import {get, map, find, some, includes, filter, transform, head, has, union} from 'lodash';
import {Button, Form, Icon, Popup} from 'semantic-ui-react';
import {CodeEditorInput, DataTable, DropdownControl, Field} from 'apstra-ui-common';

import buildPropertyDocHTMLFromSchema from '../../pythonExpression/buildPropertyDocHTMLFromSchema';
import './KeysInput.less';

const tableSchema = [
  {
    name: 'key',
    label: 'Key Name',
    formatter: ({item: {required, key, schema}, params: {onChange, values, extraItems, disabled}}) =>
      required ?
        <Field required>
          <label>{key}</label>
          <InfoPopup name={key} schema={schema} />
        </Field>
      :
        <Form.Group>
          <Field width={16}>
            <DropdownControl
              fluid
              value={key}
              options={map([key, ...extraItems], (item) => ({key: item, text: item, value: item}))}
              onChange={(value) => {
                const result = {...values, [value]: ''};
                delete result[key];
                onChange(result);
              }}
              disabled={disabled}
            />
          </Field>
          {schema &&
            <Field className='no-input-field'>
              <InfoPopup name={key} schema={schema} />
            </Field>
          }
        </Form.Group>
  },
  {
    name: 'value',
    label: 'Value',
    formatter: ({
      item: {value, key}, params: {onChange, values, errors, disabled, knownPythonExpressionVariables}
    }) => (
      <CodeEditorInput
        mode='python-expression'
        enableCompletion
        completerParams={{knownVariables: knownPythonExpressionVariables}}
        value={value}
        onChange={(value) => onChange({...values, [key]: value})}
        errors={errors[key]}
        disabled={disabled}
      />
    ),
  },
  {
    name: 'action',
    formatter: ({item: {required, key}, params: {disabled, deleteProperty}}) => {
      const deletionPossible = !(required || disabled);
      const tooltip = required ? 'Cannot delete required keys' : disabled ? null : 'Delete';
      return (
        <Popup
          trigger={
            <Icon
              link={deletionPossible}
              color={deletionPossible ? 'red' : 'grey'}
              name='remove'
              disabled={!deletionPossible}
              onClick={() => deleteProperty(key)}
              tabIndex={0}
              role='button'
              aria-label='Delete'
            />
          }
          position='top left'
          offset={[deletionPossible ? -11 : 11, 0]}
          disabled={!tooltip}
        >
          {tooltip}
        </Popup>
      );
    }
  }
];

@observer
export default class KeysInput extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeServiceNameReaction = reaction(
      () => this.serviceName,
      () => {
        const {value} = this.props;
        const {required} = this.getProperties();
        if (some(required, (item) => !includes(value, item))) {
          const newValue = transform(required, (result, key) => {
            result[key] = '';
          }, {});
          this.props.onChange(newValue);
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  componentWillUnmount() {
    this.disposeServiceNameReaction();
  }

  getProperties = () => {
    return {
      required: get(this.service, ['application_schema', 'properties', 'key', 'required'], []),
      properties: get(this.service, ['application_schema', 'properties', 'key', 'properties'], []),
    };
  };

  @computed get errors() {
    const {props: {errors}, value} = this;
    const getErrors = (key) => map(filter(errors, (error) => has(error, [key])), (error) => error[key]);
    return transform(value, (result, value, key) => {
      result[key] = getErrors(key);
    }, {keysError: getErrors('keys')});
  }

  @computed get serviceName() {
    return this.props.values.service_name;
  }

  @computed get service() {
    const {serviceName, props: {telemetryServiceRegistryItems}} = this;
    return find(telemetryServiceRegistryItems, {service_name: serviceName});
  }

  @computed get extraItems() {
    const {properties} = this.getProperties();
    return filter(map(properties, (schema, key) => key), (key) => !has(this.value, [key]));
  }

  @computed get items() {
    const {required, properties} = this.getProperties();
    return map(this.value, (value, key) => ({key, schema: properties[key], value, required: includes(required, key)}));
  }

  @computed get value() {
    const {value, values} = this.props;
    const {required} = this.getProperties();
    return transform(union(value, required), (result, key) => {
      result[key] = values[key] ?? '';
    }, {});
  }

  addProperty = () => {
    const key = head(this.extraItems);
    this.props.onChange({...this.value, [key]: ''});
  };

  deleteProperty = (key) => {
    const result = {...this.value};
    delete result[key];
    this.props.onChange(result);
  };

  render() {
    const {
      props: {name, schema, required, disabled, onChange, fieldProps, knownPythonExpressionVariables},
      value, extraItems, errors, deleteProperty
    } = this;
    return (
      <Field
        className='keys-input'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors.keysError}
        {...fieldProps}
      >
        <DataTable
          size='small'
          schema={tableSchema}
          items={this.items}
          params={{
            onChange, values: value, errors, extraItems, disabled, deleteProperty, knownPythonExpressionVariables
          }}
          getCellProps={({name}) => name !== 'value' ? {collapsing: true} : {}}
          getRowProps={() => ({verticalAlign: 'top'})}
        />
        {!!this.extraItems.length && (
          <Button
            type='button'
            color='teal'
            size='tiny'
            icon='add'
            labelPosition='left'
            content='Add key'
            onClick={this.addProperty}
          />
        )}
      </Field>
    );
  }
}

const InfoPopup = ({name, schema}) => (
  <Popup
    trigger={
      <Icon
        name='info circle'
        color='grey'
        aria-label={schema?.description || name}
        aria-hidden='true'
      />
    }
    position='right center'
    wide
  >
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{__html: buildPropertyDocHTMLFromSchema(schema)}}
    />
  </Popup>
);
