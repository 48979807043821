import cx from 'classnames';
import {observer} from 'mobx-react';
import {Icon, Label, Step} from 'semantic-ui-react';

import ProcessorIcon from '../../ProcessorIcon';
import {EllipsisPopup} from '../../../../components/EllipsisPopup';
import {ScrollAnchor} from '../ScrollContext';

export const ProcessorStep = observer(({
  processor, active,
  highlights, highlightCurrentEntity, highlightProcessorAndRelatedStages, setCurrentProcessorName,
  processorStepHeight, hasProcessorError
}) => {
  return (
    <Step
      key={`processor ${processor.name}`}
      className={cx('processor', {highlighted: processor.name in highlights.processors})}
      style={{height: processorStepHeight}}
      active={active}
      onMouseEnter={() => highlightProcessorAndRelatedStages(processor.name)}
      onMouseLeave={highlightCurrentEntity}
      {...active ?
          {}
        :
          {link: true, onClick: () => {
            setCurrentProcessorName(processor.name);
          }}
      }
    >
      <Step.Title>
        <ScrollAnchor entityName='processor step' id={processor.name} />
        <ProcessorIcon processorType={processor.type} />
        <EllipsisPopup
          content={processor.name}
          trigger={<span>{processor.name}</span>}
        />
      </Step.Title>
      {!!hasProcessorError &&
        <Label floating>
          <Icon name='warning sign' />
        </Label>
      }
    </Step>
  );
});
