import {Component} from 'react';
import {map, flatMap, uniq, find, some, isEmpty} from 'lodash';
import Ajv from 'ajv';
import {generatePropertyFromSchema} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import ImportModal, {ImportError} from '../../components/ImportModal';
import probeSchema from '../probeSchema';
import {getInputStageNames} from '../stageUtils';

export default class ProbeImportModal extends Component {
  static contextType = IBAContext;

  ajv = new Ajv({allErrors: true});

  validateAndNormalizeProbe = (parsedProbeContents) => {
    this.ajv.validate(probeSchema, parsedProbeContents);
    return map(this.ajv.errors, ({message, instancePath}) => `probe${instancePath}: ${message}`);
  };

  validateAndNormalizeProcessors = (parsedProbeContents) => {
    const {processorDefinitions} = this.context;
    return flatMap(parsedProbeContents.processors, (processor, processorIndex) => {
      const errorPrefix = `probe.processors[${processorIndex}]`;
      // validating type
      const processorDefinition = find(processorDefinitions, {name: processor.type});
      if (!processorDefinition) return [`${errorPrefix}.type: unknown type ${processor.type}`];
      // normalizing processor
      if (!processor.inputs) processor.inputs = {};
      if (!processor.outputs) processor.outputs = {};
      if (!processor.properties) processor.properties = {};
      // validating properties
      const valid = this.ajv.validate(processorDefinition.schema, processor.properties);
      if (!valid) {
        return uniq(map(this.ajv.errors, ({message, instancePath}) => `${errorPrefix}${instancePath}: ${message}`));
      }
      // validating inputs
      for (const inputName of getInputStageNames(processor)) {
        if (!some(parsedProbeContents.processors, (processor) =>
          some(processor.outputs, (outputName) => inputName === outputName)
        )) {
          return [`${errorPrefix}.inputs: unknown input ${inputName}`];
        }
      }
      // nomalizing properties - generating default values for optional and absent properties
      for (const propertyName of Object.keys(processorDefinition.schema.properties)) {
        if (!(propertyName in processor.properties)) {
          const propertySchema = processorDefinition.schema.properties[propertyName];
          processor.properties[propertyName] = generatePropertyFromSchema(propertySchema);
        }
      }
      return [];
    });
  };

  validateAndNormalizeStages = (parsedProbeContents) => {
    if (!parsedProbeContents.stages) parsedProbeContents.stages = [];
    for (const processor of parsedProbeContents.processors) {
      for (const stageName of Object.values(processor.outputs)) {
        if (!some(parsedProbeContents.stages, {name: stageName})) {
          parsedProbeContents.stages.push({name: stageName});
        }
      }
    }
    return [];
  };

  validateAndNormalizeProbeContents = (parsedProbeContents) => {
    for (const validator of [
      this.validateAndNormalizeProbe,
      this.validateAndNormalizeProcessors,
      this.validateAndNormalizeStages,
    ]) {
      const errors = validator(parsedProbeContents);
      if (!isEmpty(errors)) return errors;
    }
    return [];
  };

  import = (contents) => {
    const {probe} = this.props;
    let parsedProbeContents = null;
    try {
      parsedProbeContents = JSON.parse(contents);
    } catch (error) {
      throw new ImportError(`JSON parse error: ${error.toString()}`);
    }
    if (parsedProbeContents) {
      let errors = [];
      try {
        errors = this.validateAndNormalizeProbeContents(parsedProbeContents);
      } catch (error) {
        throw new ImportError(`Validation error: ${error.toString()}`);
      }
      if (errors.length) {
        throw new ImportError(errors);
      }
      probe.label = parsedProbeContents.label;
      probe.description = isEmpty(parsedProbeContents.description) ? '' : parsedProbeContents.description;
      probe.processors = parsedProbeContents.processors;
      probe.stages = parsedProbeContents.stages;
    }
  };

  render() {
    const {helpPageId, ...props} = this.props;
    delete props.probe;
    return (
      <ImportModal
        helpPageId={helpPageId}
        notifyOnSuccess={false}
        header='Import Probe'
        description={
          'Drag and drop probe file here, choose it by clicking the button or ' +
          'paste its contents in the field below.'
        }
        accept={{'application/json': [], 'text/plain': []}}
        import={this.import}
        {...props}
      />
    );
  }
}
