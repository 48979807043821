import {PureComponent} from 'react';
import {scaleLinear} from 'd3';
import {Axis} from '@visx/axis';
import {Group} from '@visx/group';
import cx from 'classnames';
import {isNil, map} from 'lodash';
import {formatNumber} from 'apstra-ui-common';

import './BulletChart.less';

export default class BulletChart extends PureComponent {
  static defaultProps = {
    units: '',
    width: 220,
    height: 80,
    margin: {top: 10, right: 10, bottom: 20, left: 10},
    numTicks: 5,
  };

  xScale = scaleLinear();

  formatValue = (value) => {
    const {units} = this.props;
    return formatNumber(value, {units, short: true});
  };

  render() {
    const {
      xScale,
      props: {
        headroomValue: value, speed, active,
        margin, width: chartWidth, height: chartHeight,
        colors, ranges, numTicks,
      }
    } = this;
    if (!active) {
      return 'Data not available';
    }
    if (isNil(value) || isNil(speed)) return null;

    const alignRight = value > speed / 4;
    const xMax = chartWidth - margin.left - margin.right;
    const scaleHeight = chartHeight - margin.top - margin.bottom;
    xScale.domain([0, speed]).rangeRound([0, xMax]);

    return (
      <svg className='iba-bullet-chart' width={chartWidth} height={chartHeight}>
        <Group top={margin.top} left={margin.left}>
          <defs>
            <linearGradient id='bullet-chart-gradient'>
              {map(colors, (color, index) =>
                <stop key={index} offset={`${ranges[index] * 100}%`} stopColor={color} />
              )}
            </linearGradient>
          </defs>
          <rect
            width={xScale(speed)}
            height={scaleHeight}
            fill={"url('#bullet-chart-gradient')"}
          />
        </Group>
        <Group
          className={cx('iba-bullet-chart-value', {'align-right': alignRight})}
          top={margin.top + scaleHeight / 4}
          left={margin.left}
        >
          <rect
            width={xScale(value)}
            height={scaleHeight / 2}
          />
          <text
            x={xScale(value)}
            y={scaleHeight / 4}
          >
            {this.formatValue(value)}
          </text>
        </Group>
        <Axis
          axisClassName='iba-bullet-axis'
          orientation='bottom'
          scale={xScale}
          left={margin.left}
          top={scaleHeight + margin.top}
          hideAxisLine
          numTicks={numTicks}
          tickFormat={(value) => formatNumber(value, {short: true})}
        />
      </svg>
    );
  }
}
