import {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {clamp} from 'lodash';
import {Input, Message} from 'semantic-ui-react';
import {Field} from 'apstra-ui-common';

import {useRackEditorStore} from '../hooks/useRackEditorStore';
import NodePropertiesStore from '../store/NodePropertiesStore';
import TagsControl from '../../components/TagsControl';
import AvailablePorts from './AvailablePorts';
import PortsCount from './PortsCount';
import NumberInput from './NumberInput';
import PortChannelIdRange from './PortChannelIdRange';
import {Checkbox, DropdownControl} from '../../exports';
import {REDUNDANCY, maxVlanId} from '../const';

import './NodeProperties.less';

const NodeProperties = ({nodes}) => {
  const {rackStore, selection} = useRackEditorStore();
  const {devices, knownTags, isL3Clos} = rackStore;

  // Mobx store creation on mount
  const [nodePropertiesStore] = useState(
    () => new NodePropertiesStore(rackStore, selection, nodes)
  );

  // Store re-initialization with props
  useEffect(
    () => nodePropertiesStore.init(nodes),
    [nodePropertiesStore, nodes]
  );

  const {
    deviceOptions, closeOnEnter, isSwitch, isBatch,
    noLogicalDevice, result, spinePorts, allMlagPairs,
    portChannelError, allPortChannelsSet, trackChange, changeRedundancy, setPortChannel,
    canSwitchToMlag, canSwitchToEsi, maxSpineLinks, spinePortsExist
  } = nodePropertiesStore;

  const {
    role, redundancyProtocol, _label, logicalDevice, tags, mlagVlanId,
    spineLinksCount, spineLinksSpeed, labelNotUnique, isLeaf
  } = result || {};

  return (
    <div className='re-node-properties'>
      <Field
        required
        label='Label'
      >
        <Input
          fluid
          value={_label || ''}
          onChange={(event, {value}) => trackChange('_label', value)}
          onKeyDown={closeOnEnter}
          error={labelNotUnique}
          maxLength={64}
        />
      </Field>
      {!isBatch && <AvailablePorts node={result} />}
      <Field
        required
        label='Logical Device'
      >
        <DropdownControl
          fluid
          selection
          search
          value={logicalDevice?.id}
          onChange={(value) => trackChange('logicalDevice', devices[value])}
          options={deviceOptions}
          onKeyDown={closeOnEnter}
          placeholder={(isBatch && !logicalDevice) ? 'Multiple values' : 'Not set'}
        />
      </Field>
      <Field label='Tags'>
        <TagsControl
          className='re-node-tags'
          fluid
          value={tags}
          onChange={(value) => trackChange('tags', [...value])}
          knownTags={knownTags}
        />
      </Field>
      {
        isLeaf && isL3Clos &&
          <div className='spine-links'>
            <h6 className='required'>{'Links per spine'}</h6>
            {spinePortsExist &&
              <div>
                <PortsCount
                  portsBySpeed={spinePorts}
                  required
                  showEmpty
                  selectedSpeed={spineLinksSpeed}
                  error={!spineLinksSpeed}
                  onClick={(speed, speedString) => {
                    trackChange(
                      'spineLinksCount',
                      clamp(spineLinksCount, 0, spinePorts[speedString] || 0)
                    );
                    trackChange('spineLinksSpeed', speedString);
                  }}
                  usedCount={+spineLinksCount}
                />
                <Field
                  className='count'
                  required
                  label='Count'
                  disabled={!spineLinksSpeed}
                >
                  <NumberInput
                    type='number'
                    fluid
                    value={+spineLinksCount || 0}
                    min={0}
                    max={maxSpineLinks}
                    onChange={(event, {value}) => trackChange(
                      'spineLinksCount',
                      clamp(value, 0, maxSpineLinks)
                    )}
                    error={!spineLinksCount}
                  />
                </Field>
              </div>
            }
            {!spinePortsExist &&
              <Message error content={'No free ports with role "spine"'} />
            }
          </div>
      }
      {
        (isSwitch && !isBatch) &&
          <Field label='Redundancy Protocol' required className='redundancies'>
            <Checkbox
              radio
              name='redundancy'
              label='None'
              checked={!redundancyProtocol}
              onChange={changeRedundancy}
              disabled={noLogicalDevice}
            />
            {isLeaf && isL3Clos &&
              <Checkbox
                radio
                name='redundancy'
                label='MLAG'
                value={REDUNDANCY.MLAG}
                checked={redundancyProtocol === REDUNDANCY.MLAG}
                onChange={changeRedundancy}
                disabled={!canSwitchToMlag}
              />
            }
            <Checkbox
              radio
              name='redundancy'
              label='ESI'
              value={REDUNDANCY.ESI}
              checked={redundancyProtocol === REDUNDANCY.ESI}
              onChange={changeRedundancy}
              disabled={!canSwitchToEsi}
            />
          </Field>
      }
      {
        allMlagPairs &&
          <Field label='MLAG Keepalive VLAN ID' required>
            <NumberInput
              type='number'
              fluid
              value={mlagVlanId}
              min={0}
              max={maxVlanId}
              onChange={(event, {value}) => trackChange('mlagVlanId', value, true)}
              error={!mlagVlanId}
            />
          </Field>
      }
      {
        role && !isSwitch &&
          <>
            {isBatch &&
              <>
                <label>{'Port Channel IDs Range:'}</label>
                {!allPortChannelsSet &&
                  <Message error content='Insufficient range' />
                }
              </>
            }
            <div className='re-port-channel-range'>
              <PortChannelIdRange
                asRange={isBatch}
                node={result}
                onChange={setPortChannel}
                error={portChannelError || !allPortChannelsSet}
              />
            </div>
          </>
      }
    </div>
  );
};

export default observer(NodeProperties);
