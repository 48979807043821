import {Lexer, CstParser, createToken} from 'chevrotain';

function matchText(text, startOffset) {
  let endOffset = startOffset;
  let currentChar = text.charAt(endOffset);
  while (endOffset < text.length) {
    if (currentChar === '{' && ['{', '%', '#'].includes(text.charAt(endOffset + 1))) break;
    endOffset++;
    currentChar = text.charAt(endOffset);
  }

  if (endOffset === startOffset) return null;
  return [text.substring(startOffset, endOffset)];
}

export const Text = createToken({name: 'Text', pattern: matchText, line_breaks: true});
export const Comment = createToken({name: 'Comment', pattern: /{#-?[\S\s]*?-?#}/, line_breaks: true});
export const Identifier = createToken({name: 'Identifier', pattern: /[a-zA-Z_][a-zA-Z0-9_]*/});
export const LDelimiter = createToken({name: 'LDelimiter', pattern: /{%-?/, push_mode: 'INSIDE'});
export const RDelimiter = createToken({name: 'RDelimiter', pattern: /-?%}/, pop_mode: true});
export const LDoubleCurly = createToken({name: 'LDoubleCurly', pattern: /{{/, push_mode: 'INSIDE'});
export const RDoubleCurly = createToken({name: 'RDoubleCurly', pattern: /}}/, pop_mode: true});
export const InfixOperator = createToken({
  name: 'InfixOperator',
  pattern: /(\bor\b|\band\b|\bnot\s+in\b|\bis\s+not\b|\bis\b|\bin\b|==|!=|>=|<=|<|>|\/\/?|%|\|)/,
});
export const For = createToken({name: 'For', pattern: /{%-?\s*\bfor\b/, push_mode: 'INSIDE'});
export const EndFor = createToken({name: 'EndFor', pattern: /\bendfor\b/});
export const If = createToken({name: 'If', pattern: /{%-?\s*\bif\b/, push_mode: 'INSIDE'});
export const InternalIf = createToken({name: 'InternalIf', pattern: /\bif\b/});
export const EndIf = createToken({name: 'EndIf', pattern: /\bendif\b/});
export const Elif = createToken({name: 'Elif', pattern: /\belif\b/});
export const Else = createToken({name: 'Else', pattern: /\belse\b/});
export const Not = createToken({name: 'Not', pattern: /\bnot\b/});
export const Equals = createToken({name: 'Equals', pattern: '='});
export const Asterisk = createToken({name: 'Asterisk', pattern: '*'});
export const DoubleAsterisk = createToken({name: 'DoubleAsterisk', pattern: '**'});
export const Plus = createToken({name: 'Plus', pattern: '+'});
export const Minus = createToken({name: 'Minus', pattern: '-'});
export const Tilde = createToken({name: 'Tilde', pattern: '~'});
export const Set = createToken({name: 'Set', pattern: /{%-?\s*\bset\b/, push_mode: 'INSIDE'});
export const Include = createToken({name: 'Include', pattern: /{%-?\s*\binclude\b/, push_mode: 'INSIDE'});
export const Ignore = createToken({name: 'Ignore', pattern: /\bignore\b/});
export const Missing = createToken({name: 'Missing', pattern: /\bmissing\b/});
export const Do = createToken({name: 'Do', pattern: /{%-?\s*\bdo\b/, push_mode: 'INSIDE'});
export const Break = createToken({name: 'Break', pattern: /{%-?\s*\bbreak\b/, push_mode: 'INSIDE'});
export const Continue = createToken({name: 'Continue', pattern: /{%-?\s*\bcontinue\b/, push_mode: 'INSIDE'});
export const Now = createToken({name: 'Now', pattern: /{%-?\s*\bnow\b/, push_mode: 'INSIDE'});
export const Debug = createToken({name: 'Debug', pattern: /{%-?\s*\bdebug\b/, push_mode: 'INSIDE'});
export const Filter = createToken({name: 'Filter', pattern: /{%-?\s*\bfilter\b/, push_mode: 'INSIDE'});
export const EndFilter = createToken({name: 'Filter', pattern: /\bendfilter\b/});
export const Macro = createToken({name: 'Macro', pattern: /{%-?\s*\bmacro\b/, push_mode: 'INSIDE'});
export const EndMacro = createToken({name: 'EndMacro', pattern: /\bendmacro\b/});
export const Block = createToken({name: 'Block', pattern: /{%-?\s*\bblock\b/, push_mode: 'INSIDE'});
export const EndBlock = createToken({name: 'EndBlock', pattern: /\bendblock\b/});
export const Scoped = createToken({name: 'Scoped', pattern: /\bscoped\b/});
export const Required = createToken({name: 'Required', pattern: /\brequired\b/});
export const Call = createToken({name: 'Call', pattern: /{%-?\s*\bcall\b/, push_mode: 'INSIDE'});
export const EndCall = createToken({name: 'EndCall', pattern: /\bendcall\b/});
export const Raw = createToken({name: 'Raw', pattern: /{%-?\s*\braw\b/, push_mode: 'INSIDE'});
export const EndRaw = createToken({name: 'EndRaw', pattern: /\bendraw\b/});
export const In = createToken({name: 'In', pattern: /\bin\b/});
export const WhiteSpace = createToken({name: 'WhiteSpace', pattern: /\s+/, group: Lexer.SKIPPED});
export const Comma = createToken({name: 'Comma', pattern: ','});
export const Dot = createToken({name: 'Dot', pattern: '.'});
export const Colon = createToken({name: 'Colon', pattern: ':'});
export const LParen = createToken({name: 'LParen', pattern: '('});
export const RParen = createToken({name: 'RParen', pattern: ')'});
export const LCurly = createToken({name: 'LCurly', pattern: '{'});
export const RCurly = createToken({name: 'RCurly', pattern: '}'});
export const LSquare = createToken({name: 'LSquare', pattern: '['});
export const RSquare = createToken({name: 'RSquare', pattern: ']'});
export const Bool = createToken({name: 'Bool', pattern: /true|false/});
export const NumberLiteral = createToken({name: 'NumberLiteral', pattern: /-?\d+(\.\d+)?/});
export const SingleQuoteStringLiteral = createToken({
  name: 'SingleQuoteStringLiteral',
  pattern: /'(:?[^\\\n\r']+|\\(:?[nr\\/']))*'/,
});
export const DoubleQuoteStringLiteral = createToken({
  name: 'DoubleQuoteStringLiteral',
  pattern: /"(:?[^\\\n\r"]+|\\(:?[nr\\/"]))*"/,
});

export const lexerDefinition = {
  defaultMode: 'OUTSIDE',
  modes: {
    OUTSIDE: [
      Comment, If, Do, Now, Debug, For, Break, Continue, Set, Filter, Macro, Block, Call, Raw, Include,
      LDoubleCurly, LDelimiter, Text,
    ],
    INSIDE: [
      WhiteSpace, Comma, LParen, RParen, LSquare, RSquare, RDelimiter, RDoubleCurly, EndFor, InternalIf, EndIf, Elif,
      Else, EndFilter, EndMacro, EndBlock, EndCall, EndRaw, In, NumberLiteral, SingleQuoteStringLiteral,
      DoubleQuoteStringLiteral, Bool, InfixOperator, Equals, DoubleAsterisk, Asterisk, Colon, Dot, LCurly,
      Minus, Not, Plus, RCurly, Tilde, Required, Scoped, Ignore, Missing, Identifier,
    ]
  }
};

export const JinjaLexer = new Lexer(lexerDefinition, {
  positionTracking: 'full',
  ensureOptimizations: false,
});

let parserInstance = null;

export default class JinjaParser extends CstParser {
  static get instance() {
    if (!parserInstance) parserInstance = new this();
    return parserInstance;
  }

  static parse(text) {
    const lexResult = JinjaLexer.tokenize(text);
    const parser = this.instance;
    parser.input = lexResult.tokens;
    const cst = parser.template();
    return {cst, lexErrors: lexResult.errors, parseErrors: parser.errors};
  }

  constructor() {
    super(lexerDefinition, {nodeLocationTracking: 'full'});
    const $ = this;

    $.RULE('template', () => {
      $.OPTION(() => {
        $.CONSUME(Text);
      });

      $.MANY(() => {
        $.OR([
          {ALT: () => $.SUBRULE($.forStatement)},
          {ALT: () => $.SUBRULE($.ifStatement)},
          {ALT: () => $.SUBRULE($.printExpression)},
          {ALT: () => $.SUBRULE($.macroStatement)},
          {ALT: () => $.SUBRULE($.blockStatement)},
          {ALT: () => $.SUBRULE($.callStatement)},
          {ALT: () => $.SUBRULE($.filterStatement)},
          {ALT: () => $.SUBRULE($.set)},
          {ALT: () => $.SUBRULE($.include)},
          {ALT: () => $.SUBRULE($.do)},
          {ALT: () => $.SUBRULE($.break)},
          {ALT: () => $.SUBRULE($.continue)},
          {ALT: () => $.SUBRULE($.now)},
          {ALT: () => $.SUBRULE($.debug)},
          {ALT: () => $.CONSUME(Comment)},
        ]);

        $.OPTION2(() => {
          $.CONSUME2(Text);
        });
      });
    });

    $.RULE('printExpression', () => {
      $.CONSUME(LDoubleCurly);
      $.SUBRULE($.expression);
      $.CONSUME(RDoubleCurly);
    });

    $.RULE('else', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(Else);
      $.CONSUME(RDelimiter);
    });

    $.RULE('loop', () => {
      $.AT_LEAST_ONE_SEP({
        SEP: Comma,
        DEF: () => {
          $.CONSUME2(Identifier);
        }
      });
      $.CONSUME(In);
      $.SUBRULE($.expression);
    });

    $.RULE('for', () => {
      $.CONSUME(For);
      $.SUBRULE($.loop);
      $.OPTION(() => {
        $.CONSUME2(InternalIf);
        $.SUBRULE2($.expression);
      });
      $.CONSUME(RDelimiter);
    });

    $.RULE('endFor', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(EndFor);
      $.CONSUME(RDelimiter);
    });

    $.RULE('forStatement', () => {
      $.SUBRULE($.for);
      $.SUBRULE($.template);
      $.OPTION(() => {
        $.SUBRULE($.else);
        $.SUBRULE2($.template);
      });
      $.SUBRULE($.endFor);
    });

    $.RULE('if', () => {
      $.CONSUME(If);
      $.SUBRULE($.expression);
      $.CONSUME(RDelimiter);
    });

    $.RULE('endIf', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(EndIf);
      $.CONSUME(RDelimiter);
    });

    $.RULE('elIf', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(Elif);
      $.SUBRULE($.expression);
      $.CONSUME(RDelimiter);
    });

    $.RULE('ifStatement', () => {
      $.SUBRULE($.if);
      $.SUBRULE($.template);
      $.MANY(() => {
        $.SUBRULE($.elIf);
        $.SUBRULE2($.template);
      });
      $.OPTION(() => {
        $.SUBRULE2($.else);
        $.SUBRULE3($.template);
      });
      $.SUBRULE($.endIf);
    });

    $.RULE('macro', () => {
      $.CONSUME(Macro);
      $.SUBRULE($.expression);
      $.CONSUME(RDelimiter);
    });

    $.RULE('endMacro', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(EndMacro);
      $.CONSUME(RDelimiter);
    });

    $.RULE('macroStatement', () => {
      $.SUBRULE($.macro);
      $.SUBRULE($.template);
      $.SUBRULE($.endMacro);
    });

    $.RULE('range', () => {
      $.OPTION(() => {
        $.CONSUME(NumberLiteral);
      });
      $.CONSUME(Colon);
      $.OPTION2(() => {
        $.CONSUME2(NumberLiteral);
      });
    });

    $.RULE('block', () => {
      $.CONSUME(Block);
      $.CONSUME(Identifier);
      $.OPTION(() => {
        $.CONSUME2(Scoped);
      });
      $.OPTION2(() => {
        $.CONSUME3(Required);
      });
      $.CONSUME(RDelimiter);
    });

    $.RULE('endBlock', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(EndBlock);
      $.OPTION(() => {
        $.CONSUME2(Identifier);
      });
      $.CONSUME(RDelimiter);
    });

    $.RULE('blockStatement', () => {
      $.SUBRULE($.block);
      $.SUBRULE($.template);
      $.SUBRULE($.endBlock);
    });

    $.RULE('call', () => {
      $.CONSUME(Call);
      $.OPTION(() => {
        $.SUBRULE2($.expression);
      });
      $.SUBRULE($.expression);
      $.CONSUME(RDelimiter);
    });

    $.RULE('endCall', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(EndCall);
      $.CONSUME(RDelimiter);
    });

    $.RULE('callStatement', () => {
      $.SUBRULE($.call);
      $.SUBRULE($.template);
      $.SUBRULE($.endCall);
    });

    $.RULE('filter', () => {
      $.CONSUME(Filter);
      $.CONSUME(Identifier);
      $.CONSUME(RDelimiter);
    });

    $.RULE('endFilter', () => {
      $.CONSUME(LDelimiter);
      $.CONSUME(EndFilter);
      $.CONSUME(RDelimiter);
    });

    $.RULE('filterStatement', () => {
      $.SUBRULE($.filter);
      $.SUBRULE($.template);
      $.SUBRULE($.endFilter);
    });

    $.RULE('set', () => {
      $.CONSUME(Set);
      $.AT_LEAST_ONE_SEP({
        SEP: Comma,
        DEF: () => {
          $.SUBRULE2($.expression);
        }
      });
      $.CONSUME(Equals);
      $.SUBRULE($.expression);
      $.CONSUME(RDelimiter);
    });

    $.RULE('include', () => {
      $.CONSUME(Include);
      $.SUBRULE($.string);
      $.OPTION(() => {
        $.CONSUME(Ignore);
        $.CONSUME(Missing);
      });
      $.CONSUME(RDelimiter);
    });

    $.RULE('do', () => {
      $.CONSUME(Do);
      $.SUBRULE($.expression);
      $.CONSUME(RDelimiter);
    });

    $.RULE('break', () => {
      $.CONSUME(Break);
      $.CONSUME(RDelimiter);
    });

    $.RULE('continue', () => {
      $.CONSUME(Continue);
      $.CONSUME(RDelimiter);
    });

    $.RULE('now', () => {
      $.CONSUME(Now);
      $.CONSUME(RDelimiter);
    });

    $.RULE('debug', () => {
      $.CONSUME(Debug);
      $.CONSUME(RDelimiter);
    });

    $.RULE('expression', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.prefixOperatorExpression)},
        {ALT: () => $.SUBRULE($.functionCall)},
        {ALT: () => $.SUBRULE($.list)},
        {ALT: () => $.SUBRULE($.dict)},
        {ALT: () => $.SUBRULE($.parenWrappedExpressionOrTuple)},
        {ALT: () => $.SUBRULE($.range)},
        {ALT: () => $.CONSUME(NumberLiteral)},
        {ALT: () => $.SUBRULE($.string)},
        {ALT: () => $.CONSUME(Identifier)},
      ]);
      $.OPTION(() => $.SUBRULE($.chainedExpression));
      $.OPTION2(() => $.SUBRULE($.indexingOrSlicingChain));
      $.OPTION3(() => $.SUBRULE($.infixOperatorExpression));
    });

    $.RULE('chainedExpression', () => {
      $.CONSUME(Dot);
      $.SUBRULE($.expression);
    });

    $.RULE('indexingOrSlicingChain', () => {
      $.AT_LEAST_ONE(() => {
        $.CONSUME(LSquare);
        $.SUBRULE($.expression);
        $.CONSUME(RSquare);
      });
      $.OPTION(() => $.SUBRULE($.chainedExpression));
    });

    $.RULE('infixOperatorExpression', () => {
      $.SUBRULE($.infixOperator);
      $.SUBRULE($.expression);
    });

    $.RULE('infixOperator', () => {
      $.OR([
        {ALT: () => $.CONSUME(InfixOperator)},
        {ALT: () => $.CONSUME(Plus)},
        {ALT: () => $.CONSUME(Minus)},
        {ALT: () => $.CONSUME(DoubleAsterisk)},
        {ALT: () => $.CONSUME(Asterisk)},
        {ALT: () => $.CONSUME(In)},
      ]);
    });

    $.RULE('prefixOperatorExpression', () => {
      $.SUBRULE($.prefixOperator);
      $.SUBRULE($.expression);
    });

    $.RULE('prefixOperator', () => {
      $.OR([
        {ALT: () => $.CONSUME(Not)},
        {ALT: () => $.CONSUME(Plus)},
        {ALT: () => $.CONSUME(Minus)},
        {ALT: () => $.CONSUME(Tilde)},
      ]);
    });

    $.RULE('string', () => {
      $.OR([
        {ALT: () => $.CONSUME(SingleQuoteStringLiteral)},
        {ALT: () => $.CONSUME(DoubleQuoteStringLiteral)},
      ]);
    });

    $.RULE('parenWrappedExpressionOrTuple', () => {
      $.CONSUME(LParen);
      $.OPTION(() => {
        $.SUBRULE($.expression);
        $.OPTION2(() => {
          $.CONSUME(Comma, {LABEL: 'tupleDistinguishingComma'});
          $.MANY(() => {
            $.SUBRULE2($.expression, {LABEL: 'restTupleExpressions'});
            $.OPTION3(() => $.CONSUME2(Comma));
          });
        });
      });
      $.CONSUME(RParen);
    });

    $.RULE('list', () => {
      $.CONSUME(LSquare);
      $.OPTION(() => {
        $.SUBRULE($.expression);
        $.MANY(() => {
          $.CONSUME(Comma);
          $.SUBRULE2($.expression);
        });
        $.OPTION2(() => {
          $.CONSUME2(Comma);
        });
      });
      $.CONSUME(RSquare);
    });
    $.RULE('keyValuePair', () => {
      $.SUBRULE($.expression, {LABEL: 'key'});
      $.CONSUME(Colon);
      $.SUBRULE2($.expression, {LABEL: 'value'});
    });
    $.RULE('dict', () => {
      $.CONSUME(LCurly);
      $.OPTION(() => {
        $.SUBRULE($.keyValuePair);
        $.MANY(() => {
          $.CONSUME(Comma);
          $.SUBRULE2($.keyValuePair);
        });
        $.OPTION2(() => $.CONSUME2(Comma));
      });
      $.CONSUME(RCurly);
    });
    $.RULE('functionCall', () => {
      $.CONSUME(Identifier, {LABEL: 'functionName'});
      $.CONSUME(LParen);
      $.SUBRULE($.functionArguments);
      $.CONSUME(RParen);
    });
    $.RULE('functionArguments', () => {
      $.OPTION(() => {
        $.SUBRULE($.functionArgument);
        $.MANY(() => {
          $.CONSUME(Comma);
          $.SUBRULE2($.functionArgument);
        });
        $.OPTION2(() => $.CONSUME2(Comma));
      });
    });
    $.RULE('functionArgument', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.keywordArgument)},
        {ALT: () => $.SUBRULE($.positionalArgument)},
      ]);
    });
    $.RULE('positionalArgument', () => {
      $.SUBRULE($.expression);
    });
    $.RULE('keywordArgument', () => {
      $.CONSUME(Identifier, {LABEL: 'key'});
      $.CONSUME(Equals);
      $.SUBRULE($.expression, {LABEL: 'value'});
    });

    $.performSelfAnalysis();
  }
}
