import {Table} from 'semantic-ui-react';
import {useMemo} from 'react';
import {max, map, range} from 'lodash';

import './ReportTable.less';

const ReportTable = ({column_names: columnNames, columns}) => {
  const rowCount = useMemo(() => {
    return max(map(columns, (column) => column?.length));
  }, [columns]);
  return (
    <div className='report-table'>
      <Table celled compact='very' size='small'>
        <Table.Header>
          <Table.Row>
            {map(columnNames, (name, i) => (
              <Table.HeaderCell key={i}>{name}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {map(range(rowCount), (i) => (
            <Table.Row key={`row-${i}`}>
              {map(columns, (column, j) => (
                <Table.Cell key={`column-${j}`}>
                  {column[i] || ''}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ReportTable;
