import {Component} from 'react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Axis} from '@visx/axis';
import {Group} from '@visx/group';
import {scaleOrdinal, scaleTime} from 'd3';
import {map, transform, constant} from 'lodash';
import {formatDateAsLocalTime} from 'apstra-ui-common';

import './DiscreteStateTimeline.less';

@observer
export default class DiscreteStateTimeline extends Component {
  static defaultProps = {
    mode: 'compact',
    possibleValues: [],
    dimensions: {
      compact: {
        height: 40,
        margin: {top: 1, right: 1, bottom: 1, left: 1}
      },
      expanded: {
        height: 100,
        margin: {top: 1, right: 1, bottom: 30, left: 1}
      },
    },
    colors: ['green', 'red'],
    valueKeyName: 'value',
  };

  colorScale = scaleOrdinal(this.props.colors);
  xScale = scaleTime();

  @observable.ref possibleValuesTextLengths = null;

  @action
  computeTextLengths = (node) => {
    if (node && !this.possibleValuesTextLengths) {
      this.possibleValuesTextLengths = transform(this.props.possibleValues, (result, value) => {
        node.textContent = value;
        result[value] = Math.ceil(node.getComputedTextLength());
      }, {});
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {
      colorScale, xScale,
      possibleValuesTextLengths, computeTextLengths,
      props: {
        mode, dimensions,
        samples, sampleTimes, possibleValues,
        width: chartWidth, numTicksColumns,
        showPopup, hidePopup,
        timelineStartTime, timelineEndTime,
        valueKeyName,
      }
    } = this;
    const {height: chartHeight, margin} = dimensions[mode];
    colorScale.domain(possibleValues);
    const xMax = chartWidth - margin.left - margin.right;
    const barHeight = chartHeight - margin.top - margin.bottom;
    xScale.domain([timelineStartTime, timelineEndTime]).rangeRound([0, xMax]);
    return (
      <svg className='discrete-state-timeline' width={chartWidth} height={chartHeight}>
        <Group top={margin.top} left={margin.left}>
          {chartWidth > 0 && map(samples, (sample, sampleIndex) => {
            const sampleTime = sampleTimes[sampleIndex];
            const nextSampleTime = sampleTimes[sampleIndex + 1] ?? null;
            const x = xScale(sampleTime);
            const barWidth = (nextSampleTime ? xScale(nextSampleTime) : xMax) - x;
            return barWidth ? (
              <Group
                key={sample.timestamp}
                className={`graph-color-${colorScale(sample[valueKeyName])}`}
                left={x}
              >
                <rect
                  x={0}
                  y={0}
                  width={barWidth}
                  height={barHeight}
                  strokeDasharray={nextSampleTime ? `${barWidth} ${barHeight} ${barWidth + barHeight}` : undefined}
                  onMouseEnter={(e) => showPopup({
                    node: e.target,
                    timestamp: sample.timestamp,
                    header: sample[valueKeyName],
                  })}
                  onMouseLeave={hidePopup}
                />
                {possibleValuesTextLengths && possibleValuesTextLengths[sample.value] < barWidth &&
                  <text
                    x={barWidth / 2}
                    y={barHeight / 2}
                  >
                    {sample[valueKeyName]}
                  </text>
                }
              </Group>
            ) : null;
          })}
        </Group>
        {mode === 'expanded' && chartWidth > 0 &&
          <Axis
            axisClassName='timeline-axis axis-bottom'
            orientation='bottom'
            top={barHeight + margin.top}
            left={margin.left}
            scale={xScale}
            tickFormat={formatDateAsLocalTime}
            tickLabelProps={constant({})}
            numTicks={numTicksColumns}
            hideAxisLine
          />
        }
        {!possibleValuesTextLengths &&
          <text ref={computeTextLengths} />
        }
      </svg>
    );
  }
}
