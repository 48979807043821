import {useState, useRef, useEffect} from 'react';
import {observer} from 'mobx-react';
import {Form, Input, TextArea, Icon, Accordion, Tab, Label, Divider} from 'semantic-ui-react';
import {chain, castArray, trim, map, toLower} from 'lodash';
import {action} from 'mobx';
import {Field, FuzzySearchBox} from 'apstra-ui-common';

import EptParameters from './EptParameters';
import TagsControl from '../../components/TagsControl';
import {isNotVnType} from '../utils';

import './Parameters.less';

const Parameters = ({ept, allTags, readonly, vnOnly}) => {
  const [isExpanded, setExpanded] = useState(true);
  const activeEptRef = useRef();

  const activeId = ept.activePrimitiveId;
  useEffect(() => {
    if (activeId && activeEptRef.current) {
      activeEptRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [activeId]);

  const handlePropertyChange = action((name, value) => {
    ept[name] = value;
    ept.hasOwnChanges = true;
  });

  const searchString = toLower(ept.searchString);
  const isSearching = searchString !== '';

  // Close Summary tab when user starts searching.
  // If the only EPT has been found - open its parameters
  useEffect(() => {
    if (isSearching) {
      setExpanded(false);
      if (ept.singleMatchId) ept.setActivePrimitive(ept.singleMatchId);
    }
  }, [isSearching, ept.singleMatchId, ept]);

  const ownProperties = readonly ?
    <>
      <h5>{ept.title}</h5>
      <p>{ept.description}</p>
      <div className='tags'>
        {
          map(
            castArray(ept.tags),
            (tag) => <Label key={tag} content={tag} size='small' icon='tag' />
          )
        }
      </div>
    </> :
    <>
      <Accordion.Title
        as='button'
        active={isExpanded}
        onClick={() => setExpanded(!isExpanded)}
        aria-label='Summary'
      >
        <Icon name='dropdown' />
        {'Summary'}
      </Accordion.Title>
      <Accordion.Content active={isExpanded}>
        <Field
          required
          label='Name'
          errors={!trim(ept.title) ? ['Name is required'] : []}
        >
          <Input
            value={ept.title}
            onChange={({target}) => handlePropertyChange('title', target?.value)}
          />
        </Field>
        <Field label='Description'>
          <TextArea
            value={ept.description}
            onChange={({target}) => handlePropertyChange('description', target?.value)}
          />
        </Field>
        <Field label='Tags'>
          <TagsControl
            knownTags={allTags}
            onChange={(tags) => handlePropertyChange('tags', tags)}
            value={ept.tags}
          />
        </Field>
      </Accordion.Content>
    </>;

  return (
    <Tab.Pane>
      <Form>
        <FuzzySearchBox value={ept.searchString} onChange={ept.searchOnChange} />
        <Divider />
        <Accordion className='cts-parameters'>
          {ownProperties}
          {chain(ept.epts)
            .filter((singleEpt) => singleEpt.editable && ept.eptsMatched[singleEpt.id])
            .sortBy('creationOrder')
            .map((singleEpt) => {
              return (
                <EptParameters
                  key={singleEpt.id}
                  {...{
                    ref: activeId === singleEpt.id ? activeEptRef : null,
                    activeEpt: ept,
                    ept: singleEpt,
                    readonly: readonly || (vnOnly && isNotVnType({type: singleEpt.dbType}))
                  }}
                />
              );
            })
            .value()
          }
        </Accordion>
      </Form>
    </Tab.Pane>
  );
};

export default observer(Parameters);
