import {FC, useEffect, useRef} from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';

import {useTooltip} from './TooltipProvider';

import './TooltipPopup.less';

export const TooltipPopup: FC = observer(() => {
  const {containerRef, sharedTooltip} = useTooltip();

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sharedTooltip.isOpen) {
      contentRef.current?.focus({preventScroll: true});
    }
  }, [sharedTooltip.isOpen]);

  return (
    <Popup
      className='topology-tooltip-popup'
      size='small'
      wide
      onMouseOver={sharedTooltip.cancelHideTooltip}
      onMouseOut={sharedTooltip.hide}
      context={containerRef}
      content={
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          ref={contentRef}
          className='tooltip-content'
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              sharedTooltip.hideImmediately();
            }
          }}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          <Icon
            link
            className='close-button'
            size='small'
            name='close'
            onClick={sharedTooltip.hideImmediately}
            aria-label='Close tooltip'
          />
          {sharedTooltip.content}
        </div>
      }
      offset={toJS(sharedTooltip.offset) as any}
      open={sharedTooltip.isOpen}
      inverted={sharedTooltip.isInverted}
      mountNode={sharedTooltip?.mountRef?.current}
    />
  );
});
