import {Component, useState} from 'react';
import PropTypes from 'prop-types';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';

import {find, filter, map} from 'lodash';
import {Checkbox, DropdownControl} from 'apstra-ui-common';

import {linkPropTypes, nodePropTypes, aggregateLinkPropTypes} from './shared-prop-types';
import {PORT_ROLE_LABELS} from '../../../../portConsts';

import Link from './Link';
import Node from './Node';
import AggregateLink from './AggregateLink';
import {TooltipPopup, TooltipProvider} from '../../GraphTooltips';

import './NeighborsView.less';

const DEFAULT_NEIGHBORS_ROLE_FILTER = 'all';

@observer
export class NeighborsView extends Component {
  static propTypes = {
    blueprintId: PropTypes.string,
    styles: PropTypes.object,
    chartWidth: PropTypes.number.isRequired,
    linksWidth: PropTypes.number.isRequired,
    nodeWidth: PropTypes.number.isRequired,

    links: PropTypes.arrayOf(
      PropTypes.shape(linkPropTypes)
    ).isRequired,

    node: PropTypes.shape(nodePropTypes),

    neighbors: PropTypes.arrayOf(
      PropTypes.shape(nodePropTypes)
    ).isRequired,

    aggregateLinks: PropTypes.arrayOf(
      PropTypes.shape(aggregateLinkPropTypes)
    ),
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get neighborsRoleOptions() {
    return [{key: DEFAULT_NEIGHBORS_ROLE_FILTER, value: DEFAULT_NEIGHBORS_ROLE_FILTER, text: 'All Neighbors'}]
      .concat(
        this.props.neighborsRoles?.sort()
          .map((role) => ({key: role, value: role, text: PORT_ROLE_LABELS[role] ?? role}))
      );
  }

  render() {
    const {
      chartWidth,
      showUnusedPorts, onShowUnusedPortsChange, showUnusedPortsAvailable,
      showAggregateLinks, onShowAggregateLinksChange, showAggregateLinksAvailable,
      neighborsRoleFilter, onNeighborsRoleFilterChange, className
    } = this.props;

    return (
      <div className={className}>
        <div style={{width: chartWidth}} className='neighbors-view-filter'>
          {showAggregateLinksAvailable &&
            <Checkbox
              label='Show Aggregate Links'
              checked={showAggregateLinks}
              onChange={() => onShowAggregateLinksChange(!showAggregateLinks)}
            />
          }
          <div className='space' />
          {showUnusedPortsAvailable &&
            <Checkbox
              label='Show Unused Ports'
              checked={showUnusedPorts}
              onChange={() => onShowUnusedPortsChange(!showUnusedPorts)}
            />
          }
          <div className='space' />
          {!!onNeighborsRoleFilterChange &&
            <div>
              {'Show '}
              <DropdownControl
                inline
                aria-label='Filter neighbors by role'
                selection={false}
                options={this.neighborsRoleOptions}
                value={neighborsRoleFilter ?? DEFAULT_NEIGHBORS_ROLE_FILTER}
                onChange={(value) => onNeighborsRoleFilterChange(
                  value === DEFAULT_NEIGHBORS_ROLE_FILTER ? null : value
                )}
              />
            </div>
          }
        </div>
        <TooltipProvider layer=''>
          <NeighborsViewContent {...this.props} />
        </TooltipProvider>
      </div>
    );
  }
}

const NeighborsViewContent = observer((props) => {
  const [hoveredIf, setHoveredIf] = useState(null);
  const [hoveredPC, setHoveredPC] = useState(null);
  const {
    chartWidth, linksWidth, nodeWidth, styles, blueprintId, node, links, neighbors,
    aggregateLinks, showAggregateLinks, interfaceSelection,
    onSelectInterface, linkSelection, highlightedInterfaces, onSelectNode, nodeSelected, onSelectAggregate,
    nodeMenuOpen, unusedInterfaceSelection
  } = props;
  const chartStyle = {...styles, width: chartWidth};
  const linksStyle = {width: linksWidth};
  const nodeContainerStyle = {width: nodeWidth};

  function linkContainsInterface(ifId, nodeId, link) {
    const nodeIf = link.nodeInterfaceInfo.interfaceId;
    return nodeIf === ifId;
  }

  function selectAggregate(aggregateId, ref) {
    const aggregate = find(aggregateLinks, ['id', aggregateId]);
    const linkIds = aggregate?.linkIds;
    const aggLinks = filter(links, (l) => linkIds.indexOf(l.sourceLink.id) !== -1);
    const interfaces = map(aggLinks, (l) => l.nodeInterfaceInfo.interfaceId);
    onSelectAggregate(blueprintId, node.id, interfaces, ref);
  }

  const hoveredLink = find(links, (l) => linkContainsInterface(hoveredIf, node.id, l));
  return (
    <div
      className='neighbors-view clear'
      style={chartStyle}
    >
      <TooltipPopup />
      <div className='main-node-container' style={nodeContainerStyle}>
        <Node
          blueprintId={blueprintId}
          {...node}
          interfaceSelection={interfaceSelection}
          unusedInterfaceSelection={unusedInterfaceSelection}
          onSelectInterface={onSelectInterface}
          onSelectNode={onSelectNode}
          nodeSelected={nodeSelected}
          setHoveredIf={setHoveredIf}
          nodeMenuOpen={nodeMenuOpen}
          hoveredIf={hoveredIf}
        />
      </div>

      <svg className='links' style={linksStyle}>
        <g>
          {links.map((link, i) => {
            const selected = !!linkSelection?.[link.sourceLink.id];
            const hovered = link.sourceLink.id === hoveredLink?.sourceLink.id;
            return (
              <Link
                key={i}
                selected={selected}
                hovered={hovered}
                {...link}
              />);
          })}
        </g>

        {showAggregateLinks &&
          <g>
            {aggregateLinks.map((aggregateLink, i) => {
              return (
                <AggregateLink
                  key={i}
                  {...aggregateLink}
                  aggregateLink={aggregateLink}
                  hovered={aggregateLink.id === hoveredPC?.id}
                  onSelect={onSelectAggregate ? selectAggregate : null}
                  onHover={setHoveredPC}
                />
              );
            })}
          </g>}
      </svg>

      <div className='neighbors-list' style={nodeContainerStyle}>
        {neighbors.map((node) => {
          const highlighted = highlightedInterfaces?.[node.id];
          const ifc = hoveredLink?.neighborInterfaceInfo;
          const hovered = node.id === ifc?.nodeId ? ifc?.interfaceId : null;
          return (
            <Node
              key={node.id}
              blueprintId={blueprintId}
              highlightedInterfaces={highlighted}
              hoveredIf={hovered}
              {...node}
            />
          );
        })}
      </div>
    </div>
  );
});
