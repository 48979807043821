import {useCallback, useMemo} from 'react';
import {difference, filter, keyBy, keys, map, mapValues, reduce, isObjectLike, size,
  omit, over, pick, transform, values, forEach, every, find, isString, range, without, isEqual} from 'lodash';
import {toJS} from 'mobx';
import {Label} from 'semantic-ui-react';
import {interpolateRoute, request} from 'apstra-ui-common';

import {generateId} from '../eptBuilder/utils';
import {
  ctrlDefaultNodeLabel,
  tailNumber, tailNumberSquared, ctrlIfcPositions, ctrlNodeHeight, ctrlIfcPositionsOffsets
} from './const.js';
import Step from './store/Step';

export function generateLocalId() {
  return `##${generateId()}##`;
}

function getNew(prev, actual) {
  return omit(actual, keys(prev));
}

function getRemoved(prev, actual) {
  return omit(prev, keys(actual));
}

function isEqualNode(previous, actual) {
  return (previous.device_profile?.id ?? null) === actual.deviceProfileId &&
    previous.system_type === actual.systemType &&
    previous.tags.length === actual.tags.length && difference(previous.tags, actual.tags).length === 0 &&
    previous.label === actual.label && previous.system_id === actual.systemId &&
    previous.hostname === actual.hostname && previous.deploy_mode === actual.deployMode;
}

function isEqualLink(previous, actual) {
  return previous.label === actual.label &&
    isEqualEndpoint(previous.endpoints[0], actual.endpoint1) &&
    isEqualEndpoint(previous.endpoints[1], actual.endpoint2) &&
    isEqual(previous.tags, actual.tags);
}

function isEqualEndpoint(previous, actual) {
  return previous.interface.if_name === actual.interfaceName &&
    (previous.interface.transformation_id ?? null) === (actual.transformationId ?? null) &&
    previous.interface.ipv4_addr === actual.ipv4Addr &&
    previous.interface.ipv6_addr === actual.ipv6Addr &&
    previous.interface.lag_mode === actual.lagMode &&
    previous.system.id === actual.nodeId &&
    isEqual(previous.interface.tags, actual.tags) &&
    (previous.endpoint_group ?? null) === (actual.endpointGroup?.index ?? null);
}

function isEqualAggregateLink(previous, actual) {
  return previous.label === actual.label &&
    isEqual(previous.tags, actual.tags) &&
    previous.member_link_ids.length === actual.memberLinkIds.length &&
    isEqual(previous.tags, actual.tags) &&
    every(previous.endpoints, (previous, index) => isEqualEndpoint(previous, actual.endpoints[index])) &&
    every(previous.endpoint_groups, (previous, index) =>
      isEqual(previous, omit(actual.endpointGroups?.[index], ['index']))) &&
    difference(previous.member_link_ids, actual.memberLinkIds).length === 0;
}

function getUpdated(prev, actual, isEqualFn) {
  const actualData = pick(actual, keys(prev));
  const prevData = pick(prev, keys(actual));
  return filter(actualData, (value, key) => !isEqualFn(prevData[key], value));
}

const getUpdatedNodes = (prev, actual) => getUpdated(prev, actual, isEqualNode);
const getUpdatedLinks = (prev, actual) => getUpdated(prev, actual, isEqualLink);
const getUpdatedAggregateLinks = (prev, actual) => getUpdated(prev, actual, isEqualAggregateLink);

function nodeToPayload(node, operation) {
  switch (operation) {
  case 'create':
    return {
      method: 'POST',
      path: '/generic-systems',
      lid: node.id,
      payload: {
        system_type: node.systemType,
        system_id: node.systemId || null,
        label: node.label,
        hostname: node.hostname || null,
        deploy_mode: node.deployMode,
        tags: node.tags,
        ...(node.systemType === 'internal' ? {device_profile_id: node.deviceProfileId} : {}),
      }
    };

  case 'update':
    return {
      method: 'PATCH',
      path: `/generic-systems/${node.id}`,
      payload: {
        system_type: node.systemType,
        system_id: node.systemId || null,
        label: node.label,
        hostname: node.hostname || null,
        deploy_mode: node.deployMode,
        tags: node.tags,
        ...(node.systemType === 'internal' ? {device_profile_id: node.deviceProfileId} : {}),
      }
    };

  case 'delete':
    return {
      method: 'DELETE',
      path: `/generic-systems/${node.id}`
    };
  }
}

function linkToPayload(link, operation) {
  switch (operation) {
  case 'create':
    return {
      method: 'POST',
      path: '/links',
      lid: link.id,
      payload: {
        label: link.label,
        tags: link.tags,
        endpoints: [
          {
            system: {
              id: link.endpoint1.nodeId,
            },
            interface: {
              ipv4_addr: link.endpoint1.ipv4Addr || null,
              ipv6_addr: link.endpoint1.ipv6Addr || null,
              if_name: link.endpoint1.interfaceName,
              transformation_id: link.endpoint1.transformationId ?? null,
              tags: link.endpoint1.tags
            }
          },
          {
            system: {
              id: link.endpoint2.nodeId,
            },
            interface: {
              ipv4_addr: link.endpoint2.ipv4Addr || null,
              ipv6_addr: link.endpoint2.ipv6Addr || null,
              if_name: link.endpoint2.interfaceName,
              transformation_id: link.endpoint2.transformationId ?? null,
              tags: link.endpoint2.tags
            }
          },
        ],
      }
    };

  case 'update':
    return {
      method: 'PATCH',
      path: `/links/${link.id}`,
      payload: {
        label: link.label,
        tags: link.tags,
        endpoints: [
          {
            system: {
              id: link.endpoint1.nodeId,
            },
            interface: {
              id: link.endpoint1.interfaceId,
              ipv4_addr: link.endpoint1.ipv4Addr || null,
              ipv6_addr: link.endpoint1.ipv6Addr || null,
              if_name: link.endpoint1.interfaceName,
              transformation_id: link.endpoint1.transformationId ?? null,
              tags: link.endpoint1.tags
            }
          },
          {
            system: {
              id: link.endpoint2.nodeId,
            },
            interface: {
              id: link.endpoint2.interfaceId,
              ipv4_addr: link.endpoint2.ipv4Addr || null,
              ipv6_addr: link.endpoint2.ipv6Addr || null,
              if_name: link.endpoint2.interfaceName,
              transformation_id: link.endpoint2.transformationId ?? null,
              tags: link.endpoint2.tags
            }
          },
        ],
      }
    };

  case 'delete':
    return {
      method: 'DELETE',
      path: `/links/${link.id}`
    };
  }
}

function aggregateLinkToPayload(link, operation) {
  const aggregateId = link?.id;
  const endpoints = map(
    link.endpoints,
    (endpoint) => ({
      system: {
        id: endpoint.nodeId,
      },
      interface: {
        id: endpoint.interfaceId,
        ipv4_addr: endpoint.ipv4Addr || null,
        ipv6_addr: endpoint.ipv6Addr || null,
        port_channel_id: endpoint.portChannelId,
        tags: endpoint.tags,
        lag_mode: endpoint.lagMode
      },
      endpoint_group: endpoint.endpointGroup?.index
    })
  );

  const endpointGroups = mapValues(
    link.endpointGroups, (endpointGroup) => omit(endpointGroup, ['index'])
  );

  switch (operation) {
  case 'create':
    return {
      method: 'POST',
      path: '/aggregate-links',
      payload: {
        endpoints: endpoints,
        endpoint_groups: endpointGroups,
        member_link_ids: link.memberLinkIds,
        tags: link.tags
      }
    };
  case 'update':
    return {
      method: 'PATCH',
      path: `/aggregate-links/${aggregateId}`,
      payload: {
        endpoints: endpoints,
        endpoint_groups: endpointGroups,
        tags: link.tags,
        member_link_ids: link.memberLinkIds,
      }
    };

  case 'delete':
    return {
      method: 'DELETE',
      path: `/aggregate-links/${link.id}`
    };
  }
}

const prepeareDiffData = ({nodes, links, aggregateLinks, cablingMapStore}) => {
  return {
    oldNodes: keyBy(nodes, 'id'),
    oldLinks: keyBy(links, 'id'),
    newNodes: toJS(cablingMapStore.nodes),
    newLinks: toJS(cablingMapStore.links),
    oldAggregateLinks: keyBy(aggregateLinks, 'id'),
    newAggregateLinks: mapValues(
      toJS(cablingMapStore.aggregateLinks),
      (aggregateLink) => ({
        ...aggregateLink,
        memberLinkIds: map(filter(cablingMapStore.links, {aggregateId: aggregateLink.id}), 'id')
      })
    ),
  };
};

// For newly created or extended with new links aggregates there are no portChannelIds
// defined immediately. These get added upon saving. PortChannelIds must be unique
// in scope of a node.
// This method analyses portChannelIds across the nodes and add missing
function generatePortChannelIds(links) {
  // Calculates which port channels are taken on each of the nodes with aggregates
  const nodePortChannels = transform(links, (acc, {endpoints}) => {
    forEach(endpoints, ({nodeId, portChannelId}) => (acc[nodeId] ??= []).push(portChannelId));
  }, {});

  // Builds the list of available portChannelIds for each node
  const nodeFreePortChannels = mapValues(nodePortChannels, (usedPortChannels) => {
    const allIds = range(1, usedPortChannels.length + 1);
    return without(allIds, ...usedPortChannels);
  });

  // Assigns portChannelIds from the list if they are missing
  forEach(links, ({endpoints}) => {
    forEach(endpoints, (endpoint) => {
      const {nodeId, portChannelId} = endpoint;
      if (!portChannelId) endpoint.portChannelId = nodeFreePortChannels[nodeId].shift();
    });
  });
}

export class SaveError extends Error {
  static getErrors(response, acc = []) {
    forEach(response, (v) => {
      if (isString(v)) {
        acc.push(v);
      }
      if (isObjectLike(v)) {
        if (v?.message) {
          acc.push(v.message);
        } else {
          SaveError.getErrors(v, acc);
        }
      }
    });
    return acc;
  }

  constructor(errorObject) {
    super('Freeform topology save error');
    this.errors = SaveError.getErrors(errorObject);
  }
}

export function useTopologySaver({blueprintId, nodes, links, aggregateLinks, cablingMapStore, preferences}) {
  return useCallback(() => {
    const preparedData = prepeareDiffData({nodes, links, aggregateLinks, cablingMapStore});
    const [addedNodes, removedNodes, updatedNodes] =
      over(getNew, getRemoved, getUpdatedNodes)(
        preparedData.oldNodes, preparedData.newNodes
      );
    const [addedLinks, removedLinks, updatedLinks] =
      over(getNew, getRemoved, getUpdatedLinks)(
        preparedData.oldLinks, preparedData.newLinks
      );
    const [addedAggregateLinks, removedAggregateLinks, updatedAggregateLinks] =
      over(getNew, getRemoved, getUpdatedAggregateLinks)(
        preparedData.oldAggregateLinks, preparedData.newAggregateLinks
      );
    generatePortChannelIds(preparedData.newAggregateLinks);
    const preferencesPayload = {
      method: 'PUT',
      path: '/preferences',
      payload: {
        preferences: {
          ...preferences,
          userData: mapValues(cablingMapStore.nodes, 'customData')
        }
      }
    };

    const payload = [
      ...map(removedAggregateLinks, (n) => aggregateLinkToPayload(n, 'delete')),
      // remove links from aggregates
      ...map(removedLinks, (n) => linkToPayload(n, 'delete')),
      ...map(removedNodes, (n) => nodeToPayload(n, 'delete')),
      ...map(addedNodes, (n) => nodeToPayload(n, 'create')),
      ...map(updatedNodes, (n) => nodeToPayload(n, 'update')),
      ...map(addedLinks, (n) => linkToPayload(n, 'create')),
      ...map(updatedLinks, (n) => linkToPayload(n, 'update')),
      // push links to aggregates
      ...map(updatedAggregateLinks, (n) => aggregateLinkToPayload(n, 'update')),
      ...map(addedAggregateLinks, (n) => aggregateLinkToPayload(n, 'create')),
      preferencesPayload,
    ];

    return request(
      interpolateRoute(
        '/api/blueprints/<blueprint_id>/batch?async=full',
        {blueprintId}
      ),
      {
        method: 'POST',
        body: JSON.stringify({operations: payload})
      }
    ).then(({task_id: taskId}) => new Promise((resolve, reject) => {
      const askTaskState = () => request(
        interpolateRoute(
          '/api/blueprints/<blueprint_id>/tasks/<task_id>',
          {blueprintId, taskId}
        ),
        {method: 'GET'}
      ).then((state) => {
        if (state?.status === 'succeeded') {
          resolve();
        } else if (state?.status === 'failed') {
          reject(new SaveError(state?.detailed_status?.errors));
        } else {
          setTimeout(askTaskState, 1000);
        }
      }).catch(reject);
      askTaskState();
    }));
  }, [nodes, links, aggregateLinks, cablingMapStore, blueprintId, preferences]);
}

export function makeUniqueNodeLabel(nodes, baseLabel = ctrlDefaultNodeLabel, squared) {
  const nodeLabels = keyBy(values(nodes), 'label');
  let index = 0;
  let label = baseLabel;
  baseLabel = baseLabel.replace(squared ? tailNumberSquared : tailNumber, '');
  do {
    const indexPadded = (++index).toString().padStart(3, '0');
    label = squared ? `${baseLabel} [${index}]` : `${baseLabel}-${indexPadded}`;
  } while (nodeLabels[label]);
  return label;
}

// Unassigns systemId from node if has already been assigned
const unassignDevice = (systemId, cablingMap) => {
  const {nodes, changes} = cablingMap;
  // If there are a node with the same systemId ...
  const assignee = find(nodes, {systemId});
  if (assignee) {
    const step = Step.modification(null, assignee);
    // ... it should be reset for it
    assignee.setProperty('systemId', null);
    step.setResult(assignee);
    // and register corresponding change
    changes.register(step, true);
  }
};

// Register a single property change
export function trackNodesChange(nodes, cablingMap, property, value) {
  const {changes} = cablingMap;
  const nodesById = keyBy(nodes, 'id');

  const isDeviceProfile = property === 'deviceProfileId';
  const isSystemId = property === 'systemId';
  const multipleNodes = nodes?.length > 1;

  let change = changes.current;
  if (change?.length === nodes.length && every(change, ({id, isUpdate}) => (!!nodesById[id] && isUpdate))) {
    // All node' properties changes must be tracked as a single change
    // Thus if the latest change equals to the current set of nodes - reuse it
    changes.splice();
  } else {
    // ... or create the new Change otherwise
    change = map(nodes, (node) => Step.modification(null, node));
    changes.register(change);
  }

  if (isSystemId && value) {
    // If device gets assigned to the system it must be unassigned first if assigned somewhere
    // else already
    unassignDevice(value, cablingMap);
  }

  forEach(change, (step) => {
    const node = nodesById[step.id];
    if (isDeviceProfile) {
      // Assigned devices (system_id) must also be removed from nodes
      // upon device profile change
      if (node.deviceProfileId !== value) {
        node.setProperty('systemId', null);
      }
    }

    node.setProperty(
      property,
      (multipleNodes && property === 'label') ? makeUniqueNodeLabel(cablingMap.nodes, value, true) : value
    );
    step.setResult(node);
  });
}

export const useDevicesLabels = (devices) => {
  return useMemo(() => (
    reduce(
      devices,
      (result, {id, ip, location}) => {
        result[id] = `${id} (${ip})` + (location ? ` - ${location}` : '');
        return result;
      },
      {}
    )
  ),
  [devices]
  );
};

export const renderTags = (tags, scale = 'tiny') => (
  size(tags) ?
    map(tags, (tag) => <Label key={tag} size={scale} icon='tag' content={tag} />) :
    'N/A'
);

const makeShiftFrom = ({x, y, placement}) => {
  const [sx, sy] = ctrlIfcPositionsOffsets?.[placement] ?? [0, 0];
  return `${x + sx * 50} ${y + sy * 50}`;
};

export const getLinkPath = (st, en, through) => {
  // Whether the path must be drawn as a curve or as a straight line
  const asCurve = st.placement === en.placement ||
    (Math.abs(st.x - en.x) > ctrlNodeHeight || Math.abs(st.y - en.y) > ctrlNodeHeight);

  const [sx, sy, ex, ey] = [st.x, st.y, en.x, en.y];
  let path = `M${sx} ${sy} L${ex} ${ey}`;
  if (asCurve) {
    if (through) {
      const [tx, ty] = [(through[1].x - through[0].x) / 5, (through[1].y - through[0].y) / 5];
      path = [
        `M${sx} ${sy}`,
        `Q${through[0].x} ${through[0].y} ${through[0].x + tx} ${through[0].y + ty}`,
        `L${through[1].x - tx} ${through[1].y - ty}`,
        `Q${through[1].x} ${through[1].y} ${ex} ${ey}`
      ].join(' ');
    } else {
      path = `M${sx} ${sy} C${makeShiftFrom(st)} ${makeShiftFrom(en)} ${ex} ${ey}`;
    }
  }

  return path;
};

// Identifies how node is positioned in relation to some other node
export const getDirectionToTarget = ({x, y}, targetPosition) => {
  const dx = targetPosition.x - x;
  const dy = targetPosition.y - y;
  const absDy = Math.abs(dy);
  return (absDy > Math.abs(dx / 3)) ?
    (dy < 0 ? ctrlIfcPositions.TOP : ctrlIfcPositions.BOTTOM) :
    (dx > 0 ? ctrlIfcPositions.RIGHT : ctrlIfcPositions.LEFT);
};

// Validation of set of IPs for the links:
// * Must belong to the same subnet
// * IP must correspond the range
export const validateSubnetsOf = (ips) => {
  const count = size(ips);
  // None or a single IP is always correct
  if (count < 2) return;

  try {
    // Different CIDRs
    const cidr = ips[0].cidr;
    if (!every(ips, {cidr})) return ['All IPs must belong to the same subnet'];
  } catch (e) {
    return ['Error parsing IP address'];
  }
};

export const fixNodeLinks = (node) => {
  const availableIfcs = transform(
    node.deviceProfile.ports,
    (acc, {id: pid, transformations}) => {
      forEach(transformations, ({id: tid, interfaces}) => {
        forEach(interfaces, ({id, name}) => {
          acc[name] = {
            portId: pid,
            transformationId: tid,
            interfaceId: id,
            interfaceName: name
          };
        });
      });
    },
    {}
  );

  forEach(node.myLinks, (link) => {
    const [ep] = link.orderEndpointsFor(node.id);
    const ifcName = keys(availableIfcs)[0];
    ep.fillWith(availableIfcs[ifcName]);
    delete availableIfcs[ifcName];
  });
};

export const getTopologicOrder = ({x, y}, transpond) => {
  const [xx, yy] = transpond ? [y, x] : [x, y];
  const left = `${~~xx}`.padStart(10, '0'); // eslint-disable-line no-bitwise
  const right = `${~~(yy + 99999)}`.padEnd(10, '0'); // eslint-disable-line no-bitwise

  return `${left}.${right}`;
};
