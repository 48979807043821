import {map, upperFirst, toLower} from 'lodash';

export const ctrlGridStep = 20;
export const ctrlGridDotSize = 2;
export const ctrlNodeWidth = 200;
export const ctrlNodeHeight = 40;
export const ctrlNodeTitleShownChars = 20;
export const ctrlCanvasMinHeight = 300;

export const ctrlPortSize = 3;
export const ctrlPortSpacing = 10;
export const ctrlRowSize = 15;

export const ctrlLagWidth = 15;
export const ctrlLagStep = 30;

export const ctrlIfcLabelWidth = 60;
export const ctrlIfcLabelHeight = 22;

export const ctrlHalfNodeWidth = ctrlNodeWidth / 2;
export const ctrlHalfNodeHeight = ctrlNodeHeight / 2;

export const ctrlStages = {
  ADD: 'add',
  DELETE: 'delete',
  CHANGE: 'change'
};

export const ctrlIfcPositions = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

export const ctrlIfcPositionsOffsets = {
  [ctrlIfcPositions.TOP]: [0, -1],
  [ctrlIfcPositions.BOTTOM]: [0, 1],
  [ctrlIfcPositions.LEFT]: [-1, 0],
  [ctrlIfcPositions.RIGHT]: [1, 0]
};

export const ctrlNodesPlacements = {
  LEFT: 'left',
  RIGHT: 'right'
};

export const ctrlNodesOppositePlacements = {
  [ctrlNodesPlacements.RIGHT]: ctrlNodesPlacements.LEFT,
  [ctrlNodesPlacements.LEFT]: ctrlNodesPlacements.RIGHT
};

export const ctrlEntityTypes = {
  LINK: 'link',
  NODE: 'node',
  AGGREGATE: 'aggregate_link',
  LINKS_GROUP: 'links_group'
};

export const ctrlSystemTypes = {
  INTERNAL: 'internal',
  EXTERNAL: 'external'
};

export const ctrlDirections = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

export const ctrlDefaultNodeLabel = 'sys';
export const ctrlDefaultExtNodeLabel = 'ext';

export const tailNumber = new RegExp(/-\d+$/);
export const tailNumberSquared = new RegExp(/ \[\d+\]$/);

export const hostNameValidation = new RegExp(
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/
);

export const deployModes = {
  DEPLOY: 'deploy',
  READY: 'ready',
  DRAIN: 'drain',
  UNDEPLOY: 'undeploy'
};

export const deployModeOptions = map(deployModes, (value, key) => ({
  text: upperFirst(toLower(key)),
  value
}));

export const CME_COMMON_SAVING_ERROR = 'Error saving cabling map data';

export const nodeTextSources = {
  LABEL: 'label',
  HOSTNAME: 'hostname',
  SYSTEM_ID: 'systemId',
  IP: 'ip',
};
