import {DataTable, Value} from 'apstra-ui-common';
import {castArray, first, isArray, isNil, map, omit} from 'lodash';
import {FC} from 'react';

import {ReactComponent as ProcessorModalArrow} from '../../../../styles/icons/iba/processor-modal-arrow.svg';
import {ProcessorDefinition, ProcessorExampleInputType, ProcessorExampleType} from '../../types';
import {Card, CardItem} from './Card';

import './ProcessorExample.less';
import {processorPropertyRenderers} from '../../processorPropertiesRenderers';

type ProcessorExampleProps = {
  processorDefinition: ProcessorDefinition;
  processorExample: ProcessorExampleType;
};

const buildSchema = (keys: any, values: any) => [
  ...map(keys, (_, key) => ({
    name: key,
    label: key,
    value: [key],
    type: 'string'
  })),
  ...map(omit(values, 'timestamp', 'value'), (_, key) => ({
    name: key,
    label: key,
    formatter: ({value}) => <Value value={value} />,
    value: [key],
  })),
  ...isNil(values.timestamp) ?
    [{
      name: 'value',
      label: 'Value',
      value: ['value'],
      type: 'string'
    }] :
    map(castArray(values.timestamp), (timestamp, index) => ({
      name: timestamp,
      label: timestamp,
      formatter: ({value}) => <Value value={value} />,
      value: isArray(values.value) ? ['value', index] : ['value'],
    }))
];

const buildItems = (inputs: ProcessorExampleInputType[]) =>
  map(inputs, (input) => ({...input.keys, ...input.values}));

export const ProcessorExample: FC<ProcessorExampleProps> = ({
  processorDefinition,
  processorExample
}) => {
  return (
    <div className='processor-name'>
      <div className='processor-inputs'>
        {map(processorExample.inputs, (input, key) => (
          <div key={key} className='card-arrow-container'>
            <Card
              className='processor-editor-input-card'
              title={key}
            >
              <DataTable
                basic
                celled
                items={buildItems(input)}
                selection={{}}
                schema={buildSchema(first(input)?.keys, first(input)?.values)}
              />
            </Card>
            <ProcessorModalArrow />
          </div>
        ))}
      </div>
      <Card
        className='processor-editor-processor-card'
        title={processorExample.name}
      >
        {map(processorExample.properties, (value, index) => (
          <div key={index} className='processor-row'>
            <CardItem>
              {processorDefinition.schema.properties[index].title}
            </CardItem>
            <CardItem>
              <Value
                renderers={processorPropertyRenderers}
                value={value}
                name={index}
              />
            </CardItem>
          </div>
        ))}
      </Card>
      <div className='processor-outputs'>
        {map(processorExample.outputs, (output, key) => (
          <div key={key} className='card-arrow-container'>
            <ProcessorModalArrow />
            <Card
              className='processor-editor-output-card'
              title={key}
            >
              <DataTable
                basic
                items={buildItems(output)}
                schema={buildSchema(first(output)?.keys, first(output)?.values)}
              />
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};
