import {Fragment, useMemo, useCallback} from 'react';
import {observer} from 'mobx-react';
import {useResizeDetector} from 'react-resize-detector';
import {Popup} from 'semantic-ui-react';
import {map, reduce, size, max, forEach, transform, sortBy, compact, keyBy, keys, groupBy,
  every, filter, min, without} from 'lodash';
import cx from 'classnames';
import {preventClickThrough} from 'apstra-ui-common';

import Node from './Node';
import Interface from './Interface';
import EndpointProperties from '../EndpointProperties';
import Lag from './Lag';
import {ctrlNodeHeight, ctrlNodeWidth, ctrlNodesPlacements, ctrlNodesOppositePlacements,
  ctrlLagWidth, ctrlLagStep, ctrlIfcLabelHeight} from '../../const';
import {getLinkPath} from '../../utils';

import './Aggregator.less';

const yOffset = (ctrlLagStep - ctrlIfcLabelHeight) / 2;

// Calculate the next position of node's interface
const calcIfcPosition = ({nodeId}, nodesPositions, nodeSlotsUsed) => {
  const currentSlot = 1 + (nodeSlotsUsed?.[nodeId] ?? 0);
  const {x, y} = nodesPositions?.[nodeId] ?? {};
  const isOnRight = x < 0;
  nodeSlotsUsed[nodeId] = currentSlot + 1;

  return {
    x: isOnRight ? x : x + ctrlNodeWidth,
    y: y + ctrlLagStep * currentSlot / 2 + yOffset,
    side: isOnRight ? ctrlNodesPlacements.RIGHT : ctrlNodesPlacements.LEFT
  };
};

// Calculate middle between two points
const middle = (top, bottom) => (top + (bottom - top) / 2);

// Checks whether current nodes placement corresponds to aggregate's
// endpoint groups
const endpointsCorrespondToPlacement = (endpoints, placement) => (
  every(endpoints, ({endpointGroup, nodeId}) => (
    endpointGroup?.index === (placement[nodeId] === ctrlNodesPlacements.LEFT ? 0 : 1)
  ))
);

const measureSideCircles = (circles, side, endpoints, aggregateId) => {
  const endpointGroup = side === ctrlNodesPlacements.LEFT ? 0 : 1;
  const sideCircles = compact(map(
    filter(endpoints, {'endpointGroup.index': endpointGroup}),
    ({nodeId}) => (circles[`${nodeId}-${aggregateId}`])
  ));
  const minY = min(map(sideCircles, 'y')) || 0;
  const maxY = max(map(sideCircles, ({y, size}) => (y + ctrlLagStep * size))) || 0;
  return [minY, maxY];
};

// Adjusts given coordinates according to a viewport size
const adjustForWidth = ({x, y, side}, width) => {
  const xx = x < 0 ? width + x : (x ? x : width / 2);
  if (isNaN(xx) || isNaN(y)) return;
  return {x: xx, y, side, placement: ctrlNodesOppositePlacements[side]};
};

// Drawing a set of circles
const drawCircles = (circles, behind, width, onClick, selectedAggregateId, usedIps) => (
  map(
    // Circles must be sorted so selected always get rendered on top of the others
    sortBy(keys(circles), (circleKey) => circleKey.indexOf(selectedAggregateId) >= 0),
    (circleKey) => {
      const circlePosition = circles[circleKey];
      const lag = adjustForWidth(circlePosition, width);
      const {size, endpoint, link} = circlePosition;
      const selected = circleKey.indexOf(selectedAggregateId) >= 0;
      const props = endpoint ?
        {
          className: {
            interactive: true,
            selected
          },
          onClick: (e) => onClick(e, circlePosition)
        } : {
          className: {
            selected
          }
        };
      return lag && <Lag
        key={circleKey}
        behind={behind}
        x={lag.x}
        y={lag.y}
        size={size}
        error={!(endpoint?.ipsAreValid ?? true) || link?.hasErrors || endpoint?.containsDuplicates(usedIps)}
        {...props}
      />;
    }
  )
);

// nodes - nodes which links will participate in aggregate creation
// links - all links connecting any two of the given nodes
const Aggregator = ({
  nodes, links, aggregateLinks, selectedLinks, onLinkClick, placement, setPlacement,
  editedEndpoint, editEndpoint, aggregateEnpoints, nodesOrder, nodesLinks, selectedSpeed,
  usedIps
}) => {
  const {width, ref} = useResizeDetector();
  // Calculate which of selected nodes are connected and how many
  // connections every node has
  const connections = useMemo(
    () => transform(nodesLinks, (acc, links, nodeId) => (acc[nodeId] = size(links)), {}),
    [nodesLinks]
  );

  // Some of the links might be deleted, thus we need the visible subset
  const linksExistence = map(links, 'exists').join();

  // Highlight selected aggregate if any
  const selectedAggregateId = useMemo(
    () => without(map(selectedLinks, 'aggregateId'), null, undefined)?.[0],
    [selectedLinks]
  );

  // Toggles node position on the canvas (Left or Right column)
  const moveNode = useCallback((nodeId) => {
    // If moved node has links selected, they must be unselected
    // since links selection on the same side is prohibited
    forEach(nodesLinks?.[nodeId], (link) => selectedLinks[link.id] && onLinkClick(link));

    // Change node's side then
    const currentSide = placement[nodeId];
    setPlacement({
      ...placement,
      [nodeId]: ctrlNodesOppositePlacements[currentSide]
    });
  }, [placement, onLinkClick]); // eslint-disable-line react-hooks/exhaustive-deps

  // Precalculate nodes positions on the canvas
  const [nodesPositions, positionsNodes] = useMemo(() => {
    const ys = {
      [ctrlNodesPlacements.LEFT]: 2,
      [ctrlNodesPlacements.RIGHT]: 2
    };
    const sideNodes = {
      [ctrlNodesPlacements.LEFT]: [],
      [ctrlNodesPlacements.RIGHT]: []
    };
    return [
      reduce(
        sortBy(keys(placement), (nodeId) => nodesOrder.indexOf(nodeId)),
        (result, nodeId) => {
          const side = placement[nodeId];
          const linksCount = connections[nodeId];
          const height = linksCount > 1 ? linksCount * ctrlLagStep + ctrlLagStep - ctrlIfcLabelHeight : ctrlNodeHeight;
          result[nodeId] = {
            x: side === ctrlNodesPlacements.LEFT ? 2 : -ctrlNodeWidth - 2,
            y: ys[side],
            height
          };
          ys[side] += ctrlLagStep + height;
          sideNodes[side].push(nodeId);
          return result;
        },
        {}),
      sideNodes
    ];
  }, [placement, nodesOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  // Height of the canvas depending on the size of the sides
  const canvasHeight = useMemo(() => (
    max([100, ...map(nodesPositions, ({y, height}) => y + height)])
  ), [nodesPositions]);

  // All links must be displayed in a comfortable order:
  // * links to the opposite (RIGHT) side go first
  // * links to nodes of the same (LEFT) side go from farer to closer to reduce # of intersections
  const linksSortingOrder = useMemo(() => [
    ...(positionsNodes?.[ctrlNodesPlacements.RIGHT] ?? []), // opposite side links should go first
    ...(positionsNodes?.[ctrlNodesPlacements.LEFT] ?? []) // same side links - after them
  ], [positionsNodes]);

  const aggregatedLinks = keyBy(links, 'aggregateId');

  // Calculate links curves and circles positions
  const [linksPositions, circles] = useMemo(() => {
    const circles = {};
    const nodeSlotsUsed = {};
    const aggregatesOrder = [];

    const linksPositions = transform(nodesOrder, (result, orderedNodeId) => {
      // Individual sorter for each node: in scope of the node links must be sorted as:
      // 1. Aggregate links (already seen), ordered by linksSortingOrder
      // 2. New aggregates, ordered by linksSortingOrder
      // 3. Not aggregated links, ordered by linksSortingOrder
      const linksSorter = (link) => {
        const [, {nodeId}] = link.orderEndpointsFor(orderedNodeId);
        const aggregatesGoFirst = link.aggregateId ? link.aggregateId : 'zzz';
        return `${aggregatesGoFirst}-${linksSortingOrder.indexOf(nodeId)}`;
      };

      forEach(nodesLinks[orderedNodeId], ({aggregateId}) => {
        if (aggregateId && !aggregatesOrder.includes(aggregateId)) {
          // Register aggregates order: already seen must go first
          aggregatesOrder.push(aggregateId);
        }
      });

      forEach(
        // For all node links ordered
        sortBy(links, linksSorter),
        ({id, endpoint1, endpoint2, aggregateId, exists}) => {
          // Link might already be positioned as we iterate by nodes:
          if (result[id]) return; // Already positioned - skip

          const positions = map([endpoint1, endpoint2], (endpoint) => {
            const position = calcIfcPosition(endpoint, nodesPositions, nodeSlotsUsed);

            const linkCircle = aggregateId && exists ? `${endpoint.nodeId}-${aggregateId}` : null;
            if (linkCircle) {
              if (circles[linkCircle]) {
                circles[linkCircle].size++;
              } else {
                circles[linkCircle] = {
                  x: position.x + (position.side === ctrlNodesPlacements.LEFT ? 20 : -20),
                  y: position.y,
                  size: 1,
                  endpoint: aggregateEnpoints?.[aggregateId]?.[endpoint.nodeId],
                  link: aggregateLinks[aggregateId]
                };
              }
            }
            return position;
          });
          result[id] = {start: positions[0], end: positions[1]};
        }
      );
    }, {});

    // Middle circles must be drawn to distinct MLAGs from set of LAGs
    forEach(aggregateEnpoints, (endpoints, aggregateId) => {
      const sides = groupBy(endpoints, 'endpointGroup.index');

      // It must only be visible if nodes placement corresponds to endpoint groups of the link
      const isApplicable = every(sides, (sideEndpoints) => (size(sideEndpoints) > 1)) &&
        endpointsCorrespondToPlacement(endpoints, placement);

      if (isApplicable) {
        const [leftMinY, leftMaxY] = measureSideCircles(circles, ctrlNodesPlacements.LEFT, endpoints, aggregateId);
        const [rightMinY, rightMaxY] = measureSideCircles(circles, ctrlNodesPlacements.RIGHT, endpoints, aggregateId);
        const [topMiddle, bottomMiddle] = [middle(leftMinY, rightMinY), middle(leftMaxY, rightMaxY)];
        const size = (bottomMiddle - topMiddle) / ctrlLagStep;
        circles[aggregateId] = {
          x: 0,
          y: topMiddle,
          size,
          endpoint: null
        };
      }
    });

    return [
      linksPositions,
      circles
    ];
  }, [nodesPositions, linksSortingOrder, aggregatedLinks, linksExistence]); // eslint-disable-line

  const lagClickHandler = useMemo(() => (event, lagData) => {
    event.stopPropagation();
    editEndpoint(editedEndpoint?.endpoint.nodeId === lagData?.endpoint.nodeId ? null : lagData);
  }, [editEndpoint, editedEndpoint?.endpoint.nodeId]);

  const popupOffset = editedEndpoint ? [
    adjustForWidth(editedEndpoint, width)?.x - 20,
    10 - editedEndpoint?.y
  ] : [0, 0];

  return (
    <div ref={ref} className='cme-aggregate'>
      <Popup
        position='top left'
        header='Endpoint Properties'
        basic
        size='tiny'
        inverted={false}
        open={!!editedEndpoint}
        on={[]}
        offset={popupOffset}
        onClick={preventClickThrough}
        trigger={
          <svg
            key='cme-aggregate'
            xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
            version='1.2'
            baseProfile='full'
            width={width}
            height={canvasHeight + 4}
          >
            <clipPath id='lagClip'>
              <rect x={-ctrlLagWidth} y={-ctrlLagStep} width={ctrlLagWidth} height={500} />
            </clipPath>
            {
              // Nodes
              map(
                nodesPositions,
                ({x, y, height}, nodeId) => (
                  <Node
                    key={nodeId}
                    node={nodes[nodeId]}
                    onRight={x < 0}
                    x={x < 0 ? width + x : x}
                    y={y}
                    height={height}
                    onReposition={moveNode}
                  />
                )
              )
            }
            {
              // Circles (behind part)
              drawCircles(circles, true, width, lagClickHandler, selectedAggregateId, usedIps)
            }
            {
              // Links & interfaces
              map(
                links,
                (link) => {
                  const {id, endpoint1, endpoint2, isValid} = link;
                  const positions = linksPositions[id];
                  if (!positions) return null;

                  const [start, end] = [
                    adjustForWidth(positions.start, width),
                    adjustForWidth(positions.end, width)
                  ];

                  if (!start || !end) return null;

                  const deleted = !link.exists;
                  const isSameGroup = positions.start.side === positions.end.side;
                  const nonSelectable =
                    (selectedSpeed && link.speedString && selectedSpeed !== link.speedString) ||
                    isSameGroup;
                  const disabled = deleted || nonSelectable;
                  const selected = !!selectedLinks[id];
                  const className = cx({
                    selected,
                    disabled,
                    deleted,
                    aggregated: link.isAggregated,
                    'same-group': isSameGroup,
                    error: !isValid
                  });
                  const props = disabled ? {disabled} : {
                    onClick: () => onLinkClick(link),
                    disabled
                  };
                  const path = getLinkPath(start, end);

                  return (
                    <Fragment key={id}>
                      <path d={path} className={cx(className, 'outline')} {...props} />
                      <path d={path} className={className} {...props} />
                      <Interface
                        x={start.x}
                        y={start.y}
                        endpoint={endpoint1}
                        node={nodes[endpoint1.nodeId]}
                        onRight={start.side === ctrlNodesPlacements.RIGHT}
                        selected={selected}
                        label={link.label}
                        {...props}
                      />
                      <Interface
                        x={end.x}
                        y={end.y}
                        endpoint={endpoint2}
                        node={nodes[endpoint2.nodeId]}
                        onRight={end.side === ctrlNodesPlacements.RIGHT}
                        selected={selected}
                        label={link.label}
                        {...props}
                      />
                    </Fragment>
                  );
                }
              )
            }
            {
              // Circles (above part)
              drawCircles(circles, false, width, lagClickHandler, selectedAggregateId, usedIps)
            }
          </svg>
        }
        content={
          <EndpointProperties
            endpoint={editedEndpoint?.endpoint}
            link={editedEndpoint?.link}
          />
        }
      />
    </div>
  );
};

export default observer(Aggregator);
