import {forEach, times, flattenDeep, isPlainObject} from 'lodash';

import PythonExpressionPlainTextFormatter, {NEWLINE, INDENT, OUTDENT} from './PythonExpressionPlainTextFormatter';
import {namedNodesFunctions} from './consts';

function formatToken(token, className) {
  return <span key={`token-${token.startOffset}`} className={className}>{token.image}</span>;
}

let visitorInstance = null;

export default class PythonExpressionJSXFormatter extends PythonExpressionPlainTextFormatter {
  static get instance() {
    if (!visitorInstance) visitorInstance = new this();
    return visitorInstance;
  }

  static run(cst, {indentationToken = '  ', multiLine = false} = {}) {
    this.resetState();
    this.instance.multiLine = multiLine;
    return this.convertSymbolsAndTokens(this.instance.visit(cst), indentationToken);
  }

  static convertSymbolsAndTokens(visitResult, indentationToken) {
    const result = flattenDeep(visitResult);
    let indentation = 0;
    forEach(result, (token, tokenIndex) => {
      if (token === NEWLINE) {
        result[tokenIndex] = (
          <span key={`indent-${tokenIndex}`} className='gq-indent'>
            {'\n'}
            {times(indentation, (index) =>
              <span key={index}>{indentationToken}</span>
            )}
          </span>
        );
      } else if (token === INDENT) {
        indentation++;
        result[tokenIndex] = null;
      } else if (token === OUTDENT) {
        indentation--;
        result[tokenIndex] = null;
      } else if (isPlainObject(token) && token.tokenType) {
        result[tokenIndex] = token.image;
      }
    });
    return result;
  }

  infixOperatorExpression(ctx) {
    const result = super.infixOperatorExpression(ctx);
    result[1] = formatToken(result[1], 'gq-infix-op');
    return result;
  }

  prefixOperatorExpression(ctx) {
    const result = super.prefixOperatorExpression(ctx);
    result[0] = formatToken(result[0], 'gq-prefix-op');
    return result;
  }

  lambda(ctx) {
    const result = super.lambda(ctx);
    result[0] = formatToken(result[0], 'gq-lambda');
    return result;
  }

  functionCall(ctx) {
    const result = super.functionCall(ctx);
    result[0] = formatToken(result[0], 'gq-function-call');
    return result;
  }

  keywordArgument(ctx) {
    const result = super.keywordArgument(ctx);
    const {image: key, startOffset} = result[0];
    result[0] = formatToken(result[0], 'gq-keyword-argument');
    const currentFunctionName = this.functionNames[this.functionNames.length - 1];
    if (key === 'name' && namedNodesFunctions.has(currentFunctionName)) {
      result[2] = (
        <span key={`node-name-highlight-${startOffset}`} className='gq-node-name-highlight'>{result[2]}</span>
      );
    }
    return result;
  }

  string(ctx) {
    return formatToken(super.string(ctx), 'gq-string');
  }
}
