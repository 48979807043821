import {Component, Fragment} from 'react';
import {observable, action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Grid, Header, Button, Popup, Icon, Placeholder, Ref, Message} from 'semantic-ui-react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {
  forEach, find, transform, flatten, without, times,
  uniqueId, defer, isEmpty, isMatch, pullAllWith, assign,
} from 'lodash';
import cx from 'classnames';
import {ActionsMenu, withRouter} from 'apstra-ui-common';

import Widget from './Widget';
import WidgetModal from './WidgetModal';
import IBAContext from '../IBAContext';

import './Dashboard.less';

@withRouter
@observer
export default class Dashboard extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    editable: false
  };

  @observable.ref widgetModalProps = this.popWidgetModalPropsFromLocationState();
  @observable.ref isDragging = false;

  widgetToId = new WeakMap();

  getWidgetId = (widget) => {
    let id = this.widgetToId.get(widget);
    if (!id) {
      id = uniqueId('widget');
      this.widgetToId.set(widget, id);
    }
    return id;
  };

  @computed get idToWidget() {
    return transform(flatten(this.props.dashboard.grid), (result, widget) => {
      result[this.getWidgetId(widget)] = widget;
    }, {});
  }

  popWidgetModalPropsFromLocationState() {
    const {location, navigate} = this.props;
    const widgetToCreate = location.state?.widgetToCreate;
    if (!widgetToCreate) return null;
    defer(() => navigate(location.pathname, {replace: true}));
    return {
      open: true,
      mode: 'create',
      widget: widgetToCreate,
      fixedWidgetType: true,
      fixedStage: true,
      onSuccess: ({result: {widget}}) => {
        this.addWidget(widget, 0);
      }
    };
  }

  @action
  setWidgetModalProps = (props) => {
    this.widgetModalProps = props;
  };

  @action
  updateWidget = (widget, columnIndex, widgetIndex) => {
    const {grid} = this.props.dashboard;
    grid[columnIndex][widgetIndex] = widget;
    pullAllWith(this.props.errors, [{type: 'widget', columnIndex, widgetIndex}], isMatch);
  };

  @action
  addWidget = (widget, columnIndex) => {
    const {grid} = this.props.dashboard;
    grid[columnIndex] = [...grid[columnIndex], widget];
  };

  @action
  removeWidget = (columnIndex, widgetIndex) => {
    const {grid} = this.props.dashboard;
    const widget = grid[columnIndex][widgetIndex];
    grid[columnIndex] = without(grid[columnIndex], widget);
    pullAllWith(this.props.errors, [{type: 'widget', columnIndex, widgetIndex}], isMatch);
  };

  @action
  onWidgetDragStart = () => {
    this.isDragging = true;
  };

  @action
  onWidgetDragEnd = ({draggableId, source, destination}) => {
    const widget = this.idToWidget[draggableId];
    this.isDragging = false;
    if (
      !destination ||
      source.droppableId === destination.droppableId && source.index === destination.index
    ) return;
    const {grid} = this.props.dashboard;
    const sourceColumnIndex = Number(source.droppableId);
    const sourceWidgetIndex = source.index;
    const destinationColumnIndex = Number(destination.droppableId);
    const destinationWidgetIndex = destination.index;

    const widgetsToErrors = transform(this.props.errors, (result, error) => {
      if (error.type !== 'widget') return;
      const widget = grid[error.columnIndex][error.widgetIndex];
      if (!widget) return;
      result.set(widget, error);
    }, new Map());

    if (sourceColumnIndex === destinationColumnIndex) {
      const column = [...grid[sourceColumnIndex]];
      column.splice(sourceWidgetIndex, 1);
      column.splice(destinationWidgetIndex, 0, widget);
      grid[sourceColumnIndex] = column;
    } else {
      const sourceColumn = [...grid[sourceColumnIndex]];
      const destinationColumn = [...grid[destinationColumnIndex]];
      sourceColumn.splice(sourceWidgetIndex, 1);
      destinationColumn.splice(destinationWidgetIndex, 0, widget);
      grid[sourceColumnIndex] = sourceColumn;
      grid[destinationColumnIndex] = destinationColumn;
    }

    forEach(grid, (column, columnIndex) => {
      forEach(column, (widget, widgetIndex) => {
        const error = widgetsToErrors.get(widget);
        if (error) assign(error, {columnIndex, widgetIndex});
      });
    });
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {editable, dashboard, errors, probes} = this.props;
    const {
      widgetModalProps, setWidgetModalProps,
      updateWidget, addWidget, removeWidget,
      onWidgetDragStart, onWidgetDragEnd, isDragging,
    } = this;
    return (
      <Fragment>
        <DragDropContext onDragStart={onWidgetDragStart} onDragEnd={onWidgetDragEnd}>
          <Grid className='iba-dashboard' columns='equal'>
            {dashboard.grid.map((column, columnIndex) =>
              <Droppable
                key={columnIndex}
                droppableId={String(columnIndex)}
                isDropDisabled={!editable}
              >
                {({innerRef, placeholder, droppableProps}, {isDraggingOver}) =>
                  <Ref innerRef={innerRef}>
                    <Grid.Column
                      className={cx('iba-dashboard-column', {'dragging-over': isDraggingOver})}
                      {...droppableProps}
                    >
                      {column.map((widget, widgetIndex) => {
                        const widgetErrors = find(errors, {type: 'widget', columnIndex, widgetIndex})?.errors;
                        const widgetId = editable ? this.getWidgetId(widget) : columnIndex + '_' + widgetIndex;
                        return (
                          <Draggable
                            key={widgetId}
                            draggableId={widgetId}
                            index={widgetIndex}
                            isDragDisabled={!editable}
                          >
                            {({innerRef, draggableProps, dragHandleProps}) =>
                              <Ref innerRef={innerRef}>
                                <Grid className='iba-widget-container' {...draggableProps}>
                                  <Grid.Column className='iba-widget-header' textAlign='center' width={16}>
                                    <Header
                                      size='small'
                                      color={!isEmpty(widgetErrors) ? 'red' : undefined}
                                      {...dragHandleProps}
                                    >
                                      {widget.label}
                                      {widget.description &&
                                        <Popup
                                          trigger={<sup><Icon name='help circle' /></sup>}
                                          content={widget.description}
                                          offset={[-13, 0]}
                                        />
                                      }
                                    </Header>
                                    {editable &&
                                      <div>
                                        <DashboardWidgetActions
                                          widget={widget}
                                          columnIndex={columnIndex}
                                          widgetIndex={widgetIndex}
                                          errors={widgetErrors}
                                          updateWidget={updateWidget}
                                          setWidgetModalProps={setWidgetModalProps}
                                          removeWidget={removeWidget}
                                        />
                                      </div>
                                    }
                                  </Grid.Column>
                                  <Grid.Column width={16}>
                                    {!isEmpty(widgetErrors) &&
                                      <Message
                                        error
                                        icon='warning sign'
                                        content='This widget contains errors. Edit the widget to fix the errors.'
                                      />
                                    }
                                    <Widget
                                      widget={widget}
                                      probes={probes}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </Ref>
                            }
                          </Draggable>
                        );
                      })}
                      {placeholder}
                      {editable &&
                        <AddWidgetPlaceholder
                          hidden={isDragging}
                          columnIndex={columnIndex}
                          setWidgetModalProps={setWidgetModalProps}
                          addWidget={addWidget}
                        />
                      }
                    </Grid.Column>
                  </Ref>
                }
              </Droppable>
            )}
          </Grid>
        </DragDropContext>
        <WidgetModal
          open={false}
          onClose={() => setWidgetModalProps({open: false})}
          probes={probes}
          showCreateAnother={false}
          {...widgetModalProps}
        />
      </Fragment>
    );
  }
}

const DashboardWidgetActions = observer(({
  widget, columnIndex, widgetIndex, errors, setWidgetModalProps, updateWidget, removeWidget
}) => {
  return (
    <ActionsMenu
      size='tiny'
      floated='right'
      items={[
        {
          icon: 'edit',
          title: 'Edit',
          onClick: () => setWidgetModalProps({
            open: true,
            mode: 'update',
            widget,
            errors,
            onSuccess: ({result: {widget}}) => updateWidget(widget, columnIndex, widgetIndex),
          }),
        },
        {
          icon: 'trash',
          title: 'Remove',
          onClick: () => removeWidget(columnIndex, widgetIndex),
        },
      ]}
    />
  );
});

const AddWidgetPlaceholder = observer(({hidden, columnIndex, setWidgetModalProps, addWidget, ...props}) => {
  const createNewWidgetButton = (
    <Button
      fluid
      color='teal'
      icon='add circle'
      labelPosition='left'
      content='Create New Widget'
      onClick={() => setWidgetModalProps({
        open: true,
        mode: 'create',
        onSuccess: ({result: {widget}}) => {
          addWidget(widget, columnIndex);
        }
      })}
    />
  );
  return (
    <div className={cx('add-widget-placeholder', {hidden})} {...props}>
      <Placeholder className='add-widget-placeholder-background' fluid>
        {times(10, (index) => <Placeholder.Line key={index} />)}
      </Placeholder>
      <div className='add-widget-placeholder-actions'>
        {createNewWidgetButton}
      </div>
    </div>
  );
});
