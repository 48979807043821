import {observer} from 'mobx-react';
import {useContext, useMemo} from 'react';
import {map, filter, find} from 'lodash';
import {DropdownControl} from 'apstra-ui-common';

import {GRAPH_QUERY_FUNCTIONS} from '../pythonExpression/consts';
import GraphQueryToObjectConverter from '../pythonExpression/GraphQueryToObjectConverter';
import {START_QUERY_MAP} from './consts';
import humanizeString from '../humanizeString';
import QueryBuilderContext from './QueryBuilderContext';

import './QueryStartPath.less';

const QueryStartPath = observer(({query, className, isRoot, queryKeyPath}) => {
  const {addNode, predefinedQueries, replaceQueryValue} = useContext(QueryBuilderContext);
  const queryFunctionOptions = useMemo(() => {
    const options = map(GRAPH_QUERY_FUNCTIONS, ({name, description}) => ({
      key: name, value: name, text: name, description
    }));
    return isRoot ?
      filter(options, ({key}) => key !== 'optional') :
      options;
  }, [isRoot]);
  const predefinedQueryOptions = map(predefinedQueries, ({id: key, label}) => ({
    key, value: key, text: label || humanizeString(key),
  }));
  const onChoosePredefinedQuery = (value) => {
    const {query: queryString} = find(predefinedQueries, ({id}) => id === value);
    const queryObject = GraphQueryToObjectConverter.parse(queryString);
    replaceQueryValue(queryKeyPath, queryObject);
  };

  return (
    <div
      className={className}
    >
      <div className='query-start-path'>
        <span>{'Start by adding'}</span>
        <DropdownControl
          fluid
          value=''
          options={queryFunctionOptions}
          onChange={(value) => addNode(query, '', START_QUERY_MAP[value])}
          placeholder='start query path'
        />
      </div>
      <div className='query-start-path'>
        <span>{'or loading'}</span>
        <DropdownControl
          fluid
          search
          options={predefinedQueryOptions}
          value=''
          onChange={onChoosePredefinedQuery}
          placeholder='predefined query'
        />
      </div>
    </div>
  );
});

export default QueryStartPath;
