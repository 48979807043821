import {Button, Popup} from 'semantic-ui-react';

const QueryCollapseButton = ({collapsed, onClick}) => (
  <Popup
    trigger={
      <Button
        size='tiny'
        icon={collapsed ? 'chevron up' : 'chevron down'}
        onClick={() => onClick(!collapsed)}
      />
    }
    content={collapsed ? 'Expand' : 'Collapse'}
  />
);

export default QueryCollapseButton;
