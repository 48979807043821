import {TopologyConfigType} from '../consts';
import {Layout} from '../types';
import {getPadding} from '../utils';
import {LayoutAlgorithm} from './LayoutAlgorithm';

export class PadLayout extends LayoutAlgorithm {
  recalculateOwn = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    if (!children.length) return false;
    const {padding = 0} = config;
    const paddings = getPadding(padding);
    return this.assignValue(own, {
      width: children[0].width + paddings.left + paddings.right,
      height: children[0].height + paddings.top + paddings.bottom,
    });
  };

  recalculateChildren = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    if (!children.length) return false;
    const {padding = 0} = config;
    const paddings = getPadding(padding);
    this.assignValue(children[0], {
      left: paddings.left,
      top: paddings.top,
    });
    return this.assignValue(children[0], {
      width: own.width - paddings.left - paddings.right,
      height: own.height - paddings.top - paddings.bottom,
    });
  };
}
