import {get, keyBy, compact, isEqual} from 'lodash';
import {toJS} from 'mobx';

class UserStore {
  preferences = {};

  init(storedPreferences, updateUserPreferences) {
    this.preferences = keyBy(storedPreferences, 'key');
    this.updateUserPreferences = updateUserPreferences;
  }

  savePreferences(label) {
    const {key, value} = this.preferences[label];
    this.updateUserPreferences(key, value);
  }

  setFor(label, value) {
    const valueForLabel = this.preferences[label] || {key: label, value: {}};
    const existingValue = toJS(valueForLabel.value);
    const newValue = Object.assign({}, existingValue, value);
    // Only update value if differs
    if (!isEqual(existingValue, newValue)) {
      this.preferences[label] = Object.assign(valueForLabel, {value: newValue});
      this.savePreferences(label);
    }
  }

  setStoreValueFn(label) {
    return (value) => {
      this.setFor(label, value);
    };
  }

  getStoreValue([label, key]) {
    return get(this.preferences, compact([label, 'value', key]));
  }
}

const userStore = new UserStore();

export default userStore;
