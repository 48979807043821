import cx from 'classnames';
import {DropdownControl, MultiItemsControl} from 'apstra-ui-common';
import {filter, includes, isArray} from 'lodash';

import QueryMatcherValue from './QueryMatcherValue';
import {getDefaultValueForMatcher} from './utils';

import './QueryConditionValues.less';

const QueryConditionValues = ({values, onChange, isTag, attribute, matcher, matcherOptions}) => {
  const matcherOptionsForCondition = filter(matcherOptions, ({key}) => !includes(['=', '_or', '_and'], key));
  const addItem = () => {
    onChange('value', [...values, {matcher: 'eq', value: getDefaultValueForMatcher('eq')}]);
  };
  const removeItem = ({index}) => {
    onChange('value', filter(values, (_, idx) => idx !== index));
  };
  const onChangeMatcher = (value, index) => {
    const result = [...values];
    result[index].matcher = value;
    result[index].value = getDefaultValueForMatcher(value, attribute);
    onChange('value', result);
  };
  const onChangeMatcherValue = (name, value, index) => {
    const result = [...values];
    result[index].value = value;
    onChange(name, result);
  };

  return (
    <div
      className={cx('condition-values', 'condition-divider', matcher)}
    >
      <MultiItemsControl
        className='test'
        items={!isArray(values) ? [] : values}
        addItem={addItem}
        removeItem={removeItem}
        addButtonProps={{basic: true, size: 'tiny', labelPosition: null, floated: 'right'}}
        deleteButtonProps={{color: 'grey', size: 'small', name: 'trash'}}
        buttonText='Add Condition'
        noItemsMessage='There are no conditions.'
      >
        {({item, index}) =>
          <div className='matcher-block two'>
            <DropdownControl
              options={matcherOptionsForCondition}
              value={item.matcher}
              onChange={(value) => onChangeMatcher(value, index)}
              placeholder='Select matcher'
              search
            />
            <QueryMatcherValue
              value={item.value}
              isTag={isTag}
              attribute={attribute}
              matcher={item.matcher}
              onChange={(name, value) => onChangeMatcherValue(name, value, index)}
            />
          </div>
        }
      </MultiItemsControl>
    </div>
  );
};

export default QueryConditionValues;
