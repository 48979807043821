import {useMemo} from 'react';
import {observer} from 'mobx-react';
import {Button, Input, Dropdown} from 'semantic-ui-react';
import {range, map, some, uniq, filter, isArray, first} from 'lodash';
import cx from 'classnames';
import {Field, onEnterKeyHandler} from 'apstra-ui-common';

import TagsControl from '../../components/TagsControl';
import {useCablingMapStore} from '../store/useCablingMapStore';
import Node from '../store/Node';
import {trackNodesChange, useDevicesLabels} from '../utils';
import {deployModeOptions} from '../const';
import './NodeProperties.less';

// If all selected nodes have the same value for the given property
// it must be prepopulated in the form (e.g. color or device profile).
// Default (empty) value returned otherwise.
const getEqualValue = (nodes, property, unequalValue) => {
  const uniqs = uniq(map(nodes, property));
  return uniqs.length === 1 ? uniqs[0] : unequalValue;
};

const NodeProperties = ({nodes, availableDevices}) => {
  const {cablingMap} = useCablingMapStore();
  const devicesLabels = useDevicesLabels(availableDevices);

  const {assignedDevices, tags: knownTags, deviceProfiles} = cablingMap;
  const isBatch = isArray(nodes);
  const multipleNodes = isBatch && nodes.length > 1;

  const result = useMemo(
    () => {
      // If more than one node are selected, ...
      if (multipleNodes) {
        // build the batch object that would represent common properties for all the nodes
        const commonProps = {
          color: getEqualValue(nodes, 'color', ''),
          deviceProfileId: getEqualValue(nodes, 'deviceProfileId', -1),
          deployMode: getEqualValue(nodes, 'deployMode', ''),
          initialDeviceProfileId: some(nodes, 'initialDeviceProfileId'),
          tags: []
        };
        return new Node(commonProps, cablingMap);
      } else {
        // or just pick the first (or the only) node otherwise
        return (first(nodes) || nodes);
      }
    },
    []); // eslint-disable-line react-hooks/exhaustive-deps

  // If some of the selected nodes are external, no device profile form
  // must be shown
  const hasExternals = isBatch ? some(nodes, 'isExternal') : nodes.isExternal;

  // Assignable devices corresponding to nodes device profile
  const devicesOptions = useMemo(
    () => result?.deviceProfile ?
      map(
        filter(
          availableDevices,
          ({device_profile_id: dpId, id}) => (
            // Only devices of appropriate profile must be filtered in
            dpId === result.deviceProfile?.globalCatalogId &&
            // Not taken or assigned to the node
            (!assignedDevices[id] || id === result.systemId)
          ),
        ),
        ({id}) => ({
          value: id,
          text: devicesLabels[id]
        })
      ) :
      [],
    [
      availableDevices, result?.deviceProfile, assignedDevices,
      result?.systemId, devicesLabels
    ]
  );

  // Options to fill device profile DDL
  const dpOptions = useMemo(
    () => (
      !hasExternals && map(deviceProfiles, ({id, label}) => ({
        text: label,
        value: id
      }))),
    [hasExternals, deviceProfiles]
  );

  const closeOnEnter = onEnterKeyHandler(() => cablingMap.paintNode());

  // Register a single property change
  const trackChange = (property, value) => {
    trackNodesChange(isBatch ? nodes : [nodes], cablingMap, property, value);

    if (multipleNodes) {
      result.setProperty(property, value);
    }
  };

  return (
    <div className='ctrl-node-properties'>
      <ul className='color-picker'>
        {map([null, ...range(1, 8)], (index) => (<Button
          key={index}
          as='li'
          className={cx(`color-${index}`, {selected: result.color === index})}
          onClick={() => trackChange('color', index)}
        />))}
      </ul>
      {!multipleNodes &&
        <>
          <Field
            label='Name'
          >
            <Input
              fluid
              value={result.label}
              onChange={(event) => trackChange('label', event.target.value)}
              onKeyDown={closeOnEnter}
            />
          </Field>
          <Field
            label='Hostname'
          >
            <Input
              fluid
              value={result.hostname}
              onChange={(event) => trackChange('hostname', event.target.value)}
              onKeyDown={closeOnEnter}
            />
          </Field>
        </>
      }
      {!hasExternals &&
        <>
          <Field
            label='Device Profile'
          >
            <Dropdown
              fluid
              selection
              value={result.deviceProfileId}
              onChange={(event, {value}) => trackChange('deviceProfileId', value)}
              options={dpOptions}
              onKeyDown={closeOnEnter}
            />
          </Field>
          {!multipleNodes &&
            <Field
              label='System'
            >
              <Dropdown
                fluid
                search
                selection
                selectOnNavigation={false}
                clearable
                lazyLoad
                value={result.systemId}
                onChange={(event, {value}) => trackChange('systemId', value)}
                options={devicesOptions}
                className='node-system-id'
              />
            </Field>
          }
        </>
      }
      <Field
        label='Deploy Mode'
      >
        <Dropdown
          fluid
          selection
          selectOnNavigation={false}
          lazyLoad
          value={result.deployMode}
          onChange={(event, {value}) => trackChange('deployMode', value)}
          options={deployModeOptions}
        />
      </Field>
      <Field label='Tags'>
        <TagsControl
          fluid
          knownTags={knownTags}
          onChange={(tags) => trackChange('tags', [...tags])}
          value={result.tags}
        />
      </Field>
    </div>
  );
};

export default observer(NodeProperties);
