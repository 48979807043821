import {Form} from 'semantic-ui-react';
import {Checkbox, Field} from 'apstra-ui-common';

import './renderRadioButtonEnum.less';

const renderRadioButtonEnum = ({name, value = null, schema, required, disabled, errors, fieldProps,
  onChange}) => {
  const options = schema?.oneOf ?? [];

  if (schema.type !== 'enum-radiobutton' || !options || !options.length) return null;

  return (
    <Field
      className='ept-enum-radiobutton'
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      {
        options.map(({const: option, title}) =>
          <Form.Field key={option}>
            <Checkbox
              radio
              name={name}
              label={title}
              disabled={disabled}
              checked={value === option}
              onClick={() => onChange((!required && value === option) ? null : option)}
            />
          </Form.Field>
        )
      }
    </Field>
  );
};

export default renderRadioButtonEnum;
