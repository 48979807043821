import {observable, makeObservable, action, computed} from 'mobx';
import {
  reduce, map, max, forEach, values, keyBy, uniq, flatten, filter, transform, countBy,
  compact, sortBy, omit, groupBy, every, mapValues
} from 'lodash';

import {deserialize} from 'serializr';

import Node from './Node';
import Link from './Link';
import AggregateLink from './AggregateLink';
import {fixNodeLinks, generateLocalId, makeUniqueNodeLabel} from '../utils';
import Positioner from '../Positioner';
import ChangesStore from './ChangesStore';
import Step from './Step';
import {ctrlStages, ctrlSystemTypes, ctrlDefaultNodeLabel, ctrlDefaultExtNodeLabel, ctrlEntityTypes,
  ctrlNodeWidth, ctrlGridStep, ctrlNodeHeight} from '../const.js';
import {DeviceProfile} from '../../deviceProfiles/stores';

const entities = {
  [ctrlEntityTypes.NODE]: Node,
  [ctrlEntityTypes.LINK]: Link,
  [ctrlEntityTypes.AGGREGATE]: AggregateLink
};

class CablingMapStore {
  @observable changes;

  @observable nodes = {};
  @observable links = {};
  @observable aggregateLinks = {};
  @observable availableDevicesById = {};

  @observable editedNodeId = null;
  @observable optionsAreShown = false;
  @observable nodesLinksProps = null;

  @observable positioner = new Positioner([]);

  @observable highlightedId = null;

  #tags = [];

  get #collections() {
    return {
      [ctrlEntityTypes.NODE]: this.nodes,
      [ctrlEntityTypes.LINK]: this.links,
      [ctrlEntityTypes.AGGREGATE]: this.aggregateLinks
    };
  }

  @computed
  get tags() {
    return compact(uniq([
      ...this.#tags,
      ...flatten(map(this.nodes, 'tags')),
      ...flatten(map(this.links, 'usedTags')),
      ...flatten(map(this.aggregateLinks, 'usedTags'))
    ]));
  }

  @computed
  get assignedDevices() {
    return keyBy(this.nodes, 'systemId');
  }

  @computed
  get usedIps() {
    return countBy(
      flatten(
        map(
          [...values(this.links), ...values(this.aggregateLinks)],
          'usedIps'
        )
      )
    );
  }

  @computed.struct
  get nodesSorted() {
    return sortBy(this.nodes, 'id');
  }

  @computed.struct
  get linksSorted() {
    return sortBy(this.links, 'id');
  }

  @computed.struct
  get aggregateLinksSorted() {
    return sortBy(this.aggregateLinks, 'id');
  }

  @computed.struct
  get nodesLinks() {
    return transform(
      [...this.linksSorted],
      (acc, link) => {
        forEach(link.endpoints, ({nodeId}) => (acc[nodeId] ??= []).push(link));
      },
      {}
    );
  }

  @computed.struct
  get nodesAggregateLinks() {
    return transform(
      [...this.aggregateLinksSorted],
      (acc, link) => {
        forEach(link.endpoints, ({nodeId}) => (acc[nodeId] ??= []).push(link));
      },
      {}
    );
  }

  @computed
  get visibleLinks() {
    return filter([...this.linksSorted, ...this.aggregateLinksSorted], {isAggregated: false});
  }

  @computed
  get linksByAggregateId() {
    return groupBy(this.linksSorted, 'aggregateId');
  }

  @computed
  get lagLinks() {
    return groupBy(
      filter(this.linksSorted, 'isAggregated'),
      'aggregateId'
    );
  }

  @computed
  get nodesIds() {
    return map(this.nodesSorted, 'id');
  }

  @computed
  get linksIds() {
    return map(this.linksSorted, 'id');
  }

  @computed
  get aggregateLinksIds() {
    return map(this.aggregateLinksSorted, 'id');
  }

  @computed
  get nodesLabelsCounts() {
    return countBy(this.nodesSorted, 'label');
  }

  // Since there is no references to device ports in links
  // we have to find 'em via transformation+intf_name in device profile
  @computed
  get dpInterfacePorts() {
    return transform(
      this.deviceProfiles,
      (result, profile, id) => {
        // Filling in the map for device profiles ids in BP and global catalog
        result[id] = transform(
          profile.ports,
          (portsMap, port) => {
            forEach(port.transformations, ({id, interfaces}) => {
              forEach(interfaces, ({name}) => {
                portsMap[`${id}:${name}`] = port;
              });
            });
          },
          {});
      },
      {});
  }

  @computed
  get isValid() {
    return every(this.nodes, 'isValid');
  }

  @action
  highlightNode = (nodeId) => {
    this.highlightedId = nodeId;
  };

  @action
  toggleOptions(forceHide = false) {
    this.optionsAreShown = forceHide ? false : !this.optionsAreShown;
  }

  @action
  manageLinks = (data) => {
    this.nodesLinksProps = data;
  };

  @action
  hidePopups(hideNodeProperties = true, hideBatchProperties = true) {
    if (hideBatchProperties) this.toggleOptions(true);
    if (hideNodeProperties) this.paintNode();
  }

  @action
  reset() {
    // Removes all existing nodes and links
    this.nodes = {};
    this.links = {};
    this.aggregateLinks = {};
    this.#tags = [];
    this.editedNodeId = null;
    this.positioner = new Positioner([]);
    this.changes.reset();
  }

  @action
  generateDummyTopology() {
    this.reset();

    const nodes = [];
    const links = [];
    let prevNode = null;

    for (let x = 0; x < 20; x++) {
      for (let y = 0; y < 50; y++) {
        const node = new Node({
          id: generateLocalId(),
          label: `node-${x + 1}x${y + 1}`,
          position: {
            x: x * (ctrlNodeWidth + ctrlGridStep),
            y: y * (ctrlNodeHeight + ctrlGridStep)
          },
          systemType: ctrlSystemTypes.INTERNAL,
          deviceProfileId: 'rEEGuxepN5SMWOAhN54'
        }, this);
        nodes.push(node);

        if (prevNode) {
          const link = new Link(node, prevNode, this);
          links.push(link);
        }
        prevNode = node;
      }
    }

    this.nodes = keyBy(nodes, 'id');
    this.links = keyBy(links, 'id');

    forEach(this.nodes, (node) => fixNodeLinks(node));

    this.updateArea();
  }

  @action
  moveNodesBy(nodesIds, offset) {
    forEach(nodesIds, (id) => this.nodes[id]?.moveBy(offset));
    this.updateArea();
  }

  @action
  dropNodes(selection, snapToGrid) {
    if (snapToGrid) {
      // * each node must be aligned to grid if 'snapped' is true.
      forEach(selection.selectedNodesIds, (id) => this.nodes[id]?.snapToGrid());
    }

    // * cummulative change must be added to the history
    this.changes.register(selection.fixFinalState(this.nodes));
  }

  serialize() {
    // Serializes created topology to json
    return {
      name: '',
      data: {
        nodes: map(this.nodes, (node) => node.serialize()),
        links: map(this.links, (link) => link.serialize()),
        aggregateLinks: map(this.aggregateLinks, (link) => link.serialize())
      }
    };
  }

  @action
  restore({nodes, links, tags, aggregateLinks, deviceProfiles, customData, availableDevices}) {
    this.deviceProfiles = keyBy(
      map(deviceProfiles, (profile) => {
        const deviceProfile = deserialize(DeviceProfile, profile);
        const portGroups = mapValues(
          groupBy(deviceProfile.ports, 'slotId'),
          (ports) => groupBy(ports, 'panelId')
        );

        return Object.assign(
          deviceProfile,
          {
            portGroups,
            globalCatalogId: profile.device_profile_id
          }
        );
      }),
      'id'
    );

    this.availableDevicesById = keyBy(availableDevices, 'id');

    this.#tags = [...tags];

    const positionedNodes = [];
    const unpositionedNodes = [];

    // Restore nodes
    const nodesParsed = reduce(nodes, (result, node) => {
      // Mixes user preferences in
      const nodeData = customData ? {...node, ...{user_data: customData?.[node?.id]}} : node;
      const newNode = Node.deserialize(nodeData, this);

      // Separates positioned nodes from non
      (newNode.isPositioned ? positionedNodes : unpositionedNodes).push(newNode);

      result[newNode.id] = newNode;
      return result;
    }, {});

    this.nodes = nodesParsed;

    // Restore links
    this.links = keyBy(
      map(links, (link) => Link.deserialize(link, this)), 'id'
    );

    // Restore aggregated links
    this.aggregateLinks = keyBy(
      map(aggregateLinks, (link) => AggregateLink.deserialize(link, this)), 'id'
    );

    // If nodes don't contain positioning info - they must be placed automatically
    const positionedCount = positionedNodes.length;
    if (positionedCount !== nodesParsed.length) {
      if (positionedCount > 0) {
        // Some of the nodes have positioning data - have to arrange the rest
        forEach(unpositionedNodes, (node) => {
          this.positioner.positionRandomly(node, positionedNodes);
          positionedNodes.push(node);
        });
      } else {
        // None of the nodes are positioned - arrange them all from scratch
        this.positioner.arrangeNodes(nodesParsed, this.links);
      }
    }

    this.updateArea();
  }

  // Recalculates interfaces positions affected by repositioning of given nodes
  @action
  repositionInterfacesOn() {
    throw new Error('Store reposition inferfaces called!');
  }

  @action
  updateArea() {
    this.positioner.update(this.nodesSorted);
  }

  @action
  addNode({
    id = generateLocalId(),
    label,
    tags = [],
    position = this.positioner.placeNewAmong(this.nodes),
    systemType = ctrlSystemTypes.INTERNAL
  }) {
    if (!label) {
      label = makeUniqueNodeLabel(
        this.nodes,
        systemType === ctrlSystemTypes.INTERNAL ? ctrlDefaultNodeLabel : ctrlDefaultExtNodeLabel
      );
    }
    const node = new Node({id, label, tags, position, systemType}, this);
    this.injectNode(node);
    this.updateArea();

    this.changes.add(node);
  }

  @action
  injectNode(node) {
    this.nodes = {...this.nodes, [node.id]: node};
  }

  @action
  extractLinksFrom(aggregateId, change) {
    // Releases all the links aggregate consists of. Usually aggregate must be deleted
    // as empty right after.
    forEach(
      // For each links in aggregate
      filter(this.links, {aggregateId}),
      (link) => {
        if (change) {
          // If changes are tracked, add the new step
          const step = Step.modification(null, link);
          link.setAggregateId();
          step.setResult(link);
          change.push(step);
        } else {
          // Extract from the aggregate
          link.setAggregateId();
        }
      }
    );
  }

  @action
  deleteNode(id, batchChange) {
    // Collect all changes into one batch
    const change = batchChange || [];
    const node = this.nodes[id];

    // Remove aggregate links connected with the node
    forEach(node.myAggregateLinks, (link) => {
      this.extractLinksFrom(link.id, change);
      change.push(Step.deletion(link));
      this.deleteLink(link);
    });

    // Remove physical links connected with the node
    forEach(node.myLinks, (link) => {
      change.push(Step.deletion(link));
      this.deleteLink(link);
    });

    // Delete the node
    change.push(Step.deletion(this.nodes[id]));

    if (!batchChange) {
      this.changes.register(change);
      this.updateArea();
      this.nodes = omit(this.nodes, id);
    }
  }

  @action
  paintNode(node) {
    // Toggles node properties popup
    const id = node?.id;
    this.editedNodeId = id === this.editedNodeId ? null : id;
  }

  @action
  cloneSelected(selectedNodesIds) {
    // Clones selected nodes on the canvas
    const selectedNodes = selectedNodesIds.map((id) => this.nodes[id]);
    const offsetY = this.positioner.belowOffset(selectedNodes);
    const batchChange = [];

    // Maps old node ids to new ones
    const nodeIdsMap = {};

    // Clone nodes
    forEach(selectedNodes, (originNode) => {
      const clone = originNode.clone();
      nodeIdsMap[originNode.id] = clone.id;

      clone.label = makeUniqueNodeLabel(this.nodes, originNode.label, true);
      clone.position.y += offsetY;
      this.injectNode(clone);
      batchChange.push(Step.creation(clone));
    });

    // Clone links
    const linksToBeCloned = filter(
      [...values(this.aggregateLinks), ...values(this.links)],
      (link) => link.fallsWithin(selectedNodesIds)
    );

    const clonedAggregatedLinks = {};
    forEach(linksToBeCloned, (link) => {
      const clone = link.cloneFor(nodeIdsMap);
      if (clone.isAggregate) {
        this.aggregateLinks[clone.id] = clone;
        clonedAggregatedLinks[link.id] = clone.id;
      } else {
        if (link.isAggregated) {
          clone.aggregateId = clonedAggregatedLinks[link.aggregateId];
        }
        this.links[clone.id] = clone;
      }
      batchChange.push(Step.creation(clone));
    });

    this.updateArea();
    this.changes.register(batchChange);

    return values(nodeIdsMap);
  }

  @action
  deleteSelected(selectedNodesIds) {
    const batchChange = [];
    forEach(selectedNodesIds, (id) => {
      this.deleteNode(id, batchChange);
    });
    this.nodes = omit(this.nodes, selectedNodesIds);
    this.updateArea();
    this.changes.register(batchChange);
  }

  @action
  bringNodeOnTop(id) {
    if (!this.nodes[id]) return;
    this.nodes[id]._order = 1 + max(map(this.nodes, '_order'));
  }

  @action
  addLink(link) {
    const collection = link.isAggregate ? this.aggregateLinks : this.links;
    this[link.isAggregate ? 'aggregateLinks' : 'links'] = {...collection, [link.id]: link};
  }

  @action
  deleteLink(link) {
    const collection = link.isAggregate ? this.aggregateLinks : this.links;
    this[link.isAggregate ? 'aggregateLinks' : 'links'] = omit(collection, link.id);
  }

  @action
  commitLinkChanges(link) {
    // Applies changes made to link:
    if (link.stage === ctrlStages.DELETE) {
      // Delete marked for deletion
      this.deleteLink(link);
    } else {
      // Cleans up backed-up data and resets link's state
      link.commit();
    }
  }

  @action
  discardLinkChanges(link) {
    // Discards changes made to link:
    if (link.stage === ctrlStages.ADD) {
      // Delete marked for deletion
      this.deleteLink(link);
    } else {
      // Cleans up backed-up data and resets link's state
      link.restore();
    }
  }

  @action
  setAggregateFor(links, aggregateId, placement) {
    if (!links?.length) {
      // Do nothing if no links selected
      return;
    }

    const aggregateNodes = uniq(
      flatten(
        map(
          links,
          ({endpoint1, endpoint2}) => [endpoint1.nodeId, endpoint2.nodeId]
        )
      )
    );
    const affectedNodes = map(aggregateNodes, (nodeId) => (this.nodes[nodeId]));

    if (aggregateId === null) {
      // Create new aggregate link to assign to selection if no target provided
      const aggregate = new AggregateLink(affectedNodes, this, placement);
      aggregateId = aggregate.id;
      aggregate.label = aggregateId;
      aggregate.stage = ctrlStages.ADD;
      this.addLink(aggregate);
    } else if (aggregateId) {
      const aggregate = this.aggregateLinks[aggregateId];
      if (aggregate) {
        aggregate.updateEndpointsWith(affectedNodes, placement);
      }
    }
    forEach(links, (link) => link.setAggregateId(aggregateId));
  }

  @action
  applyChange(change) {
    // Applies change from the history (undo/redo operations)
    if (!change?.length) return;

    const affectedNodesIds = [];
    // For each step in the change
    forEach(change, (step) => {
      const collection = this.#collections[step.type];

      // Preserving the target item
      let item = collection[step.id];
      if (!step.isDelete) {
        item = (entities[step.type]).deserialize(step.item, this);
        collection[step.id] = item;
      }

      // For affected nodes interfaces positions must be recalculated
      if (step.isNode) {
        affectedNodesIds.push(step.id);
      }

      if (step.isDelete) {
        delete collection[step.id];
      }
    });
    this.updateArea();
    return affectedNodesIds;
  }

  @action
  undo() {
    return this.applyChange(this.changes.undo());
  }

  @action
  redo() {
    return this.applyChange(this.changes.redo());
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    this.changes = new ChangesStore(rootStore);
  }
}

export default CablingMapStore;
