import {get, isNumber} from 'lodash';
import {formatBytesPerSecond, formatNumber} from 'apstra-ui-common';

import {
  TRAFFIC_HEAT_LAYER,
  TRAFFIC_PROBE_STAGE,
} from '../../../iba/components/graphs/trafficUtils';
import NodeAnomalyInfo from './NodeAnomalyInfo';
import {ConfigTemplate, SystemType, Tags} from './ValueRenderers';
import {TooltipItem} from './TooltipItem';

import './NodeTooltipContent.less';

const formatPercentage = (num) => {
  return `${formatNumber(num.toFixed(2), {units: '%', short: true})}`;
};

const renderTrafficHeatData = ({layer, node}) => {
  const {anomaly, stages, traffic_data: trafficData} = node;
  const stageItem = stages?.[TRAFFIC_PROBE_STAGE.systemInterfaceCounters];
  if (!stageItem && !trafficData) {
    return null;
  }
  return (
    <>
      <TooltipItem
        label='Total TX'
        value={`
          ${formatBytesPerSecond((stageItem || trafficData).aggregate_tx_bps)}
          (${formatPercentage((stageItem || trafficData).aggregate_tx_utilization)})
        `}
      />
      <TooltipItem
        label='Total RX'
        value={`
          ${formatBytesPerSecond((stageItem || trafficData).aggregate_rx_bps)}
          (${formatPercentage((stageItem || trafficData).aggregate_rx_utilization)})
        `}
      />
      <TooltipItem
        label='Max Link TX'
        value={`${formatPercentage((stageItem || trafficData).max_ifc_tx_utilization)}`}
      />
      <TooltipItem
        label='Max Link RX'
        value={`${formatPercentage((stageItem || trafficData).max_ifc_rx_utilization)}`}
      />
      {anomaly && <NodeAnomalyInfo layer={layer} />}
    </>
  );
};

export const NodeTooltipContent = ({node, layer}) => {
  return (
    <>
      <div className='node-tooltip-label'>{node?.label}</div>
      <TooltipItem label='Tags' value={<Tags tags={node.tags} />} />
      <TooltipItem hideEmpty label='Type' value={node.system_type && <SystemType systemType={node.system_type} />} />
      <TooltipItem hideEmpty label='Device Profile' value={get(node, ['device_profile', 'label'])} />
      <TooltipItem hideEmpty label='Role' value={node.role} />
      <TooltipItem hideEmpty label='Hostname' value={node.hostname} />
      <TooltipItem
        hideEmpty
        label='Config Template'
        value={node['config-template'] && <ConfigTemplate configTemplate={node['config-template']} />}
      />
      {isNumber(node.port_channel_id_min) && isNumber(node.port_channel_id_max) &&
        <TooltipItem
          label='Port Channel ID Range'
          value={`${node.port_channel_id_min}-${node.port_channel_id_max}`}
        />
      }
      {layer === TRAFFIC_HEAT_LAYER && renderTrafficHeatData({node, layer})}
    </>
  );
};
