import {useContext} from 'react';
import {map, filter} from 'lodash';
import {Message} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import IBAContext from '../IBAContext';

export default function PredefinedDashboardsLinks({dashboards}) {
  const {blueprintId} = useContext(IBAContext);
  const predefinedDashboards = filter(dashboards, ({predefined_dashboard: dashboard}) => !!dashboard);
  return (
    <Message.List>
      {map(predefinedDashboards, (({id, label}) =>
        <Message.Item key={id}>
          <Link to={`/blueprints/${blueprintId}/analytics/dashboards/${id}`}>
            {label}
          </Link>
        </Message.Item>
      ))}
    </Message.List>
  );
}
