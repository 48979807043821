import {ResourceModal} from 'apstra-ui-common';
import {observer} from 'mobx-react';
import React from 'react';
import {Grid, Message} from 'semantic-ui-react';

import {ProcessorDescription} from './ProcessorDescription';
import {ProcessorEditor} from './ProcessorEditor';
import {ProcessorSelectorTree} from './ProcessorSelectorTree';
import {AddProcessorModalProps, useStore} from './Store';

import './ProcessorModal.less';

// modifies props: errors, processor, probe

export const ProcessorModal: React.FC<AddProcessorModalProps> = observer((props) => {
  const {
    errors = [],
    mode = 'create',
    onClose,
    onSuccess,
    open,
    helpPageId
  } = props;
  const store = useStore({...props, errors, mode});
  const shouldRenderProcessorList = mode === 'create';
  return (
    <ResourceModal
      helpPageId={helpPageId}
      mode={mode}
      resourceName='Processor'
      titlesByMode={{create: 'Add', update: 'Edit', clone: 'Clone'}}
      actionsByMode={{create: 'Add', update: 'Update', clone: 'Clone'}}
      open={open}
      onClose={onClose}
      resetState={store.resetState}
      submit={store.submit}
      submitAvailable={store.isValid}
      onSuccess={onSuccess}
      notifyOnSuccess={false}
      showCreateAnother={false}
      size='large'
    >
      {() =>
        <Grid className='processor-modal'>
          {shouldRenderProcessorList &&
            <Grid.Column width={4}>
              <ProcessorSelectorTree store={store} />
            </Grid.Column>
          }
          <Grid.Column width={shouldRenderProcessorList ? 6 : 6}>
            <ProcessorEditor store={store} />
          </Grid.Column>
          <Grid.Column width={shouldRenderProcessorList ? 6 : 10} className='processor-description'>
            {store.processorDefinition && [
              !store.availableProcessorTypes[store.processorDefinition.name] &&
                <Message
                  key='availability-warning'
                  warning
                  content='There are no existing processors that can be connected to a processor of this type.'
                />,
              <ProcessorDescription
                key='description'
                store={store}
              />
            ]}
          </Grid.Column>
        </Grid>
      }
    </ResourceModal>
  );
});
