export type ProcessorDefinition = {
  label: string;
  name: string;
  description: string;
  inputs: ProcessorDefinitionInputMap;
  outputs: ProcessorDefinitionOutputMap;
  schema: SchemaType;
  category: [string, string];
  examples?: ProcessorExampleType[];
  property_categories?: ProcessorPropertiesCategoryType;
};

export type ProcessorPropertiesCategoryType = Record<string, ProcessorPropertyCategoryType>;
export type ProcessorPropertyCategoryType = 'graph' | 'telemetry' | 'processing';
export const PROCESSOR_PROPERTY_CATEGORIES: ProcessorPropertyCategoryType[] = ['graph', 'telemetry', 'processing'];

export type ProcessorDefinitionInputType = {
  types: ProcessorValueType[];
  required: boolean;
};
export type ProcessorDefinitionInputMap = Record<string, ProcessorDefinitionInputType>;

export type ProcessorDefinitionOutputType = {
  description: string;
  required: boolean;
  types: ProcessorValueType[];
};
export type ProcessorDefinitionOutputMap = Record<string, ProcessorDefinitionOutputType>;

export type ProcessorValueType = {
  dynamic: boolean;
  type: 'table' | 'string';
  keys: any;
  values: any;
};

export type SchemaType = {
  type: string;
  properties: PropertyDescriptionMap;
}

export type PropertyDescription = {
  title: string;
  description: string;
  anyOf?: any[];
  additionalProperties?: PropertyDescriptionMap;
  default?: any;
}

export type PropertyDescriptionMap = Record<string, PropertyDescription>;

export type Probe = {
  anomaly_count: number;
  description: string;
  disabled: boolean;
  host_node: string;
  iba_unit: string;
  id: string;
  label: string;
  last_error: any | null;
  predefined_probe: string;
  probe_state: any;
  processors: ProbeProcessorType[];
  referencing_dashboards: Array<{
    id: string;
    label: string;
    predefined_dashboard: string;
  }>;
  stages: ProbeStageType[];
  state: 'error' | 'operational';
  task_error: string;
  task_state: 'running';
}

export type ProbeProcessorType = {
  inputs: ProcessorInputMap;
  name: string;
  outputs: Record<string, string>,
  properties: Record<string, any>;
  task_error?: any;
  type: string;
}

export type ProbeStageType = {
  anomaly_count: number;
  description: string;
  enable_metric_logging: boolean;
  graph_annotation_properties: any;
  name: string;
  retention_duration: number;
  units: any;
  warnings: number;
}

export type ProcessorInputType = {
  column?: string;
  stage?: string;
};

export type ProcessorInputMap = Record<string, ProcessorInputType>;

export type ProcessorCategory = {
  description: string;
  label: string;
  name: string;
  subcategories: Record<string, ProcessorSubcategory>;
};

export type ProcessorCategories = Record<string, ProcessorCategory>;

export type ProcessorSubcategory = {
  description: string;
  label: string;
  name: string;
};

export type ProcessorExampleType = {
  inputs: Record<string, ProcessorExampleInputType[]>;
  name: string;
  outputs: Record<string, ProcessorExampleOutputType[]>;
  properties: Record<string, any>;
}

export type ProcessorExampleInputType = {
  keys: any;
  values: any;
}

export type ProcessorExampleOutputType = {
  keys: any;
  values: any;
}
