import React from 'react';

import './TooltipItem.less';

type TooltipItemProps = {
  hideEmpty?: boolean;
  label: string;
  value: React.ReactElement;
}

export const TooltipItem: React.FC<TooltipItemProps> = ({hideEmpty, label, value}) => {
  if (hideEmpty && !value) {
    return null;
  }
  return (
    <div className='tooltip-item'>
      <span className='tooltip-item-name'>{label}</span><span>{value || 'n/a'}</span>
    </div>
  );
};
