import {Component} from 'react';
import {observer} from 'mobx-react';
import {action, observable, makeObservable, runInAction} from 'mobx';
import {isFunction, has} from 'lodash';
import {Checkbox, PermissionChecker,
  hasBlueprintPermissions, interpolateRoute, notifier, request} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

@observer
export default class ProbeToggle extends Component {
  static contextType = IBAContext;

  @observable toggleInProgress = false;

  @action
  toggleProbeDisabled = async () => {
    const {blueprintId, routes} = this.context;
    const {probe, refetchData} = this.props;
    let error = null;
    this.toggleInProgress = true;
    try {
      await request(
        interpolateRoute(routes.probeDetails, {blueprintId, probeId: probe.id}),
        {method: 'PATCH', body: JSON.stringify({disabled: !probe.disabled})}
      );
    } catch (e) {
      error = e;
    }
    if (!error) {
      notifier.notify({message: `Probe successfully ${probe.disabled ? 'enabled' : 'disabled'}`});
    } else {
      notifier.showError(error);
    }
    try {
      if (isFunction(refetchData)) {
        await refetchData();
      }
    } finally {
      runInAction(() => {
        this.toggleInProgress = false;
      });
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {probe, value, refetchInProgress} = this.props;
    const {blueprintPermissions} = this.context;
    const {toggleInProgress, toggleProbeDisabled} = this;
    const isRefetchInProgress = has(refetchInProgress, probe.id);
    const disabled = toggleInProgress || isRefetchInProgress;

    return (
      <PermissionChecker
        hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
      >
        <Checkbox
          toggle
          checked={value}
          aria-label={disabled ? null : value ? 'Enabled' : 'Disabled'}
          disabled={disabled}
          onChange={toggleProbeDisabled}
        />
      </PermissionChecker>
    );
  }
}
