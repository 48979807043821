import {useMemo} from 'react';
import {filter, groupBy, includes, keyBy, map, pick, transform, uniqBy, values} from 'lodash';

import {ALL_NODE_ROLES} from '../../../../roles';
import {IAccessGroup, ILink, INode, IPlane, IPod, IRack, ISecurityZone, IVlan, TopologyData,
  TopologySettingsType} from '../types';
import {processLinks} from '../utils';

type UseTopologyData = (data: {
  accessGroups: IAccessGroup[],
  links: ILink[],
  nodes: INode[],
  racks: IRack[],
  planes: IPlane[],
  securityZones?: ISecurityZone[],
  vlans?: IVlan[],
  pods: IPod[],
  topologySettings: TopologySettingsType,
  selectedPlaneId?: string,
}) => TopologyData;

const restoreExternalRouterRole = (node: INode): INode => {
  if (node.role === ALL_NODE_ROLES.GENERIC && node.external) {
    return {...node, role: ALL_NODE_ROLES.EXTERNAL_GENERIC};
  }
  return node;
};

export const useTopologyData: UseTopologyData = ({
  accessGroups, links: rawLinks,
  nodes: nodesRaw, racks: racksRaw, planes, pods, securityZones, vlans,
  topologySettings, selectedPlaneId
}) => {
  const topologyData = useMemo(() => {
    const {nodes, pairs} = transform<INode, {nodes: INode[], pairs: Record<string, INode>}>(nodesRaw,
      ({nodes, pairs}, node) => {
        const partOf = node.part_of;
        if (partOf && includes([ALL_NODE_ROLES.ACCESS, ALL_NODE_ROLES.LEAF], node.role)) {
          pairs[partOf] ??= {
            id: partOf,
            role: (node.role === ALL_NODE_ROLES.LEAF) ? ALL_NODE_ROLES.LEAF_PAIR : ALL_NODE_ROLES.ACCESS_PAIR,
            'composed-of': [],
            ...pick(node, ['pod_id', 'rack_id', 'access_group_id'])
          };
          pairs[partOf]['composed-of']!.push(node.id);
        }
        if (includes([ALL_NODE_ROLES.LEAF_PAIR, ALL_NODE_ROLES.ACCESS_PAIR], node.role)) {
          return;
        }
        nodes.push(restoreExternalRouterRole(node));
      },
      {nodes: [], pairs: {}}
    );
    nodes.push(...values(pairs));
    const nodeByRoles = groupBy(nodes, 'role');
    const racks = racksRaw ?? map(uniqBy(nodesRaw, 'rack_id'), (node) => ({
      rack_id: node.rack_id,
      pod_id: node.pod_id,
      label: node.rack_id,
    }));
    const indexedRacks = racks && keyBy(filter(racks, 'rack_id'), 'rack_id');
    return {
      externalRouters: nodeByRoles[ALL_NODE_ROLES.EXTERNAL_GENERIC],
      superspinesByPlanes: groupBy(nodeByRoles[ALL_NODE_ROLES.SUPERSPINE], 'superspine_plane_id'),
      spinesByPods: groupBy(nodeByRoles[ALL_NODE_ROLES.SPINE], 'pod_id'),
      leafsByRacks: groupBy(nodeByRoles[ALL_NODE_ROLES.LEAF], 'rack_id'),
      leafPairsByRacks: groupBy(nodeByRoles[ALL_NODE_ROLES.LEAF_PAIR], 'rack_id'),
      accessSwitchesByRacks: groupBy(nodeByRoles[ALL_NODE_ROLES.ACCESS], 'rack_id'),
      genericsByRacks: groupBy(nodeByRoles[ALL_NODE_ROLES.GENERIC], 'rack_id'),
      accessSwitchesByAccessGroups: groupBy(nodeByRoles[ALL_NODE_ROLES.ACCESS], 'access_group_id'),
      accessSwitchPairsByAccessGroups: groupBy(nodeByRoles[ALL_NODE_ROLES.ACCESS_PAIR], 'access_group_id'),
      genericsByAccessGroups: groupBy(nodeByRoles[ALL_NODE_ROLES.GENERIC], 'access_group_id'),
      rackByPods: groupBy(indexedRacks, 'pod_id'),
      accessGroupsByRacks: groupBy(accessGroups, 'rack_id'),
      indexedNodes: keyBy(nodes, 'id'),
      indexedRawNodes: keyBy(nodesRaw, 'id'),
      nodeByRoles,
      indexedRacks,
      indexedPods: pods && keyBy(pods, 'id'),
      indexedPlanes: planes && keyBy(planes, 'id'),
      indexedAccessGroups: accessGroups && keyBy(accessGroups, 'access_group_id'),
      indexedVlans: keyBy(vlans, 'id'),
      hasAccessGroups: !!accessGroups?.length,
      vlansBySecurityZone: groupBy(vlans, 'security_zone_id'),
      vlans: vlans ?? [],
      securityZones: securityZones ?? [],
      hasVlans: !!vlans?.length,
      links: [] as ILink[],
      nodes,
    };
  }, [nodesRaw, racksRaw, accessGroups, pods, planes, vlans, securityZones]);

  topologyData.links = useMemo(
    () => processLinks(rawLinks, topologyData.indexedNodes, topologySettings, selectedPlaneId),
    [rawLinks, topologyData.indexedNodes, topologySettings, selectedPlaneId]
  );

  return topologyData;
};
