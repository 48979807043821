import {useContext, useMemo, useCallback} from 'react';
import {forEach, filter, map, head, join, compact, sortBy, transform, isEmpty, some} from 'lodash';
import {DropdownControl} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

const SystemInterfaceControl = ({systemLabel, interfaces, ...props}) => {
  const {cablingMap} = useContext(IBAContext);
  const usedInterfaces = useMemo(() => {
    return transform(interfaces, (result, {system_label: label, interface_name: usedInterface}) => {
      if (label === systemLabel) result[usedInterface] = true;
    }, {});
  }, [interfaces, systemLabel]);
  const interfaceOptions = useMemo(() => {
    const options = [];
    forEach(cablingMap, ({endpoints}) => {
      forEach(endpoints, ({label, if_name: name}) => {
        if (label === systemLabel) {
          const description = head(map(
            filter(endpoints, ({label}) => label !== systemLabel),
            'label',
          ));
          if (!usedInterfaces[name] || name === props.value) {
            options.push({
              key: name,
              text: name,
              content: <span>{join(compact([name, description]), ' - ')}</span>,
              value: name,
            });
          }
        }
      });
    });
    if (!isEmpty(props.value) && !some(options, {value: props.value})) {
      options.push({
        key: props.value,
        value: props.value,
        text: props.value,
      });
    }
    return sortBy(options, 'text');
  }, [cablingMap, systemLabel, usedInterfaces, props.value]);

  const searchFn = useCallback((options, value) => {
    return options.filter((option) => option.content.props.children.includes(value));
  }, []);

  return (
    <DropdownControl
      {...props}
      search={searchFn}
      clearable
      options={interfaceOptions}
    />
  );
};

export default SystemInterfaceControl;
