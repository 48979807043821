import {observable, makeObservable, action, autorun} from 'mobx';
import {forEach} from 'lodash';

class HighlightStore {
  @observable.ref highlightedNodeIds = new Set();

  @action
  setHighlightNodeIds(ids) {
    this.highlightedNodeIds = new Set(ids);
  }

  setNodeHighlightedDisposer = autorun(() => {
    const shouldFadeOut = this.highlightedNodeIds.size > 0;
    forEach(this.rootStore.cablingMap.nodes, (node) => {
      const isFadedOut = shouldFadeOut && !this.highlightedNodeIds.has(node.id);
      node.setIsFadedOut(isFadedOut);
    });
  });

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  dispose() {
    this.setNodeHighlightedDisposer();
  }
}

export default HighlightStore;
