import {Component} from 'react';
import {request} from 'apstra-ui-common';

import MultiUploadModal from '../../components/MultiUploadModal';

export default class UploadPackageModal extends Component {
  uploadFile({file, signal}) {
    const body = new FormData();
    body.append('package', file);
    return request('/api/packages/upload', {method: 'POST', body, signal});
  }

  render() {
    return (
      <MultiUploadModal
        header='Upload Packages'
        uploadFile={this.uploadFile}
        {...this.props}
      />
    );
  }
}
