import {withResizeDetector} from 'react-resize-detector';
import {Group} from '@visx/group';
import {range, map, forEach, isArray} from 'lodash';
import {Line} from '@visx/shape';
import {brandColorNames} from 'apstra-ui-common';
import {Fragment, useMemo} from 'react';
import {Text} from '@visx/text';

import './ExplanationChart.less';

const calculateManyVNPerIndividualNode = ({
  chartWidth, nodeLabel = 'Node', nodeWidth = 100, vnWidth = 40,
  offset = 5, height = 36, relationships = 4,
}) => {
  const chartHeight = height + relationships * (offset + height / 2);
  const y = chartHeight - height;
  const lines = [];
  const rects = [{
    x: 0,
    y,
    width: nodeWidth,
    height,
    className: 'node',
    label: nodeLabel,
    rx: 4,
  }];
  const lineOffset = nodeWidth / (relationships + 1);
  forEach(range(relationships), (i) => {
    const color = brandColorNames[i];
    const x0 = lineOffset * (relationships - i);
    const x1 = chartWidth - vnWidth * (i + 1);
    const y0 = y - offset * (i + 1) - height / 2 * i;
    lines.push({
      from: {y: y0, x: x0},
      to: {y, x: x0},
      className: color,
    });
    lines.push({
      from: {y: y0, x: x0},
      to: {y: y0, x: x1},
      className: color,
    });
    rects.push({
      x: x1,
      y: y0 - height / 2,
      width: vnWidth,
      height,
      className: `vn ${color}`,
      label: `VN ${i + 1}`,
      rx: '50%',
    });
  });
  return [rects, lines];
};

const calculateManyNodePerIndividualVN = ({
  chartWidth, nodeLabel = 'Node', vnWidth = 40, height = 36, offset = 40,
  nodePadding = 5, relationships = 4
}) => {
  const nodeWidth = (chartWidth - nodePadding * (relationships - 1)) / relationships;
  const rects = [];
  const color = brandColorNames[0];
  let y = 0;
  // vn
  rects.push({
    x: chartWidth / 2 - vnWidth / 2,
    y,
    width: vnWidth,
    height,
    className: `vn ${color}`,
    label: 'VN',
    rx: '50%',
  });
  y += height;
  // lines
  const lines = [
    // vertical from
    {
      from: {x: chartWidth / 2, y},
      to: {x: chartWidth / 2, y: y + offset / 2},
      className: color,
    },
    // horizontal
    {
      from: {x: 0, y: y + offset / 2},
      to: {x: chartWidth, y: y + offset / 2},
      className: color,
    },
  ];
  // nodes + vertical line to
  forEach(range(relationships), (i) => {
    const xNode = nodeWidth * i + nodePadding * i;
    const label = isArray(nodeLabel) ? nodeLabel[i] : `${nodeLabel} ${i + 1}`;
    rects.push({
      x: xNode,
      y: y + offset,
      width: nodeWidth,
      height,
      className: 'node',
      label,
      rx: 4,
    });
    lines.push({
      from: {x: xNode + nodeWidth / 2, y: y + offset / 2},
      to: {x: xNode + nodeWidth / 2, y: y + offset},
      className: color,
    });
  });
  return [rects, lines];
};

const ExplanationChart = ({
  width: chartWidth, description, nodeLabel, targetRef, rectHeight: height, relationships, offset, isSingleNode,
}) => {
  const chartHeight = isSingleNode ?
    height + relationships * (height / 2 + offset) :
    height * 2 + offset;
  const [rects, lines] = useMemo(() => {
    return isSingleNode ?
      calculateManyVNPerIndividualNode({chartWidth, nodeLabel, height, relationships, offset}) :
      calculateManyNodePerIndividualVN({chartWidth, nodeLabel, height, relationships, offset});
  }, [chartWidth, nodeLabel, height, relationships, offset, isSingleNode]);

  return (
    <div ref={targetRef} className='explanation-chart'>
      {chartWidth &&
        <svg className='chart' width={chartWidth} height={chartHeight}>
          <Group>
            {map(rects, ({label, ...node}, i) =>
              <Fragment key={i}>
                <rect {...node} />
                <Text
                  className={node.className}
                  x={node.x + node.width / 2}
                  y={node.y + node.height / 2}
                  width={node.width}
                  verticalAnchor='middle'
                  textAnchor='middle'
                >
                  {label}
                </Text>
              </Fragment>
            )}
          </Group>
          <Group>
            {map(lines, (line, i) =>
              <Line key={i} {...line} />
            )}
          </Group>
        </svg>
      }
      <div>
        {description}
      </div>
    </div>
  );
};

ExplanationChart.defaultProps = {
  offset: 5,
  relationships: 4,
  rectHeight: 36,
};

export default withResizeDetector(ExplanationChart, {handleWidth: true, handleHeight: false});
