import React from 'react';
import {every, forEach, map, some} from 'lodash';
import {Modal, Button} from 'semantic-ui-react';

import {ctrlStages} from '../const.js';
import Step from '../store/Step';
import {useCablingMapStore} from '../store/useCablingMapStore';
import {LinkPropertiesAdapter} from './LinkPropertiesAdapter';

import './LinkEditorModal.less';
import Link from '../store/Link.js';

export const LinkEditorModal = ({links, onClose}) => {
  const {cablingMap} = useCablingMapStore();

  React.useEffect(() => {
    forEach(links, (link) => link.backup());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const discardHandler = React.useCallback(() => {
    forEach(links, (link) => link.restore());
    onClose?.();
  }, [links, onClose]);

  const commitHandler = React.useCallback(() => {
    const changes = [];
    forEach(links, (link) => {
      const linkHasChanges = link.hasChanges;
      const previousState = link.isUpdate ? link.restore(new Link({}, {}, cablingMap)) : null;
      link.commit();
      if (linkHasChanges) {
        changes.push(new Step(ctrlStages.CHANGE, link, previousState));
      }
    });
    cablingMap.changes.register(changes);
    onClose?.();
  }, [cablingMap, links, onClose]);

  const hasChanges = some(links, 'hasChanges');
  const isValid = every(links, 'isValid');
  const canCommit = hasChanges && isValid;

  return (
    <Modal
      className='link-editor-modal'
      open
      onClose={onClose}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      size='large'
    >
      <Modal.Header>{'Links Management'}</Modal.Header>
      <Modal.Content scrolling>
        {map(links, (link) => (
          <LinkPropertiesAdapter
            key={link.id}
            link={link}
          />
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content='Apply Changes'
          disabled={!canCommit}
          onClick={commitHandler}
        />
        <Button
          content='Discard'
          onClick={discardHandler}
        />
      </Modal.Actions>
    </Modal>
  );
};

export const useLinkEditor = () => {
  const [links, setLinks] = React.useState();
  const [isOpen, setOpen] = React.useState(false);
  const showLinkEditor = React.useCallback((links) => {
    setLinks(links);
    setOpen(true);
  }, []);
  const onCloseHandler = React.useCallback(() => setOpen(false), []);
  const content = isOpen && (
    <LinkEditorModal
      onClose={onCloseHandler}
      links={links}
    />
  );
  return [content, showLinkEditor];
};
