import {Component, Fragment} from 'react';
import {observable, action, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Grid, Message, Dropdown, Icon} from 'semantic-ui-react';
import {map, isEmpty, filter, keys, pick, values} from 'lodash';
import {
  ActionsMenu, DataFilter, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, PermissionChecker,
  hasBlueprintPermissions, interpolateRoute, request, withRouter,
  stringFilterer,
} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import ProbeDeletionModal from './ProbeDeletionModal';
import ProbeExportModal from './ProbeExportModal';
import ProbeBatchImportModal from './ProbeBatchImportModal';
import PredefinedProbeModal from './PredefinedProbeModal';
import generateProbeURI from '../generateProbeURI';
import {ProbeStateLabel, ProbeAnomalyCountLabel} from './ProbeDetails';
import UpdatedByLabel from './UpdatedByLabel';
import PredefinedEntityIcon from './PredefinedEntityIcon';
import ProbeActions from './ProbeActions';
import ProbeToggle from './ProbeToggle';
import userStore from '../../userStore';

import './ProbeList.less';

const tableSchema = [
  {
    name: 'label',
    label: 'Name',
    value: ['label'],
    formatter: ({item: probe, value, params: {blueprintId, predefinedProbes}}) => (
      <div className='entity-name'>
        <Link to={generateProbeURI({blueprintId, probeId: probe.id})}>
          {value}
        </Link>
        {probe.predefined_probe &&
          <PredefinedEntityIcon
            predefinedEntities={predefinedProbes}
            predefinedEntityName={probe.predefined_probe}
            predefinedEntityType='Predefined probe'
          />
        }
      </div>
    ),
    sortable: true,
  },
  {
    name: 'anomalies',
    label: 'Anomalies',
    value: ({item: probe}) => probe.anomaly_count,
    formatter: ({value}) => <ProbeAnomalyCountLabel anomalyCount={value} />,
    sortable: true,
  },
  {
    name: 'state',
    label: 'State',
    value: ({item: {state, disabled}}) => [state, disabled],
    formatter: ({item: probe}) => <ProbeStateLabel probe={probe} />,
    sortable: true,
  },
  {
    name: 'updated_by',
    label: 'Updated By',
    value: ['updated_by'],
    formatter: ({item: probe}) => <UpdatedByLabel updatedBy={probe.updated_by} timestamp={probe.updated_at} />,
    sortable: true,
  },
  {
    name: 'enabled',
    label: 'Enabled',
    value: ({item: {disabled}}) => !disabled,
    formatter: ({item: probe, value, params: {refetchOnEnableToggle, refetchOnEnableToggleInProgress}}) => (
      <ProbeToggle
        value={value}
        probe={probe}
        refetchInProgress={refetchOnEnableToggleInProgress}
        refetchData={() => refetchOnEnableToggle(probe.id)}
      />
    ),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'Actions',
    formatter: ({
      item: probe,
      params: {setProbeDeletionModalProps, setProbeExportModalProps, setPredefinedProbeModalProps, refetchData}
    }) => (
      <ProbeActions
        size='small'
        probe={probe}
        setProbeDeletionModalProps={setProbeDeletionModalProps}
        setProbeExportModalProps={setProbeExportModalProps}
        setPredefinedProbeModalProps={setPredefinedProbeModalProps}
        refetchData={refetchData}
      />
    ),
  },
];

const searchBoxSchema = [
  {
    name: 'label',
    schema: {
      type: 'string',
      title: 'Name',
    }
  },
  {
    name: 'anomalousOnly',
    schema: {
      type: 'boolean',
      title: 'Show only probes with anomalies',
    }
  },
];

const labelFilterer = stringFilterer(['label'], ['label']);

@withRouter
@observer
export default class ProbeList extends Component {
  static contextType = IBAContext;

  static pollingInterval = 10000;
  static userStoreKey = 'probeList';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, routes, signal}) {
    const route = routes.probeList;
    const {items: probes} = await request(interpolateRoute(route, {blueprintId}), {signal});
    return {probes};
  }

  @observable.ref probeDeletionModalProps = null;
  @observable.ref probeExportModalProps = null;
  @observable.ref predefinedProbeModalProps = null;
  @observable.ref batchImportModalProps = null;
  @observable.ref selection = {};
  @observable refetchOnEnableToggleInProgress = {};

  @action
  setProbeDeletionModalProps = (props) => {
    this.probeDeletionModalProps = props;
  };

  @action
  setProbeExportModalProps = (props) => {
    this.probeExportModalProps = props;
  };

  @action
  setPredefinedProbeModalProps = (props) => {
    this.predefinedProbeModalProps = props;
  };

  @action
  setBatchImportModalProps = (props) => {
    this.batchImportModalProps = props;
  };

  @action
  updateSelection = (selection) => {
    this.selection = selection;
  };

  @action
  refetchOnEnableToggle = async (refetchData, probeId) => {
    this.refetchOnEnableToggleInProgress[probeId] = true;
    await refetchData();
    runInAction(() => {
      this.refetchOnEnableToggleInProgress[probeId] = false;
      if (isEmpty(filter(values(this.refetchOnEnableToggleInProgress)))) {
        this.refetchOnEnableToggleInProgress = {};
      }
    });
  };

  getDataTableActions = ({selectionIds, probes, blueprintPermissions}) => [
    {
      icon: 'trash',
      title: 'Delete selected',
      onClick: () => this.setProbeDeletionModalProps({
        open: true, probes: probes.filter((probe) => selectionIds.includes(probe.id))
      }),
      hasPermissions: hasBlueprintPermissions({blueprintPermissions, action: 'edit'}),
      notPermittedMessage: 'You do not have permission to delete',
      popupPosition: 'top center',
    }
  ];

  anomalousOnlyFilterer = ({item: probe, filters: {anomalousOnly = false}}) => {
    if (!anomalousOnly) return true;
    return probe.anomaly_count;
  };

  render() {
    const {blueprintId, predefinedProbes, routes, blueprintPermissions} = this.context;
    const {
      getDataTableActions, updateSelection, selection,
      refetchOnEnableToggle, refetchOnEnableToggleInProgress,
      probeDeletionModalProps, probeExportModalProps, predefinedProbeModalProps, batchImportModalProps,
      setProbeDeletionModalProps, setProbeExportModalProps, setPredefinedProbeModalProps, setBatchImportModalProps
    } = this;
    const defaultPageSize = userStore.getStoreValue([ProbeList.userStoreKey, 'pageSize']);
    return (
      <DataFilteringContainer
        defaultPageSize={defaultPageSize}
        stateQueryParam='probes-filter'
        defaultSorting={{label: 'asc'}}
        setUserStoreProps={userStore.setStoreValueFn(ProbeList.userStoreKey)}
      >
        {({activePage, pageSize, updatePagination, filters, updateFilters, sorting, updateSorting}) =>
          <FetchData
            customLoader
            fetchData={ProbeList.fetchData}
            fetchParams={{blueprintId, routes}}
            pollingInterval={ProbeList.pollingInterval}
          >
            {({probes = [], loaderVisible, fetchDataError, refetchData}) => {
              const filteredSelection = pick(selection, map(probes, 'id'));
              const selectionIds = filter(keys(filteredSelection), (key) => filteredSelection[key]);
              const dataTableActionItems = getDataTableActions({selectionIds, probes, blueprintPermissions});

              const addProbe = (
                <Grid.Row>
                  <Grid.Column width={16} textAlign='right'>
                    <PermissionChecker
                      hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
                      notPermittedMessage='You do not have permission to create'
                      popupProps={{
                        position: 'top center',
                        offset: ({reference}) => [0, reference.height],
                      }}
                    >
                      <Dropdown
                        button
                        aria-label='Create Probe'
                        className='primary big add-probe-button'
                        trigger={
                          <span>
                            <Icon name='add circle' />
                            {'Create Probe'}
                          </span>
                        }
                      >
                        <Dropdown.Menu direction='left'>
                          <Dropdown.Item
                            as={Link}
                            to='create-probe'
                            size='big'
                            icon='file outline'
                            content='New Probe'
                          />
                          <Dropdown.Item
                            icon='box'
                            content='Instantiate Predefined Probe'
                            onClick={() => setPredefinedProbeModalProps({open: true})}
                          />
                          <Dropdown.Item
                            icon='upload'
                            content='Import Probes'
                            onClick={() => setBatchImportModalProps({open: true})}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </PermissionChecker>
                  </Grid.Column>
                </Grid.Row>
              );

              return (
                <Fragment>
                  <DataFilter
                    items={probes}
                    schema={tableSchema}
                    activePage={activePage}
                    pageSize={pageSize}
                    filterers={[labelFilterer, this.anomalousOnlyFilterer]}
                    filters={filters}
                    sorting={sorting}
                  >
                    {({items, allItems, totalCount}) =>
                      <DataFilteringLayout
                        loaderVisible={loaderVisible}
                        fetchDataError={fetchDataError}
                        paginationProps={{
                          totalCount,
                          activePage,
                          pageSize,
                          onChange: updatePagination
                        }}
                        searchProps={{
                          filters,
                          schema: searchBoxSchema,
                          onChange: updateFilters
                        }}
                        extraContent={{
                          headerRow: addProbe,
                          buttonsPanel: selectionIds.length > 0 &&
                            <ActionsMenu
                              size='tiny'
                              items={dataTableActionItems}
                            />
                        }}
                      >
                        {probes.length ?
                          <DataTable
                            selectable
                            allowSelectAll
                            allItems={allItems}
                            updateSelection={updateSelection}
                            selection={filteredSelection}
                            className='probe-list-table'
                            verticalAlign='middle'
                            items={items}
                            schema={tableSchema}
                            params={{
                              blueprintId, predefinedProbes,
                              setProbeDeletionModalProps, setProbeExportModalProps, setPredefinedProbeModalProps,
                              refetchData, refetchOnEnableToggleInProgress,
                              refetchOnEnableToggle: (probeId) => refetchOnEnableToggle(refetchData, probeId),
                            }}
                            sorting={sorting}
                            updateSorting={updateSorting}
                            getHeaderCellProps={({name}) => ({collapsing: name === 'actions'})}
                            getRowProps={({item}) => ({
                              error: item.anomaly_count > 0 || item.state === 'error'
                            })}
                            getItemKey={({id}) => id}
                          />
                      :
                          <Message
                            info
                            icon='info circle'
                            content={
                              'There are currently no probes defined. ' +
                              'Please consult Juniper Apstra documentation to create new probes.'
                            }
                          />
                        }
                      </DataFilteringLayout>
                    }
                  </DataFilter>
                  <ProbeDeletionModal
                    open={false}
                    onClose={() => setProbeDeletionModalProps({open: false})}
                    onSuccess={() => refetchData()}
                    {...probeDeletionModalProps}
                  />
                  <ProbeExportModal
                    open={false}
                    onClose={() => setProbeExportModalProps({open: false})}
                    {...probeExportModalProps}
                  />
                  <PredefinedProbeModal
                    open={false}
                    onClose={() => setPredefinedProbeModalProps({open: false})}
                    {...predefinedProbeModalProps}
                  />
                  <ProbeBatchImportModal
                    open={false}
                    onUploadSuccess={refetchData}
                    onClose={() => setBatchImportModalProps({open: false})}
                    {...batchImportModalProps}
                  />
                </Fragment>
              );
            }}
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}
