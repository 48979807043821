import {
  isString, isFinite, isEmpty, isBoolean, flatMap, join, size, capitalize,
  escape, map, filter, compact, keys, isPlainObject,
} from 'lodash';

export default function buildPropertyDocHTMLFromSchema(schema = {}) {
  const result = [];
  const properties = {};
  if (isString(schema.type)) {
    properties.Type = schema.type;
  }
  if (isFinite(schema.default)) {
    properties.Default = schema.default;
  } else if (isBoolean(schema.default)) {
    properties.Default = capitalize(String(schema.default));
  }
  if (schema.type === 'object' && !isEmpty(schema.properties)) {
    const propertyList = isPlainObject(schema.properties) ?
      keys(schema.properties) :
      map(schema.properties, 'name');
    properties.Properties = join(compact(propertyList), ', ');
  }
  if (schema.type === 'number' || schema.type === 'integer') {
    if ('minimum' in schema) properties.Min = schema.minimum;
    if ('maximum' in schema) properties.Max = schema.maximum;
  }
  if (schema.type === 'string' && schema.enum) {
    properties['Possible values'] = join(schema.enum, ', ');
  }
  if (schema.pattern) {
    properties.Pattern = schema.pattern;
  }
  const format = filter([schema.format, ...map(schema.oneOf, 'format')]).join(', ');
  if (format) {
    properties.Format = format;
  }
  if (size(properties)) {
    result.push(
      '<div class="ui bulleted list">',
      ...flatMap(properties, (value, key) => [
        '<div class="item">',
        '<b>', key, '</b>', ': ', escape(value),
        '</div>',
      ]),
      '</div>'
    );
  }
  if (schema.description) {
    result.push(
      '<div>',
      escape(schema.description),
      '</div>'
    );
  }
  return result.join('');
}
