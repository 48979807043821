import {TimelineGraphContainer} from 'apstra-ui-common';
import {sortBy, isUndefined} from 'lodash';
import moment from 'moment';

import StateLineChart from '../StateLineChart';

const AnomalyHistory = ({
  samples = [],
  color = 'blue',
  expanded = false,
  valueKeyName = 'raised',
  extraDataKey = 'data',
  periodStartKey = 'detected_at',
  timelineStart,
  timelineEnd,
  useCurrentTimeAsTimelineEnd = false,
  popupProps = {},
  useTimestampZoom = false,
  showTimelineWithMilliseconds = false,
  generatePopupContentFn,
}) => {
  const sortedSamples = sortBy(samples, 'timestamp');
  const samplesWithCornerValues = [...sortedSamples];

  if (samples.length > 0) {
    if (!isUndefined(timelineStart) && moment(sortedSamples[0]?.timestamp).isAfter(timelineStart)) {
      samplesWithCornerValues.unshift({
        timestamp: timelineStart,
        [valueKeyName]: null,
      });
    }

    if (!isUndefined(timelineEnd) && moment(sortedSamples[sortedSamples.length - 1]?.timestamp).isBefore(timelineEnd)) {
      samplesWithCornerValues.push({
        timestamp: timelineEnd,
        [valueKeyName]: null,
      });
    }
  }

  return (
    <TimelineGraphContainer
      samples={samplesWithCornerValues}
      expanded={expanded}
      useCurrentTimeAsTimelineEnd={useCurrentTimeAsTimelineEnd}
      showTimelineWithMilliseconds={showTimelineWithMilliseconds}
    >
      <StateLineChart
        color={color}
        chartStart={timelineStart}
        chartEnd={timelineEnd}
        valueKeyName={valueKeyName}
        extraDataKey={extraDataKey}
        periodStartKey={periodStartKey}
        popupProps={popupProps}
        useTimestampZoom={useTimestampZoom}
        generatePopupContentFn={generatePopupContentFn}
      />
    </TimelineGraphContainer>
  );
};

export default AnomalyHistory;
