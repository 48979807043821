import {Component} from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import {find, get} from 'lodash';

export default class PredefinedEntityIcon extends Component {
  static defaultProps = {
    trigger: <Icon name='box' />,
  };

  render() {
    const {predefinedEntities, predefinedEntityName, predefinedEntityType, trigger} = this.props;
    const predefinedEntityLabel = get(
      find(predefinedEntities, {name: predefinedEntityName}),
      ['schema', 'properties', 'label', 'default'],
      predefinedEntityName
    );
    return (
      <Popup
        trigger={trigger}
        size='tiny'
        position='right center'
        header={predefinedEntityLabel}
        content={predefinedEntityType}
      />
    );
  }
}
