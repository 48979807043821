import {useCallback, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form} from 'semantic-ui-react';
import {map} from 'lodash';
import {
  FetchData, FetchDataError, AsyncActionsModal, DropdownInput,
  interpolateRoute, request
} from 'apstra-ui-common';

import generateDashboardURI from '../generateDashboardURI';
import IBAContext from '../IBAContext';

const NEW_DASHBOARD_VALUE = true;

export default function DashboardChoiceModal({widget, ...props}) {
  const [dashboardId, setDashboardId] = useState(NEW_DASHBOARD_VALUE);

  const {blueprintId, routes} = useContext(IBAContext);

  const fetchDashboards = useCallback(async ({signal}) => {
    const {items: dashboards} = await request(interpolateRoute(routes.dashboardList, {blueprintId}), {signal});
    return {dashboards};
  }, [blueprintId, routes]);

  const navigate = useNavigate();

  const submit = useCallback(() => {
    navigate(
      generateDashboardURI(
        dashboardId === NEW_DASHBOARD_VALUE ?
          {blueprintId, action: 'create'}
        :
          {blueprintId, dashboardId, action: 'update'}
      ),
      {state: {widgetToCreate: widget}}
    );
  }, [blueprintId, dashboardId, navigate, widget]);

  return (
    <FetchData
      customLoader
      fetchData={fetchDashboards}
      pollingInterval={null}
    >
      {({dashboards, loaderVisible, fetchDataError}) =>
        <AsyncActionsModal
          closeIcon
          size='small'
          header='Choose Dashboard'
          content={
            () =>
              <Form>
                {fetchDataError ?
                  <FetchDataError error={fetchDataError} />
                :
                  <DropdownInput
                    search
                    value={dashboardId}
                    loading={loaderVisible}
                    options={[
                      {
                        key: 'new-dashboard',
                        text: 'Create New Dashboard',
                        icon: 'plus circle',
                        value: NEW_DASHBOARD_VALUE
                      },
                      ...map(dashboards, ({id, label, predefined_dashboard: predefinedDashboard}) => ({
                        key: 'dashboard-' + id,
                        text: label,
                        description: predefinedDashboard ? 'Predefined' : undefined,
                        value: id
                      }))
                    ]}
                    onChange={setDashboardId}
                  />
                }
              </Form>
          }
          actions={[{
            key: 'submit',
            primary: true,
            content: 'Proceed',
            onClick: submit,
            disabled: loaderVisible
          }]}
          {...props}
        />
      }
    </FetchData>
  );
}
