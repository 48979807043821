import React, {Component, Fragment, createRef, PureComponent} from 'react';
import {Label, Message, Grid, Table, Icon, Placeholder} from 'semantic-ui-react';
import {observable, computed, action, toJS, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {
  castArray, compact, filter, find, findIndex, forEach, get, has, head, includes, isEmpty, isFinite, isFunction, values,
  isNumber, isString, keys, last, map, max, merge, min, transform, xor,
  isUndefined, join, startCase, times
} from 'lodash';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import {
  COMBINE_GRAPHS_MODE, DEFAULT_COMBINE_GRAPHS_MODE, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES, Checkbox,
  DataTable, DataTableRowFragment, DateFromNow,
  DropdownControl, DurationInput, FetchDataError, FormattedNumber, LayoutContext, MultipleLineChart,
  Pagination, StackedChart, TimelineGraphContainer, Value, DataFilteringLayout,
  createValueRenderer, parseTimestamp,
  withRouter
} from 'apstra-ui-common';

import {
  NODE_ROLES, DEFAULT_STAGE_DATA_SOURCE,
  STAGE_DATA_SOURCE,
  DEFAULT_STAGE_TIME_SERIES_AGGREGATION,
  DEFAULT_STAGE_TIME_SERIES_DURATION,
} from '../consts';
import {filtersToQueryParam} from '../../queryParamUtils';
import {
  processorHasRaiseAnomaliesFlag, processorCanRaiseAnomalies,
  getStageDataSchema, sortStagePropertyNames, getInputStages, getProcessorByStageName,
  getStageRenderingStrategy, processorCanRaiseWarnings, getTelemetryServiceSchema,
  getValueAggregationTypes, getPossibleAggregationTypes,
} from '../stageUtils';
import {withContext} from '../../withContext';
import generateProbeURI from '../generateProbeURI';
import {rangeProcessorUtils} from '../processorUtils';
import {anomalyColors, discreteStateColors} from '../../graphColors';
import AnomalyValues from './AnomalyValues';
import checkForPatterns from '../checkForPatterns';
import DiscreteStateLegend from './graphs/DiscreteStateLegend';
import AnomalyHistoryGraphLegend from './graphs/AnomalyHistoryGraphLegend';
import SingleHorizontalBar from './graphs/SingleHorizontalBar';
import LineChart from '../../components/graphs/LineChart';
import DiscreteStateTimeline from '../../components/graphs/DiscreteStateTimeline';
import EventTimeline from '../../components/graphs/EventTimeline';
import AggregationInput, {
  aggregationIntervalOptions, aggregationIntervalOptionsWithOff
} from '../../components/AggregationInput';
import {renderRegexTextInput, renderSeconds, renderSpeed} from '../commonRenderers';
import DiscreteStateTimelineWithSamples from './graphs/DiscreteStateTimelineWithSamples';
import Gauge from './graphs/Gauge';
import TrafficDiagramContainer from './graphs/TrafficDiagramContainer';
import StageSearchBox, {getStageSearchSchema} from './StageSearchBox';
import IBAContext from '../IBAContext';
import humanizeString from '../../humanizeString';
import {ValueColumnNameInput} from './StageWidgetValueColumnNameInput';
import {TRAFFIC_PROBE_STAGE} from './graphs/trafficUtils';
import AggregationTypeInput from './AggregationTypeInput';
import {rangeControlRenderer} from '../../components/RangeControl';
import {withScrollContext} from './ProbeDetails/ScrollContext';

import './StageData.less';

@withContext(LayoutContext)
@withRouter
@withScrollContext
@observer
export class StageData extends Component {
  static contextType = IBAContext;

  previousScrollPositionTop = null;
  spotlightViewRef = createRef();

  @observable highlightedColumn = null;

  @action highlightPropertyColumn = (column) => {
    if (column !== this.highlightedColumn) this.highlightedColumn = column;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loaderVisible && !this.props.loaderVisible) {
      if (!this.props.filters.spotlightMode && this.previousScrollPositionTop) {
        this.props.mainContentElement?.scrollTo({top: this.previousScrollPositionTop, behavior: 'smooth'});
        this.previousScrollPositionTop = null;
      }
      if (this.props.filters.spotlightMode && this.spotlightViewRef.current) {
        this.spotlightViewRef.current.focus();
      }
    }
  }

  storeScrollPosition = () => {
    this.previousScrollPositionTop = this.props.mainContentElement?.scrollTop !== undefined ?
      this.props.mainContentElement?.scrollTop
      :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;
  };

  @action
  switchToSpotlightMode = (itemId) => {
    this.storeScrollPosition();
    const {updatePagination, filters, activePage, pageSize, scrollContext, stage} = this.props;
    this.updateFilters({...filters, spotlightMode: true});
    const page = (activePage - 1) * pageSize + findIndex(this.props.stageItems, (item) => item.id === itemId) + 1;
    updatePagination({activePage: page, pageSize: 1});
    scrollContext?.scrollToElement('stage details', stage.name);
  };

  onSpotlightModeKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.switchToListMode();
    }
  };

  @action
  switchToListMode = () => {
    const {updatePagination, filters, activePage, scrollContext, stage} = this.props;
    this.updateFilters({...filters, spotlightMode: false});
    const page = Math.floor(activePage / DEFAULT_PAGE_SIZE) + 1;
    updatePagination({activePage: page, pageSize: DEFAULT_PAGE_SIZE});
    scrollContext?.scrollToElement('stage details', stage.name);
  };

  @computed get anomaliesByItems() {
    const {stageItems, processor} = this.props;
    if (!processorCanRaiseAnomalies(processor)) {
      return {};
    }

    return transform(stageItems, (result, item) => {
      const {actual_value: actualValue, anomalous_value: anomalyValue,
        anomalous_value_min: valueMin, anomalous_value_max: valueMax, value} = item;
      const anomaly = {actual: {value: actualValue}, anomalous: {}};
      if (anomalyValue) anomaly.anomalous.value = anomalyValue;
      if (isNumber(valueMin)) anomaly.anomalous.value_min = valueMin;
      if (isNumber(valueMax)) anomaly.anomalous.value_max = valueMax;
      if (value === 'true') {
        result[item.id] = anomaly;
      }
    }, {});
  }

  @computed get stageHasPersistedData() {
    return this.props.stage.enable_metric_logging;
  }

  @computed get patternDescription() {
    const {probe, stage, filters} = this.props;
    return checkForPatterns({probe, stageName: stage.name, dataSource: filters.dataSource});
  }

  @computed get usePatternWithPersistedData() {
    const {patternDescription, props: {filters}} = this;
    return patternDescription && patternDescription.shouldFetchPersistedStageData && filters.showContextInfo;
  }

  @computed get usePatternWithRawPersistedData() {
    const {patternDescription} = this;
    return patternDescription && patternDescription.shouldFetchRawPersistedStageData;
  }

  @computed get valueColumnName() {
    const {filters: {valueColumnName}, stage} = this.props;
    return valueColumnName || head(keys(stage.values));
  }

  @computed get tableSchema() {
    const {processorDefinitions} = this.context;
    const {stage, stageItems, processor, visibleColumns, filters} = this.props;
    const {renderingStrategy, valueColumnName, stageGraphColors} = this;
    const isTelemetryServiceWarningsDataSource = filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings;
    const tableSchema = isTelemetryServiceWarningsDataSource ?
      getTelemetryServiceSchema(stageItems) :
      getStageDataSchema({
        stage,
        processor,
        renderingStrategy,
        processorDefinitions,
        showValue: !filters.spotlightMode && !isTelemetryServiceWarningsDataSource,
        valueColumnName: filters.dataSource === STAGE_DATA_SOURCE.time_series ? valueColumnName : null,
      });
    forEach(tableSchema, (column) => {
      column.valueSchema ??= stage.values[column.name] ?? null;
      if (column.name === 'anomaly') {
        column.value = ({item}) => item.id in this.anomaliesByItems;
        column.formatter = ({item}) => <AnomalyValues anomaly={this.anomaliesByItems[item.id]} />;
      } else if (column.name === 'warning') {
        column.formatter = ({value}) => <TelemetryServiceStatus warning={value} />;
      } else if (column.valueSchema) {
        let renderValueAs = column.renderValueAs ?? renderingStrategy.renderValueAs;
        renderValueAs = isFunction(renderValueAs) ?
          renderValueAs(column.valueSchema) :
          renderValueAs ?? 'primitiveValue';
        column.formatter = this.valueRenderers[renderValueAs];
      } else if (column.name === 'timestamp') {
        column.value = ({item}) => parseTimestamp(item.timestamp);
        column.formatter = ({value}) => <DateFromNow date={value} />;
      } else if (!column.formatter) {
        column.formatter = this.renderProperty;
      }
      // FIXME(vkramskikh): only used makePrecedingStageColumnDefinition to override item and name.
      // Formatters need to be reimplemented as function components accepting actual values.
      if (column.overrideFormatter) {
        column.formatter = column.overrideFormatter(column.formatter);
      }
      if (!column.description && column.type) {
        column.description = <ColumnDescription
          column={column}
          stage={stage}
          stageGraphColors={column.stageGraphColors ?? stageGraphColors}
        />;
      }
    });
    if (isEmpty(visibleColumns)) {
      return tableSchema;
    } else {
      return compact(map(visibleColumns, (name) => find(tableSchema, {name})));
    }
  }

  @computed get valueSchemas() {
    return transform(this.tableSchema, (result, column) => {
      if (column.valueSchema) result[column.name] = column.valueSchema;
    }, transform(this.props.stage.values, (result, valueSchema, columnName) => {
      result[columnName] = valueSchema;
    }, {}));
  }

  @computed get stageGraphColors() {
    const {probe, stage} = this.props;
    let {processor} = this.props;

    let processorWithRaiseAnomalyFlag = null;
    const stages = [stage];
    do {
      processorWithRaiseAnomalyFlag = processorHasRaiseAnomaliesFlag(processor) ? processor : null;
      const inputStage = stages.pop();
      processor = getProcessorByStageName({probe, stageName: inputStage.name});
      stages.push(...getInputStages({probe, processor}));
    } while (!isEmpty(stages) && !processorWithRaiseAnomalyFlag);

    return processorWithRaiseAnomalyFlag && processorCanRaiseAnomalies(processorWithRaiseAnomalyFlag) ?
      anomalyColors : discreteStateColors;
  }

  // Common graph props

  maxValuesByItems = new WeakMap();

  getItemMaxValue(items) {
    if (this.maxValuesByItems.has(items)) return this.maxValuesByItems.get(items);
    const maxValue = max(map(items, 'value'));
    this.maxValuesByItems.set(items, maxValue);
    return maxValue;
  }

  sampleValueExtentByItems = new WeakMap();

  getItemSampleExtentValues(items) {
    const {valueColumnName} = this;
    if (this.sampleValueExtentByItems.has(items)) return this.sampleValueExtentByItems.get(items);
    let minValue;
    let maxValue = 0;
    for (const item of items) {
      if (item && item.persisted_samples) {
        for (const sample of item.persisted_samples) {
          const value = sample[valueColumnName];
          if (isUndefined(minValue) || value < minValue) minValue = value;
          if (value > maxValue) maxValue = value;
        }
      }
    }
    const result = [minValue, maxValue];
    this.sampleValueExtentByItems.set(items, result);
    return result;
  }

  getCorrespondingStageItemForStage = ({item, stage}) => {
    return head(item.preceding_items[stage.name]) || null;
  };

  getCorrespondingStageItemsForStages({item, stages}) {
    return map(stages, (stage) => this.getCorrespondingStageItemForStage({item, stage}));
  }

  correspondingStageItemMapping = new WeakMap();

  getAllCorrespondingStageItemsForStages({items, stages}) {
    const {correspondingStageItemMapping} = this;

    if (correspondingStageItemMapping.has(items)) {
      return correspondingStageItemMapping.get(items);
    }

    const result = map(stages, (stage) =>
      map(items, (item) => this.getCorrespondingStageItemForStage({item, stage}))
    );
    correspondingStageItemMapping.set(items, result);
    return result;
  }

  // Property Renderers
  renderProperty = ({name, value, item}) => (
    <Value
      name={name}
      value={value}
      item={item}
      renderers={[
        this.renderSystemId,
        this.renderEndpoint,
        renderSeconds.renderValue,
        renderSpeed.renderValue,
        this.renderVNI,
        this.renderSubnet,
      ]}
    />
  );

  renderChartPopupSystemIdLabel = createValueRenderer({
    condition: ({name}) => name === 'properties.system_id',
    renderValue: ({value: systemId}) => {
      const systemInfo = this.context.systemIdMap[systemId] ?? {};
      const {hostname, role} = systemInfo;
      return (
        <>
          {systemId}
          {!(isUndefined(hostname) && isUndefined(role)) && ' ('}
          {!isUndefined(hostname) && hostname}
          {!isUndefined(role) && (
            <>
              <div className='content-item-divider' />
              {role}
            </>
          )}
          {!(isUndefined(hostname) && isUndefined(role)) && ') '}
        </>
      );
    }
  }).renderValue;

  renderEndpoint = createValueRenderer({
    condition: ({name}) => name === 'properties.endpoint',
    renderValue: ({value}) => {
      return map((value || '').split('/'), (systemId, index) =>
        <SystemInfo key={index} systemId={systemId} />);
    }
  }).renderValue;

  renderVNI = createValueRenderer({
    condition: ({name}) => name === 'properties.vni',
    renderValue: ({value}) => {
      const {generateVnLink, vnIdMap} = this.context;
      const vnName = vnIdMap[value];
      return (
        <>
          <div><a href={generateVnLink({'vn-id': [`${value}`]})}>{value}</a></div>
          {vnName &&
            <div>{vnName}</div>
          }
        </>
      );
    }
  }).renderValue;

  renderSubnet = createValueRenderer({
    condition: ({name}) => name === 'properties.subnet',
    renderValue: ({value, item}) => {
      const addressFamily = get(item, ['properties', 'address_family']);
      const {generateVnLink} = this.context;
      const filter = {[`${addressFamily}-subnet`]: value};
      return (
        <a href={generateVnLink(filter)}>{value}</a>
      );
    }
  }).renderValue;

  renderSystemId = createValueRenderer({
    condition: ({name}) => name === 'properties.system_id',
    renderValue: ({value}) => <SystemInfo systemId={value} />
  }).renderValue;

  // Value Renderers
  renderPrimitiveValue = ({item, name}) => (
    <div className='value-container'>
      <div>
        <Value value={item[name]} />
      </div>
    </div>
  );

  renderFormattedNumber = ({item, name, value}) => {
    const {stage} = this.props;
    if (!isFinite(value) || !this.valueSchemas[name]) return this.renderPrimitiveValue({item, name});
    const {[name]: units} = stage.units;
    return (
      <FormattedNumber value={value} units={units} />
    );
  };

  // N/NS
  renderHorizontalBar = ({item, name, items, extraContent}) => {
    if (!isFinite(item.value)) return this.renderPrimitiveValue({item, name});
    const {props: {stage}, valueColumnName} = this;
    const {[valueColumnName]: units} = stage.units;
    return (
      <SingleHorizontalBar
        value={item.value}
        maxValue={this.getItemMaxValue(items)}
        units={units}
        extraContent={extraContent}
      />
    );
  };

  renderGauge = ({item, groupBy}, params) => {
    const {blueprintId} = this.context;
    const {probe, processor, navigate} = this.props;
    const referenceState = get(processor, ['properties', 'reference_state'], 'true');
    const inputColumn = head(map(processor?.inputs, ({column}) => column));

    const {patternDescription: {relatedStages}} = this;
    const sourceStage = relatedStages[0];

    const redirectToSourceStage = (e, operator) => {
      e.stopPropagation(); // to prevent opening spotlight view
      const properties = transform(groupBy, (acc, key) => {acc[key] = item.properties[key];}, {});
      const filter = join(
        compact([`${inputColumn}${operator}"${referenceState}"`, filtersToQueryParam(properties)]),
        ' and '
      );
      const queryParams = {filters: {filter}};
      navigate(generateProbeURI(
        {blueprintId, probeId: probe.id, stageName: sourceStage.name, queryParams}
      ));
    };

    const {rangeMin, rangeMax, minValue: originalMinValue = 0, maxValue: originalMaxValue, value} = params;
    const minValue = min([originalMinValue, rangeMin, rangeMax]);
    const maxValue = max([originalMaxValue, rangeMin, rangeMax, value]);

    return (
      <Gauge
        colors={this.stageGraphColors}
        onBaseArcClick={(e) => redirectToSourceStage(e, '!=')}
        onSpecialRangeArcClick={(e) => redirectToSourceStage(e, '=')}
        withValueArrow
        {...params}
        minValue={minValue}
        maxValue={maxValue}
        mode={this.props.filters.spotlightMode ? 'expanded' : 'compact'}
      />
    );
  };

  // N/NS (N out of M)
  renderGaugeCount = ({item, name}) => {
    if (!isFinite(item.value)) return this.renderPrimitiveValue({item, name});
    const {patternDescription: {relatedProcessors}, props: {stage}, valueColumnName} = this;
    const {[valueColumnName]: units} = stage.units;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;
    const maxValue = item.total_count;

    return this.renderGauge({item, groupBy}, {value: item.value, maxValue, units});
  };

  // N/NS (N out of M) + Range
  renderGaugeCountWithRange = ({item, name}) => {
    const {patternDescription: {relatedStages, relatedProcessors}, props: {processor}, valueColumnName} = this;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;
    const [, matchCountStage] = relatedStages;
    const [, matchCountStageItem] = this.getCorrespondingStageItemsForStages({item, stages: relatedStages});
    const {[valueColumnName]: units} = matchCountStage.units;

    if (!isFinite(matchCountStageItem.value)) {
      return this.renderPrimitiveValue({item: matchCountStageItem, name});
    }
    const maxValue = matchCountStageItem.total_count;
    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

    return this.renderGauge({item, groupBy}, {
      value: matchCountStageItem.value,
      maxValue,
      rangeMin,
      rangeMax,
      units,
    });
  };

  // N/NS (N out of M percent)
  renderGaugePercent = ({item, name}) => {
    if (!isFinite(item.value)) return this.renderPrimitiveValue({item, name});

    const {patternDescription: {relatedProcessors}, props: {stage}, valueColumnName} = this;
    const {[valueColumnName]: units} = stage.units;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;

    return this.renderGauge({item, groupBy}, {value: item.value, maxValue: 100, units});
  };

  // N/NS (N out of M percent) + Range
  renderGaugePercentWithRange = ({item, name}) => {
    const {patternDescription: {relatedProcessors, relatedStages}, props: {processor}, valueColumnName} = this;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;
    const [, matchPercentStage] = relatedStages;
    const [, matchPercentStageItem] = this.getCorrespondingStageItemsForStages({item, stages: relatedStages});
    const {[valueColumnName]: units} = matchPercentStage.units;

    if (!isFinite(matchPercentStageItem.value)) {
      return this.renderPrimitiveValue({item: matchPercentStageItem, name});
    }

    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

    return this.renderGauge({item, groupBy}, {
      value: matchPercentStageItem.value,
      maxValue: 100,
      rangeMin,
      rangeMax,
      units,
    });
  };

  // DS/DSS
  renderDiscreteStateValue = ({item, name, stageGraphColors = this.stageGraphColors}) => {
    const {possible_values: possibleValues} = this.valueSchemas[name];
    const result = this.renderPrimitiveValue({item, name});
    const index = possibleValues.indexOf(item[name]);
    if (index !== -1) {
      const colorName = stageGraphColors[index];
      if (colorName) {
        return React.cloneElement(result, {className: `value-container graph-color-${colorName}`});
      }
    }
    return result;
  };

  // T/TS
  renderTextValue = ({item, name, value}) => {
    if (!isString(value)) return this.renderPrimitiveValue({item, name});
    return (
      <div className='value-container'>
        <div>
          <pre>
            <code>{value}</code>
          </pre>
        </div>
      </div>
    );
  };

  // NTS/NSTS
  renderLineChart = ({item, items, rangeMin, rangeMax, expanded = false}) => {
    const {props: {stage, filters: {combineGraphs}, visibleColumns}, valueColumnName} = this;
    const units = stage.units[valueColumnName];

    const stageKeys = isEmpty(visibleColumns) ?
      keys(stage.keys) :
      filter(keys(stage.keys), (key) =>
        (includes(visibleColumns, key) || includes(visibleColumns, `properties.${key}`)));
    const sortedKeys = sortStagePropertyNames(stageKeys);

    const [originalMinValue, originalMaxValue] = this.getItemSampleExtentValues(items);

    const minValue = min([originalMinValue, rangeMin, rangeMax]);
    const maxValue = max([originalMaxValue, rangeMin, rangeMax]);

    if (combineGraphs !== COMBINE_GRAPHS_MODE.NONE) {
      return (
        <TimelineGraphContainer
          items={items}
          itemSamplesPath='persisted_samples'
          useCurrentTimeAsTimelineEnd={false}
          expanded={expanded}
        >
          {combineGraphs === COMBINE_GRAPHS_MODE.LINEAR ? (
            <MultipleLineChart
              popupContentItemKeys={sortedKeys}
              minValue={minValue}
              maxValue={maxValue}
              units={units}
              valueKeyName={valueColumnName}
              popupRenderers={[
                renderSeconds.renderValue,
                renderSpeed.renderValue,
                this.renderChartPopupSystemIdLabel
              ]}
            />
          ) : combineGraphs === COMBINE_GRAPHS_MODE.STACKED ? (
            <StackedChart
              popupContentItemKeys={sortedKeys}
              units={units}
              valueKeyName={valueColumnName}
              popupRenderers={[
                renderSeconds.renderValue,
                renderSpeed.renderValue,
                this.renderChartPopupSystemIdLabel
              ]}
            />
          ) : null}
        </TimelineGraphContainer>
      );
    }

    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        useCurrentTimeAsTimelineEnd={false}
        expanded={expanded}
      >
        <LineChart
          minValue={minValue}
          maxValue={maxValue}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          colors={this.stageGraphColors}
          units={units}
          valueKeyName={valueColumnName}
        />
      </TimelineGraphContainer>
    );
  };

  // NTS/NSTS + Range
  renderLineChartWithRange = ({item, index, items, expanded = false}) => {
    const {patternDescription: {relatedStages}, props: {processor}} = this;
    const [nsSourceItems] = this.getAllCorrespondingStageItemsForStages({items, stages: relatedStages});
    const nsSourceItem = nsSourceItems[index];
    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

    return nsSourceItem ? this.renderLineChart({
      item: nsSourceItem,
      items: nsSourceItems,
      rowKey: item.id,
      rangeMin, rangeMax,
      index, expanded, processor
    }) : null;
  };

  // DSTS/DSSTS
  renderDiscreteStateTimeline = ({item, expanded = false}) => {
    const {props: {filters: {timeSeriesAggregation}}, valueColumnName} = this;
    const {possible_values: possibleValues} = this.valueSchemas[valueColumnName];
    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        expanded={expanded}
        timeSeriesAggregation={timeSeriesAggregation}
        useCurrentTimeAsTimelineEnd={timeSeriesAggregation === 0}
      >
        <DiscreteStateTimeline
          possibleValues={possibleValues}
          colors={this.stageGraphColors}
          valueKeyName={valueColumnName}
        />
      </TimelineGraphContainer>
    );
  };

  // DSTS/DSSTS
  renderDiscreteStateTimelineWithLegend = ({item, expanded = false}) => {
    const {
      patternDescription: {relatedStages, relatedProcessors},
      context: {processorDefinitions},
      valueColumnName,
    } = this;
    const {possible_values: possibleValues} = this.valueSchemas[valueColumnName];

    const [nsProcessor, rangeProcessor, timeInStateProcessor] = relatedProcessors;

    const [nsSourceItem, rangeSourceItem] =
      this.getCorrespondingStageItemsForStages({item, stages: relatedStages});

    const processorDefinition = find(processorDefinitions, {name: nsProcessor.type});
    const counterTypeSchema = processorDefinition.schema.properties.counter_type;
    const counterTypeName = nsProcessor.properties.counter_type;

    let timeLineSamples = item.persisted_samples;
    if (!timeLineSamples && item.value) {
      timeLineSamples = [{
        timestamp: item.timestamp,
        value: item.value,
      }];
    }

    const numericValue = nsSourceItem?.value;
    const lastSample = last(timeLineSamples);
    const sustainedAnomalyValue = lastSample?.value;
    const instantaneousAnomalyValue = rangeSourceItem?.value;

    return (
      <TimelineGraphContainer
        samples={timeLineSamples}
        expanded={expanded}
        popupIntervalPrefix='Time in State: '
      >
        {(childProps) => (
          <Fragment>
            <DiscreteStateTimeline {...childProps} possibleValues={possibleValues} colors={this.stageGraphColors} />
            <AnomalyHistoryGraphLegend
              counterType={counterTypeName ? <Value value={counterTypeName} schema={counterTypeSchema} /> : null}
              lastSampleTime={last(timeLineSamples)?.timestamp}
              numericValue={numericValue}
              sustainedAnomalyValue={sustainedAnomalyValue}
              instantaneousAnomalyValue={instantaneousAnomalyValue}
              possibleValues={possibleValues}
              relatedProcessors={{rangeProcessor, timeInStateProcessor}}
              relatedItems={{rangeSourceItem, timeInStateSourceItem: item}}
              colors={this.stageGraphColors}
            />
          </Fragment>
        )}
      </TimelineGraphContainer>
    );
  };

  // DSTS/DSSTS
  renderDiscreteStateTimelineWithSamples = ({item, index, items, expanded = false, rowKey = item.id}) => {
    const {
      patternDescription: {relatedStages, relatedProcessors},
      context: {processorDefinitions}, stageGraphColors,
      props: {stage},
      valueColumnName
    } = this;
    const {possible_values: possibleValues} = this.valueSchemas[valueColumnName];
    const units = stage.units[valueColumnName];

    const correspondingSourceStageItems = this.getAllCorrespondingStageItemsForStages({items, stages: relatedStages});
    const [nsSourceItems] = correspondingSourceStageItems;
    const [minValue, maxValue] = this.getItemSampleExtentValues(nsSourceItems);

    const graphProps = {correspondingSourceStageItems, expanded, index, item, maxValue, minValue,
      possibleValues, processorDefinitions, relatedProcessors, rowKey,
      units, colors: stageGraphColors};

    return (
      <DiscreteStateTimelineWithSamples {...graphProps} />
    );
  };

  // TTS/TSTS
  renderEventTimeline = ({item, expanded = false}) => {
    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        expanded={expanded}
      >
        <EventTimeline valueKeyName={this.valueColumnName} />
      </TimelineGraphContainer>
    );
  };

  // Stage data renderers
  renderDeviceTraffic = () => {
    const {filters, stage, stageItems, patternData, probe} = this.props;
    const averageInterfaceCountersStage = find(
      probe?.stages,
      {name: TRAFFIC_PROBE_STAGE.averageInterfaceCounters}
    );
    return (
      <TrafficDiagramContainer
        stage={stage}
        stageItems={stageItems}
        patternData={patternData}
        sourceNodeId={filters?.filter?.sourceId}
        destinationNodeId={filters?.filter?.targetId}
        systemValuesSchema={toJS(stage?.values)}
        interfaceValuesSchema={toJS(averageInterfaceCountersStage?.values)}
      />
    );
  };

  // EVPN routes
  renderRoutesStatus = ({item}) => {
    const color = item.value === 'Missing' ? 'red' : item.value === 'Expected' ? 'green' : null;
    return (
      <div className={cx('value-container', {[`graph-color-${color}`]: !!color})}>
        <div>
          <Value value={item.value} />
        </div>
      </div>
    );
  };

  // Optical Transcievers
  renderAnomalousOpticalMetric = (value) => {
    const {props: {stage}, valueColumnName} = this;
    const {name, item, items, expanded} = value;
    const warnColor = 'yellow';
    const alarmColor = 'red';

    const interfaceStatsStageName = get(this.patternDescription, ['fetchDataForRelatedStages', 0, 'stageName']);
    const sourceThresholdItem =
      (interfaceStatsStageName && get(item, ['preceding_items', interfaceStatsStageName, 0])) ?? item;

    const [lowWarn, highWarn] = [
      get(sourceThresholdItem, [`${name}_low_warn`]),
      get(sourceThresholdItem, [`${name}_high_warn`]),
    ];
    const [lowAlarm, highAlarm] = [
      get(sourceThresholdItem, [`${name}_low_alarm`]),
      get(sourceThresholdItem, [`${name}_high_alarm`]),
    ];
    const rangesByColor = {};
    rangesByColor[warnColor] = [
      {name: 'Warning High', borders: [highWarn, highAlarm], borderColor: warnColor, includeBorders: {min: true}},
      {name: 'Warning Low', borders: [lowAlarm, lowWarn], borderColor: warnColor, includeBorders: {max: true}},
    ];
    rangesByColor[alarmColor] = [
      {name: 'Alert High', borders: [highAlarm, null], borderColor: alarmColor, includeBorders: {min: true}},
      {name: 'Alert Low', borders: [null, lowAlarm], borderColor: alarmColor, includeBorders: {max: true}},
    ];

    const units = stage.units[name];
    const [originalMinValue, originalMaxValue] = this.getItemSampleExtentValues(items);
    const minValue = min([originalMinValue, lowAlarm]);
    const maxValue = max([originalMaxValue, highAlarm]);

    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        useCurrentTimeAsTimelineEnd={false}
        expanded={expanded}
      >
        <LineChart
          minValue={minValue}
          maxValue={maxValue}
          rangesByColor={rangesByColor}
          colors={anomalyColors}
          units={units}
          valueKeyName={valueColumnName}
          showRangeInfoInPopup
        />
      </TimelineGraphContainer>
    );
  };

  // MAC probe missing macs
  renderMissingMacCount = ({item, name, items}) => {
    if (!isFinite(item.value)) return this.renderPrimitiveValue({item, name});
    const {blueprintId} = this.context;
    const {probe, processor} = this.props;
    const stageName = processor.outputs.mac;
    const filter = filtersToQueryParam({
      'properties.system_id': item.properties.system_id,
      'properties.vni': item.properties.vni,
      value: 'missing',
    });
    const queryParams = {filters: {filter}};
    const extraContent = (
      <>
        {' '}
        <Link to={generateProbeURI({blueprintId, probeId: probe.id, stageName, queryParams})}>{'(view)'}</Link>
      </>
    );
    return this.renderHorizontalBar({item, name, items, extraContent});
  };

  // Renderer mapping
  valueRenderers = {
    formattedNumberValue: this.renderFormattedNumber,
    primitiveValue: this.renderPrimitiveValue,
    textValue: this.renderTextValue,
    discreteStateValue: this.renderDiscreteStateValue,
    horizontalBar: this.renderHorizontalBar,
    discreteStateTimeline: this.renderDiscreteStateTimeline,
    discreteStateTimelineWithLegend: this.renderDiscreteStateTimelineWithLegend,
    discreteStateTimelineWithSamples: this.renderDiscreteStateTimelineWithSamples,
    lineChart: this.renderLineChart,
    lineChartWithRange: this.renderLineChartWithRange,
    eventTimeline: this.renderEventTimeline,
    gaugeCount: this.renderGaugeCount,
    gaugeCountWithRange: this.renderGaugeCountWithRange,
    gaugePercent: this.renderGaugePercent,
    gaugePercentWithRange: this.renderGaugePercentWithRange,
    routesStatus: this.renderRoutesStatus,
    anomalousOpticalMetric: this.renderAnomalousOpticalMetric,
    missingMacCount: this.renderMissingMacCount,
  };

  stageDataRenderers = {
    deviceTraffic: this.renderDeviceTraffic,
  };

  @computed get renderingStrategy() {
    const {props: {probe, stage, processor, filters: {dataSource, showContextInfo}}} = this;
    return getStageRenderingStrategy({
      probe, processor, stage,
      dataSource, usePattern: showContextInfo,
    });
  }

  @computed get renderValueAs() {
    const {renderingStrategy, valueColumnName} = this;
    const valueSchema = this.valueSchemas[valueColumnName];
    return isFunction(renderingStrategy.renderValueAs) ?
      renderingStrategy.renderValueAs(valueSchema) :
      renderingStrategy.renderValueAs ?? 'primitiveValue';
  }

  @computed get mayCombineGraphs() {
    const {spotlightMode} = this.props.filters;
    if (spotlightMode) return false;
    const {renderingStrategy, valueColumnName, props: {stage: {values}}} = this;
    const valueSchema = values[valueColumnName];
    return isFunction(renderingStrategy.graphCombiningAvailable) ?
      renderingStrategy.graphCombiningAvailable(valueSchema) :
      renderingStrategy.graphCombiningAvailable ?? false;
  }

  @computed get maySwitchSpotlightMode() {
    return !this.props.compact;
  }

  @computed get stageDataSourceIsTimeSeries() {
    return this.props.filters.dataSource === STAGE_DATA_SOURCE.time_series;
  }

  @computed get customRows() {
    const {spotlightMode} = this.props.filters;
    return transform(this.props.stageItems, (result, item) => {result[item.id] = spotlightMode;}, {});
  }

  @computed get stageOptions() {
    const {patternDescription, stageDataSourceIsTimeSeries, props: {filters, processor}} = this;
    return [
      {
        name: 'Anomalies Only',
        isAvailable: processorCanRaiseAnomalies(processor) && !stageDataSourceIsTimeSeries,
        getUpdateOptions: (isSelected) => ({filters: {anomalousOnly: isSelected}}),
        isSelected: filters.anomalousOnly
      },
      {
        name: 'Show Context',
        isAvailable: !!patternDescription &&
          !patternDescription.renderingStrategy?.alwaysUseContext &&
          !stageDataSourceIsTimeSeries,
        getUpdateOptions: (isSelected) => {
          if (!isSelected && patternDescription?.renderingStrategy?.alwaysUseContext) return {};
          return {filters: {showContextInfo: isSelected}};
        },
        isSelected: filters.showContextInfo
      }
    ];
  }

  @computed get availableOptions() {
    return map(filter(this.stageOptions, {isAvailable: true}), 'name');
  }

  @computed get availableOptionsControls() {
    return map(this.availableOptions, (option) => (
      <Checkbox
        key={option}
        label={option}
        checked={includes(this.selectedOptions, option)}
        onChange={() => this.applyStageOptions(xor(this.selectedOptions, [option]))}
      />
    ));
  }

  @computed get selectedOptions() {
    return map(filter(this.stageOptions, {isSelected: true}), 'name');
  }

  @computed get aggregationTypeOptions() {
    const {stage, filters} = this.props;
    return map(getPossibleAggregationTypes(stage, filters.valueColumnName), (type) => ({
      key: type,
      text: type,
      value: type,
    }));
  }

  @action
  applyStageOptions = (value) => {
    const {props: {filters, updatePagination}, stageOptions, updateFilters} = this;
    const updateOptions = {};
    forEach(stageOptions, (option) => {
      if (option.isAvailable) {
        const isSelected = value.includes(option.name);
        if (isSelected !== option.isSelected) {
          merge(updateOptions, option.getUpdateOptions(isSelected));
        }
      } else if (option.isSelected) {
        merge(updateOptions, option.getUpdateOptions(false));
      }
    });
    if (updateOptions.filters) {
      const newFilters = {...filters, ...updateOptions.filters};
      updateFilters(newFilters);
    }
    if (updateOptions.pagination) {
      updatePagination(updateOptions.pagination);
    }
  };

  updateFilters = (newFilters) => {
    const {props: {filters}, patternDescription} = this;
    const processedFilters = {...newFilters};
    if (patternDescription?.renderingStrategy?.clearFilterOnContextTrigger &&
      has(newFilters, 'showContextInfo') &&
      filters?.showContextInfo !== newFilters?.showContextInfo
    ) {
      processedFilters.filter = {};
    }
    this.props.updateFilters(processedFilters);
  };

  @action
  onDataSourceChange = (value) => {
    const {props: {probe, stage, filters}, updateFilters} = this;
    const patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource: value});
    const changes = {dataSource: value};
    if (value === STAGE_DATA_SOURCE.telemetry_service_warnings ||
      filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (filters.filter) changes.filter = {};
    }
    if (value === STAGE_DATA_SOURCE.time_series) {
      if (!filters.timeSeriesDuration) {
        changes.timeSeriesDuration = DEFAULT_STAGE_TIME_SERIES_DURATION;
      }
      if (filters.showContextInfo && !patternDescription?.renderingStrategy?.alwaysUseContext) {
        changes.showContextInfo = false;
      }
    } else if (value === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (filters.spotlightMode) {
        changes.spotlightMode = false;
      }
    } else if (patternDescription) {
      changes.showContextInfo = !patternDescription.renderingStrategy?.hiddenContextByDefault;
    }
    updateFilters({...filters, ...changes});
  };

  @action
  onUpdateValueColumnName = (valueColumnName) => {
    const {props: {filters, stage}, updateFilters, patternDescription} = this;
    const aggregationType = getValueAggregationTypes(stage, valueColumnName, patternDescription);
    updateFilters({...filters, valueColumnName, aggregationType});
  };

  getDataTableCellProps = ({name, item}) => {
    const {noSpotlightMode} = this.renderingStrategy;
    const {stage} = this.props;
    const valueCell = name in this.valueSchemas;
    const expandableValueCell = name in stage.values && !noSpotlightMode;
    return ({
      onClick: expandableValueCell && this.maySwitchSpotlightMode ? () => this.switchToSpotlightMode(item.id) : null,
      className: cx({
        'value-cell': valueCell,
        expandable: expandableValueCell,
        highlight: this.highlightedColumn === name,
      })
    });
  };

  getSpotlightModeDataTableProps = ({name, params}) => ({
    label: params.labels[name]
  });

  renderSpotlightItemValue = () => {
    const {props: {stageItems}, valueColumnName} = this;
    const renderValue = this.valueRenderers[this.renderValueAs];
    return renderValue({item: stageItems[0], name: valueColumnName, index: 0, items: stageItems, expanded: true});
  };

  renderCombinedGraphs = () => {
    const {stageItems} = this.props;
    const renderValue = this.valueRenderers[this.renderValueAs];
    return renderValue({
      item: stageItems[0],
      index: 0,
      items: stageItems,
      expanded: true
    });
  };

  renderStageDataTableFooterContent = (wrap, colSpan) => {
    const {tableSchema, props: {stageLink}} = this;
    if (!stageLink) return null;
    const footer = (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell
            className={cx('stage-footer', {standalone: wrap})}
            colSpan={colSpan || tableSchema.length}
            textAlign='center'
          >
            {stageLink}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
    return wrap ? <Table size='small'>{footer}</Table> : footer;
  };

  wrapWithLoader(renderContent, noDataContent, alwaysShownContent = null) {
    const {loaderVisible, fetchDataError} = this.props;

    return loaderVisible ?
      <Placeholder className='stage-placeholder-background' fluid>
        {times(4, (index) =>
          <Placeholder.Paragraph key={index}>
            {times(10, (index) =>
              <Placeholder.Line key={index} />
            )}
          </Placeholder.Paragraph>
        )}
      </Placeholder>
      :
      fetchDataError ?
        <FetchDataError error={fetchDataError} /> :
        <>
          {alwaysShownContent}
          {noDataContent || renderContent()}
        </>;
  }

  renderSpotlightMode(noDataContent) {
    const {
      activePage, pageSize, updatePagination, totalCount,
      probe, stage, stageItems, stageLink
    } = this.props;
    const {blueprintId} = this.context;

    const paginationProps = {
      activePage,
      pageSize,
      pageSizes: [1],
      totalCount,
      onChange: updatePagination,
    };

    const tableParams = {
      labels: transform(this.tableSchema, (result, schemaItem) => {
        result[schemaItem.name] = schemaItem.label;
      }, {}),
      blueprintId, probe, stage
    };

    return this.wrapWithLoader(
      () => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          ref={this.spotlightViewRef}
          tabIndex={-1}
          onKeyDown={this.onSpotlightModeKeyDown}
          className='spotlight-mode-container'
        >
          <div className='spotlight-mode-item item-set'>
            <div className='spotlight-mode-item-value'>
              {this.renderSpotlightItemValue()}
              {this.maySwitchSpotlightMode &&
                <Icon link name='close' onClick={this.switchToListMode} />
              }
            </div>
            <Table celled size='small' className='schema-table'>
              <Table.Body>
                <DataTableRowFragment
                  schema={this.tableSchema}
                  item={stageItems[0]}
                  params={tableParams}
                  getCellProps={this.getSpotlightModeDataTableProps}
                  CellComponent={SpotlightKeysTableRow}
                />
              </Table.Body>
              {stageLink &&
                this.renderStageDataTableFooterContent(false, 2)
              }
            </Table>
          </div>
          <div className='pagination-centered'>
            <Pagination separateButtons {...paginationProps} />
          </div>
        </div>
      ),
      noDataContent
    );
  }

  @computed
  get searchSchema() {
    return getStageSearchSchema(this.props, this.context);
  }

  getFilteringControls() {
    const {
      stageHasPersistedData,
      stageDataSourceIsTimeSeries,
      onDataSourceChange,
      usePatternWithPersistedData,
      usePatternWithRawPersistedData,
      onUpdateValueColumnName, valueColumnName,
      mayCombineGraphs,
      updateFilters,
      aggregationTypeOptions,
      props: {compact, stage, processor, filters}
    } = this;

    const valueColumnOptions = values(stage.values).map(({name, title}) => ({
      key: name,
      value: name,
      text: title || humanizeString(name),
    }));

    const dataSourceOptions = filter([
      {key: STAGE_DATA_SOURCE.real_time, value: STAGE_DATA_SOURCE.real_time, text: 'Real Time'},
      stageHasPersistedData ?
        {key: STAGE_DATA_SOURCE.time_series, value: STAGE_DATA_SOURCE.time_series, text: 'Time Series'} :
        null,
      processorCanRaiseWarnings(processor) ?
        {
          key: STAGE_DATA_SOURCE.telemetry_service_warnings,
          value: STAGE_DATA_SOURCE.telemetry_service_warnings,
          text: 'Telemetry Service Warnings'
        } :
        null
    ]);

    const isTimeSeriesControl = (stageDataSourceIsTimeSeries || usePatternWithPersistedData);

    const showPersistedDataControl = (
      stageHasPersistedData || usePatternWithPersistedData ||
      usePatternWithRawPersistedData || processorCanRaiseWarnings(processor)
    ) && (
      dataSourceOptions.length > 1 || mayCombineGraphs ||
        (isTimeSeriesControl && valueColumnOptions.length > 1) ||
        usePatternWithRawPersistedData
    );

    return !compact &&
      <Fragment>
        {showPersistedDataControl &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='stage-data-controls'>
                {dataSourceOptions.length > 1 &&
                  <DropdownControl
                    value={filters.dataSource || DEFAULT_STAGE_DATA_SOURCE}
                    selectedValueLabel='Data source: '
                    options={dataSourceOptions}
                    onChange={onDataSourceChange}
                    className='data-source'
                  />}
                {stageDataSourceIsTimeSeries && valueColumnOptions.length > 1 && (
                  <ValueColumnNameInput
                    options={valueColumnOptions}
                    value={valueColumnName}
                    onChange={(value) => onUpdateValueColumnName(value)}
                  />
                )}
                {mayCombineGraphs &&
                  <DropdownControl
                    value={filters.combineGraphs || DEFAULT_COMBINE_GRAPHS_MODE}
                    selectedValueLabel=' '
                    options={[
                      {key: COMBINE_GRAPHS_MODE.NONE, value: COMBINE_GRAPHS_MODE.NONE,
                        text: 'Separate graphs'},
                      {key: COMBINE_GRAPHS_MODE.LINEAR, value: COMBINE_GRAPHS_MODE.LINEAR,
                        text: 'Combine graphs: Linear'},
                      {key: COMBINE_GRAPHS_MODE.STACKED, value: COMBINE_GRAPHS_MODE.STACKED,
                        text: 'Combine graphs: Stacked'},
                    ]}
                    onChange={(value) => updateFilters({...filters, combineGraphs: value})}
                  />
                }
                {usePatternWithRawPersistedData && (
                  <>
                    {usePatternWithPersistedData && (
                      <AggregationInput
                        value={filters.timeSeriesAggregation}
                        disabled={filters.aggregationType === 'none'}
                        selectedValueLabel='Time Series Aggregation: '
                        onChange={(timeSeriesAggregation) => updateFilters(
                          {...filters, timeSeriesAggregation}, {resetActivePage: false})}
                      />
                    )}
                    <DurationInput
                      value={filters.timeSeriesDuration}
                      selectedValueLabel='Time Series Duration: '
                      customValueType='dates'
                      onChange={(timeSeriesDuration) => updateFilters(
                        {...filters, timeSeriesDuration}, {resetActivePage: false})}
                    />
                  </>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        }
        {isTimeSeriesControl && !usePatternWithRawPersistedData &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='stage-aggregation-controls'>
                <AggregationTypeInput
                  selectedValueLabel='Aggregation Type: '
                  value={filters.aggregationType}
                  onChange={(aggregationType) => updateFilters(
                    {
                      ...filters,
                      aggregationType,
                      timeSeriesAggregation: aggregationType === 'none' ?
                        0 : filters.timeSeriesAggregation || DEFAULT_STAGE_TIME_SERIES_AGGREGATION
                    },
                    {resetActivePage: false}
                  )}
                  options={aggregationTypeOptions}
                  clearable
                />
                <AggregationInput
                  value={filters.timeSeriesAggregation}
                  disabled={filters.aggregationType === 'none'}
                  selectedValueLabel='Time Series Aggregation: '
                  aggregations={filters.aggregationType === 'none' ?
                    aggregationIntervalOptionsWithOff : aggregationIntervalOptions}
                  onChange={(timeSeriesAggregation) => updateFilters(
                    {...filters, timeSeriesAggregation}, {resetActivePage: false})}
                />
                <DurationInput
                  value={filters.timeSeriesDuration}
                  selectedValueLabel='Time Series Duration: '
                  customValueType='dates'
                  onChange={(timeSeriesDuration) => updateFilters(
                    {...filters, timeSeriesDuration}, {resetActivePage: false})}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        }
        {this.availableOptions.length > 0 &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='options'>
                {this.availableOptionsControls}
              </div>
            </Grid.Column>
          </Grid.Row>
        }
      </Fragment>;
  }

  renderFilteredData(noDataContent) {
    const {
      tableSchema,
      renderingStrategy, anomaliesByItems,
      renderStageDataTableFooterContent,
      renderCombinedGraphs, mayCombineGraphs,
      updateFilters, highlightPropertyColumn, highlightedColumn,
      props: {
        compact, probe, stage,
        loaderVisible, fetchDataError,
        activePage, pageSize, updatePagination,
        filters, stageItems,
        sorting, updateSorting,
        totalCount
      },
      context: {blueprintId},
    } = this;
    const {spotlightMode} = filters;
    const pageSizes = spotlightMode ? [1] : DEFAULT_PAGE_SIZES;
    const {
      noAnomalyHighlighting, noSearch, noPagination = null,
      SearchComponent = StageSearchBox
    } = renderingStrategy;
    const tableParams = {highlightedColumn, blueprintId, probe, stage};
    const paginationProps = {
      activePage,
      pageSize,
      pageSizes,
      totalCount,
      onChange: updatePagination,
    };

    return (
      <DataFilteringLayout
        loaderVisible={loaderVisible}
        fetchDataError={fetchDataError}
        hideActionsAndPagination={compact}
        paginationProps={noPagination ? undefined : paginationProps}
        SearchComponent={SearchComponent}
        searchProps={{
          filters: filters.filter,
          schema: this.searchSchema,
          renderers: [renderRegexTextInput, rangeControlRenderer],
          disabled: noSearch,
          onChange: (filter) => updateFilters({...filters, filter}),
          highlightPropertyColumn,
          'aria-label': 'Stage filter query',
          asAccordion: false
        }}
        extraContent={{
          headerRow: this.getFilteringControls()
        }}
      >
        {
          noDataContent || (
            mayCombineGraphs && filters.combineGraphs !== COMBINE_GRAPHS_MODE.NONE ?
              <>
                {renderCombinedGraphs()}
                {renderStageDataTableFooterContent(true)}
              </>
            :
              <DataTable
                className='item-set'
                size='small'
                items={stageItems}
                schema={tableSchema}
                sortable={!compact}
                sorting={sorting}
                updateSorting={updateSorting}
                getHeaderCellProps={({name}) => ({
                  collapsing: !(name in stage.values),
                  className: cx({highlight: name === highlightedColumn})
                })}
                getCellProps={this.getDataTableCellProps}
                getRowProps={({item}) => ({
                  warning: !isEmpty(item.warning),
                  error: !noAnomalyHighlighting && item.id in anomaliesByItems,
                })}
                getItemKey={({id}) => id}
                footer={
                  renderStageDataTableFooterContent(false)
                }
                params={tableParams}
              />
          )
        }
      </DataFilteringLayout>
    );
  }

  render() {
    const {
      renderingStrategy,
      renderStageDataTableFooterContent,
      highlightPropertyColumn,
      updateFilters,
      props: {filters, stageItems, stage, processor, noSearch}
    } = this;
    const {spotlightMode} = filters;
    const {renderStageDataAs = null, noDataMessage, SearchComponent = StageSearchBox} = renderingStrategy;

    const noDataContent = isEmpty(stageItems) ?
      <div className='no-data'>
        {
          filters.anomalousOnly ?
            <Message success icon='check circle' header='No anomalies!' />
          :
            <Message info icon='info circle' content={noDataMessage || 'No data'} />
        }
        {this.renderStageDataTableFooterContent(true)}
      </div> :
      null;

    const stageControls = (
      <Grid stackable className='data-filtering-layout'>
        {this.getFilteringControls()}
        <Grid.Row>
          <Grid.Column width={16}>
            <SearchComponent
              filters={filters.filter}
              stage={stage}
              stageItems={stageItems}
              processor={processor}
              dataSource={filters.dataSource}
              disabled={noSearch}
              onChange={(filter) => updateFilters({...filters, filter})}
              highlightPropertyColumn={highlightPropertyColumn}
              aria-label='Stage filter query'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return (
      renderStageDataAs ?
        this.wrapWithLoader(
          () => (
            <>
              {this.stageDataRenderers[renderStageDataAs]()}
              {renderStageDataTableFooterContent(true)}
            </>
          ),
          noDataContent,
          stageControls
        )
      :
        this.wrapWithLoader(
          () => (
            <div className='item-set-container'>
              {spotlightMode ?
                this.renderSpotlightMode(noDataContent)
              :
                this.renderFilteredData(noDataContent)
              }
            </div>
          )
        )
    );
  }
}

function ColumnDescription({column, stage, stageGraphColors}) {
  const isContextValue = column.valueSchema && !(column.name in stage.values);
  const kind =
    column.key ? 'key' :
    isContextValue ? 'context value' :
    stage.values[column.name] ? 'value' : null;
  const descriptionSchema = {
    Name: column.name,
    Kind: kind,
    Type: column.type,
    Units: column.valueSchema && stage.units[column.name],
    Description: column.valueSchema?.description && castArray(column.valueSchema.description).join(', '),
    'Possible Values': !isEmpty(column.valueSchema?.possible_values) &&
      <DiscreteStateLegend
        colors={stageGraphColors}
        possibleValues={column.valueSchema.possible_values}
      />
  };
  return map(descriptionSchema, (value, key) =>
    value ?
      <div key={key}>
        <strong>{key}{': '}</strong>
        {value}
      </div>
    : null
  );
}

class SystemInfo extends PureComponent {
  static contextType = IBAContext;

  render() {
    const {systemId} = this.props;
    const {systemIdMap, systemsHrefs} = this.context;
    const systemInfo = systemIdMap[systemId] ?? {};
    const {href, hostname, role} = systemInfo;
    const internalUrl = systemsHrefs ? systemsHrefs[systemId] : null;
    const systemIdHref = href ?? internalUrl;
    return [
      <div key='link'>
        {systemIdHref ?
          <a href={systemIdHref}>{systemId}</a> :
          systemId
        }
      </div>,
      hostname && <div key='hostname'>{hostname}</div>,
      role && <Label key='role' size='tiny'>{NODE_ROLES[role] ?? role}</Label>,
    ];
  }
}

function SpotlightKeysTableRow({children, label}) {
  return (
    <Table.Row role='row'>
      <Table.Cell role='cell' collapsing>
        {label}
      </Table.Cell>
      <Table.Cell role='cell' collapsing>
        {children}
      </Table.Cell>
    </Table.Row>
  );
}

class TelemetryServiceStatus extends Component {
  render() {
    const {warning} = this.props;
    const warningType = warning ? warning.type : null;
    if (warningType === 'invalid_service') {
      return (
        <Fragment>
          <div><strong>{'Invalid service'}</strong></div>
          <div>{warning.message}</div>
        </Fragment>
      );
    } else if (warningType === 'conflict') {
      return (
        <Fragment>
          <div><strong>{'Conflict'}</strong></div>
          {map(['input', 'interval', 'execution_count'], (property) => {
            const expected = get(warning, ['expected', property], null);
            const actual = get(warning, ['actual', property], null);
            return expected !== actual ? (
              <Fragment key={property}>
                <div>{`Expected ${startCase(property)}: `}<b><Value value={expected} /></b></div>
                <div>{`Actual ${startCase(property)}: `}<b><Value value={actual} /></b></div>
              </Fragment>
            ) : null;
          })}
        </Fragment>
      );
    }
    return 'No warnings';
  }
}
