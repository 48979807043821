import {Component} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';
import {RadioGroupInput} from 'apstra-ui-common';

import AggregationInput from '../../components/AggregationInput';

import './StageDataSourceSelectors.less';

export const DATA_SOURCE = {
  realTime: 'Real Time',
  timeSeries: 'Time Series',
};

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const aggregations = [
  {text: '2 Minutes', value: MINUTE_IN_SECONDS * 2},
  {text: '5 Minutes', value: MINUTE_IN_SECONDS * 5},
  {text: '10 Minutes', value: MINUTE_IN_SECONDS * 10},
  {text: '20 Minutes', value: MINUTE_IN_SECONDS * 20},
  {text: '30 Minutes', value: MINUTE_IN_SECONDS * 30},
  {text: '1 Hour', value: HOUR_IN_SECONDS},
  {text: '3 Hours', value: HOUR_IN_SECONDS * 3},
  {text: '6 Hours', value: HOUR_IN_SECONDS * 6},
  {text: '12 Hours', value: HOUR_IN_SECONDS * 12},
  {text: '1 Day', value: HOUR_IN_SECONDS * 24},
];

export default class StageDataSourceSelectors extends Component {
  static props = {
    aggregation: PropTypes.number,
    dataSource: PropTypes.string,
    onChange: PropTypes.func
  };

  onDataSourceChange = (dataSource) => {
    this.props.onChange({dataSource});
  };

  onAggregationChange = (aggregation) => {
    this.props.onChange({aggregation});
  };

  render() {
    const {onDataSourceChange, onAggregationChange, props: {dataSource, aggregation}} = this;

    return (
      <div className='stage-data-source-selectors'>
        <Form className='data-source'>
          <RadioGroupInput
            value={dataSource}
            schema={{enum: [DATA_SOURCE.realTime, DATA_SOURCE.timeSeries]}}
            onChange={onDataSourceChange}
          />
        </Form>
        {dataSource === DATA_SOURCE.timeSeries &&
          <small>
            <AggregationInput
              label='Aggregation: '
              value={aggregation}
              aggregations={aggregations}
              onChange={onAggregationChange}
            />
          </small>
        }
      </div>
    );
  }
}
