import {Fragment, useEffect, useMemo, useRef, useCallback, useState} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, Grid, Header, Label, Message, Popup, Segment, Tab} from 'semantic-ui-react';
import {action, observable, makeObservable, reaction} from 'mobx';
import {observer} from 'mobx-react';
import {debounce, find, forEach, map, mapValues, pick, transform, isNil, values} from 'lodash';
import cx from 'classnames';
import {Checkbox, CodeEditorControl, DataFilteringContainerWithRouter, DropdownControl, FetchData,
  FetchDataError, FormattedJSON, Loader,
  interpolateRoute, orderedKeysReplacer, request} from 'apstra-ui-common';

import GraphExplorerContext from './GraphExplorerContext';
import GraphFullBlueprint from './GraphFullBlueprint';
import GraphQueryResult, {GraphQueryResultStore} from './GraphQueryResult';
import GraphReferenceDesignSchema from './GraphReferenceDesignSchema';
import {GraphStore} from './Graph';
import wrapWithComponent from '../wrapWithComponent';
import QueryBuilder from '../queryBuilder/QueryBuilder';
import {useStateCallback} from '../useStateCallback';
import QueryCatalogModal from '../queryBuilder/QueryCatalogModal';
import {QueryEntityModalWithPopup} from '../queryBuilder/QueryEntityModal';

import './GraphExplorer.less';
import {interWindowControlled} from './InterWindowController';
import QueryDeleteModal from '../queryBuilder/QueryDeleteModal';

export const jsonPriorityKeys = ['id', 'type', 'label'];
const queryModes = [
  {
    id: 'qe',
    label: 'QueryEngine',
    route: '/api/blueprints/<blueprintId>/qe',
    editorMode: 'graph-query',
    editorPlaceholder: "node('system', name='system').out('hosted_interfaces').node('interface', name='interface')",
    prettifiable: true,
    graphRepresentable: true,
    traceable: true,
  },
  {
    id: 'cypher',
    label: 'Cypher',
    route: '/api/blueprints/<blueprintId>/cypher',
    editorMode: 'cypher',
    editorPlaceholder: 'match (system:system)-[:hosted_interfaces]->(interface:interface) return *',
    graphRepresentable: true,
  },
  {
    id: 'ql',
    label: 'GraphQL',
    route: '/api/blueprints/<blueprintId>/ql',
    editorPlaceholder: '{system_nodes {system_id}}',
  },
];
const graphTypes = ['staging', 'config', 'deployed', 'operation'];

export const createLinkId = ({linkType, source, target}) => `${linkType}|${source}|${target}`;

export const getDuplicateIndex = (duplicates, id) => {
  duplicates[id] = (duplicates[id] ?? 0) + 1;
  return duplicates[id];
};

export const transformNode = (sourceEntity) => ({
  id: sourceEntity.id,
  nodeType: sourceEntity.type,
  sourceEntity
});

export const transformLink = (sourceEntity) => ({
  id: sourceEntity.id,
  source: sourceEntity.source_id,
  target: sourceEntity.target_id,
  linkType: sourceEntity.type,
  sourceEntity
});

async function fetchQueryResult({currentQueryParams, signal}) {
  if (!currentQueryParams) return {};
  const {query, blueprintId, graphType, route} = currentQueryParams;
  const url = interpolateRoute(route, {blueprintId});
  const queryResult = await request(
    url,
    {
      body: JSON.stringify({
        'blueprint-id': blueprintId,
        query
      }),
      method: 'POST',
      queryParams: {type: graphType},
      signal
    }
  );
  return {queryResult};
}

async function fetchReferenceDesign({referenceDesignName, previousData, signal}) {
  if (previousData?.referenceDesignName?.referenceDesignName === referenceDesignName) {
    return pick(previousData, 'referenceDesignSchema');
  }
  const {nodes, relationships} = await request(
    interpolateRoute('/api/docs/reference_design_schemas/<reference_design_name>', {referenceDesignName}),
    {method: 'GET', signal}
  );
  const nodeProperties = mapValues(nodes, 'properties');
  const linkProperties = transform(relationships,
    (acc, link) => {
      const id = createLinkId({linkType: link.name, source: link.source_type, target: link.target_type});
      acc[id] = link.properties.properties;
    },
    {}
  );
  const duplicates = {};
  return {
    referenceDesignSchema: {
      nodes: map(nodes, (sourceEntity) => ({
        id: sourceEntity.title,
        nodeType: sourceEntity.title,
        sourceEntity
      })),
      links: map(relationships, (sourceEntity) => ({
        source: sourceEntity.source_type,
        target: sourceEntity.target_type,
        linkType: sourceEntity.name,
        id: createLinkId({
          linkType: sourceEntity.name,
          source: sourceEntity.source_type,
          target: sourceEntity.target_type
        }),
        duplicateIndex: getDuplicateIndex(duplicates, `${sourceEntity.source_type}|${sourceEntity.target_type}`),
        sourceEntity
      })),
      properties: {...nodeProperties, ...linkProperties},
      referenceDesignName
    }
  };
}

async function fetchFullBlueprint({blueprintId, signal, graphType}) {
  if (!blueprintId) return {};
  const blueprint = await request(
    interpolateRoute('/api/blueprints/<blueprint_id>', {blueprintId}),
    {
      method: 'GET',
      queryParams: {type: graphType},
      signal
    }
  );
  const nodes = map(blueprint?.nodes, transformNode);
  const links = map(blueprint?.relationships, transformLink);
  return {fullBlueprint: {nodes, links, blueprintId}};
}

async function fetchTrace({currentQueryParams, signal, traceable}) {
  const {query, blueprintId, graphType} = currentQueryParams;
  if (!blueprintId || !traceable) return {};
  const traceResult = await request(
    interpolateRoute('/api/blueprints/<blueprint_id>/trace-query', {blueprintId}),
    {
      body: JSON.stringify({query}),
      queryParams: {type: graphType},
      method: 'POST',
      signal
    }
  );
  return {traceResult};
}

async function fetchFullBlueprintAndQueryResultAndTrace(fetchParameters) {
  const {fetchContextualData} = fetchParameters.currentQueryParams;
  const [{queryResult}, {fullBlueprint}, {traceResult}] = await Promise.all([
    fetchQueryResult(fetchParameters),
    fetchContextualData ? fetchFullBlueprint(fetchParameters) : {fullBlueprint: {}},
    fetchContextualData ? fetchTrace(fetchParameters) : {traceResult: {}}
  ]);
  fetchParameters.setSubmitAvailableOn();
  return {queryResult, fullBlueprint, traceResult};
}

class UserDefinedEditableQueryStore {
  @observable queryProps = null;

  @observable isNewEntity = true;
  @observable submitAvailable = false;
  @observable isCatalogModalOpen = false;
  @observable isDeleteModalOpen = false;

  @observable lastCatalogUpdateDate;

  @action
  resetStore = () => {
    this.queryProps = {id: null, label: ''};
    this.submitAvailable = false;
  };

  @action
  onUpdateEditableQuery = (name, value) => {
    this.queryProps = {...this.queryProps, [name]: value};
  };

  @action
  onSaveEditableQuery = (editableQuery) => {
    this.queryProps = editableQuery;
  };

  @action
  setSubmitAvailableOn = () => {
    this.submitAvailable = true;
  };

  @action
  setSubmitAvailableOff = () => {
    this.submitAvailable = false;
  };

  @action
  openCatalogModal = () => {
    this.isCatalogModalOpen = true;
  };

  @action
  closeCatalogModal = () => {
    this.isCatalogModalOpen = false;
  };

  @action
  openDeleteModal = (deleteQuery) => {
    this.queryProps = deleteQuery;
    this.isDeleteModalOpen = true;
  };

  @action
  closeDeleteModal = () => {
    this.isDeleteModalOpen = false;
  };

  @action
  updateCatalogDate = () => {
    this.lastCatalogUpdateDate = +new Date();
  };

  constructor() {
    this.resetStore();
    this.updateCatalogDate();
    this.disposeIsNewReaction = reaction(
      () => this.queryProps,
      () => {
        this.isNewEntity = isNil(this.queryProps?.id);
      },
    );
    makeObservable(this);
  }
}

class Store {
  @observable query = null;
  @observable prettifiedQuery = null;
  @observable.ref currentQueryParams = null;
  @observable tabIndex = 0;
  @observable activeViewMode = null;
  @observable.ref hiddenNodeTypes = {};
  @observable refreshParam = null;

  @action
  clearCurrentQueryParams = () => {
    this.currentQueryParams = null;
  };

  @action
  setActiveViewMode = (viewMode) => {
    this.activeViewMode = viewMode;
  };

  @action
  updateTabIndex = (tabIndex) => {
    this.tabIndex = tabIndex;
  };

  @action
  updateQuery = (query) => {
    this.query = query;
    this.prettifyQuery(query);
  };

  @action
  refreshData = () => {
    this.refreshParam = new Date();
  };

  @action
  applyPrettifiedQuery = () => {
    this.prettifyQuery(this.query);
    this.query = this.prettifyQuery.flush();
    this.prettifiedQuery = null;
    this.codeEditorRef.current.focus();
  };

  @action
  onExecuteQuery = ({filters: {graphType, blueprintId, fetchContextualData}, route}) => {
    if (this.query.length) {
      const query = this.codeEditorRef.current.formatValue(this.query, false);
      this.currentQueryParams = {
        query,
        graphType,
        blueprintId,
        fetchContextualData,
        route,
        time: new Date(),
      };
      this.setActiveViewMode('query');
    }
  };

  @action
  toggleNodeTypeVisibility = (nodeTypeName) => {
    if (this.hiddenNodeTypes[nodeTypeName]) {
      delete this.hiddenNodeTypes[nodeTypeName];
    } else {
      this.hiddenNodeTypes[nodeTypeName] = true;
    }
    this.hiddenNodeTypes = {...this.hiddenNodeTypes};
  };

  @action
  hideNodeTypes = (nodeTypes) => {
    forEach(nodeTypes, (nodeType) => {
      this.hiddenNodeTypes[nodeType] = true;
    });
    this.hiddenNodeTypes = {...this.hiddenNodeTypes};
  };

  @action
  showAllNodeTypes = () => {
    this.hiddenNodeTypes = {};
  };

  prettifyQuery = (query) => {
    const prettifiedQuery = this.codeEditorRef.current.formatValue(query, true);
    this.prettifiedQuery = query !== prettifiedQuery ? prettifiedQuery : null;
    return prettifiedQuery;
  };

  constructor({defaultQuery, prettificationDelay, codeEditorRef}) {
    this.query = defaultQuery;
    this.codeEditorRef = codeEditorRef;
    this.prettifyQuery = debounce(
      action(this.prettifyQuery),
      this.prettificationDelay,
      {leading: false, trailing: true}
    );
    this.prettificationDelay = prettificationDelay;
    makeObservable(this);
  }
}

const GraphExplorer = ({
  preferencesKey = 'GraphExplorerPreferences',
  explorerMode = explorerModes.STANDALONE,
  saveQuery,
  blueprints = [],
  defaultBlueprintId,
  defaultGraphType = 'operation',
  defaultQueryModeId = 'qe',
  defaultQuery,
  prettificationDelay = 300,
  preferences,
  updateUserPreferences,
}) => {
  const codeEditorRef = useRef();
  const [store] = useState(() => new Store({defaultQuery: defaultQuery ?? '', prettificationDelay, codeEditorRef}));
  const [graphQueryResultStore] = useState(() => new GraphQueryResultStore());
  const [schemaGraphStore] = useState(() => new GraphStore());
  const [fullGraphStore] = useState(() => new GraphStore());
  const nodeCoordinatesById = useRef({});
  const defaultFetchContextualData = useMemo(
    () => !!find(preferences, {key: preferencesKey})?.value?.fetchContextualData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [markers, setMarkers] = useStateCallback([]);
  const [editableQueryPropsStore] = useState(() => new UserDefinedEditableQueryStore());

  const saveNodeCoordinates = useCallback((nodes) => {
    forEach(nodes, ({x, y, fx, fy, id}) => {nodeCoordinatesById.current[id] = {x, y, fx, fy};});
  }, [nodeCoordinatesById]);

  const {query, prettifiedQuery, currentQueryParams, tabIndex, activeViewMode, hiddenNodeTypes, refreshParam,
    setActiveViewMode, updateTabIndex, updateQuery, refreshData, applyPrettifiedQuery, onExecuteQuery,
    toggleNodeTypeVisibility, hideNodeTypes, showAllNodeTypes, clearCurrentQueryParams} = store;
  const {submitAvailable, queryProps, setSubmitAvailableOff, setSubmitAvailableOn, onSaveEditableQuery,
    isNewEntity, onUpdateEditableQuery, isCatalogModalOpen, openCatalogModal, closeCatalogModal,
    resetStore, lastCatalogUpdateDate, isDeleteModalOpen, openDeleteModal, closeDeleteModal,
    updateCatalogDate} = editableQueryPropsStore;
  const onCancelEditMode = (query = '') => {
    resetStore();
    updateQuery(query);
    setSubmitAvailableOff();
  };
  return !blueprints?.length ?
    <Message
      info
      icon='info circle'
      content='Graph Explorer requires a blueprint to operate'
    />
    : (
      <DataFilteringContainerWithRouter
        defaultFilters={{
          blueprintId: defaultBlueprintId ?? blueprints[0]?.id ?? '',
          graphType: defaultGraphType,
          queryModeId: defaultQueryModeId,
          isBuilderMode: false,
          fetchContextualData: defaultFetchContextualData,
        }}
        stateQueryParam='params'
      >
        {({filters, updateFilters}) => {
          const blueprintDesign = find(blueprints, {id: filters.blueprintId})?.design;
          const queryMode = find(queryModes, {id: filters.queryModeId});
          const toggleBuilderMode = (isBuilderMode) => {
            updateFilters({...filters, isBuilderMode});
            clearCurrentQueryParams();
          };
          return (
            <div className='graph-explorer'>
              <ExplorerHeader
                explorerMode={explorerMode}
                blueprints={blueprints}
                parameters={filters}
                query={query}
                saveQuery={saveQuery}
                onParametersChange={(parameters) => {
                  updateFilters(parameters);
                  setSubmitAvailableOff();
                  clearCurrentQueryParams();
                }}
                onCancelEditMode={onCancelEditMode}
                queryProps={queryProps}
                isNewEntity={isNewEntity}
              />
              <FetchData
                fetchData={fetchReferenceDesign}
                fetchParams={{referenceDesignName: blueprintDesign, refreshParam}}
                pollingInterval={null}
                customLoader
              >
                {({fetchDataError, loaderVisible, referenceDesignSchema}) =>
                  <GraphExplorerContext.Provider
                    value={{
                      graphQueryResultStore,
                      schemaGraphStore,
                      fullGraphStore,
                      referenceDesignSchema,
                      hiddenNodeTypes,
                      toggleNodeTypeVisibility,
                      hideNodeTypes,
                      showAllNodeTypes,
                      nodeCoordinatesById: nodeCoordinatesById.current,
                      saveNodeCoordinates,
                      fetchContextualData: filters.fetchContextualData,
                      preferences,
                      updateUserPreferences
                    }}
                  >
                    <QueryInput
                      codeEditorRef={codeEditorRef}
                      blueprintDesign={blueprintDesign}
                      blueprintId={filters.blueprintId}
                      queryMode={queryMode}
                      isBuilderMode={filters.isBuilderMode}
                      executeOnMount={explorerMode !== explorerModes.STANDALONE && query.length}
                      onExecuteQuery={() => onExecuteQuery({filters, route: queryMode.route})}
                      onQueryChange={(value) => {
                        setSubmitAvailableOff();
                        updateQuery(value);
                      }}
                      onCancelEditMode={onCancelEditMode}
                      explorerMode={explorerMode}
                      query={query}
                      prettifiedQuery={prettifiedQuery}
                      applyPrettifiedQuery={applyPrettifiedQuery}
                      activeViewMode={activeViewMode}
                      onReferenceDesignSchemaClick={() => setActiveViewMode('schema')}
                      onFullBlueprintClick={() => setActiveViewMode('fullGraph')}
                      fetchContextualData={filters.fetchContextualData}
                      toggleBuilderMode={toggleBuilderMode}
                      onFetchFullQueryData={
                        () => {
                          const updated = {fetchContextualData: !filters.fetchContextualData};
                          updateFilters({...filters, ...updated});
                          updateUserPreferences(preferencesKey, updated);
                        }
                      }
                      refreshData={refreshData}
                      markers={markers}
                      setMarkers={setMarkers}
                      submitAvailable={submitAvailable}
                      queryProps={queryProps}
                      onSaveEditableQuery={onSaveEditableQuery}
                      onUpdateEditableQuery={onUpdateEditableQuery}
                      isCatalogModalOpen={isCatalogModalOpen}
                      openCatalogModal={openCatalogModal}
                      closeCatalogModal={closeCatalogModal}
                      isNewEntity={isNewEntity}
                      graphType={filters.graphType}
                      lastCatalogUpdateDate={lastCatalogUpdateDate}
                      isDeleteModalOpen={isDeleteModalOpen}
                      openDeleteModal={openDeleteModal}
                      closeDeleteModal={closeDeleteModal}
                      updateCatalogDate={updateCatalogDate}
                    />
                    {
                      activeViewMode === null ?
                        null
                      : loaderVisible ?
                        <Loader />
                      : fetchDataError ?
                        <FetchDataError error={fetchDataError} />
                      : activeViewMode === 'fullGraph' ?
                        <FetchData
                          fetchData={fetchFullBlueprint}
                          fetchParams={{blueprintId: filters.blueprintId, refreshParam, graphType: filters.graphType}}
                          pollingInterval={null}
                        >
                          <GraphFullBlueprint />
                        </FetchData>
                      : activeViewMode === 'schema' ?
                        <GraphReferenceDesignSchema />
                      : activeViewMode === 'query' && currentQueryParams ?
                        <FetchData
                          fetchData={fetchFullBlueprintAndQueryResultAndTrace}
                          fetchParams={{
                            currentQueryParams,
                            blueprintId: filters.blueprintId,
                            traceable: queryMode.traceable,
                            refreshParam,
                            graphType: filters.graphType,
                            setSubmitAvailableOn,
                          }}
                          pollingInterval={null}
                        >
                          {({queryResult, fullBlueprint, traceResult}) =>
                            <Tab
                              activeIndex={tabIndex}
                              onTabChange={(e, {activeIndex}) => updateTabIndex(activeIndex)}
                              panes={[
                                {
                                  menuItem: {key: 'code', icon: 'code', content: 'Code'},
                                  render: () =>
                                    <Tab.Pane>
                                      <FormattedJSON
                                        json={queryResult}
                                        replacer={orderedKeysReplacer(jsonPriorityKeys)}
                                      />
                                    </Tab.Pane>
                                },
                                queryMode.graphRepresentable ? {
                                  menuItem: {key: 'graph', icon: 'share alternate', content: 'Graph'},
                                  render: () =>
                                    <Tab.Pane>
                                      <GraphQueryResult
                                        queryResult={queryResult}
                                        traceResult={traceResult}
                                        fullBlueprint={fullBlueprint}
                                      />
                                    </Tab.Pane>
                                } : null,
                              ]}
                            />
                          }
                        </FetchData>
                      :
                        null
                    }
                  </GraphExplorerContext.Provider>
                }
              </FetchData>
            </div>
          );
        }}
      </DataFilteringContainerWithRouter>
    );
};

const explorerModes = {
  STANDALONE: 'standalone',
  EDIT_QUERY: 'edit-query',
  VIEW_QUERY: 'view-query',
};

GraphExplorer.propTypes = {
  blueprints: PropTypes.array,
  defaultQuery: PropTypes.string,
  defaultGraphType: PropTypes.oneOf(graphTypes),
  defaultQueryModeId: PropTypes.oneOf(map(queryModes, 'id')),
  explorerMode: PropTypes.oneOf(values(explorerModes)),
  prettificationDelay: PropTypes.number,
};

export default interWindowControlled(window.graphExplorerControllerWindow)(
  (wrapWithComponent(Router)(
    observer(GraphExplorer))));

const ExplorerHeader = ({
  explorerMode,
  parameters,
  blueprints,
  query, saveQuery,
  onParametersChange,
  onCancelEditMode,
  isNewEntity,
  queryProps,
}) => (
  <Grid>
    {!isNewEntity &&
      <Grid.Column width={16}>
        <Message>
          <Message.Header>
            {'Edit Mode'}
            <Button
              secondary
              content='Cancel'
              onClick={() => onCancelEditMode()}
              floated='right'
            />
          </Message.Header>
          <Message.Content
            content='You have opened a saved query for editing. Saving is available after a successful query execution.'
          />
        </Message>
      </Grid.Column>
    }
    <Grid.Column verticalAlign='middle' width={4}>
      <Header as='h3'>
        {isNewEntity ?
          'Apstra Graph Explorer' :
          `Edit: ${queryProps.label}`
        }
      </Header>
    </Grid.Column>
    {explorerMode === explorerModes.STANDALONE &&
      <Fragment>
        <Grid.Column textAlign='right' verticalAlign='middle' width={1}>
          <Label className='options-label'>{'Mode:'}</Label>
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={2}>
          <DropdownControl
            aria-label='Mode'
            fluid
            disabled={!isNewEntity}
            value={parameters.queryModeId}
            onChange={(value) => onParametersChange({...parameters, queryModeId: value, isBuilderMode: false})}
            options={queryModes.map(({id, label}) => ({key: id, value: id, text: label}))}
          />
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={1}>
          <Label className='options-label'>{'Blueprint:'}</Label>
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={5}>
          <DropdownControl
            aria-label='Blueprint'
            fluid
            value={parameters.blueprintId}
            onChange={(value) => onParametersChange({...parameters, blueprintId: value})}
            options={blueprints.map(({id, label}) => ({key: id, value: id, text: label}))}
          />
        </Grid.Column>
      </Fragment>
    }
    <Grid.Column
      textAlign='right'
      verticalAlign='middle'
      width={{[explorerModes.EDIT_QUERY]: 8, [explorerModes.VIEW_QUERY]: 10}[explorerMode] ?? 1}
    >
      <Label className='options-label'>{'Type:'}</Label>
    </Grid.Column>
    <Grid.Column textAlign='right' verticalAlign='middle' width={2}>
      <DropdownControl
        aria-label='Type'
        fluid
        value={parameters.graphType}
        onChange={(value) => onParametersChange({...parameters, graphType: value})}
        options={graphTypes.map((graphType) => ({key: graphType, value: graphType, text: graphType}))}
      />
    </Grid.Column>
    {explorerMode === explorerModes.EDIT_QUERY &&
      <Grid.Column textAlign='right' verticalAlign='middle' width={2}>
        <Button
          primary
          icon='check'
          content='Save Changes'
          onClick={() => saveQuery(query)}
        />
      </Grid.Column>
    }
  </Grid>
);

const QueryInput = ({
  codeEditorRef,
  blueprintDesign,
  blueprintId,
  activeViewMode,
  onQueryChange,
  onCancelEditMode,
  query,
  queryMode,
  isBuilderMode,
  prettifiedQuery,
  applyPrettifiedQuery,
  onReferenceDesignSchemaClick,
  onFullBlueprintClick,
  fetchContextualData,
  onFetchFullQueryData,
  refreshData,
  executeOnMount,
  onExecuteQuery,
  toggleBuilderMode,
  markers,
  setMarkers,
  submitAvailable,
  queryProps,
  onSaveEditableQuery,
  onUpdateEditableQuery,
  isCatalogModalOpen,
  openCatalogModal,
  closeCatalogModal,
  isNewEntity,
  explorerMode,
  graphType,
  lastCatalogUpdateDate,
  isDeleteModalOpen,
  openDeleteModal,
  closeDeleteModal,
  updateCatalogDate,
}) => {
  useEffect(() => {
    if (executeOnMount) {
      onExecuteQuery();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment>
      <Grid>
        {queryMode?.id === 'qe' &&
          <Grid.Column width={16} className='qe-nav'>
            <Button.Group basic>
              <Button
                content='Query Editor'
                onClick={() => toggleBuilderMode(false)}
                active={!isBuilderMode}
              />
              <Button
                content='Query Builder'
                onClick={() => toggleBuilderMode(true)}
                active={isBuilderMode}
              />
            </Button.Group>
            {explorerMode !== explorerModes.EDIT_QUERY &&
              <QueryCatalogModal
                open={isCatalogModalOpen}
                trigger={
                  <Button
                    color='teal'
                    content='Predefined Query Catalog'
                    floated='right'
                    onClick={() => openCatalogModal()}
                  />
                }
                onExecuteQuery={onExecuteQuery}
                onCancelEditMode={onCancelEditMode}
                onSaveEditableQuery={onSaveEditableQuery}
                onQueryChange={onQueryChange}
                onDeleteQuery={openDeleteModal}
                closeModal={closeCatalogModal}
              />
            }
            {explorerMode !== explorerModes.EDIT_QUERY &&
              <QueryDeleteModal
                queryProps={queryProps}
                open={isDeleteModalOpen}
                onClose={() => {
                  onCancelEditMode();
                  closeDeleteModal();
                }}
                onSuccess={() => {
                  onCancelEditMode();
                  updateCatalogDate();
                  closeCatalogModal();
                }}
              />
            }
          </Grid.Column>
        }
        <Grid.Column className={cx({'builder-mode': isBuilderMode})} width={isBuilderMode ? 8 : 16}>
          <CodeEditorControl
            ref={codeEditorRef}
            mode={queryMode?.editorMode}
            placeholder={queryMode?.editorPlaceholder}
            completerParams={{blueprintDesign}}
            value={query}
            onChange={onQueryChange}
            commands={[{
              name: 'submit',
              bindKey: {win: 'Ctrl-Enter', mac: 'Ctrl-Enter|Command-Enter'},
              exec: onExecuteQuery
            }]}
            disabled={queryMode?.id === 'qe' && isBuilderMode}
            markers={markers}
            validate
            multiLine
            showGutter
            enableCompletion
            aria-label='Graph Explorer query'
          />
        </Grid.Column>
        {queryMode?.id === 'qe' && isBuilderMode &&
          <Grid.Column width={8}>
            <QueryBuilder
              blueprintDesign={blueprintDesign}
              blueprintId={blueprintId}
              onQueryChange={onQueryChange}
              setMarkers={setMarkers}
              markers={markers}
              query={query}
              graphType={graphType}
              lastCatalogUpdateDate={lastCatalogUpdateDate}
            />
          </Grid.Column>
        }
        <Grid.Column width={16}>
          <Button
            icon='fork'
            active={activeViewMode === 'schema'}
            content='Show reference design schema'
            floated='left'
            onClick={onReferenceDesignSchemaClick}
          />
          <Button
            icon='certificate'
            active={activeViewMode === 'fullGraph'}
            content='Show full blueprint'
            floated='left'
            onClick={onFullBlueprintClick}
          />
          <Button
            primary
            icon='play circle'
            content='Execute'
            floated='right'
            disabled={!query.length}
            onClick={onExecuteQuery}
          />
          <Button
            primary
            icon='sync alternate'
            content='Refresh'
            floated='right'
            onClick={refreshData}
          />
          {queryMode?.id === 'qe' && explorerMode !== explorerModes.EDIT_QUERY &&
            <QueryEntityModalWithPopup
              query={query}
              queryProps={queryProps}
              mode={isNewEntity ? 'create' : 'update'}
              onUpdate={onUpdateEditableQuery}
              onSuccess={(queryProps) => {
                onSaveEditableQuery(queryProps);
                updateCatalogDate();
              }}
              submitAvailable={submitAvailable}
            />
          }
          {queryMode?.prettifiable &&
            <Button
              secondary
              icon='magic'
              content='Prettify'
              floated='right'
              disabled={!prettifiedQuery}
              onClick={applyPrettifiedQuery}
            />
          }
          <Popup
            content='Fetch blueprint/trace data for a better graphical representation.
              Uncheck this if you experience performance issues.'
            trigger={
              <Checkbox
                className='fetch-contextual-data-checkbox'
                label='Fetch contextual data'
                checked={fetchContextualData}
                onChange={onFetchFullQueryData}
              />
            }
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
