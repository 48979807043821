import {request, interpolateRoute, schemaPropertyDefaultValues} from 'apstra-ui-common';
import {
  find, forEach, includes, sortBy, uniq, sum, map, transform, range,
  difference, keys, isUndefined,
} from 'lodash';

import {namedNodesFunctions, NODE} from '../pythonExpression/consts';
import {QUERY_PROPERTIES} from './consts';
import {filtersToQueryParam, sortingToQueryParam} from '../queryParamUtils';

export const castType = (type) => includes(['integer', 'float', 'number'], type) ?
  'number' :
  includes(['bool', 'boolean'], type) ?
    'boolean' :
    'string';

export const getDefaultValueForMatcher = (matcher, attribute) => {
  const defaultValue = schemaPropertyDefaultValues[attribute?.type] ?? '';
  return matcher === 'has_items' ?
    {'': ''} :
    matcher === '_or' || matcher === '_and' ?
      [] :
      defaultValue;
};

export const getUniqNames = (query) => {
  const result = [];
  if (!query) return result;
  if (namedNodesFunctions.has(query.name)) {
    const name = find(query.kwargs, ({name}) => name === 'name');
    if (name) result.push(name.value);
  }
  const path = includes(['match', 'optional'], query.name) ?
    query.value :
    query.path;
  forEach(path, (node) => {
    result.push(...getUniqNames(node.name === 'having' ? node.query : node));
  });
  return sortBy(uniq(result));
};

export const getPathForNode = (path, startNode, currentIndex) => {
  const result = [{name: NODE, type: startNode}];
  forEach(path, ({name, type}, index) => {
    if (index === currentIndex) return false;
    result.push({name, type});
  });
  return result;
};

const getSumLines = (arr) => sum(map(arr, (v) => calculateRange(v)));

export const getPositions = (queries, startRow, isOptional) => {
  const nodeRange = getSumLines(queries);
  let sumOfLines = isOptional && nodeRange <= 1 ? startRow : startRow + 1;
  return transform(range(queries?.length), (result, index) => {
    result.push(sumOfLines);
    sumOfLines += calculateRange(queries[index]);
  }, []);
};

export const calculateRange = (query) => {
  if (!query?.name) return 0;
  if (query.name === 'match') {
    const nodeRange = getSumLines(query.value) + getSumLines(query.path);
    if (nodeRange === 0) return 3;
    return 1 + nodeRange + 1;
  }
  if (query.name === 'optional') {
    const nodeRange = getSumLines(query.value);
    if (nodeRange <= 1) return 1;
    return 1 + nodeRange + 1;
  }
  if (query.name === 'having') {
    const nodeRange = calculateRange(query.query);
    if (nodeRange <= 1) return 1;
    const diff = difference(keys(query), Array.from(QUERY_PROPERTIES));
    return 1 + nodeRange + diff.length + 1;
  }
  return 1 + getSumLines(query.path);
};

const GRAPH_QUERY_ROUTE = '/api/design/graph-queries';

export const fetchPredefinedQuery = ({
  signal, activePage, pageSize, filters = {}, sorting = {predefined: 'asc'},
  schema = [],
}) => {
  const queryParams = !isUndefined(activePage) && !isUndefined(pageSize) ?
     {page: activePage, per_page: pageSize} : {};
  const castRegexFilter = (filters) => {
    if (filters?.label) return {...filters, label: `*${filters.label}*`};
    return filters;
  };
  const filtersAsString = filtersToQueryParam(castRegexFilter(filters), schema);
  if (filtersAsString.length) queryParams.filter = filtersAsString;
  queryParams.orderby = sortingToQueryParam(sorting);
  return request(GRAPH_QUERY_ROUTE, {signal, queryParams});
};

export const createOrUpdatePredefinedQuery = async ({id: queryId, ...query} = {}) => {
  const route = queryId ? GRAPH_QUERY_ROUTE + '/<query_id>' : GRAPH_QUERY_ROUTE;
  const method = queryId ? 'PUT' : 'POST';
  const {id: createQueryId} = await request(interpolateRoute(route, {queryId}), {
    method,
    body: JSON.stringify(query),
  });
  return {...query, id: queryId || createQueryId};
};

export const deletePredefinedQuery = (queryId) => {
  const route = GRAPH_QUERY_ROUTE + '/<queryId>';
  return request(interpolateRoute(route, {queryId}), {method: 'DELETE'});
};
