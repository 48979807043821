import {map} from 'lodash';
import {observer} from 'mobx-react';
import {keyPressClick} from 'apstra-ui-common';
import {Popup} from 'semantic-ui-react';

import './DeviceProfiles.less';

const DeviceProfiles = ({deviceProfiles, dpOnClick}) => {
  return map(
    deviceProfiles,
    ({label, deviceProfile}) => (
      <DeviceProfile key={deviceProfile?.id} deviceProfile={deviceProfile} imLabel={label} dpOnClick={dpOnClick} />
    )
  );
};

export default observer(DeviceProfiles);

const DeviceProfile = ({deviceProfile, dpOnClick}) => {
  const {label} = deviceProfile;
  const interactiveProps = dpOnClick ?
  {
    tabIndex: 0,
    role: 'button',
    onClick: () => dpOnClick(deviceProfile),
    onKeyDown: keyPressClick(dpOnClick, deviceProfile)
  } : {};

  return (
    <div className='device-profile'>
      <Popup
        position='top center'
        trigger={<label {...interactiveProps}>{label}</label>}
      >
        {label}
      </Popup>
    </div>
  );
};
