import {useMemo} from 'react';
import {map} from 'lodash';

import {Layout} from '../types';

export const usePreviewNodes = (nodeLayouts: Record<string, Layout>, collapsedLayouts: Record<string, Layout>) => {
  return useMemo(() => map({...nodeLayouts, ...collapsedLayouts},
    (layout: any, id) => ({
      id,
      position: {
        x: layout.left,
        y: layout.top,
      },
      width: layout.width,
      height: layout.height
    })
  ), [nodeLayouts, collapsedLayouts]);
};
