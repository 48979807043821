import {observer} from 'mobx-react';
import {useMemo} from 'react';
import {groupBy, size, first, map} from 'lodash';
import cx from 'classnames';

import {useLinkTooltip, useTooltip} from '../../components/graphs/GraphTooltips';
import {useTooltipData} from './TooltipDataProvider';
// import Port from './Port';
import {getLinkPath} from '../utils';

import './AggregateLink.less';

const AggregateLink = ({link, memberLinks, readonly, onClick}) => {
  const {sharedTooltip} = useTooltip();
  const {indexedLinks} = useTooltipData();
  const linkTooltipHandler = useLinkTooltip();

  // There could be multiple links connecting the same pair of nodes in the aggregate
  const pairsGroups = useMemo(
    () => (groupBy(memberLinks, 'symmetricId')),
    [memberLinks]
  );

  // Mouse events handlers
  const mouseEventsHandlers = useMemo(
    () => (
      readonly ?
        {
          onMouseOver: linkTooltipHandler(indexedLinks[link.id]),
          onMouseOut: sharedTooltip.hide
        } :
        (onClick ? {onClick} : {})
    ),
    [readonly, linkTooltipHandler, indexedLinks, link.id, sharedTooltip.hide, onClick]
  );

  const classNames = useMemo(
    () => cx(
      'ctrl-aggregate-link',
      {
        disabled: readonly,
        interactive: !!onClick && !readonly,
        error: !link.isValid
      }
    ),
    [readonly, onClick, link.isValid]
  );

  const mainPath = useMemo(() => {
    return map(pairsGroups, (groupLinks, index) => {
      const groupSize = size(groupLinks);

      const {endpoint1, endpoint2} = first(groupLinks);
      const firstGroup = link.endpointsByNodeId[endpoint1.nodeId]?.endpointGroup ?? null;
      const secondGroup = link.endpointsByNodeId[endpoint2.nodeId]?.endpointGroup ?? null;
      if (firstGroup === null) return;

      const [startPoint, endPoint] = [
        link.endpointsByNodeId[endpoint1.nodeId],
        link.endpointsByNodeId[endpoint2.nodeId]
      ];
      const [fromNode, toNode] = [startPoint.node, endPoint.node];
      if (!fromNode || !toNode) return;

      const className = `bond${groupSize > 5 ? 5 : groupSize}`;
      const [start, end] = [startPoint.portPosition, endPoint.portPosition];

      const through = groupSize > 0 ? [
        link.groupThroughPoints[firstGroup.index],
        link.groupThroughPoints[secondGroup.index]
      ] : undefined;

      const path = getLinkPath(start, end, through);
      return <path key={index} d={path} className={className} />;
    });
  }, [link.groupThroughPoints, link.endpointsByNodeId, pairsGroups]);

  return (
    <g
      className={classNames}
      {...mouseEventsHandlers}
    >
      {mainPath}
    </g>
  );
};

export default observer(AggregateLink);
