import {useContext} from 'react';

import Graph from './Graph';
import GraphExplorerContext from './GraphExplorerContext';
import GraphQueryTooltip from './GraphQueryTooltip';

const GraphFullBlueprint = ({
  fullBlueprint
}) => {
  const {fullGraphStore} = useContext(GraphExplorerContext);

  return (
    <Graph
      nodes={fullBlueprint.nodes}
      links={fullBlueprint.links}
      TooltipComponent={GraphQueryTooltip}
      store={fullGraphStore}
    />
  );
};

export default GraphFullBlueprint;
