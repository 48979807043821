import cx from 'classnames';

import {connectionPointRadius} from '../settings';
import './Link.less';

function calcPath(from, to) {
  const {x, y} = from;
  const dx = to.x - x;
  const dy = to.y - y;

  const l = Math.sqrt(dx * dx + dy * dy);

  const rx = dx * (l ? connectionPointRadius / l : 1);
  const ry = dy * (l ? connectionPointRadius / l : 1);

  const tx = dx / 8;
  const ty = dy / 3;

  let [sx, sy] = [to.x - 2 * rx, to.y - 2 * ry];

  if (dy < 0) {
    [sx, sy] = [to.x - 2 * rx, to.y + 2 * ry];
    return `M${x + rx},${y - ry}C${x + 6 * tx},${y - 4 * ty},${sx - 6 * tx},${sy + 4 * ty},${sx},${sy}`;
  }

  return `M${x + rx},${y + ry}C${x + tx},${y + ty},${sx - tx},${sy - ty},${sx},${sy}`;
}

const Link = ({id, from, to, activeEpt, disabled}) => {
  const handleRemoving = () => {
    if (!disabled && activeEpt) activeEpt.removeLink(id);
  };

  const classes = cx(
    'ct-link',
    {disabled}
  );

  return <path
    className={classes}
    d={calcPath(from, to)}
    onClick={handleRemoving}
  />;
};

export default Link;
