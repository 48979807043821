import {useContext} from 'react';
import {observer} from 'mobx-react';
import {ActionsMenu, hasBlueprintPermissions} from 'apstra-ui-common';

import generateDashboardURI from '../generateDashboardURI';
import IBAContext from '../IBAContext';

import './DashboardActions.less';

function DashboardActions({
  dashboard, size,
  setDashboardDeletionModalProps, setDashboardExportModalProps
}) {
  const {blueprintId, blueprintPermissions} = useContext(IBAContext);
  const hasPermissions = hasBlueprintPermissions({blueprintPermissions, action: 'edit'});
  return (
    <div className='dashboard-actions'>
      <ActionsMenu
        size={size}
        items={[{
          icon: 'share square',
          title: 'Export',
          onClick: () => setDashboardExportModalProps({open: true, dashboard}),
        }]}
      />
      <ActionsMenu
        size={size}
        items={[
          {
            icon: 'edit',
            title: 'Edit',
            hasPermissions,
            notPermittedMessage: 'You do not have permission to edit',
            href: generateDashboardURI({blueprintId, dashboardId: dashboard.id, action: 'update'}),
          },
          {
            icon: 'clone',
            title: 'Clone',
            hasPermissions,
            notPermittedMessage: 'You do not have permission to clone',
            href: generateDashboardURI({blueprintId, dashboardId: dashboard.id, action: 'clone'}),
          },
          {
            icon: 'trash',
            title: 'Delete',
            hasPermissions,
            notPermittedMessage: 'You do not have permission to delete',
            onClick: () => setDashboardDeletionModalProps({open: true, dashboards: [dashboard]}),
          },
        ]}
      />
    </div>
  );
}

export default observer(DashboardActions);
