import {find, groupBy, isEmpty, map, some, sortBy, transform, values} from 'lodash';

import {getPossibleStageValues} from '../../../stageUtils';
import {linkPadding, nodeWidth, processorStepHeight, processorXPadding, processorYPadding,
  stageStepHeight} from './consts';

export function getProcessorSize(processor) {
  const stageCount = values(processor.outputs).length;
  return {
    width: nodeWidth,
    height: processorStepHeight + stageCount * stageStepHeight,
  };
}

export function checkConnectorsCompatibility(c1, c2, {
  processorDefinitions
}) {
  if (c1.type === c2.type || c1.processor.name === c2.processor.name) return false;
  const [input, output] = c1.type === 'output' ? [c2, c1] : [c1, c2];
  const destinationProcessorDefinition = find(processorDefinitions, {name: input.processor.type});
  const sourceProcessorDefinition = find(processorDefinitions, {name: output.processor.type});
  const inputValues = getPossibleStageValues(destinationProcessorDefinition, 'inputs')[input.id];
  if (isEmpty(inputValues)) return true;
  const outputValues = getPossibleStageValues(sourceProcessorDefinition, 'outputs')[output.id];
  return some(map(inputValues), (input) => {
    const condition = input.name === '*' ?
      (inp, out) => inp.type === out.type :
      (inp, out) => inp.name === out.name && inp.type === out.type;
    return find(outputValues, (output) => condition(input, output)) ? input : null;
  });
}

function getFreeOffset(offsetById) {
  const offsets = new Set(values(offsetById));
  for (let i = 0; i < offsets.size; i++) {
    if (!offsets.has(i)) return i;
  }
  return offsets.size;
}

function defineOffsets(ranges, space, grow) {
  const rangesByPos = groupBy(ranges, 'pos');
  return transform(rangesByPos, (lineOffsets, ranges) => {
    const points = transform(ranges, (acc, range) => {
      acc.push({type: 'start', id: range.id, pos: Math.min(range.from, range.to)});
      acc.push({type: 'end', id: range.id, pos: Math.max(range.from, range.to)});
    }, []);
    const orderedPoints = sortBy(points, ['pos']);
    let maxId = 0;
    const {offsets} = transform(orderedPoints, ({currentLinks, offsets}, point) => {
      if (point.type === 'start') {
        const offsetId = getFreeOffset(currentLinks);
        currentLinks[point.id] = offsetId;
        offsets[point.id] = offsetId;
        maxId = Math.max(maxId, offsetId);
      } else {
        delete currentLinks[point.id];
      }
    }, {currentLinks: {}, offsets: {}});
    transform(offsets, (lineOffsets, offset, id) => {
      lineOffsets[id] = grow ?
        linkPadding + (space - 2 * linkPadding) * offset / (maxId || 1)
        : space - linkPadding - (space - 2 * linkPadding) * offset / (maxId || 1);
    }, lineOffsets);
  }, {});
}

export function getLinkOffsets({links, inputPositions, outputPositions}) {
  const {horizontalLines, verticalLines} = transform(links, ({horizontalLines, verticalLines}, link) => {
    const outPos = outputPositions[link.from];
    const inPos = inputPositions[link.to];
    horizontalLines[link.id] = {pos: inPos.y, from: outPos.x, to: inPos.x, id: link.id};
    verticalLines[link.id] = {pos: outPos.x, from: outPos.y, to: inPos.y, id: link.id};
  }, {horizontalLines: {}, verticalLines: {}});
  const horizontalLineOffsets = defineOffsets(horizontalLines, processorYPadding, true);
  const verticalLineOffsets = defineOffsets(verticalLines, processorXPadding, false);

  return transform(links, (acc, {id}) => {
    acc[id] = {
      x: verticalLineOffsets[id],
      y: horizontalLineOffsets[id]
    };
  }, {});
}
