/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {debounce, includes, map, get, keys, isObject, isArray, isEmpty, reverse} from 'lodash';
import {convertChevrotainErrors, setSessionErrors} from 'apstra-ui-common';

import JinjaParser from './JinjaParser';
import buildPropertyDocHTMLFromSchema from '../pythonExpression/buildPropertyDocHTMLFromSchema';

const validKey = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
const keywords = [
  'if', 'endif', 'elif', 'for', 'continue', 'break', 'endfor', 'set', 'filter', 'endfilter',
  'include', 'block', 'endblock', 'do',
];
const getSnippetKey = (key) => {
  if (validKey.test(key)) return key;
  return `['${key}']`;
};

const prepareCompletions = (editor, options, meta) => map(options, ({name, schema}, index) => ({
  caption: name,
  snippet: getSnippetKey(name),
  docHTML: buildPropertyDocHTMLFromSchema(schema),
  className: 'completion-keyword-argument ace_',
  meta,
  score: 1000 - index,
  completer: {
    insertMatch(insertEditor, data) {
      const insertValue = data.snippet;
      const position = editor.selection.getCursor();
      const token = insertEditor.session.getTokenAt(position.row, position.column);
      const offset = (token?.type === 'variable.other.jinja.attribute') ? token.value.length : 0;
      const previousToken = insertEditor.session.getTokenAt(position.row, position.column - offset);

      if (validKey.test(insertValue) || (previousToken?.value !== '.' && token?.value !== '.')) {
        insertEditor.completer.insertMatch({value: insertValue});
        return;
      }

      insertEditor.jumpToMatching();
      insertEditor.session.replace({
        start: {
          row: position.row,
          column: position.column - offset - 1,
        },
        end: {
          row: position.row,
          column: position.column
        }
      }, insertValue);
    }
  }
}));

const prepareIncludeCompletions = (configTemplateNames) => {
  return map(configTemplateNames, (configTemplateName, idx) => ({
    caption: configTemplateName,
    snippet: `'${configTemplateName}'`,
    className: 'completion-string ace_',
    meta: 'config template',
    score: 2000 - idx,
  }));
};

const getType = (value) => {
  if (isArray(value)) {
    return 'array';
  }
  return (typeof value);
};

const getKeysAndSchemaByPath = (obj, path = []) => {
  const currentObj = isEmpty(path) ? obj : get(obj, path, {});
  if (isObject(currentObj) && !isArray(currentObj)) {
    return map(keys(currentObj), (option) => {
      const schema = {};
      const value = get(obj, [...path, option]);
      schema.type = getType(value);
      if (schema.type === 'object') {
        const properties = map(value, (value, key) => ({
          name: key,
        }));
        if (!isEmpty(properties)) schema.properties = properties;
      }
      return {
        name: option,
        schema,
      };
    });
  }
  return [];
};

ace.define('ace/mode/jinja', ['require', 'exports', 'module'], (require, exports) => {
  const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
  const {Mode: TextMode} = require('ace/mode/text');
  const {TokenIterator} = require('ace/token_iterator');
  const {BaseCompleter} = require('ace/base_completer');

  class JinjaCompleter extends BaseCompleter {
    getCustomCompletions(state, session, pos) {
      const token = session.getTokenAt(pos.row, pos.column);
      const previousTokens = this.getPreviousTokens({session, pos});
      const {configContext = {}, configTemplateNames = []} = session.completerParams ?? {};
      if (!token || !previousTokens) return [];
      const {tokens: [firstToken]} = previousTokens;
      if (token.value === '.' || token.value === '].' || includes(['{{', 'if', 'elif', '.', 'in'], firstToken.value)) {
        const path = this.preparePathForContext({session, pos});
        const availableKeys = getKeysAndSchemaByPath(configContext, path);
        return prepareCompletions(state, availableKeys);
      } else if (this.isIncludeOperatorCompletion(token, previousTokens, configTemplateNames)) {
        return prepareIncludeCompletions(configTemplateNames);
      } else if (firstToken.type === 'entity.other.jinja.delimiter.tag' && includes(firstToken.value, '{')) {
        return map(keywords, (keyword, index) => ({
          caption: keyword,
          snippet: keyword,
          className: 'completion-keyword-argument ace_',
          meta: 'keyword',
          score: 100 - index
        }));
      } else if (firstToken.value === 'is') {
        return map(['not', 'None', 'defined'], (keyword, index) => ({
          caption: keyword,
          snippet: keyword,
          className: 'completion-keyword-argument ace_',
          meta: 'keyword',
          score: 100 - index
        }));
      }
      return [];
    }

    preparePathForContext({iterator, session, pos}) {
      if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
      const path = [];
      let currentToken;
      do {
        do {
          currentToken = iterator.stepBackward();
          if (!currentToken ||
            includes(['keyword.operator.jinja', 'entity.other.jinja.delimiter.tag'], currentToken.type)) {
            return reverse(path);
          }
        } while (currentToken.value === ' ');
        if (includes(
          [
            'string.quoted.single.jinja',
            'string.quoted.double.jinja',
            'variable.other.jinja',
            'variable.other.jinja.attribute'
          ],
          currentToken.type
        )) {
          path.push(currentToken.value);
        }
      } while (true); // eslint-disable-line no-constant-condition
    }

    checkForCustomCharacterCompletions(editor) {
      const pos = editor.getCursorPosition();
      const line = editor.getSession().getLine(pos.row).substr(0, pos.column);
      if (/(['"]|\s*)$/.test(line)) {
        this.showCompletionsPopup(editor);
      }
    }

    getPreviousTokens({iterator, session, pos}) {
      if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
      const tokens = [];
      let currentToken, nextToken;
      let parenNesting = false;
      do {
        do {
          currentToken = iterator.stepBackward();
          if (!currentToken) return null;
        } while (currentToken.value === ' ');
        tokens.unshift(currentToken);
        parenNesting = includes(['string', 'constant.numeric', 'constant.language'], currentToken.type);
        if (!parenNesting) {
          if (nextToken && tokens[0].type === 'keyword.operator') {
            return {iterator, tokens: [nextToken], name: nextToken.value};
          }
          return {iterator, tokens, name: currentToken.value};
        }
        nextToken = currentToken;
      } while (true); // eslint-disable-line no-constant-condition
    }

    isIncludeOperatorCompletion(currentToken, previousTokens, includeListOptions) {
      const {tokens: [firstPreviousToken]} = previousTokens;
      const prevTokensChain = this.getPreviousTokens({iterator: previousTokens.iterator});
      if (prevTokensChain) {
        const {tokens: [secondPreviousToken]} = prevTokensChain;
        return (
          currentToken.type === 'meta.scope.jinja.tag' &&
          currentToken.value === ' ' &&
          firstPreviousToken.type === 'keyword.operator.jinja' &&
          firstPreviousToken.value === 'include' &&
          secondPreviousToken.type === 'entity.other.jinja.delimiter.tag' &&
          secondPreviousToken.value === '{%' &&
          includeListOptions &&
          includeListOptions.length > 0
        );
      }
      return false;
    }
  }

  class JinjaHighlightRules extends TextHighlightRules {
    constructor() {
      super();

      this.$rules = {
        start: [
          {
            token: [
              'entity.other.jinja.delimiter.tag',
              'comment.block.jinja.raw',
              'entity.other.jinja.delimiter.tag'
            ],
            regex: /({%)\s*(raw)\s*(%})/,
            push: [
              {
                token: [
                  'entity.other.jinja.delimiter.tag',
                  'comment.block.jinja.raw',
                  'entity.other.jinja.delimiter.tag'
                ],
                regex: /({%)\s*(endraw)\s*(%})/,
                next: 'pop'
              },
              {
                defaultToken: 'comment.block.jinja.raw'
              }
            ]
          },
          {
            token: 'entity.other.jinja.delimiter.comment',
            regex: /{#-?/,
            push: [
              {
                token: 'entity.other.jinja.delimiter.comment',
                regex: /-?#}/,
                next: 'pop'
              },
              {
                defaultToken: 'comment.block.jinja'
              }
            ]
          },
          {
            token: 'entity.other.jinja.delimiter.variable',
            regex: /{{-?/,
            push: [
              {
                token: 'entity.other.jinja.delimiter.variable',
                regex: /-?}}/,
                next: 'pop'
              },
              {
                include: '#expression'
              },
              {
                defaultToken: 'meta.scope.jinja.variable'
              }
            ]
          },
          {
            token: 'entity.other.jinja.delimiter.tag',
            regex: /{%-?/,
            push: [
              {
                token: 'entity.other.jinja.delimiter.tag',
                regex: /-?%}/,
                next: 'pop'
              },
              {
                include: '#expression'
              },
              {
                defaultToken: 'meta.scope.jinja.tag'
              }
            ]
          }
        ],
        '#escaped_char': [
          {
            token: 'constant.character.escape.hex.jinja',
            regex: /\\x[0-9A-F]{2}/
          }
        ],
        '#escaped_unicode_char': [{
          token: [
            'constant.character.escape.unicode.16-bit-hex.jinja',
            'constant.character.escape.unicode.32-bit-hex.jinja',
            'constant.character.escape.unicode.name.jinja'
          ],
          regex: /(\\U[0-9A-Fa-f]{8})|(\\u[0-9A-Fa-f]{4})|(\\N\{[a-zA-Z ]+\})/
        }],
        '#expression': [
          {
            token: 'keyword.operator.jinja',
            regex: /\b(?:for|endfor|do|debug|break|continue|now|if|elif|else|endif|set|include|block|endblock|filter|endfilter|macro|endmacro|call|endcall|is|in|as|import|not|and|or|recursive|with(?:out)?\s+context)\b/ // eslint-disable-line max-len
          },
          {
            token: 'constant.numeric',
            regex: /[-+]?\d+(\.\d+)?/
          },
          {
            token: 'constant.language.jinja',
            regex: /\b(?:[T|t]rue|[F|f]alse|[N|n]one|defined)\b/
          },
          {
            token: 'variable.language.jinja',
            regex: /\b(?:loop|super|self|varargs|kwargs)\b/
          },
          {
            token: 'variable.other.jinja',
            regex: /[a-zA-Z_][a-zA-Z0-9_]*/
          },
          {
            token: 'keyword.operator.arithmetic.jinja',
            regex: /\+|-|\*\*|\*|\/\/|\/|%/
          },
          {
            token: ['punctuation.other.jinja', 'variable.other.jinja.filter'],
            regex: /(\|)([a-zA-Z_][a-zA-Z0-9_]*)/
          },
          {
            token: ['punctuation.other.jinja', 'variable.other.jinja.attribute'],
            regex: /(\.)([a-zA-Z_][a-zA-Z0-9_]*)/
          },
          {
            token: 'punctuation.other.jinja',
            regex: /\[/,
            push: [
              {
                token: 'punctuation.other.jinja',
                regex: /\]/,
                next: 'pop'
              },
              {
                include: '#expression'
              }
            ]
          },
          {
            token: 'punctuation.other.jinja',
            regex: /\(/,
            push: [
              {
                token: 'punctuation.other.jinja',
                regex: /\)/,
                next: 'pop'
              },
              {
                include: '#expression'
              }
            ]
          },
          {
            token: 'punctuation.other.jinja',
            regex: /\{/,
            push: [
              {
                token: 'punctuation.other.jinja',
                regex: /\}/,
                next: 'pop'
              },
              {
                include: '#expression'
              }
            ]
          },
          {
            token: 'punctuation.other.jinja',
            regex: /\.|:|\||,/
          },
          {
            token: 'keyword.operator.comparison.jinja',
            regex: /<=|==|=>|<|>|!=/
          },
          {
            token: 'keyword.operator.arithmetic.jinja',
            regex: /\+|-|\*\*?|\/\/?|%/
          },
          {
            token: 'keyword.operator.assignment.jinja',
            regex: /[=]/
          },
          {
            token: 'punctuation.definition.string.begin.jinja',
            regex: /"/,
            push: [
              {
                token: 'punctuation.definition.string.end.jinja',
                regex: /"/,
                next: 'pop'
              },
              {
                include: '#string'
              },
              {
                defaultToken: 'string.quoted.double.jinja'
              }
            ]
          },
          {
            token: 'punctuation.definition.string.begin.jinja',
            regex: /'/,
            push: [
              {
                token: 'punctuation.definition.string.end.jinja',
                regex: /'/,
                next: 'pop'
              },
              {
                include: '#string'
              },
              {
                defaultToken: 'string.quoted.single.jinja'
              }
            ]
          },
          {
            token: 'punctuation.definition.regexp.begin.jinja',
            regex: /@\//,
            push: [
              {
                token: 'punctuation.definition.regexp.end.jinja',
                regex: /\//,
                next: 'pop'
              },
              {
                include: '#simple_escapes'
              },
              {
                defaultToken: 'string.regexp.jinja'
              }
            ]
          }
        ],
        '#simple_escapes': [
          {
            token: [
              'constant.character.escape.newline.jinja',
              'constant.character.escape.backlash.jinja',
              'constant.character.escape.double-quote.jinja',
              'constant.character.escape.single-quote.jinja',
              'constant.character.escape.bell.jinja',
              'constant.character.escape.backspace.jinja',
              'constant.character.escape.formfeed.jinja',
              'constant.character.escape.linefeed.jinja',
              'constant.character.escape.return.jinja',
              'constant.character.escape.tab.jinja',
              'constant.character.escape.vertical-tab.jinja'
            ],
            regex: /(\\$)|(\\\\)|(\\")|(\\')|(\\a)|(\\b)|(\\f)|(\\n)|(\\r)|(\\t)|(\\v)/
          }
        ],
        '#string': [
          {
            include: '#simple_escapes'
          }, {
            include: '#escaped_char'
          }, {
            include: '#escaped_unicode_char'
          }
        ]
      };

      this.normalizeRules();
    }
  }

  class JinjaMode extends TextMode {
    $id = 'ace/mode/jinja';
    $behaviour = this.$defaultBehaviour;
    HighlightRules = JinjaHighlightRules;
    completer = new JinjaCompleter();

    validate(text) {
      try {
        const {lexErrors, parseErrors} = JinjaParser.parse(text);
        return convertChevrotainErrors(lexErrors, parseErrors);
      } catch {}
      return [];
    }

    createWorker = (session) => {
      const document = session.getDocument();
      const validator = new Worker(
        new URL('./Validator', import.meta.url) /* webpackChunkName: 'jinja-validator-worker' */
      );

      const validate = debounce((text) => {
        validator.postMessage({topic: 'value to validate', text});
      }, 500);

      validator.addEventListener('message', ({data}) => {
        if (data.topic === 'validation result') {
          setSessionErrors(session, data.errors);
        }
      });

      const onDocumentChange = (_, document) => {
        validate(document.getValue());
      };

      document.on('change', onDocumentChange);

      const validatorTerminate = validator.terminate;
      validator.terminate = () => {
        document.off('change', onDocumentChange);
        setSessionErrors(session, []);
        validatorTerminate.call(validator);
      };

      return validator;
    };
  }

  exports.Mode = JinjaMode;
  exports.HighlightRules = JinjaHighlightRules;
});

ace.require(['ace/mode/jinja']);
