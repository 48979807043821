import {observable, makeObservable, action, computed, toJS} from 'mobx';
import {compact, filter, find, isEmpty, size, some, values} from 'lodash';
import ipaddr from 'ipaddr.js';

import {ctrlDirections, ctrlHalfNodeHeight, ctrlHalfNodeWidth, ctrlIfcPositions,
  ctrlNodeHeight, ctrlNodeWidth, ctrlPortSpacing, ctrlSystemTypes} from '../const';

class Endpoint {
  @observable externalId; // Emulates ifc_name for external system to preserve links rendering order
  @observable nodeId;
  @observable portId;
  @observable transformationId;
  @observable portChannelId;
  @observable interfaceName;
  @observable speed;
  @observable ipv4Addr;
  @observable ipv6Addr;
  @observable tags = [];
  @observable lagMode = 'lacp_active';

  @observable endpointGroup = null;

  @observable.ref cablingMap = null;

  @action
  init({node: {id, isExternal}, aggregatePortId, externalId, ...props}) {
    this.nodeId = id;

    if (aggregatePortId) {
      this.portId = aggregatePortId;
    }

    if (isExternal) {
      this.externalId = externalId;
    }

    this.fillWith(props);
  }

  constructor(props, cablingMap) {
    makeObservable(this);

    if (!cablingMap) throw new Error('Endpoint init w/o the store');

    this.cablingMap = cablingMap;
    this.init(props);
  }

  @computed
  get node() {
    return this.cablingMap?.nodes[this.nodeId];
  }

  @computed
  get deviceProfile() {
    return this.node?.deviceProfile;
  }

  @computed
  get isExternal() {
    return this.node?.isExternal;
  }

  @computed
  get isAggregate() {
    return !!this.endpointGroup;
  }

  @computed
  get portPosition() {
    // No node to calculate port position
    if (!this.node) return {};

    const {interfacesPositions, position: {x, y}, centerPosition} = this.node;

    const myPosition = find(interfacesPositions, {id: this.id});
    // If no position found - return node's center by default
    if (!myPosition) {
      return centerPosition;
    }

    // Identify which side of the node this interface is on
    const {side} = myPosition;
    // and filter the other sides out
    const sidePorts = filter(values(interfacesPositions), {side});
    // Detect the index of this interface on the node's side
    const index = sidePorts.indexOf(myPosition);
    const sidePortsCount = size(sidePorts);

    const onShortSide = side === ctrlIfcPositions.RIGHT || side === ctrlIfcPositions.LEFT;

    // If there are too many links on the side - adjust the interval so that
    // no interfaces get placed outbound the node's side
    const sideSize = onShortSide ? ctrlNodeHeight : ctrlNodeWidth;
    const spacer = sidePortsCount * ctrlPortSpacing > sideSize ?
      (sideSize - 8) / sidePortsCount :
      ctrlPortSpacing;

    const portsHalfWidth = ((sidePortsCount - 1) * spacer) / 2;

    // Calculate position differently for the long and short sides
    return onShortSide ?
      {
        x: x + (side === ctrlIfcPositions.LEFT ? 0 : ctrlNodeWidth),
        y: y + ctrlHalfNodeHeight - portsHalfWidth + index * spacer,
        direction: ctrlDirections.HORIZONTAL,
        placement: side
      } :
      {
        x: x + ctrlHalfNodeWidth - portsHalfWidth + index * spacer,
        y: y + (side === ctrlIfcPositions.TOP ? 0 : ctrlNodeHeight),
        direction: ctrlDirections.VERTICAL,
        placement: side
      };
  }

  @computed get external() {
    return !!this.externalId;
  }

  @action setEndpointGroup(endpointGroup) {
    this.endpointGroup = endpointGroup;
  }

  cloneFor(nodeIds) {
    return new Endpoint(
      {
        node: this.cablingMap.nodes[nodeIds[this.nodeId]],
        portId: this.portId,
        interfaceId: this.interfaceId,
        interfaceName: this.interfaceName,
        transformationId: this.transformationId,
        externalId: this.externalId,
        endpointGroup: this.endpointGroup,
        portChannelId: this.portChannelId,
        tags: toJS(this.tags),
        lagMode: this.lagMode
      },
      this.cablingMap
    );
  }

  @action
  setProperty(property, value) {
    this[property] = value;
  }

  @action
  fillWith(data = {}) {
    const {ifc = null, ...props} = data;
    const interfaceData = ifc ? {
      interfaceName: ifc.name,
      speed: ifc.speed,
      interfaceId: ifc.interfaceId ?? this.interfaceId
    } : {};

    Object.assign(this, interfaceData, props);
  }

  @action
  removeIps() {
    this.ipv4Addr = null;
    this.ipv6Addr = null;
  }

  @computed
  get data() {
    const {
      nodeId, portId, transformationId, interfaceName, speed, externalId,
      portChannelId, ipv4Addr, ipv6Addr, tags, lagMode
    } = toJS(this);

    return {
      nodeId,
      portId,
      transformationId,
      ifc: {
        name: interfaceName,
        speed
      },
      externalId,
      portChannelId,
      ipv4Addr,
      ipv6Addr,
      tags,
      lagMode
    };
  }

  @computed get id() {
    if (this.externalId && !this.interfaceName) return this.externalId;
    return [this.portId, this.transformationId, this.interfaceName, this.interfaceId].join('-');
  }

  @computed
  get ipv4Parsed() {
    if (!this.ipv4Addr) return true;
    try {
      const [range, cidr] = ipaddr.IPv4.parseCIDR(this.ipv4Addr);
      return {range: range.toString(), cidr};
    } catch (e) {
      return false;
    }
  }

  @computed
  get ipv6Parsed() {
    if (!this.ipv6Addr) return true;
    try {
      const [range, cidr] = ipaddr.IPv6.parseCIDR(this.ipv6Addr);
      return {range: range.toString(), cidr};
    } catch {
      return false;
    }
  }

  @computed
  get ipv4IsValid() {
    return !!this.ipv4Parsed;
  }

  @computed
  get ipv6IsValid() {
    return this.ipv6Parsed;
  }

  containsDuplicates(usedIps) {
    const {ipv4Parsed, ipv6Parsed} = this;
    if (ipv4Parsed && (usedIps[ipv4Parsed.range] ?? 0) > 1) return true;
    if (ipv6Parsed && (usedIps[ipv6Parsed.range] ?? 0) > 1) return true;
  }

  @computed
  get usedIps() {
    return compact([
      this.ipv4Parsed?.range,
      this.ipv6Parsed?.range
    ]);
  }

  @computed
  get ipsAreValid() {
    return this.ipv4IsValid && this.ipv6IsValid;
  }

  @computed
  get errors() {
    const result = [];
    if (!this.isExternal && !this.isAggregate) {
      // Validation of internal systems' endpoints
      if (!this.portId) {
        result.push('No port selected');
      } else if (!this.transformationId) {
        result.push('No transformation/interface selected');
      } else if (this.missingInDeviceProfile) {
        result.push('Selected configuration does not exist in the device profile');
      }
    }
    // Common rules validation
    if (!this.ipv4IsValid) {
      result.push('Invalid IPv4');
    }
    if (!this.ipv6IsValid) {
      result.push('Invalid IPv6');
    }
    return result;
  }

  @computed
  get isValid() {
    return isEmpty(this.errors);
  }

  correspondsTo(nodeId, portId) {
    return this.nodeId === nodeId && this.portId === portId;
  }

  @computed
  get missingInDeviceProfile() {
    // There is no device profile in aggregates and external systems
    if (this.isExternal || this.isAggregate) {
      return false;
    }

    // Transformation and interface must be defined in order to check
    // if they exist in the Device Profile
    if (!this.deviceProfile || !this.transformationId || !this.interfaceName) {
      return false;
    }

    return !some(
      this.deviceProfile?.ports,
      (port) => some(
        port.transformations,
        ({id, interfaces}) => id === this.transformationId && find(interfaces, {name: this.interfaceName})
      )
    );
  }

  static deserialize(jsonValue, cablingMap, linkSpeed) {
    const {
      interface: {
        if_name: interfaceName, transformation_id: transformationId, port_channel_id: portChannelId,
        ipv4_addr: ipv4Addr, ipv6_addr: ipv6Addr, id: interfaceId, tags, lag_mode: lagMode
      },
      system: {id: nodeId, system_type: systemType},
      endpoint_group: endpointGroup,
      local
    } = jsonValue;
    let externalId = null;

    // Trying to determine port/transformation from device profile
    const deviceProfileId = cablingMap.nodes[nodeId]?.deviceProfileId;
    let port = cablingMap.dpInterfacePorts[deviceProfileId]?.[`${transformationId}:${interfaceName}`];

    let speed = {};
    if (port) {
      // If data found in device profile - identify speed
      const transformation = find(port.transformations, {id: transformationId});
      if (transformation) {
        speed = transformation.speed;
      }
    } else if (local) {
      // ... or rely on locally preserved data otherwise
      [port, externalId, speed] = [{port_id: local?.portId}, local?.externalId, local?.speed || linkSpeed];
    }

    return new Endpoint({
      node: {id: nodeId},
      portId: port?.portId,
      transformationId,
      ifc: {name: interfaceName, speed, interfaceId},
      externalId: systemType === ctrlSystemTypes.INTERNAL ? null : externalId,
      portChannelId,
      ipv4Addr,
      ipv6Addr,
      tags,
      endpointGroup,
      lagMode
    }, cablingMap);
  }

  serialize(toPayload) {
    const result = {
      interface: {
        if_name: this.interfaceName,
        transformation_id: this.transformationId ?? null,
        ipv4_addr: this.ipv4Addr ?? null,
        ipv6_addr: this.ipv6Addr ?? null,
        tags: toJS(this.tags),
        lag_mode: this.lagMode,
      },
      system: {
        id: this.nodeId
      },
      endpoint_group: this.endpointGroup?.index
    };

    if (!toPayload) {
      result.system.system_type = this.external ? ctrlSystemTypes.EXTERNAL : ctrlSystemTypes.INTERNAL;
      result.local = {
        externalId: this.externalId,
        portId: this.portId,
        speed: Object.assign({}, this.speed)
      };
    }
    return toJS(result);
  }
}

export default Endpoint;
