import {forEach, map, max, sumBy} from 'lodash';

import {Layout} from '../types';
import {TopologyConfigType} from '../consts';
import {LayoutAlgorithm} from './LayoutAlgorithm';

export class RowLayout extends LayoutAlgorithm {
  recalculateOwn = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    const {gap} = config;
    const childrenCount = children.length;
    if (!childrenCount) return false;

    return this.assignValue(own, {
      width: sumBy(children, 'width') + (childrenCount - 1) * gap,
      height: max(map(children, 'height')) ?? 0
    });
  };

  recalculateChildren = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    let wereChildrenUpdated = false;
    const {gap} = config;
    const childrenCount = children.length;
    if (!childrenCount) return false;
    const childrenWidth = own.width - (childrenCount - 1) * gap;
    const fullChildrenSize = sumBy(children, 'width');
    const extension = childrenWidth / fullChildrenSize;

    let x = 0;
    forEach(children, (child, index) => {
      const isLastChild = index === childrenCount - 1;
      const newWidth = isLastChild ? own.width - x : Math.ceil(extension * child.width);
      if (this.assignValue(child, {width: newWidth})) {
        wereChildrenUpdated = true;
      }
      this.assignValue(child, {left: x, top: 0});
      x += newWidth + gap;
    });
    return wereChildrenUpdated;
  };
}
