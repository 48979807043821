import {useCallback, useMemo, useState} from 'react';
import cx from 'classnames';
import {constant, forEach, keys, without, map, min, max, head, last, compact, isUndefined, sumBy,
  values,
  first} from 'lodash';
import {Group} from '@visx/group';
import {Axis, AxisLeft} from '@visx/axis';
import {GridRows} from '@visx/grid';
import {BarGroup} from '@visx/shape';
import {useResizeDetector} from 'react-resize-detector';
import {easeCubic, scaleBand, scaleLinear, scaleOrdinal, scaleTime} from 'd3';
import {brandColorNames, formatChartAxisTime, formatNumber, SvgTextLengthMeasurer,
  useAxisWidth} from 'apstra-ui-common';
import {animated, Spring} from '@react-spring/web';

import {getFittedTickLabels} from './utils';

import ChartPopup from './ChartPopup';
import ChartLegend from './ChartLegend';

import './BarGroupChart.less';

const DATA_FORMAT = {
  none: 'none',
  timestamp: 'timestamp',
};

const BarGroupChart = ({
  mode, samples, yScaleFn, className, width: widthProp, dimensions,
  groupLabelKey, colors, padding, springConfig, processPopupHeader, processPopupContent,
  units, tickGroupFormat, xLabel, yLabel, dataXFormat,
  fontSize, showZeroBars, stackBars
}) => {
  const {width: parentWidth = widthProp, ref: targetRef} = useResizeDetector({handleHeight: false});
  const chartWidth = isUndefined(widthProp) ? parentWidth : widthProp;
  const yLabelWithUnits = compact([yLabel, units]).join(', ');

  const {ref: leftAxisRef, maxLabelWidth: leftAxisWidth} = useAxisWidth(yLabel, units);

  const xScaleFn = dataXFormat === DATA_FORMAT.none ?
    scaleBand :
    dataXFormat === DATA_FORMAT.timestamp ?
      scaleTime :
      scaleLinear;

  const [popupDescription, setPopupDescription] = useState(null);
  const [textLength, setTextLength] = useState(0);
  const labelMarginLeft = leftAxisWidth + (yLabelWithUnits ? 15 : 0);

  const hidePopup = () => setPopupDescription(null);

  const {height: chartHeight, margin} = dimensions[mode];

  const xMax = (chartWidth - margin.left - margin.right - labelMarginLeft) || 0;
  const yMax = (chartHeight - margin.top - margin.bottom - 25 - (xLabel ? 20 : 0)) || 0;

  const [minValue, maxValue, groups, valueKeys] = useMemo(() => {
    const valueKeys = without(keys(first(samples)), groupLabelKey);

    const groups = [];
    let minValue = Number.MAX_VALUE;
    let maxValue = -Number.MAX_VALUE;
    forEach(samples, (item) => {
      groups.push(item[groupLabelKey]);
      if (stackBars) {
        // If group bars are stacked, calculate group min and max values as sums
        const groupValues = values(item);
        const [groupMin, groupMax] = [
          sumBy(groupValues, (n) => n < 0 ? n : 0),
          sumBy(groupValues, (n) => n > 0 ? n : 0)
        ];
        if (groupMin < minValue) minValue = groupMin;
        if (groupMax > maxValue) maxValue = groupMax;
      } else {
        // Take individual values otherwise
        forEach(valueKeys, (key) => {
          const value = item[key];
          if (value < minValue) minValue = value;
          if (value > maxValue) maxValue = value;
        });
      }
    });
    return [minValue, maxValue, groups, valueKeys];
  }, [samples, groupLabelKey, stackBars]);

  const formattedGroups = useMemo(
    () => map(groups, (group) => tickGroupFormat(group)),
    [groups, tickGroupFormat]
  );

  const xScale = useMemo(() => {
    const xScale = xScaleFn();
    if (dataXFormat === DATA_FORMAT.timestamp) {
      xScale.domain([head(groups), last(groups)]);
    } else if (dataXFormat === DATA_FORMAT.none) {
      xScale.domain(groups).padding(padding);
    } else {
      xScale.domain(groups);
    }
    xScale.rangeRound([0, xMax]);
    return xScale;
  }, [xMax, groups, padding, xScaleFn, dataXFormat]);

  const yScale = useMemo(() => {
    const yScale = yScaleFn();
    yScale.domain([min([minValue, 0]), max([maxValue, 0])]).rangeRound([yMax, 0]);
    return yScale;
  }, [minValue, maxValue, yMax, yScaleFn]);

  let boxSize = 0;
  if (dataXFormat === DATA_FORMAT.none) {
    boxSize = xScale.bandwidth();
  } else if (dataXFormat === DATA_FORMAT.timestamp) {
    const domain = xScale.domain();
    const startTime = head(domain);
    const endTime = last(domain);
    boxSize = (xScale(endTime) - xScale(startTime)) / groups.length;
  }

  const barScale = scaleBand().domain(valueKeys).padding(padding).rangeRound([0, boxSize]);
  const colorScale = scaleOrdinal().domain(valueKeys).range(colors);

  const formatTicksMap = useMemo(() => {
    if (dataXFormat !== DATA_FORMAT.none) return [];
    return getFittedTickLabels({
      textLength, scaleFn: xScale, labels: groups, labelFormatFn: tickGroupFormat, widthMax: xMax,
    });
  }, [xScale, textLength, groups, xMax, tickGroupFormat, dataXFormat]);
  const formatXTick = (value) => {
    if (dataXFormat === DATA_FORMAT.timestamp) return formatChartAxisTime(value);
    if (dataXFormat === DATA_FORMAT.none) return formatTicksMap[value];
    return value;
  };

  const showPopup = useCallback(
    ({target}, barGroup) => {
      setPopupDescription({
        node: target.parentNode,
        header: processPopupHeader(groups[barGroup.index]),
        content: processPopupContent(barGroup.bars, units),
      });
    },
    [groups, processPopupContent, processPopupHeader, units]
  );

  const y0 = yScale(0);
  const showTicks = chartWidth > 0;
  return (
    <div
      ref={targetRef}
      className={cx('bar-group-chart', 'graph-container', {expandable: mode !== 'expanded'})}
    >
      {
        showTicks &&
          <>
            <svg className={cx('bar-group-chart-layout', className)} width={chartWidth} height={chartHeight}>
              <Group top={margin.top} left={margin.left + labelMarginLeft}>
                <BarGroup
                  data={samples || []}
                  keys={valueKeys}
                  height={yMax}
                  x0={(item) => item[groupLabelKey]}
                  x0Scale={xScale}
                  x1Scale={barScale}
                  yScale={yScale}
                  color={colorScale}
                >
                  {(barGroups) =>
                    barGroups.map((barGroup) => {
                      const stacks = {
                        positive: 0,
                        negative: 0
                      };
                      const lastBar = last(barGroup.bars);
                      const singleBarWidth = lastBar.x + lastBar.width - head(barGroup.bars).x;
                      return (
                        <Group
                          key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                          left={barGroup.x0}
                          onMouseEnter={(e) => showPopup(e, barGroup)}
                          onMouseLeave={hidePopup}
                        >
                          {barGroup.bars.map((bar) => {
                            let y, height;
                            if (bar.value > 0) {
                              // Positive bars
                              y = bar.y + (stackBars ? stacks.positive : 0);
                              height = bar.height - yMax + y0;
                              stacks.positive = y - y0;
                            } else {
                              // Negative bars
                              y = y0 + (stackBars ? stacks.negative : 0);
                              height = yMax - bar.height - y0;
                              stacks.negative = y + height - y0;
                            }

                            // Make zero-sized bars visible if asked
                            if (showZeroBars && height === 0) {
                              y -= 1;
                              height = 1;
                            }

                            return (
                              <Spring
                                key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                                from={{y: y0, height: 0}}
                                to={{y, height}}
                                springConfig={springConfig}
                              >
                                {({y, height}) => {
                                  return (
                                    <>
                                      <animated.rect
                                        className={cx('bar', bar.color)}
                                        x={stackBars ? 0 : bar.x}
                                        y={y}
                                        width={stackBars ? singleBarWidth : bar.width}
                                        height={height}
                                        rx={stackBars ? 0 : 4}
                                      />
                                      {!stackBars &&
                                        <circle
                                          cx={bar.x + bar.width / 2}
                                          cy={bar.y}
                                          r={min([bar.width / 4, 7])}
                                          className={cx('circle', bar.color)}
                                        />
                                      }
                                    </>
                                  );
                                }}
                              </Spring>
                            );
                          })}
                        </Group>
                      );
                    })
                  }
                </BarGroup>
                <Axis
                  axisClassName='timeline-axis axis-bottom'
                  orientation='bottom'
                  top={yScale(0)}
                  scale={xScale}
                  hideTicks
                  tickFormat={() => null}
                />
                <Axis
                  axisClassName='timeline-axis axis-bottom'
                  orientation='bottom'
                  top={yScale(min([minValue, 0]))}
                  scale={xScale}
                  tickLabelProps={constant({})}
                  tickFormat={formatXTick}
                  label={xLabel}
                  labelProps={{y: 40}}
                  labelClassName='axis-label'
                  hideAxisLine
                />
                <AxisLeft
                  innerRef={leftAxisRef}
                  axisClassName='timeline-axis axis-left'
                  scale={yScale}
                  labelProps={{y: 10 - labelMarginLeft}}
                  label={yLabelWithUnits}
                  labelClassName='axis-label'
                  tickFormat={(value) =>
                    formatNumber(value, {units, short: (value <= -1 || value >= 1), withIndent: true})
                  }
                />
              </Group>
              <ChartPopup popupDescription={popupDescription} />
              {showTicks && (
                <GridRows
                  className='timeline-grid'
                  top={margin.top}
                  left={margin.left}
                  width={xMax}
                  height={yMax}
                  scale={yScale}
                  stroke={null}
                />
              )}
              {dataXFormat === DATA_FORMAT.none && (
                <SvgTextLengthMeasurer
                  value={formattedGroups}
                  style={{fontSize}}
                  onMeasure={(value) => setTextLength(value)}
                />
              )}
            </svg>
            <ChartLegend ordinalColorScale={colorScale} horizontal />
          </>
      }
    </div>
  );
};

BarGroupChart.defaultProps = {
  mode: 'expanded',
  dimensions: {
    compact: {
      height: 100,
      margin: {top: 10, right: 0, bottom: 0, left: 0},
    },
    expanded: {
      height: 400,
      margin: {top: 10, right: 0, bottom: 0, left: 0},
    },
  },
  processPopupHeader: (groupName) => groupName,
  processPopupContent: (items, units) => <BarGroupPopupContent items={items} units={units} />,
  yScaleFn: scaleLinear,
  groupLabelKey: 'group',
  tickGroupFormat: (value) => `${value}`,
  colors: brandColorNames,
  padding: 0.2,
  springConfig: {
    duration: 1000,
    easing: easeCubic
  },
  dataXFormat: DATA_FORMAT.none,
  fontSize: 10
};

const BarGroupPopupContent = ({items, units}) => map(items, ({color, value, key}) => (
  <div key={key} className='bar-group-popup'>
    <div className={cx('legend', color)} />
    {key}
    {' '}
    {units ?
      `${formatNumber(value, {units, short: true})} (${value}${units})` :
      value
    }
  </div>
));

export default BarGroupChart;
