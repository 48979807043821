import {useState} from 'react';
import {LegendItem, LegendLabel, LegendOrdinal} from '@visx/legend';
import {isPlainObject, isUndefined, noop, map} from 'lodash';
import cx from 'classnames';

import './ChartLegend.less';

const ChartLegend = ({
  ordinalColorScale, legendDescriptionMap, legendGlyphSize, onMouseOver, onMouseOut, hoveredItem,
  horizontal, className, ignoreEmpty
}) => {
  const getValue = (id) => {
    const description = legendDescriptionMap[id];
    return isPlainObject(description) ? description?.value || id : description;
  };

  return (
    <LegendOrdinal
      scale={ordinalColorScale}
    >
      {(labels) => (
        <div className={cx('chart-legend', className, {horizontal})}>
          {map(labels, ({text, value}, i) => {
            const description = legendDescriptionMap[text];
            return (description || !ignoreEmpty) && (
              <div
                key={`legend-ordinal-${i}`}
                className={cx('legend-row', {
                  hovered: hoveredItem === text,
                  pointer: !isUndefined(hoveredItem)
                })}
                onMouseOver={() => onMouseOver(text)}
                onMouseOut={onMouseOut}
                onFocus={() => onMouseOver(text)}
                onBlur={onMouseOut}
              >
                <div className='legend-cell'>
                  <Item
                    label={description?.name || text}
                    legendGlyphSize={legendGlyphSize}
                    itemClassName={description?.itemClassName}
                    glyphClassName={description?.glyphClassName}
                    color={value}
                  />
                </div>
                <div className='legend-cell'>
                  {getValue(text)}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </LegendOrdinal>
  );
};

ChartLegend.defaultProps = {
  legendGlyphSize: 15,
  onMouseOver: noop,
  onMouseOut: noop,
  legendDescriptionMap: {},
};

const Item = ({legendGlyphSize, itemClassName, glyphClassName, label, color}) => (
  <LegendItem className={cx(itemClassName)}>
    <svg width={legendGlyphSize} height={legendGlyphSize}>
      <rect
        className={cx(
          'legend-glyph',
          color,
          glyphClassName,
        )}
        width={legendGlyphSize}
        height={legendGlyphSize}
      />
    </svg>
    <LegendLabel>
      {label}
    </LegendLabel>
  </LegendItem>
);

export function useHoveredLegendItem() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const onMouseOver = (id) => {
    setHoveredItem(id);
  };
  const onMouseOut = () => setHoveredItem(null);
  return {hoveredItem, onMouseOut, onMouseOver};
}

export default ChartLegend;
