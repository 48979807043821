import cx from 'classnames';
import React from 'react';

import './Card.less';

type CardProps = React.PropsWithChildren<{
  className?: string;
  withBorder?: boolean;
  title?: string;
}>;

export const Card: React.FC<CardProps> = ({
  withBorder = true,
  children,
  className,
  title
}) => {
  return (
    <div className={cx('processor-modal-card', className, {'with-border': withBorder})}>
      {title &&
        <div className='card-title'>
          {title}
        </div>
      }
      <div className='card-content'>
        {children}
      </div>
    </div>
  );
};

type CardItemProps = React.PropsWithChildren<{
  className?: string;
  withInput?: boolean;
}>;

export const CardItem: React.FC<CardItemProps> = ({
  children,
  className,
  withInput
}) => (
  <div className={cx('card-item', className, {'with-input': withInput})}>
    {children}
  </div>
);
