import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {clamp, isNumber, max, min} from 'lodash';
import {drag, select, ZoomTransform} from 'd3';
import {Button} from 'semantic-ui-react';

import useUserStore from '../../hooks/useUserStore';

import './ZoomArea.less';

export function zoomConstrain(transform, viewSize, translateConstrain) {
  const calculateZoomArea = () => ({
    minX: transform.invertX(viewSize[0][0]), minY: transform.invertY(viewSize[0][1]),
    maxX: transform.invertX(viewSize[1][0]), maxY: transform.invertY(viewSize[1][1])
  });
  let zoomArea = calculateZoomArea();
  zoomArea.width = zoomArea.maxX - zoomArea.minX;
  zoomArea.height = zoomArea.maxY - zoomArea.minY;
  const maxArea = {
    minX: translateConstrain[0][0], minY: translateConstrain[0][1],
    maxX: translateConstrain[1][0], maxY: translateConstrain[1][1],
    width: translateConstrain[1][0] - translateConstrain[0][0],
    height: translateConstrain[1][1] - translateConstrain[0][1],
  };
  transform = transform.scale(max([1, zoomArea.width / maxArea.width, zoomArea.height / maxArea.height]));
  zoomArea = calculateZoomArea();

  if (zoomArea.minX < maxArea.minX) {
    transform = transform.translate(zoomArea.minX - maxArea.minX, 0);
  } else if (zoomArea.maxX > maxArea.maxX) {
    transform = transform.translate(zoomArea.maxX - maxArea.maxX, 0);
  }
  if (zoomArea.minY < maxArea.minY) {
    transform = transform.translate(0, zoomArea.minY - maxArea.minY);
  } else if (zoomArea.maxY > maxArea.maxY) {
    transform = transform.translate(0, (zoomArea.maxY - maxArea.maxY));
  }

  return transform;
}

export const usePanAreaSize = ({
  viewWidth, viewHeight,
  graphLeft = 0, graphWidth,
  graphTop = 0, graphHeight
}) => {
  const viewAspectRatio = viewWidth / viewHeight;
  const graphAspectRatio = graphWidth / graphHeight;
  const [extendX, extendY] = viewAspectRatio > graphAspectRatio ?
    [0.5 * (graphHeight * viewAspectRatio - graphWidth), 0] :
    [0, 0.5 * (graphWidth / viewAspectRatio - graphHeight)];
  const minZoom = min([viewWidth / graphWidth, viewHeight / graphHeight]);

  return useMemo(() => ({
    panConstraints: [
      [Math.round(graphLeft - extendX), Math.round(graphTop - extendY)],
      [
        Math.round(graphLeft - extendX + max([graphWidth + 2 * extendX, viewWidth])),
        Math.round(graphTop - extendY + max([graphHeight + 2 * extendY, viewHeight]))
      ],
    ],
    minZoom
  }),
  [extendX, extendY, graphHeight, graphLeft, graphTop, graphWidth, minZoom, viewHeight, viewWidth]);
};

function toAbsolutePosition({left, right, top, bottom, width, height, ownWidth, ownHeight}) {
  return {
    left: isNumber(left) ? left : width - right - ownWidth,
    top: isNumber(top) ? top : height - bottom - ownHeight,
  };
}

function toRelativePosition({left, top, width, height, ownWidth, ownHeight}) {
  const result = {};
  if (left + 0.5 * ownWidth < 0.5 * width) {
    result.left = left;
  } else {
    result.right = width - left - ownWidth;
  }
  if (top + 0.5 * ownHeight < 0.5 * height) {
    result.top = top;
  } else {
    result.bottom = height - top - ownHeight;
  }
  return result;
}

function withConstraints({left, top, right, bottom, positionPadding, width, height, ownWidth, ownHeight}) {
  return {
    left: left && clamp(left, positionPadding[3], width - ownWidth - positionPadding[1]),
    top: top && clamp(top, positionPadding[0], height - ownHeight - positionPadding[2]),
    right: right && clamp(right, positionPadding[1], width - ownWidth - positionPadding[3]),
    bottom: bottom && clamp(bottom, positionPadding[2], height - ownHeight - positionPadding[0])
  };
}

export const ZoomArea = ({
  size = 150, eventName = 'zoom.area', zoomInterval = 200,
  userStoreKey = 'zoom area position', positionPadding = [20, 35, 20, 20],
  zoom, minX, minY, maxX, maxY, width, height, setZoomTransition,
  onTransformChange, resetZoom, zoomIn, zoomOut, children, zoomFullGraph
}) => {
  const graphRef = useRef();
  const moveAreaRef = useRef();
  const [transform, setTransform] = useState(null);
  const [zooming, setZooming] = useState(null);
  const [defaultPosition = {top: 20, right: 40}, setDefaultPosition] =
    useUserStore(userStoreKey);
  const [position, setPosition] = useState(defaultPosition);
  const graphWidth = maxX - minX;
  const graphHeight = maxY - minY;
  const graphScale = size / max([graphWidth, graphHeight]);
  const ownWidth = graphScale * graphWidth;
  const ownHeight = graphScale * graphHeight;

  const viewWidth = width / transform?.k;
  const viewHeight = height / transform?.k;

  const viewXOffset = -minX - (transform?.x) / transform?.k;
  const viewYOffset = -minY - (transform?.y) / transform?.k;

  const noRendering = !transform || (graphWidth <= width && graphHeight <= height);

  useEffect(() => {
    zoom.on(eventName, (e) => {
      setTransform(e.transform);
    });
    return () => zoom.on(eventName, null);
  }, [eventName, zoom]);

  useEffect(() => {
    if (!zooming) return;
    setZoomTransition(true);
    const fn = ({in: zoomIn, out: zoomOut})[zooming];
    fn();
    const interval = setInterval(fn, zoomInterval);
    return () => {
      clearInterval(interval);
      setZoomTransition(false);
    };
  }, [setZoomTransition, zoomIn, zoomInterval, zoomOut, zooming]);

  const stopPropagationHandler = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const areaMouseDownHandler = useCallback((e) => {
    if (e.buttons !== 1) return;
    e.preventDefault();
    e.stopPropagation();
    const rect = graphRef.current.getBoundingClientRect();
    const x = clamp(minX + (e.clientX - rect.left) / graphScale - 0.5 * viewWidth, minX, maxX - viewWidth);
    const y = clamp(minY + (e.clientY - rect.top) / graphScale - 0.5 * viewHeight, minY, maxY - viewHeight);
    onTransformChange?.(new ZoomTransform(
      transform.k,
      -x * transform.k,
      -y * transform.k
    ));
  }, [graphScale, maxX, maxY, minX, minY, onTransformChange, transform, viewHeight, viewWidth]);

  useEffect(() => {
    const offset = {};
    const startPosition = {};
    const areaDrag = drag()
      .on('start', (event) => {
        event.sourceEvent.stopPropagation();
        event.sourceEvent.preventDefault();
        offset.x = event.sourceEvent.pageX;
        offset.y = event.sourceEvent.pageY;
        setPosition((pos) => {
          Object.assign(startPosition, toAbsolutePosition({...pos, width, height, ownWidth, ownHeight}));
          return startPosition;
        });
      })
      .on('drag', (event) => {
        event.sourceEvent.stopPropagation();
        event.sourceEvent.preventDefault();
        setPosition(withConstraints({
          left: startPosition.left + event.sourceEvent.pageX - offset.x,
          top: startPosition.top + event.sourceEvent.pageY - offset.y,
          positionPadding,
          width, height, ownWidth, ownHeight
        }));
      })
      .on('end', () => {
        setPosition((pos) => {
          const newPosition = toRelativePosition({...pos, width, height, ownWidth, ownHeight});
          setDefaultPosition({left: undefined, top: undefined, right: undefined, bottom: undefined, ...newPosition});
          return newPosition;
        });
      });
    select(moveAreaRef.current).call(areaDrag);
  }, [height, noRendering, ownHeight, ownWidth, positionPadding, setDefaultPosition, width]);

  if (noRendering) return null;

  return (
    <div
      ref={graphRef}
      className='zoom-area'
      style={{
        width: ownWidth,
        height: ownHeight,
        ...withConstraints({...position, width, height, ownWidth, ownHeight, positionPadding})
      }}
    >
      <div
        onMouseDown={areaMouseDownHandler}
        onMouseMove={areaMouseDownHandler}
        role='presentation'
      >
        {children}
        <div
          className='display-area'
          style={{
            width: graphScale * viewWidth,
            height: graphScale * viewHeight,
            left: graphScale * viewXOffset,
            top: graphScale * viewYOffset,
          }}
        />
      </div>
      <div ref={moveAreaRef} className='drag-button'>
        <Button basic compact circular size='mini' icon='arrows alternate' />
      </div>
      <div
        className='zoom-panel'
        onMouseDown={stopPropagationHandler}
        onMouseMove={stopPropagationHandler}
        role='toolbar'
      >
        <Button
          basic compact circular size='mini' icon='plus' aria-label='Zoom in'
          onMouseDown={() => setZooming('in')} onMouseUp={() => setZooming(null)}
        />
        <Button
          basic compact circular size='mini' icon='minus' aria-label='Zoom out'
          onMouseDown={() => setZooming('out')} onMouseUp={() => setZooming(null)}
        />
        <Button
          basic compact circular size='mini' icon='crosshairs' aria-label='Reset zoom'
          onClick={resetZoom}
        />
        {zoomFullGraph && <Button
          basic compact circular size='mini' icon='compress' aria-label='Zoom to full graph'
          onClick={zoomFullGraph}
        />}
      </div>
    </div>
  );
};
