import {useContext, useCallback} from 'react';
import {FetchDataError, GenericErrors, NetworkError,
  interpolateRoute, request, responseBodyErrorsAsArray} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import MultiUploadModal from '../../components/MultiUploadModal';

function UploadErrorComponent({error}) {
  if (error instanceof NetworkError) {
    const {response, responseBody} = error;
    if (response && response.status === 422 && responseBody && responseBody.errors) {
      return <GenericErrors errors={responseBodyErrorsAsArray(responseBody.errors, 'probe')} />;
    }
  }
  return <FetchDataError error={error} />;
}

export default function ProbeBatchImportModal(props) {
  const {blueprintId, routes} = useContext(IBAContext);

  const importProbe = useCallback(({file, signal}) => request(
    interpolateRoute(routes.probeList, {blueprintId}),
    {method: 'POST', body: file, signal}
  ), [blueprintId, routes]);

  return (
    <MultiUploadModal
      header='Import Probes'
      description='Drag and drop probe files here or click the button below.'
      successNotificationText='Probes imported successfully'
      action='Import'
      accept={{'application/json': [], 'text/plain': []}}
      uploadFile={importProbe}
      UploadErrorComponent={UploadErrorComponent}
      {...props}
    />
  );
}
