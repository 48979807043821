import {observable, action, makeObservable} from 'mobx';

class ConnectionCandidateStore {
  @observable isSearched = false;
  @observable position;
  isInput;
  types;
  payload;
  isAnyAccepted;
  hasConnections;

  @observable candidate = undefined;

  // Start searching for the connection candidate in accordance
  // with the conditions supplied
  @action
  startSearching(conditions) {
    Object.assign(this, conditions, {isSearched: true});
  }

  // Stop searching for the connection candidate (no matter by
  // what reason)
  @action
  stopSearching() {
    Object.assign(this, {
      isSearched: false,
      candidate: undefined
    });
  }

  // Register a connection candidate that matches the
  // search criteria
  @action
  registerCandidate(candidate) {
    this.candidate = candidate;
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }
}

export default ConnectionCandidateStore;
