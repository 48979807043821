import {useState, useCallback} from 'react';
import {Progress, Icon, List, Button} from 'semantic-ui-react';
import {filter, isObject, map, isEmpty} from 'lodash';
import cx from 'classnames';
import {formatNumber} from 'apstra-ui-common';
import PropTypes from 'prop-types';

import './StepByStepGuide.less';

const DEFAULT_TITLE = 'Get Started!';
const DEFAULT_HIDE_FOREVER = 'Hide forever';

const StepByStepGuide = ({
  title = DEFAULT_TITLE, steps = [], collapsed = false,
  hideButtonText = DEFAULT_HIDE_FOREVER, style, onCollapse, onExpand, onHide, onResetSteps,
}) => {
  const [isOpen, setIsOpen] = useState(!collapsed);

  const progressPercentage = formatNumber(
    filter(steps, (step) => step.completed).length * 100 / steps.length,
    {short: true}
  );

  const toggleGuide = useCallback((isExpanded) => {
    setIsOpen(isExpanded);
    if (isExpanded) {
      onExpand();
    } else {
      onCollapse();
    }
  }, [onCollapse, onExpand]);

  return !isEmpty(steps) && (
    <div className={cx('step-by-step-guide', style, {collapsed: !isOpen})}>
      {isOpen
        ? (
          <>
            <div className='header'>
              <div className='guide-title'>
                {(isObject(title) ? title.expanded : title) || DEFAULT_TITLE}
              </div>
              <Button compact icon='angle down' className='ghost' onClick={() => toggleGuide(!isOpen)} />
            </div>
            <div className='content'>
              <div>
                <span>{`${progressPercentage}% complete`}</span>
                <Progress
                  size='tiny'
                  color='green'
                  percent={progressPercentage}
                />
              </div>
              <List
                key='options'
                items={
                  map(steps, ({label, navigateToUrl, completed, disabled}, id) => ({
                    key: id,
                    children: (
                      <>
                        <Icon name={completed ? 'check circle' : 'check circle outline'} />
                        <a href={!disabled ? navigateToUrl : undefined}>
                          {label}
                        </a>
                      </>
                    ),
                    disabled,
                  }))}
              />
            </div>
            <div className='footer'>
              {/* TODO (vkniazeva): should be a link to a Preferences page */}
              <Button
                content={
                  <>
                    <span>
                      {
                        (isObject(hideButtonText) ? hideButtonText.expanded : hideButtonText) ||
                        DEFAULT_HIDE_FOREVER
                      }
                    </span>
                    <Icon name='arrow right' />
                  </>
                }
                className='hide-forever-button'
                onClick={onHide}
              />
            </div>
          </>
        )
        : (
          <>
            <div className='left-section'>
              <div className='guide-title'>
                {(isObject(title) ? title.collapsed : title) || DEFAULT_TITLE}
              </div>
              <div>{`${progressPercentage}% complete`}</div>
            </div>
            <div>
              <Button compact icon='angle up' className='ghost' onClick={() => toggleGuide(!isOpen)} />
              <Button
                compact
                content={
                  (isObject(hideButtonText) ? hideButtonText.collapsed : hideButtonText) || DEFAULT_HIDE_FOREVER
                }
                className='ghost hide-forever-btn'
                onClick={onHide}
              />
              {/* TODO (vkniazeva): temp button to make everybody's life easier */}
              <Button compact className='ghost reset-steps-btn' size='mini' content='x' onClick={onResetSteps} />
            </div>
          </>
        )
      }
    </div>
  );
};

StepByStepGuide.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string, PropTypes.shape({
      expanded: PropTypes.string,
      collapsed: PropTypes.string,
    })
  ]),
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    navigateToUrl: PropTypes.string,
    completed: PropTypes.bool,
    disabled: PropTypes.bool,
  })),
  collapsed: PropTypes.bool,
  hideButtonText: PropTypes.oneOfType([
    PropTypes.string, PropTypes.shape({
      expanded: PropTypes.string,
      collapsed: PropTypes.string,
    })
  ]),
  style: PropTypes.string,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
  onHide: PropTypes.func,
};

export default StepByStepGuide;
