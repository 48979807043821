import {Component} from 'react';
import {observable, action, computed, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {get, find, sumBy} from 'lodash';
import {Grid, Message, Breadcrumb} from 'semantic-ui-react';
import {Checkbox, DataFilter, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, PermissionChecker,
  hasBlueprintPermissions, interpolateRoute, notifier, request} from 'apstra-ui-common';

import MultiParagraphText from '../../components/MultiParagraphText';
import IBAContext from '../IBAContext';
import DashboardPreview from './DashboardPreview';
import DashboardsDisplayMode, {DASHBOARDS_DISPLAY_MODE} from './DashboardsDisplayMode';
import generateDashboardURI from '../generateDashboardURI';
import userStore from '../../userStore';

import './AutoEnabledDashboards.less';

const tableSchema = [
  {
    name: 'name',
    label: 'Name',
    value: ({item, name}) => get(item, ['schema', 'properties', 'label', 'default'], name),
    sortable: true,
  },
  {
    name: 'description',
    label: 'Description',
    value: ['description'],
    formatter: ({value, item: predefinedDashboard}) => {
      const conditionDescription = get(predefinedDashboard, ['condition', 'description']);
      return (
        <small>
          <MultiParagraphText asMarkdown text={value} />
          {conditionDescription && <MultiParagraphText asMarkdown text={`Condition: ${conditionDescription}`} />}
        </small>
      );
    }
  },
  {
    name: 'widgets',
    label: 'Widgets',
    formatter: ({item: predefinedDashboard, value, params: {dashboardPreview}}) =>
      dashboardPreview ? <DashboardPreview grid={predefinedDashboard.grid} /> : value,
    value: ({item: {grid}}) => sumBy(grid, 'length'),
    sortable: true,
  },
  {
    name: 'enabled',
    label: 'Auto-Enable',
    value: ({item}) => !get(item, ['disabled']),
    formatter: ({item, value, params: {
      blueprintPermissions, dashboardNameToggleInProgress, toggleDashboard, predefinedDashboards, refetchData
    }}) => (
      <PermissionChecker
        hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
      >
        <Checkbox
          toggle
          checked={value}
          aria-label='Auto-Enable'
          disabled={
            dashboardNameToggleInProgress === item.name ||
            item.condition.type === 'never'
          }
          onChange={() => toggleDashboard({
            dashboardName: item.name,
            disabled: value,
            predefinedDashboards,
            refetchData,
          })}
        />
      </PermissionChecker>
    ),
  },
];

@observer
export default class AutoEnabledDashboards extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, routes, signal}) {
    const [{items: predefinedDashboards}] = await Promise.all([
      request(interpolateRoute(routes.predefinedDashboardList, {blueprintId}), {signal}),
    ]);
    return {predefinedDashboards};
  }

  static pollingInterval = 10000;
  static userStoreKey = 'autoEnabledDashboards';

  @observable dashboardNameToggleInProgress = null;
  @observable displayMode = DASHBOARDS_DISPLAY_MODE.preview;

  @computed
  get breadcrumbSections() {
    const {blueprintId} = this.context;
    return [
      {key: 'home', content: 'Dashboards', as: Link, to: generateDashboardURI({blueprintId})},
      {key: 'label', content: 'Auto-Enabled Dashboards', active: true},
    ];
  }

  @action
  toggleDashboard = async ({dashboardName, disabled, predefinedDashboards, refetchData}) => {
    const {blueprintId, routes} = this.context;
    this.dashboardNameToggleInProgress = dashboardName;
    let updateError = null;
    try {
      await request(
        interpolateRoute(routes.predefinedDashboard, {blueprintId, predefinedDashboard: dashboardName}),
        {
          method: 'PATCH',
          body: JSON.stringify({disabled})
        }
      );
    } catch (error) {
      updateError = error;
    }
    if (updateError) {
      notifier.showError(updateError);
    } else {
      notifier.notify({
        resourceLabel: get(
          find(predefinedDashboards, {name: dashboardName}),
          ['schema', 'properties', 'label', 'default'],
          dashboardName
        ),
        message: `${disabled ? 'Disabled' : 'Enabled'} successfully`
      });
    }
    try {
      await refetchData();
    } finally {
      runInAction(() => {
        this.dashboardNameToggleInProgress = null;
      });
    }
  };

  render() {
    const {blueprintId, routes, blueprintPermissions} = this.context;
    const {dashboardNameToggleInProgress, toggleDashboard, breadcrumbSections, displayMode} = this;
    const defaultPageSize = userStore.getStoreValue([AutoEnabledDashboards.userStoreKey, 'pageSize']);

    return (
      <DataFilteringContainer
        stateQueryParam='auto-enabled-dashboards-filter'
        defaultPageSize={defaultPageSize}
        defaultFilters={{displayMode}}
        defaultSorting={{name: 'asc'}}
        setUserStoreProps={userStore.setStoreValueFn(AutoEnabledDashboards.userStoreKey)}
      >
        {({activePage, pageSize, updatePagination, sorting, updateSorting, filters, updateFilters}) =>
          <FetchData
            fetchData={AutoEnabledDashboards.fetchData}
            fetchParams={{blueprintId, routes}}
            pollingInterval={AutoEnabledDashboards.pollingInterval}
          >
            {({predefinedDashboards = [], refetchData}) =>
              <Grid>
                <Grid.Column width={16}>
                  <Breadcrumb
                    size='big'
                    icon='right caret'
                    sections={breadcrumbSections}
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <DataFilter
                    items={predefinedDashboards}
                    schema={tableSchema}
                    activePage={activePage}
                    pageSize={pageSize}
                    sorting={sorting}
                  >
                    {({items: filteredPredefinedDashboards, totalCount}) =>
                      <DataFilteringLayout
                        paginationProps={{
                          totalCount,
                          activePage,
                          pageSize,
                          onChange: updatePagination
                        }}
                        extraContent={{
                          buttonsPanel: (
                            <DashboardsDisplayMode
                              value={filters.displayMode}
                              onChange={(displayMode) => updateFilters({...filters, displayMode})}
                              modes={[
                                DASHBOARDS_DISPLAY_MODE.summary,
                                DASHBOARDS_DISPLAY_MODE.preview,
                              ]}
                            />
                          )
                        }}
                      >
                        {predefinedDashboards.length ?
                          <div className='auto-enabled-dashboards-table-container'>
                            <DataTable
                              items={filteredPredefinedDashboards}
                              schema={tableSchema}
                              params={{
                                routes, blueprintPermissions, dashboardNameToggleInProgress,
                                dashboardPreview: filters.displayMode === DASHBOARDS_DISPLAY_MODE.preview,
                                toggleDashboard, predefinedDashboards, refetchData,
                              }}
                              sorting={sorting}
                              updateSorting={updateSorting}
                            />
                          </div>
                        :
                          <Message
                            info
                            icon='info circle'
                            content='There are currently no dashboards defined.'
                          />
                        }
                      </DataFilteringLayout>
                    }
                  </DataFilter>
                </Grid.Column>
              </Grid>
            }
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}
