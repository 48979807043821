import {Lexer, CstParser, createToken} from 'chevrotain';

export const NumberLiteral = createToken({
  name: 'NumberLiteral',
  pattern: /[-+]?\d+(\.\d+)?/,
});
export const SingleQuoteStringLiteral = createToken({
  name: 'SingleQuoteStringLiteral',
  pattern: /'(:?[^\\\n\r']+|\\(:?[nr\\/']))*'/,
});
export const DoubleQuoteStringLiteral = createToken({
  name: 'DoubleQuoteStringLiteral',
  pattern: /"(:?[^\\\n\r"]+|\\(:?[nr\\/"]))*"/,
});
export const Property = createToken({
  name: 'Property',
  pattern: /[a-zA-Z_][.\w]*/,
});
export const Comma = createToken({name: 'Comma', pattern: ','});
export const LParen = createToken({name: 'LParen', pattern: '('});
export const RParen = createToken({name: 'RParen', pattern: ')'});
export const LSquare = createToken({name: 'LSquare', pattern: '['});
export const RSquare = createToken({name: 'RSquare', pattern: ']'});
export const Lt = createToken({name: 'Lt', pattern: '<'});
export const Gt = createToken({name: 'Gt', pattern: '>'});
export const Equals = createToken({name: 'Equals', pattern: '='});
export const NotEquals = createToken({name: 'NotEquals', pattern: '!='});
export const RegexMatch = createToken({name: 'RegexMatch', pattern: '~='});
export const Or = createToken({name: 'Or', pattern: /or|Or|OR/, longer_alt: Property});
export const And = createToken({name: 'And', pattern: /and|And|AND/, longer_alt: Property});
export const Is = createToken({name: 'Is', pattern: /is|Is/, longer_alt: Property});
export const IsNot = createToken({name: 'IsNot', pattern: /is not|Is Not/, longer_alt: Property});
export const None = createToken({name: 'None', pattern: /none|None/, longer_alt: Property});
export const Bool = createToken({name: 'Bool', pattern: /True|False/, longer_alt: Property});
export const WhiteSpace = createToken({name: 'WhiteSpace', pattern: /\s+/, group: Lexer.SKIPPED});
export const In = createToken({name: 'In', pattern: /in|In|IN/, longer_alt: Property});

export const allTokens = [
  WhiteSpace,
  Comma,
  LParen,
  RParen,
  LSquare,
  RSquare,
  Lt,
  Gt,
  Equals,
  NotEquals,
  RegexMatch,
  Or,
  And,
  IsNot,
  Is,
  None,
  Bool,
  In,
  NumberLiteral,
  SingleQuoteStringLiteral,
  DoubleQuoteStringLiteral,
  Property
];

export const MatcherFilterStringLexer = new Lexer(allTokens);

let parserInstance = null;

export default class MatcherFilterStringParser extends CstParser {
  static get instance() {
    if (!parserInstance) parserInstance = new this();
    return parserInstance;
  }

  static parse(text) {
    const lexResult = MatcherFilterStringLexer.tokenize(text);
    const parser = this.instance;
    parser.input = lexResult.tokens;
    const cst = parser.expression();
    return {cst, lexErrors: lexResult.errors, parseErrors: parser.errors};
  }

  constructor() {
    super(allTokens);
    const $ = this;
    $.RULE('expression', () => {
      $.SUBRULE($.conditionOrSubexpression);
      $.OPTION(() => {
        $.MANY(() => {
          $.OR([
            {ALT: () => $.CONSUME(Or)},
            {ALT: () => $.CONSUME(And)},
          ]);
          $.SUBRULE2($.conditionOrSubexpression);
        });
      });
    });
    $.RULE('conditionOrSubexpression', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.condition)},
        {ALT: () => $.SUBRULE($.subexpression)},
      ]);
    });
    $.RULE('subexpression', () => {
      $.CONSUME(LParen);
      $.SUBRULE($.expression);
      $.CONSUME(RParen);
    });
    $.RULE('condition', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.equals)},
        {ALT: () => $.SUBRULE($.notEquals)},
        {ALT: () => $.SUBRULE($.lt)},
        {ALT: () => $.SUBRULE($.gt)},
        {ALT: () => $.SUBRULE($.regexMatch)},
        {ALT: () => $.SUBRULE($.isNone)},
        {ALT: () => $.SUBRULE($.isNotNone)},
        {ALT: () => $.SUBRULE($.inList)},
      ]);
    });
    $.RULE('equals', () => {
      $.CONSUME(Property);
      $.CONSUME(Equals);
      $.SUBRULE($.value);
    });
    $.RULE('notEquals', () => {
      $.CONSUME(Property);
      $.CONSUME(NotEquals);
      $.SUBRULE($.value);
    });
    $.RULE('lt', () => {
      $.CONSUME(Property);
      $.CONSUME(Lt);
      $.CONSUME(NumberLiteral);
    });
    $.RULE('gt', () => {
      $.CONSUME(Property);
      $.CONSUME(Gt);
      $.CONSUME(NumberLiteral);
    });
    $.RULE('regexMatch', () => {
      $.CONSUME(Property);
      $.CONSUME(RegexMatch);
      $.SUBRULE($.string);
    });
    $.RULE('isNone', () => {
      $.CONSUME(Property);
      $.CONSUME(Is);
      $.CONSUME(None);
    });
    $.RULE('isNotNone', () => {
      $.CONSUME(Property);
      $.CONSUME(IsNot);
      $.CONSUME(None);
    });
    $.RULE('in', () => {
      $.CONSUME(Property);
      $.CONSUME(In);
    });
    $.RULE('list', () => {
      $.CONSUME(LSquare);
      $.MANY_SEP({
        SEP: Comma,
        DEF: () => $.SUBRULE($.value)
      });
      $.CONSUME(RSquare);
    });
    $.RULE('inList', () => {
      $.SUBRULE($.in);
      $.SUBRULE($.list);
    });
    $.RULE('string', () => {
      $.OR([
        {ALT: () => $.CONSUME(SingleQuoteStringLiteral)},
        {ALT: () => $.CONSUME(DoubleQuoteStringLiteral)}
      ]);
    });
    $.RULE('value', () => {
      $.OR([
        {ALT: () => $.CONSUME(NumberLiteral)},
        {ALT: () => $.CONSUME(Bool)},
        {ALT: () => $.SUBRULE($.string)},
        {ALT: () => $.SUBRULE($.list)},
      ]);
    });
    $.performSelfAnalysis();
  }
}
