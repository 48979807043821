import {Dropdown} from 'semantic-ui-react';
import {Field} from 'apstra-ui-common';
import {escapeRegExp, filter, isEmpty, map, first} from 'lodash';

import {Tags} from '../../components/graphs/GraphTooltips/ValueRenderers';

import './renderTaggedNodes.less';

// Custom search that includes tags
const searchWithTags = (options, query) => {
  const needle = new RegExp(escapeRegExp(query), 'i');
  return filter(options, ({text, tags}) => {
    const haystack = `${text} ${tags?.join(' ')}`;
    return needle.test(haystack);
  });
};

// Allows to search nodes not only by title but also by tags (if included in the options)
const renderTaggedNodes = ({name, value = null, schema, required, disabled, errors, fieldProps, onChange}) => {
  let options;
  let isMultiple = false;

  if (schema.type === 'node') {
    options = schema?.oneOf ?? [];
  } else if (schema.subtype === 'node_id') {
    options = schema?.items?.anyOf ?? [];
    isMultiple = true;
  }

  // This renderer is only usable when tags are present in the options.
  // For the empty dataset it doesn't matter how to render it.
  if (!first(options)?.tags) return null;

  const dropDownOptions = map(
    options,
    ({title: text, const: value, tags}) => (
      {
        key: value,
        text,
        value,
        tags,
        content: (
          <>
            {text}
            {
              !isEmpty(tags) && <div className='tags'><Tags tags={tags} size='tiny' /></div>
            }
          </>
        )
      }
    )
  );

  return (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <Dropdown
        className='ct-tagged-nodes'
        value={value}
        fluid
        selection
        clearable
        search={searchWithTags}
        options={dropDownOptions}
        multiple={isMultiple}
        onChange={(event, {value}) => onChange(value)}
      />
    </Field>
  );
};

export default renderTaggedNodes;
