import {
  capitalize, compact, find, forEach, has, includes, isArray, isPlainObject,
  isUndefined, join, map,
} from 'lodash';

import {QUERY_PROPERTIES} from './consts';
import {GRAPH_QUERY_KWARGS} from '../pythonExpression/consts';
import {castType} from './utils';

export const convertGraphQueryObjectToString = (object) => {
  if (!has(object, 'name') || !object.name) return '';
  let query = `${object.name}(`;
  const castValue = (value, propType = 'string') => {
    const type = castType(propType);
    return isArray(value) ?
      `[${join(map(value, (v) => castValue(v, type)), ', ')}]` :
      isPlainObject(value) ?
        JSON.stringify(value) :
        type === 'number' ?
          value :
          type === 'boolean' ?
            capitalize(value) :
            `'${value}'`;
  };
  if (includes(['match', 'optional'], object.name)) {
    const nodes = [];
    forEach(object.value, (value) => {
      nodes.push(convertGraphQueryObjectToString(value));
    });
    query += join(compact(nodes), ', ');
  } else {
    const args = [];
    if (has(object, 'type') && object.type) args.push(`'${object.type}'`);
    if (has(object, 'predicate')) args.push(`${object.predicate}`);
    if (has(object, 'query')) args.push(convertGraphQueryObjectToString(object.query));
    if (has(object, 'uniqNames')) args.push(join(map(object.uniqNames, (v) => `'${v}'`), ', '));
    forEach(object.kwargs, ({name, value, matcher, type}) => {
      const getValueWithMatcher = (value, matcher, type) => {
        const typeValue = castValue(value, type);
        return matcher && matcher !== '=' ?
          includes(['is_none', 'not_none'], matcher) ?
            `${matcher}()` :
            `${matcher}(${typeValue})` :
          typeValue;
      };
      if (matcher === '_or' || matcher === '_and') {
        const result = [];
        forEach(value, ({value, matcher, type}) => {
          const valueWithMatcher = getValueWithMatcher(value, matcher, type);
          if (!isUndefined(valueWithMatcher)) result.push(valueWithMatcher);
        });
        args.push(`${name}=${matcher}(${join(result, ', ')})`);
      } else {
        const valueWithMatcher = getValueWithMatcher(value, matcher, type);
        if (!isUndefined(valueWithMatcher)) args.push(`${name}=${valueWithMatcher}`);
      }
    });
    forEach(object, (valueProp, name) => {
      if (!QUERY_PROPERTIES.has(name)) {
        if (!isUndefined(valueProp)) {
          const schema = find(GRAPH_QUERY_KWARGS[object.name], ({name: schemaName}) => schemaName === name);
          const value = castValue(valueProp, schema?.type);
          args.push(`${name}=${value}`);
        }
      }
    });
    query += join(compact(args), ', ');
  }
  query += ')';
  const path = [query];
  forEach(object.path, (value) => {
    path.push(convertGraphQueryObjectToString(value));
  });
  return join(path, '.');
};
