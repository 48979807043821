import {useMemo} from 'react';
import {filter, map} from 'lodash';
import {Icon} from 'semantic-ui-react';

import {SPECIAL_RENDERED_INPUT_PROPERTIES} from '../consts';

import './PythonExpressionSuggestions.less';

export default function PythonExpressionSuggestions({propertyName, setPropertyValue, knownPythonExpressionVariables}) {
  const [nodeType, nodePropertyName] = SPECIAL_RENDERED_INPUT_PROPERTIES.PYTHON_EXPRESSION_SUGGESTIONS[propertyName];
  const suggestions = useMemo(
    () => filter(knownPythonExpressionVariables, {nodeType, propertyName: nodePropertyName}),
    [nodeType, nodePropertyName, knownPythonExpressionVariables]
  );
  if (!suggestions.length) return null;
  return (
    <div className='python-exression-suggestions form-text'>
      {'Suggestions: '}
      {map(suggestions, ({nodeName, propertyName, color, shape}) => {
        const variable = nodeName + '.' + propertyName;
        return (
          <button
            key={variable}
            className='python-exression-suggestion'
            onClick={() => setPropertyValue(variable)}
          >
            <Icon size='small' name={shape ?? 'circle outline'} color={color} />
            <span>{variable}</span>
          </button>
        );
      })}
    </div>
  );
}
