import {HashRouter as Router} from 'react-router-dom';
import {Breadcrumb, Button, Divider, Input, Loader, Message} from 'semantic-ui-react';
import {useCallback, useEffect, useState} from 'react';
import {drop, map, nth, slice} from 'lodash';
import cx from 'classnames';
import {Checkbox, DataFilteringContainerWithRouter, onEnterKeyHandler, request} from 'apstra-ui-common';

import useUserStore from '../hooks/useUserStore';
import wrapWithComponent from '../wrapWithComponent';
import WebconsData from './WebconsData';

import './Webcons.less';

const BASE_PATH = '/api/webcons';
const WEBCONS_USER_STORE_KEY = 'webcons';

const Webcons = ({filters, updateFilters}) => {
  const [preferences, setPreferences] = useUserStore(WEBCONS_USER_STORE_KEY);
  const [loadId, setLoadId] = useState(0);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const simpleStyleStoreValue = preferences?.simpleStyle;
  const [simpleStyle, setSimpleStyle] = useState(simpleStyleStoreValue);
  useEffect(() => {
    setSimpleStyle(simpleStyleStoreValue);
  }, [simpleStyleStoreValue]);

  const [pathInputValue, setPathInputValue] = useState();

  const {path = BASE_PATH} = filters;
  useEffect(() => {
    const query = async () => {
      try {
        setLoading(true);
        setData(null);
        const response = await request(path);
        setData(response);
        setError(null);
      } catch (e) {
        setError(e);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    query();
  }, [path, loadId]);

  const toggleSimpleStyle = () => {
    const newSimpleStyle = !simpleStyle;
    setSimpleStyle(newSimpleStyle);
    setPreferences({simpleStyle: newSimpleStyle});
  };

  const paths = drop(path.split('/'), 3);
  useEffect(
    () => setPathInputValue(drop(path.split('/'), 3).join('/')),
    [path]
  );
  paths.unshift('Webcons');
  const breadcrumbSections = map(paths, (p, i) => {
    const isLast = i === (paths.length - 1);
    const link = [BASE_PATH, ...slice(paths, 1, i + 1)].join('/');
    return {
      key: link,
      content: p,
      link: !isLast,
      active: isLast,
      onClick: isLast ? undefined : () => updateFilters({path: link})
    };
  });

  const navigateToInputPath = useCallback(() => {
    updateFilters({path: `${BASE_PATH}/${pathInputValue}`});
  }, [pathInputValue, updateFilters]);

  return (
    <div className={cx('webcons', {simple: simpleStyle})}>
      <Breadcrumb
        icon='right angle'
        sections={breadcrumbSections}
      />
      <div className='buttons-container'>
        {breadcrumbSections.length > 1 && (
          <Button
            basic
            content='UP'
            icon='arrow up'
            onClick={() => nth(breadcrumbSections, -2)?.onClick?.()}
          />
        )}
        <Button
          basic
          content='Reload'
          icon='sync'
          onClick={() => setLoadId((id) => id + 1)}
        />
        <Input
          className='path-input'
          label='/'
          icon={{
            name: 'arrow alternate circle right outline',
            link: true,
            onClick: navigateToInputPath
          }}
          value={pathInputValue}
          onKeyDown={onEnterKeyHandler(navigateToInputPath)}
          onChange={(e) => setPathInputValue(e.target.value)}
        />
      </div>
      <Divider />
      <div className='simple-view-checkbox'>
        <Checkbox label='Simple view' checked={simpleStyle} onClick={toggleSimpleStyle} />
      </div>
      {data && (
        <WebconsData
          key={path}
          document={data}
          updateFilters={updateFilters}
          basePath={BASE_PATH}
          simpleStyle={simpleStyle}
        />
      )}
      {loading && <Loader active inline='centered' size='large' />}
      {error && <Message negative><Message.Header>{'Error'}</Message.Header>{error.message}</Message>}
    </div>
  );
};

export default wrapWithComponent(Router)(
  wrapWithComponent(DataFilteringContainerWithRouter, {stateQueryParam: 'params', pushToHistory: true})(Webcons)
);
