import {Fragment, useContext} from 'react';
import {BooleanInput, CodeEditorControl, DropdownControl, Field, MapInput,
  StringListDropdownControl} from 'apstra-ui-common';
import {Input} from 'semantic-ui-react';
import {concat, filter, includes, isEmpty, map, some} from 'lodash';
import isNumeric from 'is-number';

import {MULTIPLE_MATCHERS} from './consts';
import {castType} from './utils';
import QueryBuilderContext from './QueryBuilderContext';

const QueryMatcherValue = ({value, isTag, attribute, onChange, matcher}) => {
  const {tags} = useContext(QueryBuilderContext);
  const knownTags = map(tags, ({label}) => label);
  const inputType = castType(attribute?.type);
  const disabled = includes(['is_none', 'not_none'], matcher);
  const getAvailableProperties = (properties) => {
    if (!value) return properties;
    if (some(properties, (property) => property === value)) return properties;
    return concat([value], properties);
  };
  if (matcher === '_or' || matcher === '_and') return null;

  return isTag || attribute?.type === 'array' || MULTIPLE_MATCHERS.has(matcher) ?
    <StringListDropdownControl
      value={value === '' ? [] : value}
      knownItems={isTag ? knownTags : attribute?.enum}
      onChange={(value) => {
        const type = castType(attribute?.type);
        if (type === 'number') {
          onChange('value', filter(value, (v) => isNumeric(v)));
        } else {
          onChange('value', [...value]);
        }
      }}
      disabled={disabled}
      placeholder='Input values'
      allowAdditions
    /> :
    attribute?.type === 'boolean' ?
      <BooleanInput schema={{}} value={value} onChange={(v) => onChange('value', v)} /> :
      matcher === 'has_items' || attribute?.type === 'object' ?
        <MapInput
          value={value === '' ? {} : value}
          schema={{}}
          onChange={(v) => onChange('value', v)}
          addButtonProps={{basic: true, size: 'tiny', labelPosition: null}}
          deleteButtonProps={{color: 'grey'}}
          buttonText='Add Property'
          noItemsMessage='There are no properties.'
        >
          {({key, value, index, errors, setEntryKey, setEntryValue}) =>
            <Fragment>
              <Field key='key' width={8}>
                <Input
                  placeholder='Key'
                  value={key}
                  onChange={(e) => setEntryKey(index, e.target.value)}
                />
              </Field>
              <Field key='value' width={8} errors={errors}>
                <Input
                  placeholder='Value'
                  value={value}
                  onChange={(e) => setEntryValue(index, e.target.value)}
                />
              </Field>
            </Fragment>
          }
        </MapInput> :
        attribute?.type === 'function' ?
          <CodeEditorControl
            mode='python-expression'
            enableCompletion
            value={value}
            disabled={disabled}
            onChange={(value) => onChange('value', value)}
            placeholder='Input expression'
          /> :
          !isEmpty(attribute?.enum) ?
            <DropdownControl
              value={value}
              disabled={disabled}
              options={map(getAvailableProperties(attribute.enum), (value) => ({key: value, text: value, value}))}
              placeholder='Select value'
              onChange={(value) => onChange('value', value)}
              search
            /> :
            <Input
              value={value}
              type={inputType}
              onChange={(e) => onChange('value', e.target.value)}
              disabled={disabled}
              placeholder='Input value'
            />;
};

export default QueryMatcherValue;
