import {observable, computed, action, makeObservable, toJS} from 'mobx';
import {map, every, set, size, intersection, groupBy, keyBy, isEqual, cloneDeep, some, isEmpty, flatten,
  without, mean, first} from 'lodash';

import Endpoint from './Endpoint';
import EndpointGroup from './EndpointGroup';
import {ctrlStages, ctrlEntityTypes, ctrlNodesPlacements, ctrlIfcPositionsOffsets} from '../const.js';
import {generateLocalId, validateSubnetsOf} from '../utils';

class AggregateLink {
  #backup;
  #backupClone;

  @observable id;
  @observable endpoints = [];
  @observable stage = null;
  @observable label;
  @observable tags;

  @observable.ref cablingMap = null;

  @observable endpointGroups = [new EndpointGroup({}, 0), new EndpointGroup({}, 1)];

  @action
  init(nodes, placement) {
    this.id = generateLocalId();
    const aggregatePortId = generateLocalId();
    this.updateEndpointsWith(nodes, placement, aggregatePortId);
    this.stage = null;
    this.tags = [];
  }

  constructor(nodes, cablingMap, placement = {}) {
    makeObservable(this);

    if (!cablingMap) {
      throw new Error('AggregateLink\'s constructor called without the CablingMap store');
    }
    this.cablingMap = cablingMap;
    this.init(nodes, placement);
  }

  @action
  setEndpointGroups(endpointGroups) {
    this.endpointGroups = endpointGroups;
  }

  @computed
  get endpointsByNodeId() {
    return keyBy(this.endpoints, 'nodeId');
  }

  @computed
  get endpointsByGroup() {
    return groupBy(this.endpoints, 'endpointGroup.index');
  }

  @computed
  get myLinks() {
    return this.cablingMap.linksByAggregateId[this.id] || [];
  }

  @computed
  get childLinksHaveErrors() {
    return some(this.myLinks, {isValid: false});
  }

  // Each endpoint group has a middle point (mean of centers of its nodes)
  @computed
  get groupMiddles() {
    return map([0, 1], (index) => {
      const positions = map(this.endpointsByGroup[index], 'node.centerPosition');
      return {
        x: mean(map(positions, 'x')),
        y: mean(map(positions, 'y'))
      };
    });
  }

  // Based on the group middles "through" points get calculated.
  // These identify two points, all links of the aggregate go through.
  @computed
  get groupThroughPoints() {
    return map(this.groupMiddles, (middle, index) => {
      const oppositeMiddle = this.groupMiddles[1 - index];

      const endpoints = this.endpointsByGroup[index];
      const singleGroupNode = size(endpoints) === 1;

      if (singleGroupNode) {
        const {x, y, placement} = first(endpoints).portPosition;
        const [sx, sy] = ctrlIfcPositionsOffsets?.[placement] ?? [0, 0];
        return {
          x: x + 20 * sx,
          y: y + 20 * sy
        };
      }

      const [dx, dy] = [oppositeMiddle.x - middle.x, oppositeMiddle.y - middle.y];
      const norma = Math.sqrt(dx * dx + dy * dy);
      return {
        x: middle.x + 20 * dx / norma,
        y: middle.y + 20 * dy / norma
      };
    });
  }

  get type() {
    return ctrlEntityTypes.AGGREGATE;
  }

  get isAggregated() {
    return false;
  }

  get isAggregate() {
    return true;
  }

  @computed
  get exists() {
    return this.stage !== ctrlStages.DELETE;
  }

  @computed
  get isUpdate() {
    return this.stage === ctrlStages.CHANGE;
  }

  get hasChanges() {
    if (this.stage === ctrlStages.ADD || this.stage === ctrlStages.DELETE) return true;
    if (!this.#backup) return false;
    return !isEqual(this.#backup, this.data);
  }

  @computed
  get nodeIds() {
    return map(this.endpoints, 'nodeId');
  }

  @computed
  get data() {
    return toJS({
      id: this.id,
      label: this.label,
      aggregateId: this.aggregateId,
      endpoints: map(this.endpoints, 'data'),
      endpointGroups: this.endpointGroups,
      tags: toJS(this.tags)
    });
  }

  @computed
  get usedIps() {
    return flatten(map(this.endpoints, 'usedIps'));
  }

  get usedTags() {
    return [
      ...this.tags,
      ...flatten(map(this.endpoints, 'tags'))
    ];
  }

  // Checks whether the link contains IP(s) from the list of duplicates
  containsDuplicates(usedIps) {
    return some(this.usedIps, (ip) => ((usedIps?.[ip] ?? 0) > 1));
  }

  @computed
  get ipsv4SubnetErrors() {
    return validateSubnetsOf(without(map(this.endpoints, 'ipv4Parsed'), true, false));
  }

  @computed
  get ipsv6SubnetErrors() {
    return validateSubnetsOf(without(map(this.endpoints, 'ipv6Parsed'), true, false));
  }

  @computed
  get ipsDiffer() {
    return !this.ipsv4SubnetErrors && !this.ipsv6SubnetErrors;
  }

  @computed
  get errors() {
    const {ipsv4SubnetErrors, ipsv6SubnetErrors} = this;
    const result = [];
    if (ipsv4SubnetErrors) {
      result.push(...ipsv4SubnetErrors);
    }
    if (ipsv6SubnetErrors) {
      result.push(...ipsv6SubnetErrors);
    }
    return result;
  }

  @computed
  get hasErrors() {
    return !isEmpty(this.errors);
  }

  @computed
  get isValid() {
    return !this.hasErrors && !this.childLinksHaveErrors && every(this.endpoints, 'isValid');
  }

  cloneFor(nodeIds) {
    const clone = new AggregateLink([], this.cablingMap);
    Object.assign(clone, {
      tags: [...this.tags],
      endpoints: map(this.endpoints, (endpoint) => endpoint.cloneFor(nodeIds)),
    });
    return clone;
  }

  @computed
  get stateId() {
    return `${this.id}:${size(this.endpoints)}`;
  }

  @action
  fillWith(data) {
    Object.assign(this, data);
  }

  @action
  updateEndpointsWith(nodes, placement, aggregatePortId) {
    if (!aggregatePortId) {
      aggregatePortId = first(this.endpoints)?.portId;
    }
    const result = map(
      nodes,
      (node) => this.endpointsByNodeId[node.id] ||
        new Endpoint(
          {
            node,
            aggregatePortId,
            externalId: this.id,
            endpointGroup: this.endpointGroups[placement?.[node.id] === ctrlNodesPlacements.RIGHT ? 1 : 0]
          },
          this.cablingMap
        )
    );
    this.endpoints = result;
  }

  @action
  commit() {
    this.#backup = null;
    this.#backupClone = null;
    this.stage = null;
  }

  @action
  backup() {
    // Preserves initial state of link to be restored in case of
    // changes discard
    this.#backup = cloneDeep(this.data);
    this.#backupClone = this.serialize();
    this.stage = ctrlStages.CHANGE;
  }

  @computed
  get isBackedUp() {
    return !!this.#backup;
  }

  @action
  markForDeletion() {
    this.stage = ctrlStages.DELETE;
  }

  @action
  restore(toBlank) {
    const result = toBlank ? new AggregateLink([], this.cablingMap) : this;
    // Restores link's preserved state.
    // Updates <target> if provided in order to track changes.
    if (this.isBackedUp) {
      AggregateLink.deserialize(this.#backupClone, this.cablingMap, result);
    }
    result.commit();
    return result;
  }

  // Checks whether the link contains IP(s) from the list of duplicates
  containsIps(ips) {
    return !!size(intersection(this.usedIps, ips));
  }

  // Checks if the link connects all/any of given nodes
  contains(nodes, all) {
    const targetNodesIds = map(nodes, 'id');
    const intersectionCount = size(intersection(this.nodeIds, targetNodesIds));
    return all ? intersectionCount === size(targetNodesIds) : intersectionCount > 0;
  }

  // Checks if the link connects all of given nodes
  fallsWithin(nodeIds) {
    return size(intersection(nodeIds, this.nodeIds)) === size(this.nodeIds);
  }

  originatesWithin(nodeIds) {
    return some([0, 1], (endpointGroupId) => {
      const groupNodesIds = map(this.endpointsByGroup[endpointGroupId], 'nodeId');
      return size(intersection(nodeIds, groupNodesIds)) === size(groupNodesIds);
    });
  }

  static deserialize(jsonValue, cablingMap, target) {
    const result = target || new AggregateLink([], cablingMap);

    const {id, label, endpoints, tags, endpoint_groups: endpointGroups} = jsonValue;
    result.id = id ?? result.id;
    result.setEndpointGroups(
      map(endpointGroups,
        (endpointGroup, index) => new EndpointGroup(endpointGroup, +index)
      )
    );

    result.endpoints = map(endpoints, (endpoint) => {
      set(endpoint, 'local.externalId', result.id);
      const resultEndpoint = Endpoint.deserialize(endpoint, cablingMap);
      resultEndpoint.setEndpointGroup(result.endpointGroups?.[resultEndpoint.endpointGroup]);
      return resultEndpoint;
    });
    result.fillWith({label, tags});
    return result;
  }

  serialize() {
    return toJS({
      id: this.id,
      label: this.label,
      speed: this.speedString,
      endpoints: map(this.endpoints, (endpoint) => endpoint.serialize()),
      type: ctrlEntityTypes.AGGREGATE,
      tags: toJS(this.tags),
      endpoint_groups: toJS(this.endpointGroups),
    });
  }
}

export default AggregateLink;
