import {v4 as uuidv4} from 'uuid';
import {toLower, toUpper} from 'lodash';

const VN_TYPES = ['AttachSingleVLAN', 'AttachMultipleVLAN'];

export function generateId() {
  return uuidv4();
}

const _titleSplitter = /([^A-Z])([A-Z])/g;
const _upperCase = new RegExp(`(^| )(${['ip', 'asn', 'svi', 'bgp', 'vlan', 'vn'].join('|')})( |$)`, 'ig');
const _lowerCase = new RegExp(`(^| )(${['for', 'and', 'or', 'with', 'to'].join('|')})( |$)`, 'ig');
const _singleSpace = /\s+/g;

export function beautifyTitle(title) {
  return title
    .replace(_titleSplitter, '$1  $2')
    .replace(_upperCase, toUpper)
    .replace(_lowerCase, toLower)
    .replace(_singleSpace, ' ');
}

export function isNotVnType({type}) {
  return !VN_TYPES.includes(type);
}
