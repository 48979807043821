import {Header} from 'semantic-ui-react';

import {ReactComponent as Vn} from '../../styles/icons/virtual-statistics/vn.svg';
import {ReactComponent as Rz} from '../../styles/icons/virtual-statistics/rz.svg';

import './SummaryInfo.less';

const SummaryInfo = ({rzCount, vnCount}) => (
  <div className='summary-info'>
    <div className='block'>
      <Rz className='icon' />
      <div className='meta'>
        <Header as='h1'>{rzCount}</Header>
        <Header.Subheader>{'Routing Zone Count'}</Header.Subheader>
      </div>
    </div>
    <div className='block'>
      <Vn className='icon' />
      <div className='meta'>
        <Header as='h1'>{vnCount}</Header>
        <Header.Subheader>{'Virtual Network Count'}</Header.Subheader>
      </div>
    </div>
  </div>
);

export default SummaryInfo;
