import {CalendarControl, DropdownControl, DurationInput, Field} from 'apstra-ui-common';
import {Button} from 'semantic-ui-react';
import {isEmpty, isNull} from 'lodash';
import {toJS} from 'mobx';

import {START_TIME_FIELD, END_TIME_FIELD, BEGIN_TIME_FIELD} from './const';

export const DATETIME_FIELDS = [
  BEGIN_TIME_FIELD,
  START_TIME_FIELD,
  END_TIME_FIELD,
];
export const DURATION_FIELDS = [
  'aggregation_interval',
];

export const renderDatetimeInput = ({name, value, schema, required, disabled, errors, onChange}) => {
  if (DATETIME_FIELDS.includes(name)) {
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        className='datetime-field'
      >
        <CalendarControl
          placeholder='Select Date'
          value={value}
          onChange={onChange}
        />
      </Field>
    );
  }
  return null;
};

export const renderDurationInput = ({name, value, schema, required, disabled, errors, onChange}) => {
  if (DURATION_FIELDS.includes(name)) {
    return (
      <DurationInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        customValueType='duration'
        textPrefix=''
        onChange={onChange}
      />
    );
  }
  return null;
};

export const renderCombineTimeInput = ({name, value, schema, required, disabled, errors, onChange, useCombineTime}) => {
  if (name === START_TIME_FIELD && useCombineTime) {
    return (
      <DurationInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        customValueType='dates'
        onChange={(value) => {
          if (!isNull(value)) onChange(toJS(value));
        }}
      />
    );
  }
  return null;
};

export const renderProbeInput = ({
  name, value, schema, required, disabled, errors, onChange, probeOptionsByName, openPredefinedProbeModal,
}) => {
  const isEmptyOptions = isEmpty(probeOptionsByName[name]);
  return (
    <>
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
      >
        <DropdownControl
          search={!disabled}
          value={value || ''}
          onChange={onChange}
          options={probeOptionsByName[name] || []}
        />
      </Field>
      {isEmptyOptions &&
        <div className='report-create-probe'>
          {'You can '}
          <Button secondary size='tiny' content='Instantiate' onClick={() => openPredefinedProbeModal(name)} />
          {'probe.'}
        </div>
      }
    </>
  );
};
