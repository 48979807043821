import {Component} from 'react';
import {Checkbox, PermissionChecker, hasBlueprintPermissions} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

export default class DashboardDefaultToggle extends Component {
  static contextType = IBAContext;

  render() {
    const {value, dashboardIdToggleInProgress, onChange} = this.props;
    const {blueprintPermissions} = this.context;

    return (
      <PermissionChecker
        hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
      >
        <Checkbox
          toggle
          aria-label='Default Dashboard'
          checked={value}
          disabled={dashboardIdToggleInProgress !== null}
          onChange={onChange}
        />
      </PermissionChecker>
    );
  }
}
