import {Fragment, forwardRef} from 'react';
import {observer} from 'mobx-react';
import {Segment, Button, Grid, Icon, Message} from 'semantic-ui-react';
import cx from 'classnames';
import {isEmpty, map} from 'lodash';
import {Checkbox, Field} from 'apstra-ui-common';

import {useCablingMapStore} from '../store/useCablingMapStore';
import PortMapView from './PortMapView';
import TagsControl from '../../components/TagsControl';
import EndpointProperties from './EndpointProperties';
import {stringFromSpeed} from '../../rackEditor/utils';
import './LinkProperties.less';

const Divider = ({text}) => {
  return (
    <Grid.Column className='ctrl-divider'>
      <div>
        <div />
        <span>{text}</span>
        <div />
      </div>
    </Grid.Column>
  );
};

const LinkProperties = forwardRef(
  ({
    link, linkNodes, links, nodesDeviceProfiles, selectedLinks,
    linkActions: {remove, portSelect, toggle}, lockedSpeed
  }, ref) => {
    const {cablingMap: {tags: knownTags}} = useCablingMapStore();
    const linkSpeed = lockedSpeed || link.speed;

    const linkClasses = cx('single-link', {red: !link.isValid});
    return (
      <Segment
        key={link.id}
        className={linkClasses}
      >
        <Grid className='port-maps'>
          <Grid.Row className='header'>
            <Grid.Column className='link-title'>
              <h4 ref={ref}>
                <Checkbox
                  checked={!!selectedLinks?.[link.id]}
                  disabled={!selectedLinks}
                  onClick={() => toggle?.(link)}
                  label={link.label}
                />
              </h4>
              <div className='tags'>
                <Field label='Tags: '>
                  <TagsControl
                    fluid
                    knownTags={knownTags}
                    onChange={(tags) => link.setProperty('tags', [...tags])}
                    value={link.tags}
                  />
                </Field>
              </div>
              <div className='actions'>
                {!!remove && <Button
                  content='Delete'
                  color='red'
                  size='tiny'
                  onClick={() => remove(link)}
                />}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            {
              map(
                nodesDeviceProfiles,
                ({deviceProfile, portGroups}, nodeIndex) => {
                  const endpoint = linkNodes[nodeIndex].id === link.endpoint1.nodeId ?
                    link.endpoint1 : link.endpoint2;
                  const classNames = cx({
                    external: endpoint.external,
                    error: !endpoint.external && !deviceProfile
                  });
                  const errors = [
                    ...endpoint.errors,
                    ...(nodeIndex ? [] : link.errors)
                  ];

                  return (
                    <Fragment key={nodeIndex}>
                      {
                        !!nodeIndex && <Divider text={stringFromSpeed(linkSpeed)} />
                      }
                      <Grid.Column className={classNames} width={8}>
                        {!link.isAggregated &&
                          <EndpointProperties
                            endpoint={endpoint}
                            link={link}
                          />
                        }
                        {
                          endpoint.external ?
                            <div className='checkmark'>
                              <Icon name='check' />
                            </div> :
                            (deviceProfile ?
                              <PortMapView
                                deviceProfile={deviceProfile}
                                portGroups={portGroups}
                                onSelect={(portId, transformationId, ifc) =>
                                  portSelect?.(portId, transformationId, ifc, endpoint)
                                }
                                endpoint={endpoint}
                                links={links}
                                speed={linkSpeed}
                              /> :
                              <>
                                <Icon name='warning sign' />
                                {'No device profile selected!'}
                              </>
                            )
                        }
                        {
                          !isEmpty(errors) &&
                            <Message error>
                              <ul>
                                {
                                  map(
                                    errors,
                                    (error, index) => <li key={index}>{error}</li>
                                  )
                                }
                              </ul>
                            </Message>
                        }
                      </Grid.Column>
                    </Fragment>
                  );
                }
              )
            }
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
);

export default observer(LinkProperties);
