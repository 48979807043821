import {scalePow} from 'd3';

const exponent = 0.5;
const ranges = [0, 0.35, 1];
const backgroundColors = ['#a8d062', '#ffcf68', '#f09189'];
const foregroundColors = ['#3b4f18', '#9b6a00', '#991e14'];
const scale = scalePow().exponent(exponent).domain(ranges).clamp(true);

export default function getCellColor({anomalies = 0, items = 0}) {
  return {
    background: scale.range(backgroundColors)(anomalies / items),
    foreground: scale.range(foregroundColors)(anomalies / items)
  };
}
