import {find, groupBy, isPlainObject, compact, keys, map, uniq, reduce, isEmpty} from 'lodash';
import {observer} from 'mobx-react';
import {Header, Label, Popup, Segment, Grid, Table, Icon} from 'semantic-ui-react';
import cx from 'classnames';

import './PortMapView.less';

const PortMapView = ({deviceProfile, portGroups, ...props}) => {
  return (
    <div className='ctrl-port-map'>
      {keys(portGroups)
        .sort((a, b) => Number(a) - Number(b))
        .map((slotId) =>
          <PortGroup
            key={slotId}
            {...props}
            ports={portGroups[slotId]}
            label={`Slot #${slotId}`}
            deviceProfile={deviceProfile}
          />
        )
      }
    </div>
  );
};

export default observer(PortMapView);

const getPortsEndpoints = (ports, links, nodeId) => {
  return ports.reduce((acc, {portId}) => {
    const taken = reduce(
      links,
      (result, link) => {
        if (link.exists) {
          const endpoint = link.getEndpointFor(nodeId, portId);
          if (endpoint?.isValid) {
            result = {
              interfacesUsed: [
                ...(result.transformationId === endpoint.transformationId ? result.interfacesUsed : []),
                endpoint.interfaceName
              ],
              transformationId: endpoint.transformationId
            };
          }
        }
        return result;
      },
      {
        transformationId: null,
        interfacesUsed: []
      }
    );
    acc[portId] = taken;
    return acc;
  }, {});
};

const NestedGroups = observer(({ports, ...props}) => {
  return keys(ports)
    .sort((a, b) => Number(a) - Number(b))
    .map((groupId) =>
      <PortGroup
        key={groupId}
        {...props}
        ports={ports[groupId]}
        label={`Panel #${groupId}`}
      />
    );
});

const PortGroup = observer((props) => {
  const {
    label, ports, deviceProfile, links, disabled, endpoint, onSelect, speed
  } = props;

  const selectedPort = find(ports, {portId: endpoint.portId});

  if (isPlainObject(ports)) {
    if (deviceProfile.hasSlots) {
      return (
        <div key={label} className='slot-container'>
          <Header as='h3' content={label} />
          <Segment.Group>
            <NestedGroups {...props} />
          </Segment.Group>
        </div>
      );
    }
    return (
      <div>
        <NestedGroups {...props} />
      </div>
    );
  }

  const portsEndpoints = getPortsEndpoints(ports, links, endpoint.nodeId);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <PortMap
            selectedPortId={endpoint.portId}
            selectPort={onSelect}
            {...{ports, deviceProfile, links, endpoint, portsEndpoints}}
          />
        </Grid.Column>
      </Grid.Row>
      {selectedPort &&
        <Grid.Row>
          <Grid.Column>
            <PortDetails
              port={selectedPort}
              portEndpoints={portsEndpoints[endpoint.portId]}
              {...{deviceProfile, links, disabled, endpoint, onSelect, speed}}
            />
          </Grid.Column>
        </Grid.Row>
      }
    </Grid>
  );
});

const renderPort = (port, {selectedPortId, selectPort, deviceProfile, portsEndpoints}) => {
  const endpoints = portsEndpoints[port.portId];
  const hasEndpoints = endpoints.interfacesUsed.length > 0;

  const colors = uniq(map(endpoints, 'color'));
  const selected = port.portId === selectedPortId;

  const {id: transformationId, interfaces} = port?.transformations?.[0] ?? {};
  const selectParameters = selected ? [null, null, {}] : (
    // If selected port has the only transformation with a single free interface it must get
    // selected as there are no other choices
    port?.transformations?.length === 1 && interfaces?.length === 1 && !hasEndpoints ?
      [port.portId, transformationId, interfaces[0]] :
      [port.portId, null, {}]
  );

  return (
    <Port
      key={port.portId}
      selected={selected}
      select={() => selectPort(...selectParameters)}
      style={hasEndpoints ? {} : {background: deviceProfile.connectorTypeColors[port.connectorType]}}
      autonegotiation={deviceProfile.isAutoNegPort(port.portId)}
      color={colors.length > 1 ? 'blue' : colors[0]}
      inverted={hasEndpoints}
      endpoints={endpoints}
      {...port}
    />
  );
};

const PortMap = observer((props) => {
  const {ports} = props;

  const rows = groupBy(ports, 'rowId');
  return (
    <div className='device-profile-port-map-container'>
      <table className='device-profile-port-map'>
        <tbody>
          {keys(rows).map((rowId) =>
            <tr key={rowId}>{rows[rowId].map((row) => renderPort(row, props))}</tr>
          )}
        </tbody>
      </table>
    </div>
  );
});

function Port({
  portId, displayId, selected, select, transformations, color, style, autonegotiation, inverted
}) {
  return (
    <Segment
      as='td'
      {...{style, inverted}}
      className={cx(
        'port no-summary',
        color,
        {selected}
      )}
      onClick={select}
    >
      <span key='port-id' className='port-id'>
        {autonegotiation &&
          <Icon className='autoneg' name='exchange' />
        }
        {displayId ?? portId}
      </span>
      {transformations.length > 1 &&
        <Icon key='port-icon' name='fork' rotated='clockwise' />
      }
    </Segment>
  );
}

const PortDetails = observer(({
  port, deviceProfile, endpoint, portEndpoints, onSelect, speed: linkSpeed
}) => {
  const availableTransformations = compact(
    port.transformations.map(({id: transformationId, isDefault, interfaces}) => {
      // If this port does already have transformation selected for one of the links
      // its other transformations must be hidden
      const {transformationId: transformationUsed, interfacesUsed} = portEndpoints;
      if (transformationUsed && transformationUsed !== transformationId) return null;

      // If link has speed defined (via the other endpoint) transformations with different
      // speeds must not be acceptable
      const {speed} = interfaces.find(({speed}) => speed) || {};
      if (linkSpeed.value && (linkSpeed.value !== speed.value || linkSpeed.unit !== speed.unit)) return null;

      return (
        <Table.Row key={transformationId}>
          <Table.Cell width={4}>
            {`Port #${port.portId} Tr. #${transformationId}` + ((isDefault || speed) ?
              ` (${compact([
                speed ? speed.label : '',
                isDefault ? 'default' : ''
              ]).join(', ')})` : '')
            }
          </Table.Cell>
          <Table.Cell>
            {interfaces.map((ifc) => {
              const {id: ifcId, name: ifcName} = ifc;
              const selected = endpoint.transformationId === transformationId &&
                endpoint.interfaceName === ifcName;
              const disabled = !selected && interfacesUsed.includes(ifcName);
              const autonegotiation = deviceProfile.isAutoNegInterface(port.portId, transformationId, ifcId);
              return <Interface
                key={ifcId}
                {...{ifc, autonegotiation}}
                onClick={(ifc) => onSelect(endpoint.portId, transformationId, ifc)}
                selected={selected}
                disabled={disabled}
              />;
            })}
          </Table.Cell>
        </Table.Row>
      );
    })
  );

  if (isEmpty(availableTransformations)) {
    return null;
  }

  return (
    <div className='port-details compact'>
      <Table definition size='small'>
        <Table.Body>
          {availableTransformations}
        </Table.Body>
      </Table>
    </div>
  );
});

function Interface({endpoint, ifc, onClick, disabled, autonegotiation, selected}) {
  if (endpoint) {
    const {link: {linkLabel, endpoint: {color, title, serverLabel}}} = endpoint;
    return (
      <Popup
        trigger={
          <Label
            size='small'
            basic={!selected}
            color={color}
            onClick={() => !disabled && onClick(ifc)}
            className={cx({disabled})}
            style={{cursor: 'pointer'}}
          >
            {autonegotiation && '[Autoneg] '}
            {ifc.name}
          </Label>
        }
        wide
        content={
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{'Interface Name'}</Table.Cell>
                <Table.Cell>{ifc.name || <Label>{'not assigned'}</Label>}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{'Server'}</Table.Cell>
                <Table.Cell>{serverLabel}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{'Link Label'}</Table.Cell>
                <Table.Cell>{linkLabel}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{'Endpoint'}</Table.Cell>
                <Table.Cell>
                  <Label color={color} content={title} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        }
      />
    );
  } else {
    return <InterfaceLabel {...{ifc, onClick, disabled, autonegotiation, selected}} />;
  }
}

function InterfaceLabel({ifc, autonegotiation, onClick, disabled, selected}) {
  return (
    <Label
      size='small'
      basic={!selected}
      color={!disabled && ifc.state === 'active' && ifc.speed ? ifc.speed.color : 'grey'}
      onClick={() => !disabled && onClick(ifc)}
      className={cx({disabled: disabled || ifc.state !== 'active'})}
    >
      {autonegotiation && '[Autoneg] '}
      {ifc.name}
    </Label>
  );
}
