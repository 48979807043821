import {useEffect, useMemo, useState} from 'react';
import {Accordion, Button, Icon} from 'semantic-ui-react';
import {formatDateAsLocalDateTime, formatSeconds, onEnterKeyHandler} from 'apstra-ui-common';
import {flatMap, has, includes, transform, isFinite} from 'lodash';

import {DATETIME_FIELDS, DURATION_FIELDS} from './reportFormRenderers';
import humanizeString from '../../../humanizeString';
import {handleTimeRange} from './reportUtils';

import './ReportQueryBox.less';

const ReportQueryBox = ({
  fetchDataError, children, apply, loaderVisible, filters: filtersProp,
  parameterSchema, useCombineTime,
}) => {
  const [active, setActive] = useState(false);
  const filters = useMemo(() => {
    return handleTimeRange(filtersProp, useCombineTime);
  }, [filtersProp, useCombineTime]);
  const filterDescription = useMemo(() => {
    const properties = parameterSchema?.properties || {};
    const elements = transform(filters, (result, value, propertyName) => {
      if (has(properties, propertyName)) {
        let formattedValue;
        if (includes(DATETIME_FIELDS, propertyName)) {
          if (isFinite(value)) {
            formattedValue = formatSeconds(-value) + ' ago';
          } else if (value) {
            formattedValue = formatDateAsLocalDateTime(value);
          } else {
            formattedValue = 'Now';
          }
        } else if (includes(DURATION_FIELDS, propertyName)) {
          formattedValue = formatSeconds(value);
        } else {
          formattedValue = `${value}`;
        }
        result.push(
          <span key={result.length}>
            <b>{properties[propertyName].label || humanizeString(propertyName)}</b>
            {' = '}
            {formattedValue}
          </span>
        );
      }
    }, []);
    if (elements.length) {
      return flatMap(elements, (chunk, index) => [index ? ' and ' : null, chunk]);
    } else {
      return 'All';
    }
  }, [filters, parameterSchema]);

  const show = () => {
    setActive(true);
  };
  const hide = () => {
    setActive(false);
  };
  const toggle = () => {
    setActive(!active);
  };
  const hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      hide();
    }
  };
  const onClick = () => {
    hide();
    apply();
  };

  useEffect(() => {
    if (fetchDataError) show();
  }, [fetchDataError]);

  return (
    <Accordion styled className='report-query-box'>
      <Accordion.Title
        active={active}
        onClick={toggle}
        onKeyDown={onEnterKeyHandler(toggle)}
        role='button'
        tabIndex={0}
      >
        <Icon name='dropdown' />
        {'Query: '}
        {filterDescription}
      </Accordion.Title>
      <Accordion.Content active={active}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className='ui form' onKeyDown={hideOnEscape} tabIndex={-1}>
          {children}
          <Button
            primary
            icon='refresh'
            content='Refresh'
            onClick={onClick}
            disabled={loaderVisible}
          />
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default ReportQueryBox;
