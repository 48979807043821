import {Component} from 'react';
import {values} from 'lodash';
import {DropdownInput} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import humanizeString from '../../humanizeString';

export default class StageWidgetValueColumnNameInput extends Component {
  static contextType = IBAContext;

  render() {
    const {value, selectedStageInfo: {stage}, name, schema, required, disabled, onChange} = this.props;
    const valueColumnOptions = values(stage.values).map(({name, title}) => ({
      key: name,
      value: name,
      text: title || humanizeString(name),
    }));
    return valueColumnOptions.length > 1 ? (
      <ValueColumnNameInput
        options={valueColumnOptions}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        schema={schema}
        name={name}
      />
    ) : null;
  }
}

export function ValueColumnNameInput(props) {
  return (
    <DropdownInput search fieldProps={{className: 'column-value-input'}} {...props} />
  );
}
