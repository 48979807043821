import cx from 'classnames';
import {filter, groupBy, includes, isEmpty, map, mapValues, some} from 'lodash';
import React from 'react';
import {Input, List, ListItem, ListList, Message} from 'semantic-ui-react';

import './ProcessorSelectorTree.less';

import {DescriptionTooltip} from '../../../components/DescriptionTooltip';
import IBAContext from '../../IBAContext';
import {Store} from './Store';
import ProcessorIcon from '../ProcessorIcon';
import {Card} from './Card';

type ProcessorSelectorTreeProps = {
  store: Store;
}

export const ProcessorSelectorTree: React.FC<ProcessorSelectorTreeProps> = ({store}) => {
  const {processorCategories} = React.useContext(IBAContext);
  const [searchText, setSearchText] = React.useState('');
  const deferredSearchText = React.useDeferredValue(searchText);
  const selectedRef = React.useRef<HTMLSpanElement>(null);

  const processors = React.useMemo(
    () => {
      const loweredSearchText = deferredSearchText.toLowerCase();
      const filteredProcessorDefinitions = deferredSearchText ?
        filter(store.sortedProcessorDefinitions, ({label}) => includes(label.toLowerCase(), loweredSearchText)) :
        store.sortedProcessorDefinitions;

      const processorsByType = groupBy(
        filteredProcessorDefinitions,
        (processorDefinition) => processorDefinition.category?.[0]
      );
      const processorsByTypeAndCategories = mapValues(
        processorsByType,
        (processorDefinitions) => groupBy(processorDefinitions,
          (processorDefinition) => processorDefinition.category?.[1],
        )
      );
      return processorsByTypeAndCategories;
    },
    [deferredSearchText, store.sortedProcessorDefinitions]
  );

  React.useEffect(() => {
    queueMicrotask(() => selectedRef.current?.scrollIntoView?.({
      behavior: 'instant',
      block: 'center',
    }));
  }, []);

  const isProcessorsEmpty = isEmpty(processors);
  return (
    <div className='tree-container'>
      <Input
        className='search-input'
        fluid
        icon='search'
        onChange={(e) => setSearchText(e.target.value)}
        placeholder='Search'
        value={searchText}
      />
      {isProcessorsEmpty ?
        <Message
          info
          content={(
            <>
              {'No matches for '}
              <strong>{deferredSearchText}</strong>
            </>
          )}
        />
        :
        <Card className='processor-selector-tree' withBorder={false}>
          <List>
            {map(processors, (categories, categoryName) => (
              <ListItem key={categoryName}>
                <div className='top-level'>
                  {processorCategories?.[categoryName]?.label ?? 'N/A'}
                  <DescriptionTooltip description={processorCategories?.[categoryName]?.description} />
                </div>
                <ListList>
                  {map(categories, (processors, subcategoryName) => (
                    <ListItem key={subcategoryName} >
                      <div
                        className={cx('category', {
                          fade: !some(processors, (processor) => store.availableProcessorTypes[processor.name])
                        })}
                      >
                        {processorCategories?.[categoryName]?.subcategories[subcategoryName]?.label ?? 'N/A'}
                        <DescriptionTooltip
                          description={processorCategories?.[categoryName]?.subcategories[subcategoryName]?.description}
                        />
                      </div>
                      {map(processors, (processor) => (
                        <ListItem
                          key={processor.name}
                          className={cx('processor', {
                            selected: store.processorType === processor.name,
                            fade: !store.availableProcessorTypes[processor.name]
                          })}
                          onClick={() => store.setProcessorType(processor.name)}
                        >
                          <ProcessorIcon processorType={processor.name} />
                          <span
                            ref={store.processorType === processor.name ? selectedRef : undefined}
                          >
                            {processor.label}
                          </span>
                        </ListItem>
                      ))}
                    </ListItem>
                  ))}
                </ListList>
              </ListItem>
            ))}
          </List>
        </Card>
      }
    </div>
  );
};
