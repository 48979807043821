import {useEffect, useState, useMemo, useCallback, useRef} from 'react';
import {filter, includes, map, toLower, set} from 'lodash';
import {observer} from 'mobx-react';
import pluralize from 'pluralize';
import {DropdownControl} from 'apstra-ui-common';

import {useCablingMapStore} from '../store/useCablingMapStore';
import './Search.less';

const defaultSearchFn = ({nodes, searchText}) => {
  const searchTextLowered = toLower(searchText);
  return filter(
    nodes,
    ({label, id}) => includes(toLower(label), searchTextLowered) || includes(toLower(id), searchTextLowered)
  );
};

const Search = ({nodes, searchFn = defaultSearchFn, onNodesFound, onActivateNode, readonly}) => {
  const ddlRef = useRef();
  const [searchText, setSearchText] = useState('');
  const [foundNodes, setFoundNodes] = useState([]);

  const {selection, cablingMap} = useCablingMapStore();

  const canSearch = searchText.length > 2;

  // Handler to focus on chosen node
  const focusOnNode = useCallback((nodeId) => {
    onActivateNode(nodes[nodeId]);
    cablingMap.highlightNode(nodeId);
  }, [onActivateNode, nodes]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handler to toggle node's selection
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleNodeSelection = useCallback((nodeId) => !readonly && selection.toggle(nodeId), [readonly]);

  // Update search results
  useEffect(
    () => {
      // Reset selected node from the previous search
      set(ddlRef, 'current.state.value', '');
      // Execute search and update the found nodes list
      setFoundNodes(canSearch ? searchFn({nodes, searchText, selection, readonly}) : []);
    },
    [canSearch, nodes, searchFn, searchText, selection, readonly]
  );

  const nodesCount = +foundNodes?.length;

  // Move canvas to the focused node
  useEffect(() => {
    onNodesFound?.(foundNodes);
    onActivateNode?.(nodesCount > 1 ? foundNodes[0] : null);
  }, [foundNodes, onActivateNode, onNodesFound, nodesCount]);

  // Build options from the list of found nodes (with all handlers)
  const options = useMemo(() => map(
    foundNodes,
    (node) => ({
      value: node.id,
      text: node.label,
      icon: !readonly && (
        selection.isSelected(node.id) ? 'check square outline' : 'square outline'
      ),
      onClick: () => toggleNodeSelection(node.id),
      onMouseOver: () => focusOnNode(node.id)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [foundNodes, readonly, selection.selectedNodesIds, focusOnNode]);

  return (
    <DropdownControl
      ref={ddlRef}
      className='cme-node-search'
      minCharacters={2}
      open={!!nodesCount}
      header={!!nodesCount && `${pluralize('node', nodesCount, true)} found:`}
      icon='search'
      selection
      search
      labeled
      searchQuery={searchText}
      text={searchText}
      closeOnChange={false}
      onChange={(value) => focusOnNode(value)}
      onClose={() => {
        setSearchText('');
        cablingMap.highlightNode();
      }}
      onKeyDown={({key}) => key === 'Enter' && toggleNodeSelection(ddlRef?.current?.state?.value)}
      onSearchChange={(_, {searchQuery}) => {
        cablingMap.highlightNode();
        setSearchText(searchQuery);
      }}
      options={options}
      placeholder='Node search'
    />
  );
};

export default observer(Search);
