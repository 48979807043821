import {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {Text} from '@visx/text';
import {map, truncate} from 'lodash';
import {Popup} from 'semantic-ui-react';
import {isExtenderPressed} from 'apstra-ui-common';

import {useCablingMapStore} from '../store/useCablingMapStore';
import {useTooltip, useNodeTooltip} from '../../components/graphs/GraphTooltips';
import {useTooltipData} from './TooltipDataProvider';
import {ctrlNodeWidth, ctrlNodeHeight, ctrlNodeTitleShownChars} from '../const';
import './Node.less';
import Port from './Port';

const Node = ({node, isSelected, onClick, readonly, layerColor, useLayerColor, anomaly, hideSmallDetails}) => {
  const {cablingMap, nodeTextExtractFn} = useCablingMapStore();
  const {sharedTooltip} = useTooltip();
  const {selectedLayerData, indexedNodes} = useTooltipData();
  const nodeTooltipHandler = useNodeTooltip();
  const {id, isExternal, color, position: {x, y}, systemId, isFadedOut, visibleEndpoints} = node;
  const nodeText = nodeTextExtractFn(node);
  const label = nodeText || node.label;
  const colorName = useLayerColor ? layerColor : color;

  const {highlightedId} = cablingMap;

  const classes = cx(
    'ctrl-node',
    {
      external: isExternal,
      selected: isSelected,
      'faded-out': isFadedOut,
      clickable: !!onClick || !readonly,
      readonly,
      invalid: !node.isValid,
      [`color-${colorName}`]: colorName,
      mapped: !!systemId,
      highlighted: highlightedId === id
    }
  );
  const radius = isExternal ? 0 : 4;

  const handleClick = useCallback((event, keepPropagation) => {
    if (!keepPropagation) event.stopPropagation();
    onClick?.(event, node);
  }, [node, onClick]);

  const handleRemoving = useCallback((event) => {
    event.stopPropagation(cablingMap.deleteNode(id));
  }, [cablingMap, id]);

  const handleKeyPress = useCallback((event) => {
    if (event?.key !== 'Tab' && !isExtenderPressed(event)) {
      handleClick(event, true);
    }
  }, [handleClick]);

  // Toggles node settings popup visibility
  const toggleSettings = useCallback((event) => {
    cablingMap.hidePopups(false);
    cablingMap.paintNode(node);
    event.stopPropagation();
  }, [node, cablingMap]);

  const stopPropagationHandler = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const shownLabel = useMemo(() => truncate(label, ctrlNodeTitleShownChars), [label]);

  const ports = map(visibleEndpoints, ({portPosition}, index) => (<Port key={index} {...portPosition} />));

  const actionButtons = [
    !hideSmallDetails && (
      <text
        key='settings'
        className='node-settings not-draggable'
        onClick={toggleSettings}
        onMouseDown={stopPropagationHandler}
        onMouseUp={stopPropagationHandler}
      >
        {'\uF013'}
      </text>
    ),
    !hideSmallDetails && (
      <text
        key='remove'
        className='node-remove not-draggable'
        onClick={handleRemoving}
        onMouseDown={stopPropagationHandler}
        onMouseUp={stopPropagationHandler}
      >
        {'\uF00D'}
      </text>
    ),
    !node.isValid && (
      <Popup
        key='errors'
        trigger={(
          <use className='invalid-icon' xlinkHref='#warning-sign' />
        )}
        offset={[-14, 0]}
        className='ctrl-node-errors'
      >
        {map(node.validationErrors, (error) => (
          <div key={error}>
            {error}
          </div>
        ))}
      </Popup>
    )
  ];

  return (
    <>
      <g
        className={classes}
        transform={`translate(${+x},${+y})`}
      >
        <g
          id={node.id}
          tabIndex={0}
          role='button'
          aria-label={`Node "${label}"`}
          onKeyDown={handleKeyPress}
          {...readonly ?
            {
              onClick: handleClick,
              onMouseOver: nodeTooltipHandler(Object.assign({}, selectedLayerData?.[node.id], indexedNodes[node.id])),
              onMouseOut: sharedTooltip.hide,
              onFocus: nodeTooltipHandler(Object.assign({}, selectedLayerData?.[node.id], indexedNodes[node.id])),
              onBlur: sharedTooltip.hide
            } :
            {
              onClick: handleClick,
            }
          }
        >
          <rect className='selection' x='-4' y='-4' rx='4' ry='4' />
          <rect className='container' rx={radius} ry={radius} />
          <Text
            className={cx('title', {'fallback-title': !nodeText})}
            x={ctrlNodeWidth / 2}
            y={ctrlNodeHeight / 2}
            width={ctrlNodeWidth - 20}
            textAnchor='middle'
            verticalAnchor='middle'
          >
            {shownLabel}
          </Text>
          {
            node?.systemId && !readonly &&
              <circle className='system' cx='8' cy='8' r='5' />
          }
        </g>
        {readonly && anomaly && <use className='invalid-icon' xlinkHref='#warning-sign' />}
        {!readonly && actionButtons}
      </g>
      {!hideSmallDetails && ports}
    </>
  );
};

export default observer(Node);
