import {DropdownInput, DropdownControl} from 'apstra-ui-common';

import {AGGREGATION_TYPE_UNSET} from '../consts';

const AggregationTypeInput = ({value, onChange, ...props}) => {
  const Dropdown = props.schema ? DropdownInput : DropdownControl;
  return (
    <Dropdown
      placeholder='Default aggregation'
      value={value === AGGREGATION_TYPE_UNSET ? '' : value}
      onChange={(aggregationType) => {
        onChange(aggregationType || AGGREGATION_TYPE_UNSET);
      }}
      {...props}
    />
  );
};

export default AggregationTypeInput;
