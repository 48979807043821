import {
  map, flatten, flattenDeep, isPlainObject, keys, slice, includes,
  transform, isString, find, isEmpty, forEach, has, join, filter, isArray, some,
} from 'lodash';

import PythonExpressionParser from './PythonExpressionParser';
import PythonExpressionPlainTextFormatter, {interpose} from './PythonExpressionPlainTextFormatter';
import {namedNodesFunctions} from './consts';
import {MULTIPLE_MATCHERS} from '../queryBuilder/consts';

let visitorInstance = null;

const getImageValue = (token) => {
  if (isArray(token)) return map(token, (value) => getImageValue(value));
  if (!token?.tokenType) return token;
  if (token.tokenType.name === 'NumberLiteral') return Number(token.image);
  if (/[Tt]rue|[Ff]alse/.test(token.image)) return token.image;
  if (isString(token.image)) {
    return token.image.replaceAll(/["']/g, '');
  }
  return token.image;
};

export default class GraphQueryToObjectConverter extends PythonExpressionPlainTextFormatter {
  static get instance() {
    if (!visitorInstance) visitorInstance = new this();
    return visitorInstance;
  }

  static resetState() {
    const {instance} = this;
    instance.functionNames = [];
  }

  static parse(text, options) {
    try {
      const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(text);
      if (!lexErrors.length && !parseErrors.length) return GraphQueryToObjectConverter.run(cst, options);
    } catch {}
    return {};
  }

  static convertToken(visitResult) {
    const isKeyValue = (token) => (has(token, 'name') && has(token, 'value'));
    const tokens = flattenDeep(visitResult);
    const currentToken = tokens[0];
    const name = keys(currentToken)[0];
    const result = {name};
    const path = map(
      filter(slice(tokens, 1), (token) => token?.image !== '.'),
      (token) => this.convertToken([token])
    );
    if (!isEmpty(path)) result.path = path;
    if (includes(['optional', 'match'], name)) {
      result.value = map(currentToken[name], (token) => this.convertToken(token));
    } else if (namedNodesFunctions.has(name)) {
      const valueArguments = map(currentToken[name], ([token]) => getImageValue(token));
      const isTokenTypeNumber = (token) => token?.tokenType?.name === 'NumberLiteral';
      const getHasItemsString = (values) => {
        let result = {};
        const str = join(
          map(flattenDeep(values), (token) => isString(token) ? token : token.image),
          ''
        );
        try {
          result = JSON.parse(str);
        } catch {}
        return result;
      };
      result.type = find(valueArguments, (token) => isString(token)) || '';
      result.kwargs = transform(valueArguments, (result, token) => {
        if (isPlainObject(token)) {
          const arg = {};
          if (token.name) arg.name = token.name;
          if (isPlainObject(getImageValue(token.value))) {
            arg.matcher = keys(token.value)[0];
            const value = flattenDeep(getImageValue(token.value[arg.matcher]));
            if (arg.matcher === '_or' || arg.matcher === '_and') {
              arg.value = [];
              forEach(value, (token) => {
                if (token?.image) {
                  arg.value.push({
                    mather: '=',
                    value: getImageValue(token),
                  });
                } else if (isPlainObject(token)) {
                  const matcher = keys(token)[0];
                  const value = matcher === 'has_items' ?
                    getHasItemsString(token[matcher]) :
                    MULTIPLE_MATCHERS.has(matcher) ?
                      getImageValue(flattenDeep(token[matcher])) :
                      getImageValue(flattenDeep(token[matcher]))[0];
                  arg.value.push({
                    matcher,
                    value,
                  });
                }
              });
            } else if (arg.matcher === 'has_items') {
              arg.value = getHasItemsString(flattenDeep(token.value[arg.matcher]));
            } else {
              arg.value = MULTIPLE_MATCHERS.has(arg.matcher) ?
                value : value[0];
            }
            const isTokenTypesNumber = some(flattenDeep(token.value[arg.matcher]), isTokenTypeNumber);
            if (isTokenTypesNumber) arg.type = 'number';
          } else {
            arg.value = getImageValue(token.value);
            arg.matcher = '=';
            if (isTokenTypeNumber(token.value)) arg.type = 'number';
          }
          result.push(arg);
        }
      }, []);
    } else if (name === 'having') {
      forEach(currentToken[name], (token) => {
        if (isKeyValue(token[0])) {
          const {name, value} = token[0];
          result[name] = getImageValue(value);
        } else {
          result.query = this.convertToken(token);
        }
      });
    } else if (name === 'where') {
      forEach(currentToken[name], (token) => {
        if (isKeyValue(token)) {
          result[token.name] = getImageValue(token.value);
        } else {
          result.predicate = join(map(token, (value) => isString(value) ? value : value.image), '');
        }
      });
    } else if (name === 'ensure_different') {
      result.uniqNames = flattenDeep(map(currentToken[name], (token) => getImageValue(token)));
    } else {
      forEach(currentToken[name], ([token]) => {
        if (isKeyValue(token)) {
          result[token.name] = getImageValue(token.value);
        }
      });
    }
    return result;
  }

  static run(cst) {
    this.resetState();
    return this.convertToken(this.instance.visit(cst));
  }

  functionCall(ctx) {
    const currentFunctionName = ctx.functionName[0].image;
    const lastFunctionName = this.functionNames[this.functionNames.length - 1];
    if (currentFunctionName === 'where') this.functionNames.push(currentFunctionName);
    const argumentsVisitResult = this.visit(ctx.functionArguments);
    if (currentFunctionName === 'where') this.functionNames.pop();
    if (lastFunctionName === 'where') {
      return [ctx.functionName[0], ctx.LParen[0], argumentsVisitResult, ctx.RParen[0]];
    }
    return {[currentFunctionName]: argumentsVisitResult};
  }

  functionArguments(ctx) {
    const lastFunctionName = this.functionNames[this.functionNames.length - 1];
    const allArguments = map(ctx.functionArgument, (functionArgument) => this.visit(functionArgument))
      .map(flattenDeep);
    return lastFunctionName === 'where' ?
      [interpose(allArguments, [', '])] :
      allArguments;
  }

  chainedExpression(ctx) {
    return [ctx.Dot[0], this.visit(ctx.expression)];
  }

  list(ctx) {
    return map(ctx.expression, (expression) => this.visit(expression));
  }

  keywordArgument(ctx) {
    const value = this.visit(ctx.value)[0];
    const result = {name: ctx.key[0].image};
    result.value = isArray(value) ? flatten(value) : value;
    return result;
  }
}
