import {
  transform, sortBy, last, filter, map, isUndefined, uniq, isEmpty, includes, compact, keys, isString, get
} from 'lodash';
import moment from 'moment';
import {LINE_MODE, brandColorNames} from 'apstra-ui-common';

export const transformData = (samples) => {
  if (samples?.length === 0) {
    return {};
  }
  const sortedSamples = sortBy(samples, ({timestamp}) => moment(timestamp));
  const samplesByItems = transform(sortedSamples, (acc, sample) => {
    const sampleUniqueKey = JSON.stringify({
      anomaly_type: sample.anomaly_type,
      ...sample.identity,
    });
    acc[sampleUniqueKey] = [...(acc[sampleUniqueKey] || []), sample];
  }, {});
  return samplesByItems;
};

export const recreateState = (samples, endDate = Date.now()) => {
  return transform(samples, (acc, stateChanges, itemId) => {
    const filteredChanges = filter(
      stateChanges,
      ({timestamp}) => moment(timestamp).isSameOrBefore(moment(endDate))
    );
    if (filteredChanges.length > 0 && last(filteredChanges).raised) {
      const anomalyType = JSON.parse(itemId).anomaly_type;
      acc.push({
        ...last(filteredChanges),
        anomaly_type: anomalyType,
        history: stateChanges,
        uniqueId: itemId,
      });
    }
  }, []);
};

export const mergeValues = (expectedValues, actualValues) => {
  const actualCopy = {...actualValues};
  const result = transform(expectedValues, (acc, expectedValue, expectedKey) => {
    const actualValue = actualCopy[expectedKey];
    acc.push({
      name: expectedKey,
      expected: expectedValue,
      actual: actualValue || null,
    });
    if (!isUndefined(actualValue)) {
      delete actualCopy[expectedKey];
    }
  }, []);
  if (Object.keys(actualCopy).length > 0) {
    map(actualCopy, (actualValue, actualKey) => {
      result.push({
        name: actualKey,
        expected: null,
        actual: actualValue,
      });
    });
  }
  return result;
};

// use excludedTypes here because `allSamples` doesn't always contains all possible
// anomaly types which affects the color schema (colors can change after auto refetch
// if a new anomaly type appears for the very first time)
export const getMLCValues = (allSamples, valueKey, excludedTypes = [], allAnomalyTypes) => {
  let minValue = 0;
  let maxValue = 0;
  const colors = compact(
    map(allAnomalyTypes, (type, id) => !includes(excludedTypes, type) && brandColorNames[id])
  );
  return {
    data: sortBy(
      transform(allSamples, (acc, anomalySamples, anomalyType) => {
        // TODO (vkniazeva): temp keep this "if" due to API for current state doesn't support filters
        if (!includes(excludedTypes, anomalyType)) {
          acc.push({
            items: sortBy(
              anomalySamples,
              (sample) => {
                if (sample[valueKey] < minValue) {
                  minValue = sample[valueKey];
                }
                if (sample[valueKey] > maxValue) {
                  maxValue = sample[valueKey];
                }
                return moment(sample.timestamp);
              }),
            data: {anomalyType: anomalyType},
            lineMode: LINE_MODE.LINES,
          });
        }
      }, []),
      ({data: {anomalyType}}) => anomalyType
    ),
    colors,
    minValue,
    maxValue,
  };
};

export const transformRetentionData = (data, labelFn) => {
  if (isEmpty(data)) {
    return {items: {}, totalSize: null, commonPeriod: null};
  }
  let allRetentionPeriods = [];
  let totalSize = 0;
  const items = transform(sortBy(keys(data)), (acc, type) => {
    acc[labelFn(type) ?? type] = data[type];
    totalSize += data[type].size;
    allRetentionPeriods.push(data[type].retention_period);
  }, {});
  allRetentionPeriods = uniq(allRetentionPeriods);
  return {
    items,
    totalSize,
    commonPeriod: allRetentionPeriods.length === 1 ? allRetentionPeriods[0] : null,
  };
};

// NOTE: this filter fn is able to process only basic filtering (string or string/regexp)
// as it is made as a temporary solution for the lack of filters in API request.
// As soon as the BE adds filters, this should be deleted
export const filterCurrentState = (filters, items) => {
  if (items.length === 0) {
    return [];
  }
  if (!filters) {
    return items;
  }

  return filter(items, (item) => {
    const results = map(filters, (filterData, path) => {
      if (isString(filterData)) {
        return item[path] === filterData;
      } else if (filterData.type === 'text') {
        return get(item, path) === filterData.value;
      } else if (filterData.type === 'regex') {
        const regExp = new RegExp(filterData.value);
        return regExp.test(get(item, path));
      } else {
        return false;
      }
    });
    return compact(results).length === keys(filters).length;
  });
};
