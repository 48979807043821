import {createContext, useContext, useMemo} from 'react';
import {keyBy} from 'lodash';

import {observer} from 'mobx-react';

const TooltipDataContext = createContext();

export const TooltipDataProvider = observer(({nodes, links, aggregateLinks, children,
  selectedLayerData}) => {
  const {indexedNodes, indexedLinks} = useMemo(() => ({
    indexedNodes: keyBy(nodes, 'id'),
    indexedLinks: keyBy([...links, ...aggregateLinks], 'id'),
  }), [nodes, links, aggregateLinks]);

  return (
    <TooltipDataContext.Provider
      value={{
        indexedNodes,
        indexedLinks,
        selectedLayerData
      }}
    >
      {children}
    </TooltipDataContext.Provider>
  );
});

export const useTooltipData = () => {
  return useContext(TooltipDataContext);
};

export default TooltipDataProvider;
