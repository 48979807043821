import {observer} from 'mobx-react';
import {LinePath} from '@visx/shape';
import {curveBasis} from '@visx/curve';
import cx from 'classnames';

import {linkPropTypes} from './shared-prop-types';
import {STATE_CLASSES, STATUS_CLASSES} from './constants';

import {useLinkTooltip, useTooltip} from '../../GraphTooltips';

import './Link.less';

const Link = observer((props) => {
  const {sharedTooltip} = useTooltip();
  const linkTooltipHandler = useLinkTooltip();
  const {
    state, status, coordinates, className,
    sourceLink, selected, hovered
  } = props;
  const stateClass = STATE_CLASSES[state];
  const statusClass = STATUS_CLASSES[status];
  const classes = cx('link', stateClass, statusClass, className, {selected: hovered ? false : selected,
    hovered: hovered});

  return (
    <g
      onMouseOver={linkTooltipHandler(sourceLink)}
      onMouseOut={sharedTooltip.hide}
    >
      <LinePath
        className='link-background'
        data={coordinates}
        x={({x}) => x}
        y={({y}) => y}
        curve={curveBasis}
      />
      <LinePath
        className={classes}
        data={coordinates}
        x={({x}) => x}
        y={({y}) => y}
        curve={curveBasis}
      />
    </g>
  );
});

Link.propTypes = linkPropTypes;

export default Link;
