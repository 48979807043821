import {isString} from 'lodash';

import {Markdown} from './Markdown';

export default function MultiParagraphText({asMarkdown = false, text = ''}) {
  if (!isString(text)) return null;
  if (asMarkdown) {
    return (
      <Markdown>
        {text}
      </Markdown>
    );
  }

  return (
    <>
      {text.split(/\n{2,}/).map((paragraph, index) =>
        <p key={index}>{paragraph}</p>
      )}
    </>
  );
}
