import {useState, useRef} from 'react';
import {Message, Button, Popup} from 'semantic-ui-react';
import {useResizeDetector} from 'react-resize-detector';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {max} from 'lodash';

import Visualizer from './Visualizer';
import './Canvas.less';
import {ReactComponent as Definitions} from '../../../styles/icons/ct/definitions.svg';

const Canvas = ({
  ept, showIntro, readonly, comparisonEpt, isComparisonDirect,
  onlyShowDifferent = false
}) => {
  const canvasRef = useRef();
  const [eptTooltip, showEptTooltip] = useState();
  const {height: containerHeight, ref} = useResizeDetector();

  const [left, top, width, height] = ept.canvasSize;
  const visibleWidth = width;
  const visibleHeight = max([containerHeight - 10, height]);

  let popupPos = null;
  if (eptTooltip && canvasRef.current) {
    const pt = canvasRef.current.createSVGPoint();
    pt.x = eptTooltip.position.x; pt.y = eptTooltip.position.y;
    popupPos = pt.matrixTransform(canvasRef.current.getCTM());
  }

  const isIntroVisible = showIntro && !readonly && ept?.switchSidePanelTab;

  const classNames = cx('ct-canvas', {
    'different-only': onlyShowDifferent,
    diff: !!comparisonEpt,
    intro: isIntroVisible
  });

  return (
    <div ref={ref} className={classNames}>
      {
        isIntroVisible ?
          <EmptyCtActions ept={ept} /> :
          <svg
            ref={canvasRef}
            width={visibleWidth}
            height={visibleHeight}
            viewBox={`${left} ${top} ${visibleWidth} ${visibleHeight}`}
            xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
            version='1.2'
            baseProfile='full'
            id={`ct-canvas${readonly ? '-readonly' : ''}`}
            onMouseDown={() => ept.setActivePrimitive(null)}
          >
            <Definitions />
            <Visualizer {...{ept, readonly, showTooltip: showEptTooltip, comparisonEpt, isComparisonDirect}} />
          </svg>
      }
      {popupPos &&
        <Popup
          context={canvasRef.current}
          offset={[popupPos.x, -popupPos.y + 5]}
          size='tiny'
          position='top left'
          pinned
          open
        >
          <Popup.Header>{`Type: ${eptTooltip.schemaLabel}`}</Popup.Header>
          <Popup.Content>{eptTooltip.title}</Popup.Content>
        </Popup>
      }
    </div>
  );
};

export default observer(Canvas);

const EmptyCtActions = observer(({ept}) => {
  return (
    <Message info>
      <Message.Header>{'You have started blank Connectivity Template creation'}</Message.Header>
      <p>{'Please select one of the possible options to proceed with CT building:'}</p>
      <table>
        <tbody>
          <tr>
            <td>
              <Button
                primary
                onClick={() => ept.switchSidePanelTab(1)}
                content='Primitives'
              />
            </td>
            <td>{'Select primitive to use'}</td>
          </tr>
          <tr>
            <td>
              <Button
                primary
                onClick={() => ept.switchSidePanelTab(2)}
                content='User-defined'
              />
            </td>
            <td>
              {
                'Re-use user-defined Connectivity Template (all primitives it consists of will be ' +
                'added to the current one)'
              }
            </td>
          </tr>
          <tr>
            <td>
              <Button
                primary
                onClick={() => ept.switchSidePanelTab(3)}
                content='Pre-defined'
              />
            </td>
            <td>
              {
                'Re-create Connectivity Template based on a pre-defined template'
              }
            </td>
          </tr>
        </tbody>
      </table>
    </Message>
  );
});
