import {Popup, Table, Label} from 'semantic-ui-react';
import {find, findKey, times} from 'lodash';
import cx from 'classnames';
import {onEnterKeyHandler} from 'apstra-ui-common';

import './PortMap.less';

export default function PortMap({
  panel: {id: panelId, panel_layout: {row_count: rows, column_count: columns}},
  policy, interfaceMap, interfaces = {}, onInterfaceClick
}) {
  return (
    <div className='interface-policies-port-map port-map'>
      <table role='grid'>
        <tbody>
          {times(rows, (row) =>
            <tr key={row} role='row'>
              {times(columns, (column) => {
                const portId = column * rows + row + 1;
                const ifc = interfaceMap && find(interfaceMap.interfaces,
                  ({mapping}) => mapping[3] === panelId && mapping[4] === portId
                );
                const assignmentId = ifc && findKey(interfaces, {if_name: ifc.name});
                const assignment = assignmentId && interfaces[assignmentId];

                const assignedToCurrentPolicy = assignment && assignment.policy_id === policy.id;
                const assignedToAnotherPolicy = assignment && !assignedToCurrentPolicy &&
                  assignment.policy_id !== null;

                const canClick = assignmentId && !assignedToAnotherPolicy && onInterfaceClick;

                return (
                  <Popup
                    key={portId}
                    trigger={
                      <td
                        className={cx('endpoint', {
                          teal: assignedToCurrentPolicy,
                          violet: assignedToAnotherPolicy,
                          grey: !assignment
                        })}
                        role='gridcell'
                        tabIndex={portId}
                        onClick={canClick && (() => onInterfaceClick(assignmentId))}
                        onKeyDown={canClick && onEnterKeyHandler(onInterfaceClick, assignmentId)}
                      >
                        {portId}
                      </td>
                    }
                    wide
                    content={
                      <Table definition>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>{'Interface Name'}</Table.Cell>
                            <Table.Cell>{ifc && ifc.name || 'N/A'}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{'Assigned Policy'}</Table.Cell>
                            <Table.Cell>
                              {!assignment || assignment.policy_id === null ?
                                <Label size='mini'>{'Not assigned'}</Label>
                              : assignedToCurrentPolicy ?
                                policy.label
                              :
                                assignment.policy_label || assignment.policy_id
                              }
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    }
                  />
                );
              })}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
