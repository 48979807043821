import {map} from 'lodash';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {Popup} from 'semantic-ui-react';
import {keyPressClick} from 'apstra-ui-common';

import DeviceProfiles from './DeviceProfiles';

import './LogicalDevices.less';

const LogicalDevices = ({relationships, logicalDevices, spine, ldOnClick, dpOnClick}) => {
  return map(logicalDevices, (logicalDevice) => <LogicalDevice
    key={logicalDevice?.id}
    logicalDevice={logicalDevice}
    relatedDevices={relationships[logicalDevice?.id]}
    spine={spine}
    ldOnClick={ldOnClick}
    dpOnClick={dpOnClick}
  />);
};

export default observer(LogicalDevices);

const LogicalDevice = ({logicalDevice, relatedDevices, spine, ldOnClick, dpOnClick}) => {
  const {display_name: label} = logicalDevice;
  const className = cx('logical-device-structure', {[spine]: spine});
  const interactiveProps = ldOnClick ?
    {
      tabIndex: 0,
      role: 'button',
      onClick: () => ldOnClick(logicalDevice),
      onKeyDown: keyPressClick(ldOnClick, logicalDevice)
    } : {};
  return (
    <div className={className}>
      <div className='logical-device arrowed'>
        <Popup
          position='top center'
          trigger={<label {...interactiveProps}>{label}</label>}
        >
          {label}
        </Popup>
      </div>
      <div className='device-profiles'>
        <DeviceProfiles deviceProfiles={relatedDevices} dpOnClick={dpOnClick} />
      </div>
    </div>
  );
};
