import {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {map, get, keys, find, head, size, isObject} from 'lodash';
import HTTPStatus from 'http-status';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {FetchDataError, GenericErrors, ResourceDeletionModal, interpolateRoute, request} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

@observer
export default class ProbeDeletionModal extends Component {
  static contextType = IBAContext;

  static propTypes = {
    open: PropTypes.bool,
    probes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })),
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get firstProbeId() {
    return get(head(this.props.probes), ['id']);
  }

  @computed
  get batchDeletion() {
    return size(this.props.probes) > 1;
  }

  getProbeLabel = (probeId) => {
    return get(find(this.props.probes, {id: probeId}), ['label'], probeId);
  };

  deleteProbe = () => request(
    interpolateRoute(this.context.routes.probeDetails,
      {blueprintId: this.context.blueprintId, probeId: this.firstProbeId}
    ),
    {method: 'DELETE'}
  );

  deleteProbes = () => request(
    interpolateRoute(this.context.routes.probesBatchDelete, {blueprintId: this.context.blueprintId}),
    {method: 'POST', body: JSON.stringify(map(this.props.probes, 'id')), throwOnHttpStatus: [HTTPStatus.MULTI_STATUS]}
  );

  submit = () => {
    return this.batchDeletion ? this.deleteProbes() : this.deleteProbe();
  };

  renderDeleteError = ({probeId, label, message, dashboards}) => {
    const {blueprintId} = this.context;

    const title = (
      <Fragment>
        {'Probe '}
        <b>
          <Link to={`/blueprints/${blueprintId}/analytics/probes/${probeId}`} style={{color: 'inherit'}}>{label}</Link>
        </b>
        {' was not deleted'}
      </Fragment>
    );

    return (
      <GenericErrors header={title} errors={message}>
        {map(dashboards, ({id, label}, index) =>
          <div key={index}>
            <Link to={`/blueprints/${blueprintId}/analytics/dashboards/${id}`}>{label}</Link>
          </div>
        )}
      </GenericErrors>
    );
  };

  renderBatchDeleteError = (errors) => {
    const {renderBatchDeleteProbeError, getProbeLabel} = this;

    const probeIds = keys(errors);

    if (probeIds.length === 1) {
      const firstProbeId = probeIds[0];
      return this.renderDeleteError({
        probeId: firstProbeId,
        label: getProbeLabel(firstProbeId),
        dashboards: errors[firstProbeId].dashboards,
        message: errors[firstProbeId].message
      });
    }

    return (
      <GenericErrors
        header='Some probes were not deleted'
        errors={probeIds.map((probeId) =>
          renderBatchDeleteProbeError({
            probeId,
            label: getProbeLabel(probeId),
            message: errors[probeId].message,
            dashboards: errors[probeId].dashboards
          }))}
      />
    );
  };

  renderBatchDeleteProbeError = ({probeId, label, message, dashboards}) => {
    const {context: {blueprintId}} = this;
    return (
      <div>
        <b><Link to={`/blueprints/${blueprintId}/analytics/probes/${probeId}`}>{label}</Link></b>
        <div>{message}</div>
        {map(dashboards, ({id, label}, index) =>
          <div key={index}>
            <Link to={`/blueprints/${blueprintId}/analytics/dashboards/${id}`}>{label}</Link>
          </div>
        )}
      </div>
    );
  };

  renderError = (error, props) => {
    const errorCanBeRendered = error.responseBody && (
      this.batchDeletion ? isObject(error.responseBody.errors) : error.responseBody.dashboards
    );

    if (!errorCanBeRendered) {
      return <FetchDataError error={error} {...props} />;
    }

    if (!this.batchDeletion) {
      return this.renderDeleteError({
        probeId: this.firstProbeId,
        label: this.getProbeLabel(this.firstProbeId),
        message: error.responseBody.errors,
        dashboards: error.responseBody.dashboards
      });
    }

    return this.renderBatchDeleteError(error.responseBody.errors);
  };

  render() {
    const {batchDeletion, props: {probes}} = this;
    const probeLabels = map(probes, 'label');

    return (
      <ResourceDeletionModal
        resourceLabel={probeLabels[0]}
        resourceLabels={probeLabels}
        resourceName='Probe'
        resourceNamePlural='Probes'
        submit={this.submit}
        renderError={this.renderError}
        batchDeletion={batchDeletion}
        disableResubmission
        {...this.props}
      />
    );
  }
}
