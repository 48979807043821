import {Component, Fragment} from 'react';
import {get, map} from 'lodash';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {FetchDataError, GenericErrors, ResourceDeletionModal} from 'apstra-ui-common';

import {deleteServiceRegistry} from '../telemetryServiceRegistryUtils';

export default class ServiceSchemaDeletionModal extends Component {
  static propTypes = {
    onSuccess: PropTypes.func,
    serviceRegistry: PropTypes.object,
  };

  submit = () => {
    const {serviceRegistry} = this.props;
    return deleteServiceRegistry(serviceRegistry);
  };

  renderError = (error, props) => {
    const {serviceRegistry} = this.props;
    const errorCanBeRendered = error.responseBody && (!!error.responseBody.probes || !!error.responseBody.systems);
    if (!errorCanBeRendered) {
      return <FetchDataError error={error} {...props} />;
    }

    const serviceName = get(serviceRegistry, ['service_name'], '');
    const title = (
      <Fragment>
        {'Service Schema '}
        <b>
          <a href={`/#/devices/telemetry-service-registry/${serviceName}`} style={{color: 'inherit'}}>
            {serviceName}
          </a>
        </b>
        {' was not deleted'}
      </Fragment>
    );
    const systems = map(error.responseBody.systems, (systemId, index) =>
      <span key={index}>
        <a href={`/#/devices/systems/${systemId}`}>{systemId}</a>
        {(index + 1) !== error.responseBody.systems.length && ', '}
      </span>
    );
    const probes = map(error.responseBody.probes, ({
      blueprint_id: blueprintId,
      probe_id: probeId,
      label,
    }, index) =>
      <span key={index}>
        <a href={`/#/blueprints/${blueprintId}/analytics/probes/${probeId}`}>{label}</a>
        {(index + 1) !== error.responseBody.probes.length && ', '}
      </span>
    );

    return (
      <GenericErrors header={title} errors={error.responseBody.errors}>
        <Fragment>
          {systems.length ?
            <p>
              {pluralize('System', systems.length)}
              {': '}
              {systems}
            </p> :
            null
          }
          {probes.length ?
            <p>
              {pluralize('Probe', systems.length)}
              {': '}
              {probes}
            </p> :
            null
          }
        </Fragment>
      </GenericErrors>
    );
  };

  render() {
    const {serviceRegistry, ...props} = this.props;

    return (
      <ResourceDeletionModal
        resourceName='Service Schema'
        resourceLabel={get(serviceRegistry, ['service_name'])}
        submit={this.submit}
        renderError={this.renderError}
        disableResubmission
        {...props}
      />
    );
  }
}
