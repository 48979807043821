import {map} from 'lodash';

import {TooltipItem} from './TooltipItem';
import {Tags} from './ValueRenderers';
import {interfaceTooltipInfoPropTypes} from '../Neighbors/components/shared-prop-types';

function adminStateName(operationState) {
  let adminState;
  switch (operationState) {
  case 'up': adminState = 'Up'; break;
  case 'admin_down': adminState = 'Admin Down'; break;
  case 'deduced_down': adminState = 'Deduced Down'; break;
  }
  return adminState;
}

export const InterfaceTooltipContent =
({label, ipv4Addr, ipv6Addr, speed, tags, CT, operation_state: operationState}) => {
  return (
    <>
      <strong>{'Interface'}</strong>
      <TooltipItem label='Label' value={label} />
      <TooltipItem hideEmpty label='IPv4 address' value={ipv4Addr} />
      <TooltipItem hideEmpty label='IPv6 address' value={ipv6Addr} />
      <TooltipItem hideEmpty label='Speed' value={speed} />
      <TooltipItem hideEmpty label='Admin state' value={adminStateName(operationState)} />
      {!!CT?.length &&
        <TooltipItem label='Applied CTs' value={map(CT, 'label').join(', ')} />
      }
      {!!tags?.length && <TooltipItem label='Tags' value={<Tags tags={tags} />} />}
    </>
  );
};

InterfaceTooltipContent.propTypes = interfaceTooltipInfoPropTypes;
