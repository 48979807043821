import {useEffect, useMemo, useState} from 'react';
import {filter, isArray, isEmpty, isPlainObject, keys, map, omit, sortBy, transform} from 'lodash';
import {Accordion, Icon} from 'semantic-ui-react';
import cx from 'classnames';
import {FormattedJSON} from 'apstra-ui-common';

export const DeviceContextTree = ({tree, isSearch, isRoot}) => {
  const showLabelKey = !isArray(tree);
  const [openByKeys, setOpenByKeys] = useState({});

  const toggleAccordion = (key) => {
    const update = {...openByKeys};
    update[key] = !update[key];
    setOpenByKeys(update);
  };

  const treeKeys = useMemo(() => {
    return sortBy(filter(keys(tree), (key) =>
      (isPlainObject(tree[key]) || isArray(tree[key])) && !isEmpty(tree[key])));
  }, [tree]);
  const flattenedKeys = useMemo(() => {
    return sortBy(keys(omit(tree, treeKeys)));
  }, [tree, treeKeys]);

  useEffect(() => {
    const update = isSearch ?
      transform(treeKeys, (result, key) => {
        result[key] = true;
      }, {}) :
      {};
    setOpenByKeys(update);
  }, [isSearch, treeKeys]);

  return (
    <>
      {map(treeKeys, (key) => (
        <Accordion key={key}>
          <Accordion.Title
            active={openByKeys[key]}
            onClick={() => toggleAccordion(key)}
          >
            <LeafLabel dataKey={key} label={showLabelKey ? key : null} active={openByKeys[key]} value={tree[key]} />
          </Accordion.Title>
          <Accordion.Content active={openByKeys[key]}>
            <DeviceContextTree tree={tree[key]} isSearch={isSearch} />
          </Accordion.Content>
          {openByKeys[key] && <pre className='__json-pretty__'>{isPlainObject(tree[key]) ? ' }' : ' ]'}</pre>}
        </Accordion>
      ))}
      {map(flattenedKeys, (key) => (
        <div key={key} data-key={key} className={cx('flattened-keys', {root: isRoot})}>
          {showLabelKey &&
            <>
              {key}
              {': '}
            </>
          }
          <FormattedJSON json={JSON.stringify(tree[key])} />
        </div>
      ))}
    </>
  );
};

const LeafLabel = ({label, active, value, dataKey}) => {
  const isObject = isPlainObject(value);
  const lBracket = isObject ? '{' : '[';
  const rBracket = isObject ? '}' : ']';
  return (
    <div data-key={dataKey}>
      <Icon name='dropdown' />
      {label}
      <pre className={cx('__json-pretty__', {closed: !active})}>
        {active ? ` ${lBracket}` : ` ${lBracket} ... ${rBracket}`}
      </pre>
    </div>
  );
};
