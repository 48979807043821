import PropTypes from 'prop-types';

import {NODE_ALIGN} from '../constants';
import {statePropTypes, statusPropTypes} from '../shared-prop-types';

const nodeAlignPropTypes = PropTypes.oneOf([NODE_ALIGN.LEFT, NODE_ALIGN.RIGHT]);

const coordinates = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
});

export const interfaceTooltipInfoPropTypes = {
  label: PropTypes.string.isRequired,
  ipv4Addr: PropTypes.string,
  ipv6Addr: PropTypes.string,
  speed: PropTypes.string,
  CT: PropTypes.array,
  operation_state: PropTypes.string,
  tags: PropTypes.array
};

export const linkPropTypes = {
  state: statePropTypes.isRequired,
  status: statusPropTypes.isRequired,
  coordinates: PropTypes.arrayOf(coordinates).isRequired,
  nodeInterfaceInfo: PropTypes.shape(interfaceTooltipInfoPropTypes).isRequired,
  neighborInterfaceInfo: PropTypes.shape(interfaceTooltipInfoPropTypes).isRequired,
  className: PropTypes.string,
};

export const interfacePropTypes = {
  height: PropTypes.number.isRequired,
  marginBottom: PropTypes.number.isRequired,
  marginTop: PropTypes.number,

  label: PropTypes.string,
  state: statePropTypes.isRequired,
  status: statusPropTypes.isRequired,
  ipv4Addr: PropTypes.string,
  ipv6Addr: PropTypes.string,
  className: PropTypes.string,
};

export const nodePropTypes = {
  align: nodeAlignPropTypes,
  marginBottom: PropTypes.number,
  interfaceWidth: PropTypes.number.isRequired,

  hasOnClickNodeHandler: PropTypes.bool.isRequired,
  onClickNode: PropTypes.func,

  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hostname: PropTypes.string,
  status: statusPropTypes.isRequired,
  interfaces: PropTypes.arrayOf(
    PropTypes.shape(interfacePropTypes)
  ).isRequired
};

export const aggregateLinkPropTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  rx: PropTypes.number.isRequired,
  ry: PropTypes.number.isRequired,
};
