import {Component} from 'react';
import {Link} from 'react-router-dom';
import {Table} from 'semantic-ui-react';
import {isEmpty, map} from 'lodash';

import generateProbeURI from '../generateProbeURI';
import IBAContext from '../IBAContext';

export default class ProcessorInputs extends Component {
  static contextType = IBAContext;

  render() {
    const {blueprintId} = this.context;
    const {probe, processor, highlightStage} = this.props;

    if (isEmpty(processor.inputs)) {
      return null;
    }

    return (
      <Table celled size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'>{'Inputs'}</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell collapsing>{'Input Name'}</Table.HeaderCell>
            <Table.HeaderCell>{'Stage Name'}</Table.HeaderCell>
            <Table.HeaderCell>{'Column Name'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {map(processor.inputs, ({stage: value, column}, key) =>
            <Table.Row key={key}>
              <Table.Cell>{key}</Table.Cell>
              <Table.Cell>
                <Link
                  to={generateProbeURI({blueprintId, probeId: probe.id, stageName: value})}
                  onMouseEnter={() => highlightStage(value, true)}
                  onMouseLeave={() => highlightStage(value, false)}
                  onClick={() => highlightStage(value, false)}
                >
                  {value}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {column}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }
}
