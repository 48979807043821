import {clamp} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {Input} from 'semantic-ui-react';

// Number Input control that allows value management with both
// mouse wheel and keyboard. At the same time value is never unset.
const NumberInput = ({value, min = 0, max, onChange, ...props}) => {
  const [tempValue, setTempValue] = useState(value);

  const definedProps = {
    ...(min !== undefined ? {min} : {}),
    ...(max !== undefined ? {max} : {}),
  };

  const changeHandler = useCallback(
    (event, {value}) => {
      const newValue = value ?
        clamp(value, min, max) :
        (value === 0 ?
          (value < min ? min : value) :
          ''
        );
      setTempValue(newValue);
      onChange(null, {value: newValue === '' ? min : +newValue});
    },
    [min, max, onChange]
  );

  useEffect(
    () => {
      if (tempValue !== '') setTempValue(value);
    },
    [value, tempValue, setTempValue]
  );

  return (
    <Input
      type='number'
      value={tempValue}
      onChange={changeHandler}
      placeholder={value}
      {...definedProps}
      {...props}
    />
  );
};

export default NumberInput;
