import {ActionsMenu, FetchData, interpolateRoute, request} from 'apstra-ui-common';
import {useContext, useState} from 'react';
import {Grid, Message, Icon, Table} from 'semantic-ui-react';
import {map} from 'lodash';

import IBAContext from '../../IBAContext';
import ReportModal from './ReportModal';
import {PREDEFINED_PROBE_SCHEMA} from './const';
import PredefinedProbeList from './PredefinedProbeList';
import PredefinedProbeModal from '../PredefinedProbeModal';

const fetchData = async ({blueprintId, routes, signal}) => {
  const [{items: predefinedReports}, {items: probeList}] = await Promise.all([
    request(interpolateRoute(routes.predefinedProbeReportList, {blueprintId}), {signal}),
    request(interpolateRoute(routes.probeList, {blueprintId}), {signal}),
  ]);
  return {predefinedReports, probeList};
};

const REPORT_PAGE_URL = '/blueprints/<blueprint-id>/analytics/reports/<report-name>';

const ReportList = () => {
  const {blueprintId, routes, predefinedProbes: predefinedProbesSchema} = useContext(IBAContext);
  const [reportModalProps, setReportModalProps] = useState({open: false});

  const onGenerate = ({name, description}) => {
    const reportUrl = interpolateRoute(REPORT_PAGE_URL, {blueprintId, reportName: name});
    setReportModalProps({open: true, description, reportUrl, blueprintId, reportName: name});
  };

  const [predefinedProbeModalProps, setPredefinedProbeModalProps] = useState(false);
  const openPredefinedProbeModal = (predefinedProbeName) => {
    setPredefinedProbeModalProps({open: true, defaultPredefinedProbeName: predefinedProbeName});
  };

  return (
    <FetchData
      fetchData={fetchData}
      fetchParams={{blueprintId, routes}}
      pollingInterval={null}
    >
      {({predefinedReports, probeList}) => {
        return (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Message info icon>
                    <Icon name='info circle' />
                    <Message.Content>
                      <p>{'Reports are accessible when:'}</p>
                      <Message.List
                        items={[
                          'All associated probes have been instantiated',
                          'Run time data has been correctly populated in the probe stages',
                          'Historical data has been stored on disk via Metric Logging',
                        ]}
                      />
                    </Message.Content>
                  </Message>
                  <Table celled role='table'>
                    <Table.Header>
                      <Table.Row role='row'>
                        <Table.HeaderCell role='columnheader'>{'Name'}</Table.HeaderCell>
                        <Table.HeaderCell role='columnheader' width={6}>{'Description'}</Table.HeaderCell>
                        <Table.HeaderCell role='columnheader' width={7}>
                          {'Required Predefined Probes'}
                        </Table.HeaderCell>
                        <Table.HeaderCell role='columnheader' collapsing>{'Actions'}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {map(predefinedReports, (
                        {name, label, description, ...reportSchema},
                      ) => (
                        <Table.Row key={name} role='row'>
                          <Table.Cell>{label || name}</Table.Cell>
                          <Table.Cell>{description}</Table.Cell>
                          <Table.Cell>
                            <PredefinedProbeList
                              schema={reportSchema[PREDEFINED_PROBE_SCHEMA]}
                              predefinedProbesSchema={predefinedProbesSchema}
                              predefinedProbes={reportSchema.predefined_probes}
                              probeList={probeList}
                              blueprintId={blueprintId}
                              openPredefinedProbeModal={openPredefinedProbeModal}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <ActionsMenu
                              items={[
                                {
                                  icon: 'apstra-icon apstra-icon-report',
                                  title: 'Generate Report',
                                  onClick: () => onGenerate({name, description}),
                                },
                              ]}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ReportModal {...reportModalProps} onClose={() => setReportModalProps({open: false})} />
            <PredefinedProbeModal
              open={false}
              onClose={() => setPredefinedProbeModalProps({open: false})}
              {...predefinedProbeModalProps}
            />
          </>
        );
      }}
    </FetchData>
  );
};

export default ReportList;
