import {useCallback} from 'react';
import {Input} from 'semantic-ui-react';
import isNumeric from 'is-number';
import {CodeEditorControl} from 'apstra-ui-common';

export default function IntegerExpressionControl({type, value, onChange, knownPythonExpressionVariables, ...props}) {
  const onChangeWithNumberCheck = useCallback((value) => {
    const newValueAsNumber = Number(value);
    onChange((isNumeric(value) && String(newValueAsNumber) === value) ? newValueAsNumber : value);
  }, [onChange]);

  return type === 'expression' ?
    <CodeEditorControl
      {...props}
      mode='python-expression'
      enableCompletion
      completerParams={{knownVariables: knownPythonExpressionVariables}}
      value={String(value ?? '')}
      onChange={onChangeWithNumberCheck}
    />
  :
    <Input
      {...props}
      type='number'
      value={value}
      onChange={(e, {value}) => onChangeWithNumberCheck(value)}
    />;
}
