import {useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {List, Tab, Message, Divider} from 'semantic-ui-react';
import {castArray, compact, escapeRegExp, intersection} from 'lodash';
import cx from 'classnames';
import {FuzzySearchBox} from 'apstra-ui-common';

import {useStore} from '../store/useStore';
import Ept from '../store/Ept';
import {beautifyTitle, isNotVnType} from '../utils';
import {serviceEpts} from '../Sausager';
import {eptMaxSize} from '../settings';

import './Catalog.less';

const Catalog = ({items, sausager, isPrimitive = false, isPredefined = false, vnOnly}) => {
  const [filter, setFilter] = useState('');
  const {activeEpt} = useStore();

  // Memoize regexp search criterion
  const filterRegex = useMemo(
    () => new RegExp(escapeRegExp(filter), 'i'),
    [filter]
  );

  // Identify primitives stackable over the selected one
  let acceptedInputTypes = [];
  if (isPrimitive && activeEpt.activePrimitiveId) {
    acceptedInputTypes = activeEpt.epts[activeEpt.activePrimitiveId]?.outputTypes;
  }

  const itemsFiltered = compact(castArray(items))
    .map((item) => {
      const content = item.description;
      let isVisible;
      let isEmpty = false;
      let title = item?.label ?? item.name;
      const type = item.name;
      let highlight = false;
      const ins = item.input_node_type;
      const out = item.output_node_type;

      if (isPredefined) {
        isVisible = true;
      } else if (isPrimitive) {
        isVisible = !serviceEpts.includes(item.name);
        title = item.label || beautifyTitle(item.name);
        const inputTypes = ins.includes('any') ? acceptedInputTypes : ins;
        highlight = !!(intersection(acceptedInputTypes, inputTypes).length);
      } else {
        const {isSausage} = sausager.deserializeUserData(item.user_data);
        isVisible = item.visible && isSausage;
        isEmpty = (item?.attributes?.subpolicies ?? []).length === 0;
      }
      return {
        title,
        type,
        content,
        ins: ins?.join(', '),
        out,
        isVisible,
        isEmpty,
        item,
        highlight
      };
    })
    .filter(({isVisible, title, content}) => {
      if (isVisible) {
        return !filter || (filterRegex.test(title) || filterRegex.test(content));
      }
      return false;
    });

  const addToCanvas = (item) => {
    activeEpt.useEpt(isPrimitive ?
      Ept.fromPrimitive(item, sausager) :
      isPredefined ?
        Ept.fromTemplate(item, sausager) :
        Ept.fromSausage(item, sausager, true, true, true)
    );
  };

  const isAddingPossible = activeEpt.size < eptMaxSize;

  return (
    <Tab.Pane className='ct-catalog'>
      <FuzzySearchBox
        value={filter}
        onChange={setFilter}
      />
      {isAddingPossible ?
        <Divider /> :
        <Message warning>
          {`Maximum amount of primitives on the canvas (${eptMaxSize}) is reached`}
        </Message>
      }
      <List relaxed>
        {
          itemsFiltered.map(({title, type, content, ins, out, item, isEmpty, highlight}, index) =>
            <List.Item
              key={index}
              disabled={isEmpty || !isAddingPossible || (vnOnly && isNotVnType({type}))}
              onClick={() => addToCanvas(item)}
              as='button'
              aria-label={title}
              className={cx({highlight})}
            >
              <List.Header>
                {title}
              </List.Header>
              <List.Content>{content}</List.Content>
              {(ins || out) &&
                <List.Content className='types'>
                  <div>
                    <span>{'Accepts'}</span>
                    {ins}
                  </div>
                  {out && out !== 'none' &&
                    <div>
                      <span>{'Produces'}</span>
                      {out}
                    </div>
                  }
                </List.Content>
              }
            </List.Item>
          )
        }
      </List>
    </Tab.Pane>
  );
};

export default observer(Catalog);
