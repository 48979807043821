import {Icon, Message} from 'semantic-ui-react';

import './Legend.less';

const Legend = () => {
  return (
    <Message info icon className='entities-map-legend'>
      <Icon name='info circle' />
      <Message.Content>
        {'This shows you all the logical relationships and dependencies chosen so you can validate '}
        {'that the design matches your expectations.'}
      </Message.Content>
    </Message>
  );
};

export default Legend;
