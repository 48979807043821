import {FC, Fragment, useContext, useMemo, useState} from 'react';
import {Button, Form} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {
  map, flatMap, transform, find, every, compact, includes,
  toLower,
} from 'lodash';
import {FuzzySearchBox} from 'apstra-ui-common';

import IBAContext from '../../../IBAContext';
import TreeProbeGraph from '../../graphs/probeGraph/TreeProbeGraph';
import {Probe, ProbeProcessorType, ProcessorDefinition} from '../../../types';

import {FilteredProbeGraph} from './FilteredProbeGraph';
import {FullProbeGraph} from './FullProbeGraph';

import {ProbeError, ProbeHighlights} from '../types';

import './ProbeGraph.less';

const matchStage = ({stage, processor, processorDefinitions, valueToSearch}) => {
  const matches = (string) => includes(toLower(string), valueToSearch);
  if (matches(stage.name)) return true;
  if (matches(processor.name)) return true;
  const processorDefinition = find(processorDefinitions, {name: processor.type});
  return processorDefinition && matches(processorDefinition.label);
};

type ProbeGraphProps = {
  actionInProgress: boolean;
  connectorsCanvasWidth?: number;
  currentProcessor: ProbeProcessorType | null;
  currentStageName: string | null;
  editable: boolean;
  errors: ProbeError[];
  highlights: ProbeHighlights;
  highlightCurrentEntity: () => void;
  highlightProcessorAndRelatedStages: (processorName: string) => void;
  highlightStageAndRelatedProcessors: (stageName: string) => void;
  probe: Probe;
  probeNavigationExpanded: boolean;
  processorDefinitionsByName: Record<string, ProcessorDefinition>;
  processorSpacing?: number;
  processorStepHeight?: number;
  setCurrentProcessorName: (processorName: string) => void;
  setCurrentStageName: (stageName: string) => void;
  stageStepHeight?: number;
  toggleProbeNavigationExpanded: () => void;
};

export const ProbeGraph: FC<ProbeGraphProps> = observer(({
  connectorsCanvasWidth = 25,
  processorStepHeight = 46,
  stageStepHeight = 33,
  processorSpacing = 14,
  editable = false,
  errors = [],
  actionInProgress,
  currentProcessor,
  currentStageName,
  highlightCurrentEntity,
  highlightProcessorAndRelatedStages,
  highlightStageAndRelatedProcessors,
  highlights,
  probeNavigationExpanded,
  probe,
  processorDefinitionsByName,
  setCurrentProcessorName,
  setCurrentStageName,
  toggleProbeNavigationExpanded,
}) => {
  const [filter, updateFilter] = useState('');
  const {processorDefinitions} = useContext(IBAContext);

  const matchedStages = useMemo(
    () => {
      const valueParts = compact(toLower(filter).split(/\s+/));
      if (!valueParts.length) return [];
      const allProbeProcessorStagePairs = flatMap(
        probe.processors,
        (processor) => map(
          find(processorDefinitions, {name: processor.type})!.outputs,
          (metadata, outputName) => ({processor, stage: find(probe.stages, {name: processor.outputs[outputName]})!})
        )
      );
      return transform(
        allProbeProcessorStagePairs,
        (result, {stage, processor}) => {
          if (every(valueParts,
            (valueToSearch) => matchStage({stage, processor, processorDefinitions, valueToSearch}))
          ) {
            result.push(stage.name);
          }
        },
        [] as string[]
      );
    },
    [filter, probe.processors, probe.stages, processorDefinitions]
  );

  const warningCountByStageName: Record<string, number> = editable ? {} :
    transform(
      probe.stages,
      (result, {name, warnings}) => {
        result[name] = warnings;
      },
      {}
    );

  const filterActivated = !!filter.length;
  return (
    <Fragment>
      {!editable &&
        <Form className='probe-graph-filter'>
          <FuzzySearchBox
            size='small'
            placeholder='Search stages...'
            disabled={actionInProgress}
            initialValue={filter}
            onChange={updateFilter}
          />
        </Form>
      }
      {!filterActivated &&
        <div className='probe-graph-controllers'>
          <Button
            className='expand-button'
            basic
            circular
            size='tiny'
            icon={probeNavigationExpanded ? 'angle left' : 'angle right'}
            onClick={toggleProbeNavigationExpanded}
            aria-label='Expand probe graph'
          />
        </div>}
      <div className='probe-graph'>
        {filterActivated ?
          <FilteredProbeGraph
            currentStageName={currentStageName}
            editable={editable}
            highlightCurrentEntity={highlightCurrentEntity}
            highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
            highlights={highlights}
            matchedStages={matchedStages}
            probe={probe}
            setCurrentStageName={setCurrentStageName}
            stageStepHeight={stageStepHeight}
            warningCountByStageName={warningCountByStageName}
          />
          : (probeNavigationExpanded ?
            <TreeProbeGraph
              currentProcessor={currentProcessor}
              currentStageName={currentStageName}
              editable={editable}
              errors={errors}
              highlightCurrentEntity={highlightCurrentEntity}
              highlightProcessorAndRelatedStages={highlightProcessorAndRelatedStages}
              highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
              highlights={highlights}
              probe={probe}
              setCurrentProcessorName={setCurrentProcessorName}
              setCurrentStageName={setCurrentStageName}
              warningCountByStageName={warningCountByStageName}
            />
          :
            <FullProbeGraph
              actionInProgress={actionInProgress}
              connectorsCanvasWidth={connectorsCanvasWidth}
              currentProcessor={currentProcessor}
              currentStageName={currentStageName}
              editable={editable}
              errors={errors}
              highlights={highlights}
              highlightCurrentEntity={highlightCurrentEntity}
              highlightProcessorAndRelatedStages={highlightProcessorAndRelatedStages}
              highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
              probe={probe}
              processorDefinitionsByName={processorDefinitionsByName}
              processorSpacing={processorSpacing}
              processorStepHeight={processorStepHeight}
              setCurrentProcessorName={setCurrentProcessorName}
              setCurrentStageName={setCurrentStageName}
              stageStepHeight={stageStepHeight}
              warningCountByStageName={warningCountByStageName}
            />
          )
        }
      </div>
    </Fragment>
  );
});
