import {Component, Fragment} from 'react';
import {Header, Grid, List, Message, Icon, Segment} from 'semantic-ui-react';
import {computed, observable, reaction, comparer, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {deserialize} from 'serializr';
import {
  find, map, transform, filter, includes, unionBy, flatMap, uniq,
  groupBy, isEmpty, some, partial, get, isFunction
} from 'lodash';
import cx from 'classnames';
import {DataFilter, DataFilteringContainer, DataFilteringLayout, Pagination, natsort} from 'apstra-ui-common';

import {Endpoint, Link} from '../stores';
import {TAG_TYPES, TAG_TYPES_METADATA} from '../consts';

import PortMapView from './PortMapView';
import {ALL_NODE_ROLES} from '../../roles';
import userStore from '../../userStore';

import './EndpointsLeafsView.less';

function getValue(object, valueGetter, defaultValue) {
  return isFunction(valueGetter) ? valueGetter(object) : get(object, valueGetter, defaultValue);
}

function filterer(itemPath, filtersArrayPath) {
  return function({item, filters}) {
    const filterValue = getValue(filters, filtersArrayPath, null);
    if (isEmpty(filterValue)) return true;
    const itemValue = getValue(item, itemPath, null);
    return some(filterValue, partial(includes, itemValue));
  };
}

export default function EndpointsLeafsView(props) {
  return (
    <DataFilteringContainer>
      {({filters, updateFilters}) =>
        <EndpointsLeafsViewContent {...props} {...{filters, updateFilters}} />
      }
    </DataFilteringContainer>
  );
}

@observer
class EndpointsLeafsViewContent extends Component {
  @observable endpoints = [];

  constructor(props) {
    super(props);
    makeObservable(this);

    this.disposeLinksUpdater = reaction(
      () => this.props.links,
      (links) => {
        this.links = links.map((plainLink) => deserialize(Link, plainLink));
        this.endpoints = this.links.map(({endpoint}) => new Endpoint(endpoint));
      },
      {equals: comparer.structural, fireImmediately: true}
    );

    this.disposeLSelectedServerUpdater = reaction(
      () => this.props.selectedServer,
      (selectedServer) => {
        if (selectedServer) {
          this.props.updateFilters({...this.props.filters, server: [selectedServer]});
        }
      },
      {fireImmediately: true}
    );

    this.searchBoxSchema = [
      {
        name: 'leaf',
        schema: {
          type: 'array',
          title: 'Leaf',
          items: {
            enum: uniq(flatMap(this.links, ({uiData}) => map(uiData.leaf, 'label'))).sort(natsort)
          },
        }
      },
      {
        name: 'ifcName',
        schema: {
          type: 'array',
          title: 'Interface Name',
          items: {
            enum: uniq(flatMap(this.links, 'uiData.interface')).sort(natsort)
          },
        }
      },
      {
        name: 'server',
        schema: {
          type: 'array',
          title: 'Server',
          items: {
            enum: uniq(map(this.links, ({uiData}) => uiData.server[0]?.label)).sort(natsort)
          },
        }
      },
      {
        name: 'linkLabel',
        schema: {
          type: 'array',
          title: 'Link Label',
          items: {
            enum: uniq(map(this.links, 'linkLabel').sort(natsort))
          },
        }
      },
      {
        name: 'serverGroup',
        schema: {
          type: 'array',
          title: 'Server Group',
          items: {
            enum: uniq(flatMap(this.links, 'uiData.server_group')).sort(natsort)
          },
        }
      },
    ];

    this.filterers = [
      filterer(({uiData}) => map(uiData.leaf, 'label'), ['leaf']),
      filterer(['uiData', 'interface'], ['ifcName']),
      filterer(({uiData}) => map(uiData.server, 'label'), ['server']),
      filterer(['linkLabel'], ['linkLabel']),
      filterer(['uiData', 'server_group'], ['serverGroup']),
    ];
  }

  componentWillUnmount() {
    this.disposeLinksUpdater();
    this.disposeLSelectedServerUpdater();
  }

  render() {
    if (!this.endpoints.length) {
      return <Message>{'There are no endpoints.'}</Message>;
    }

    const {
      searchBoxSchema, filterers, links, endpoints,
      props: {nodes, filters, updateFilters, deviceProfiles, interfaceMaps}
    } = this;

    if (!links.length) {
      return <Message content='No endpoints found.' />;
    }

    if (links.every(({endpoints}) => {
      return endpoints
        .filter(({system}) => [ALL_NODE_ROLES.LEAF, ALL_NODE_ROLES.ACCESS].includes(system.role))
        .every(({system}) => !system.interface_map_id);
    })) {
      return (
        <Message warning icon>
          <Icon name='warning sign' />
          <Message.Content>
            {'No leaf or access switch have a device profile assigned. '}
          </Message.Content>
        </Message>
      );
    }

    return (
      <Fragment>
        <DataFilter
          items={links}
          {...{filters, filterers}}
        >
          {({allItems}) =>
            <Segment attached='bottom'>
              <Grid className='endpoints-map-view' textAlign='left'>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Header as='h4' content='Port Maps:' />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <DataFilteringLayout
                      searchProps={{
                        filters,
                        schema: searchBoxSchema,
                        onChange: updateFilters
                      }}
                    >
                      <PortMapList
                        disabled
                        links={allItems}
                        endpoints={groupBy(endpoints, 'id')}
                        {...{nodes, interfaceMaps, deviceProfiles}}
                      />
                    </DataFilteringLayout>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          }
        </DataFilter>
      </Fragment>
    );
  }
}

@observer
class PortMapList extends Component {
  static userStoreKey = 'portMapList';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get leafs() {
    const {links, nodes, endpoints} = this.props;
    return transform(
      links,
      (result, {endpoint: {id: endpointId}, linkLabel, leafs}) => {
        if (leafs.length) {
          const {label: leafLabel, id: leafId} = leafs[0];
          const {redundancy_group_id: leafPairId} = nodes[leafId] || {};
          const resultLabel = leafPairId ? nodes[leafPairId].label : leafLabel;

          result[resultLabel] = result[resultLabel] || {links: [], leafs: []};

          result[resultLabel].leafs = unionBy(result[resultLabel].leafs, leafs, 'label');

          leafs.forEach(({ifcName, label}) =>
            result[resultLabel].links.push({ifcName, linkLabel, leafLabel: label, endpoint: endpoints[endpointId][0]})
          );
        }
      },
      {}
    );
  }

  @computed.struct get leafLabels() {
    return Object.keys(this.leafs).sort(natsort);
  }

  render() {
    const {
      props: {disabled, nodes, interfaceMaps, deviceProfiles},
      leafLabels
    } = this;
    const defaultPageSize = userStore.getStoreValue([PortMapList.userStoreKey, 'pageSize']) || 5;

    return (
      <DataFilteringContainer
        defaultPageSize={defaultPageSize}
        setUserStoreProps={userStore.setStoreValueFn(PortMapList.userStoreKey)}
      >
        {({activePage, pageSize, updatePagination}) =>
          <DataFilter
            items={leafLabels}
            {...{activePage, pageSize}}
          >
            {({items, totalCount}) =>
              <Grid>
                <Grid.Row>
                  <Grid.Column className='legend' width={10}>
                    <List horizontal>
                      <List.Item key='label'>
                        <strong>{'Ports:'}</strong>
                      </List.Item>
                      {map(TAG_TYPES, (tagType) => [
                        <List.Item key={tagType}>
                          <div className={cx('endpoint', TAG_TYPES_METADATA[tagType].color)} />
                          {TAG_TYPES_METADATA[tagType].title}
                        </List.Item>
                      ])}
                      <List.Item key='mixed'>
                        <div className='endpoint blue' />
                        {'Partial'}
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column textAlign='right' width={6}>
                    <Pagination
                      {...{activePage, pageSize, totalCount}}
                      pageSizes={[5, 10, 25]}
                      onChange={updatePagination}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    {map(items, (leafLabel) => {
                      const {leafs, links} = this.leafs[leafLabel];
                      leafs.sort(({label: label1}, {label: label2}) => natsort(label1, label2));
                      const labels = map(leafs, 'label');
                      const {id: leafId} = leafs[0];

                      const {part_of: leafPairId} = nodes[leafId] || {};
                      let siblingLabel;
                      if (leafPairId && leafs.length === 1) {
                        siblingLabel = (find(nodes[leafPairId].composed_of, ({id}) => id !== leafId) || {}).label;
                      }
                      const showSibling = siblingLabel && !includes(labels, siblingLabel);
                      if (showSibling) labels.push(siblingLabel);

                      return (
                        <Fragment key={leafLabel}>
                          <Header as='h3'>
                            <Header.Content>{leafLabel}</Header.Content>
                            {labels.length > 1 &&
                              <Header.Subheader>{labels.join(' + ')}</Header.Subheader>
                            }
                          </Header>
                          {leafs.some((leaf) => !leaf.interface_map_id) ?
                            <Message warning>
                              {'The leaf has no device profile assigned. '}
                            </Message>
                          :
                            <div className='port-maps'>
                              {leafs.map(
                                ({label, interface_map_id: interfaceMapId, device_profile_id: deviceProfileId}) => {
                                  const leafLinks = filter(links, {leafLabel: label});
                                  const interfaceMap = find(interfaceMaps, {id: interfaceMapId});
                                  const deviceProfile = find(deviceProfiles, {id: deviceProfileId});

                                  return (
                                    <PortMapView
                                      key={label}
                                      {...{deviceProfile, interfaceMap, disabled}}
                                      links={leafLinks}
                                    />
                                  );
                                }
                              )}
                            </div>
                          }
                        </Fragment>
                      );
                    })}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            }
          </DataFilter>
        }
      </DataFilteringContainer>
    );
  }
}
