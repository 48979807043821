import React, {Component} from 'react';
import {action, observable, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {isFunction, isString} from 'lodash';
import {Button, Popup} from 'semantic-ui-react';
import cx from 'classnames';
import isNumeric from 'is-number';
import {CodeEditorInput} from 'apstra-ui-common';

import './OptionalExpressionInputWrapper.less';

@observer
export default class OptionalExpressionInputWrapper extends Component {
  @observable isExpressionInput = false;

  constructor(props) {
    super(props);

    makeObservable(this);

    this.disposeValueReaction = reaction(
      () => this.props.value,
      (value) => {
        if (isString(value) && !isNumeric(value) && this.props.switchModeEnabled) {
          this.setIsExpressionInput(true);
        }
      },
      {fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeValueReaction();
  }

  @action
  setIsExpressionInput = (value) => {
    this.isExpressionInput = value;
  };

  toggleIsExpressionInput = () => {
    this.setIsExpressionInput(!this.isExpressionInput);
    this.onChange(null);
  };

  onChange = (value) => {
    const newValue = isNumeric(value) ? Number(value) : value;
    this.props.onChange(newValue);
  };

  render() {
    const {isExpressionInput, toggleIsExpressionInput, onChange} = this;
    const {
      children, switchModeEnabled, value, name, required, errors, schema, disabled, knownPythonExpressionVariables
    } = this.props;
    const hasLabel = schema?.title ?? name;
    const tooltip = isExpressionInput ? 'Switch to default' : 'Switch to expression';
    return (
      <div className={
        cx(
          'optional-expression-input-wrapper',
          {'with-label': hasLabel, 'switch-mode-enabled': switchModeEnabled}
        )}
      >
        {isExpressionInput ? (
          <CodeEditorInput
            mode='python-expression'
            enableCompletion
            completerParams={{knownVariables: knownPythonExpressionVariables}}
            name={name}
            value={String(value ?? '')}
            schema={schema}
            required={required}
            disabled={disabled}
            errors={errors}
            onChange={onChange}
          />
        ) : isFunction(children) ? children() : React.cloneElement(children)}
        {switchModeEnabled && <Popup
          trigger={
            <Button
              className={cx('switch-mode-icon', {ghost: !isExpressionInput})}
              icon='terminal'
              disabled={disabled}
              onClick={toggleIsExpressionInput}
              active={isExpressionInput}
              aria-label={tooltip}
            />
          }
          disabled={disabled}
          content={tooltip}
        />}
      </div>
    );
  }
}
