import {isEmpty} from 'lodash';
import {Message} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {Field} from 'apstra-ui-common';

import './AvailablePorts.less';

import Ports from './Ports';

const AvailablePorts = ({node}) => {
  if (!node) return null;

  if (isEmpty(node.availablePorts)) {
    return <Message warning>{'No ports available'}</Message>;
  }

  return (
    <Field label='Available Ports'>
      <Ports ports={node.availablePorts} showEmpty />
    </Field>
  );
};

export default observer(AvailablePorts);
