import {FC} from 'react';

import './Plane.less';

export type PlaneProps = {
  rx?: number;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  label?: string;
  onClick?: () => void;
  planeRole: string;
  testDataAttributes?: any;
};

export const Plane: FC<PlaneProps> = ({
  rx = 4, testDataAttributes = {},
  width = 0, height = 0, top = 0, left = 0, label, onClick, planeRole,
}) => {
  return (
    <g
      className={`topology-plane-rect topology-${planeRole}`}
      transform={`translate(${left},${top})`}
      onClick={onClick}
      {...testDataAttributes}
    >
      <rect
        width={width}
        height={height}
        rx={rx}
      />
      {label && (
        <foreignObject x={5} y={5} width={width} height={15}>
          <div className='label'>
            {label}
          </div>
        </foreignObject>
      )}
    </g>
  );
};
