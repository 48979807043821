import {Component, Fragment, PureComponent} from 'react';
import {action, computed, set, remove, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Form, Table, Input, Icon, Button} from 'semantic-ui-react';
import {
  map, transform, find, filter, keys, entries, size, union, pullAllWith, isMatch, isEmpty,
  isString, isPlainObject, uniq, forEach, get, some, includes, head, has,
} from 'lodash';
import {DropdownControl, Field, MapInput} from 'apstra-ui-common';

import {generateNewInputName, getPossibleStageValues} from '../stageUtils';

import './ProcessorInputEditor.less';

@observer
export default class ProcessorInputEditor extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  getAvailableStagesForInput(input) {
    const {probe, processor: destinationProcessor, processorDefinitionsByName} = this.props;
    const destinationProcessorDefinition = processorDefinitionsByName[destinationProcessor.name];
    const inputValues = getPossibleStageValues(destinationProcessorDefinition, 'inputs', false)[input];
    return transform(probe.processors, (result, sourceProcessor) => {
      if (sourceProcessor.name === destinationProcessor.name) return [];
      const sourceProcessorDefinition = processorDefinitionsByName[sourceProcessor.name];
      const outputValuesByOutput = getPossibleStageValues(sourceProcessorDefinition, 'outputs', false);

      if (isEmpty(inputValues)) {
        forEach(outputValuesByOutput, (outputValues, output) => {
          result[sourceProcessor.outputs[output]] = uniq(map(outputValues, 'name'));
        });
      }

      forEach(outputValuesByOutput, (outputValues, output) => {
        const intersections = filter(map(outputValues), (output) => {
          const condition = (input, output) =>
            (input.name === '*' || input.name === output.name) &&
            input.type === output.type &&
            input.dynamic === output.dynamic &&
            !!input.possible_values === !!output.possible_values;
          return find(inputValues, (input) => condition(input, output)) ? output : null;
        });
        if (!isEmpty(intersections)) {
          result[sourceProcessor.outputs[output]] = uniq(map(intersections, 'name'));
        }
      });
    }, {});
  }

  @action
  onInputChange = (inputName, value) => {
    const {processor, errors, highlightStage} = this.props;
    if (value !== undefined) {
      set(processor.inputs, inputName, value);
      highlightStage(value, false);
    } else {
      remove(processor.inputs, inputName, value);
    }
    pullAllWith(errors, [
      {type: 'processors'},
      {type: 'processor', processorName: processor.name},
      {type: 'processorInput', processorName: processor.name, inputName},
      {type: 'processorInput', processorName: processor.name, inputName: null},
    ], isMatch);
  };

  @action
  removeItem = (inputName) => {
    const {processor, errors} = this.props;
    remove(processor.inputs, inputName);
    pullAllWith(errors, [
      {type: 'processors'},
      {type: 'processor', processorName: processor.name},
      {type: 'processorInput', processorName: processor.name, inputName},
    ], isMatch);
  };

  @action
  addItem = () => {
    const {processor} = this.props;
    set(processor.inputs, head(this.availableProcessorInputs), {});
  };

  @action
  onInputNameChange = (inputName, nextInputName, value) => {
    const {processor, errors} = this.props;
    remove(processor.inputs, inputName);
    set(processor.inputs, nextInputName, value);
    pullAllWith(errors, [
      {type: 'processors'},
      {type: 'processor', processorName: processor.name},
      {type: 'processorInput', processorName: processor.name, inputName},
    ], isMatch);
  };

  @computed
  get availableProcessorInputs() {
    const {processor, processorDefinitionsByName} = this.props;
    const processorDefinition = processorDefinitionsByName[processor.name];
    const usedInputs = keys(processor.inputs);
    return transform(processorDefinition?.inputs, (result, inputDefinition, inputName) => {
      if (!includes(usedInputs, inputName) && !inputDefinition.required) result.push(inputName);
    }, []);
  }

  renderInputComponent() {
    const {processor, highlightStage, actionInProgress, errors, processorDefinitionsByName} = this.props;
    const processorDefinition = processorDefinitionsByName[processor.name];
    const isMultipleInputs = has(processorDefinition.inputs, '*');
    const hasRemoveAction = some(processorDefinition.inputs, {required: false});
    const inputs = isMultipleInputs ?
      processorDefinition.inputs : processor.inputs;
    return map(inputs, (inputDefinition, inputName) => {
      const InputComponent = inputName === '*' ? MultipleInputs : SingleInput;
      const required = processorDefinition.inputs[inputName]?.required;
      return (
        <div key={inputName} className='input-component-wrapper'>
          <div className='input-component'>
            <InputComponent
              inputName={inputName}
              processor={processor}
              processorDefinition={processorDefinition}
              required={required}
              disabled={actionInProgress}
              errors={errors}
              availableStagesMap={this.getAvailableStagesForInput(inputName)}
              highlightStage={highlightStage}
              onInputChange={this.onInputChange}
              onInputNameChange={this.onInputNameChange}
              availableProcessorInputs={this.availableProcessorInputs}
            />
          </div>
          {hasRemoveAction && (
            <Icon
              link
              name='remove'
              color={required ? 'grey' : 'red'}
              disabled={required}
              onClick={() => this.removeItem(inputName)}
              aria-label='Remove'
              className='icon'
            />
          )}
        </div>
      );
    });
  }

  render() {
    const {processor, processorDefinitionsByName} = this.props;
    const processorDefinition = processorDefinitionsByName[processor.name];
    if (!size(processorDefinition.inputs)) return null;
    return (
      <Table size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Inputs'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Form>
                {this.renderInputComponent()}
              </Form>
              {this.availableProcessorInputs.length > 0 && (
                <Button
                  type='button'
                  color='teal'
                  size='tiny'
                  icon='add'
                  labelPosition='left'
                  content='Add input'
                  onClick={() => this.addItem()}
                />
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

@observer
class SingleInput extends Component {
  render() {
    const {
      inputName, processor, processorDefinition, required, disabled, availableProcessorInputs,
      errors, availableStagesMap, highlightStage, onInputChange, onInputNameChange,
    } = this.props;
    const errorMessages = map(filter(errors, {
      type: 'processorInput',
      processorName: processor.name,
      inputName
    }), 'message');
    return (
      <Fragment>
        <ProcessorInputHeader required={required} label='Input Stage' />
        <Form.Group>
          <Field disabled={disabled} width={3}>
            <DropdownControl
              placeholder='Input Name'
              value={inputName}
              options={[inputName, ...availableProcessorInputs].map((inputName) => ({
                key: inputName,
                value: inputName,
                text: inputName,
              }))}
              onChange={(value) => onInputNameChange(inputName, value, processor.inputs[inputName])}
            />
          </Field>
          <Field
            width={13}
            disabled={disabled}
            errors={filter(errorMessages, isString)}
            description={processorDefinition.inputs[inputName].description}
            className='multi-items-input'
          >
            <StageDropdown
              name={inputName}
              value={processor.inputs[inputName]}
              disabled={disabled}
              availableStagesMap={availableStagesMap}
              highlightStage={highlightStage}
              onInputChange={onInputChange}
              errors={filter(errorMessages, isPlainObject)}
            />
          </Field>
        </Form.Group>
      </Fragment>
    );
  }
}

@observer
class MultipleInputs extends Component {
  generateNewEntry = () => [generateNewInputName(this.props.processor), {}];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get value() {
    const {processor, processorDefinition} = this.props;
    return transform(entries(processor.inputs), (result, [inputName, value]) => {
      if (!processorDefinition.inputs[inputName]) result[inputName] = value;
    }, {});
  }

  @action
  onChange = (newValue) => {
    const oldValue = this.value;
    for (const key of union(keys(oldValue), keys(newValue))) {
      if (key in oldValue && !(key in newValue)) {
        this.props.onInputChange(key);
      } else if (!(key in oldValue) && key in newValue) {
        this.props.onInputChange(key, newValue[key]);
      }
    }
  };

  render() {
    const {
      processor, required, disabled, errors, availableStagesMap, highlightStage, onInputChange,
    } = this.props;
    const generalErrors = filter(errors,
      (error) => error.type === 'processorInput' && error.processorName === processor.name && error.inputName === null
    );
    const generalErrorsMessages = map(generalErrors, 'message');
    return [
      <ProcessorInputHeader
        key='header'
        isEmptyValue={isEmpty(this.value)}
        required={required}
        label='Input Stages'
        multiple
      />,
      <MapInput
        key='control'
        value={this.value}
        disabled={disabled}
        generateNewEntry={this.generateNewEntry}
        onChange={this.onChange}
        buttonText='Add Input Stage'
        noItemsMessage='There are no input stages defined.'
        errors={generalErrorsMessages}
      >
        {({key: inputName, value, index, setEntryKey: setInputName}) => {
          const errorMessages = map(filter(errors, {
            type: 'processorInput',
            processorName: processor.name,
            inputName
          }), 'message');
          return (
            <Fragment>
              <Field key='name' width={3}>
                <Input
                  placeholder='Input Name'
                  disabled={disabled}
                  value={inputName}
                  onChange={(e) => setInputName(index, e.target.value)}
                />
              </Field>
              <Field
                key='value'
                width={13}
                className='multi-items-input'
                errors={filter(errorMessages, isString)}
                disabled={disabled}
              >
                <StageDropdown
                  name={inputName}
                  value={value}
                  disabled={disabled}
                  availableStagesMap={availableStagesMap}
                  highlightStage={highlightStage}
                  onInputChange={onInputChange}
                  errors={filter(errorMessages, isPlainObject)}
                />
              </Field>
            </Fragment>
          );
        }}
      </MapInput>
    ];
  }
}

@observer
class StageDropdown extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get columns() {
    const stageName = get(this.props.value, 'stage');
    const {availableStagesMap} = this.props;
    return map(availableStagesMap[stageName], (stage) => ({
      key: stage,
      value: stage,
      text: stage,
    }));
  }

  @computed.struct get errors() {
    const {errors} = this.props;
    return {
      stage: filter(map(errors, 'stage')),
      column: filter(map(errors, 'column')),
    };
  }

  render() {
    const {
      name, availableStagesMap, disabled, highlightStage,
      onInputChange,
    } = this.props;
    const stageName = get(this.props.value, 'stage');
    const column = get(this.props.value, 'column');

    const availableStages = keys(availableStagesMap);
    const invalid = stageName && !availableStages.includes(stageName);
    const stages = invalid ? [...availableStages, stageName] : availableStages;
    return (
      <Form.Group>
        <Field
          width={8}
          errors={this.errors.stage}
          disabled={!stages.length || disabled}
        >
          <DropdownControl
            search
            clearable
            placeholder={stages.length ? 'Choose input stage' : 'No available stages'}
            value={stageName}
            options={stages.map((stage) => ({
              key: stage,
              value: stage,
              text: stage,
              onMouseEnter: () => highlightStage(stage, true),
              onMouseLeave: () => highlightStage(stage, false),
            }))}
            onChange={
              (value) => onInputChange(name, value === '' ? {} : {stage: value, column: null})
            }
          />
        </Field>
        <Field
          width={8}
          errors={this.errors.column}
          disabled={!this.columns.length || disabled}
        >
          <DropdownControl
            clearable
            placeholder={this.columns.length ? 'Choose value column name' : 'No available columns'}
            value={column}
            options={this.columns}
            onChange={
              (value) => onInputChange(name, value === '' ? {stage: stageName} : {stage: stageName, column: value})
            }
          />
        </Field>
      </Form.Group>
    );
  }
}

class ProcessorInputHeader extends PureComponent {
  render() {
    const {label, required, multiple, isEmptyValue} = this.props;
    return (
      <Field required={required} label={label} className='processor-input-header'>
        {!isEmptyValue && (
          <Form.Group className='processor-input-subheader'>
            <Form.Field key='name' width={3} label='Input Name' />
            <Form.Field key='value' width={13} className='multi-items-input'>
              <Form.Group>
                <Form.Field width={8} label='Stage Name' />
                <Form.Field width={8} label='Column Name' />
              </Form.Group>
            </Form.Field>
            {multiple && (
              <Form.Field className='no-input-field hidden-icon'>
                <Icon name='remove' />
              </Form.Field>
            )}
          </Form.Group>
        )}
      </Field>
    );
  }
}
