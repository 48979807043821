import {every, find, first, forEach, keyBy, map, transform, values} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';

import LinksGroup from '../store/LinksGroup';
import {useRackEditorStore} from './useRackEditorStore';
import Step from '../../cablingMapEditor/store/Step';
import {PEER_LINKS_TYPES} from '../const';

const getPeerLink = (node, peeringType, rackStore) => {
  const existingLink = find(node.myLinksGroups, {peeringType});
  return existingLink ||
    LinksGroup.createFilledWith({fromName: node.name, toName: node.name, count: 0, peeringType}, rackStore);
};

const usePeerLinker = (selectedNodes) => {
  const node = first(selectedNodes) ?? {};
  const {rackStore} = useRackEditorStore();
  const {changes} = rackStore;

  // Depending on the node role and peering type there could be
  // different combinations of peer links
  const peeringTypes = node.isLeaf ?
    (
      // MLAG Leaf pairs have L2* and L3 peer links
      // ESI Leaf pairs do not have peer links
      node.isMlagLeafPair ? [PEER_LINKS_TYPES.L2_PEER, PEER_LINKS_TYPES.L3_PEER] : []
    ) : (
      // Access Switch pairs must have L3* peer links
      node.isAccessSwitch ? [PEER_LINKS_TYPES.L3_PEER] : []
    );

  // Reusing/creating links groups for every required peering type.
  // New links groups get created of zero size.
  const [linksGroups] = useState(() => {
    return transform(peeringTypes, (acc, type) => {
      acc[type] = getPeerLink(node, type, rackStore);
    });
  });

  // Identify/create the change.
  // Change must contain:
  // * LinksGroups for all peering types
  // * Node for portChannelId[Ext]
  const [change] = useState(() => {
    const links = values(linksGroups);
    const linksIds = map(links, 'id');
    return every([...linksIds, node.id], (id) => changes.current?.includes?.(id)) ?
      changes.current :
      map([...links, node], (item) => Step.modification(item, item));
  });

  // Registering all affected peer links in the links groups collection
  useEffect(
    () => {
      forEach(values(linksGroups), (link) => rackStore.addLinksGroup(link));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Make all steps involved accessible by item id
  const stepsById = useMemo(
    () => keyBy(change, 'id'),
    [change]
  );

  const trackChange = useCallback(
    (parameter, value, item) => {
      // Apply changes
      item.setProperty(parameter, value, true);
      // Update corresponding step
      stepsById[item.id].setResult(item);
      // Register changes, making sure no duplicates get created
      rackStore.changes.register(change, false, true);
    },
    [change, rackStore.changes, stepsById]
  );

  const {portChannelIdsErrors} = node;

  return {node, portChannelIdsErrors, linksGroups, trackChange};
};

export default usePeerLinker;
