import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {action, makeObservable, observable} from 'mobx';
import {Popup} from 'semantic-ui-react';

/**
 * Shows popup for target with truncated text
 */
@observer
export class EllipsisPopup extends Component {
  @observable open = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  onOpen = ({target}) => {
    this.open = target.parentElement.scrollWidth > target.parentElement.offsetWidth;
  };

  @action
  onClose = () => {
    this.open = false;
  };

  render() {
    return <Popup
      {...this.props}
      onClose={this.onClose}
      onOpen={this.onOpen}
      open={this.open}
    />;
  }
}
