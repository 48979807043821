import {Component} from 'react';
import {deserialize} from 'serializr';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';

import {InterfaceMap} from '../../../interfaceMaps/stores';
import {NeighborsBase} from './NeighborsBase';
import {getNeighborsDataFromCablingMap} from './data';
import UserStoreContext from '../../../UserStoreContext';

import './NeighborsIntent.less';

@observer
export class NeighborsIntent extends Component {
  static contextType = UserStoreContext;
  static propTypes = NeighborsBase.propTypes;

  static defaultProps = {
    userStoreKey: 'neighbors',
  };

  @observable settings = {
    showUnusedPorts: false,
    showAggregateLinks: true
  };

  @action
  onShowUnusedPortsChange = (value) => {
    const {context: {userStore}, props: {userStoreKey, showUnusedPortsCallback}, settings} = this;
    showUnusedPortsCallback?.(value);
    settings.showUnusedPorts = value;
    userStore.setFor(userStoreKey, settings);
  };

  @action
  onShowAggregateLinksChange = (value) => {
    const {context: {userStore}, props: {userStoreKey}, settings} = this;
    settings.showAggregateLinks = value;
    userStore.setFor(userStoreKey, settings);
  };

  @action
  setSettingsFromStore = () => {
    this.settings = this.context.userStore.getStoreValue([this.props.userStoreKey]) ?? this.settings;
  };

  componentDidMount() {
    this.setSettingsFromStore();
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get interfaceMap() {
    const {interfaceMap} = this.props;
    return interfaceMap ? deserialize(InterfaceMap, interfaceMap) : null;
  }

  render() {
    const {interfaceMap, onShowUnusedPortsChange, onShowAggregateLinksChange, settings} = this;
    const {
      isFreeform, cablingMap, aggregateLinks, nodeId, labelKey, neighborsRoleFilter, lldpTelemetry,
      interfaceTelemetry, nodes, interfacesWithCts, deviceProfile,
    } = this.props;

    const data = getNeighborsDataFromCablingMap({
      isFreeform,
      cablingMap,
      aggregateLinks,
      nodeId,
      neighborsRoleFilter,
      labelKey,
      nodes,
      interfaceTelemetry,
      interfaceMap,
      lldpTelemetry,
      interfacesWithCts,
      deviceProfile,
      ...settings
    });

    return (<NeighborsBase
      className='node-neighbors-intent-graph'
      data={data}
      {...this.props}
      showUnusedPortsAvailable={!!interfaceMap || !!deviceProfile}
      {...settings}
      onShowUnusedPortsChange={onShowUnusedPortsChange}
      onShowAggregateLinksChange={onShowAggregateLinksChange}
    />);
  }
}
