import {action, computed, observable, makeObservable} from 'mobx';

import {nodeTextSources} from '../const';
import CablingMapStore from './CablingMapStore';
import SelectionStore from './SelectionStore';
import HighlightStore from './HighlightStore';
import NodeArrangerStore from './NodeArrangerStore';

class CablingMapEditorStore {
  @observable cablingMap;
  @observable selection;
  @observable highlight;
  @observable.ref layerData = null;

  @observable nodeTextSource = null;

  @action
  setNodeTextSource = (value) => {
    this.nodeTextSource = value;
  };

  @computed
  get nodeTextExtractFn() {
    return (this.nodeTextSource === nodeTextSources.IP) ?
      (node) => this.cablingMap.availableDevicesById[node.systemId]?.mgmt_ipaddr :
      (node) => node[this.nodeTextSource];
  }

  constructor() {
    makeObservable(this);
    this.cablingMap = new CablingMapStore(this);
    this.selection = new SelectionStore(this);
    this.arranger = new NodeArrangerStore(this);
    this.highlight = new HighlightStore(this);
  }

  dispose() {
    this.highlight.dispose();
    this.arranger.dispose();
  }
}

export default CablingMapEditorStore;
