import cx from 'classnames';
import {Component} from 'react';
import {Popup} from 'semantic-ui-react';

import GraphCanvasContext from './GraphCanvasContext';

import './GraphConnector.less';

export default class GraphConnector extends Component {
  static contextType = GraphCanvasContext;

  onMouseDown = (e) => {
    this.props.onMouseDown?.(this.props.id, this.props.data, e);
  };

  onMouseOver = () => {
    this.props.onMouseOver?.(this.props.id, this.props.data);
  };

  onMouseOut = () => {
    this.props.onMouseOut?.(this.props.id, this.props.data);
  };

  render() {
    const {className, label, x, y, data, isButton, draggingConnectorData,
      output, large, content, ...props} = this.props;
    const {connectorsCompatibilityFn} = this.context;
    const height = large ? 16 : 10;
    const width = height + (content?.length > 1 && 3);
    return (
      <g
        className={cx(
          'graph-connector',
          {
            incompatible: draggingConnectorData && !connectorsCompatibilityFn(data, draggingConnectorData),
            button: isButton,
            output
          },
          className)}
        {...props}
        onMouseDown={this.onMouseDown}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        <Popup
          content={label}
          disabled={!label}
          offset={[-14, 0]}
          trigger={
            <g transform={`translate(${x - 0.5 * width},${y - 0.5 * height})`}>
              <rect
                className='marker'
                width={width}
                height={height}
                rx={5}
              />
              {!!content &&
                <svg width={width} height={height}>
                  <text
                    className='label'
                    x='50%'
                    y='50%'
                    textAnchor='middle'
                    dominantBaseline='middle'
                  >
                    {content}
                  </text>
                </svg>
              }
              {isButton &&
                <path d={`M${x - 3},${y}h6M${x},${y - 3}v6`} />
              }
            </g>}
        />
      </g>
    );
  }
}
