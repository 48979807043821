import {
  map, flatMap, transform, split, castArray, isString, isNumber, isBoolean,
  isPlainObject, isArray, capitalize, isFinite,
} from 'lodash';
import {isRangeFilter, isRegexFilter} from 'apstra-ui-common';

function convertValue(value) {
  if (isString(value)) {
    return convertStringValue(value);
  } else if (isBoolean(value)) {
    return convertBooleanValue(value);
  } else if (isArray(value)) {
    return convertArrayValue(value);
  } else {
    return String(value);
  }
}

function convertBooleanValue(value) {
  return capitalize(String(value));
}

function convertStringValue(value) {
  return `"${value.replace(/["\\]/g, (c) => '\\' + c)}"`;
}

function convertArrayValue(value) {
  return `[${value.map((value) => convertValue(value)).join(',')}]`;
}

function convertFilter(name, value, schema) {
  if (isString(value) || isNumber(value) || isBoolean(value)) {
    return convertPrimitiveFilter(name, value, schema);
  } else if (isArray(value)) {
    return convertArrayFilter(name, value);
  } else if (isPlainObject(value) && (value.start || value.end)) {
    return convertTimeFilter(name, value);
  } else if (isRangeFilter(value)) {
    return convertRangeFilter(name, value);
  } else if (isOptionalRegexFilter(value)) {
    return convertOptionalRegexFilter(name, value);
  } else {
    return [];
  }
}

function isOptionalRegexFilter(value) {
  return value?.value && ['regex', 'text'].includes(value?.type);
}

function convertOptionalRegexFilter(name, {type, value}) {
  const operator = type === 'regex' ? '~=' : '=';
  return `${name}${operator}${convertValue(value)}`;
}

function convertRangeFilter(name, {min, equals, max}, strictMode = true) {
  const result = [];
  if (isFinite(min)) result.push(`${name}${strictMode ? ' > ' : ' >= '}${min}`);
  if (isFinite(max)) result.push(`${name}${strictMode ? ' < ' : ' <= '}${max}`);
  if (isFinite(equals)) result.push(`${name} = ${equals}`);
  return result;
}

function convertPrimitiveFilter(name, value, schema) {
  const operator = isRegexFilter(schema, name) ? '~=' : '=';
  return `${name}${operator}${convertValue(value)}`;
}

function convertArrayFilter(name, value) {
  if (!value.length) {
    return [];
  } else {
    return `${name} in ${convertArrayValue(value)}`;
  }
}

function convertTimeFilter(name, {start, end}) {
  const result = [];
  if (start) result.push(`${name}>=${start}`);
  if (end) result.push(`${name}<=${end}`);
  return result;
}

export function filtersToQueryParam(filters, schema) {
  if (isString(filters)) {
    return filters;
  }
  return flatMap(filters, (value, name) => castArray(convertFilter(name, value, schema))).join(' and ');
}

export function sortingToQueryParam(sorting) {
  return map(sorting, (direction, name) => `${name}:${direction.toUpperCase()}`).join(';');
}

export function queryParamToSorting(stringifiedSorting) {
  return transform(split(stringifiedSorting, ';'), (result, stringifiedColumnSorting) => {
    const match = stringifiedColumnSorting.match(/^(.*?):(ASC|DESC)$/);
    if (match) {
      const [, columnName, direction] = match;
      result[columnName] = direction.toLowerCase();
    }
  }, {});
}
