import {useCallback, useContext} from 'react';
import {find, flatMap, size, isEmpty, isObject} from 'lodash';

import {getStageFormSchema, getProcessorByStageName} from '../stageUtils';
import ExportModal from '../../components/ExportModal';
import IBAContext from '../IBAContext';

export default function ProbeExportModal({probe = null, ...props}) {
  const {processorDefinitions} = useContext(IBAContext);

  const exportCallback = useCallback(() => {
    if (!probe) return '';
    const exportedProbe = {label: probe.label};
    if (!isEmpty(probe.description)) exportedProbe.description = probe.description;
    exportedProbe.processors = probe.processors;
    const stages = flatMap(probe.stages, (stage) => {
      const exportedStage = {name: stage.name};
      const processor = getProcessorByStageName({probe, stageName: stage.name});
      const processorDefinition = find(processorDefinitions, {name: processor.type});
      for (const {name} of getStageFormSchema(processorDefinition)) {
        const value = stage[name];
        if (value && (!isObject(value) || !isEmpty(value))) exportedStage[name] = value;
      }
      return size(exportedStage) > 1 ? [exportedStage] : [];
    });
    if (stages.length) exportedProbe.stages = stages;
    return JSON.stringify(exportedProbe, null, 2);
  }, [processorDefinitions, probe]);

  if (!probe) return null;
  const label = probe.label ?? 'Probe';

  return (
    <ExportModal
      header={`Export ${label}`}
      fileName={`Probe - ${label}.json`}
      export={exportCallback}
      type='application/json'
      {...props}
    />
  );
}
