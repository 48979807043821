import {observer} from 'mobx-react';
import {useCallback, useEffect, useMemo} from 'react';
import {Accordion, Button, Icon, Message} from 'semantic-ui-react';
import {filter, findKey, map, size, sortBy} from 'lodash';
import {Field} from 'apstra-ui-common';

import {draftLinksGroupId, HOMING_TYPES, NODE_ROLES, rolesOrder} from '../const';
import LeafPeerLinker from './LeafPeerLinker';
import AccessSwitchPeerLinker from './AccessSwitchPeerLinker';
import {speedFromString} from '../utils';
import LinksGroupForm from './LinksGroupForm';
import PortsCount from './PortsCount';
import {Checkbox} from '../../exports';
import NumberInput from './NumberInput';
import useLinksGroupOptions from '../hooks/useLinksGroupOptions';
import {useRackEditorStore} from '../hooks/useRackEditorStore';

import './Linker.less';

const switches = [NODE_ROLES.ACCESS_SWITCH, NODE_ROLES.LEAF];

const Linker = ({portsSpeeds, selectedNodes, freePorts, newLinkRestriction}) => {
  const {
    isPair, isLeafPair, createLinksGroup, nodesLinksGroups, result,
    onPortClick, portsAvailable, homingType, openNewLinksGroupForm, isDraft,
    toggleSwitchLink, toggleHoming, maxSizePerSpeed, canLinkFirst, canLinkSecond, canLinkBoth, canCreateNewLink
  } = useLinker(selectedNodes, portsSpeeds, freePorts);

  if (isPair) {
    // Manage peer links for switch pairs
    return isLeafPair ?
      <LeafPeerLinker portsSpeeds={portsSpeeds} selectedNodes={selectedNodes} /> :
      <AccessSwitchPeerLinker portsSpeeds={portsSpeeds} selectedNodes={selectedNodes} />;
  }

  const maxLinks = portsAvailable + result.count;
  const showNewLinkForm = (canCreateNewLink && !newLinkRestriction) || isDraft;

  return (
    <div className='re-linker'>
      <h5>{'Links Groups:'}</h5>
      <Accordion>
        {
          map(nodesLinksGroups, (linksGroup) =>
            linksGroup.id !== draftLinksGroupId &&
              <LinksGroupForm
                key={linksGroup.id}
                linksGroup={linksGroup}
                freePorts={freePorts}
                selectedNodes={selectedNodes}
              />
          )
        }
        {
          showNewLinkForm &&
            <>
              <Accordion.Title active={isDraft} onClick={openNewLinksGroupForm} className='new-link'>
                <Icon name='dropdown' />
                <b>{'Create New Links Group'}</b>
              </Accordion.Title>
              <Accordion.Content active={isDraft}>
                <div className='re-links-form green'>
                  <PortsCount
                    portsBySpeed={maxSizePerSpeed}
                    selectedSpeed={result.speedString}
                    onClick={onPortClick}
                    error={!result.speedString}
                    showEmpty
                    usedCount={result.count}
                  />
                  <Field label='Count' disabled={!result.speedString} required>
                    <NumberInput
                      type='number'
                      size='small'
                      fluid
                      min={1}
                      max={maxLinks}
                      value={result.count}
                      onChange={(event, {value}) => result.setProperty('count', value)}
                    />
                  </Field>
                  {
                    homingType === HOMING_TYPES.LEFT_RIGHT &&
                      <Field label='Peer Switch' className='checkboxes' required>
                        <Checkbox
                          label='First'
                          checked={result.isAttachedToFirst}
                          disabled={!canLinkFirst || (result.isAttachedToFirst && !canLinkSecond)}
                          onChange={() => toggleSwitchLink(true, canLinkBoth)}
                        />
                        <Checkbox
                          label='Second'
                          checked={result.isAttachedToSecond}
                          disabled={!canLinkSecond || (result.isAttachedToSecond && !canLinkFirst)}
                          onChange={() => toggleSwitchLink(false, canLinkBoth)}
                        />
                      </Field>
                  }
                  {
                    homingType === HOMING_TYPES.DOUBLE_SINGLE &&
                      <Field label='Attachemt Type' className='checkboxes' disabled={!canLinkBoth}>
                        <Checkbox
                          label='Dual-Homed'
                          checked={result.isDualAttached}
                          onChange={toggleHoming}
                        />
                      </Field>
                  }
                  <Button
                    primary
                    size='tiny'
                    disabled={result.count < 1}
                    onClick={createLinksGroup}
                  >
                    {'Create'}
                  </Button>
                </div>
              </Accordion.Content>
            </>
        }
      </Accordion>
      {
        !showNewLinkForm &&
          <Message warning className='re-linker-errors'>
            {newLinkRestriction}
          </Message>
      }
    </div>
  );
};

export default observer(Linker);

const useLinker = (selectedNodes, portsSpeeds, freePorts) => {
  const {rackStore, selection} = useRackEditorStore();
  const {linksGroups, openedLinksGroupId} = rackStore;

  // Links are always oriented from lower zone to the uppers
  const nodesRoleOrdered = useMemo(
    () => sortBy(selectedNodes, ({role}) => -rolesOrder.indexOf(role)),
    [selectedNodes]
  );

  const result = linksGroups[openedLinksGroupId] || {};
  const [firstNode, secondNode] = nodesRoleOrdered;
  const isDraft = openedLinksGroupId === draftLinksGroupId;

  const {
    maxSizePerSpeed, toggleSwitchLink, toggleHoming, onPortClick, portsAvailable, homingType,
    canCreateNewLink, canLinkFirst, canLinkSecond, canLinkBoth
  } = useLinksGroupOptions(result, freePorts);

  useEffect(() => {
    if (result?.id === draftLinksGroupId) {
      // Selects first speed with free ports for the new link
      const firstSpeedString = findKey(portsSpeeds, (count) => (count > 0));
      result.setProperty('speed', firstSpeedString ? speedFromString(firstSpeedString) : null);
      result.setProperty('count', (firstSpeedString && !result.isPeer) ? 1 : 0);
    }
  }, [result]); // eslint-disable-line react-hooks/exhaustive-deps

  const createLinksGroup = useCallback(() => {
    rackStore.commitLinksGroup();
  }, [rackStore]);

  // Filter links between the selected nodes
  const nodesLinksGroups = sortBy(
    filter(
      linksGroups, (linkGroup) => linkGroup.contains(selectedNodes, true)
    ),
    'label'
  );

  const isPair = size(selectedNodes) === 2 && firstNode.isPairedWith(secondNode.id);
  const isLeafPair = isPair && firstNode.role === NODE_ROLES.LEAF;
  const isAccessToLeaf = !isPair && firstNode.role !== secondNode.role &&
    switches.includes(firstNode.role) && switches.includes(secondNode.role);

  const openNewLinksGroupForm = () => !isDraft && rackStore.toggleLinker(null, selection);

  return {
    isPair, isLeafPair, isAccessToLeaf, createLinksGroup, nodesLinksGroups,
    onPortClick, portsAvailable, homingType, result, openNewLinksGroupForm, isDraft,
    toggleSwitchLink, toggleHoming, maxSizePerSpeed, canLinkFirst, canLinkSecond, canLinkBoth, canCreateNewLink
  };
};
