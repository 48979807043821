import {Fragment, memo, useMemo, useState} from 'react';
import {find, get, isEmpty, keys, map, transform} from 'lodash';
import {DateFromNow} from 'apstra-ui-common';
import {Accordion, Button, Icon, Popup, Table} from 'semantic-ui-react';

import humanizeString from '../../../humanizeString';
import ProbeExternalLink from './ProbeExternalLink';
import {NO_AVAILABLE_PREDEFINED_PROBES} from './const';

const PredefinedProbeList = memo(({
  schema, predefinedProbesSchema, predefinedProbes, probeList, blueprintId, openPredefinedProbeModal,
}) => {
  const probeLabelMap = useMemo(() => {
    return transform(predefinedProbesSchema, (result, {name, schema}) => {
      result[name] = get(schema, ['properties', 'label', 'default'], humanizeString(name));
    }, {});
  }, [predefinedProbesSchema]);

  const probesMap = useMemo(() => {
    return transform(predefinedProbes, (result, probes, label) => {
      result[label] = map(probes, (date, probeId) => {
        const result = {id: probeId, date: new Date(date)};
        const probe = find(probeList, ({id}) => id === probeId);
        if (!probe) return result;
        result.label = probe.label;
        result.updatedAt = new Date(probe.updated_at);
        return result;
      });
    }, {});
  }, [predefinedProbes, probeList]);

  const probes = useMemo(() => {
    return keys(get(schema, ['properties'], {}));
  }, [schema]);

  const [activeProbe, setActiveProbe] = useState({});
  const onClick = (name) => setActiveProbe({...activeProbe, [name]: !activeProbe[name]});

  return (
    <Accordion>
      {map(probes, (probeName) => (
        <Fragment key={probeName}>
          <Accordion.Title onClick={() => onClick(probeName)} active={activeProbe[probeName]}>
            <Icon name='dropdown' />
            {' '}
            {probeLabelMap[probeName] || probeName}
            {' '}
            <PredefinedProbeStatus active={!isEmpty(probesMap[probeName])} />
          </Accordion.Title>
          <Accordion.Content active={activeProbe[probeName]}>
            {isEmpty(probesMap[probeName]) ?
              <Button
                secondary
                size='tiny'
                content='Instantiate'
                onClick={() => openPredefinedProbeModal(probeName)}
              /> :
              <Table compact celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{'Probe Name'}</Table.HeaderCell>
                    <Table.HeaderCell>{'Date since'}</Table.HeaderCell>
                    <Table.HeaderCell>{'Updated at'}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {map(probesMap[probeName], (probe) => (
                    <Table.Row key={probe.id}>
                      <Table.Cell>
                        <strong>
                          {probe.label}
                          {' '}
                          <sup>
                            <ProbeExternalLink blueprintId={blueprintId} probeId={probe.id} />
                          </sup>
                        </strong>
                      </Table.Cell>
                      <Table.Cell>
                        <DateFromNow date={probe.date} />
                      </Table.Cell>
                      <Table.Cell>
                        <DateFromNow date={probe.updatedAt} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            }
          </Accordion.Content>
        </Fragment>
      ))}
    </Accordion>
  );
});

const PredefinedProbeStatus = ({active}) => (
  <Popup
    offset={[-12, 0]}
    trigger={
      <Icon
        color={active ? 'green' : undefined}
        name={active ? 'check circle outline' : 'times circle outline'}
      />
    }
    content={NO_AVAILABLE_PREDEFINED_PROBES}
    disabled={active}
  />
);

export default PredefinedProbeList;
