import {useMemo} from 'react';
import cx from 'classnames';
import {forEach, map, reject, transform} from 'lodash';

import {ALL_NODE_ROLES} from '../../../../roles';

import {LayoutBuilder, TopologyViewModel, VlanNodeLink, calculateLayout, layout as l, limitNodeWidth,
  getNodeInterfaces, getWorstStatus, saveLayout} from '../utils';
import {INode, LayoutType, PairRendering, TopologyData, TopologySettingsType,
  emptyLayout} from '../types';
import {COLLAPSED_EXTERNAL_GENERICS_ID, TOPOLOGY_CONFIG, VLAN_COLOR_NAMES} from '../consts';

type UseTopologyViewModel = (
  topologyData: TopologyData,
  topologySettings: TopologySettingsType,
  options: {
    showSpines: boolean,
    showSuperspines: boolean,
    shouldRenderRackDevices?: boolean,
  }
) => TopologyViewModel;

const buildLayout = ({
  builder, topologyData, expandNodes, expandExternalGenerics, showSpines, showSuperspines, shouldRenderRackDevices
}) => {
  const remoteGatewayLine = builder.buildRemoteGatewaysLine();
  const externalRoutersLine = builder.buildExternalRoutersLine(expandExternalGenerics);
  const superspinesLine = showSuperspines && builder.buildSuperspinePlanes();

  const rackLayoutBuilder = topologyData.hasAccessGroups ? builder.buildAccessRack : builder.buildRack;

  const racksLayout = topologyData.indexedPods ? builder.buildPodColumns({
    showSpines,
    expandNodes,
    shouldRenderRackDevices,
    rackLayoutBuilder
  }) : builder.buildPod({
    podId: null,
    showSpines,
    expandNodes,
    shouldRenderRackDevices,
    rackLayoutBuilder
  });

  const vlanBuses = builder.buildVlanBases();

  return l(LayoutType.Pad,
    [l(LayoutType.Stack,
      [
        remoteGatewayLine,
        superspinesLine,
        externalRoutersLine,
        racksLayout,
        vlanBuses,
      ],
    )],
  );
};

const getNodeTestDataAttributes = (node: INode) => {
  return {
    'data-node-id': node.id,
    'data-access-group-id': node.access_group_id,
    'data-rack-id': node.rack_id,
    'data-pod-id': node.pod_id,
    'data-plane-id': node.superspine_plane_id,
  };
};

const NODE_ROLE_CLASS_MAPPING: Partial<Record<ALL_NODE_ROLES, string>> = {
  superspine: 'tp-node-superspine',
  spine: 'tp-node-spine',
  leaf: 'tp-node-leaf',
  access: 'tp-node-access',
  generic: 'tp-node-generic',
  external_generic: 'tp-node-externalGeneric',
  remote_gateway: 'tp-node-remote-gateway',
};

export const useTopologyViewModel: UseTopologyViewModel = (topologyData, topologySettings, options) => {
  const topologyViewModel = useMemo(
    () => {
      const topologyViewModel: TopologyViewModel = {
        collapsedGenerics: {},
        nodes: {},
        nodePairs: {},
        planes: {},
        pods: {},
        racks: {},
        simpleGroupBoxes: {},
        accessGroups: {},
        layout: emptyLayout(),
        interfaces: {},
        links: {},
        vlans: {},
      };

      const layout = buildLayout({
        builder: new LayoutBuilder(topologyViewModel, topologyData),
        topologyData,
        expandNodes: topologySettings.expandNodes,
        expandExternalGenerics: topologySettings.expandExternalGenerics,
        shouldRenderRackDevices: options.shouldRenderRackDevices,
        showSpines: options.showSpines,
        showSuperspines: options.showSuperspines,
      });

      calculateLayout(layout);

      if (layout.layout.width < TOPOLOGY_CONFIG.minGraphWidth) {
        layout.layout.width = TOPOLOGY_CONFIG.minGraphWidth;
        calculateLayout(layout, true);
      }

      saveLayout(layout);

      forEach(topologyViewModel.collapsedGenerics, (collapsedGeneric, id) => {
        let generics: INode[] = [];
        if (id === COLLAPSED_EXTERNAL_GENERICS_ID) {
          generics = topologyData.externalRouters;
        } else {
          generics = topologyData.genericsByAccessGroups[id] ?? topologyData.genericsByRacks[id];
          const isCollapsedGenericsOutOfAccessGroup = topologyData.hasAccessGroups && topologyData.genericsByRacks[id];
          if (isCollapsedGenericsOutOfAccessGroup) {
            generics = reject(generics, 'access_group_id');
          }
        }
        collapsedGeneric.count = generics?.length ?? 0;
        const status = getWorstStatus(map(generics, 'layer_status'));
        if (status) {
          collapsedGeneric.className = `tp-status-${status}`;
        }
        const parent = topologyData.indexedAccessGroups?.[id] ?? topologyData.indexedRacks?.[id];
        collapsedGeneric.testDataAttributes = {
          'data-access-group-id': parent?.access_group_id,
          'data-rack-id': parent?.rack_id,
          'data-pod-id': parent?.pod_id,
        };
      });
      forEach(topologyViewModel.nodePairs, (nodePairData, nodePairId) => {
        const {'composed-of': composedOf} = topologyData.indexedNodes[nodePairId];
        if (composedOf?.length !== 2) return;
        const width = Math.ceil(nodePairData.width / 2);
        const splitPair = topologySettings.expandNodes || options.shouldRenderRackDevices;
        topologyViewModel.nodes[composedOf[0]] = {
          top: nodePairData.top,
          left: nodePairData.left,
          height: nodePairData.height,
          width: width - (splitPair ? TOPOLOGY_CONFIG.nodePairSpace / 2 : 0),
          pairRendering: splitPair ? undefined : PairRendering.Left,
        };
        topologyViewModel.nodes[composedOf[1]] = {
          top: nodePairData.top,
          left: nodePairData.left + width + (splitPair ? TOPOLOGY_CONFIG.nodePairSpace : 0),
          height: nodePairData.height,
          width,
          pairRendering: splitPair ? undefined : PairRendering.Right,
        };
      });
      forEach(topologyViewModel.nodes, (nodeData, nodeId) => {
        nodeData.sourceNode = topologyData.indexedNodes[nodeId];
        const {role, layer_status: layerStatus} = nodeData.sourceNode;
        limitNodeWidth(nodeData, role);
        nodeData.className = cx(
          NODE_ROLE_CLASS_MAPPING[role],
          layerStatus && `tp-status-${layerStatus}`
        );
        nodeData.testDataAttributes = getNodeTestDataAttributes(nodeData.sourceNode);
        if (role === ALL_NODE_ROLES.REMOTE_GATEWAY) {
          nodeData.icon = 'apstra-icon-remote-gateway';
        }
      });
      forEach(topologyViewModel.planes, (planeData, planeId) => {
        const plane = topologyData.indexedPlanes[planeId];
        planeData.label = plane?.label;
        planeData.testDataAttributes = {
          'data-plane-id': plane?.id,
        };
      });
      forEach(topologyViewModel.pods, (podData, podId) => {
        const pod = topologyData.indexedPods?.[podId];
        podData.label = pod?.label;
        podData.testDataAttributes = {
          'data-pod-id': pod?.id,
        };
      });
      forEach(topologyViewModel.racks, (rackData, rackId) => {
        const rack = topologyData.indexedRacks?.[rackId];
        rackData.label = rack?.label;
        rackData.testDataAttributes = {
          'data-rack-id': rack?.rack_id,
          'data-pod-id': rack?.pod_id,
        };
      });
      forEach(topologyViewModel.accessGroups, (accessGroupData, accessGroupId) => {
        const accessGroup = topologyData.indexedAccessGroups?.[accessGroupId];
        accessGroupData.label = accessGroup?.label;
        accessGroupData.testDataAttributes = {
          'data-access-group-id': accessGroup?.access_group_id,
          'data-rack-id': accessGroup?.rack_id,
          'data-pod-id': accessGroup?.pod_id,
        };
      });
      let vlanIndex = 0;
      const vlanCount = topologyData.vlans.length;
      forEach(topologyViewModel.vlans, (vlan, vlanId) => {
        const vlanData = topologyData.indexedVlans?.[vlanId];
        vlan.label = vlanData?.label;
        vlan.colorName = VLAN_COLOR_NAMES[vlanIndex % VLAN_COLOR_NAMES.length];
        vlan.nodeLinks = transform(vlanData.endpoints, (acc, nodeId) => {
          const nodeData = topologyViewModel.nodes[nodeId];
          if (!nodeData) return;
          const padding = 3;
          acc.push({
            nodeConnection: {
              x: nodeData.left + nodeData.width,
              y: Math.ceil(vlanCount === 1 ?
                nodeData.top + 0.5 * nodeData.height :
                nodeData.top + padding + vlanIndex / (vlanCount - 1) * (nodeData.height - 2 * padding)
              ),
            },
            busX: nodeData.left + nodeData.width + (vlanCount - vlanIndex) * TOPOLOGY_CONFIG.vlanGap,
          });
        }, [] as VlanNodeLink[]);
        vlanIndex++;
      });
      topologyViewModel.layout = layout.layout;

      return topologyViewModel;
    },
    [options.showSpines, options.showSuperspines, topologyData, topologySettings.expandNodes,
      topologySettings.expandExternalGenerics, options.shouldRenderRackDevices]
  );

  topologyViewModel.interfaces = useMemo(
    () => getNodeInterfaces(topologyData.links, topologyViewModel.nodes),
    [topologyData.links, topologyViewModel.nodes]
  );

  topologyViewModel.links = useMemo(() => {
    return transform(topologyData.links, (acc, link) => {
      const point1 = topologyViewModel.interfaces[link.endpoints[0].if_id]?.position;
      const point2 = topologyViewModel.interfaces[link.endpoints[1].if_id]?.position;

      if (point1 && point2) {
        acc[link.id] = {point1, point2, sourceLink: link};
      }
    }, {});
  }, [topologyData.links, topologyViewModel.interfaces]);

  return topologyViewModel;
};
