import {observable, makeObservable, action, computed} from 'mobx';
import {map, filter, xor, without, forEach, takeRight, size, keyBy, transform} from 'lodash';

import Step from './Step';

class SelectionStore {
  @observable.struct selectedNodesIds = [];
  @observable keptNodesIds = [];
  @observable isSelecting = false;
  @observable selectionAreaStart = {x: 0, y: 0};
  @observable selectionAreaEnd = {x: 0, y: 0};
  @observable isCtrlPressed = false;
  @observable offset = {x: 0, y: 0};

  @observable batchChanges = [];

  @computed
  get keyedSelectedNodesIds() {
    return keyBy(this.selectedNodesIds);
  }

  @computed
  get size() {
    return size(this.selectedNodesIds);
  }

  @action
  clear() {
    this.keptNodesIds = [];
    this.selectedNodesIds = [];
  }

  @action
  select(...nodesIds) {
    this.selectedNodesIds = [...nodesIds];
  }

  isSelected(nodeId) {
    return this.selectedNodesIds.includes(nodeId);
  }

  @action
  toggle(nodeId) {
    this.selectedNodesIds = this.isSelected(nodeId) ?
      without(this.selectedNodesIds, nodeId) : [...this.selectedNodesIds, nodeId];
  }

  @action
  remove(nodeId) {
    if (this.isSelected(nodeId)) this.selectedNodesIds = without(this.selectedNodesIds, nodeId);
  }

  @action
  reset(event, pointTransformFn) {
    this.isCtrlPressed = (event?.ctrlKey || event?.altKey || event?.metaKey);
    if (this.isCtrlPressed) {
      this.keptNodesIds = [...this.selectedNodesIds];
    } else {
      this.clear();
    }
    this.selectionAreaStart = pointTransformFn(event.clientX, event.clientY);
    this.selectionAreaEnd = {...this.selectionAreaStart};
  }

  @action
  start(event, pointTransformFn) {
    this.reset(event, pointTransformFn);
    this.isSelecting = true;
  }

  @action
  change(event, nodes, pointTransformFn, onlyTopmost = false) {
    if (!this.isSelecting) return false;
    this.selectionAreaEnd = pointTransformFn(event.clientX, event.clientY);
    const areaNodeIds = map(
      filter(nodes, (node) => node.fallsWithin(this.selectionAreaStart, this.selectionAreaEnd)),
      'id'
    );
    this.selectedNodesIds = xor(this.keptNodesIds, onlyTopmost ? takeRight(areaNodeIds) : areaNodeIds);
  }

  @action
  end() {
    this.isSelecting = false;
  }

  @action
  toggleNodeSelection(event, {id}) {
    // If Extender key is down - keep the existing selection
    const isToggle = (event?.ctrlKey || event?.altKey || event?.metaKey);
    if (isToggle) {
      this.toggle(id);
    } else {
      this.select(id);
    }
  }

  @action
  setBatchChanges(batchChanges) {
    this.batchChanges = batchChanges;
  }

  @action
  fixInitialState(nodes) {
    this.setBatchChanges(
      transform(
        this.selectedNodesIds,
        (acc, nodeId) => {
          const node = nodes[nodeId];
          acc.push(Step.modification(node).inverted);
          if (node?.isPaired) {
            // If node is paired, both must be tracked
            acc.push(Step.modification(node.pairedWith).inverted);
          }
        },
        []
      )
    );
  }

  @action
  fixFinalState(nodes) {
    forEach(this.batchChanges, (step) => {
      const node = nodes[step.id];
      step.setResult(node);
    });
    return this.batchChanges;
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }
}

export default SelectionStore;
