import {Component, Fragment} from 'react';
import {deserialize} from 'serializr';
import {find, groupBy, mapValues, isPlainObject, isEmpty, compact, keys, map, uniq} from 'lodash';
import {action, comparer, computed, observable, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Header, Label, Popup, Segment, Grid, Table, Icon} from 'semantic-ui-react';
import cx from 'classnames';

import {DeviceProfile} from '../../deviceProfiles/stores';
import {InterfaceMap} from '../../interfaceMaps/stores';

@observer
export default class PortMapView extends Component {
  constructor(props) {
    super(props);

    makeObservable(this);

    this.deviceProfile = deserialize(DeviceProfile, this.props.deviceProfile);
    this.disposeInterfaceMapUpdater = reaction(
      () => this.props.interfaceMap,
      (interfaceMap) => {
        this.interfaceMap = deserialize(InterfaceMap, interfaceMap || {});
      },
      {equals: comparer.structural, fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeInterfaceMapUpdater();
  }

  @computed get portGroups() {
    const slots = groupBy(this.deviceProfile.ports, 'slotId');
    return mapValues(slots, (ports) => groupBy(ports, 'panelId'));
  }

  render() {
    return (
      <Fragment>
        {keys(this.portGroups)
          .sort((a, b) => Number(a) - Number(b))
          .map((slotId) =>
            <PortGroup
              key={slotId}
              {...this.props}
              ports={this.portGroups[slotId]}
              label={`Slot #${slotId}`}
              deviceProfile={this.deviceProfile}
            />
          )
        }
      </Fragment>
    );
  }
}

@observer
class PortGroup extends Component {
  @observable selectedPortId = null;

  @action
  selectPort = (portId) => {
    this.selectedPortId = portId;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get selectedPort() {
    return find(this.props.ports, {portId: this.selectedPortId});
  }

  renderNestedGroups() {
    return keys(this.props.ports)
      .sort((a, b) => Number(a) - Number(b))
      .map((groupId) =>
        <PortGroup
          key={groupId}
          {...this.props}
          ports={this.props.ports[groupId]}
          label={`Panel #${groupId}`}
        />
      );
  }

  render() {
    const {
      label, ports, deviceProfile, interfaceMap, links, disabled
    } = this.props;

    if (isPlainObject(ports)) {
      if (deviceProfile.hasSlots) {
        return (
          <div key={label} className='slot-container'>
            <Header as='h3' content={label} />
            <Segment.Group>
              {this.renderNestedGroups()}
            </Segment.Group>
          </div>
        );
      }

      return (
        <div>
          {this.renderNestedGroups()}
        </div>
      );
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PortMap
              selectedPortId={this.selectedPortId}
              selectPort={this.selectPort}
              {...{ports, deviceProfile, interfaceMap, links}}
            />
          </Grid.Column>
        </Grid.Row>
        {this.selectedPortId !== null &&
          <Grid.Row>
            <Grid.Column>
              <PortDetails
                port={this.selectedPort}
                {...{
                  deviceProfile, interfaceMap, links, disabled
                }}
              />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    );
  }
}

@observer
class PortMap extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed.struct get portEndpoints() {
    const {ports, links, interfaceMap, selectedEndpoints} = this.props;
    return ports.reduce((acc, {portId}) => {
      const portInterfaces = interfaceMap?.interfaces?.filter(({mapping}) => mapping[0] === portId) || [];
      const endpoints = (links || [])
        .reduce(
          (result, {ifcName, endpoint}) => {
            const existsInInterfaceMap = portInterfaces.some(({name}) => name === ifcName);
            if (existsInInterfaceMap) {
              result.push(endpoint);
            }
            return result;
          },
          []
        );
      const hasSelectedEndpoints =
        this.props.links && !!selectedEndpoints && endpoints.some(({id}) => selectedEndpoints.get(id));
      acc[portId] = {...{endpoints, hasSelectedEndpoints}};
      return acc;
    }, {});
  }

  renderPort = (port) => {
    const {
      selectedPortId, selectPort, deviceProfile, links, toggleEndpoint
    } = this.props;

    const {endpoints, hasSelectedEndpoints} = this.portEndpoints[port.portId];
    const colors = uniq(map(endpoints, 'color'));
    const selected = port.portId === selectedPortId;

    return (
      <Port
        key={port.portId}
        selected={!links && selected}
        hasSelectedEndpoints={hasSelectedEndpoints}
        select={() => {
          if (!links || endpoints.length) {
            selectPort(selected ? null : port.portId);
            if (
              links && endpoints.length === 1 && toggleEndpoint &&
              (selected && hasSelectedEndpoints || !selected && !hasSelectedEndpoints)
            ) {
              toggleEndpoint(endpoints[0].id);
            }
          }
        }}
        style={links ? {} : {background: deviceProfile.connectorTypeColors[port.connectorType]}}
        autonegotiation={deviceProfile.isAutoNegPort(port.portId)}
        color={colors.length > 1 ? 'blue' : colors[0]}
        inverted={!links || !!endpoints.length}
        {...port}
      />
    );
  };

  render() {
    const {renderPort, props: {ports}} = this;

    const rows = groupBy(ports, 'rowId');
    return (
      <div className='device-profile-port-map-container'>
        <table className='device-profile-port-map'>
          <tbody>
            {keys(rows).map((rowId) =>
              <tr key={rowId}>{rows[rowId].map(renderPort)}</tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

function Port({
  portId, selected, select, transformations, color, style, autonegotiation, inverted, hasSelectedEndpoints
}) {
  return (
    <Segment
      as='td'
      {...{style, inverted}}
      className={cx(
        'port no-summary',
        color,
        {selected: selected || hasSelectedEndpoints}
      )}
      onClick={select}
    >
      <span key='port-id' className='port-id'>
        {autonegotiation &&
          <Icon className='autoneg' name='exchange' />
        }
        {portId}
      </span>
      {transformations.length > 1 &&
        <Icon key='port-icon' name='fork' rotated='clockwise' />
      }
    </Segment>
  );
}

function PortDetails({
  port, deviceProfile, interfaceMap, links, disabled
}) {
  const endpoints = !!links &&
    interfaceMap.interfaces
      .map((ifc) => {
        const {name, mapping} = ifc;
        const link = find(links, {ifcName: name});

        if (mapping[0] === port.portId && link) {
          return {...ifc, link};
        }
      })
      .filter((item) => !!item);

  return (
    <div className='port-details compact'>
      <Table definition size='small'>
        <Table.Body>
          {port.transformations.map(({id: transformationId, isDefault, interfaces}) => {
            if (!isEmpty(endpoints) && transformationId !== endpoints[0].mapping[1]) return null;

            const {speed} = interfaces.find(({speed}) => speed) || {};
            return (
              <Table.Row key={transformationId}>
                <Table.Cell width={4}>
                  {`Port #${port.portId} Tr. #${transformationId}` + ((isDefault || speed) ?
                    ` (${compact([
                      speed ? speed.label : '',
                      isDefault ? 'default' : ''
                    ]).join(', ')})` : '')
                  }
                </Table.Cell>
                <Table.Cell>
                  {interfaces.map((ifc) => {
                    const endpoint = endpoints && endpoints.find((e) => e.name === ifc.name);
                    const ifcDisabled = disabled || (!!links && !endpoint);
                    const autonegotiation = deviceProfile.isAutoNegInterface(port.portId, transformationId, ifc.id);
                    return <Interface
                      key={ifc.id}
                      {...{ifc, autonegotiation}}
                      endpoint={endpoint}
                      disabled={ifcDisabled}
                    />;
                  })}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

function Interface({endpoint, ifc, onClick, disabled, autonegotiation}) {
  if (endpoint) {
    const {link: {linkLabel, endpoint: {color, title, serverLabel}}} = endpoint;
    return (
      <Popup
        trigger={
          <Label
            size='small'
            basic
            color={color}
            onClick={onClick}
            className={cx({disabled})}
            style={{cursor: 'pointer'}}
          >
            {autonegotiation && '[Autoneg] '}
            {ifc.name}
          </Label>
        }
        wide
        content={
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{'Interface Name'}</Table.Cell>
                <Table.Cell>{ifc.name || <Label>{'not assigned'}</Label>}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{'Server'}</Table.Cell>
                <Table.Cell>{serverLabel}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{'Link Label'}</Table.Cell>
                <Table.Cell>{linkLabel}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{'Endpoint'}</Table.Cell>
                <Table.Cell>
                  <Label color={color} content={title} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        }
      />
    );
  } else {
    return <InterfaceLabel {...{ifc, onClick, disabled, autonegotiation}} />;
  }
}

function InterfaceLabel({ifc, autonegotiation, onClick, disabled}) {
  return (
    <Label
      size='small'
      basic
      color={!disabled && ifc.state === 'active' && ifc.speed ? ifc.speed.color : 'grey'}
      onClick={onClick}
      className={cx({disabled: disabled || ifc.state !== 'active'})}
    >
      {autonegotiation && '[Autoneg] '}
      {ifc.name}
    </Label>
  );
}
