import {scaleOrdinal} from '@visx/scale';
import {brandColorNames} from 'apstra-ui-common';
import {forEach, get, set, sortBy, transform} from 'lodash';
import * as THREE from 'three';

export const selfLinkRadius = 10;
export const duplicateLinkScaleIncrement = 0.3;
export const linkArrowHeight = 6;
export const linkArrowHeadAngle = Math.PI / 12;
export const defaultNodeRadius = 7;
export const highlightedLinkWidth = 2;
export const hoveredLinkWidth = 3;
const linkCurveDivisions = 20;
const selfLinkCurveDivisions = 50;

class EllipseCurve3 extends THREE.EllipseCurve {
  getPoint(t) {
    const point2 = super.getPoint(t);
    return new THREE.Vector3(point2.x, point2.y, 0);
  }
}

export const getDuplicateScale = (duplicateIndex = 1) =>
  1 + duplicateLinkScaleIncrement * (duplicateIndex - 1);

const getLinkCurve = (link, EllipseCurve = THREE.EllipseCurve) => {
  const {source, target, duplicateIndex = 1} = link;
  const q = Math.sqrt((target.x - source.x) ** 2 + (target.y - source.y) ** 2);
  const radius = q * 2 - q / duplicateIndex;
  const aX = (source.x + target.x) / 2 + Math.sqrt(radius ** 2 - ((q / 2) ** 2)) * (source.y - target.y) / q;
  const aY = (source.y + target.y) / 2 + Math.sqrt(radius ** 2 - ((q / 2) ** 2)) * (target.x - source.x) / q;
  const aStartAngle = Math.atan2(source.y - aY, source.x - aX);
  const aEndAngle = Math.atan2(target.y - aY, target.x - aX);
  return new EllipseCurve(aX, aY, radius, radius, aStartAngle, aEndAngle);
};

const getSelfLinkCurve = (link, EllipseCurve = THREE.EllipseCurve) => {
  const {source, duplicateIndex = 1} = link;
  const radius = selfLinkRadius * getDuplicateScale(duplicateIndex);
  return new EllipseCurve(source.x - radius, source.y, radius, radius);
};

export const linkGeometry = new THREE.BufferGeometry().setFromPoints(
  getLinkCurve({source: {x: -0.5, y: 0}, target: {x: 0.5, y: 0}}).getPoints(linkCurveDivisions)
);

export const selfLinkGeometry = new THREE.BufferGeometry().setFromPoints(
  getSelfLinkCurve({source: {x: 0, y: 0}}).getPoints(selfLinkCurveDivisions)
);

export const arrowGeometry = new THREE.BufferGeometry().setFromPoints([
  new THREE.Vector2(
    linkArrowHeight,
    linkArrowHeight * Math.tan(-linkArrowHeadAngle),
  ),
  new THREE.Vector2(0, 0),
  new THREE.Vector2(
    linkArrowHeight,
    linkArrowHeight * Math.tan(linkArrowHeadAngle),
  ),
]);

export class TubeGeometryCache {
  linkCache = {};
  selfLinkCache = {};

  constructor(cacheScaleBase = 1.1) {
    this.cacheScaleBase = cacheScaleBase;
  }

  getLinkTubeGeometry = (length, width) => {
    const {linkCache, cacheScaleBase} = this;
    length = Math.max(0.5, length);
    const scaleIndex = Math.round(Math.log(length) / Math.log(cacheScaleBase));
    const scaledLength = cacheScaleBase ** scaleIndex;

    if (!get(linkCache, [width, scaleIndex])) {
      set(
        linkCache,
        [width, scaleIndex],
        new THREE.TubeGeometry(
          getLinkCurve(
            {source: {x: -0.5 * scaledLength, y: 0}, target: {x: 0.5 * scaledLength, y: 0}},
            EllipseCurve3
          ),
          linkCurveDivisions,
          width,
          4
        )
      );
    }

    return {
      geometry: get(linkCache, [width, scaleIndex]),
      scale: length / scaledLength
    };
  };

  getSelfLinkTubeGeometry = ({duplicateIndex}, width) => {
    const {selfLinkCache} = this;
    if (!selfLinkCache[duplicateIndex]) {
      selfLinkCache[duplicateIndex] = new THREE.TubeGeometry(
        getSelfLinkCurve({duplicateIndex, source: {x: 0, y: 0}}, EllipseCurve3),
        selfLinkCurveDivisions,
        width,
        4
      );
    }
    return selfLinkCache[duplicateIndex];
  };

  dispose = () => {
    const {linkCache, selfLinkCache} = this;
    forEach(linkCache,
      (geometries) => forEach(geometries, (geometry) => geometry?.dispose())
    );
    forEach(selfLinkCache,
      (geometry) => geometry?.dispose()
    );
  };
}

export const buildGraphNodesStyles = (roles) => {
  const sortedRoles = sortBy(roles);
  const colors = scaleOrdinal().domain(sortedRoles).range(brandColorNames);
  return transform(sortedRoles, (acc, typeName) => {
    acc[typeName] = {
      color: colors(typeName),
      shape: 'circle',
    };
  }, {});
};
