import {PureComponent} from 'react';
import {Label, Popup} from 'semantic-ui-react';
import {isNull, isEmpty, map} from 'lodash';
import {formatBytes, formatSeconds} from 'apstra-ui-common';

import {ReactComponent as StagePersisted} from '../../../styles/icons/iba/stage-persisted.svg';

export default class PersistedLabel extends PureComponent {
  getLabelDetail = () => {
    const {duration, diskUsage} = this.props;
    if (!diskUsage && isNull(duration)) {
      return '';
    }
    if (!diskUsage) {
      return formatSeconds(duration);
    }
    if (isNull(duration)) {
      return formatBytes(diskUsage.size || 0);
    }
    return `${formatSeconds(duration)} / ${formatBytes(diskUsage.size || 0)}`;
  };

  render() {
    const {diskUsage, duration, dataByTypes} = this.props;
    const labelDetail = this.getLabelDetail();
    return (
      <Popup
        className='persisted-label-popup'
        trigger={
          <Label
            className='persisted-label-trigger'
            icon={<StagePersisted className='stage-icon' />}
            content='Persisted'
            detail={labelDetail}
          />
        }
        {...dataByTypes && !isEmpty(dataByTypes) && {wide: 'very'}}
      >
        {dataByTypes && !isEmpty(dataByTypes)
          ? (
            <div className='popup-sections'>
              {isNull(duration) && (
                <div className='popup-section'>
                  <strong>{'Retention duration: '}</strong>
                  <ul>
                    {map(dataByTypes, ({retention_period: retentionPeriod}, type) => (
                      <li key={type}>
                        {`${type}: ${formatSeconds(retentionPeriod)}`}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className='popup-section'>
                <strong>{'Consumed disk size: '}</strong>
                <ul>
                  {map(dataByTypes, ({size}, type) => (
                    <li key={type}>
                      {`${type}: ${formatBytes(size || 0)}`}
                      <br />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )
          : (
            <>
              {!isNull(duration) && (
                <div>
                  <strong>{'Retention duration: '}</strong>
                  {formatSeconds(duration)}
                </div>
              )}
              {diskUsage && (
                <div>
                  <strong>{'Consumed disk size: '}</strong>
                  {formatBytes(diskUsage.size || 0)}
                </div>
              )}
            </>
          )}
      </Popup>
    );
  }
}
