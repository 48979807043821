import {Button, Icon, Popup} from 'semantic-ui-react';
import {map, includes, filter, concat} from 'lodash';
import cx from 'classnames';
import {DropdownControl, schemaPropertyDefaultValues} from 'apstra-ui-common';

import {GRAPH_QUERY_MATCHERS, GRAPH_QUERY_TAG_MATCHERS} from '../pythonExpression/consts';
import buildPropertyDocHTMLFromSchema from '../pythonExpression/buildPropertyDocHTMLFromSchema';
import {COMPARISON_OPERATORS, EXCLUDE_MATCHERS} from './consts';
import {getDefaultValueForMatcher} from './utils';
import QueryDeleteButton from './QueryDeleteButton';
import QueryMatcherValue from './QueryMatcherValue';
import QueryConditionValues from './QueryConditionValues';

import './QueryNodeAttribute.less';

const QueryNodeAttribute = ({value, options, attributesMap, name, matcher, onDelete, onChange}) => {
  const attribute = attributesMap[name];
  const isTag = includes(['tag', 'tags'], name);
  const filterMatchers = (matchers) => {
    const filteredMatchers = filter(matchers, ({name}) => !EXCLUDE_MATCHERS.has(name));
    if (attribute?.enum?.length > 0) {
      return filter(filteredMatchers, ({name}) => !COMPARISON_OPERATORS.has(name));
    }
    return filteredMatchers;
  };
  const matchers = isTag ?
    GRAPH_QUERY_TAG_MATCHERS :
    filterMatchers(concat(
      [{name: '=', value: '=', description: 'Checks if the property is equal to the argument.'}],
      GRAPH_QUERY_MATCHERS
    ));
  const matcherOptions = map(matchers, ({name, value, description}) => ({
    key: name, value: value ?? name, text: name, description
  }));
  const content = buildPropertyDocHTMLFromSchema(attribute);
  return [
    <div key='attribute' className='query-node-attribute'>
      <DropdownControl
        options={[...options, {text: name, value: name, key: name, description: attribute?.description}]}
        value={name}
        onChange={(name) => {
          const type = attributesMap[name]?.type ?? 'string';
          const defaultValue = schemaPropertyDefaultValues[type] ?? '';
          onChange('name', name);
          onChange('matcher', name === 'tag' || name === 'tags' ? 'has_all' : '=');
          onChange('type', type);
          onChange('value', defaultValue);
        }}
        fluid
        search
      />
      <Popup
        trigger={
          <Button
            size='tiny'
            icon={
              <Icon name='question' bordered inverted size='tiny' color='grey' />
            }
          />
        }
        position='top right'
        content={
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: content}}
          />
        }
      />
      <div
        className={
          cx('matcher-block', {two: attribute?.name !== 'name' && matcher !== '_or' && matcher !== '_and'})
        }
      >
        {(attribute?.name !== 'name' && attribute?.type !== 'boolean') &&
          <DropdownControl
            options={matcherOptions}
            value={matcher}
            onChange={(value) => {
              onChange('matcher', value);
              onChange('value', getDefaultValueForMatcher(value, attribute));
              onChange('type', attribute?.type);
            }}
            placeholder='Select matcher'
            search
          />
        }
        <QueryMatcherValue
          attribute={attribute}
          matcher={matcher}
          value={value}
          isTag={isTag}
          onChange={onChange}
        />
      </div>
      <QueryDeleteButton
        content='Delete attribute'
        onClick={onDelete}
      />
    </div>,
    (matcher === '_or' || matcher === '_and') &&
      <QueryConditionValues
        key='condition'
        values={value}
        onChange={onChange}
        isTag={isTag}
        attribute={attribute}
        matcher={matcher}
        matcherOptions={matcherOptions}
      />
  ];
};

export default QueryNodeAttribute;
