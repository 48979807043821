/* eslint-disable sonarjs/no-duplicate-string */
import {action, autorun, computed, makeObservable, observable} from 'mobx';
import {find, forEach, keyBy} from 'lodash';
import ELK from 'elkjs/lib/elk.bundled.js';

import {ctrlNodeWidth, ctrlNodeHeight} from '../const';

const nodeDiameter = (ctrlNodeWidth ** 2 + ctrlNodeHeight ** 2) ** 0.5;

class NodeArrangerStore {
  elk = new ELK();
  @observable.ref arrangedPositions = null;
  @observable arrangeType = 'user-defined';
  @observable isCalculating = false;
  @observable readonly = true;

  @action
  setIsCalculating = (isCalculating) => {
    this.isCalculating = isCalculating;
  };

  @action
  setReadonly = (readonly) => {
    this.readonly = readonly;
  };

  @action
  setArrangeType = (arrangeType) => {
    this.arrangeType = arrangeType;
  };

  @action
  setArrangedPositions = (arrangedPositions) => {
    this.arrangedPositions = arrangedPositions;
  };

  @computed
  get positions() {
    if (this.isCalculating || this.arrangeType === 'user-defined') {
      return null;
    }
    return this.arrangedPositions;
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.updateReactionDisposer = autorun(this.arrange);
  }

  dispose = () => {
    this.updateReactionDisposer();
  };

  arrange = () => {
    const elkAlgorithm = this.arrangeType;
    if (elkAlgorithm === 'user-defined' || !this.readonly) {
      return;
    }
    this.setIsCalculating(true);
    const graph = {
      id: 'root',
      layoutOptions: {
        'elk.algorithm': elkAlgorithm,
        ...(find(algorithms, {id: elkAlgorithm})?.options ?? {})
      },
      children: [],
      edges: [],
    };

    forEach(this.rootStore.cablingMap.nodes, (node) => {
      graph.children.push({
        id: node.id,
        width: ctrlNodeWidth,
        height: ctrlNodeHeight,
        x: node._position.x,
        y: node._position.y
      });
    });

    forEach(this.rootStore.cablingMap.links, (link) => {
      graph.edges.push({id: link.id, sources: [link.endpoint1.nodeId], targets: [link.endpoint2.nodeId]});
    });

    const savePositions = (graph) => {
      this.setArrangedPositions(keyBy(graph.children, 'id'));
    };

    this.elk.layout(graph)
      .then(savePositions)
      .finally(() => this.setIsCalculating(false));
  };
}

export default NodeArrangerStore;

export const algorithms = [
  {
    id: 'org.eclipse.elk.layered',
    name: 'Layered',
    description: `Layer-based algorithm provided by the Eclipse Layout Kernel. Arranges as many edges as possible into
    one direction by placing nodes into subsequent layers. This implementation supports different routing styles 
    (straight, orthogonal, splines); if orthogonal routing is selected, arbitrary port constraints are respected, thus 
    enabling the layout of block diagrams such as actor-oriented models or circuit schematics. Furthermore, full 
    layout of compound graphs with cross-hierarchy edges is supported when the respective option is activated on the 
    top level.`,
    category: 'org.eclipse.elk.layered',
    knownOptions: [
      'org.eclipse.elk.spacing.commentComment',
      'org.eclipse.elk.spacing.commentNode',
      'org.eclipse.elk.spacing.componentComponent',
      'org.eclipse.elk.spacing.edgeEdge',
      'org.eclipse.elk.spacing.edgeLabel',
      'org.eclipse.elk.spacing.edgeNode',
      'org.eclipse.elk.spacing.labelLabel',
      'org.eclipse.elk.spacing.labelPortHorizontal',
      'org.eclipse.elk.spacing.labelPortVertical',
      'org.eclipse.elk.spacing.labelNode',
      'org.eclipse.elk.spacing.nodeNode',
      'org.eclipse.elk.spacing.nodeSelfLoop',
      'org.eclipse.elk.spacing.portPort',
      'org.eclipse.elk.spacing.individual',
      'org.eclipse.elk.layered.spacing.baseValue',
      'org.eclipse.elk.layered.spacing.edgeEdgeBetweenLayers',
      'org.eclipse.elk.layered.spacing.edgeNodeBetweenLayers',
      'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers',
      'org.eclipse.elk.priority',
      'org.eclipse.elk.layered.priority.direction',
      'org.eclipse.elk.layered.priority.shortness',
      'org.eclipse.elk.layered.priority.straightness',
      'org.eclipse.elk.layered.wrapping.strategy',
      'org.eclipse.elk.layered.wrapping.additionalEdgeSpacing',
      'org.eclipse.elk.layered.wrapping.correctionFactor',
      'org.eclipse.elk.layered.wrapping.cutting.strategy',
      'org.eclipse.elk.layered.wrapping.cutting.cuts',
      'org.eclipse.elk.layered.wrapping.cutting.msd.freedom',
      'org.eclipse.elk.layered.wrapping.validify.strategy',
      'org.eclipse.elk.layered.wrapping.validify.forbiddenIndices',
      'org.eclipse.elk.layered.wrapping.multiEdge.improveCuts',
      'org.eclipse.elk.layered.wrapping.multiEdge.distancePenalty',
      'org.eclipse.elk.layered.wrapping.multiEdge.improveWrappedEdges',
      'org.eclipse.elk.layered.nodePlacement.networkSimplex.nodeFlexibility',
      'org.eclipse.elk.layered.nodePlacement.networkSimplex.nodeFlexibility.default',
      'org.eclipse.elk.layered.edgeRouting.splines.mode',
      'org.eclipse.elk.layered.edgeRouting.splines.sloppy.layerSpacingFactor',
      'org.eclipse.elk.padding',
      'org.eclipse.elk.edgeRouting',
      'org.eclipse.elk.port.borderOffset',
      'org.eclipse.elk.randomSeed',
      'org.eclipse.elk.aspectRatio',
      'org.eclipse.elk.noLayout',
      'org.eclipse.elk.portConstraints',
      'org.eclipse.elk.port.side',
      'org.eclipse.elk.debugMode',
      'org.eclipse.elk.alignment',
      'org.eclipse.elk.hierarchyHandling',
      'org.eclipse.elk.separateConnectedComponents',
      'org.eclipse.elk.insideSelfLoops.activate',
      'org.eclipse.elk.insideSelfLoops.yo',
      'org.eclipse.elk.nodeSize.constraints',
      'org.eclipse.elk.nodeSize.options',
      'org.eclipse.elk.nodeSize.fixedGraphSize',
      'org.eclipse.elk.direction',
      'org.eclipse.elk.nodeLabels.placement',
      'org.eclipse.elk.nodeLabels.padding',
      'org.eclipse.elk.portLabels.placement',
      'org.eclipse.elk.portLabels.nextToPortIfPossible',
      'org.eclipse.elk.portLabels.treatAsGroup',
      'org.eclipse.elk.portAlignment.default',
      'org.eclipse.elk.portAlignment.north',
      'org.eclipse.elk.portAlignment.south',
      'org.eclipse.elk.portAlignment.west',
      'org.eclipse.elk.portAlignment.east',
      'org.eclipse.elk.layered.unnecessaryBendpoints',
      'org.eclipse.elk.layered.layering.strategy',
      'org.eclipse.elk.layered.layering.nodePromotion.strategy',
      'org.eclipse.elk.layered.thoroughness',
      'org.eclipse.elk.layered.layering.layerConstraint',
      'org.eclipse.elk.layered.cycleBreaking.strategy',
      'org.eclipse.elk.layered.crossingMinimization.strategy',
      'org.eclipse.elk.layered.crossingMinimization.forceNodeModelOrder',
      'org.eclipse.elk.layered.crossingMinimization.greedySwitch.activationThreshold',
      'org.eclipse.elk.layered.crossingMinimization.greedySwitch.type',
      'org.eclipse.elk.layered.crossingMinimization.greedySwitchHierarchical.type',
      'org.eclipse.elk.layered.crossingMinimization.semiInteractive',
      'org.eclipse.elk.layered.mergeEdges',
      'org.eclipse.elk.layered.mergeHierarchyEdges',
      'org.eclipse.elk.layered.interactiveReferencePoint',
      'org.eclipse.elk.layered.nodePlacement.strategy',
      'org.eclipse.elk.layered.nodePlacement.bk.fixedAlignment',
      'org.eclipse.elk.layered.feedbackEdges',
      'org.eclipse.elk.layered.nodePlacement.linearSegments.deflectionDampening',
      'org.eclipse.elk.layered.edgeRouting.selfLoopDistribution',
      'org.eclipse.elk.layered.edgeRouting.selfLoopOrdering',
      'org.eclipse.elk.contentAlignment',
      'org.eclipse.elk.layered.nodePlacement.bk.edgeStraightening',
      'org.eclipse.elk.layered.compaction.postCompaction.strategy',
      'org.eclipse.elk.layered.compaction.postCompaction.constraints',
      'org.eclipse.elk.layered.compaction.connectedComponents',
      'org.eclipse.elk.layered.highDegreeNodes.treatment',
      'org.eclipse.elk.layered.highDegreeNodes.threshold',
      'org.eclipse.elk.layered.highDegreeNodes.treeHeight',
      'org.eclipse.elk.nodeSize.minimum',
      'org.eclipse.elk.junctionPoints',
      'org.eclipse.elk.edge.thickness',
      'org.eclipse.elk.edgeLabels.placement',
      'org.eclipse.elk.edgeLabels.inline',
      'org.eclipse.elk.layered.crossingMinimization.hierarchicalSweepiness',
      'org.eclipse.elk.port.index',
      'org.eclipse.elk.commentBox',
      'org.eclipse.elk.hypernode',
      'org.eclipse.elk.port.anchor',
      'org.eclipse.elk.partitioning.activate',
      'org.eclipse.elk.partitioning.partition',
      'org.eclipse.elk.layered.layering.minWidth.upperBoundOnWidth',
      'org.eclipse.elk.layered.layering.minWidth.upperLayerEstimationScalingFactor',
      'org.eclipse.elk.position',
      'org.eclipse.elk.layered.allowNonFlowPortsToSwitchSides',
      'org.eclipse.elk.layered.layering.nodePromotion.maxIterations',
      'org.eclipse.elk.layered.edgeLabels.sideSelection',
      'org.eclipse.elk.layered.edgeLabels.centerLabelPlacementStrategy',
      'org.eclipse.elk.margins',
      'org.eclipse.elk.layered.layering.coffmanGraham.layerBound',
      'org.eclipse.elk.layered.nodePlacement.favorStraightEdges',
      'org.eclipse.elk.spacing.portsSurrounding',
      'org.eclipse.elk.layered.directionCongruency',
      'org.eclipse.elk.layered.portSortingStrategy',
      'org.eclipse.elk.layered.edgeRouting.polyline.slopedEdgeZoneWidth',
      'org.eclipse.elk.layered.layering.layerChoiceConstraint',
      'org.eclipse.elk.layered.crossingMinimization.positionChoiceConstraint',
      'org.eclipse.elk.interactiveLayout',
      'org.eclipse.elk.layered.layering.layerId',
      'org.eclipse.elk.layered.crossingMinimization.positionId',
      'org.eclipse.elk.layered.considerModelOrder.strategy',
      'org.eclipse.elk.layered.considerModelOrder.longEdgeStrategy',
      'org.eclipse.elk.layered.considerModelOrder.crossingCounterNodeInfluence',
      'org.eclipse.elk.layered.considerModelOrder.crossingCounterPortInfluence',
      'org.eclipse.elk.layered.considerModelOrder.noModelOrder',
      'org.eclipse.elk.layered.considerModelOrder.components',
      'org.eclipse.elk.layered.generatePositionAndLayerIds'
    ],
    supportedFeatures: [
      'SELF_LOOPS',
      'INSIDE_SELF_LOOPS',
      'MULTI_EDGES',
      'EDGE_LABELS',
      'PORTS',
      'COMPOUND',
      'CLUSTERS'
    ]
  },
  {
    id: 'org.eclipse.elk.stress',
    name: 'Stress',
    description: `Minimizes the stress within a layout using stress majorization. Stress exists if the euclidean 
    distance between a pair of nodes doesn't match their graph theoretic distance, that is, the shortest path 
    between the two nodes. The method allows to specify individual edge lengths.`,
    category: 'org.eclipse.elk.force',
    options: {
      'org.eclipse.elk.stress.desiredEdgeLength': nodeDiameter,
      'org.eclipse.elk.nodeSize.minimum': nodeDiameter,
    },
    knownOptions: [
      'org.eclipse.elk.interactive',
      'org.eclipse.elk.edgeLabels.inline',
      'org.eclipse.elk.nodeSize.constraints',
      'org.eclipse.elk.nodeSize.minimum',
      'org.eclipse.elk.nodeSize.options',
      'org.eclipse.elk.nodeLabels.placement',
      'org.eclipse.elk.omitNodeMicroLayout',
      'org.eclipse.elk.portLabels.placement',
      'org.eclipse.elk.stress.fixed',
      'org.eclipse.elk.stress.dimension',
      'org.eclipse.elk.stress.epsilon',
      'org.eclipse.elk.stress.iterationLimit',
      'org.eclipse.elk.stress.desiredEdgeLength'
    ]
  },
  {
    id: 'org.eclipse.elk.force',
    name: 'Force',
    description: `Force-based algorithm provided by the Eclipse Layout Kernel. Implements methods that follow physical 
    analogies by simulating forces that move the nodes into a balanced distribution. Currently the original Eades 
    model and the Fruchterman - Reingold model are supported.`,
    category: 'org.eclipse.elk.force',
    options: {
      'org.eclipse.elk.spacing.nodeNode': 2,
      'org.eclipse.elk.padding': 1,
      'org.eclipse.elk.omitNodeMicroLayout': true,
    },
    knownOptions: [
      'org.eclipse.elk.priority',
      'org.eclipse.elk.spacing.nodeNode',
      'org.eclipse.elk.spacing.edgeLabel',
      'org.eclipse.elk.aspectRatio',
      'org.eclipse.elk.randomSeed',
      'org.eclipse.elk.separateConnectedComponents',
      'org.eclipse.elk.padding',
      'org.eclipse.elk.interactive',
      'org.eclipse.elk.portConstraints',
      'org.eclipse.elk.edgeLabels.inline',
      'org.eclipse.elk.omitNodeMicroLayout',
      'org.eclipse.elk.nodeSize.options',
      'org.eclipse.elk.nodeSize.constraints',
      'org.eclipse.elk.nodeLabels.placement',
      'org.eclipse.elk.portLabels.placement',
      'org.eclipse.elk.force.model',
      'org.eclipse.elk.force.temperature',
      'org.eclipse.elk.force.iterations',
      'org.eclipse.elk.force.repulsion',
      'org.eclipse.elk.force.repulsivePower'
    ],
    supportedFeatures: [
      'MULTI_EDGES',
      'EDGE_LABELS'
    ]
  },
  {
    id: 'org.eclipse.elk.sporeCompaction',
    name: 'Compaction',
    description: `ShrinkTree is a compaction algorithm that maintains the topology of a layout. The relocation of 
    diagram elements is based on contracting a spanning tree.`,
    knownOptions: [
      'org.eclipse.elk.underlyingLayoutAlgorithm',
      'org.eclipse.elk.processingOrder.treeConstruction',
      'org.eclipse.elk.processingOrder.spanningTreeCostFunction',
      'org.eclipse.elk.processingOrder.preferredRoot',
      'org.eclipse.elk.processingOrder.rootSelection',
      'org.eclipse.elk.padding',
      'org.eclipse.elk.spacing.nodeNode',
      'org.eclipse.elk.structure.structureExtractionStrategy',
      'org.eclipse.elk.compaction.compactionStrategy',
      'org.eclipse.elk.compaction.orthogonal',
      'org.eclipse.elk.debugMode'
    ]
  }
];
