import {useQueries} from '@tanstack/react-query';
import {find, some} from 'lodash';
import {useContext} from 'react';
import {
  RouterProvider, Navigate, createHashRouter,
  useRouteError, useParams, useSearchParams,
} from 'react-router-dom';
import {FetchData, FetchDataError, Loader, interpolateRoute, request} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import {queryKeys} from '../../queryKeys';
import ProbeDetails from './ProbeDetails';
import ProbeList from './ProbeList';
import AnomalyList from './AnomalyList';
import ReportList from './report/ReportList';
import ReportContainer from './report/ReportContainer';
import DashboardList from './DashboardList';
import DashboardDetails from './DashboardDetails';
import AutoEnabledDashboards from './AutoEnabledDashboards';

import './IBA.less';

export const routes = {
  probeList: '/api/blueprints/<blueprint_id>/probes',
  probeDetails: '/api/blueprints/<blueprint_id>/probes/<probe_id>',
  probeStage: '/api/blueprints/<blueprint_id>/probes/<probe_id>/query',
  probesBatchDelete: '/api/blueprints/<blueprint_id>/probes-batch-delete',
  cablingMap: '/api/blueprints/<blueprint_id>/experience/web/cabling-map',
  dashboardList: '/api/blueprints/<blueprint_id>/iba/dashboards',
  dashboardDetails: '/api/blueprints/<blueprint_id>/iba/dashboards/<dashboard_id>',
  dashboardsBatchDelete: '/api/blueprints/<blueprint_id>/iba/dashboards-batch-delete',
  dashboardExport: '/api/blueprints/<blueprint_id>/iba/dashboards/<dashboard_id>/export',
  dashboardImport: '/api/blueprints/<blueprint_id>/iba/dashboards/import',
  predefinedProbeList: '/api/blueprints/<blueprint_id>/iba/predefined-probes',
  predefinedProbeDetails: '/api/blueprints/<blueprint_id>/iba/predefined-probes/<predefined_probe>',
  predefinedProbeParameters: '/api/blueprints/<blueprint_id>/iba/predefined-probes/<predefined_probe>/<probe_id>',
  predefinedDashboard: '/api/blueprints/<blueprint_id>/iba/predefined-dashboards/<predefined_dashboard>',
  predefinedDashboardList: '/api/blueprints/<blueprint_id>/iba/predefined-dashboards',
  anomalyList: '/api/blueprints/<blueprint_id>/anomalies',
  anomalousStageList: '/api/blueprints/<blueprint_id>/iba/anomalous-stages',
  vShpereAnomalyResolver: (
    '/api/blueprints/<blueprint_id>/virtual_infra/predefined_probes/virtual_infra_vlan_match/anomaly_resolver'
  ),
  telemetryServiceWarnings: '/api/blueprints/<blueprint_id>/iba/probes/<probe_id>/ts-warnings/<stage_name>',
  telemetryServiceRegistry: '/api/telemetry-service-registry',
  diskSpaceUsageForProbe: '/api/metricdb/metric/iba/<blueprint_id>/<probe_id>/stats',
  diskSpaceUsageForStage: '/api/metricdb/metric/iba/<blueprint_id>/<probe_id>/<stage_name>/stats',
  predefinedProbeReportList: '/api/blueprints/<blueprint_id>/predefined-reports',
  predefinedProbeReportRegistry: '/api/blueprints/<blueprint_id>/predefined-reports/<report_name>',
  predefinedProbeReportQuery: '/api/blueprints/<blueprint_id>/predefined-report/<report_name>',
  referenceDesignSchemaDetails: '/api/docs/reference_design_schemas/<reference_design_name>',
};

const DashboardDetailsWithFetchData = () => {
  const {blueprintId, dashboardId, action} = useParams();
  return (
    <FetchData
      pollingInterval={DashboardDetails.pollingInterval}
      fetchParams={{blueprintId, dashboardId, action, routes}}
    >
      <DashboardDetails dashboardId={dashboardId} action={action} />
    </FetchData>
  );
};

const CreateDashboardWithFetchData = () => {
  const {blueprintId} = useParams();
  const {blueprintPermissions} = useContext(IBAContext);
  return (
    <FetchData
      pollingInterval={DashboardDetails.pollingInterval}
      fetchParams={{blueprintId, routes, blueprintPermissions}}
    >
      <DashboardDetails action='create' />
    </FetchData>
  );
};

const AnomalyListWrapper = (props) => {
  const [searchParams] = useSearchParams();
  const grouped = (searchParams.get('grouped') === 'true');
  return <AnomalyList grouped={grouped} {...props} />;
};

const ProbeDetailsWithFetchData = () => {
  const {blueprintId, probeId, action} = useParams();
  return (
    <FetchData
      pollingInterval={action ? null : ProbeDetails.pollingInterval}
      fetchParams={{blueprintId, probeId, action, routes}}
      fetchData={ProbeDetails.fetchData}
    >
      <ProbeDetails
        probeId={probeId}
        action={action}
      />
    </FetchData>
  );
};

const CreateProbeWithFetchData = () => {
  return (
    <FetchData
      pollingInterval={null}
      fetchData={ProbeDetails.fetchTelemetryServiceRegistryItems}
      fetchParams={{routes}}
    >
      <ProbeDetails action='create' />
    </FetchData>
  );
};

export const RouterErrorBoundary = () => {
  const error = useRouteError();
  return <FetchDataError error={error} />;
};

const router = createHashRouter([
  {
    // this will be briefly shown when navigating away from the Analytics tab
    path: '*',
    element: <></>,
    errorElement: <></>,
  },
  {
    path: '/blueprints/:blueprintId/analytics',
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: 'dashboards',
        children: [
          {
            path: ':dashboardId/:action?',
            Component: DashboardDetailsWithFetchData,
          },
          {
            index: true,
            Component: DashboardList,
          }
        ],
      },
      {
        path: 'auto-enabled-dashboards',
        Component: AutoEnabledDashboards,
      },
      {
        path: 'create-dashboard',
        Component: CreateDashboardWithFetchData,
      },
      {
        path: 'anomalies',
        Component: AnomalyListWrapper,
      },
      {
        path: 'probes',
        children: [
          {
            path: 'create-probe',
            Component: CreateProbeWithFetchData,
          },
          {
            path: ':probeId',
            children: [
              {
                path: 'stages/:stage/:action?',
                Component: ProbeDetailsWithFetchData,
              },
              {
                path: 'processors/:processor/:action?',
                Component: ProbeDetailsWithFetchData,
              },
              {
                path: ':action',
                Component: ProbeDetailsWithFetchData,
              },
              {
                index: true,
                Component: ProbeDetailsWithFetchData,
              }
            ],
          },
          {
            index: true,
            Component: ProbeList,
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: ':reportName',
            Component: ReportContainer,
          },
          {
            index: true,
            Component: ReportList,
          }
        ],
      },
      {
        index: true,
        element: <Navigate to='dashboards' replace />,
      },
    ],
  },
]);

// FIXME(vkramskikh): this is a hack to connect react-router (JS) with bide (CLJS).
// bide doesn't use html5 history API in the hash mode and listens to hashchange event.
// This hack should be removed after switching to html5 history API everywhere.
const originalNavigate = router.navigate;
router.navigate = function(...args) {
  const result = originalNavigate.apply(router, args);
  window.dispatchEvent(new HashChangeEvent('hashchange'));
  return result;
};

async function fetchPredefinedProbes({blueprintId, routes, signal}) {
  const route = routes.predefinedProbeList;
  const {items: predefinedProbes} = await request(
    interpolateRoute(route, {blueprintId}),
    {signal, queryParams: {include_experimental: true}}
  );
  return predefinedProbes;
}

async function fetchBlueprintReferenceDesignSchema({blueprintDesign, routes, signal}) {
  if (!blueprintDesign) return null;
  const route = routes.referenceDesignSchemaDetails;
  const blueprintReferenceDesignSchema = await request(
    interpolateRoute(route, {referenceDesignName: blueprintDesign}),
    {signal}
  );
  return blueprintReferenceDesignSchema;
}

const IBA = (props) => {
  const {
    blueprintId, blueprintDesign, allActiveNodes, systemIdMap, systemsHrefs, vnIdMap, cablingMap,
    processorDefinitions, blueprintPermissions, anomalyRemediationUrl, generateVnLink, blueprintTags, isFreeform,
    deviceProfiles, processorCategories,
  } = props;
  const {
    isLoading, fetchDataError, predefinedProbes, blueprintReferenceDesignSchema
  } = useQueries({
    queries: [{
      queryFn: ({signal}) => fetchPredefinedProbes({blueprintId, routes, signal}),
      queryKey: queryKeys.predefinedProbes(blueprintId),
    }, {
      queryFn: ({signal}) => fetchBlueprintReferenceDesignSchema({blueprintDesign, routes, signal}),
      queryKey: queryKeys.blueprintReferenceDesign(blueprintDesign),
    }],
    combine: (results) => ({
      isLoading: some(results, (r) => r.isPending),
      fetchDataError: find(results, (r) => r.error)?.error,
      predefinedProbes: results[0].data,
      blueprintReferenceDesignSchema: results[1].data
    })
  });

  return (
    <IBAContext.Provider
      value={{
        blueprintId, blueprintDesign, allActiveNodes, systemIdMap, systemsHrefs, vnIdMap, cablingMap,
        processorDefinitions, predefinedProbes, blueprintReferenceDesignSchema,
        routes, blueprintPermissions, anomalyRemediationUrl,
        generateVnLink, blueprintTags, isFreeform, deviceProfiles, processorCategories,
      }}
    >
      <div className='iba-root'>
        {isLoading ?
          <Loader />
        : fetchDataError ?
          <FetchDataError error={fetchDataError} />
        :
          <RouterProvider router={router} />
        }
      </div>
    </IBAContext.Provider>
  );
};

export default IBA;
