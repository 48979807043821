import {observer} from 'mobx-react';
import React from 'react';

import MultiParagraphText from '../../../components/MultiParagraphText';
import {Card} from './Card';
import {ProcessorExamplesPanel} from './ProcessorExamplesPanel';
import {Store} from './Store';

import './ProcessorDescription.less';

type ProcessorDescriptionProps = {
  store: Store;
}

export const ProcessorDescription: React.FC<ProcessorDescriptionProps> = observer(({
  store
}) => {
  return store.processorDefinition ? (
    <>
      <ProcessorExamplesPanel processorDefinition={store.processorDefinition} />
      <Card
        withBorder={false}
      >
        <MultiParagraphText
          asMarkdown
          text={store.processorDefinition.description}
        />
      </Card>
    </>
  ) : null;
});
