export const NODE_ALIGN = {
  LEFT: 'align-left',
  RIGHT: 'align-right'
};

export const STATE = {
  UP: 'up',
  DOWN: 'down',
  NOT_AVAILABLE: 'n/a'
};

export const DEFAULT_STATE = STATE.NOT_AVAILABLE;

export const STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  NOT_AVAILABLE: 'n/a'
};

export const TELEMETRY_STATUS = {
  OK: 'ok',
  MISMATCH: 'mismatch',
  MISSING: 'missing',
  UNINTENDED: 'unintended',
};

export const TELEMETRY_STATE = {
  UP: 'up',
  DOWN: 'down',
};

export const DEFAULT_STATUS = STATUS.NOT_AVAILABLE;

export const NOT_AVAILABLE = 'n/a';

export const STATUS_MAPPING = {
  [TELEMETRY_STATUS.OK]: STATUS.SUCCESS,
  [TELEMETRY_STATUS.MISMATCH]: STATUS.ERROR,
  [TELEMETRY_STATUS.MISSING]: STATUS.ERROR,
  [TELEMETRY_STATUS.UNINTENDED]: STATUS.NOT_AVAILABLE,
};

export const STATE_MAPPING = {
  [TELEMETRY_STATE.UP]: STATE.UP,
  [TELEMETRY_STATE.DOWN]: STATE.DOWN,
};
