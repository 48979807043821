import {isEmpty} from 'lodash';
import {observer} from 'mobx-react';
import {Icon, Popup} from 'semantic-ui-react';
import cx from 'classnames';

import './ButtonAlert.less';

const ButtonAlert = ({message, shiftLeft}) => {
  if (isEmpty(message)) return null;

  const classNames = cx('re-button-alert', {
    left: shiftLeft
  });

  return (
    <div className={classNames}>
      <Popup
        inverted
        offset={[-11, 0]}
        trigger={<Icon name='warning sign' />}
        content={message}
      />
    </div>
  );
};

export default observer(ButtonAlert);
