import {action, observable, computed, makeObservable} from 'mobx';
import {assign, defer, first, keys, size, some, toLower, transform} from 'lodash';

import {generateId, isNotVnType} from '../utils';
import {Positioner} from '../Positioner';
import Ept, {emptyEpt} from './Ept';
import Link from './Link';
import {eptMaxSize} from '../settings';

class ActiveEptStore extends Ept {
  newOrder;
  @observable sidePanelTab = 0;

  @observable searchString = '';
  @action
  searchOnChange = (value) => {
    this.searchString = value;
  };

  @observable hasOwnChanges = false;

  // Searches for the EPTs that match the search criterion
  @computed.struct
  get eptsMatched() {
    const isSearching = this.searchString !== '';
    const lcNeedle = toLower(this.searchString);
    return transform(
      this.epts,
      (acc, ept) => {
        if (ept.editable && (!isSearching || ept?.matchesSearch?.(lcNeedle))) acc[ept.id] = true;
      },
      {}
    );
  }

  @computed
  get hasChanges() {
    return this.hasOwnChanges || some(this.epts, 'touched') || some(this.links, 'touched');
  }

  // If the only EPT is found - get its ID, otherwise null
  @computed
  get singleMatchId() {
    return size(this.eptsMatched) === 1 ? first(keys(this.eptsMatched)) : null;
  }

  @computed
  get hasNonVnPrimitives() {
    return some(this.epts, (ept) => ept.id ? isNotVnType({type: ept.dbType}) : false);
  }

  get size() {
    return Object.keys(this.epts).length - 1;
  }

  constructor(rootStore, data = {}) {
    super();

    makeObservable(this);

    this.rootStore = rootStore;
    this.reset();
    assign(this, data);
  }

  // Make given EPT active (view on canvas)
  @action
  activate(sausage, sausager, keepTab) {
    assign(
      this,
      Ept.fromSausage(sausage, sausager, true),
      {
        newOrder: 100
      },
      keepTab ? {} : {sidePanelTab: 0}
    );
  }

  // Reset active EPT (activate blank EPT)
  @action
  reset(keepTab) {
    assign(
      this,
      emptyEpt,
      {
        id: generateId(),
        newOrder: 100,
        searchString: '',
        hasOwnChanges: false
      },
      keepTab ? {} : {sidePanelTab: 0}
    );
  }

  // Add a new EPT to the canvas
  @action
  addEpt(ept) {
    defer(() => {
      if (this.size >= eptMaxSize) return;
      assign(
        ept,
        {
          id: generateId(),
          creationOrder: this.newOrder++,
          order: 0,
          touched: true
        }
      );
      ept.generateMeta();
      ept.validateParameters();
      this.bringEptOnTop(ept);
      this.epts[ept.id] = ept;

      const connectionEpt = new Positioner(this.epts, this.links).position(ept, null, this.activePrimitiveId);
      if (connectionEpt) {
        this.addLink(connectionEpt.id, ept.id);
      }
    });
  }

  // Link two EPTs on the canvas
  @action
  addLink(fromId, toId, registerChange = true) {
    const link = new Link({
      from: fromId,
      to: toId
    });
    this.links[link.id] = link;
    if (registerChange) {
      link.touch();
    }
  }

  // Delete the link from the canvas
  @action
  removeLink(id) {
    delete this.links[id];
    this.hasOwnChanges = true;
  }

  // Remove the EPT and all its links from the canvas
  @action
  removeEpt(id) {
    Object.values(this.links).forEach((link) => {
      if (link.from === id || link.to === id) {
        delete this.links[link.id];
      }
    });
    delete this.epts[id];
    this.hasOwnChanges = true;
  }

  // Insert all primitives EPT consists of the the active EPT
  @action
  useEpt(ept) {
    if (ept.isPrimitive) {
      this.addEpt(ept);
    } else {
      Object.entries(ept.epts).forEach(([id, e]) => id && this.addEpt(e));
    }
  }

  @action
  switchSidePanelTab(tabIndex) {
    this.sidePanelTab = tabIndex;
  }

  @action
  setProperties({title, description = '', tags = []}) {
    assign(this, {title, description, tags});
  }
}

export default ActiveEptStore;
