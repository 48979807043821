import {Component} from 'react';
import {List} from 'semantic-ui-react';
import {map} from 'lodash';
import {Popper} from 'react-popper';
import {Value, formatBytesPerSecond} from 'apstra-ui-common';

import BulletChart from './BulletChart';
import './HeadroomPopup.less';

export default class HeadroomPopup extends Component {
  render() {
    const {header, description, properties, propertiesSchema, ...bulletChartProps} = this.props;
    return (
      <Popper
        placement='right'
        referenceElement={{
          getBoundingClientRect: () => ({
            ...this.props.coordinates,
            right: this.props.coordinates.left + 1,
            bottom: this.props.coordinates.top + 1,
            width: 1,
            height: 1,
          }),
          clientWidth: 1,
          clientHeight: 1,
        }}
      >
        {({placement, ref, style}) => {
          return (
            <div
              ref={ref}
              className='headroom-popup ui basic popup transition visible'
              data-placement={placement}
              style={style}
            >
              {header && <div className='header'>{header}</div>}
              {description && <div className='description'>{description}</div>}
              <BulletChart {...bulletChartProps} />
              <PropertiesList properties={properties} schema={propertiesSchema} />
            </div>
          );
        }}
      </Popper>
    );
  }
}

export const PropertiesList = ({properties, schema}) => {
  if (!properties) return null;
  return (
    <List className='headroom-popup-properties' size='mini'>
      {map(properties,
        (propValue, propName) => {
          const propSchema = schema?.[propName];
          const value = propSchema?.units === 'bps' ?
            formatBytesPerSecond(propValue)
            : `${propValue} ${propSchema?.units || ''}`;
          return (
            <List.Item key={propName}>
              <span className='property-name'>
                {propSchema?.title || propName}
              </span>
              <span className='property-value'>
                {' '}
                <Value value={value} />
              </span>
            </List.Item>
          );
        }
      )}
    </List>
  );
};
