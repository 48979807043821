import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Grid, Label, Popup, Sticky} from 'semantic-ui-react';
import {filter, get, isEmpty, find, noop, some} from 'lodash';
import {usePrevious} from 'react-use';
import cx from 'classnames';
import {DropdownControl, FetchData, Field, Loader, ValidationErrors,
  interpolateRoute, request} from 'apstra-ui-common';

import DeviceContext from '../../nodes/components/DeviceContext';
import {ConfigletEditor} from './ConfigletInput';
import {ConfigView} from '../../components/ConfigDiff';
import humanizeString from '../../humanizeString';
import ResizableColumns from '../../components/ResizableColumns';

import './ConfigTemplate.less';

const CONFIG_APPLY_MODES = ['incremental', 'complete'];
const [, COMPLETE] = CONFIG_APPLY_MODES;
const fetchRenderedConfigData = async ({blueprintId, configTemplateId, text, configApplyMode, systemId}) => {
  const previewConfigRoute = '/api/blueprints/<blueprint_id>/config-templates/<config_template_id>/preview';
  const body = {text: text, system_id: systemId, config_apply_mode: configApplyMode};
  try {
    const {text: renderedConfig, errors} = await request(
      interpolateRoute(previewConfigRoute, {blueprintId, configTemplateId}),
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
    return {renderedConfig, errors};
  } catch ({responseBody}) {
    const errors = get(responseBody, ['errors', 'text'], responseBody?.errors);
    return {renderedConfig: '', errors: filter([errors])};
  }
};
const fetchData = async ({systemId, blueprintId, signal}) => {
  if (!blueprintId || !systemId) return {configContext: {}};
  const configContextRoute = '/api/blueprints/<blueprint_id>/nodes/<system_id>/config-context';
  const {context} = await request(
    interpolateRoute(configContextRoute, {systemId, blueprintId}),
    {signal, queryParams: {type: 'staging'}}
  );
  return {configContext: JSON.parse(context)};
};

const ConfigTemplateControl = ({
  configContext = {}, systems, systemId, setSystemId, loaderVisible, value, blueprintId, submitting, onClear,
  popupNonExtendedText = 'It is available just for editing mode', configTemplateId, errors: errorsProps,
  ...configletInputProps
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [renderedConfig, setRenderedConfig] = useState('');
  const [configApplyMode, setConfigApplyMode] = useState(COMPLETE);
  const [showDeviceContext, setShowDeviceContext] = useState(false);
  const [errors, setErrors] = useState(errorsProps);
  const [sticky, setSticky] = useState(false);
  const prevProps = usePrevious({configTemplateId, systemId, configApplyMode});
  const previewConfig = useCallback(async () => {
    onClear();
    setIsLoading(true);
    setErrors([]);
    const {renderedConfig, errors} = await fetchRenderedConfigData({
      configTemplateId: configTemplateId,
      blueprintId,
      systemId,
      configApplyMode,
      text: value,
    });
    if (!isEmpty(errors)) {
      setErrors(errors);
    } else if (!isEmpty(errorsProps)) {
      setErrors(errorsProps);
    } else {
      setRenderedConfig(renderedConfig);
    }
    setIsLoading(false);
  }, [
    value, setRenderedConfig, configApplyMode, blueprintId, configTemplateId, systemId,
    setErrors, setIsLoading, onClear, errorsProps,
  ]);
  const configContextJSON = useMemo(() => {
    return JSON.stringify(configContext);
  }, [configContext]);
  const updateSystem = (systemId) => {
    setSystemId(systemId);
    setRenderedConfig('');
  };
  const updateConfigApplyMode = (mode) => {
    setConfigApplyMode(mode);
    setRenderedConfig('');
  };
  const system = useMemo(() => {
    return find(systems, {id: systemId});
  }, [systems, systemId]);

  useEffect(() => {
    const isChanged = some([
      prevProps?.configTemplateId !== configTemplateId,
      prevProps?.systemId !== systemId,
      prevProps?.configApplyMode !== configApplyMode,
    ]);
    if (prevProps && isChanged) previewConfig();
  }, [configTemplateId, systemId, configApplyMode, prevProps, previewConfig]);
  useEffect(() => {
    setErrors([]);
  }, [submitting, setErrors]);
  useEffect(() => {
    setErrors(errorsProps);
  }, [errorsProps, setErrors]);

  const templateTextWidth = showDeviceContext ?
    configTemplateId ?
      8 :
      12 :
    configTemplateId ?
      12 :
      16;
  const initialWidth = [templateTextWidth];
  if (configTemplateId) initialWidth.push(4);
  if (showDeviceContext) initialWidth.push(4);

  const gridProps = {
    celled: true,
    className: cx('config-template', {red: !isEmpty(errors)}),
  };

  return (
    <div>
      <Sticky onStick={() => setSticky(true)} onUnstick={() => setSticky(false)} />
      <Grid className={cx('config-template-header', {sticky})}>
        <Grid.Row>
          <Grid.Column width={5}>
            <Button
              onClick={() => setShowDeviceContext(!showDeviceContext)}
              active={showDeviceContext}
              content='Device Context'
              aria-label='Show/Hide Device Context'
              basic
            />
          </Grid.Column>
          <Grid.Column textAlign='right' verticalAlign='middle' width={2}>
            <Label className='dropdown-label'>{'System:'}</Label>
          </Grid.Column>
          <Grid.Column textAlign='right' verticalAlign='middle' width={4}>
            <DropdownControl
              aria-label='Systems'
              fluid
              value={systemId}
              onChange={updateSystem}
              options={systems.map(({label, id: systemId}) => ({
                key: systemId,
                value: systemId,
                text: label,
              }))}
            />
          </Grid.Column>
          <Grid.Column textAlign='right' verticalAlign='middle' width={2}>
            <Label className='dropdown-label'>{'Preview Mode:'}</Label>
          </Grid.Column>
          <Grid.Column textAlign='right' verticalAlign='middle' width={3}>
            <DropdownControl
              aria-label='Preview Mode'
              fluid
              value={configApplyMode}
              onChange={updateConfigApplyMode}
              options={CONFIG_APPLY_MODES.map((mode) => ({
                key: mode,
                value: mode,
                text: humanizeString(mode),
              }))}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column verticalAlign='middle'>
            <Button
              icon='help'
              labelPosition='left'
              content='Jinja function reference'
              as='a'
              href='/static/jinja_docs'
              target='_blank'
            />
          </Grid.Column>
          <Grid.Column textAlign='right' verticalAlign='middle'>
            <Popup
              trigger={
                <div>
                  <Button
                    onClick={previewConfig}
                    loading={isLoading}
                    disabled={isLoading || !configTemplateId}
                    icon='play circle'
                    content='Preview'
                    primary
                  />
                </div>
              }
              position='top right'
              disabled={!!configTemplateId}
              content={popupNonExtendedText}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ValidationErrors errors={errors} pointing='below' />
      <ResizableColumns
        gridProps={gridProps}
        initialWidth={initialWidth}
        header={[
          {label: 'Template Text', options: {className: 'required'}},
          configTemplateId ? {label: `Preview (as rendered for device ${system?.label})`} : null,
          showDeviceContext ? {label: 'Device Context'} : null,
        ]}
      >
        <Grid.Column className='editor'>
          <ConfigletEditor
            ariaLabel='Config Template Viewer'
            configContext={configContext}
            value={value}
            {...configletInputProps}
          />
        </Grid.Column>
        {configTemplateId &&
          <Grid.Column>
            <RenderedTemplateView config={renderedConfig} isLoading={isLoading} />
          </Grid.Column>
        }
        {showDeviceContext &&
          <Grid.Column>
            <DeviceContext deviceContext={configContextJSON} loaderVisible={loaderVisible} />
          </Grid.Column>
        }
      </ResizableColumns>
    </div>
  );
};

const RenderedTemplateView = ({className, config, isLoading}) => {
  return isLoading ?
    <Loader /> :
    <ConfigView config={config} className={className} />;
};

const ConfigTemplate = ({
  genericSystems = [], label = 'Template Text', disabled, required,
  blueprintId, submitting, onClear = noop, ...props
}) => {
  const internalSystems = useMemo(() => {
    return filter(genericSystems, ({system_type: systemType}) => systemType === 'internal');
  }, [genericSystems]);
  const [systemId, setSystemId] = useState(get(internalSystems, ['0', 'id']));
  return (
    <FetchData
      customLoader
      fetchData={fetchData}
      fetchParams={{systemId, blueprintId}}
      pollingInterval={null}
    >
      {({configContext, loaderVisible}) => blueprintId && !isEmpty(internalSystems) ?
        <Field label={label} disabled={disabled}>
          <ConfigTemplateControl
            systems={internalSystems}
            configContext={configContext}
            blueprintId={blueprintId}
            systemId={systemId}
            setSystemId={setSystemId}
            loaderVisible={loaderVisible}
            submitting={submitting}
            onClear={onClear}
            {...props}
          />
        </Field> :
        <Field required={required} label={label} disabled={disabled} errors={props.errors}>
          <ConfigletEditor
            ariaLabel='Configlet Viewer'
            configContext={configContext}
            disabled={disabled}
            {...props}
          />
        </Field>
      }
    </FetchData>
  );
};

export default ConfigTemplate;
