import {observable, makeObservable, action, computed, toJS} from 'mobx';
import {find, some, every, map, set, isEqual, cloneDeep, isEmpty, flatten, without} from 'lodash';

import {generateLocalId, validateSubnetsOf} from '../utils';
import Endpoint from './Endpoint';
import {ctrlStages, ctrlEntityTypes} from '../const.js';
import {speedFromString, stringFromSpeed} from '../../rackEditor/utils';

class Link {
  #backup;

  @observable id;
  @observable endpoint1 = null;
  @observable endpoint2 = null;
  @observable stage;
  @observable label;
  @observable aggregateId;
  @observable type = ctrlEntityTypes.LINK;
  @observable tags;
  timestamp; // Needed to track links order in links management modal

  @observable.ref cablingMap = null;

  @observable peerLinkType = null;

  @action
  init(node1, node2) {
    this.id = generateLocalId();
    if (node1) this.endpoint1 = new Endpoint({node: node1, externalId: this.id}, this.cablingMap);
    if (node2) this.endpoint2 = new Endpoint({node: node2, externalId: this.id}, this.cablingMap);
    this.stage = null;
    this.tags = [];
  }

  constructor(node1, node2, cablingMap) {
    makeObservable(this);

    if (!cablingMap) {
      throw new Error('Link\'s constructor called without the CablingMap store');
    }
    this.timestamp = Date.now();
    this.cablingMap = cablingMap;
    this.init(node1, node2);
  }

  get isAggregate() {
    return false;
  }

  @computed
  get isAggregated() {
    return !!this.aggregateId;
  }

  @computed
  get endpoints() {
    return [this.endpoint1, this.endpoint2];
  }

  set endpoints([endpoint1, endpoint2]) {
    this.setProperty('endpoint1', endpoint1);
    this.setProperty('endpoint2', endpoint2);
  }

  @computed
  get nodesIds() {
    return map(this.endpoints, 'nodeId');
  }

  @computed
  get symmetricId() {
    return [this.endpoint1.nodeId, this.endpoint2.nodeId].sort().join('|');
  }

  orderEndpointsFor(nodeId) {
    return nodeId === this.endpoint1.nodeId ? [this.endpoint1, this.endpoint2] : [this.endpoint2, this.endpoint1];
  }

  @computed
  get exists() {
    return this.stage !== ctrlStages.DELETE;
  }

  @computed
  get isUpdate() {
    return this.stage === ctrlStages.CHANGE;
  }

  @computed
  get data() {
    return toJS({
      id: this.id,
      label: this.label,
      aggregateId: this.aggregateId,
      endpoint1: this.endpoint1.data,
      endpoint2: this.endpoint2.data,
      tags: this.tags
    });
  }

  @computed
  get usedIps() {
    return flatten(map(this.endpoints, 'usedIps'));
  }

  get hasChanges() {
    if (this.stage === ctrlStages.ADD || this.stage === ctrlStages.DELETE) return true;
    if (!this.#backup) return false;
    return !isEqual(this.#backup, this.data);
  }

  cloneFor(nodeIds) {
    const clone = new Link({}, {}, this.cablingMap);
    clone.fillWith({
      tags: [...this.tags],
      endpoint1: this.endpoint1.cloneFor(nodeIds),
      endpoint2: this.endpoint2.cloneFor(nodeIds)
    });
    return clone;
  }

  @action
  commit() {
    this.#backup = null;
    this.stage = null;
  }

  @action
  backup() {
    // Preserves initial state of link to be restored in case of
    // changes discard
    this.#backup = cloneDeep(this.data);
    this.stage = ctrlStages.CHANGE;
  }

  @computed
  get isBackedUp() {
    return !!this.#backup;
  }

  @action
  markForDeletion() {
    this.stage = ctrlStages.DELETE;
  }

  @action
  fillWith(data) {
    Object.assign(this, data);
  }

  @action
  restore(toBlank) {
    const result = toBlank ? new Link({}, {}, this.cablingMap) : this;
    // Restores link's preserved state.
    // Updates <target> if provided in order to track changes.
    if (this.isBackedUp) {
      const {id, label, aggregateId, endpoint1, endpoint2, tags} = this.#backup;
      result.fillWith({
        id,
        label,
        aggregateId,
        tags
      });
      result.endpoint1.fillWith(endpoint1);
      result.endpoint2.fillWith(endpoint2);
    }
    result.commit();
    return result;
  }

  @action
  setAggregateId(aggregateId) {
    this.aggregateId = aggregateId;
    if (aggregateId) {
      // When individual links get added to an aggregate
      // their IP address settings must be cleaned up
      this.endpoint1.removeIps();
      this.endpoint2.removeIps();
    }
  }

  @action
  setProperty(property, value) {
    this[property] = value;
  }

  // Checks whether the link contains IP(s) from the list of duplicates
  containsDuplicates(usedIps) {
    return some(this.usedIps, (ip) => ((usedIps?.[ip] ?? 0) > 1));
  }

  usesPort(portId) {
    return (this.endpoint1.portId === portId || this.endpoint2.portId === portId);
  }

  contains(nodes, all) {
    return this.containsNodesIds(map(nodes, 'id'), all);
  }

  containsNodesIds(nodesIds, all) {
    return (all ? every : some)(nodesIds, (id) => (id === this.endpoint1?.nodeId || id === this.endpoint2?.nodeId));
  }

  fallsWithin(nodesIds) {
    return nodesIds.includes(this.endpoint1.nodeId) && nodesIds.includes(this.endpoint2.nodeId);
  }

  oppositeTo(nodeId) {
    if (this.endpoint1.nodeId === nodeId) {
      return this.endpoint2.nodeId;
    } else if (this.endpoint2.nodeId === nodeId) {
      return this.endpoint1.nodeId;
    } else {
      return false;
    }
  }

  getEndpointFor(nodeId, portId) {
    return find([this.endpoint1, this.endpoint2], (endpoint) => endpoint.correspondsTo(nodeId, portId));
  }

  connectsSameNodesAs({endpoint1, endpoint2}) {
    return (this.endpoint1.nodeId === endpoint1.nodeId && this.endpoint2.nodeId === endpoint2.nodeId) ||
    (this.endpoint2.nodeId === endpoint1.nodeId && this.endpoint1.nodeId === endpoint2.nodeId);
  }

  @computed
  get usedTags() {
    return [
      ...this.tags,
      ...flatten(map(this.endpoints, 'tags'))
    ];
  }

  @computed
  get errors() {
    const {ipsv4SubnetErrors, ipsv6SubnetErrors} = this;
    const result = [];
    if (ipsv4SubnetErrors) {
      result.push(...ipsv4SubnetErrors);
    }
    if (ipsv6SubnetErrors) {
      result.push(...ipsv6SubnetErrors);
    }
    return result;
  }

  @computed
  get hasErrors() {
    return !isEmpty(this.errors);
  }

  @computed
  get isValid() {
    const {hasErrors, endpoint1, endpoint2} = this;
    return !hasErrors && endpoint1.isValid && endpoint2.isValid;
  }

  @computed
  get speed() {
    const result = this.endpoint1.speed ?
      this.endpoint1.speed :
      (this.endpoint2.speed ? this.endpoint2.speed : {value: '', unit: ''});
    return result;
  }

  @computed
  get speedString() {
    return stringFromSpeed(this.speed);
  }

  @computed
  get ipsv4SubnetErrors() {
    return validateSubnetsOf(without(map(this.endpoints, 'ipv4Parsed'), true, false));
  }

  @computed
  get ipsv6SubnetErrors() {
    return validateSubnetsOf(without(map(this.endpoints, 'ipv6Parsed'), true, false));
  }

  @computed
  get ipsDiffer() {
    return !this.ipsv4SubnetErrors && !this.ipsv6SubnetErrors;
  }

  static deserialize(jsonValue, cablingMap) {
    const {aggregate_link_id: aggregateId, label, endpoints, id, speed: linkSpeed, tags} = jsonValue;
    const result = new Link({}, {}, cablingMap);

    result.id = id ?? result.id;

    [result.endpoint1, result.endpoint2] = map(endpoints, (endpoint) => {
      set(endpoint, 'local.externalId', result.id);
      return Endpoint.deserialize(
        endpoint,
        cablingMap,
        speedFromString(linkSpeed)
      );
    });
    result.fillWith({label, aggregateId, tags});
    return result;
  }

  serialize() {
    return toJS({
      id: this.id,
      aggregate_link_id: this.aggregateId,
      label: this.label,
      speed: this.speedString,
      endpoints: [this.endpoint1.serialize(), this.endpoint2.serialize()],
      tags: this.tags
    });
  }
}

export default Link;
