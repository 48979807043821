import {Component, Fragment} from 'react';

import {observable, action, reaction, computed, comparer, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import moment from 'moment';
import {findIndex, max, map, isEmpty, last, includes, transform, keys, values} from 'lodash';
import PropTypes from 'prop-types';
import {withResizeDetector} from 'react-resize-detector';
import cx from 'classnames';
import {Button} from 'semantic-ui-react';
import {Loader, Slider, formatSeconds} from 'apstra-ui-common';

import {generateDateTicks} from './graphs/trafficUtils';
import {getIntervalsArraysIntersect} from '../dateUtils';

import './DateInRangeSlider.less';

const DATE_FORMAT = 'MMM D, HH:mm:ss';
const MIN_HANDLE_WIDTH = 1;
const DATE_SLIDER_DOT_WIDTH = 1;

const DateSliderHandle = (props) => {
  const {date, ticksCount, dateTicks, waitingDataReload, dateHandleWidth,
    minDate, maxDate, value, prefixCls, handleElementProps} = props;
  if (!date || !ticksCount || dateTicks.length < 2 || waitingDataReload) {
    return null;
  }

  const offset = 100 * (value - minDate) / (maxDate - minDate);

  return (
    <div
      {...handleElementProps}
      className={`${prefixCls}-handle`}
      style={{
        left: `calc(${offset}% - ${max([dateHandleWidth, MIN_HANDLE_WIDTH])}px)`,
        width: dateHandleWidth,
      }}
    />
  );
};

@withResizeDetector
@observer
export default class DateInRangeSlider extends Component {
  static props = {
    aggregation: PropTypes.number,
    maxRangeDuration: PropTypes.number,
    date: PropTypes.date,
    beginTime: PropTypes.date,
    endTime: PropTypes.date,
    isLoading: PropTypes.bool,
    ticksCount: PropTypes.number,
    width: PropTypes.number,
    period: PropTypes.number,
    periodLabel: PropTypes.string,
    fetchedIntervals: PropTypes.object,
    onChange: PropTypes.func
  };

  static defaultProps = {
    ticksCount: 300,
    width: 800
  };

  @observable rangeTicks = [];
  @observable rangeValue = [];
  @observable isDraggingRange = false;
  @observable localDate;
  @observable waitingDataReload = false;

  now = this.props.baseTime || moment.utc();

  constructor(props) {
    super(props);

    makeObservable(this);

    this.disposeReactions = [
      reaction(
        () => this.props.date,
        (date) => {
          const validDate = this.getValidDate(date);
          this.setLocalDate(null);
          if (validDate !== date) {
            this.onDateChange(validDate);
          }
        }
      ),
      reaction(
        () => this.dateTicks,
        (dateTicks) => {
          if (!this.hasDateInList({date: this.props.date, dates: dateTicks})) {
            this.onDateChange(last(dateTicks));
          }
        },
        {equals: comparer.structural, fireImmediately: true}
      ),
      reaction(
        () => this.props.aggregation,
        () => this.updateRange()
      ),
      reaction(
        () => this.props.width,
        () => this.updateRange()
      ),
      reaction(
        () => this.props.isLoading,
        (value) => {this.waitingDataReload = value;}
      )
    ];
  }

  componentDidMount() {
    this.updateRange();
    this.setBeginEndTimeInitials();
  }

  componentWillUnmount() {
    this.disposeReactions.forEach((disposeReaction) => disposeReaction());
  }

  @computed
  get beginTime() {
    const {endTime, rangeTicks, rangeValue, props: {aggregation, maxRangeDuration}} = this;
    return aggregation ?
      moment(endTime).add(-maxRangeDuration, 's').toDate() :
      rangeTicks[rangeValue[0]];
  }

  @computed
  get endTime() {
    const {rangeTicks, rangeValue} = this;
    return rangeTicks[rangeValue[1]];
  }

  @computed
  get sliderValueIndex() {
    const {getClosestDateInTicksIndex, isDraggingRange, localDate, dateTicks} = this;
    const date = isDraggingRange ? null : (localDate || this.props.date);
    return getClosestDateInTicksIndex({date, ticks: dateTicks});
  }

  @computed
  get brushStyles() {
    const {rangeValue, rangeTicks, props: {width}} = this;
    if (isEmpty(rangeValue)) {
      return null;
    }
    const ticksCount = rangeTicks.length - 1;
    return {
      borderRightWidth: rangeValue[0] / ticksCount * width,
      borderLeftWidth: width - rangeValue[1] / ticksCount * width,
    };
  }

  @computed
  get normalizedDateTicks() {
    const {props: {aggregation, beginTime}, dateTicks} = this;
    if (isEmpty(dateTicks)) {
      return [];
    }
    return [moment.max([moment(dateTicks[0]).add(-aggregation, 's').toDate(), moment(beginTime)]), ...dateTicks];
  }

  @computed
  get dateHandleWidth() {
    const {sliderValueIndex, extremeDateTicksValues, normalizedDateTicks, props: {aggregation, width}} = this;
    if (!aggregation) {
      return MIN_HANDLE_WIDTH;
    }
    const dateRangeDiff = extremeDateTicksValues.last - extremeDateTicksValues.first;
    const dateDiff = normalizedDateTicks[sliderValueIndex + 1] - normalizedDateTicks[sliderValueIndex];
    return max([width * (dateDiff / dateRangeDiff) - MIN_HANDLE_WIDTH, MIN_HANDLE_WIDTH]);
  }

  @computed
  get extremeDateTicksValues() {
    const {normalizedDateTicks} = this;
    return {first: normalizedDateTicks[0], last: last(normalizedDateTicks)};
  }

  @computed
  get dateTicks() {
    const {beginTime, endTime, aggregation} = this.props;
    return generateDateTicks({beginTime, endTime, aggregation});
  }

  @computed
  get shouldRenderDateSliderDots() {
    const {dateTicks, props: {width}} = this;
    return width / dateTicks.length > (DATE_SLIDER_DOT_WIDTH + 5);
  }

  @computed
  get fetchedIntervalsIntersection() {
    return getIntervalsArraysIntersect(
      values(this.props.fetchedIntervals),
      {startKey: 'beginTime', endKey: 'endTime'}
    );
  }

  @computed
  get periodRangeTicksDistance() {
    const {props: {period, maxRangeDuration}, width, now, toUTCDate, roundToMinutes} = this;
    const start = toUTCDate(now.clone().add(-period, 's'));
    const end = toUTCDate(now.toDate());

    const expectedTicksCount = generateDateTicks(
      {beginTime: start, endTime: end, aggregation: maxRangeDuration}
    ).length;
    const handlesWidth = 2 * MIN_HANDLE_WIDTH;
    const maxTicksCount = width / handlesWidth;
    const divide = maxTicksCount / expectedTicksCount;
    if (divide >= handlesWidth) {
      return roundToMinutes(maxRangeDuration / divide, 1);
    }
    return maxRangeDuration;
  }

  @action
  updateRange() {
    const {
      props: {period, beginTime, endTime}, now, periodRangeTicksDistance,
      getNormalizedRangeStartIndex, getNormalizedRangeEndIndex, getClosestDateInTicksIndex, toUTCDate
    } = this;
    const start = toUTCDate(now.clone().add(-period, 's'));
    const end = toUTCDate(now.toDate());
    const ticks = generateDateTicks({beginTime: start, endTime: end, aggregation: periodRangeTicksDistance});

    const ticksAsNumbers = ticks.map((tick) => +tick);
    if (!ticksAsNumbers.includes(+now)) {
      ticks.push(now.toDate());
    }

    const getIndex = (date) => getClosestDateInTicksIndex({date, ticks});
    let startIndex = getIndex(isEmpty(this.rangeValue) ? beginTime || start : this.rangeTicks[this.rangeValue[0]]);
    if (startIndex < 0) {
      startIndex = 0;
    }
    let endIndex = getIndex(isEmpty(this.rangeValue) ? endTime || end : this.rangeTicks[this.rangeValue[1]]);
    if (endIndex < 0) {
      endIndex = ticks.length - 1;
    }
    if (startIndex === 0 && endIndex === 0) {
      endIndex++;
    }

    const normalizedRangeStartIndex = getNormalizedRangeStartIndex({range: [startIndex, endIndex], ticks});
    const newRange = [normalizedRangeStartIndex, endIndex];

    this.rangeTicks = ticks;
    if (isEmpty(this.rangeValue)) {
      this.rangeValue = [
        normalizedRangeStartIndex,
        getNormalizedRangeEndIndex({range: newRange, ticks: this.rangeTicks})
      ];
    } else {
      this.onRangeChange(newRange);
    }
    this.onRangeAfterChange();
  }

  @action
  onRangeChange = (range) => {
    const newRange = [...range];
    const isLeftHandleChanged = range[0] !== this.rangeValue[0];
    if (isLeftHandleChanged) {
      newRange[1] = this.getNormalizedRangeEndIndex({range, ticks: this.rangeTicks});
      newRange[0] = this.getNormalizedRangeStartIndex({range: newRange, ticks: this.rangeTicks});
    } else {
      newRange[0] = this.getNormalizedRangeStartIndex({range, ticks: this.rangeTicks});
      newRange[1] = this.getNormalizedRangeEndIndex({range: newRange, ticks: this.rangeTicks});
    }

    this.rangeValue = newRange;
    this.waitingDataReload = true;
    this.isDraggingRange = true;
  };

  @action
  onRangeAfterChange = () => {
    const {beginTime, endTime, props} = this;
    this.isDraggingRange = false;
    if (props.shouldUpdateTimeRange || +beginTime !== +props.beginTime || +endTime !== +props.endTime) {
      this.setLocalDate(null);
      this.props.onChange({beginTime, endTime, baseTime: this.now});
    } else {
      this.waitingDataReload = false;
    }
  };

  @action
  setLocalDate = (date) => {
    if (!date || !this.isDraggingRange) {
      this.localDate = date;
    }
  };

  roundToMinutes(milliseconds, minutes) {
    const roundTo = 1000 * 60 * minutes;
    return Math.round(milliseconds / roundTo) * roundTo;
  }

  getIntervalStyles = ({beginTime, endTime}) => {
    const {extremeDateTicksValues, props: {width}} = this;
    if (!beginTime || !endTime || beginTime > endTime) {
      return;
    }
    if (beginTime < extremeDateTicksValues.first) {
      beginTime = extremeDateTicksValues.first;
    }
    if (endTime > extremeDateTicksValues.last) {
      endTime = extremeDateTicksValues.last;
    }
    const dateRangeDiff = extremeDateTicksValues.last - extremeDateTicksValues.first;
    const beginTimeDiff = beginTime - extremeDateTicksValues.first;
    const style = {
      left: beginTimeDiff / dateRangeDiff * width,
      width: (endTime - beginTime) / dateRangeDiff * width
    };
    if (style.left > width || (style.left + width) < 0) {
      return null;
    }
    return style;
  };

  hasDateInList = ({date, dates}) => {
    return includes(map(dates, (d) => +d), +date);
  };

  getNormalizedRangeStartIndex = ({range, ticks}) => {
    const {props: {maxRangeDuration}, getClosestDateInTicksIndex} = this;
    const [, endIndex] = range;
    const startDate = moment(ticks[endIndex]).add(-maxRangeDuration, 's').toDate();
    return getClosestDateInTicksIndex({date: startDate, ticks});
  };

  getNormalizedRangeEndIndex = ({range, ticks}) => {
    const {props: {maxRangeDuration}, getClosestDateInTicksIndex} = this;
    const [startIndex] = range;
    const endDate = moment(ticks[startIndex]).add(maxRangeDuration, 's').toDate();
    return getClosestDateInTicksIndex({date: endDate, ticks, before: true});
  };

  getClosestDateInTicksIndex = ({date, ticks, before}) => {
    if (!date) {
      return -1;
    }
    const dateMoment = moment.utc(date);
    const dates = map(ticks, (tick) => moment.utc(tick));
    const index = findIndex(dates, (d) => d.isSame(dateMoment));
    if (index > -1) {
      return index;
    }
    const firstAfterDateIndex = findIndex(dates, (d) => d.isAfter(dateMoment));
    if (firstAfterDateIndex < 0) {
      return ticks.length - 1;
    }
    return firstAfterDateIndex - (before ? 1 : 0);
  };

  formatDate = (date) => moment(date).format(DATE_FORMAT);

  toUTCDate = (date) => moment.utc(date).toDate();

  getValidDate = (date) => {
    const {extremeDateTicksValues: {first, last}, dateTicks} = this;

    if (!date) {
      return last;
    }
    if (date < first) {
      return first;
    }
    if (date > last || !this.hasDateInList({date, dates: dateTicks})) {
      return last;
    }
    return date;
  };

  onDateChange = (date) => {
    if ((!this.isDraggingRange || this.props.date) && +date !== +this.props.date) {
      this.props.onChange({date});
    }
  };

  setBeginEndTimeInitials() {
    const {beginTime, endTime} = this.props;
    const beginTimeIndex = findIndex(this.rangeTicks, (tick) => +tick === +beginTime);
    const endTimeIndex = findIndex(this.rangeTicks, (tick) => +tick === +endTime);
    if (beginTimeIndex >= 0) {
      this.rangeValue = [beginTimeIndex, this.rangeValue[1]];
    }
    if (endTimeIndex >= 0) {
      this.rangeValue = [this.rangeValue[0], endTimeIndex];
    }
  }

  moveRangeInterval = (value) => {
    const {rangeValue} = this;
    this.onRangeChange([rangeValue[0] + value, rangeValue[1] + value]);
    this.onRangeAfterChange();
  };

  renderPeriodRange = () => {
    const {
      rangeTicks, rangeValue,
      formatDate, onRangeChange, onRangeAfterChange, moveRangeInterval,
      props: {period, periodLabel}
    } = this;
    const ticksCount = rangeTicks.length ? rangeTicks.length - 1 : 0;
    const hasTicks = ticksCount > 1;

    return (
      <div>
        <Slider
          range
          className='period-slider'
          value={rangeValue}
          handleRender={ticksCount ? undefined : () => null}
          min={0}
          max={ticksCount}
          allowCross={false}
          pushable={1}
          marks={hasTicks ? {0: '', [ticksCount]: ''} : undefined}
          onChange={onRangeChange}
          onAfterChange={onRangeAfterChange}
          ariaLabelForHandle='Period range handle'
        />
        {hasTicks &&
          <Fragment>
            <Button.Group basic size='mini' className='period-slider-controls'>
              <Button
                icon='left chevron'
                onClick={() => moveRangeInterval(-1)}
                disabled={!rangeValue[0]}
                aria-label='Select earlier range'
              />
              <Button
                icon='right chevron'
                onClick={() => moveRangeInterval(1)}
                disabled={rangeValue[1] === ticksCount}
                aria-label='Select later range'
              />
            </Button.Group>
            <div className='slider-legend'>
              <div>
                {`${formatDate(rangeTicks[0])} (${periodLabel ? `${periodLabel}: ` : ''}${formatSeconds(period)})`}
              </div>
              <div>{formatDate(rangeTicks[ticksCount])}</div>
            </div>
          </Fragment>
        }
      </div>
    );
  };

  getDateSliderMarks = () => {
    const {normalizedDateTicks, waitingDataReload} = this;
    if (waitingDataReload) {
      return {};
    }
    return transform(normalizedDateTicks, (acc, date) => {acc[+date] = +date;}, {});
  };

  renderDateSlider = () => {
    const {
      formatDate, onDateChange, setLocalDate, getDateSliderMarks, getIntervalStyles,
      shouldRenderDateSliderDots, normalizedDateTicks, dateTicks,
      sliderValueIndex, waitingDataReload, beginTime, endTime, fetchedIntervalsIntersection, dateHandleWidth,
      props: {aggregation, date, isLoading}
    } = this;

    const ticksCount = normalizedDateTicks.length ? normalizedDateTicks.length - 1 : 0;
    const selectedDate = waitingDataReload ? null : dateTicks[sliderValueIndex];
    const fromDate = selectedDate ?
      (sliderValueIndex > 1 ?
        moment.max([moment(selectedDate).add(-aggregation, 's'), moment(dateTicks[sliderValueIndex - 1])]) :
        moment.max([moment(selectedDate).add(-aggregation, 's'), moment(normalizedDateTicks[0])])
      ) : null;
    const formattedDate = selectedDate ? formatDate(selectedDate) : null;
    const shouldRenderMarks = dateTicks.length > 1 && !isLoading;
    const marks = getDateSliderMarks();
    const hasMarks = !isEmpty(marks);
    const marksKeys = keys(marks);
    const {min, max} = hasMarks ? {min: +marksKeys[0], max: +last(marksKeys)} : {};

    return (
      <div className='date-slider'>
        <div className='slider-legend'>
          <div>{formatDate(beginTime)}</div>
          <div className='selected-date-text'>
            {
              (!selectedDate || isLoading ?
                  null :
                  aggregation ?
                    `${formatDate(fromDate)} - ${formattedDate}` :
                    formattedDate
              )
            }
            {isLoading && <Loader size='tiny' text='' />}
          </div>
          <div>{formatDate(endTime)}</div>
        </div>
        <Slider
          className={cx({'no-dots': !shouldRenderDateSliderDots, empty: !ticksCount})}
          value={+dateTicks[sliderValueIndex]}
          startPoint={+dateTicks[sliderValueIndex]}
          handleRender={(originalHandle, handleProps) =>
            <DateSliderHandle
              handleElementProps={originalHandle.props}
              {...{date, ticksCount, dateTicks, waitingDataReload, dateHandleWidth,
                minDate: min, maxDate: max, ...handleProps}}
            />
          }
          step={null}
          min={min}
          max={max}
          marks={shouldRenderMarks ? marks : undefined}
          onChange={(newDate) => !waitingDataReload && setLocalDate(new Date(newDate))}
          onAfterChange={(newDate) => onDateChange(new Date(newDate))}
          ariaLabelForHandle='Date handle'
        />
        <div className='intervals'>
          {map(fetchedIntervalsIntersection, (duration, index) => {
            const style = getIntervalStyles(duration);
            return style ?
              <div key={index} className='indicator' style={style} />
              : null;
          })}
        </div>
      </div>
    );
  };

  render() {
    const {rangeTicks, rangeValue, brushStyles, renderPeriodRange, renderDateSlider} = this;
    return (
      <div className='date-in-range-slider'>
        {rangeValue && rangeTicks?.length && renderDateSlider()}
        <div className='brush' style={brushStyles} />
        {renderPeriodRange()}
      </div>
    );
  }
}
