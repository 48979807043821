import {Component, Fragment} from 'react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {map} from 'lodash';

import generateDashboardURI from '../generateDashboardURI';
import PredefinedEntityModal from './PredefinedEntityModal';
import MultiParagraphText from '../../components/MultiParagraphText';
import DashboardPreview from './DashboardPreview';
import IBAContext from '../IBAContext';

@observer
export default class PredefinedDashboardModal extends Component {
  static defaultProps = {
    mode: 'create'
  };
  static contextType = IBAContext;

  generateResourceHref = ({blueprintId, entityId}) => {
    return generateDashboardURI({blueprintId, dashboardId: entityId});
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get predefinedDashboardsWithPreview() {
    return map(this.props.predefinedDashboards, (predefinedDashboard) => (
      {...predefinedDashboard, description: (
        <Fragment>
          <DashboardPreview grid={predefinedDashboard.grid} />
          {predefinedDashboard.description &&
            <Fragment>
              <br />
              <MultiParagraphText asMarkdown text={predefinedDashboard.description} />
            </Fragment>
          }
        </Fragment>
      )}
    ));
  }

  render() {
    const {routes} = this.context;
    const {generateResourceHref} = this;
    return (
      <PredefinedEntityModal
        helpPageId={this.props.helpPageId}
        resourceName='Predefined Dashboard'
        entities={this.predefinedDashboardsWithPreview}
        baseRoute={routes.predefinedDashboardList}
        generateResourceHref={generateResourceHref}
        columnWidths={[7, 9]}
        {...this.props}
      />
    );
  }
}
