import {ctrlPortSize} from '../const';
import './Port.less';

const Port = ({x, y}) => {
  return (
    <circle className='ctrl-port' cx={x} cy={y} r={ctrlPortSize} />
  );
};

export default Port;
