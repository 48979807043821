import {Component, Fragment} from 'react';
import {observable, action, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {pick, sumBy, map, keys, filter,
  flatten, transform, sum, values, isEmpty} from 'lodash';
import {Button, Message, Dropdown, Menu, Label, Icon, Popup, Ref, Segment} from 'semantic-ui-react';
import {ActionsMenu, DataFilter, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, PermissionChecker,
  hasBlueprintPermissions, interpolateRoute, notifier, request} from 'apstra-ui-common';

import DashboardDeletionModal from './DashboardDeletionModal';
import DashboardExportModal from './DashboardExportModal';
import DashboardBatchImportModal from './DashboardBatchImportModal';
import PredefinedDashboardModal from './PredefinedDashboardModal';
import DashboardsDisplayMode, {DASHBOARDS_DISPLAY_MODE} from './DashboardsDisplayMode';
import {getInputStages, getProcessorByStageName, getStageRenderingStrategy,
  getStageComponentPropsFromWidget, getStageByName} from '../stageUtils';
import generateDashboardURI from '../generateDashboardURI';
import UpdatedByLabel from './UpdatedByLabel';
import PredefinedEntityIcon from './PredefinedEntityIcon';
import DashboardActions from './DashboardActions';
import IBAContext from '../IBAContext';
import DashboardPreview from './DashboardPreview';
import DashboardExpanded from './DashboardExpanded';
import DashboardDefaultToggle from './DashboardDefaultToggle';
import userStore from '../../userStore';

import './DashboardList.less';

const tableSchema = [
  {
    name: 'label',
    label: 'Name',
    value: ['label'],
    formatter: ({item: dashboard, value, params: {blueprintId, predefinedDashboards}}) => (
      <div className='entity-name'>
        <Link to={generateDashboardURI({blueprintId, dashboardId: dashboard.id})}>{value}</Link>
        {dashboard.predefined_dashboard &&
          <PredefinedEntityIcon
            predefinedEntities={predefinedDashboards}
            predefinedEntityName={dashboard.predefined_dashboard}
            predefinedEntityType='Predefined dashboard'
          />
        }
      </div>
    ),
    sortable: true,
  },
  {
    name: 'widgets',
    label: 'Widgets',
    formatter: ({item: dashboard, value, params: {dashboardPreview}}) => (
      dashboardPreview ? <DashboardPreview grid={dashboard.grid} /> : value
    ),
    value: ({item: {grid}}) => sumBy(grid, 'length'),
    sortable: true,
  },
  {
    name: 'updated_by',
    label: 'Updated By',
    value: ['updated_by'],
    formatter: ({item: dashboard}) =>
      <UpdatedByLabel updatedBy={dashboard.updated_by} timestamp={dashboard.updated_at} />,
    sortable: true,
  },
  {
    name: 'default',
    label: 'Default',
    description: "Default analytics dashboard will be shown on the blueprint's dashboard.",
    value: ['default'],
    formatter: ({item: dashboard, value, params: {
      dashboardIdToggleInProgress, toggleDashboardDefaultFlag,
      blueprintId, refetchData,
    }}) => (
      <DashboardDefaultToggle
        value={value}
        dashboardIdToggleInProgress={dashboardIdToggleInProgress}
        onChange={() => toggleDashboardDefaultFlag({blueprintId, dashboard, refetchData})}
      />
    ),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'Actions',
    formatter: ({item: dashboard, params}) =>
      <DashboardActions
        size='small'
        dashboard={dashboard}
        {...params}
      />,
  },
];

@observer
export default class DashboardList extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, routes, signal}) {
    const [{items: predefinedDashboards}, {items: dashboards}, {items: probes}] = await Promise.all([
      request(interpolateRoute(routes.predefinedDashboardList, {blueprintId}), {signal}),
      request(interpolateRoute(routes.dashboardList, {blueprintId}), {signal}),
      request(interpolateRoute(routes.probeList, {blueprintId}), {signal}),
    ]);
    return {predefinedDashboards, dashboards, probes};
  }

  static pollingInterval = 10000;
  static userStoreKey = 'dashboardList';

  @observable.ref dashboardDeletionModalProps = null;
  @observable.ref predefinedDashboardModalProps = null;
  @observable.ref dashboardExportModalProps = null;
  @observable.ref dashboardBatchImportModalProps = null;
  @observable dashboardIdToggleInProgress = null;
  @observable dashboardPreview = false;
  @observable displayMode = DASHBOARDS_DISPLAY_MODE.expanded;
  @observable.ref selection = {};
  dashboardRefs = {};

  @action
  setDashboardDeletionModalProps = (props) => {
    this.dashboardDeletionModalProps = props;
  };

  @action
  setPredefinedDashboardModalProps = (props) => {
    this.predefinedDashboardModalProps = props;
  };

  @action
  setDashboardExportModalProps = (props) => {
    this.dashboardExportModalProps = props;
  };

  @action
  setDashboardBatchImportModalProps = (props) => {
    this.dashboardBatchImportModalProps = props;
  };

  @action
  toggleDashboardDefaultFlag = async ({blueprintId, dashboard, refetchData}) => {
    this.dashboardIdToggleInProgress = dashboard.id;
    let updateError = null;
    try {
      await request(
        interpolateRoute(this.context.routes.dashboardDetails, {blueprintId, dashboardId: dashboard.id}),
        {
          method: 'PUT',
          body: JSON.stringify({
            ...pick(dashboard, 'label', 'description', 'grid'),
            default: !dashboard.default
          })
        }
      );
    } catch (error) {
      updateError = error;
    }
    if (updateError) {
      notifier.showError(updateError);
    } else {
      notifier.notify({
        resourceLabel: dashboard.label,
        resourceHref: generateDashboardURI({blueprintId, dashboardId: dashboard.id}),
        message: dashboard.default ? 'Dashboard is not marked as default anymore' : 'Dashboard is marked as default'
      });
    }
    try {
      await refetchData();
    } finally {
      runInAction(() => {
        this.dashboardIdToggleInProgress = null;
      });
    }
  };

  @action
  updateSelection = (selection) => {
    this.selection = selection;
  };

  @action
  onDisplayModeChange = ({displayMode, updateFilters, filters}) => {
    updateFilters({...filters, displayMode});
  };

  getDataTableActions = ({selectionIds, dashboards, blueprintPermissions}) => [
    {
      icon: 'trash',
      title: 'Delete',
      hasPermissions: hasBlueprintPermissions({blueprintPermissions, action: 'edit'}),
      notPermittedMessage: 'You do not have permission to delete',
      onClick: () => this.setDashboardDeletionModalProps({
        open: true, dashboards: dashboards.filter((dashboard) => selectionIds.includes(dashboard.id))
      }),
      popupPosition: 'bottom center',
    }
  ];

  getWidgetAnomalies = (widget, probes) => {
    const {probe, processor, stage} = getStageComponentPropsFromWidget({widget, probes});
    if (!stage) return 0;
    const renderingStrategy = getStageRenderingStrategy({
      probe, processor, stage,
      dataSource: widget.data_source,
      usePattern: widget.show_context
    });
    let anomalies = 0;
    const stages = [stage];
    do {
      const {name: stageName} = stages.pop();
      const currentStage = getStageByName({probe, stageName});
      anomalies += currentStage.anomaly_count ?? 0;
      stages.push(...getInputStages({
        probe,
        processor: getProcessorByStageName({probe, stageName})
      }));
    } while (renderingStrategy?.includePrecedingProcessorAnomalies && !isEmpty(stages));
    return anomalies;
  };

  getDashboardAnomalyCount = ({dashboard, probes}) => {
    const {getWidgetAnomalies} = this;
    const widgets = filter(flatten(dashboard.grid), {type: 'stage'});
    return sumBy(widgets, (widget) => getWidgetAnomalies(widget, probes));
  };

  scrollToDashboard = (dashboard) => {
    const element = this.dashboardRefs[dashboard.id];
    if (element && element.scrollIntoView) {
      element.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  render() {
    const {blueprintId, routes, blueprintPermissions} = this.context;
    const {
      dashboardDeletionModalProps, setDashboardDeletionModalProps,
      predefinedDashboardModalProps, setPredefinedDashboardModalProps,
      dashboardExportModalProps, setDashboardExportModalProps,
      dashboardBatchImportModalProps, setDashboardBatchImportModalProps,
      dashboardIdToggleInProgress, toggleDashboardDefaultFlag,
      getDataTableActions, updateSelection, selection,
      displayMode, onDisplayModeChange, scrollToDashboard, dashboardRefs
    } = this;
    const defaultPageSize = userStore.getStoreValue([DashboardList.userStoreKey, 'pageSize']);

    return (
      <DataFilteringContainer
        stateQueryParam='dashboards-filter'
        defaultPageSize={defaultPageSize}
        defaultFilters={{displayMode}}
        defaultSorting={{label: 'asc'}}
        setUserStoreProps={userStore.setStoreValueFn(DashboardList.userStoreKey)}
      >
        {
          ({activePage, pageSize, updatePagination, sorting, updateSorting, filters, updateFilters}) => {
            return (
              <FetchData
                fetchData={DashboardList.fetchData}
                fetchParams={{blueprintId, routes}}
                pollingInterval={DashboardList.pollingInterval}
              >
                {({dashboards = [], predefinedDashboards = [], probes = [], refetchData}) => {
                  const filteredSelection = pick(selection, map(dashboards, 'id'));
                  const selectionIds = filter(keys(filteredSelection), (key) => filteredSelection[key]);
                  const dataTableActionItems = getDataTableActions({selectionIds, dashboards, blueprintPermissions});
                  return (
                    <>
                      <div className='iba-dashboard-list'>
                        <div className='dashboard-management'>
                          <PermissionChecker
                            hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
                            popupProps={{
                              position: 'top center',
                              offset: ({reference}) => [0, reference.height],
                            }}
                          >
                            <Button
                              as={Link}
                              to='../auto-enabled-dashboards'
                              secondary
                              size='big'
                              icon='settings'
                              content='Configure Auto-Enabled Dashboards'
                            />
                          </PermissionChecker>
                          <PermissionChecker
                            hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
                            notPermittedMessage='You do not have permission to create'
                            popupProps={{
                              position: 'top center',
                              offset: ({reference}) => [0, reference.height],
                            }}
                          >
                            <Dropdown
                              button
                              className='primary big'
                              trigger={
                                <span>
                                  <Icon name='add circle' className='add-dashboard-icon' />
                                  {'Create Dashboard'}
                                </span>
                              }
                              aria-label='Create Dashboard'
                            >
                              <Dropdown.Menu direction='left'>
                                <Dropdown.Item
                                  as={Link}
                                  to='../create-dashboard'
                                  icon='file outline'
                                  content='New Dashboard'
                                />
                                <Dropdown.Item
                                  icon='box'
                                  content='Instantiate Predefined Dashboard'
                                  onClick={() => setPredefinedDashboardModalProps({open: true})}
                                />
                                <Dropdown.Item
                                  icon='upload'
                                  content='Import Dashboards'
                                  onClick={() => setDashboardBatchImportModalProps({open: true})}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </PermissionChecker>
                        </div>
                        <div>
                          <DataFilter
                            items={dashboards}
                            schema={tableSchema}
                            activePage={activePage}
                            pageSize={pageSize}
                            sorting={sorting}
                          >
                            {({items: filteredDashboards, allItems, totalCount}) => {
                              const dashboardAnomalies = transform(filteredDashboards, (acc, dashboard) => {
                                acc[dashboard.id] = this.getDashboardAnomalyCount({dashboard, probes});
                              }, {});
                              const totalAnomalyCount = sum(values(dashboardAnomalies));

                              const buttonsPanel = (
                                <div className='anomalies-count'>
                                  <DashboardsDisplayMode
                                    value={filters.displayMode}
                                    onChange={(displayMode) =>
                                      onDisplayModeChange({displayMode, filters, updateFilters})
                                    }
                                    modes={[
                                      DASHBOARDS_DISPLAY_MODE.summary,
                                      DASHBOARDS_DISPLAY_MODE.preview,
                                      DASHBOARDS_DISPLAY_MODE.expanded
                                    ]}
                                  />
                                  {
                                    filters.displayMode === DASHBOARDS_DISPLAY_MODE.expanded &&
                                    !isEmpty(filteredDashboards) &&
                                      <Popup
                                        basic
                                        hoverable
                                        position='bottom left'
                                        hideOnScroll
                                        offset={[0, -8]}
                                        trigger={
                                          <div>
                                            <Label
                                              color={totalAnomalyCount ? 'red' : 'green'}
                                              className='anomalies-count-label'
                                            >
                                              {`${totalAnomalyCount || 'No'} anomalies`}
                                            </Label>
                                          </div>
                                        }
                                        style={{padding: 0, border: 0}}
                                      >
                                        <Menu vertical fluid>
                                          {filteredDashboards.map((dashboard) => {
                                            return (
                                              <Menu.Item
                                                key={dashboard.id}
                                                name='inbox'
                                                onClick={() => scrollToDashboard(dashboard)}
                                              >
                                                {dashboardAnomalies[dashboard.id] ?
                                                  <Label color='red'>
                                                    <Icon name='warning sign' />
                                                    {dashboardAnomalies[dashboard.id]}
                                                  </Label>
                                                    :
                                                  <Label color='green'>
                                                    <Icon name='check circle' style={{margin: 0}} />
                                                  </Label>
                                                }
                                                {dashboard.label}
                                              </Menu.Item>
                                            );
                                          })}
                                        </Menu>
                                      </Popup>
                                  }
                                </div>
                              );

                              return (
                                <DataFilteringLayout
                                  paginationProps={{
                                    totalCount,
                                    activePage,
                                    pageSize,
                                    onChange: updatePagination
                                  }}
                                  extraContent={{
                                    buttonsPanel
                                  }}
                                >
                                  {filteredDashboards.length ?
                                    <Fragment>
                                      {selectionIds.length > 0 &&
                                        <ActionsMenu size='small' items={dataTableActionItems} />
                                      }
                                      {filters.displayMode === DASHBOARDS_DISPLAY_MODE.expanded ?
                                        filteredDashboards.map((dashboard) =>
                                          <Ref
                                            key={dashboard.id}
                                            innerRef={(element) => {dashboardRefs[dashboard.id] = element;}}
                                          >
                                            <Segment
                                              className='iba-dashboard-expanded-container'
                                              color={dashboardAnomalies[dashboard.id] ? 'red' : null}
                                            >
                                              <DashboardExpanded
                                                dashboard={dashboard}
                                                predefinedDashboards={predefinedDashboards}
                                                setDashboardDeletionModalProps={setDashboardDeletionModalProps}
                                                setDashboardExportModalProps={setDashboardExportModalProps}
                                                dashboardIdToggleInProgress={dashboardIdToggleInProgress}
                                                toggleDashboardDefaultFlag={() => toggleDashboardDefaultFlag({
                                                  dashboard, blueprintId, refetchData
                                                })}
                                              />
                                            </Segment>
                                          </Ref>)
                                      :
                                        <DataTable
                                          selectable
                                          allowSelectAll
                                          allItems={allItems}
                                          updateSelection={updateSelection}
                                          selection={filteredSelection}
                                          verticalAlign='middle'
                                          items={filteredDashboards}
                                          schema={tableSchema}
                                          params={{
                                            blueprintId, setDashboardDeletionModalProps, setDashboardExportModalProps,
                                            dashboardIdToggleInProgress, toggleDashboardDefaultFlag,
                                            predefinedDashboards,
                                            dashboardPreview: filters.displayMode === DASHBOARDS_DISPLAY_MODE.preview,
                                            refetchData,
                                          }}
                                          sorting={sorting}
                                          updateSorting={updateSorting}
                                          getHeaderCellProps={({name}) => ({
                                            collapsing:
                                              name === 'actions' ||
                                              name === 'widgets' &&
                                                filters.displayMode === DASHBOARDS_DISPLAY_MODE.preview
                                          })}
                                          getItemKey={({id}) => id}
                                        />
                                      }
                                    </Fragment>
                                :
                                    <Message
                                      info
                                      icon='info circle'
                                      content='There are currently no dashboards defined.'
                                    />
                                  }
                                </DataFilteringLayout>
                              );
                            }}
                          </DataFilter>
                        </div>
                      </div>
                      <DashboardDeletionModal
                        open={false}
                        onClose={() => setDashboardDeletionModalProps({open: false})}
                        onSuccess={() => refetchData()}
                        {...dashboardDeletionModalProps}
                      />
                      <DashboardExportModal
                        open={false}
                        onClose={() => setDashboardExportModalProps({open: false})}
                        {...dashboardExportModalProps}
                      />
                      <PredefinedDashboardModal
                        open={false}
                        onClose={() => setPredefinedDashboardModalProps({open: false})}
                        predefinedDashboards={predefinedDashboards}
                        {...predefinedDashboardModalProps}
                      />
                      <DashboardBatchImportModal
                        open={false}
                        onUploadSuccess={refetchData}
                        onClose={() => setDashboardBatchImportModalProps({open: false})}
                        {...dashboardBatchImportModalProps}
                      />
                    </>
                  );
                }}
              </FetchData>
            );
          }
        }
      </DataFilteringContainer>
    );
  }
}
