import {observable, action, makeObservable} from 'mobx';
import {debounce} from 'lodash';

const SHOW_POPUP_DELAY = 300;

export class SharedTooltip {
  @observable isOpen = false;
  @observable isInverted = false;
  @observable.ref content = null;
  @observable offset = [0, 0];
  cursorPosition = [0, 0];
  @observable position = 'top right';
  @observable.ref mountRef = null;

  followThePointer = (mouseEvent, containerRef) => {
    const {clientX, clientY} = mouseEvent;
    const {x, y} = containerRef.current?.getBoundingClientRect?.() ?? {x: 0, y: 0};
    this.cursorPosition = [clientX - x - 20, 5 - clientY + y];
  };

  show = (content, inverted) => {
    this.#hideDebounced.cancel();
    this.#showDebounced(content, inverted);
  };

  #showDebounced = debounce(action((content, inverted) => {
    this.content = content;
    // Popup must not be displayed until position is determined
    this.isOpen = true;
    this.isInverted = !!inverted;
    this.offset = this.cursorPosition;
  }), SHOW_POPUP_DELAY);

  hide = () => {
    this.#showDebounced.cancel();
    if (this.isOpen) this.#hideDebounced();
  };

  cancelHideTooltip = () => {
    this.#hideDebounced.cancel();
  };

  hideImmediately = action(() => {
    if (this.isOpen) {
      this.isOpen = false;
      this.content = null;
    }
  });

  #hideDebounced = debounce(this.hideImmediately, SHOW_POPUP_DELAY);

  @action
  setMountRef(ref) {
    this.mountRef = ref;
  }

  constructor() {
    makeObservable(this);
  }
}
