import {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {action, runInAction} from 'mobx';
import {Dimmer, Loader, Message} from 'semantic-ui-react';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import {forEach, isEmpty, map} from 'lodash';

import useUserStore from '../../hooks/useUserStore';
import {CablingMapStoreProvider} from '../store/useCablingMapStore';
import CablingMapEditorStore from '../store/CablingMapEditorStore';
import {TooltipPopup, TooltipProvider} from '../../components/graphs/GraphTooltips';
import Menu from './Menu';
import Canvas from './Canvas';
import TooltipDataProvider from './TooltipDataProvider';
import {useTopologySaver} from '../utils';
import {CME_COMMON_SAVING_ERROR, nodeTextSources} from '../const';
import './CablingMapEditor.less';

const CABLIMG_MAP_EDITOR_ARRANGE_ALGORITHM_KEY = 'CablingMapEditorArrangeAlgorithm';
const CABLING_MAP_EDITOR_DEFAULT_NODE_TEXT_SOURCE_KEY = 'CablingMapEditorNodeTextSource';

const CablingMapEditor = observer(forwardRef(({
  dps, nodes, links, aggregateLinks = [], tags = [], readonly, onClickNode,
  layer, blueprintId, onSave, onValid,
  preferences, selectedLayerData, availableDevices,
  waitingForActualData
}, ref) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState();
  const [snapped, setSnapping] = useState(true);
  const [userStorePreferences = {arrangeType: 'user-defined'}, setUserStorePreferences] =
    useUserStore(CABLIMG_MAP_EDITOR_ARRANGE_ALGORITHM_KEY);
  const [nodeTextSourcePreference = {value: nodeTextSources.LABEL}, setNodeTextSourcePreference] =
    useUserStore(CABLING_MAP_EDITOR_DEFAULT_NODE_TEXT_SOURCE_KEY);
  const [zoomNode, setZoomNode] = useState(null);

  const prevStoreRef = useRef();

  // Will be used for topology initialization from api payload
  // and upon changes cancellation by user
  const restoreFromProps = useCallback((store) => {
    const {cablingMap} = store;
    cablingMap.reset();
    const {userData: customData, snap = true} = preferences ?? {};
    setSnapping(snap);
    cablingMap.restore({nodes, links, tags, aggregateLinks, deviceProfiles: dps, customData, availableDevices});
    store.setNodeTextSource(nodeTextSourcePreference.value);
  }, [nodes, links, aggregateLinks, tags, dps, availableDevices]); // eslint-disable-line react-hooks/exhaustive-deps

  // Memoizing store
  const store = useMemo(() => {
    if (!readonly && prevStoreRef.current) return prevStoreRef.current;

    const store = new CablingMapEditorStore();
    restoreFromProps(store);

    prevStoreRef.current = store;
    setErrors(null);
    return store;
  }, [restoreFromProps, readonly, setErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (nodeTextSourcePreference.value !== store.nodeTextSource) {
      setNodeTextSourcePreference({value: store.nodeTextSource});
    }
  }, [nodeTextSourcePreference.value, setNodeTextSourcePreference, store.nodeTextSource]);

  useEffect(() => {
    store.arranger.setReadonly(readonly);
    store.arranger.setArrangeType(userStorePreferences.arrangeType);
  }, [readonly, store.arranger, userStorePreferences.arrangeType]);

  useEffect(() => {
    runInAction(() => {
      forEach(store.cablingMap.nodes, (node) => {
        node.arrangePosition = readonly ? store.arranger.positions?.[node.id] : null;
      });
      store.cablingMap.updateArea();
    });
  }, [userStorePreferences.arrangeType, store.arranger.positions, readonly, store]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(action(() => {
    store.layerData = readonly && selectedLayerData;
  }), [readonly, selectedLayerData, store]);

  useEffect(() => () => store.dispose(), [store]);

  // Handling topology saving (nodes and links)
  const saveTopology = useTopologySaver({
    blueprintId,
    nodes, links, aggregateLinks,
    cablingMapStore: store.cablingMap,
    preferences: {...preferences, snap: snapped}
  });

  // Cummulative saving handler
  const saveHandler = useCallback(() => {
    (async function() {
      setIsSaving(true);
      setErrors(null);
      try {
        await saveTopology();
        onSave?.();
      } catch ({errors: savingErrors}) {
        setErrors(isEmpty(savingErrors) ? [CME_COMMON_SAVING_ERROR] : savingErrors);
      } finally {
        setIsSaving(false);
      }
    })();
  }, [onSave, saveTopology]);

  useImperativeHandle(ref, () => ({
    save: saveHandler,
    cancel: () => restoreFromProps(store)
  }), [saveHandler, restoreFromProps, store]);

  useEffect(() => {
    onValid?.(!!store.cablingMap.isValid);
  }, [onValid, store.cablingMap.isValid]);
  const fullscreenHandle = useFullScreenHandle();

  const onNodesFound = useCallback((nodesFound) => {
    store.highlight.setHighlightNodeIds(map(nodesFound, 'id'));
  }, [store]);

  const {tags: allTags} = store.cablingMap;

  return (
    <TooltipProvider layer={layer}>
      <TooltipDataProvider
        nodes={nodes}
        links={links}
        aggregateLinks={aggregateLinks}
        selectedLayerData={selectedLayerData}
      >
        <TooltipPopup />
        {(isSaving || store.arranger.isCalculating || waitingForActualData) && (
          <Dimmer active inverted>
            <Loader
              content={isSaving ? 'Saving' : waitingForActualData ? 'Fetching updated data' : 'Arranging nodes'}
            />
          </Dimmer>
        )}
        <CablingMapStoreProvider value={store}>
          <Menu
            {...{snapped, setSnapping, readonly, onNodesFound}}
            arrangeType={userStorePreferences.arrangeType}
            setArrangeType={(arrangeType) => setUserStorePreferences({arrangeType})}
            enterFullScreenFn={fullscreenHandle.enter}
            onActivateNode={setZoomNode}
          />
          {!!errors?.length &&
            <Message
              error
              header='There were some errors while saving topology'
              list={errors}
            />
          }
          <FullScreen handle={fullscreenHandle}>
            <Canvas
              {...{readonly, onClickNode, tags: allTags, snapped, availableDevices}}
              fullscreen={fullscreenHandle.active}
              zoomNode={zoomNode}
            />
          </FullScreen>
        </CablingMapStoreProvider>
      </TooltipDataProvider>
    </TooltipProvider>
  );
}));

export default CablingMapEditor;
