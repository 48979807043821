import {Component} from 'react';
import {isNumber, get} from 'lodash';
import {scaleOrdinal} from 'd3';
import cx from 'classnames';
import {formatDateAsLocalDateTimeMS, formatNumber, formatSeconds} from 'apstra-ui-common';

import {timeInStateProcessorUtils, rangeProcessorUtils} from '../../processorUtils';
import {discreteStateColors} from '../../../graphColors';
import RangeValue from '../../../components/RangeValue';
import './AnomalyHistoryGraphLegend.less';

export default class AnomalyHistoryGraphLegend extends Component {
  static defaultProps = {
    colors: discreteStateColors
  };

  colorScale = scaleOrdinal(this.props.colors);

  get columns() {
    const {counterType, numericValue, sustainedAnomalyValue, instantaneousAnomalyValue,
      lastSampleTime, possibleValues, relatedProcessors, relatedItems} = this.props;
    const {colorScale, renderNumericRange, renderSecondsRange} = this;

    const {rangeProcessor, timeInStateProcessor} = relatedProcessors;
    const {rangeSourceItem, timeInStateSourceItem} = relatedItems;

    colorScale.domain(possibleValues);

    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(rangeProcessor, rangeSourceItem);
    const {min: timeInStateMin, max: timeInStateMax} =
      timeInStateProcessorUtils.getMinMax(timeInStateProcessor, timeInStateSourceItem);
    const monitoredState = timeInStateProcessorUtils.getState(timeInStateProcessor, timeInStateSourceItem);

    const period = timeInStateProcessorUtils.getTimeWindow(timeInStateProcessor, timeInStateSourceItem);
    const dseTime = get(timeInStateSourceItem, ['dse_time']);

    const instantaneousAnomalyColorClassName = `graph-color-${this.colorScale(instantaneousAnomalyValue)}`;
    const sustainedAnomalyColorClassName = `graph-color-${this.colorScale(sustainedAnomalyValue)}`;

    return [
      {
        title: (
          <span>{'Instantaneous Anomaly: '}
            <span className={instantaneousAnomalyColorClassName}>{instantaneousAnomalyValue}</span>
          </span>),
        rows: [
          {title: 'Counter Type: ', value: counterType, hidden: !counterType},
          {title: 'Anomalous Range: ', value: renderNumericRange(rangeMin, rangeMax)},
          {title: 'Value: ', value: formatNumber(numericValue), valueClassName: instantaneousAnomalyColorClassName},
          {title: 'Last Sample Time: ', value: formatDateAsLocalDateTimeMS(lastSampleTime)}
        ]
      },
      {
        title: (
          <span>{'Sustained Anomaly: '}
            <span className={sustainedAnomalyColorClassName}>{sustainedAnomalyValue}</span>
          </span>),
        rows: [
          {title: 'Period: ', value: formatSeconds(period)},
          {title: 'Monitored State: ', value: monitoredState},
          {title: 'Time Range: ', value: renderSecondsRange(timeInStateMin, timeInStateMax)},
          {title: 'Current time: ', value: formatSeconds(dseTime)},
        ]
      }
    ];
  }

  renderRange = (min, max) => {
    return <RangeValue min={min} max={max} />;
  };

  renderSecondsRange = (min, max) => {
    return this.renderRange(
      isNumber(min) ? formatSeconds(min) : null,
      isNumber(max) ? formatSeconds(max) : null
    );
  };

  renderNumericRange = (min, max) => {
    return this.renderRange(
      isNumber(min) ? formatNumber(min) : null,
      isNumber(max) ? formatNumber(max) : null
    );
  };

  renderSectionRow = ({title, value, valueClassName, index}) => (
    <div key={index} className='section-row'>
      <span className='section-row-title'>{title}</span>
      <span className={cx('section-row-value', valueClassName)}>
        {value}
      </span>
    </div>
  );

  renderSectionTitle = (title) => <div className='section-title'>{title}</div>;

  render() {
    const {columns, renderSectionTitle, renderSectionRow} = this;

    return (
      <div className='iba-anomaly-history-graph-legend'>
        {columns.map((column, index) => (
          <div key={index} className='section'>
            {renderSectionTitle(column.title)}
            {column.rows.filter((row) => !row.hidden).map((row, innerIndex) => (
              renderSectionRow({...row, index: innerIndex})
            ))}
          </div>
        ))}
      </div>
    );
  }
}
