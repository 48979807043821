import {Grid} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {useEffect} from 'react';
import {action} from 'mobx';
import {map} from 'lodash';

import ParametersDiff from './ParametersDiff';
import Canvas from '../Canvas';
import Ept from '../../store/Ept';
import Sausager from '../../Sausager';
import './EptDiff.less';

// Prevents requesting the full set of nodes by type
// to prepopulate DDL control
const nodesGetterStub = () => Promise.resolve([]);

// Builds the Ept object from set of parameterized primitives
const fillEptIn = ({activeEptId, epts, eptSchemas}) => {
  const sausage = epts.find((ept) => ept.id === activeEptId);
  return sausage ?
    Ept.fromSausage(sausage, new Sausager(epts, eptSchemas, nodesGetterStub), true) :
    new Ept();
};

const EptDiff = (props) => {
  const {onlyShowDifferent = false, getNodeDetails} = props;

  // Converts nodes ids to labels via getNodeDetails method
  const fetchNodeLabels = (ept, isStaged = false) => {
    map(ept.parameters, ({name, value, schema: {type, subtype}}) => {
      if (type === 'node') {
        // Single node type parameter
        getNodeDetails(value, isStaged)
          .then(action((node) => (ept.parameters[name].value = node)));
      } else if (subtype === 'node_id') {
        // List of nodes parameter
        Promise.all(map(value, (id) => getNodeDetails(id, isStaged)))
          .then(action((nodes) => (ept.parameters[name].value = nodes)));
      }
    });

    map(ept.epts, (childEpt) => fetchNodeLabels(childEpt, isStaged));
  };

  const eptA = fillEptIn({...props, epts: props.eptsActive});
  const eptB = fillEptIn({...props, epts: props.eptsStaged});
  if (eptA && eptB) {
    eptA.inSyncWith = eptB;
    eptB.inSyncWith = eptA;
  }

  useEffect(() => {
    fetchNodeLabels(eptA);
    fetchNodeLabels(eptB, true);
  });

  return (
    <Grid>
      <Grid.Column width={4} className='ct-diff-properties'>
        <h3>{'Properties'}</h3>
        <ParametersDiff {...{eptA, eptB, onlyShowDifferent, getNodeDetails}} />
      </Grid.Column>
      <Grid.Column width={6} className='canvas-container'>
        <h3>{'Active'}</h3>
        {!eptA.isEmpty &&
          <Canvas
            showIntro={false}
            ept={eptA}
            comparisonEpt={eptB}
            isComparisonDirect={false}
            scale={0.7}
            readonly
            onlyShowDifferent={onlyShowDifferent}
          />
        }
      </Grid.Column>
      <Grid.Column width={6} className='canvas-container'>
        <h3>{'Staged'}</h3>
        {!eptB.isEmpty &&
          <Canvas
            showIntro={false}
            ept={eptB}
            comparisonEpt={eptA}
            isComparisonDirect
            scale={0.7}
            readonly
            onlyShowDifferent={onlyShowDifferent}
          />
        }
      </Grid.Column>
    </Grid>
  );
};

export default observer(EptDiff);
