import {castArray, forEach, isPlainObject, compact, join} from 'lodash';
import isNumeric from 'is-number';

const joinMessage = (prefix, message, separator = '.') => {
  return join(compact([prefix, message]), separator);
};

export const addError = (result, messages, generateError) => {
  result.push(...castArray(messages).map((message) => generateError(message)));
};

export const searchForGenericErrors = (result, object, prefix = '') => {
  if (isPlainObject(object)) {
    forEach(object, (value, key) => searchForGenericErrors(result, value, joinMessage(prefix, key)));
  } else {
    addError(result, object, (error) => ({
      type: 'generic',
      message: joinMessage(prefix, error, ': ')
    }));
  }
};

export const castObjectValuesToArray = (object) => {
  const result = [];
  forEach(object, (value, key) => {
    if (isNumeric(key)) {
      result[key] = value;
    } else {
      result.push(value);
    }
  });
  return result;
};
