import {useCallback, useEffect, useState} from 'react';

import InterWindowController from './InterWindowController';

const GraphExplorerNewWindow = ({
  url = '#/platform/developer/graph-explorer',
  onOpen,
  onClose,
  saveQuery,
  defaultQuery,
  explorerProps,
}) => {
  const [explorerWindow, setExplorerWindow] = useState(null);

  const onUnload = useCallback(() => {
    onClose();
    setExplorerWindow(null);
  }, [onClose]);

  useEffect(() => {
    const explorerWindow = window.open(url);
    explorerWindow.graphExplorerControllerWindow = window;
    explorerWindow.focus();
    explorerWindow.addEventListener('load', () => {
      explorerWindow.addEventListener('beforeunload', onUnload);
      onOpen?.(explorerWindow);
      window.addEventListener('beforeunload', onUnload);
      setExplorerWindow(explorerWindow);
    });
    return () => {
      explorerWindow?.close();
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [onOpen, onUnload, url]);

  const handleSaveQuery = (query) => {
    saveQuery?.(query);
    onUnload();
  };

  return (
    explorerWindow &&
      <InterWindowController
        defaultQuery={defaultQuery}
        saveQuery={handleSaveQuery}
        window={explorerWindow}
        {...explorerProps}
      />
  );
};

export default GraphExplorerNewWindow;
