import {Lexer, CstParser, createToken} from 'chevrotain';

export const DoubleQuoteStringLiteral = createToken({
  name: 'DoubleQuoteStringLiteral',
  pattern: /"(:?[^\\\n\r"]+|\\(:?[nr\\/"]))*"/,
});
export const NoQuoteStringLiteral = createToken({
  name: 'NoQuoteStringLiteral',
  pattern: /[\w*?./]+/,
});
export const Field = createToken({
  name: 'Field',
  pattern: /[a-zA-Z_]+[\w.]*\s*:/,
});
export const LParen = createToken({name: 'LParen', pattern: '('});
export const RParen = createToken({name: 'RParen', pattern: ')'});
export const Or = createToken({name: 'Or', pattern: 'OR'});
export const And = createToken({name: 'And', pattern: 'AND'});
export const WhiteSpace = createToken({name: 'WhiteSpace', pattern: /\s+/, group: Lexer.SKIPPED});

export const allTokens = [
  WhiteSpace,
  LParen,
  RParen,
  Or,
  And,
  Field,
  DoubleQuoteStringLiteral,
  NoQuoteStringLiteral,
];

export const LuceneApstraStringLexer = new Lexer(allTokens);

let parserInstance = null;

export default class LuceneApstraStringParser extends CstParser {
  static get instance() {
    if (!parserInstance) parserInstance = new this();
    return parserInstance;
  }

  static parse(text) {
    const lexResult = LuceneApstraStringLexer.tokenize(text);
    const parser = this.instance;
    parser.input = lexResult.tokens;
    const cst = parser.expression();
    return {cst, lexErrors: lexResult.errors, parseErrors: parser.errors};
  }

  constructor() {
    super(allTokens);
    const $ = this;
    $.RULE('expression', () => {
      $.SUBRULE($.conditionOrSubexpression);
      $.MANY(() => {
        $.OPTION(() => {
          $.OR([
            {ALT: () => $.CONSUME(Or)},
            {ALT: () => $.CONSUME(And)}
          ]);
        });
        $.SUBRULE2($.conditionOrSubexpression);
      });
    });
    $.RULE('conditionOrSubexpression', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.condition)},
        {ALT: () => $.SUBRULE($.subexpression)},
      ]);
    });
    $.RULE('subexpression', () => {
      $.CONSUME(LParen);
      $.SUBRULE($.expression);
      $.CONSUME(RParen);
    });
    $.RULE('condition', () => {
      $.OPTION(() => $.CONSUME(Field));
      $.SUBRULE($.string);
    });
    $.RULE('string', () => {
      $.OR([
        {ALT: () => $.CONSUME(DoubleQuoteStringLiteral)},
        {ALT: () => $.CONSUME(NoQuoteStringLiteral)}
      ]);
    });
    $.performSelfAnalysis();
  }
}
