import {observable, computed, action, toJS, makeObservable} from 'mobx';
import {isEmpty, isArray, castArray, has, filter, min, max, map, compact, toLower, find, transform,
  values} from 'lodash';

import {generateId, beautifyTitle} from '../utils';
import Link from './Link';
import Sausager from '../Sausager';
import ParametersValidator from '../ParametersValidator';
import {eptWidth, eptHeight, canvasWidth, canvasHeight, aplicationPointWidth} from '../settings';

const applicationPoint = {
  position: {x: 10 + canvasWidth / 2, y: 23},
  isInput: false,
  outputTypes: null,
  outputIsFlexible: true,
  id: ''
};

const validator = new ParametersValidator();

export const emptyEpt = {
  id: '',
  title: '',
  description: '',
  order: 0,
  creationOrder: 0,
  type: 'custom',
  inputTypes: null,
  inputIsFlexible: false,
  outputTypes: null,
  outputIsFlexible: false,
  position: {x: 0, y: 0},
  epts: {
    '': applicationPoint
  },
  links: {},
  parameters: {},
  parametersErrors: {},
  appliedTimes: 0,
  activePrimitiveId: null,
  tags: []
};

// Gets a searchable value of given EPT parameter
function getSearchableValue({value, schema}) {
  const list = toJS(schema?.oneOf ?? schema?.anyOf);
  if (list) {
    // It value is one value of (any values of) a list,
    // every non-empty value ...
    return toLower(compact(
      // must be mapped ...
      map(
        castArray(value),
        // to a title
        (valueItem) => (find(list, {const: valueItem})?.title || valueItem)
      )
    ).join(' '));
  } else {
    // Otherwise return value as is
    return toLower(value);
  }
}

function getSchemaInOutTypes(schema = {}) {
  return {
    inputTypes: isEmpty(schema.input_node_type) ? null : castArray(schema.input_node_type),
    outputTypes: (schema.output_node_type && schema.output_node_type !== 'none') ? [schema.output_node_type] : null,
    inputIsFlexible: false,
    outputIsFlexible: false
  };
}

export class Ept {
  @observable id;
  @observable title;
  @observable description;
  @observable order;
  @observable creationOrder;
  @observable type;
  dbType;
  @observable inputTypes;
  inputIsFlexible;
  @observable outputTypes;
  outputIsFlexible;
  @observable position;
  @observable epts;
  @observable links;
  @observable parameters;
  @observable parametersErrors;
  @observable appliedTimes;
  @observable activePrimitiveId = null;
  @observable tags = [];
  @observable touched = false;

  inSyncWith = null;

  // EPT data to search through
  @computed
  get parametersSearchData() {
    return transform(
      this.parameters,
      (acc, parameter, id) => {
        acc[id] = getSearchableValue(parameter);
      },
      {}
    );
  }

  // EPT data to search through
  @computed
  get searchData() {
    return [
      toLower(this.title),
      ...compact(values(this.parametersSearchData))
    ].join(' ');
  }

  @computed
  get isNotPositioned() {
    return !this.position?.x && !this.position?.y;
  }

  // True if EPT's searchData matches the searchString supplied
  matchesSearch(needle) {
    return this.searchData.indexOf(needle) >= 0;
  }

  // Checks if EPT is editable (has parameters user can customize)
  @computed
  get editable() {
    return !!this.id && !isEmpty(this.parameters);
  }

  // An EPT is complete when all its mandatory parameters are defined
  @computed
  get isComplete() {
    return !Object.values(this.parameters)
      .some((parameter) => parameter.isMandatory && !parameter.value);
  }

  // An EPT could be primitive (atomic) or custom (made of primitives)
  @computed
  get isPrimitive() {
    return this.type === 'primitive';
  }

  // EPT is considered empty if it does not contain child EPTs
  @computed
  get isEmpty() {
    return Object.keys(this.epts).length < 2;
  }

  // Whether there are errors in parameters
  @computed
  get hasErrors() {
    return !isEmpty(this.parametersErrors) || !isEmpty(this.missingAttributes);
  }

  // Any changes to the EPT that can be saved
  @action
  touch(state = true) {
    this.touched = state;
  }

  // Set some property of a parameter
  @action
  setParameterProperty(name, property, value, registerChage = true) {
    if (has(this.parameters, [name])) {
      this.parameters[name][property] = value;
      if (registerChage) {
        this.touch();
      }
    }
  }

  // Define a value of EPT parameter
  @action
  setParameter(name, value, registerChange = true) {
    this.setParameterProperty(name, 'value', value, registerChange);
    this.validateParameters();
  }

  // Primitive whose parameters are being edited must be highlighted on
  // the canvas
  @action
  setActivePrimitive(id) {
    this.activePrimitiveId = id;
    if (this.inSyncWith) {
      this.inSyncWith.activePrimitiveId = id;
    }
  }

  // Nodes list for DDL population is fetched asynchronously
  // thus this method reacts on data fetching for post-population
  setParameterSchema(name, schema) {
    this.setParameterProperty(name, 'schema', schema, false);
  }

  // Parameter can have displaying state (visible/hidden/enabled/disabled)
  setParameterState(name, state) {
    this.setParameterProperty(name, 'state', state, false);
  }

  // Parameter can be mandatory or optional
  setParameterRequired(name, isRequired) {
    this.setParameterProperty(name, 'required', isRequired, false);
    this.validateParameters();
  }

  // Property that contains all the parameters errors
  // to reflect on the UI
  @action
  setParametersErrors(errors = {}) {
    this.parametersErrors = errors;
  }

  // Set EPT's input/output types when they are not fixed
  @action
  setAcceptedTypes(eptId, types, isInput) {
    const ept = this.epts[eptId];
    if (!ept) return;
    ept[isInput ? 'inputTypes' : 'outputTypes'] = types;
  }

  // Checks whether any of required parameters are empty
  @action
  validateParameters() {
    this.setParametersErrors(validator.validate(this.parameters));
  }

  // Change name & description
  @action
  setTitle(title, description = null) {
    this.title = title;
    if (description !== null) {
      this.description = description;
    }
    this.touch();
  }

  @action
  moveTo(position, registerChage = true) {
    this.position = position;
    if (registerChage) {
      this.touch();
    }
  }

  // Reorder EPTs on canvas so the one with given ID is rendered last
  // (on top of the others)
  @action
  bringEptOnTop(id) {
    if (!this.epts[id]) return;
    this.epts[id].order = 1 + Math.max(...Object.values(this.epts).map((ept) => +ept.order || 0));
  }

  // Clone the EPT
  clone(data = {}) {
    const {id, title, description, type, dbType, inputTypes, inputIsFlexible,
      outputTypes, outputIsFlexible, epts, links, parameters} = toJS(this);

    return new Ept(Object.assign({
      id, title, description, type, dbType, inputTypes, inputIsFlexible,
      outputTypes, outputIsFlexible,
      epts: Object.values(epts).reduce((result, ept) => {
        result[ept.id] = new Ept(ept);
        return result;
      }, {}),
      links: Object.values(links).reduce((result, link) => {
        const newLink = new Link(link);
        result[newLink.id] = newLink;
        return result;
      }, {}),
      parameters: Object.entries(parameters).reduce((result, [name, value]) => {
        result[name] = Object.assign({}, value);
        return result;
      }, {})
    }, data));
  }

  // Create an EPT from the primitive's JSON representation
  static fromPrimitive(primitive, sausager, attributes = {}) {
    const result = new Ept();
    Object.assign(
      result,
      {
        id: generateId(),
        title: primitive.label || beautifyTitle(primitive.name),
        description: primitive.description || '',
        order: 0,
        creationOrder: 0,
        type: 'primitive',
        dbType: primitive.name,
        position: {x: 0, y: 0}
      },
      getSchemaInOutTypes(primitive)
    );

    sausager.combineParametersWithSchema(
      {
        policy_type_name: primitive.name,
        attributes
      },
      result,
      true
    );
    return result;
  }

  // Create an EPT from the sausage's JSON representation
  static fromSausage(sausage, sausager, goDeep = false, ignorePositions = false, resetApplicationsCount = false) {
    const result = new Ept();
    Object.assign(
      result,
      {
        id: sausage.id,
        title: sausage.label,
        description: sausage.description || '',
        order: 0,
        creationOrder: 0,
        type: 'custom',
        dbType: sausage.policy_type_name,
        position: {x: 0, y: 0},
        epts: {
          '': Object.assign({}, applicationPoint)
        },
        links: {},
        appliedTimes: resetApplicationsCount ? 0 : +sausage.app_points_count,
        missingAttributes: sausage.missing_attributes,
        tags: sausage?.tags ?? []
      },
      getSchemaInOutTypes(sausager.schemas[sausage.policy_type_name])
    );

    sausager.combineParametersWithSchema(sausage, result, false);

    if (goDeep) sausager.desausage(sausage, result, result.appliedTimes, ignorePositions);
    return result;
  }

  // Create an EPT from the template provided by Global Catalog
  static fromTemplate(template, sausager) {
    // Sausage to parse must be non-empty Array of primitives
    let sausage;
    try {
      sausage = template.payload.policies;
      if (!isArray(sausage) || isEmpty(sausage)) {
        throw 'Template does not correspond the format expected';
      }
    } catch (error) {
      throw `Unable to parse Global Catalog template ${template.label}`;
    }

    // Create temporary Sausager with references to the template' EPTs
    const templateSausager = new Sausager(sausage, [], sausager.nodeGetByType);
    templateSausager.schemas = sausager.schemas;

    return Ept.fromSausage(sausage[0], templateSausager, true, true);
  }

  generateMeta() {
    this.meta = {
      batch: generateId(),
      pipeline: generateId()
    };
  }

  @computed get canvasSize() {
    const epts = filter(this.epts, ({id}) => !!id);
    const positions = map(epts, 'position');
    const left = min([0, ...map(positions, 'x')]);
    const right = max([aplicationPointWidth, ...map(positions, ({x}) => x + eptWidth)]);
    const top = min([0, ...map(positions, 'y')]);
    const bottom = max([canvasHeight, ...map(positions, ({y}) => y + eptHeight)]);
    return [left, top, right - left, bottom - top];
  }

    // Recursively determines which primitives are linked [in]directly
  // to the Application Point and which are not
  _markAsLinkedToAp(eptId) {
    return Object.values(this.links)
      .filter((link) => link.from === eptId)
      .reduce((result, link) =>
        Object.assign(result, {[link.to]: true}, this._markAsLinkedToAp(link.to))
      , {});
  }

  @computed
  get eptsConnections() {
    return this._markAsLinkedToAp('');
  }

  hasConnections(eptId) {
    return !!this.eptsConnections[eptId];
  }

  constructor(data = {}) {
    makeObservable(this);
    Object.assign(
      this,
      emptyEpt,
      data
    );
    this.generateMeta();
  }
}

export default Ept;
