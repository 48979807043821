import {observer} from 'mobx-react';
import {useMemo} from 'react';
import {keyBy, transform} from 'lodash';
import {Popup} from 'semantic-ui-react';
import {keyPressClick} from 'apstra-ui-common';

import LogicalDevices from './LogicalDevices';
import Legend from './Legend';

import './RackTypeStructure.less';

const RackTypeStructure = ({rackType, interfaceMaps, deviceProfiles, rtOnClick, ldOnClick, dpOnClick, showLegend}) => {
  const {display_name: label, logical_devices: logicalDevices} = rackType;
  const {relationships} = useRackTypeStructure({interfaceMaps, logicalDevices, deviceProfiles});
  const clickHandlers = {ldOnClick, dpOnClick};
  const interactiveProps = rtOnClick ?
    {
      tabIndex: 0,
      role: 'button',
      onClick: () => rtOnClick(rackType),
      onKeyDown: keyPressClick(rtOnClick, rackType)
    } : {};

  return (
    <>
      {
        showLegend &&
          <Legend />
      }
      <div className='rack-type-structure'>
        <div className='rack-type arrowed'>
          <Popup
            position='top center'
            trigger={<label {...interactiveProps}>{label}</label>}
          >
            {label}
          </Popup>
        </div>
        <div className='logical-devices'>
          <LogicalDevices logicalDevices={logicalDevices} relationships={relationships} {...clickHandlers} />
        </div>
      </div>
    </>
  );
};

export default observer(RackTypeStructure);

export const useRackTypeStructure = ({interfaceMaps, deviceProfiles}) => {
  const deviceProfilesById = useMemo(() => keyBy(deviceProfiles, 'id'), [deviceProfiles]);
  const relationships = useMemo(() => {
    return transform(
      interfaceMaps,
      (relationships, {label, device_profile: {id: dpId}, logical_device: {id: ldId}}) => {
        (relationships[ldId] ??= []).push({label, deviceProfile: deviceProfilesById[dpId]});
      },
      {}
    );
  }, [deviceProfilesById, interfaceMaps]);
  return {relationships};
};
