import {Component, useCallback} from 'react';
import {action, computed, observable, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {find, filter, flattenDeep, map, omit, some, sortBy, transform, flatten} from 'lodash';
import {Button, Container, Form} from 'semantic-ui-react';
import {DropdownControl, interpolateRoute, request} from 'apstra-ui-common';

import {filtersToQueryParam} from '../../queryParamUtils';
import IBAContext from '../IBAContext';
import {TRAFFIC_PROBE_STAGE, buildGraphFromCablingMap, getGraphBfs, getNeighborsPaths} from './graphs/trafficUtils';

@observer
export default class TrafficSearchBox extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static shouldFetchData({filters}) {
    // eslint-disable-next-line camelcase
    return some(filters?.filter?.system_id);
  }

  static filterSerializer(filter) {
    try {
      return JSON.stringify(filter);
    } catch {}
    return '{}';
  }

  static filterDeserializer(filterString) {
    try {
      return JSON.parse(filterString);
    } catch {}
    return {};
  }

  static filterTransformer(filter) {
    return omit(filter, ['sourceId', 'targetId', 'interfaces']);
  }

  @observable sourceId = this.props.filters?.sourceId;
  @observable targetId = this.props.filters?.targetId;

  @action
  setSourceId = (sourceId) => {
    this.sourceId = sourceId;
  };

  @action
  setTargetId = (targetId) => {
    this.targetId = targetId;
  };

  @computed
  get nodeList() {
    const filteredNodes = filter(
      this.context.allActiveNodes,
      this.context.isFreeform ? undefined : 'logical-device'
    );
    let nodesWithLinks = filteredNodes;
    if (!this.context.isFreeform) {
      nodesWithLinks = flatten(transform(filteredNodes, (acc, node) => {
        const links = flatten(getNeighborsPaths({graph: this.graph, sourceId: node.id}));
        if (links.length > 0) {
          acc.push(node);
        }
      }, []));
    }
    return sortBy(map(nodesWithLinks, ({id, label}) => ({
      value: id,
      text: label
    })), 'text');
  }

  @computed
  get paths() {
    const {graph, sourceId, targetId} = this;
    const {isFreeform} = this.context;
    if (!sourceId || !targetId) return null;
    return getGraphBfs({isFreeform})({graph, sourceId, destinationId: targetId});
  }

  @computed.struct
  get filtersData() {
    const {paths, context: {systemIdMap}} = this;
    const pathParts = flattenDeep(paths);
    const data = transform(pathParts, (systems, {dstId, srcId, links}) => {
      if (!systems[dstId]) systems[dstId] = [];
      if (!systems[srcId]) systems[srcId] = [];
      systems[dstId].push(...map(links, 'dst_intf_name'));
      systems[srcId].push(...map(links, 'src_intf_name'));
    }, {});
    return transform(systemIdMap, ({systems, interfaces}, {id}, systemId) => {
      if (!data[id]) return;
      systems.push(systemId);
      interfaces.push(...map(data[id], (intf) => [systemId, intf]));
    }, {systems: [], interfaces: []});
  }

  @computed.struct
  get graph() {
    return buildGraphFromCablingMap({cablingMap: this.context.cablingMap, isFreeform: this.context.isFreeform});
  }

  componentDidMount() {
    this.updateFiltersDisposer = reaction(
      () => [this.filtersData, this.sourceId, this.targetId],
      ([{systems, interfaces}, sourceId, targetId]) => this.props.onChange({
        system_id: systems,
        interfaces,
        sourceId,
        targetId,
      })
    );
  }

  componentWillUnmount() {
    this.updateFiltersDisposer();
  }

  render() {
    const {nodeList, setSourceId, setTargetId, sourceId, targetId} = this;
    return (
      <Container>
        <TrafficSearchBoxForm
          sourceList={nodeList}
          targetList={nodeList}
          onSourceChange={setSourceId}
          onTargetChange={setTargetId}
          sourceId={sourceId}
          targetId={targetId}
        />
      </Container>
    );
  }
}

export const TrafficSearchBoxForm = ({
  sourceList, targetList,
  onSourceChange, onTargetChange,
  sourceId, targetId,
  hideSource, hideTarget
}) => {
  const exchangeFn = useCallback(() => {
    if (find(sourceList, {value: targetId}) && find(targetList, {value: sourceId})) {
      onSourceChange(targetId);
      onTargetChange(sourceId);
    }
  }, [onSourceChange, onTargetChange, sourceId, sourceList, targetId, targetList]);

  return (
    <Form>
      <Form.Group>
        {!hideSource &&
          <Form.Field width={4}>
            <DropdownControl
              fluid
              placeholder='Source'
              search
              options={sourceList}
              onChange={onSourceChange}
              value={sourceId}
            />
          </Form.Field>
        }
        {!hideTarget && !hideSource && <Button basic icon='exchange' onClick={exchangeFn} />}
        {!hideTarget &&
          <Form.Field width={4}>
            <DropdownControl
              fluid
              placeholder='Destination'
              search
              options={targetList}
              onChange={onTargetChange}
              value={targetId}
            />
          </Form.Field>
        }
      </Form.Group>
    </Form>
  );
};

export function requestInterfacesTraffic({blueprintId, filters, probe, routes, signal}) {
  return request(
    interpolateRoute(routes.probeStage, {blueprintId, probeId: probe.id}),
    {signal, method: 'POST', body: JSON.stringify({
      stage: TRAFFIC_PROBE_STAGE.averageInterfaceCounters,
      filter: filtersToQueryParam({'[system_id, interface]': filters.filter.interfaces})
    })}
  );
}
