import {Modal} from 'semantic-ui-react';
import {DataFilteringContainer, FetchData} from 'apstra-ui-common';

import QueryCatalog, {searchBoxSchema} from './QueryCatalog';
import {fetchPredefinedQuery} from './utils';
import userStore from '../userStore';

const QueryCatalogModal = ({
  trigger, open, closeModal, onSaveEditableQuery, onQueryChange, onExecuteQuery, onCancelEditMode,
  userStoreKey = 'queryCatalog', onDeleteQuery,
}) => {
  const defaultPageSize = userStore.getStoreValue([userStoreKey, 'pageSize']);
  return (
    <Modal
      open={open}
      trigger={trigger}
      size='fullscreen'
      closeIcon
      onClose={() => closeModal()}
      className='catalog-modal'
    >
      <Modal.Header>{'Predefined Query Catalog'}</Modal.Header>
      <Modal.Content>
        <DataFilteringContainer
          defaultPageSize={defaultPageSize}
          setUserStoreProps={userStore.setStoreValueFn(userStoreKey)}
        >
          {({activePage, pageSize, updatePagination, filters, updateFilters}) =>
            <FetchData
              fetchData={fetchPredefinedQuery}
              pollingInterval={null}
              fetchParams={{activePage, pageSize, filters, schema: searchBoxSchema}}
              customLoader
            >
              {({items: queries, total_count: totalCount, loaderVisible, fetchDataError}) =>
                <QueryCatalog
                  queries={queries}
                  loaderVisible={loaderVisible}
                  fetchDataError={fetchDataError}
                  totalCount={totalCount}
                  activePage={activePage}
                  pageSize={pageSize}
                  filters={filters}
                  updatePagination={updatePagination}
                  updateFilters={updateFilters}
                  onExecute={(query) => {
                    onCancelEditMode(query);
                    onExecuteQuery();
                    closeModal();
                  }}
                  onSaveEditableQuery={({query, id, label}) => {
                    onSaveEditableQuery({id, label});
                    onQueryChange(query);
                    closeModal();
                  }}
                  onDeleteQuery={({id, label}) => onDeleteQuery({id, label})}
                />
              }
            </FetchData>
          }
        </DataFilteringContainer>
      </Modal.Content>
    </Modal>
  );
};

export default QueryCatalogModal;
