import {memo} from 'react';
import cx from 'classnames';

import PythonExpressionParser from '../PythonExpressionParser';
import PythonExpressionJSXFormatter from '../PythonExpressionJSXFormatter';

import './FormattedPythonExpression.less';

const FormattedPythonExpression = memo((props) => {
  let {value = ''} = props;
  const {multiLine = false} = props;
  const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(value);
  if (!lexErrors.length && !parseErrors.length) {
    value = PythonExpressionJSXFormatter.run(cst, {multiLine});
  }
  return <span className={cx('python-expression', {multiline: multiLine})}>{value}</span>;
});

export default FormattedPythonExpression;
