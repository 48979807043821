import {filter} from 'lodash';

import {Layout} from '../types';
import {TopologyConfigType} from '../consts';

export abstract class LayoutAlgorithm {
   // if true, go to update children
  public abstract recalculateOwn(own: Layout, children: Layout[], config: TopologyConfigType): boolean;

  // if false, can stop traversing to children
  public abstract recalculateChildren(own: Layout, children: Layout[], config: TopologyConfigType): boolean;

  assignValue = (target, value) => !!filter(value, (v, field) => {
    if (target[field] !== v) {
      target[field] = v;
      return true;
    }
  }).length;
}
