import {Button, Popup} from 'semantic-ui-react';

const QueryDeleteButton = ({content = 'Delete', onClick}) => (
  <Popup
    trigger={
      <Button
        size='tiny'
        icon='trash'
        onClick={onClick}
      />
    }
    content={content}
  />
);

export default QueryDeleteButton;
