import {FC} from 'react';
import cx from 'classnames';
import {uniqueId} from 'lodash';

import './CollapsedGenerics.less';

const COLLAPSED_GENERICS_PATTERN_ID = uniqueId('collapsed-generics-pattern');

export type CollapsedGenericsProps = {
  rx?: number;
  baseWidth?: number;
  baseHeight?: number;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  label?: string;
  className?: string;
  testDataAttributes?: any;
};

export const CollapsedGenericsPattern = ({size = 20}) => (
  <defs>
    <pattern
      id={COLLAPSED_GENERICS_PATTERN_ID}
      patternUnits='userSpaceOnUse'
      width={size}
      height={size}
      patternTransform='rotate(45 0 0)'
    >
      <line
        className='collapsed-generics-fill-pattern-line'
        x1={0}
        y1={0}
        x2={0}
        y2={size}
        strokeWidth={size}
      />
    </pattern>
  </defs>
);

export const CollapsedGenerics: FC<CollapsedGenericsProps> = ({
  baseWidth = 80, baseHeight = 30, rx = 4, testDataAttributes = {},
  width, height, top = 0, left = 0, label, className
}) => {
  const rectWidth = width ?? baseWidth;
  const rectHeight = height ?? baseHeight;

  return (
    <g
      className={cx('topology-collapsed-generics', className)}
      transform={`translate(${left},${top})`}
      {...testDataAttributes}
    >
      <rect
        width={rectWidth}
        height={rectHeight}
        rx={rx}
      />
      <rect
        fill={`url(#${COLLAPSED_GENERICS_PATTERN_ID})`}
        width={rectWidth}
        height={rectHeight}
        rx={rx}
      />
      {label && (
        <foreignObject x={0} y={0} width={rectWidth} height={rectHeight}>
          <div className='text'>
            {label}
          </div>
        </foreignObject>
      )}
    </g>
  );
};
