import {isNil, sortBy, uniqBy, find, findIndex, min, get, forEach, map, chain, isEmpty,
  concat, some, times, flatten, filter} from 'lodash';
import {natsort} from 'apstra-ui-common';

import {LOCAL_NODE_ROLES, NODE_ROLES} from '../../../roles';
import {
  DEFAULT_STATE, STATE, STATUS, DEFAULT_STATUS, TELEMETRY_STATUS, STATUS_MAPPING, STATE_MAPPING
} from './constants';

export function getNodeLocalRole(node) {
  if (node?.role === NODE_ROLES.GENERIC && node?.external) {
    return LOCAL_NODE_ROLES.EXTERNAL_GENERIC;
  }
  return node?.role;
}

export function getNeighborsDataFromCablingMap(params) {
  const {
    isFreeform,
    cablingMap,
    aggregateLinks,
    nodeId,
    neighborsRoleFilter,
    nodes,
    interfaceTelemetry,
    interfaceMap,
    lldpTelemetry,
    labelKey = 'label',
    showUnusedPorts,
    showAggregateLinks,
    interfacesWithCts,
    deviceProfile,
  } = params;

  const data = {
    node: null,
    neighbors: [],
    links: [],
    neighborsRoles: [],
    aggregateLinks: []
  };

  const node = find(nodes, {id: nodeId});

  if (!Array.isArray(cablingMap) || !node) {
    return data;
  }

  data.node = {...getNodeProps({node, labelKey, nodes}), interfaces: []};

  forEach(cablingMap, (link) => {
    const params = {data, nodes, neighborsRoleFilter, labelKey, link};

    if (link.endpoints[0].id === nodeId) {
      addNeighbor({
        node: link.endpoints[0],
        neighbor: link.endpoints[1],
        ...params,
      });
    } else if (link.endpoints[1].id === nodeId) {
      addNeighbor({
        node: link.endpoints[1],
        neighbor: link.endpoints[0],
        ...params,
      });
    }
  });

  if (showUnusedPorts) {
    const hasPortsWithoutLabels = some(data.node.interfaces, ({label}) => !label);
    if (interfaceMap) {
      const interfaceMapPorts = uniqBy(map(
        concat(interfaceMap.unusedInterfaces || [], interfaceMap.mappedInterfaces || []),
        ({name, mapping}) => ({label: name, mapping: mapping})
      ), 'label');

      if (hasPortsWithoutLabels) {
        const addPortsCount = interfaceMapPorts.length - data.node.interfaces.length;
        const unusedPorts = times(addPortsCount, () => ({label: null}));
        data.node.interfaces.push(...unusedPorts);
      } else {
        data.node.interfaces.push(...interfaceMapPorts);
        data.node.interfaces = uniqBy(data.node.interfaces, 'label');
      }
    } else if (deviceProfile) {
      const interfaceMapPorts = map(
        flatten(map(deviceProfile.ports, 'interfaces')),
        (port) => ({...port, label: port.name})
      );

      if (hasPortsWithoutLabels) {
        const addPortsCount = interfaceMapPorts.length - data.node.interfaces.length;
        const unusedPorts = times(addPortsCount, () => ({label: null}));
        data.node.interfaces.push(...unusedPorts);
      } else {
        data.node.interfaces.push(...interfaceMapPorts);
        data.node.interfaces = uniqBy(data.node.interfaces, 'label');
      }
    }
  }

  setInterfaceStatuses({data, interfaceTelemetry, lldpTelemetry});

  addAggregateLinks(data, aggregateLinks, interfacesWithCts, isFreeform);
  addInterfaceCTs(data, interfacesWithCts);

  sortNeighborsData(data, showAggregateLinks);

  return data;
}

function findOtherIf(links, ifId) {
  const ifLink = find(links, (link) => link.neighbor_interface_id === ifId || link.node_interface_id === ifId);
  if (!isNil(ifLink)) {
    const isNode = ifLink.node_interface_id === ifId;
    const otherIf = isNode ? ifLink.neighbor_interface_id : ifLink.node_interface_id;
    const otherIfRole = isNode ? ifLink.neighbor_role : ifLink.node_role;
    return {id: otherIf, role: otherIfRole};
  } else {
    return null;
  }
}

function addInterfaceCTs(data, interfacesWithCts) {
  forEach([data.node, ...data.neighbors], (node) => {
    forEach(node.interfaces, (intf) => {
      // if node is generic than try to assign CTs from the other end of the link
      const otherIf = findOtherIf(data.links, intf.id);
      if (node.role === 'generic' && otherIf) {
        intf.CT = find(interfacesWithCts, (_, key) => key === otherIf.id);
      } else {
        intf.CT = find(interfacesWithCts, (_, key) => key === intf.id);
      }
    });
  });
}

function setInterfaceStatuses({data, interfaceTelemetry, lldpTelemetry}) {
  const {node, neighbors, links, nodes} = data;

  node.interfaces.forEach((iface) => {
    const telemetry = find(interfaceTelemetry,
      (telemetry) => get(telemetry, ['identity', 'interface_name']) === iface.label
    );
    iface.state = (!telemetry || STATE_MAPPING[telemetry.actual.value]) || DEFAULT_STATE;
    iface.status = (!telemetry || STATUS_MAPPING[telemetry.status]) || DEFAULT_STATUS;
  });

  forEach(lldpTelemetry, (telemetry) => {
    const {identity} = telemetry;
    const nodeInterface = find(node.interfaces, {label: identity.interface_name});
    if (!nodeInterface) {
      return;
    }
    const expectedLink = getNeighborLink({nodes, neighbors, telemetry, key: 'expected', node}) || {};
    const actualLink = getNeighborLink({nodes, neighbors, telemetry, key: 'actual', node}) || {};
    const link = find(links, {node_interface_id: nodeInterface.id});
    if (!link) {
      return;
    }
    const neighbor = find(neighbors, {id: link.neighbor_id});
    if (!neighbor) {
      return;
    }
    const neighborInterface = find(get(neighbor, ['interfaces']), {id: link.neighbor_interface_id});

    const validExpected = expectedLink.neighbor_id === neighbor.id &&
      expectedLink.neighbor_interface_id === neighborInterface.id;

    if (validExpected) {
      neighborInterface.state = nodeInterface.state ?? STATE.UP;
      neighborInterface.status = telemetry.status === TELEMETRY_STATUS.OK ?
        STATUS.SUCCESS : (nodeInterface.status ?? TELEMETRY_STATUS.UNINTENDED);
    }

    switch (telemetry.status) {
    case TELEMETRY_STATUS.OK:
      Object.assign(link, {...expectedLink, state: STATE.UP, status: STATUS.SUCCESS}); break;
    case TELEMETRY_STATUS.MISSING:
      Object.assign(link, {...expectedLink, state: STATE.DOWN, status: STATUS.ERROR}); break;
    case TELEMETRY_STATUS.MISMATCH:
      data.links.push(Object.assign({}, link, {...expectedLink, state: STATE.DOWN, status: STATUS.ERROR}));
      Object.assign(link, {...actualLink, state: STATE.UP, status: STATUS.ERROR}); break;
    case TELEMETRY_STATUS.UNINTENDED:
      Object.assign(link, {...actualLink, state: STATE.NOT_AVAILABLE, status: STATUS.NOT_AVAILABLE}); break;
    }
  });
}

function getNeighborLink({nodes, neighbors, telemetry, key, node}) {
  const {identity} = telemetry;
  const result = telemetry[key];
  if (!result) {
    return null;
  }
  const hostname = result.neighbor_system_id;
  const isMismatch = telemetry.status === TELEMETRY_STATUS.MISMATCH;
  let neighbor = find(neighbors, {hostname});
  if (!neighbor) {
    if (telemetry.actual && isMismatch) {
      const neighborSystemId = telemetry.actual.neighbor_system_id;
      const existingMissingNeighbor = find(neighbors, {id: neighborSystemId});
      const hasInNodes = find(nodes, {id: neighborSystemId});
      if (hasInNodes && !existingMissingNeighbor) { // was already filtered out
        return null;
      }
      const missingNeighbor = existingMissingNeighbor || {
        id: neighborSystemId,
        label: neighborSystemId,
        notInCablingMap: true,
        interfaces: []
      };
      if (!existingMissingNeighbor) {
        neighbors.push(missingNeighbor);
      }
      neighbor = missingNeighbor;
    } else {
      return null;
    }
  }
  const neighborInterfaceName = get(telemetry, [key, 'neighbor_interface_name']);
  let neighborInterface = find(neighbor.interfaces, {label: neighborInterfaceName});
  if (!neighborInterface) {
    if (isMismatch) {
      const missingNeighborInterface = {id: neighborInterfaceName, label: neighborInterfaceName};
      neighbor.interfaces.push(missingNeighborInterface);
      neighborInterface = missingNeighborInterface;
    } else {
      return null;
    }
  }

  const nodeInterface = find(node.interfaces, {label: identity.interface_name});

  return {
    node_interface_id: nodeInterface.id,
    node_role: node.role,
    neighbor_id: neighbor.id,
    neighbor_interface_id: neighborInterface.id,
    neighbor_role: neighbor.role
  };
}

function addNeighbor({data, nodes, neighborsRoleFilter, labelKey, link, node, neighbor}) {
  const neighborNode = find(nodes, {id: neighbor.id});
  const neighborLocalRole = getNodeLocalRole(neighborNode);
  if (!data.neighborsRoles.includes(neighborLocalRole)) {
    data.neighborsRoles.push(neighborLocalRole);
  }

  data.node.interfaces.push(getInterfaceProps(node));

  const shouldDisplayNeighbor = !neighborsRoleFilter || neighborLocalRole === neighborsRoleFilter;
  if (shouldDisplayNeighbor) {
    const existingNeighbor = data.neighbors.find((n) => n.id === neighbor.id);
    if (!existingNeighbor) {
      data.neighbors.push(getNodeProps({node: neighbor, labelKey, nodes}));
    } else {
      existingNeighbor.interfaces.push(getInterfaceProps(neighbor));
    }

    data.links.push({
      id: link.id,
      label: link.label,
      node_interface_id: node.if_id,
      neighbor_id: neighbor.id,
      neighbor_interface_id: neighbor.if_id,
      aggregate_link_id: link.aggregate_link_id,
      sourceLink: link,
    });
  }
}
function findAggregateLinkInterface(link) {
  const intf = chain(link.endpoints)
    .map((e) => e.interface)
    .find('port_channel_id')
    .get(['id'])
    .value();
  const intf2 = chain(link.endpoints)
    .find(['system', null])
    .get(['interface', 'id'])
    .value();
  return intf || intf2;
}

function addAggregateLinks(data, aggregateLinksData, interfacesWithCts, isFreeform) {
  data.aggregateLinks = chain(data.links)
    .filter(({aggregate_link_id: id}) => !!id)
    .groupBy(({aggregate_link_id: id}) => id)
    .transform((result, links, aggregateLinkId) => {
      const linkIds = links.map(({id}) => id);
      const aggregateLinkData = aggregateLinksData?.[aggregateLinkId];

      const ifId = (aggregateLinksData && interfacesWithCts) ?
        findAggregateLinkInterface(aggregateLinkData) : null;
      const lagMode = get(aggregateLinkData, ['endpoints', '0', 'interface', 'lag_mode']) ??
        get(links, ['0', 'sourceLink', 'endpoints', '0', 'lag_mode']);
      const portChannelId = get(aggregateLinkData, ['endpoints', '0', 'interface', 'port_channel_id']) ??
        get(links, ['0', 'sourceLink', 'endpoints', '0', 'port_channel_id']);
      // FIXME(vkramskikh): proper fix for CLOS design is required
      const interfaceName = isFreeform ?
        get(aggregateLinkData, ['endpoints', '0', 'interface', 'if_name']) ??
        get(links, ['0', 'sourceLink', 'endpoints', '0', 'if_name'])
      : null;

      const isGeneric = data.node.role === NODE_ROLES.GENERIC;

      const interfaceTags = isGeneric ? [] :
        get(aggregateLinkData, ['endpoints', '0', 'interface', 'tags']) ??
        get(links, ['0', 'sourceLink', 'endpoints', '0', 'tags']);

      result.push({
        id: aggregateLinkId,
        linkIds,
        ifId,
        lagMode,
        portChannelId,
        interfaceName,
        interfaceTags,
        ct: ifId ? map(interfacesWithCts[ifId], 'label') || [] : [],
        tags: aggregateLinkData?.tags,
      });
    }, [])
    .sort((link1, link2) => natsort(link1.id, link2.id))
    .value();
}

function sortByUsedInterfaces(interfaces) {
  const filterFn = function(isUsed) {
    return function({id}) {
      return isUsed ? !!id : !id;
    };
  };
  const interfacesWithLinks = filter(interfaces, filterFn(true));
  const unusedInterfaces = filter(interfaces, filterFn(false));
  return concat(interfacesWithLinks, unusedInterfaces);
}

function sortNeighborsData(data, showAggregateLinks) {
  data.node.interfaces.sort((iface1, iface2) => natsort(iface1.label, iface2.label));

  if (showAggregateLinks && !isEmpty(data.aggregateLinks)) {
    data.links = sortBy(data.links,
      [
        (link) => {
          const lagIndex = findIndex(data.aggregateLinks, {id: link.aggregate_link_id});
          return lagIndex === -1 ? Number.POSITIVE_INFINITY : lagIndex;
        },
        (link) => findIndex(data.node.interfaces, {id: link.node_interface_id})
      ]
    );

    data.node.interfaces = sortBy(data.node.interfaces, ({id}) => {
      const index = findIndex(data.links, {node_interface_id: id});
      return index === -1
        ? data.links.length
        : index;
    });
  } else {
    data.node.interfaces = sortByUsedInterfaces(data.node.interfaces);
    data.links = sortBy(data.links, (link) => findIndex(data.node.interfaces, {id: link.node_interface_id}));
  }

  const getNeighborInterfaceIndex = ((neighborInterface) =>
    findIndex(data.links, {neighbor_interface_id: neighborInterface.id}));

  data.neighbors = sortBy(data.neighbors, (neighbor) => {
    neighbor.interfaces = sortBy(neighbor.interfaces, getNeighborInterfaceIndex);
    return min(neighbor.interfaces.map(getNeighborInterfaceIndex));
  });
}

function getInterfaceProps(node) {
  return {
    id: node.if_id,
    label: node.if_name,
    operation_state: node.operation_state,
    ipv4_addr: node.ipv4_addr,
    ipv6_addr: node.ipv6_addr,
    ipv6_enabled: node.ipv6_enabled,
    tags: node.tags
  };
}

function getNodeProps({node, labelKey, nodes}) {
  const nodeAdditionalInfo = find(nodes, {id: node.id});
  const label = node[labelKey] || get(nodeAdditionalInfo, [labelKey]);

  return {
    id: node.id,
    label: isNil(label) ? '' : label,
    system_id: get(nodeAdditionalInfo, ['system_id']),
    hostname: get(nodeAdditionalInfo, ['hostname']),
    role: node.role,
    interfaces: [getInterfaceProps(node)],
    sourceNode: nodeAdditionalInfo,
  };
}
