import {Component, Fragment} from 'react';
import {Icon} from 'semantic-ui-react';
import {StringListDropdownControl} from 'apstra-ui-common';
import {isUndefined} from 'lodash';

export default class TagsControl extends Component {
  static defaultProps = {
    knownTags: [],
    allowNewTags: true,
    placeholder: 'No tags',
  };

  formatItem = (tag) => (
    <Fragment>
      <Icon name='tag' />
      {tag}
    </Fragment>
  );

  render() {
    const {value, onChange, placeholder, knownTags, allowNewTags, open, loading, ...props} = this.props;
    return (
      <StringListDropdownControl
        {...props}
        sort
        {...!isUndefined(open) && {open}}
        placeholder={placeholder}
        allowAdditions={allowNewTags}
        additionLabel={allowNewTags ? 'Add tag ' : undefined}
        noResultsMessage={
          loading
            ? 'Loading tags...'
            : allowNewTags
              ? 'Start typing to add a new tag'
              : 'No tags found'
        }
        knownItems={knownTags}
        formatItem={this.formatItem}
        value={value}
        loading={loading}
        onChange={onChange}
      />
    );
  }
}
