import {useMemo} from 'react';
import {observer} from 'mobx-react';
import cx from 'classnames';

import {useLinkTooltip, useTooltip} from '../../components/graphs/GraphTooltips';
import {useTooltipData} from './TooltipDataProvider';
import {getLinkPath} from '../utils';

import './Link.less';

const Link = ({startPoint, endPoint, link, readonly, onClick}) => {
  const {sharedTooltip} = useTooltip();
  const {indexedLinks} = useTooltipData();
  const linkTooltipHandler = useLinkTooltip();

  const [fromNode, toNode] = [startPoint.node, endPoint.node];

  const className = cx(
    'ctrl-link',
    {
      disabled: readonly,
      interactive: !!onClick,
      error: !link.isValid
    }
  );

  const start = startPoint.portPosition;
  const end = endPoint.portPosition;

  const path = useMemo(
    () => (fromNode && toNode) ? getLinkPath(start, end) : null,
    [start, end, fromNode, toNode]
  );

  const mouseEventsHandlers = useMemo(
    () => {
      return readonly ?
        {
          onMouseOver: linkTooltipHandler(indexedLinks[link.id]),
          onMouseOut: sharedTooltip.hide
        } :
        {
          ...{onClick: onClick ? () => onClick(link) : undefined}
        };
    },
    [indexedLinks, link, linkTooltipHandler, onClick, readonly, sharedTooltip.hide]
  );

  // Sometimes links for newly created systems arrive prior to systems themselves,
  // that's why these potentially can be empty at some point.
  // Link rendering must be postponed then until that happens
  return !!path && <path d={path} {...mouseEventsHandlers} className={className} />;
};

export default observer(Link);
