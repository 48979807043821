import {Base64} from 'js-base64';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {NetworkError, interpolateRoute, request} from 'apstra-ui-common';
import {
  castArray, cloneDeep, filter, find, findIndex,
  first, forEach, groupBy, includes,
  isEqual, isMatch, isPlainObject, keys,
  map, mapValues, noop, pick, pullAllWith, remove, some,
  startsWith,
  transform, upperFirst, values, zipObject,
} from 'lodash';
import {
  observable,
  observe,
  computed,
  action,
  autorun,
  reaction,
  toJS,
  makeObservable,
  runInAction,
} from 'mobx';
import {useContext, useEffect, useMemo, useState} from 'react';
import {Location, NavigateFunction, Params, useLocation, useNavigate, useParams} from 'react-router-dom';
import {ModalProps} from 'semantic-ui-react';

import IBAContext, {IBAContextValue} from '../../IBAContext';
import PythonExpressionPlainTextFormatter from '../../../pythonExpression/PythonExpressionPlainTextFormatter';
import generateProbeURI from '../../generateProbeURI';
import humanizeString from '../../../humanizeString';
import {getStageFormSchema, getProcessorByStageName, getInputStageNames} from '../../stageUtils';
import {Probe, ProbeProcessorType, ProbeStageType, ProcessorDefinition, ProcessorValueType} from '../../types';
import {ProbeDetailsProps} from './ProbeDetails';
import {ProbeError, ProbeHighlights} from './types';

type UseProbeDetailsStoreArgs = ProbeDetailsProps & {
  scrollToForm: () => void;
}

type ProbeDetailsStoreProps = UseProbeDetailsStoreArgs & {
  location: Location;
  navigate: NavigateFunction;
  params: Readonly<Params<string>>;
};

const transformToObjectType = (serviceItems) => {
  const serviceItemsValueType = serviceItems?.application_schema?.properties?.value?.type;
  if (!serviceItemsValueType || serviceItemsValueType === 'object') {
    return serviceItems;
  }
  const result = cloneDeep(serviceItems);
  result.application_schema.properties.value = {
    type: 'object',
    properties: {
      value: {
        type: serviceItemsValueType
      }
    },
  };
  return result;
};

export class ProbeDetailsStore {
  @observable.struct props: ProbeDetailsStoreProps;
  @observable.struct context: IBAContextValue;
  @observable currentProbe: Probe | null = null;
  @observable currentProcessorName: string | null = null;
  @observable currentStageName: string | null = null;
  @observable currentActionInProgress: string | null = null;
  @observable.shallow errors: ProbeError[] = [];
  @observable.ref highlightConnectionsFor: [string, string] | null = null;
  @observable highlightedInputStageName: string | null = null;
  @observable isProbeDirty: boolean = false;
  @observable probeNavigationExpanded: boolean = false;
  @observable.ref probeDeletionModalProps: ModalProps = {};
  @observable.ref probeExportModalProps: ModalProps = {};
  @observable.ref predefinedProbeModalProps: ModalProps = {};

  private disposeClearGenericErrorsObserver = noop;
  private disposeErrorReaction = noop;
  private disposeLocationReaction = noop;
  private disposeDirtyChecker = noop;

  constructor(context: IBAContextValue, props: ProbeDetailsStoreProps) {
    this.context = context;
    this.props = props;
    this.currentProbe = !this.props.probeId ? {
      label: '',
      description: '',
      disabled: false,
      processors: [],
      stages: []
    } : this.props.location.state?.probe ?? {
      label: this.props.action === 'clone' ? `${this.props.probe.label} copy` : this.props.probe.label,
      description: this.props.probe.description,
      disabled: this.props.probe.disabled,
      processors: this.props.probe.processors,
      stages: map(
        this.props.probe.stages,
        (stage) => pick(
          stage,
          'name',
          'type',
          'keys',
          'warnings',
          'description',
          'units',
          'enable_metric_logging',
          'retention_duration',
          'values',
          'graph_annotation_properties',
          'dynamic',
        )
      ),
    };
    this.probeNavigationExpanded = this.props.location.state?.probeNavigationExpanded ?? false;

    makeObservable(this);
    this.disposeClearGenericErrorsObserver = observe(this.probe.processors, this.clearGenericErrors);
    this.disposeLocationReaction = reaction(
      () => this.props.location,
      () => this.setCurrentEntitiesFromPathParams()
    );
    this.disposeErrorReaction = reaction(
      () => this.probe.last_error,
      () => this.checkDetailedError()
    );
  }

  @action
  public toggleProbeNavigationExpanded = () => {
    this.probeNavigationExpanded = !this.probeNavigationExpanded;
  };

  @action
  public highlightStage = (stageName: string, highlight = true) => {
    this.highlightedInputStageName = highlight ? stageName : null;
  };

  @action
  public highlightProcessorAndRelatedStages = (processorName: string) => {
    this.highlightConnectionsFor = ['processor', processorName];
  };

  @action
  public highlightStageAndRelatedProcessors = (stageName: string) => {
    this.highlightConnectionsFor = ['stage', stageName];
  };

  @action
  public highlightCurrentEntity = () => {
    this.highlightConnectionsFor = null;
  };

  private getRelatedHighlightsForProcessor(processorName: string): ProbeHighlights {
    const processor = find(this.probe.processors, {name: processorName})!;
    return {
      processors: {[processorName]: {highlightConnections: true}},
      stages: transform(processor?.inputs,
        (result, {stage: stageName}) => {
          result[stageName!] = {};
        },
        {}
      )
    };
  }

  private getRelatedHighlightsForStage(stageName: string) {
    const connectedProcessors = filter(
      this.probe.processors,
      (processor) => includes(getInputStageNames(processor), stageName)
    );
    return {
      processors: transform(
        connectedProcessors,
        (result, processor) => {
          result[processor.name] = {};
        },
        {}
      ),
      stages: {[stageName]: {highlightConnections: true}}
    };
  }

  @computed
  public get highlights() {
    const highlights = {processors: {}, stages: {}};
    if (this.highlightConnectionsFor) {
      const [entity, entityName] = this.highlightConnectionsFor;
      if (entity === 'processor') {
        Object.assign(highlights, this.getRelatedHighlightsForProcessor(entityName));
      } else if (entity === 'stage') {
        Object.assign(highlights, this.getRelatedHighlightsForStage(entityName));
      }
    } else {
      const {currentProcessorName, currentStageName} = this;
      if (currentProcessorName) {
        Object.assign(highlights, this.getRelatedHighlightsForProcessor(currentProcessorName));
      } else if (currentStageName) {
        Object.assign(highlights, this.getRelatedHighlightsForStage(currentStageName));
      }
    }
    if (this.highlightedInputStageName) {
      highlights.stages[this.highlightedInputStageName] = {veryHighlighted: true};
    }
    return highlights;
  }

  @computed
  public get processorDefinitionsByName(): Record<string, ProcessorDefinition> {
    function buildProcessorOutputsFromServiceValues(
      serviceValues?: {properties: any},
      valueMap?: Record<string, Record<number, string>>
    ) {
      function valueToDefinition(value: any, name: string) {
        const result = {
          name,
          title: humanizeString(name),
          type: value.type === 'integer' ? 's64' : value.type,
        };
        if (valueMap?.[name]) {
          return [{
            ...result,
            type: 'string',
            possible_values: values(valueMap?.[name])
          }];
        }
        return [result];
      }

      return mapValues(serviceValues?.properties, valueToDefinition);
    }

    const {telemetryServiceRegistryItems} = this.props;
    return transform(this.probe.processors, (acc, processor) => {
      let processorDefinition = find(this.context.processorDefinitions, {name: processor.type});
      if (processorDefinition && processor.type === 'extensible_data_collector') {
        const serviceName = processor.properties.service_name;
        const serviceItems = transformToObjectType(find(telemetryServiceRegistryItems, {service_name: serviceName}));
        const serviceValues = serviceItems?.application_schema.properties.value;
        const outputs = buildProcessorOutputsFromServiceValues(serviceValues, processor.properties.value_map);
        processorDefinition = cloneDeep(processorDefinition);
        const isDynamicProcessor = processor.properties.data_type === 'dynamic';
        processorDefinition.outputs.out.types = transform(processorDefinition.outputs.out.types,
          (acc, outputType) => {
            if (outputType.dynamic !== isDynamicProcessor) return;
            outputType.values = outputs;
            acc.push(outputType);
          },
          [] as ProcessorValueType[]
        );
      }
      acc[processor.name] = processorDefinition;
    }, {});
  }

  private processErrors({errors}): ProbeError[] {
    const result: ProbeError[] = [];
    function addError(messages: string | string[], generateError: (message: string) => ProbeError) {
      result.push(...castArray(messages).map((message) => generateError(message)));
    }
    if (!isPlainObject(errors)) {
      return result;
    }
    if ('processors' in errors) {
      if (isPlainObject(errors.processors)) {
        forEach(errors.processors, (processorErrors, processorIndex) => {
          const processor = this.probe.processors[Number(processorIndex)];
          if (processor) {
            if (isPlainObject(processorErrors)) {
              forEach({inputs: 'input', properties: 'property'}, (errorType, errorGroup) => {
                if (errorGroup in processorErrors) {
                  if (isPlainObject(processorErrors[errorGroup])) {
                    forEach(processorErrors[errorGroup], (groupErrors, propertyName) => {
                      addError(groupErrors, (message) => ({
                        type: 'processor' + upperFirst(errorType),
                        [errorType + 'Name']: propertyName,
                        processorName: processor.name,
                        message
                      }));
                    });
                  } else {
                    addError(processorErrors[errorGroup], (message) => ({
                      type: 'processor' + upperFirst(errorType),
                      [errorType + 'Name']: null,
                      processorName: processor.name,
                      message,
                    }));
                    addError(processorErrors[errorGroup], (message) => (
                      {type: 'processor', processorName: processor.name, message}
                    ));
                  }
                }
              });
              if (processorErrors.name) {
                addError(processorErrors.name, (message) => (
                  {type: 'processorName', processorName: processor.name, message}
                ));
              }
            } else {
              addError(processorErrors, (message) => (
                {type: 'processor', processorName: processor.name, message}
              ));
            }
          }
        });
      } else {
        addError(errors.processors, (message) => ({type: 'processors', message}));
      }
    }
    forEach(errors.stages, (stageErrors, stageIndex) => {
      const stage = this.probe.stages[Number(stageIndex)];
      if (stage) {
        const processor = find(
          this.probe.processors,
          (processor) => includes(processor.outputs, stage.name)
        );
        if (processor) {
          forEach(stageErrors, (propertyErrors, propertyName) => {
            addError(propertyErrors, (message) => (
              {type: 'stageProperty', stageName: stage.name, processorName: processor.name, propertyName, message}
            ));
          });
        }
      }
    });
    for (const propertyName of ['label', 'description']) {
      if (propertyName in errors) {
        addError(errors[propertyName], (message) => ({type: 'probeProperty', propertyName, message}));
      }
    }
    return result;
  }

  private formatGraphQueries(probe) {
    return {...probe, processors: map(probe.processors, (processor) => {
      if ('graph_query' in processor.properties) {
        const originalProcessor = this.props.action === 'update' ?
          find(this.props.probe.processors, {name: processor.name}) :
          null;
        if (
          this.props.action !== 'update' || !originalProcessor ||
          originalProcessor && 'graph_query' in originalProcessor.properties &&
          !isEqual(processor.properties.graph_query, originalProcessor.properties.graph_query)
        ) {
          const graphQuery = map(castArray(processor.properties.graph_query), (graphQuery) =>
            PythonExpressionPlainTextFormatter.parseAndFormat(graphQuery)
          );
          processor = toJS(processor);
          processor.properties.graph_query = graphQuery;
        }
      }
      return processor;
    })};
  }

  private getNormalizedProbeGraph = (probe) => ({
    processors: probe.processors,
    stages: map(probe.stages, (stage) => {
      const processor = getProcessorByStageName({probe, stageName: stage.name});
      const processorDefinition = find(this.context.processorDefinitions, {name: processor.type});
      const schema = getStageFormSchema(processorDefinition);
      const fields = ['name', ...map(schema, 'name')];
      return pick(stage, fields);
    })
  });

  private probeToRequestBody() {
    return {...toJS(this.probe), ...this.formatGraphQueries(this.getNormalizedProbeGraph(this.probe))};
  }

  private probeToPatchRequestBody() {
    return pick(toJS(this.probe), 'label', 'description', 'disabled');
  }

  private removeBackendErrors() {
    remove(this.errors, ({isClientValidationError}) => !isClientValidationError);
  }

  @action
  public submit = async ({action = 'create', redirect = true} = {}) => {
    const {blueprintId, routes} = this.context;
    const {navigate, probeId} = this.props;
    const {isProbeDirty} = this;
    this.currentActionInProgress = action;
    this.removeBackendErrors();
    const route = action !== 'update' ?
      interpolateRoute(routes.probeList, {blueprintId})
    :
      interpolateRoute(routes.probeDetails, {blueprintId, probeId});
    const method = action !== 'update' ? 'POST' : isProbeDirty ? 'PUT' : 'PATCH';
    const body = JSON.stringify(method === 'PATCH' ? this.probeToPatchRequestBody() : this.probeToRequestBody());
    try {
      const {id: newProbeId} = await request(route, {method, body});
      if (redirect) {
        navigate(generateProbeURI({
          blueprintId,
          probeId: newProbeId ?? probeId,
          processorName: this.currentProcessorName!,
          stageName: this.currentStageName!,
        }));
      }
    } catch (error: NetworkError) {
      runInAction(() => {
        this.currentActionInProgress = null;
        const {response, responseBody} = error;
        if (response && response.status === 422 && responseBody) {
          this.errors.push(...this.processErrors(responseBody));
          const nameOfProcessorWithError = find(this.errors, ({processorName}) => !!processorName)?.processorName;
          const nameOfStageWithError = find(this.errors, ({stageName}) => !!stageName)?.stageName;
          if (nameOfProcessorWithError) {
            this.setCurrentProcessorName(nameOfProcessorWithError);
            this.props.scrollContext?.scrollToElement(
              'processor details',
              nameOfProcessorWithError
            );
          } else if (nameOfStageWithError) {
            this.setCurrentStageName(nameOfStageWithError);
            this.props.scrollContext?.scrollToElement(
              'stage details',
              nameOfStageWithError
            );
          } else {
            this.props.scrollToForm();
          }
        } else {
          this.errors.push({type: 'http', error});
        }
      });
    }
  };

  @computed
  public get probe(): Probe {
    return this.props.action ? this.currentProbe! : observable(this.props.probe);
  }

  @computed
  public get isProbeValid(): boolean {
    return some(this.probe.processors) && !some(this.errors, 'isClientValidationError');
  }

  @computed
  public get processorsErrors(): ProbeError[] {
    return filter(this.errors, {type: 'processors'});
  }

  @computed
  public get propertyErrorMessages() {
    return mapValues(
      groupBy(filter(this.errors, {type: 'probeProperty'}), 'propertyName'),
      (errors) => map(errors, 'message')
    );
  }

  @computed
  public get httpError(): ProbeError | undefined {
    return find(this.errors, {type: 'http'});
  }

  private getMatchedRouteParameters = (routePattern: string, path: string) => {
    const paramNames: string[] = [];
    const matcher = new RegExp(
      routePattern.replace(/:[^/]+/gi, (name) => {
        paramNames.push(name.substr(1));
        return '([^/]+)';
      })
    );
    const matches = path.match(matcher);
    if (matches) {
      return zipObject(paramNames, matches.slice(1));
    }
  };

  @computed
  private get matchedPathParams(): {processorName?: string | null, stageName?: string | null} {
    const {pathname} = this.props.location;
    return mapValues(
      {
        processorName: '/processors/:processorName',
        stageName: '/stages/:stageName'
      },
      (pathSuffix, key) => {
        const match = this.getMatchedRouteParameters(pathSuffix, pathname);
        if (match) {
          try {
            return Base64.decode(match[key]);
          } catch {
            return null;
          }
        }
      }
    );
  }

  @computed
  public get currentEntities() {
    const {currentProcessorName, currentStageName, probe} = this;
    let processor: ProbeProcessorType | null = null;
    let stage: ProbeStageType | null = null;
    if (currentProcessorName) {
      processor = find(probe.processors, {name: currentProcessorName})!;
    } else if (currentStageName) {
      stage = find(probe.stages, {name: currentStageName})!;
      processor = find(
        probe.processors,
        (processor) => includes(processor.outputs, currentStageName)
      )!;
    }
    return {processor, stage};
  }

  @computed
  private get defaultProcessorName(): string | undefined | null {
    return first(this.probe.processors)?.name ?? null;
  }

  @computed
  private get defaultStageName() {
    const firstProcessor = first(this.probe.processors);
    if (!firstProcessor) return null;
    const processorDefinition = find(this.context.processorDefinitions, {name: firstProcessor.type});
    const firstOutputName = first(keys(processorDefinition?.outputs));
    return firstOutputName ? firstProcessor.outputs[firstOutputName] : null;
  }

  @computed
  public get probeHasUnsupportedProcessorTypes() {
    const {processorDefinitions} = this.context;
    const processors = new Set(map(processorDefinitions, 'name'));
    return some(this.probe.processors, ({type}) => !processors.has(type));
  }

  @action
  public setCurrentProcessorName = (processorName: string | null) => {
    this.onCurrentEntitiesChange({processorName});
  };

  @action
  public setCurrentStageName = (stageName: string | null) => {
    this.onCurrentEntitiesChange({stageName});
  };

  @action
  private onCurrentEntitiesChange = ({stageName, processorName, replaceHistory}: {
    stageName?: string | null,
    processorName?: string | null,
    replaceHistory?: boolean
  }) => {
    this.setCurrentEntitiesNames({stageName, processorName});
    if (this.props.probeId) {
      this.redirectToProbe({stageName, processorName, replaceHistory});
    }
  };

  @action
  private setCurrentEntitiesNames({stageName, processorName}) {
    this.currentStageName = stageName;
    this.currentProcessorName = processorName;
  }

  @action
  private setCurrentEntitiesFromPathParams = (): boolean => {
    const {
      matchedPathParams: {processorName, stageName}
    } = this;

    if (
      processorName && some(this.probe.processors, {name: processorName}) ||
      stageName && some(this.probe.stages, {name: stageName})
    ) {
      // if one of provided names is valid - select the corresponding entity
      this.setCurrentEntitiesNames({stageName, processorName});
      return true;
    }
    return false;
  };

  @action
  private setDefaultEntitiesAsCurrent = () => {
    this.setCurrentEntitiesNames(
      this.props.action ?
      {
        processorName: this.defaultProcessorName,
        stageName: null,
      } :
      {
        processorName: null,
        stageName: this.defaultStageName,
      }
    );
  };

  @computed
  public get probeProperties() {
    return {...this.probe, enabled: !this.probe.disabled};
  }

  @computed
  public get predefinedDashboards() {
    return filter(this.props.probe.referencing_dashboards, 'predefined_dashboard');
  }

  @action
  public setProbePropertyValue = (propertyName, value) => {
    if (propertyName === 'enabled') {
      this.probe.disabled = !value;
    } else {
      this.probe[propertyName] = value;
    }
    pullAllWith(this.errors, [{type: 'probeProperty', propertyName}], isMatch);
  };

  @action
  private clearGenericErrors = () => {
    pullAllWith(this.errors, [{type: 'processors'}, {type: 'http'}], isMatch);
  };

  @action
  private markProbeAsDirty = (isDirty: boolean) => {
    this.isProbeDirty = isDirty;
  };

  @action
  public revertProbeGraph = () => {
    Object.assign(this.probe, this.getNormalizedProbeGraph(this.props.probe));
    this.markProbeAsDirty(false);
    this.redirectToProbe({
      processorName: this.defaultProcessorName!,
      replaceHistory: true
    });
  };

  private checkProbeGraphModification = () => {
    if (
      this.props.action === 'update' &&
      !this.isProbeDirty &&
      !isEqual(this.getNormalizedProbeGraph(this.probe), this.getNormalizedProbeGraph(this.props.probe))
    ) {
      this.markProbeAsDirty(true);
    }
  };

  @action
  public setProbeDeletionModalProps = (props: ModalProps) => {
    this.probeDeletionModalProps = props;
  };

  @action
  public setProbeExportModalProps = (props: ModalProps) => {
    this.probeExportModalProps = props;
  };

  @action
  public setPredefinedProbeModalProps = (props: ModalProps) => {
    this.predefinedProbeModalProps = props;
  };

  private redirectToProbe = ({
    blueprintId = this.context.blueprintId,
    probeId = this.props.probeId,
    processorName,
    stageName,
    action = this.props.action,
    replaceHistory = false
  }: {
    blueprintId?: string;
    probeId?: string;
    processorName?: string | null;
    stageName?: string | null;
    action?: ProbeDetailsProps['action'];
    replaceHistory?: boolean;
  }) => {
    const url = generateProbeURI({
      blueprintId,
      probeId,
      processorName,
      stageName,
      action
    });
    this.props.navigate(
      url,
      {
        state: {
          probe: cloneDeep(this.probe),
          probeNavigationExpanded: this.probeNavigationExpanded,
        },
        replace: replaceHistory
      }
    );
  };

  private checkDetailedError(): void {
    this.removeBackendErrors();
    if (this.probe.last_error) {
      const {last_error: {detailed_error: detailedErrors, message, processor}} = this.probe;
      const processorIndex = findIndex(this.probe.processors, ({name}) => name === processor);
      if (processorIndex >= 0 && message) {
        this.errors.push(...this.processErrors({
          errors: {
            processors: {[processorIndex]: detailedErrors}
          }
        }));
      }
      if (detailedErrors) {
        this.errors.push(...this.processErrors({errors: detailedErrors}));
      }
    }
  }

  public checkProcessorHasErrors = (processorName: string): boolean => {
    return some(
      this.errors,
      (error) => error.processorName === processorName &&
        startsWith(error.type, 'processor')
    );
  };

  public checkStageHasErrors = (stageName: string): boolean => {
    return some(
      this.errors,
      (error) => error.type === 'stageProperty' && error.stageName === stageName
    );
  };

  public componentDidMount = (): void => {
    if (!this.setCurrentEntitiesFromPathParams()) {
      this.setDefaultEntitiesAsCurrent();
    }
    this.checkDetailedError();
    this.disposeDirtyChecker = autorun(this.checkProbeGraphModification);
  };

  public componentWillUnmount = (): void => {
    this.disposeClearGenericErrorsObserver();
    this.disposeLocationReaction();
    this.disposeDirtyChecker();
    this.disposeErrorReaction();
  };

  @action
  public update = (context, props): void => {
    this.context = context;
    this.props = props;
  };
}

export const useProbeDetailsStore = (props: UseProbeDetailsStoreArgs) => {
  const context = useContext(IBAContext);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const storeProps = useMemo(
    () => ({...props, location, navigate, params}),
    [location, navigate, params, props]
  );
  const [store] = useState(() => new ProbeDetailsStore(context, storeProps));
  useEffect(
    () => {
      store.componentDidMount();
      return store.componentWillUnmount;
    }, [store]
  );
  useEffect(
    () => store.update(context, storeProps),
    [context, store, storeProps]
  );
  return store;
};
