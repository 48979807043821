import {Icon} from 'semantic-ui-react';

import {IN, NODE, OUT} from '../pythonExpression/consts';

import {ReactComponent as In} from '../../styles/icons/query-builder/in.svg';
import {ReactComponent as Out} from '../../styles/icons/query-builder/out.svg';
import {ReactComponent as Having} from '../../styles/icons/query-builder/having.svg';
import {ReactComponent as EnsureDifferent} from '../../styles/icons/query-builder/ensure_different.svg';
import {ReactComponent as Match} from '../../styles/icons/query-builder/match.svg';
import {ReactComponent as Optional} from '../../styles/icons/query-builder/optional.svg';
import {ReactComponent as Where} from '../../styles/icons/query-builder/where.svg';

import './BrickIcon.less';

const iconsByBrickName = {
  [IN]: In,
  [OUT]: Out,
  having: Having,
  ensure_different: EnsureDifferent,
  match: Match,
  optional: Optional,
  where: Where,
  [NODE]: () => <Icon name='share alternate' />,
  distinct: () => <Icon name='sun' />,
};

const BrickIcon = ({name}) => {
  const IconComponent = iconsByBrickName[name];
  if (!IconComponent) return null;
  return <IconComponent className='brick-icon' />;
};

export default BrickIcon;
