import cx from 'classnames';
import {useCallback, useRef, useState} from 'react';
import {Icon, Popup} from 'semantic-ui-react';

import GraphExplorerNewWindow from './GraphExplorerNewWindow';

import './GraphExplorerNewWindowButton.less';

const GraphExplorerNewWindowButton = ({
  className,
  floated,
  explorerProps,
  saveQuery,
  defaultQuery
}) => {
  const [explorerWindowOpen, setExplorerWindowOpen] = useState(false);
  const explorerWindow = useRef(null);

  const setExplorerWindow = useCallback((newExplorerWindow) => {
    explorerWindow.current = newExplorerWindow;
  }, []);

  const openExplorerWindow = useCallback(() => {
    setExplorerWindowOpen(true);
    explorerWindow.current?.focus();
  }, []);

  const closeExplorerWindow = useCallback(() => {
    setExplorerWindowOpen(false);
    explorerWindow.current = null;
  }, []);

  return (
    <>
      <Popup
        trigger={
          <Icon
            className={cx('graph-explorer-new-window-button', {floated}, className)}
            name='external'
            link
            onClick={openExplorerWindow}
            aria-label='Open in Graph Explorer'
            role='button'
          />
        }
        content='Open in Graph Explorer'
        position='left center'
      />
      {explorerWindowOpen &&
        <GraphExplorerNewWindow
          onOpen={setExplorerWindow}
          onClose={closeExplorerWindow}
          defaultQuery={defaultQuery}
          saveQuery={saveQuery}
          explorerProps={explorerProps}
        />
      }
    </>
  );
};

export default GraphExplorerNewWindowButton;
