import {Fragment} from 'react';
import {LinePath} from '@visx/shape';
import {curveLinear} from '@visx/curve';
import {map, isUndefined, isNull, forEach, last, first, includes} from 'lodash';
import moment from 'moment';
import {
  CHART_VALUE_CIRCLE_RADIUS, CHART_ELEMENTS_STROKE_WIDTH, NumericChartLayout,
} from 'apstra-ui-common';

import ChartHoverHandler from './ChartHoverHandler';

import './StateLineChart.less';

const defaultDimensions = {
  compact: {
    height: 60 + (CHART_VALUE_CIRCLE_RADIUS + CHART_ELEMENTS_STROKE_WIDTH) * 2,
    margin: {
      top: 4 + CHART_VALUE_CIRCLE_RADIUS + CHART_ELEMENTS_STROKE_WIDTH,
      right: 5,
      bottom: 5 + CHART_VALUE_CIRCLE_RADIUS + CHART_ELEMENTS_STROKE_WIDTH,
      left: 5,
    },
    numTicksRows: 4,
  },
  expanded: {
    height: 300,
    margin: {top: 10, right: 5, bottom: 30, left: 5},
    numTicksRows: 10,
  },
};

const START_OUT_OF_RANGE_MSG = 'The real start date might be out of the selected range';
const END_OUT_OF_RANGE_MSG = 'The real end date is out of the selected range';

const StateLineChart = ({
  samples = [],
  sampleTimes = [],
  mode = 'compact',
  dimensions = defaultDimensions,
  color = 'red',
  maxSamplesDisplayCircles = 150,
  valueKeyName = 'value',
  extraDataKey = 'data',
  periodStartKey = 'detected_at',
  generatePopupContentFn,
  minSpaceBetweenCircles = 0,
  chartStart, chartEnd, popupProps,
  useTimestampZoom = false,
  showTimelineWithMilliseconds = false,
  width: chartWidth, showPopup, hidePopup, timelineStartTime, timelineEndTime, numTicksColumns,
}) => {
  const isValueCirclesLayerVisible = !minSpaceBetweenCircles ||
    (samples.length < chartWidth / minSpaceBetweenCircles && samples.length < maxSamplesDisplayCircles);

  const hasArtificialStart = !isUndefined(chartStart) && moment(first(sampleTimes)).isSame(chartStart);
  const hasArtificialEnd = !isUndefined(chartEnd) && moment(last(sampleTimes)).isSame(chartEnd);

  return (
    <NumericChartLayout
      className='state-line-chart'
      mode={mode}
      dimensions={dimensions}
      minValue={0}
      maxValue={0}
      width={chartWidth}
      showPopup={showPopup}
      hidePopup={hidePopup}
      timelineStartTime={timelineStartTime}
      timelineEndTime={timelineEndTime}
      numTicksColumns={numTicksColumns}
      useTimestampZoom={useTimestampZoom}
      showTimelineWithMilliseconds={showTimelineWithMilliseconds}
    >
      {({xScale, yScale, yScaleMin, yMax, xMax}) => {
        const lines = [];
        const chartSamples = [];
        const hoverHandlerTimes = [];
        const standaloneSamplesIds = [];
        if (chartWidth > 0) {
          let timePeriodStartDate = null;
          let samePeriodSamples = [];
          let hasFirstRaisedSample = false;
          forEach(samples, (sample, sampleIndex) => {
            sample.color = color;
            if (isUndefined(sample[extraDataKey])) {
              sample[extraDataKey] = {};
            }

            const prevSample = samples[sampleIndex - 1];

            if (!prevSample || isNull(prevSample[valueKeyName]) || !prevSample[valueKeyName]) {
              if (sample[valueKeyName]) {
                // [1], [... -> 1] or [0 -> 1] ==> the start of a time period
                samePeriodSamples = [sample];
                timePeriodStartDate = sample[periodStartKey]
                  ? new Date(sample[periodStartKey])
                  : sampleTimes[sampleIndex];

                if (sampleIndex === (hasArtificialStart ? 1 : 0)) {
                  hasFirstRaisedSample = true;
                }
              } else {
                // [0], [... -> 0] or [0 -> 0]
                // ==> first, artificial or standalone sample w/o issue been raised
                standaloneSamplesIds.push(sampleIndex);
              }
            } else if (prevSample && !!prevSample[valueKeyName]) {
              if (!isNull(sample[valueKeyName]) && !sample[valueKeyName]) {
                // [1 -> 0] ==> the end of a time period
                samePeriodSamples.push(sample);

                forEach(samePeriodSamples, (samePeriodSample) => {
                  samePeriodSample[extraDataKey]._periodStart = !sample[periodStartKey] && hasFirstRaisedSample
                      ? START_OUT_OF_RANGE_MSG
                      : timePeriodStartDate;
                  samePeriodSample[extraDataKey]._periodEnd = sampleTimes[sampleIndex];
                });

                lines.push([
                  [
                    xScale(
                      !moment(timePeriodStartDate).isSameOrAfter(timelineStartTime) ||
                        (!sample[periodStartKey] && hasFirstRaisedSample)
                          ? timelineStartTime
                          : timePeriodStartDate
                    ),
                    yScale(0)
                  ],
                  [xScale(sampleTimes[sampleIndex]), yScale(0)]
                ]);
                hasFirstRaisedSample = false;
              } else {
                // [1 -> 1] ==> another raised issue sample in same time period
                samePeriodSamples.push(sample);

                // [1 -> 1] ==> current sample is a last one and the chart doesn't have an artificial "end" sample
                // [1 -> ...] ==> or it is an artificial "chart end" sample itself,
                // it imitates a true sample for cases when the real last known sample
                // is a raised issue, and we need to draw the line till the end of the axis
                if (isNull(sample[valueKeyName]) || (!hasArtificialEnd && sampleIndex === samples.length - 1)) {
                  forEach(samePeriodSamples, (samePeriodSample) => {
                    samePeriodSample[extraDataKey]._periodStart = !sample[periodStartKey] && hasFirstRaisedSample
                        ? START_OUT_OF_RANGE_MSG
                        : timePeriodStartDate;
                    samePeriodSample[extraDataKey]._periodEnd = hasArtificialEnd
                      ? END_OUT_OF_RANGE_MSG
                      : sampleTimes[sampleIndex];
                  });

                  lines.push([
                    [
                      xScale(
                        !moment(timePeriodStartDate).isSameOrAfter(timelineStartTime) ||
                          (!sample[periodStartKey] && hasFirstRaisedSample)
                            ? timelineStartTime
                            : timePeriodStartDate
                      ),
                      yScale(0)
                    ],
                    [xScale(sampleTimes[sampleIndex]), yScale(0)]
                  ]);
                  hasFirstRaisedSample = false;
                }
              }
            }
          });

          forEach([...samples], (sample, id) => {
            // remove first sample for ChartHoverHandler and circles
            if (id === 0 && hasArtificialStart) {
              return;
            }
            // remove last sample for ChartHoverHandler and circles
            if (id === samples.length - 1 && hasArtificialEnd) {
              return;
            }
            if (sample[valueKeyName] || !sample[valueKeyName] && !includes(standaloneSamplesIds, id)) {
              chartSamples.push(sample);
              hoverHandlerTimes.push(sampleTimes[id]);
            }
          });
        }

        return (
          <Fragment>
            {lines.length > 0 && map(
              lines,
              (linePathData, id) => (
                <LinePath
                  key={`line-normal-${id}`}
                  className={`state-line-chart-line graph-color-${color}`}
                  data={linePathData}
                  curve={curveLinear}
                />
              )
            )}

            {isValueCirclesLayerVisible && chartSamples.length > 0 && map(
              chartSamples,
              (_, id) => (
                <circle
                  key={`value-circle-${id}`}
                  className={`state-line-chart-value-circle graph-color-${color}`}
                  cx={xScale(hoverHandlerTimes[id])}
                  cy={yScale(0)}
                  r={CHART_VALUE_CIRCLE_RADIUS}
                />
              )
            )}

            <ChartHoverHandler
              samples={chartSamples}
              sampleTimes={hoverHandlerTimes}
              width={Math.abs(xMax)}
              height={yMax}
              yScale={yScale}
              yScaleMin={yScaleMin}
              xScale={xScale}
              valueKeyName={valueKeyName}
              formatValue={() => null}
              showPopup={showPopup}
              hidePopup={hidePopup}
              popupProps={popupProps}
              generatePopupContentFn={generatePopupContentFn}
            />
          </Fragment>
        );
      }}
    </NumericChartLayout>
  );
};

export default StateLineChart;
