import {Layout} from '../types';
import {LayoutAlgorithm} from './LayoutAlgorithm';

export const VLAN_HEIGHT = 10;

export class VlanBusLayout extends LayoutAlgorithm {
  recalculateOwn = (own: Layout) => {
    if (own.height < VLAN_HEIGHT) {
      own.height = VLAN_HEIGHT;
      return true;
    }
    return false;
  };

  recalculateChildren = () => false;
}
