import {useCallback, useMemo} from 'react';
import {Button, Message, Modal, Popup} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {compact, countBy, every, filter, first, flatten, forEach, groupBy, isEmpty, keys,
  map, max, min, size, some, sortBy, transform, uniq} from 'lodash';

import Linker from './Linker';
import {useRackEditorStore} from '../hooks/useRackEditorStore';
import {useTooltip} from '../../components/graphs/GraphTooltips';
import {REDUNDANCY, NODE_ROLES, PORT_ROLES} from '../const';
import NodeProperties from './NodeProperties';
import Port from '../store/Port';
import ButtonAlert from './ButtonAlert';
import HoverableButton from './HoverableButton';
import Description from './Description';

import './Actions.less';

const Actions = ({devices, onClose}) => {
  const {rackStore, selection} = useRackEditorStore();
  const {id, optionsAreShown, isSaving, error} = rackStore;
  const {
    showActionTooltip, isLinkerOpened, nothingSelected, portsSpeeds, selectedNodes, linkNodes,
    createNode, canManageLinks, canClone, freePorts, cloningWarning, linkingWarning, isValid,
    newLinkRestriction, managedLinkId, hidePopups
  } = useActions(devices);

  return (
    <Modal.Actions onMouseOut={showActionTooltip()} onBlur={showActionTooltip()}>
      {error &&
        <Message
          error
          className='rt-global-error'
          content={error}
          onDismiss={() => rackStore.setError()}
        />}
      <div className='rt-actions'>
        <div className='extra-actions'>
          <Button.Group size='large' primary>
            <Button
              icon='leaf'
              aria-label='Add leaf'
              onClick={() => createNode(NODE_ROLES.LEAF)}
              onMouseOver={showActionTooltip('Add Leaf')}
              onMouseOut={showActionTooltip()}
            />
            <Button
              icon='exchange'
              aria-label='Add access switch'
              onClick={() => createNode(NODE_ROLES.ACCESS_SWITCH)}
              onMouseOver={showActionTooltip('Add Access Switch')}
              onMouseOut={showActionTooltip()}
            />
            <Button
              icon='server'
              aria-label='Add generic'
              onClick={() => createNode(NODE_ROLES.GENERIC)}
              onMouseOver={showActionTooltip('Add Generic')}
              onMouseOut={showActionTooltip()}
            />
          </Button.Group>
          <Button.Group size='large' primary>
            <Popup
              inverted={false}
              basic
              hoverable
              on={[]}
              position='top center'
              open={canManageLinks && isLinkerOpened}
              size='tiny'
              pinned
              trigger={
                <HoverableButton
                  icon='linkify'
                  disabled={!canManageLinks}
                  aria-label='Manage links'
                  onClick={() => {
                    hidePopups(false, true);
                    rackStore.toggleLinker(managedLinkId, selection);
                  }}
                  onMouseOver={showActionTooltip('Manage links')}
                  onMouseOut={showActionTooltip()}
                />
              }
            >
              <Linker
                portsSpeeds={portsSpeeds}
                selectedNodes={linkNodes}
                freePorts={freePorts}
                newLinkRestriction={newLinkRestriction}
              />
            </Popup>
            <ButtonAlert message={linkingWarning} shiftLeft />
            <ButtonAlert message={cloningWarning} />
            <HoverableButton
              icon='clone'
              aria-label='Clone selected'
              disabled={!canClone}
              onClick={() => {
                hidePopups(true, true);
                selection.select(...rackStore.cloneSelected(selection.selectedNodesIds));
              }}
              onMouseOver={showActionTooltip('Clone selected')}
              onMouseOut={showActionTooltip()}
            />
            <Popup
              inverted={false}
              basic
              on={[]}
              position='top center'
              open={optionsAreShown && !nothingSelected}
              size='tiny'
              trigger={
                <HoverableButton
                  active={optionsAreShown}
                  aria-label='Manage properties of selected node(s)'
                  icon='wrench'
                  disabled={nothingSelected}
                  onClick={() => {
                    hidePopups(true);
                    rackStore.toggleOptions();
                  }}
                  onMouseOver={showActionTooltip('Manage properties of selected node(s)')}
                  onMouseOut={showActionTooltip()}
                />
              }
            >
              <NodeProperties nodes={selectedNodes} />
            </Popup>
            <HoverableButton
              icon='trash'
              aria-label='Delete selected nodes'
              disabled={nothingSelected}
              onClick={() => {
                const nodes = [...selection.selectedNodesIds];
                selection.clear();
                rackStore.deleteNodes(nodes);
                hidePopups(true, true);
              }}
              onMouseOver={showActionTooltip('Delete selected nodes')}
              onMouseOut={showActionTooltip()}
            />
          </Button.Group>
          <Button.Group size='large' primary>
            <HoverableButton
              icon='undo'
              aria-label='Undo'
              disabled={!rackStore.changes.canUndo}
              onClick={() => {
                hidePopups(true, true);
                selection.select(...rackStore.undo());
              }}
              onMouseOver={showActionTooltip('Undo')}
              onMouseOut={showActionTooltip()}
            />
            <HoverableButton
              icon='redo'
              aria-label='Redo'
              disabled={!rackStore.changes.canRedo}
              onClick={() => {
                hidePopups(true, true);
                selection.select(...rackStore.redo());
              }}
              onMouseOver={showActionTooltip('Redo')}
              onMouseOut={showActionTooltip()}
            />
          </Button.Group>
          <Button.Group size='large' primary>
            <Popup
              on={['click']}
              inverted={false}
              trigger={
                <HoverableButton
                  icon='file alternate'
                  aria-label='Generate summary'
                  onMouseOver={showActionTooltip('Generate summary')}
                  onMouseOut={showActionTooltip()}
                />
              }
            >
              <Description />
            </Popup>
          </Button.Group>
        </div>
        <div>
          <Button
            size='large'
            primary
            content={id ? 'Update' : 'Create'}
            aria-label='Save changes'
            onClick={() => rackStore.submit(onClose)}
            disabled={!isValid || isSaving}
            loading={isSaving}
          />
        </div>
      </div>
    </Modal.Actions>
  );
};

const useActions = (devices) => {
  const {rackStore, selection} = useRackEditorStore();
  const {selectedNodesIds} = selection;
  const {nodes, linksGroups, nodesByName, isLinkerOpened, isValid} = rackStore;
  const {sharedTooltip} = useTooltip();

  const selectionLength = selectedNodesIds?.length ?? 0;
  const nothingSelected = selectionLength === 0;
  const twoSelected = selectionLength === 2;
  const selectionIndex = selectedNodesIds.join(':');

  const selectedNodes = useMemo(
    () => compact(map(selectedNodesIds, (nodeId) => nodes[nodeId])),
    [selectedNodesIds, nodes]
  );

  const namesInvolved = useMemo(() => uniq(map(selectedNodes, 'name')), [selectedNodes]);

  // Show/hide action button tooltip
  const showActionTooltip = useCallback((title) => (e) => {
    if (title) {
      sharedTooltip.show(title);
    } else {
      sharedTooltip.hide();
    }
    e.stopPropagation();
  }, [sharedTooltip]);

  // Devices options for DDL (must be divided into roles-based groups)
  const deviceOptions = useMemo(() => map(devices, ({id, display_name: text}) => ({
    text,
    value: id
  })), [devices]);

  const hidePopups = useCallback(
    (hideLinker, hideNodeProperties) => {
      showActionTooltip();
      rackStore.editNode();
      if (hideLinker) rackStore.toggleLinker();
      if (hideNodeProperties) rackStore.toggleOptions(true);
    },
    [showActionTooltip, rackStore]
  );

  // Adding new node of the given role to the canvas
  const createNode = useCallback((role) => {
    hidePopups(true, true);
    const newNode = rackStore.addNode(role);
    selection.select(newNode.id);
    rackStore.calculateZonesSizes();
  }, [rackStore, selection, hidePopups]);

  const nodesLinksGroups = useMemo(
    () => filter(linksGroups, (linksGroup) => linksGroup.containsNames(namesInvolved, true)),
    [linksGroups, namesInvolved]
  );

  // Identifies whether selection cloning is possible
  const [canClone, cloningWarning] = useMemo(
    () => {
      if (nothingSelected) return [false, null];

      const portsRequired = transform(linksGroups, (acc, linksGroup) => {
        const {fromName, toName, speed, isPeer, fromRole, toRole} = linksGroup;
        const containsFrom = namesInvolved.includes(fromName);
        const containsTo = namesInvolved.includes(toName);

        // Identify links that go from selected subset to outside
        const [outsideName, insideRole] = containsFrom ? (
          containsTo ? [null, null] : [toName, fromRole]
        ) : (containsTo ? [fromName, toRole] : [null, null]);

        // Peer links do not take part in cloning
        if (outsideName && !isPeer) {
          const nodesLinksPorts = linksGroup.getSizeOnNodes(nodesByName);
          forEach(nodesByName[outsideName], ({id}) => {
            if (!acc[id]) acc[id] = [];
            acc[id].push(...Port.generate(nodesLinksPorts[id], speed, insideRole));
          });
        }
      }, {});

      let error = '';
      // Cloning is possible if all involved nodes have enough of free ports of needed speeds
      return [
        every(portsRequired, (portsNeeded, nodeId) => {
          const node = nodes?.[nodeId];
          const result = node?.canConnectPorts(portsNeeded);
          if (!result) {
            error = <><b>{node?.label}</b>{' does not have enough free ports to clone selected nodes'}</>;
          }
          return result;
        }),
        error
      ];
    },
    [selectionIndex] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // True if selected nodes are peers
  const arePeers = twoSelected && selectedNodes[0]?.isPairedWith(selectedNodes[1]?.id);

  // Links can be managed between pairs thus more than two nodes might identify a link
  const selectedNames = useMemo(
    () => keys(groupBy(selectedNodes, 'name')),
    [selectedNodes]
  );

  const twoNamesSelected = size(selectedNames) === 2;

  const twoPairs = useMemo(
    () => (twoNamesSelected && every(selectedNodes, {isPaired: true})),
    [twoNamesSelected, selectedNodes]
  );

  // Link direction matters. It always come like generic->switch, access->leaf
  const [sourceNode, destinationNode] = useMemo(
    () => {
      if (arePeers) {
        return [selectedNodes[0], selectedNodes[1]];
      } else if (size(selectedNames) !== 2) {
        return [null, null];
      } else {
        return sortBy(map(selectedNames, (name) => first(nodesByName[name])), 'roleIndex');
      }
    },
    [arePeers, nodesByName, selectedNames, selectedNodes]
  );

  // It is forbidden to create links between non-peer nodes of the same role
  const sameRole = !arePeers && sourceNode && sourceNode.role === destinationNode?.role;

  // Calculate amounts of free ports of desired role on each of the nodes involved
  const freePorts = useMemo(
    () => {
      return sourceNode &&
        transform(
          [sourceNode, destinationNode],
          (acc, node, index) => {
            const role = arePeers ? PORT_ROLES.PEER : (index ? sourceNode.role : destinationNode.role);
            acc.push(
              map(
                node.nodesInvolved,
                ({availablePorts}) => countBy(
                  filter(availablePorts, (port) => port.matchesRole(role, true)), 'speedString'
                )
              )
            );
          },
          []
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arePeers, sourceNode?.usedPorts, destinationNode?.usedPorts]
  );

  // Calculate ports intersection per speed
  const portsSpeeds = useMemo(
    () => {
      if (!sourceNode || !freePorts || sameRole) return {};

      const isOneToPair = !sourceNode.isPaired && destinationNode.isPaired;
      const isPairToOne = sourceNode.isPaired && !destinationNode.isPaired;

      // Now find ports intersection based on ports speed and possible configuration of links group
      return transform(
        // Iterating through ports speeds first source node has
        keys(freePorts[0][0]),
        (acc, speedString) => {
          const speedPorts = map(
            flatten(freePorts),
            (portsCounts) => portsCounts?.[speedString] ?? 0
          );

          let intersection = 0;
          if (isOneToPair) {
            // For that type of link it is enough that source and ONE of destination nodes
            // to have intersecting ports
            intersection = min([speedPorts[0], max([speedPorts[1], speedPorts[2]])]);
          } else {
            // In other cases all nodes must have free ports available
            const ports = compact(speedPorts);
            const compactSize = size(ports);
            if (
              // When a pair is connected to a single node, both source nodes must have free ports and
              // the destination must have at least two
              (isPairToOne && compactSize === 3 && ports[0] > 1) ||
              // In all other cases all nodes must have intersected amount of ports
              (!isPairToOne && compactSize === size(speedPorts))
            ) {
              intersection = min(ports);
            }
          }

          acc[speedString] = intersection;
        },
        {}
      );
    },
    [freePorts, sameRole, sourceNode, destinationNode]
  );

  const firstNode = first(selectedNodes) ?? {};

  const allEsis = useMemo(
    () => every(selectedNodes, {redundancyProtocol: REDUNDANCY.ESI}),
    [selectedNodes]
  );

  const allGenerics = useMemo(
    () => every(selectedNodes, {role: NODE_ROLES.GENERIC}),
    [selectedNodes]
  );

  const noLinksExist = isEmpty(nodesLinksGroups);

  const enoughPortsForNewLink = useMemo(
    () => some(portsSpeeds, (freeCount) => freeCount > 0),
    [portsSpeeds]
  );

  // Reason why new link creation is not possible
  const newLinkRestriction = useMemo(
    () => {
      if (twoSelected) {
        const roleRestriction = rackStore.getLinkingRoleRestrictionFor(sourceNode, destinationNode);
        if (roleRestriction) {
          return roleRestriction;
        }
      }
      if (!enoughPortsForNewLink) {
        return 'You have used all available links, and can not create more link groups';
      }
    },
    [rackStore, sourceNode, destinationNode, enoughPortsForNewLink, twoSelected]
  );

  // Links can not be managed if:
  let linkingWarning = null;
  let canManageLinks = true;

  // * Not just two names selected
  // * And selected nodes are not peers
  if (!twoNamesSelected && !arePeers) {
    // Disable linking silently
    canManageLinks = false;
  } else if (allGenerics) {
    // * No links between generic systems
    linkingWarning = 'Links cannot be created between generic systems';
    canManageLinks = false;
  } else if (arePeers) {
    // * Selected peers are a ESI leaf-pair
    if (allEsis && firstNode.role === NODE_ROLES.LEAF) {
      linkingWarning = 'ESI leaf pair can not have peer links';
      canManageLinks = false;
    }
  } else {
    if (twoPairs) {
      // * Switch pairs of the different redundancy type (ESI -> MLAG)
      if (!allEsis) {
        linkingWarning = 'Only switch pairs of the same redundancy type linking is allowed';
        canManageLinks = false;
      }
    }
    // The rest of the common checks
    if (canManageLinks && noLinksExist) {
      linkingWarning = newLinkRestriction;
      canManageLinks = !linkingWarning;
    }
  }

  const managedLinkId = (canManageLinks && !newLinkRestriction) ?
    null : first(nodesLinksGroups)?.id;

  return {
    showActionTooltip, deviceOptions, nothingSelected, isLinkerOpened, managedLinkId,
    portsSpeeds, selectedNodes, linkNodes: [sourceNode, destinationNode], createNode, canManageLinks,
    canClone, freePorts, cloningWarning, linkingWarning, arePeers, isValid, newLinkRestriction, hidePopups
  };
};

export default observer(Actions);
