import {computed, observable, makeObservable} from 'mobx';
import {reduce, set, get} from 'lodash';

class PermissionsStore {
  @observable permissions = [];

  @computed
  get access() {
    return reduce(this.permissions, (result, permission) => set(result, permission, true), {});
  }

  get fullAccess() {
    return get(this.access, 'core.blueprints.write') || get(this.access, 'rbac.blueprint.write') || false;
  }

  get vnAccessOnly() {
    return !this.fullAccess &&
      (
        get(this.access, 'two_stage_l3clos.virtual_network_endpoints.write') ||
        get(this.access, 'two_stage_l3clos.virtual_networks.write')
      );
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }
}

export default PermissionsStore;
