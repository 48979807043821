import {observer} from 'mobx-react';
import {min} from 'lodash';

import './Selection.less';

const Selection = ({selection}) => {
  const {
    selectionAreaStart: {x: startX, y: startY},
    selectionAreaEnd: {x: endX, y: endY},
    isSelecting
  } = selection;

  if (!isSelecting) return null;

  return (
    <rect
      className='ctrl-selection'
      x={min([startX, endX])}
      y={min([startY, endY])}
      width={Math.abs(endX - startX)}
      height={Math.abs(endY - startY)}
    />
  );
};

export default observer(Selection);
