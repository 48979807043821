import {ctrlStages, ctrlEntityTypes} from '../const';

const invertedOperations = {
  [ctrlStages.ADD]: ctrlStages.DELETE,
  [ctrlStages.DELETE]: ctrlStages.ADD,
  [ctrlStages.CHANGE]: ctrlStages.CHANGE
};

class Step {
  #operation;
  item;
  previousState;
  id;
  type;

  get isCreate() {
    return this.#operation === ctrlStages.ADD;
  }

  get isUpdate() {
    return this.#operation === ctrlStages.CHANGE;
  }

  get isDelete() {
    return this.#operation === ctrlStages.DELETE;
  }

  get isNode() {
    return this.type === ctrlEntityTypes.NODE;
  }

  get isLink() {
    return this.type === ctrlEntityTypes.LINK;
  }

  get isAggregateLink() {
    return this.type === ctrlEntityTypes.AGGREGATE;
  }

  get isLinksGroup() {
    return this.type === ctrlEntityTypes.LINKS_GROUP;
  }

  constructor(operation, item, previousState) {
    [this.#operation, this.item, this.previousState, this.type, this.id] = [
      operation,
      item?.serialize(),
      previousState?.serialize(),
      item?.type ?? previousState?.type,
      item?.id ?? previousState?.id
    ];
  }

  setResult(item) {
    this.item = item?.serialize();
  }

  get inverted() {
    const step = new Step(invertedOperations[this.#operation]);
    [step.previousState, step.item, step.type, step.id] = [
      this.isUpdate ? this.item : this.previousState,
      this.isUpdate ? this.previousState : this.item,
      this.type,
      this.id
    ];
    return step;
  }

  static creation(item) {
    return new Step(ctrlStages.ADD, item);
  }

  static deletion(item) {
    return new Step(ctrlStages.DELETE, item);
  }

  static modification(item, previousState) {
    return new Step(ctrlStages.CHANGE, item, previousState);
  }
}

export default Step;
