import {forEach, map, max, min, sumBy} from 'lodash';

import {TopologyConfigType} from '../consts';
import {Layout} from '../types';
import {LayoutAlgorithm} from './LayoutAlgorithm';

export class ColumnLayout extends LayoutAlgorithm {
  recalculateOwn = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    if (!children.length) return false;
    const {gap = 0, columns = 3, columnRightPadding} = config;
    const actualColumns = min([children.length, columns])!;

    const columnWidth = max(map(children, 'width'))!;

    const fullHeight = sumBy(children, 'height') + gap * (children.length - actualColumns);
    const columnHeight = fullHeight / (actualColumns ?? 1);
    let height = 0;
    let currentColumnIndex = 0;
    let y = 0;
    let currentFullHeight = 0;

    forEach(children, (child, i) => {
      const childrenLeft = children.length - i - 1;
      if (currentFullHeight >= columnHeight * (currentColumnIndex + 1) ||
        childrenLeft < actualColumns - currentColumnIndex - 1) {
        currentColumnIndex++;
        height = Math.max(height, y - gap);
        y = 0;
        currentFullHeight -= gap;
      }
      y += child.height + gap;
      currentFullHeight += child.height + gap;
    });
    return this.assignValue(own, {
      width: actualColumns * (columnWidth + columnRightPadding) + gap * (actualColumns - 1),
      height: max([height, y - gap]),
    });
  };

  recalculateChildren = (own: Layout, children: Layout[], config: TopologyConfigType) => {
    if (!children.length) return false;
    let wereChildrenUpdated = false;
    const {gap = 0, columns = 3, columnRightPadding} = config;
    const actualColumns = min([children.length, columns])!;

    const columnWidth = (own.width - (actualColumns - 1) * gap - actualColumns * columnRightPadding) / actualColumns;

    const fullHeight = sumBy(children, 'height') + gap * (children.length - actualColumns);
    const columnHeight = fullHeight / (actualColumns ?? 1);
    let currentColumnIndex = 0;
    let y = 0;
    let currentFullHeight = 0;

    forEach(children, (child, i) => {
      const childrenLeft = children.length - i - 1;
      if (currentFullHeight >= columnHeight * (currentColumnIndex + 1) ||
        childrenLeft < actualColumns - currentColumnIndex - 1) {
        currentColumnIndex++;
        y = 0;
        currentFullHeight -= gap;
      }

      if (this.assignValue(child, {width: columnWidth})) {
        wereChildrenUpdated = true;
      }
      this.assignValue(child, {
        left: currentColumnIndex * (columnWidth + gap + columnRightPadding),
        top: y,
      });
      y += child.height + gap;
      currentFullHeight += child.height + gap;
    });

    return wereChildrenUpdated;
  };
}
