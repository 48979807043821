import {useCallback, useMemo, useState} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import cx from 'classnames';
import {transform, map, max as _max, min as _min, omit, head, last, compact, isUndefined, toString, clamp, size, first,
  random, floor} from 'lodash';
import {scaleBand, scaleLinear, scaleTime} from 'd3';
import {BoxPlot} from '@visx/stats';
import {Group} from '@visx/group';
import {GridRows} from '@visx/grid';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {formatNumber, SvgTextLengthMeasurer, formatDateAsLocalDateTime, formatChartAxisTime,
  useAxisWidth} from 'apstra-ui-common';
import moment from 'moment';

import {getFittedTickLabels, makePrintable} from './utils';

import ChartPopup from './ChartPopup';

import './BoxplotChart.less';

export const DATA_FORMAT = {
  none: 'none',
  timestamp: 'timestamp',
};

const MIN_BOX_SIZE = 3;
const HIDDEN_FIELDS = ['x', 'outliers', 'dots', 'points'];
const DOTS_BAR_GAP = 5;

const BoxplotChart = ({
  mode, width: widthProp, dimensions, samples, className,
  gap, yScaleFn, maxBarWidth, x, min, max, median, firstQuartile,
  thirdQuartile, outliers, xLabel, yLabel, units, dots, dotRadius,
  dataXFormat, fontSize, combineDotsWithBars
}) => {
  const {width: parentWidth = widthProp, ref: targetRef} = useResizeDetector({handleHeight: false});
  const chartWidth = isUndefined(widthProp) ? parentWidth : widthProp;
  const samplesCount = size(samples);
  const yLabelWithUnits = compact([yLabel, units]).join(', ');

  const showDots = !isUndefined(dots);

  const [popupDescription, setPopupDescription] = useState(null);
  const hidePopup = () => setPopupDescription(null);

  const {ref: leftAxisRef, width: labelMarginLeft} = useAxisWidth(yLabel, units);
  const {ref: bottomAxisRef, maxLabelWidth: bottomAxisWidth} = useAxisWidth();

  const [textLength, setTextLength] = useState(0);

  const {height: chartHeight, margin} = dimensions[mode];

  const formatIsTimestamp = dataXFormat === DATA_FORMAT.timestamp;
  const formatIsNone = dataXFormat === DATA_FORMAT.none;

  const xMax = (chartWidth - margin.left - margin.right - labelMarginLeft) || 0;
  const yMax = (chartHeight - margin.top - margin.bottom - 25 - (xLabel ? 20 : 0)) || 0;
  const showTicks = chartWidth > 0;
  const xTicks = formatIsTimestamp ? floor(xMax / (2 * (1 + bottomAxisWidth))) : samplesCount;

  const [xScale, groups, domain] = useMemo(() => {
    const groups = map(samples, x);

    let domain = groups;
    if (formatIsTimestamp) {
      // For the timestamp format emulate paddings by adding the offsets to the beginning
      // and the end of the scale
      let [start, end] = [moment(head(groups)), moment(last(groups))];
      // Handling proper date/timestamp format parsing
      if (!start.isValid() || !end.isValid()) {
        [start, end] = [moment().subtract(1, 'd'), moment().add(1, 'd')];
      }
      // Simulate offsets on the time scale
      const timeOffset = moment.duration(end.diff(start)).asMilliseconds() / (samplesCount + 1);
      domain = [
        start.subtract(timeOffset, 'ms').toDate(),
        end.add(timeOffset, 'ms').toDate()
      ];
    }

    const xScaleFn = formatIsNone ?
      scaleBand :
      (formatIsTimestamp ? scaleTime : scaleLinear);

    const xScale = xScaleFn().domain(domain).rangeRound([0, xMax]);
    // Make outer padding and align to the right to fit the dots on the left edge.
    // It is only visible when there are many samples (e.g. > 3)
    if (formatIsNone && !combineDotsWithBars && showDots && samplesCount > 3) {
      xScale.paddingOuter(0.1).align(1);
    }

    return [xScale, groups, domain];
  }, [samples, x, formatIsTimestamp, formatIsNone, xMax, combineDotsWithBars, showDots, samplesCount]);

  const [yScale, minYValue] = useMemo(() => {
    const values = transform(samples, (result, d) => {
      result.push(min(d), max(d));
      result.push(_min(outliers(d)));
      result.push(_max(outliers(d)));
      if (dots?.(d)) {
        result.push(_min(dots(d)));
        result.push(_max(dots(d)));
      }
    }, []);
    const minYValue = _min(values);
    const maxYValue = _max(values);

    return [
      yScaleFn()
        .domain([minYValue, maxYValue])
        .rangeRound([yMax, 0]),
      minYValue
    ];
  }, [samples, yMax, yScaleFn, max, min, outliers, dots]);

  const formatTicksMap = useMemo(() => {
    if (!formatIsNone) return [];
    return getFittedTickLabels({
      textLength, scaleFn: xScale, labels: groups, widthMax: xMax,
    });
  }, [formatIsNone, textLength, xScale, groups, xMax]);

  const formatXTick = (value) => {
    return formatIsTimestamp ? formatChartAxisTime(value) : (formatIsNone ? formatTicksMap[value] : value);
  };

  // Maximal possible size of the single figure
  const bandWidth = xScale.bandwidth ?
    xScale.bandwidth() :
    (samplesCount === 1 ?
      maxBarWidth :
      (xScale(last(domain)) - xScale(first(domain))) / (samplesCount || 1));

  // Calculate visible bar's width + gap size
  const gapDelta = maxBarWidth - bandWidth + gap;
  let visibleBoxWidth = _min([bandWidth, maxBarWidth]) - clamp(gapDelta, 0, gap);
  if (showDots && !combineDotsWithBars) {
    visibleBoxWidth = (visibleBoxWidth - DOTS_BAR_GAP) / 2;
  }
  visibleBoxWidth = clamp(visibleBoxWidth, MIN_BOX_SIZE, maxBarWidth);

  // Calculate left offset for the given figure
  const calculateBoxLeft = useCallback(
    (d) => {
      const result = xScale(x(d)) - visibleBoxWidth / 2 + (formatIsTimestamp ? 0 : bandWidth / 2);
      return (isNaN(result) ? 0 : result);
    },
    [bandWidth, formatIsTimestamp, visibleBoxWidth, x, xScale]
  );

  // Box always stays on the same position only its size changes. But the dots might either be
  // displayed over the bar or aside
  const dotsOffset = (!showDots || combineDotsWithBars) ? 0 : -(visibleBoxWidth + DOTS_BAR_GAP);

  const dotsData = useMemo(() => {
    return transform(samples, (result, d) => {
      const middleX = calculateBoxLeft(d);
      result.push(
        transform(dots?.(d), (acc, value) => {
          const [cx, cy] = [middleX + random(visibleBoxWidth), yScale(value)];
          if (!isNaN(cx) && !isNaN(cy)) acc.push({cx, cy});
        }, [])
      );
    }, []);
  }, [samples, calculateBoxLeft, dots, visibleBoxWidth, yScale]);

  const xAxisPosition = yScale(_max([0, minYValue]));

  const popupEvents = useCallback(
    (d, popupProps) => ({
      onMouseOver: (e) => setPopupDescription({
        node: e.target,
        header: formatIsTimestamp ? formatDateAsLocalDateTime(x(d)) : x(d),
        content: () => <BoxplotPopupContent {...popupProps} />
      }),
      onMouseLeave: hidePopup,
    }),
    [formatIsTimestamp, x]
  );

  return (
    <div
      ref={targetRef}
      className={cx('boxplot-chart', 'graph-container', dataXFormat, {expandable: mode !== 'expanded'})}
    >
      {
        showTicks &&
          <svg className={cx('boxplot-chart-layout', className)} width={chartWidth} height={chartHeight}>
            <Group top={margin.top} left={margin.left + labelMarginLeft}>
              {
                map(samples, (d, index) => {
                  const popupFullData = {items: omit(d, HIDDEN_FIELDS), units, outliers: outliers(d)};
                  return (
                    <g key={`item-${index}`} className='boxplot-points'>
                      {showDots && (
                        <Group className='boxplot-dots' left={dotsOffset}>
                          {map(dotsData[index], ({cx, cy}, dotIndex) => (
                            <circle key={`dot-${dotIndex}`} r={dotRadius} cx={cx} cy={cy} />
                          ))}
                        </Group>
                      )}
                      <BoxPlot
                        className='boxplot-summary'
                        min={min(d)}
                        max={max(d)}
                        left={calculateBoxLeft(d)}
                        firstQuartile={firstQuartile(d)}
                        thirdQuartile={thirdQuartile(d)}
                        median={median(d)}
                        boxWidth={visibleBoxWidth}
                        valueScale={yScale}
                        outliers={outliers(d)}
                        rx={0}
                        ry={0}
                        minProps={popupEvents(
                          d, min(d) === max(d) ? popupFullData : {items: {lower_fence: min(d)}, units}
                        )}
                        maxProps={popupEvents(d, {items: {upper_fence: max(d)}, units})}
                        boxProps={popupEvents(d, popupFullData)}
                      />
                    </g>
                  );
                })
              }
              {!isNaN(xAxisPosition) &&
                <AxisBottom
                  axisClassName='timeline-axis axis-bottom'
                  scale={xScale}
                  top={xAxisPosition}
                  tickFormat={() => null}
                  hideTicks
                />
              }
              <AxisBottom
                innerRef={bottomAxisRef}
                axisClassName='timeline-axis axis-bottom'
                scale={xScale}
                top={yScale(minYValue)}
                label={xLabel}
                labelClassName='axis-label'
                labelProps={{y: 38}}
                tickFormat={formatXTick}
                numTicks={xTicks}
                hideAxisLine
              />
              <AxisLeft
                innerRef={leftAxisRef}
                axisClassName='timeline-axis axis-left'
                scale={yScale}
                label={yLabelWithUnits}
                labelClassName='axis-label'
                labelProps={{y: 10 - labelMarginLeft}}
                tickFormat={
                  (value) => formatNumber(value, {units, short: (value <= -1 || value >= 1), withIndent: true})
                }
              />
              {showTicks && (
                <GridRows
                  className='timeline-grid'
                  width={xMax}
                  height={yMax}
                  scale={yScale}
                  stroke={null}
                />
              )}
            </Group>
            <ChartPopup popupDescription={popupDescription} />
            {formatIsNone && (
              <SvgTextLengthMeasurer
                value={map(groups, toString)}
                style={{fontSize}}
                onMeasure={(value) => setTextLength(value)}
              />
            )}
          </svg>
      }
    </div>
  );
};

BoxplotChart.defaultProps = {
  mode: 'expanded',
  dimensions: {
    compact: {
      height: 150,
      margin: {top: 10, right: 0, bottom: 0, left: 0},
    },
    expanded: {
      height: 400,
      margin: {top: 10, right: 0, bottom: 0, left: 0},
    },
  },
  yScaleFn: scaleLinear,
  gap: 5,
  maxBarWidth: 40,
  x: (d) => d.x,
  min: (d) => d.min,
  max: (d) => d.max,
  median: (d) => d.median,
  firstQuartile: (d) => d.firstQuartile,
  thirdQuartile: (d) => d.thirdQuartile,
  outliers: (d) => d.outliers,
  // dots: (d) => d.dots,
  dotRadius: 2,
  dataXFormat: DATA_FORMAT.none,
  fontSize: 10,
  combineDotsWithBars: false
};

const BoxplotPopupContent = ({items, outliers, units}) => {
  const data = useMemo(() => {
    let filteredItems = {...items};
    const values = map(filteredItems, (value) => value);
    const minOutlier = _min(outliers);
    const maxOutlier = _max(outliers);
    if (maxOutlier > _max(values)) filteredItems = {max: maxOutlier, ...filteredItems};
    if (minOutlier < _min(values)) filteredItems.min = minOutlier;
    return filteredItems;
  }, [items, outliers]);

  return (
    <div className='ui bulleted list'>
      {map(data, (value, key) => (
        <div key={key} className='item'>
          <b>{makePrintable(key)}</b>
          {': '}
          {formatNumber(value, {units, short: true, withIndent: true})}
        </div>
      ))}
    </div>
  );
};

export default BoxplotChart;

