import {FetchData, interpolateRoute, request,} from 'apstra-ui-common';
import {useContext} from 'react';

import IBAContext from '../../IBAContext';

const fetchData = async ({blueprintId, reportName, routes, signal}) => {
  const [reportSchema, {items: probeList}] = await Promise.all([
    request(
      interpolateRoute(routes.predefinedProbeReportRegistry, {blueprintId, reportName}),
      {signal}
    ),
    request(
      interpolateRoute(routes.probeList, {blueprintId}),
      {signal}
    )
  ]);
  return {reportSchema, probeList};
};

const ReportData = ({children, reportName, ...props}) => {
  const {blueprintId, routes} = useContext(IBAContext);

  return (
    <FetchData
      fetchData={fetchData}
      fetchParams={{blueprintId, reportName, routes}}
      pollingInterval={null}
      {...props}
    >
      {children}
    </FetchData>
  );
};

export default ReportData;
