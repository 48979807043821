import {observer} from 'mobx-react';
import {Accordion, Button, Icon, Input, Popup} from 'semantic-ui-react';
import {Field} from 'apstra-ui-common';
import {first, map} from 'lodash';

import TagsControl from '../../components/TagsControl';
import Port from './Port';
import useLinksGroupOptions from '../hooks/useLinksGroupOptions';
import PortsCount from './PortsCount';
import {Checkbox} from '../../exports';
import {useRackEditorStore} from '../hooks/useRackEditorStore';
import {HOMING_TYPES, LAG_MODES, LAG_MODES_NAMES, LAG_MODES_TITLES} from '../const';

import './LinksGroupForm.less';
import NumberInput from './NumberInput';
import Step from '../../cablingMapEditor/store/Step';

const LinksGroupForm = ({linksGroup, freePorts}) => {
  const {
    deleteLinksGroup, usedTags, homingType,
    toggleSwitchLink, toggleHoming,
    maxSizePerSpeed, portsAvailable, onPortClick, canLinkFirst, canLinkSecond, canLinkBoth,
    openedLinksGroupId, toggleLinksGroup, fromGeneric, trackChanges
  } = useLinksGroupForm(linksGroup, freePorts);

  const isActive = linksGroup.id === openedLinksGroupId;
  const maxLinks = portsAvailable + linksGroup.count;

  return (
    <>
      <Accordion.Title className='re-links-group' active={isActive} onClick={toggleLinksGroup}>
        <Icon name='dropdown' />
        <b>{linksGroup.label || '<Empty>'}</b>
        <Port amount={linksGroup.count} speedString={linksGroup.speedString} />
        <Button icon='trash' size='tiny' onClick={deleteLinksGroup} />
      </Accordion.Title>
      <Accordion.Content className='re-links-group-content' active={isActive}>
        <Field label='Label' required>
          <Input
            size='small'
            fluid
            value={linksGroup.label}
            onChange={(event, {value}) => trackChanges({label: value})}
            error={!linksGroup.label}
            maxLength={64}
          />
        </Field>
        <Field label='Tags'>
          <TagsControl
            className='tags'
            size='tiny'
            fluid
            value={linksGroup.tags ?? []}
            knownTags={usedTags}
            onChange={(value) => trackChanges({tags: value})}
          />
        </Field>
        <div className='re-links-form'>
          <PortsCount
            portsBySpeed={maxSizePerSpeed}
            selectedSpeed={linksGroup.speedString}
            onClick={onPortClick}
            error={!linksGroup.speedString}
            showEmpty
            usedCount={+linksGroup.count}
          />
          <Field label='Count' disabled={!linksGroup.speedString} required>
            <NumberInput
              type='number'
              size='small'
              fluid
              min={1}
              max={maxLinks}
              value={+linksGroup.count}
              onChange={(event, {value}) => trackChanges({count: value})}
            />
          </Field>
          {
            homingType === HOMING_TYPES.LEFT_RIGHT &&
              <Field label='Peer Switch' className='checkboxes' required>
                <Checkbox
                  label='First'
                  checked={linksGroup.isAttachedToFirst}
                  disabled={!canLinkFirst}
                  onChange={() => toggleSwitchLink(true, canLinkBoth)}
                />
                <Checkbox
                  label='Second'
                  checked={linksGroup.isAttachedToSecond}
                  disabled={!canLinkSecond}
                  onChange={() => toggleSwitchLink(false, canLinkBoth)}
                />
              </Field>
          }
          {
            homingType === HOMING_TYPES.DOUBLE_SINGLE &&
              <Field label='Attachemt Type' className='checkboxes' disabled={!canLinkBoth}>
                <Checkbox
                  label='Dual-Homed'
                  checked={linksGroup.isDualAttached}
                  onChange={toggleHoming}
                />
              </Field>
          }
        </div>
        {
          fromGeneric &&
            <Field label='LAG Mode' className='lag-mode'>
              <div>
                {
                  map(LAG_MODES, (mode) => makeLagCheckbox(mode, linksGroup, trackChanges))
                }
              </div>
            </Field>
        }
      </Accordion.Content>
    </>
  );
};

export default observer(LinksGroupForm);

const useLinksGroupForm = (linksGroup, freePorts) => {
  const {rackStore, selection} = useRackEditorStore();
  const {openedLinksGroupId, tags: usedTags} = rackStore;

  const {
    maxSizePerSpeed, toggleHoming, toggleSwitchLink, onPortClick, portsAvailable, homingType,
    fromPorts0, toPorts0, toPorts1, betweenPairs, isOneToPair, fromNodes, trackChanges
  } = useLinksGroupOptions(linksGroup, freePorts);

  const {
    id, speedString, count, isAttachedToFirst, isAttachedToSecond, isDualAttached
  } = linksGroup;

  // These methods differ for existing and newly created links groups!!!

  // Identifies whether isAttachedToFirst can be changed
  const canLinkFirst =
    // Only makes sense for one-to-pair configuration and
    isOneToPair && (
      (
        // if attached to the second and can be reattached to the first
        !isAttachedToFirst || toPorts1 >= count
      ) || isDualAttached
    );

  // Identifies whether isAttachedToSecond can be changed
  const canLinkSecond =
    // Only makes sense for one-to-pair configuration
    isOneToPair && (
      (
        // if attached to the first and can be reattached to the second
        !isAttachedToSecond || toPorts0 >= count
      ) || isDualAttached
    );

  // Identifies whether dual-homing changing is allowed
  const canLinkBoth = (
    (
      // For pair-to-pair configuration
      betweenPairs &&
        (
          // If have cross-links already or
          isDualAttached ||
          // all nodes have enough free ports to create them
          maxSizePerSpeed[speedString] >= count
        )
    ) || (
      // For one-to-pair configuration
      isOneToPair &&
      // when source has enough free ports for dual-homing
      fromPorts0 >= count && (
        // and unset target has enough free ports either
        (!isAttachedToFirst && toPorts0 >= count) ||
        (!isAttachedToSecond && toPorts1 >= count)
      )
    )
  );

  const fromGeneric = first(fromNodes)?.isGeneric;

  const toggleLinksGroup = () => rackStore.toggleLinker(id, selection);

  const deleteLinksGroup = (event) => {
    event.stopPropagation();
    // If opened links group gets deleted - its section must be collapsed
    if (id === openedLinksGroupId) rackStore.toggleLinker(null, selection);
    // Register links group deletion in history
    rackStore.changes.register(Step.deletion(linksGroup));
    // Delete it
    rackStore.deleteLinksGroup(id);
  };

  return {
    usedTags, homingType,
    canLinkFirst, canLinkSecond, canLinkBoth, toggleSwitchLink, toggleHoming,
    maxSizePerSpeed, onPortClick, portsAvailable, fromGeneric,
    openedLinksGroupId, toggleLinksGroup, deleteLinksGroup, trackChanges
  };
};

const bracketsExpr = new RegExp(/^(.+) \((.+)\)$/, 'i');

const makeLagCheckbox = (mode, linksGroup, trackChanges) => {
  const tooltip = LAG_MODES_TITLES[mode];

  const label = LAG_MODES_NAMES[mode];
  const parts = label.match(bracketsExpr);
  const labelControl = (
    <>
      <span>
        {parts ? parts[1] : label}
        {
          parts &&
            <>
              <br />
              <small>{`${parts[2]}`}</small>
            </>
        }
      </span>
      {
        tooltip &&
          <Popup
            trigger={<Icon name='info circle' />}
            content={tooltip}
            offset={[-12, 0]}
          />
      }
    </>
  );

  const disabled = mode === LAG_MODES.NO_LAG && (linksGroup.count > 1 || linksGroup.isDualAttached);

  return (
    <Checkbox
      key={mode}
      value={mode}
      radio
      label={labelControl}
      name='lagMode'
      checked={linksGroup.lagMode === mode}
      onClick={disabled ? undefined : () => trackChanges({lagMode: mode})}
      disabled={disabled}
    />
  );
};
