import {Layout} from '../types';
import {LayoutAlgorithm} from './LayoutAlgorithm';

export const MIN_NODE_WIDTH = 100;
export const MIN_NODE_HEIGHT = 32;

export class NodeLayout extends LayoutAlgorithm {
  recalculateOwn = (own: Layout) => {
    let changed = false;
    if (own.width < MIN_NODE_WIDTH) {
      own.width = MIN_NODE_WIDTH;
      changed = true;
    }
    if (own.height < MIN_NODE_HEIGHT) {
      own.height = MIN_NODE_HEIGHT;
      changed = true;
    }
    return changed;
  };

  recalculateChildren = () => false;
}
