import {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import {keys, map, join, isNumber, has, isString} from 'lodash';

import FormattedPythonExpression from '../../pythonExpression/components/FormattedPythonExpression';
import RangeValue from '../../components/RangeValue';

export const FILTER_TYPE = {
  any: 'any',
  equals: 'equals',
  pattern: 'pattern',
  prefix: 'prefix',
  range: 'range'
};

@observer
export default class IngestionFilter extends Component {
  renderMaskValue = (filter, field, label) => (
    <Fragment>&nbsp;<strong>{label}</strong>&nbsp;
      {this.renderNumberOrExpression(filter.value[field])}
    </Fragment>
  );

  renderNumberOrExpression = (value) => isNumber(value) ? value : this.renderExpression(value);
  renderExpression = (value) => <FormattedPythonExpression value={value} />;

  renderValue = (filter) => {
    if (filter.type === FILTER_TYPE.equals || filter.type === FILTER_TYPE.pattern) {
      return Array.isArray(filter.value) ?
        `${join(map(filter.value, (v) => `"${v}"`), ', ')}`
        :
        isString(filter.value) ? this.renderExpression(filter.value) : null;
    } else if (filter.type === FILTER_TYPE.range) {
      const {min, max} = filter.value || {};
      return <RangeValue min={this.renderNumberOrExpression(min)} max={this.renderNumberOrExpression(max)} />;
    } else if (filter.type === FILTER_TYPE.prefix) {
      return (
        <Fragment>
          {this.renderExpression(filter.value.prefix)}
          {has(filter.value, ['ge_mask']) && this.renderMaskValue(filter, 'ge_mask', 'ge')}
          {has(filter.value, ['le_mask']) && this.renderMaskValue(filter, 'le_mask', 'le')}
        </Fragment>
      );
    }
  };

  render() {
    const {renderValue, props: {value: filters}} = this;
    return (
      <div>
        {map(keys(filters), (key) => {
          return (
            <div key={key}>
              {key}
              {' ⟶ '}
              <strong>{filters[key].type}</strong>
              {filters[key].value && <Fragment>&nbsp;{renderValue(filters[key])}</Fragment>}
            </div>
          );
        })}
      </div>
    );
  }
}
