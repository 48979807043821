import {useCallback, useMemo} from 'react';
import {map} from 'lodash';
import {observer} from 'mobx-react';

import Node from './Node';
import Draggable from '../../eptBuilder/components/Draggable';
import {useCablingMapStore} from '../store/useCablingMapStore';

// Renders a list of nodes. Wraps selected set into Draggable
const CanvasNodes = ({nodes, affectedNodes, onClick, readonly, snapped, hideSmallDetails}) => {
  const {
    nodesRendered, isSelected, handleStartDragging, handleMoving, handleDropping
  } = useCanvasNodes(nodes, affectedNodes, onClick, readonly, snapped, hideSmallDetails);

  return isSelected ?
    <Draggable
      key='selection'
      relative
      onStartDragging={handleStartDragging}
      onMove={handleMoving}
      onDrop={handleDropping}
    >
      {nodesRendered}
    </Draggable> : nodesRendered;
};

export default observer(CanvasNodes);

const useCanvasNodes = (nodes, affectedNodes, onClick, readonly, snapped, hideSmallDetails) => {
  const {cablingMap, selection, layerData} = useCablingMapStore();
  const isSelected = !!affectedNodes;

  // Dragging start handler
  const handleStartDragging = useCallback(
    () => {
      cablingMap.hidePopups();
      selection.fixInitialState(cablingMap.nodes);
    },
    [cablingMap, selection]
  );

  // Moving the group of selected nodes upon dragging
  const handleMoving = useCallback(
    (offset) => cablingMap.moveNodesBy(selection.selectedNodesIds, offset),
    [cablingMap, selection]
  );

  // Handler for nodes dropping
  const handleDropping = useCallback(
    () => cablingMap.dropNodes(selection, snapped),
    [cablingMap, selection, snapped]
  );

  const nodesRendered = useMemo(
    () => {
      return map(
        nodes,
        (node) => {
          return <Node
            key={node.id}
            isSelected={isSelected || selection.keyedSelectedNodesIds[node.id]}
            {...{node, onClick, readonly, hideSmallDetails}}
            layerColor={layerData?.[node.id]?.layer_status}
            anomaly={layerData?.[node.id]?.anomaly}
            useLayerColor={!!layerData}
          />;
        }
      );
    }, [nodes, isSelected, layerData, onClick, readonly, selection.keyedSelectedNodesIds, hideSmallDetails]
  );

  return {nodesRendered, isSelected, handleStartDragging, handleMoving, handleDropping};
};
