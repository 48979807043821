import {FC} from 'react';

interface IGridProps {
  circleRadius?: number;
  className: string;
  step: number;
  size: number;
}

const Grid: FC<IGridProps> = ({circleRadius = 1, className, step, size}) => {
  const alignedSize = Math.ceil(size / step) * step;
  return (
    <g className={className}>
      <pattern
        id='cme-grid-pattern-circles'
        x={-2 * circleRadius}
        y={-2 * circleRadius}
        width={step}
        height={step}
        patternUnits='userSpaceOnUse'
      >
        <circle cx={circleRadius} cy={circleRadius} r={circleRadius} />
      </pattern>
      <rect
        fill='url(#cme-grid-pattern-circles)'
        x={-alignedSize}
        y={-alignedSize}
        width={2 * alignedSize}
        height={2 * alignedSize}
      />
    </g>
  );
};

export default Grid;
