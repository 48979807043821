import {useCallback} from 'react';
import {Input} from 'semantic-ui-react';
import {has} from 'lodash';
import {Field, MapInput} from 'apstra-ui-common';

export default function ValueMapInput({
  name, value, schema, required, disabled, errors, onChange, minItems, maxItems, fieldProps
}) {
  const generateNewEntryKey = useCallback((from = 0) => {
    const result = String(from);
    if (!has(value, [result])) return result;
    return generateNewEntryKey(from + 1);
  }, [value]);

  const generateNewEntry = useCallback(() => [generateNewEntryKey(), ''], [generateNewEntryKey]);

  return (
    <MapInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      generateNewEntry={generateNewEntry}
      minItems={minItems}
      maxItems={maxItems}
      buttonText='Add Entry'
      noItemsMessage='Value map is empty.'
      fieldProps={fieldProps}
    >
      {({key, value, index, errors, setEntryKey, setEntryValue}) =>
        <>
          <Field key='key' width={2}>
            <Input
              type='number'
              step='1'
              disabled={disabled}
              value={key}
              onChange={(e) => setEntryKey(index, e.target.value)}
            />
          </Field>
          <Field errors={errors} width={6}>
            <Input
              disabled={disabled}
              value={value}
              onChange={(e) => setEntryValue(index, e.target.value)}
            />
          </Field>
        </>
      }
    </MapInput>
  );
}
