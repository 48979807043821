import {BaseSyntheticEvent, FC, useMemo} from 'react';
import cx from 'classnames';

import './Link.less';

type LinkProps = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  down: boolean;
  onClick: () => void;
  onMouseOut?: (e: BaseSyntheticEvent) => void;
  onBlur?: (e: BaseSyntheticEvent) => void;
  onMouseOver?: (e: BaseSyntheticEvent) => void;
  onFocus?: (e: BaseSyntheticEvent) => void;
};

export const Link: FC<LinkProps> = ({
  x1, y1, x2, y2, onClick, onMouseOut, onBlur, onMouseOver, onFocus, down
}) => {
  const curve = useMemo(
    () => {
      const avgY = Math.ceil(0.5 * (y1 + y2));
      return `M${x1},${y1} C${x1},${avgY} ${x2},${avgY} ${x2},${y2}`;
    },
    [x1, x2, y1, y2]
  );
  const className = cx('link', {'admin-down': down});
  return (
    <g
      className='topology-link'
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <path
        className='link-hover-area'
        d={curve}
      />
      <path
        className={className}
        d={curve}
      />
    </g>
  );
};
