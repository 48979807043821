import {isFunction, omit, transform} from 'lodash';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Component} from 'react';

export default class InterWindowController extends Component {
  componentDidMount() {
    window.addEventListener('message', this.messageHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  componentDidUpdate() {
    this.pushProperties();
  }

  messageHandler = (message) => {
    if (message.source !== this.props.window) return;
    if (message.data.type === 'ready') {
      this.pushProperties();
    }
    if (message.data.type === 'callback') {
      this.props[message.data.name](...message.data.args);
    }
  };

  pushProperties = () => {
    const props = transform(omit(this.props, ['children', 'window']), (acc, value, key) => {
      if (isFunction(value)) {
        acc.callbacks.push(key);
      } else {
        acc.props[key] = value;
      }
    }, {callbacks: [], props: {}, type: 'props'});
    this.props.window.postMessage(props);
  };

  render() {
    return null;
  }
}

export const interWindowControlled = (controllerWindow) => (WrappedComponent) => {
  return observer(class RemoteControlled extends Component {
    @observable receivedProps = null;

    constructor() {
      super();
      makeObservable(this);
    }

    @action
    setReceivedProps = (props) => {
      this.receivedProps = props;
    };

    componentDidMount() {
      window.addEventListener('message', this.messageHandler);
      this.notifyInitialized();
    }

    componentWillUnmount() {
      window.removeEventListener('message', this.messageHandler);
    }

    notifyInitialized = () => {
      if (!controllerWindow) return;
      controllerWindow.postMessage({type: 'ready'}, location.origin);
    };

    createCallback = (name) => (...args) => {
      controllerWindow.postMessage({type: 'callback', name, args}, location.origin);
    };

    messageHandler = (message) => {
      if (message.source !== controllerWindow) return;
      if (message.data.type === 'props') {
        const props = transform(message.data.callbacks, (acc, name) => {
          acc[name] = this.createCallback(name);
        }, {...message.data.props});
        this.setReceivedProps(props);
      }
    };

    render() {
      const props = {...this.props, ...(this.receivedProps ?? {})};
      if (controllerWindow && !this.receivedProps) return null;
      return <WrappedComponent {...props} />;
    }
  });
};
