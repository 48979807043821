import {action, makeObservable, observable, remove, set} from 'mobx';
import {forEach, get, has, isArray, isUndefined, keys, noop} from 'lodash';

import {convertGraphQueryObjectToString} from './convertGraphQueryObjectToString';
import PythonExpressionPlainTextFormatter from '../pythonExpression/PythonExpressionPlainTextFormatter';
import GraphQueryToObjectConverter from '../pythonExpression/GraphQueryToObjectConverter';

export default class QueryStore {
  @observable queryObject = {name: null};
  @observable query = '';

  @action
  updateQueryString = (query) => {
    this.query = query;
  };

  @action
  addNode = (query, key, value) => {
    if (!key) {
      set(query, value);
    } else {
      if (!has(query, key) || !isArray(query[key])) {
        query[key] = [];
      }
      query[key].push(value);
    }
    this.onChangeQueryObject();
  };

  @action
  deleteNode = (query, key = '', index = -1) => {
    if (!key) {
      set(query, {name: null});
      forEach(keys(query), (key) => {
        if (key !== 'name') remove(query, key);
      });
    } else {
      query[key].splice(index, 1);
    }
    this.onChangeQueryObject();
  };

  @action
  replaceQueryValue = (queryKeyPath = [], value) => {
    const query = get(this.queryObject, queryKeyPath, this.queryObject);
    set(query, value);
    this.onChangeQueryObject();
  };

  @action
  removeQueryKey = (queryKeyPath = [], key) => {
    const query = get(this.queryObject, queryKeyPath, this.queryObject);
    remove(query, key);
    this.onChangeQueryObject();
  };

  @action
  removePathQuery = (queryKeyPath = [], index) => {
    const query = get(this.queryObject, queryKeyPath, this.queryObject);
    if (!query.path) return;
    if (!isUndefined(index)) {
      query.path.splice(index + 1, query.path.length);
    } else {
      query.path.length = 0;
    }
    this.onChangeQueryObject();
  };

  @action
  onChangeQueryObject = () => {
    const query = convertGraphQueryObjectToString(this.queryObject);
    this.query = PythonExpressionPlainTextFormatter.parseAndFormat(query, {multiLine: true});
    this.onChangeQueryString(this.query);
  };

  @action
  initStore = (query) => {
    this.query = query;
    this.queryObject = query ? GraphQueryToObjectConverter.parse(query) : {name: null};
  };

  constructor(query = '', onQueryChange = noop) {
    this.initStore(query);
    this.onChangeQueryString = onQueryChange;
    makeObservable(this);
  }
}
