import {observer} from 'mobx-react';

import PeerLinkForm from './PeerLinkForm';
import {PEER_LINKS_TYPES} from '../const';

import './AccessSwitchPeerLinker.less';
import usePeerLinker from '../hooks/usePeerLinker';

const AccessSwitchPeerLinker = ({portsSpeeds, selectedNodes}) => {
  const {node, linksGroups, portChannelIdsErrors, trackChange} = usePeerLinker(selectedNodes);

  return (
    <div className='re-access-switch-pair-linker'>
      <h5>{'Access Switch Pair Peer Links'}</h5>
      <PeerLinkForm
        title='L3 Links'
        node={node}
        link={linksGroups[PEER_LINKS_TYPES.L3_PEER]}
        portsSpeeds={portsSpeeds}
        trackChange={trackChange}
        error={!!portChannelIdsErrors?.portChannelId}
        applyToBoth
        required
      />
    </div>
  );
};

export default observer(AccessSwitchPeerLinker);
