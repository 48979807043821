import {useEffect, useState} from 'react';
import {Grid} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {isEqual} from 'lodash';
import {FetchData, interpolateRoute, request} from 'apstra-ui-common';

import QueryBrick from './QueryBrick';
import QueryBuilderContext from './QueryBuilderContext';
import QueryDeleteButton from './QueryDeleteButton';
import {fetchPredefinedQuery} from './utils';
import QueryStore from './QueryStore';

import './QueryBuilder.less';

const fetchData = async ({blueprintDesign: referenceDesignName, blueprintId, graphType, signal}) => {
  const routes = {
    referenceDesign: '/api/docs/reference_design_schemas/<reference_design_name>',
    tags: '/api/blueprints/<blueprint_id>/tags'
  };
  const [data, {items: tags}, {items: predefinedQueries}] = await Promise.all([
    request(interpolateRoute(routes.referenceDesign, {referenceDesignName}), {signal}),
    request(interpolateRoute(routes.tags, {blueprintId}), {signal, queryParams: {type: graphType}}),
    fetchPredefinedQuery({signal}),
  ]);
  return {data, tags, predefinedQueries};
};

const QueryBuilder = observer(({
  blueprintDesign, onQueryChange, setMarkers, markers: currentMarkers, blueprintId,
  query: queryProp, graphType, lastCatalogUpdateDate,
}) => {
  const [queryStore] = useState(() => new QueryStore(queryProp, onQueryChange));
  const {
    addNode, deleteNode, replaceQueryValue, removeQueryKey,
    removePathQuery, initStore, queryObject, query
  } = queryStore;
  useEffect(() => {
    if (!isEqual(queryProp, query)) {
      initStore(queryProp);
    }
  }, [queryProp, query, initStore]);
  useEffect(
    () => {
      const markers = currentMarkers ? [...currentMarkers] : [];
      setMarkers([], () => setMarkers(markers));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query]
  );
  const onHover = (e, startRow = 0, endRow = 0) => {
    e.stopPropagation();
    const markers = [{startRow, endRow: endRow + 1, className: 'query-builder-highlight', type: 'background'}];
    if (!isEqual(markers, currentMarkers)) setMarkers(markers);
  };
  const onLeave = (e) => {
    e.stopPropagation();
    setMarkers([]);
  };

  return (
    <FetchData
      fetchData={fetchData}
      pollingInterval={null}
      fetchParams={{blueprintDesign, blueprintId, graphType, lastCatalogUpdateDate}}
    >
      {({data, tags, predefinedQueries}) => (
        <QueryBuilderContext.Provider
          value={{
            data, tags, predefinedQueries, onHover, onLeave, addNode, deleteNode,
            replaceQueryValue, removeQueryKey, removePathQuery,
          }}
        >
          <div className='query-builder'>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <div className='query-brick'>
                    <QueryBrick
                      query={queryObject}
                      canAddPath
                      canBeRemoved
                      deleteEl={
                        <QueryDeleteButton
                          onClick={() => deleteNode(queryObject)}
                        />
                      }
                      removePathAfterThisNode={removePathQuery}
                      isRoot
                      queryKeyPath={[]}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </QueryBuilderContext.Provider>
      )}
    </FetchData>
  );
});

export default QueryBuilder;
