import {Component} from 'react';
import {get, omit, includes, sortBy, some, isEmpty} from 'lodash';
import {computed, makeObservable} from 'mobx';
import {DropdownControl} from 'apstra-ui-common';

import {ALL_NODE_ROLES} from '../../roles';
import IBAContext from '../IBAContext';

export default class SystemNodeControl extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    valuePath: ['label'],
    textPath: ['label'],
    allowedRoles: [ALL_NODE_ROLES.LEAF, ALL_NODE_ROLES.SPINE, ALL_NODE_ROLES.GENERIC],
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get options() {
    const {isFreeform} = this.context;
    const {valuePath, textPath, allowedRoles, value} = this.props;
    const filterFn = isFreeform ?
      ({system_type: systemType, system_id: systemId}) => systemId && systemType === 'internal' :
      ({role}) => includes(allowedRoles, role);
    const nodes = sortBy(this.context.allActiveNodes.filter(filterFn), 'label');
    const options = nodes.map((node) => ({
      key: node.id,
      value: get(node, valuePath, null),
      text: get(node, textPath, ''),
    }));
    if (!isEmpty(value) && !some(options, {value})) {
      options.push({
        key: value,
        text: value,
        value,
      });
    }
    return sortBy(options, 'text');
  }

  render() {
    return (
      <DropdownControl
        {...omit(this.props, ['allowedRoles', 'allActiveNodes', 'valuePath', 'textPath'])}
        search
        clearable
        options={this.options}
      />
    );
  }
}
