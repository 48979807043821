import {tokenMatcher} from 'chevrotain';
import {map, forEach, flatMap, values, zip, unzip, flattenDeep, castArray, isPlainObject, compact} from 'lodash';

import PythonExpressionParser, {Not} from './PythonExpressionParser';

export const NEWLINE = Symbol('NEWLINE');
export const INDENT = Symbol('INDENT');
export const OUTDENT = Symbol('OUTDENT');

export function interpose(array, separator) {
  return flatMap(array, (item, index) => index ? castArray(separator).concat(item) : item);
}

let visitorInstance = null;

const PythonExpressionParserCSTVisitor = PythonExpressionParser.instance.getBaseCstVisitorConstructor();

export default class PythonExpressionPlainTextFormatter extends PythonExpressionParserCSTVisitor {
  static get instance() {
    if (!visitorInstance) visitorInstance = new this();
    return visitorInstance;
  }

  static resetState() {
    const {instance} = this;
    instance.functionNames = [];
    instance.chainStartedForStackSize = null;
    instance.forbidNewLines = false;
  }

  static parseAndFormat(text, options) {
    try {
      const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(text);
      if (!lexErrors.length && !parseErrors.length) return PythonExpressionPlainTextFormatter.run(cst, options);
    } catch {}
    return text;
  }

  static run(cst, {indentationToken = '  ', multiLine = false} = {}) {
    this.resetState();
    this.instance.multiLine = multiLine;
    return this.convertSymbolsAndTokens(this.instance.visit(cst), indentationToken).join('');
  }

  static convertSymbolsAndTokens(visitResult, indentationToken) {
    const result = flattenDeep(visitResult);
    let indentator = '';
    forEach(result, (token, tokenIndex) => {
      if (token === NEWLINE) {
        result[tokenIndex] = '\n' + indentator;
      } else if (token === INDENT) {
        indentator += indentationToken;
        result[tokenIndex] = '';
      } else if (token === OUTDENT) {
        indentator = indentator.slice(indentationToken.length);
        result[tokenIndex] = '';
      } else if (isPlainObject(token) && token.tokenType) {
        result[tokenIndex] = token.image;
      }
    });
    return result;
  }

  expression(ctx) {
    const result = [];
    if (ctx.prefixOperatorExpression) {
      result.push(this.visit(ctx.prefixOperatorExpression));
    } else if (ctx.functionCall) {
      result.push(this.visit(ctx.functionCall));
    } else if (ctx.lambda) {
      result.push(this.visit(ctx.lambda));
    } else if (ctx.list) {
      result.push(this.visit(ctx.list));
    } else if (ctx.dict) {
      result.push(this.visit(ctx.dict));
    } else if (ctx.string) {
      result.push(this.visit(ctx.string));
    } else if (ctx.parenWrappedExpressionOrTuple) {
      result.push(this.visit(ctx.parenWrappedExpressionOrTuple));
    } else {
      result.push(values(ctx)[0][0]);
    }
    if (ctx.chainedExpression) {
      result.push(this.visit(ctx.chainedExpression));
    }
    if (ctx.indexingOrSlicingChain) {
      result.push(this.visit(ctx.indexingOrSlicingChain));
    }
    if (ctx.infixOperatorExpression) {
      result.push(this.visit(ctx.infixOperatorExpression));
    }
    return result;
  }

  chainedExpression(ctx) {
    const result = [];
    let indent = false;
    const oldChainStartedForStackSize = this.chainStartedForStackSize;
    const insertNewlines = this.multiLine && !this.forbidNewLines;
    if (insertNewlines && this.chainStartedForStackSize !== this.functionNames.length) {
      indent = true;
      this.chainStartedForStackSize = this.functionNames.length;
    }
    if (indent) {
      result.push(INDENT);
    }
    if (insertNewlines) {
      result.push(NEWLINE);
    }
    result.push(ctx.Dot[0], this.visit(ctx.expression));
    if (indent) {
      result.push(OUTDENT);
      this.chainStartedForStackSize = oldChainStartedForStackSize;
    }
    return result;
  }

  indexingOrSlicingChain(ctx) {
    const result = map(
      zip(ctx.LSquare, ctx.indexingOrSlicingChainContent, ctx.RSquare),
      ([LSquare, content, RSquare]) => [LSquare, this.visit(content), RSquare]
    );
    return ctx.chainedExpression ? [...result, this.visit(ctx.chainedExpression)] : result;
  }

  indexingOrSlicingChainContent(ctx) {
    return compact([
      ctx.slices && this.visit(ctx.slices),
      ctx.expressionWithSlices && this.visit(ctx.expressionWithSlices),
    ]);
  }

  slices(ctx) {
    return compact([
      ctx.Colon[0],
      ctx.endIndex && this.visit(ctx.endIndex),
      ctx.Colon[1],
      ctx.step && this.visit(ctx.step)
    ]);
  }

  expressionWithSlices(ctx) {
    return compact([
      ctx.expression && this.visit(ctx.expression),
      ctx.slices && this.visit(ctx.slices)
    ]);
  }

  infixOperatorExpression(ctx) {
    return [' ', this.visit(ctx.infixOperator), ' ', this.visit(ctx.expression)];
  }

  infixOperator(ctx) {
    return values(ctx)[0][0];
  }

  prefixOperatorExpression(ctx) {
    const prefixOperatorToken = this.visit(ctx.prefixOperator);
    const result = [prefixOperatorToken];
    if (tokenMatcher(prefixOperatorToken, Not)) {
      result.push(' ');
    }
    result.push(this.visit(ctx.expression));
    return result;
  }

  prefixOperator(ctx) {
    return values(ctx)[0][0];
  }

  parenWrappedExpressionOrTuple(ctx) {
    const result = [ctx.LParen[0], this.visit(ctx.expression)];
    if (ctx.tupleDistinguishingComma) { // this is tuple
      result.push(ctx.tupleDistinguishingComma[0]);
      if (ctx.restTupleExpressions) { // this is tuple with 2+ elements
        result.push(' ', interpose(map(ctx.restTupleExpressions, (expression) => this.visit(expression)), ', '));
      }
    }
    result.push(ctx.RParen[0]);
    return result;
  }

  lambda(ctx) {
    const result = [ctx.Lambda[0]];
    this.forbidNewLines = true;
    const argumentsVisitResult = this.visit(ctx.functionArguments);
    if (argumentsVisitResult.length) result.push(' ');
    result.push(argumentsVisitResult, ctx.Colon[0], ' ', this.visit(ctx.expression));
    this.forbidNewLines = false;
    return result;
  }

  functionCall(ctx) {
    const currentFunctionName = ctx.functionName[0].image;
    this.functionNames.push(currentFunctionName);
    const argumentsVisitResult = this.visit(ctx.functionArguments);
    const result = [ctx.functionName[0], ctx.LParen[0]];
    if (this.multiLineArguments) {
      result.push(INDENT, NEWLINE, argumentsVisitResult, OUTDENT, NEWLINE);
    } else {
      result.push(argumentsVisitResult);
    }
    result.push(ctx.RParen[0]);
    this.functionNames.pop();
    return result;
  }

  functionArguments(ctx) {
    const currentFunctionName = this.functionNames[this.functionNames.length - 1];
    const allArguments = map(ctx.functionArgument, (functionArgument) => this.visit(functionArgument))
      .map(flattenDeep);
    this.multiLineArguments = this.multiLine && !this.forbidNewLines && (
      currentFunctionName === 'match' ||
      allArguments.some((argument) => argument.some((token) => token === NEWLINE))
    );
    return interpose(allArguments, this.multiLineArguments ? [',', NEWLINE] : [', ']);
  }

  functionArgument(ctx) {
    const result = [];
    if (ctx.positionalArgument) {
      result.push(this.visit(ctx.positionalArgument));
    } else if (ctx.keywordArgument) {
      result.push(this.visit(ctx.keywordArgument));
    } else if (ctx.variadicPositionalArguments) {
      result.push(this.visit(ctx.variadicPositionalArguments));
    } else if (ctx.variadicKeywordArguments) {
      result.push(this.visit(ctx.variadicKeywordArguments));
    }
    return result;
  }

  keywordArgument(ctx) {
    return [ctx.key[0], ctx.Equals[0], this.visit(ctx.value)];
  }

  positionalArgument(ctx) {
    return this.visit(ctx.expression);
  }

  variadicKeywordArguments(ctx) {
    return [ctx.DoubleAsterisk[0], this.visit(ctx.expression)];
  }

  variadicPositionalArguments(ctx) {
    return [ctx.Asterisk[0], this.visit(ctx.expression)];
  }

  string(ctx) {
    return values(ctx)[0][0];
  }

  list(ctx) {
    return [
      ctx.LSquare[0],
      interpose(map(ctx.expression, (expression) => this.visit(expression)), ', '),
      ctx.RSquare[0],
    ];
  }

  dict(ctx) {
    return [
      ctx.LCurly[0],
      interpose(map(ctx.keyValuePair, (keyValuePair) => this.visit(keyValuePair)), ', '),
      ctx.RCurly[0],
    ];
  }

  keyValuePair(ctx) {
    return interpose(map(
      unzip([ctx.key, ctx.value]),
      ([key, value]) => [this.visit(key), ': ', this.visit(value)]
    ), ', ');
  }
}
