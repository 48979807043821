import {escapeRegExp} from 'lodash';

const HighlightText = ({text, highlight}) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'i'));
  const highlightLowerCase = highlight.toLowerCase();
  return parts.map((part, i) =>
    (
      <span key={i} className={part.toLowerCase() === highlightLowerCase ? 'highlight' : null} >
        {part}
      </span>
    )
  );
};

export default HighlightText;
