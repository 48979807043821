import {observer} from 'mobx-react';
import {Segment, Icon} from 'semantic-ui-react';
import {isNil, isString, compact, get} from 'lodash';
import {Field, ValueInput} from 'apstra-ui-common';

import renderRadioButtonEnum from './renderRadioButtonEnum';
import './DictInput.less';

const DictInput = ({
  value, onChange, schema = {}, disabled, onDelete,
  name, required, errors, fieldProps
}) => {
  const {title, description, dictSchema = []} = schema;

  const onChangeOne = (name, attributeValue) => {
    const result = {...value, [name]: attributeValue};
    if (isNil(attributeValue) || attributeValue === '') {
      delete result[name];
    }
    onChange(result);
  };

  const topLevelErrors = isString(errors);
  const globalErrors = topLevelErrors ? [errors] : [];
  const itemsErrors = topLevelErrors ? {} : errors;

  const dictFields = dictSchema.map((
    {name, type, label: title, enum_values: enumValues, description, placeholder, required},
    index
  ) => {
    const processedValue = value?.[name] ?? '';
    const extraProps = type === 'boolean' ?
      {
        value: !!processedValue,
        appearance: 'toggle',
        showLabelInline: true,
      } :
      {};

    return (
      <ValueInput
        key={index}
        value={processedValue}
        onChange={(value) => onChangeOne(name, value)}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        errors={compact([get(itemsErrors, name)])}
        schema={{
          type,
          name,
          title,
          description,
          enum: enumValues
        }}
        fieldProps={{
          descriptionPosition: 'tooltip'
        }}
        {...extraProps}
        renderers={[renderRadioButtonEnum]}
      />
    );
  });

  return (
    <Segment className='ct-dict-item'>
      {(!disabled && onDelete) &&
        <Icon name='delete' link onClick={onDelete} aria-label='Delete' />
      }
      {
        name ?
          <Field
            className='ct-dict-title'
            label={title || name}
            description={description}
            required={required}
            disabled={disabled}
            errors={globalErrors}
            {...fieldProps}
          >
            {dictFields}
          </Field> :
          dictFields
      }
    </Segment>
  );
};

export default observer(DictInput);
