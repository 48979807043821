import {formatSeconds} from 'apstra-ui-common';
import cx from 'classnames';
import {observer} from 'mobx-react';
import {FC} from 'react';
import {Label, Popup, Step} from 'semantic-ui-react';

import {ReactComponent as StageAnomaly} from '../../../../../styles/icons/iba/stage-anomaly.svg';
import {ReactComponent as StagePersisted} from '../../../../../styles/icons/iba/stage-persisted.svg';
import {ReactComponent as StageStreaming} from '../../../../../styles/icons/iba/stage-streaming.svg';
import {ReactComponent as StageDynamic} from '../../../../../styles/icons/iba/stage-dynamic.svg';

import {EllipsisPopup} from '../../../../components/EllipsisPopup';
import {STAGE_DYNAMIC_POPUP_MESSAGE} from '../../../consts';
import {ScrollAnchor} from '../ScrollContext';

type IconWithTooltipProps = {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  tooltip: string;
  className?: string;
}

const IconWithTooltip: FC<IconWithTooltipProps> = ({Icon, tooltip, className}) => (
  <Popup
    trigger={<Icon className={cx('stage-icon icon', className)} aria-label={tooltip} />}
    content={tooltip}
    position='top center'
  />
);

export const StageStep: FC<any> = observer(({
  stageName, active, editable,
  highlights, highlightCurrentEntity, highlightStageAndRelatedProcessors, setCurrentStageName,
  anomalyCount, warningCount,
  stageStepHeight, hasStageError, isDynamic,
  canRaiseAnomalies, hasPersistedData, retentionDuration, streamingEnabled
}) => {
  const hasIssues = !!(anomalyCount || warningCount);
  return (
    <Step
      className={cx('stage', {
        highlighted: stageName in highlights.stages,
        very: stageName in highlights.stages && highlights.stages[stageName].veryHighlighted,
        'has-warnings': !anomalyCount && warningCount,
        'has-errors': anomalyCount || hasStageError,
      })}
      style={{height: stageStepHeight}}
      active={active}
      onMouseEnter={() => highlightStageAndRelatedProcessors(stageName)}
      onMouseLeave={highlightCurrentEntity}
      {...active ?
          {}
        :
          {link: true, onClick: () => {
            setCurrentStageName(stageName);
          }}
      }
    >
      <div className='step-content'>
        <ScrollAnchor entityName='stage step' id={stageName} />
        <Step.Title>
          <div className='stage-name'>
            <EllipsisPopup
              content={stageName}
              trigger={<span>{stageName}</span>}
            />
          </div>
          {isDynamic && !editable &&
            <Label size='tiny' className='stage-dynamic-icon'>
              <IconWithTooltip
                Icon={StageDynamic}
                tooltip={STAGE_DYNAMIC_POPUP_MESSAGE}
              />
            </Label>
          }
        </Step.Title>
        <Label size='tiny'>
          {hasPersistedData &&
            <IconWithTooltip
              Icon={StagePersisted}
              tooltip={`Retention Duration: ${formatSeconds(retentionDuration)}`}
            />
          }
          {streamingEnabled &&
            <IconWithTooltip
              Icon={StageStreaming}
              tooltip='Streaming enabled'
            />
          }
          {(canRaiseAnomalies && !hasIssues) &&
            <IconWithTooltip
              Icon={StageAnomaly}
              tooltip='Can raise anomalies'
            />
          }
          {hasIssues &&
            <>
              <IconWithTooltip
                Icon={StageAnomaly}
                tooltip={anomalyCount ? 'Has anomalies' : 'Has warnings'}
                className='issue'
              />
              <span className='anomaly-counter'>
                {anomalyCount || warningCount}
              </span>
            </>
          }
        </Label>
      </div>
    </Step>
  );
});
