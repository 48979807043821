export const PORT_SPEEDS = [
  {value: 100, unit: 'M'},
  {value: 1, unit: 'G'},
  {value: 10, unit: 'G'},
  {value: 25, unit: 'G'},
  {value: 40, unit: 'G'},
  {value: 50, unit: 'G'},
  {value: 100, unit: 'G'},
  {value: 200, unit: 'G'},
  {value: 400, unit: 'G'},
];

export const PORT_SPEED_COLORS = [
  'green',
  'orange',
  'blue',
  'yellow',
  'violet',
  'brown',
  'purple',
  'pink',
  'olive',
];

export const PORT_SPEED_UNIT_LABELS = {
  M: 'Mbps',
  G: 'Gbps'
};

export const PORT_ROLE_LABELS = {
  superspine: 'Superspine',
  spine: 'Spine',
  leaf: 'Leaf',
  access: 'Access',
  peer: 'Peer',
  unused: 'Unused',
  generic: 'Generic',
  external_generic: 'External Generic',
};
