import {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {Text} from '@visx/text';
import {countBy, filter, map, truncate} from 'lodash';
import {Popup} from 'semantic-ui-react';
import {isExtenderPressed} from 'apstra-ui-common';

import {useRackEditorStore} from '../hooks/useRackEditorStore';
import {ctrlNodeWidth, ctrlNodeHeight, ctrlNodeTitleShownChars} from '../../cablingMapEditor/const';
import {nodeRadius, PORT_ROLES, REDUNDANCY} from '../const';
import './Node.less';
import {useNodeTooltip, useTooltip} from '../../components/graphs/GraphTooltips';

const Node = ({node, isSelected, onClick, readonly}) => {
  const {
    toggleSettings, stopPropagationHandler, handleRemoving, isEsi, classes, labelTruncated,
    showPorts, availableSpeedPorts, editedLinksGroupSpeed, targetRole, eventsHandlers
  } = useNode(node, isSelected, onClick, readonly);

  const {label, position: {x, y}} = node;

  const redundancyLabel = node.isPaired && (
    <g key='redundancy' className='redundancy'>
      <rect x={isEsi ? -20 : -34} y={0} width={isEsi ? 26 : 40} height={15} rx={2} ry={2} />
      <Text y={11} fontSize={10} fontWeight={600} textAnchor='end'>{node.redundancyProtocol.toUpperCase()}</Text>
    </g>
  );

  const actionButtons = [
    <text
      key='settings'
      className='node-settings not-draggable'
      onClick={toggleSettings}
      onMouseDown={stopPropagationHandler}
      onMouseUp={stopPropagationHandler}
    >
      {'\uF013'}
    </text>,
    <text
      key='remove'
      className='node-remove not-draggable'
      onClick={handleRemoving}
      onMouseDown={stopPropagationHandler}
      onMouseUp={stopPropagationHandler}
    >
      {'\uF00D'}
    </text>,
    !node.isValid && (
      <Popup
        key='errors'
        trigger={(
          <use className='invalid-icon' xlinkHref='#warning-sign' />
        )}
        offset={[-14, 0]}
        className='re-tooltip-errors'
      >
        <ul>
          {map(node.validationErrors, (error) => <li key={error}>{error}</li>)}
        </ul>
      </Popup>
    ),
    redundancyLabel
  ];

  return (
    <g
      className={classes}
      transform={`translate(${+x},${+y})`}
    >
      <g
        id={node.id}
        tabIndex={0}
        role='button'
        aria-label={`Node "${label}"`}
        {...eventsHandlers}
      >
        <rect className='selection' x='-4' y='-4' rx='4' ry='4' />
        <rect className='container' rx={nodeRadius} ry={nodeRadius} />
        <Text
          className='title'
          x={ctrlNodeWidth / 2}
          y={ctrlNodeHeight / 2}
          width={ctrlNodeWidth - 20}
          textAnchor='middle'
          verticalAnchor='middle'
        >
          {labelTruncated}
        </Text>
      </g>
      {readonly ? redundancyLabel : actionButtons}
      {showPorts &&
        <SvgPort
          count={availableSpeedPorts}
          speedString={editedLinksGroupSpeed}
          role={targetRole}
        />
      }
    </g>
  );
};

export default observer(Node);

const useNode = (node, isSelected, onClick, readonly) => {
  const {sharedTooltip} = useTooltip();
  const nodeTooltipHandler = useNodeTooltip();
  const {rackStore, selection} = useRackEditorStore();
  const {linksGroups, openedLinksGroupId, isLinkerOpened} = rackStore;

  const {id, label, role, isFadedOut, isPaired, pairedWith, isFirstInPair} = node;

  const labelTruncated = truncate(label, ctrlNodeTitleShownChars);

  // When links beetween some nodes get managed, the other nodes must be
  // dimmed to reduce distraction
  const dimmed = (openedLinksGroupId && (
    !selection.isSelected(id) && (
      !isPaired || !selection.isSelected(pairedWith?.id)
    )
  ));

  const editedLinksGroup = isLinkerOpened && linksGroups[openedLinksGroupId];
  const editedLinksGroupSpeed = editedLinksGroup?.speedString;

  const targetRole = editedLinksGroup && (
    editedLinksGroup.isPeer ?
      PORT_ROLES.PEER :
      editedLinksGroup?.oppositeToNode(node)?.role
  );
  const suitablePorts = targetRole ?
    filter(node.availablePorts, (port) => port.matchesRole(targetRole)) :
    node.availablePorts;

  const availableSpeedPorts = countBy(suitablePorts, 'speedString')?.[editedLinksGroupSpeed] ?? 0;
  const showPorts = !dimmed && editedLinksGroup;

  const classes = cx(
    'rack-editor-node',
    `role-${role}`,
    {
      selected: isSelected,
      'faded-out': isFadedOut,
      clickable: !!onClick || !readonly,
      readonly,
      dimmed,
      'show-ports': showPorts,
      invalid: !node.isValid,
      paired: isPaired,
      first: isPaired && isFirstInPair,
      second: isPaired && !isFirstInPair
    }
  );

  // Handle clicking the node
  const handleClick = useCallback((event, keepPropagation) => {
    if (!keepPropagation) event.stopPropagation();
    onClick?.(event, node);
  }, [node, onClick]);

  // Handle node removing
  const handleRemoving = useCallback((event) => {
    selection.remove(id);
    event.stopPropagation(rackStore.deleteNode(id));
    rackStore.editNode();
  }, [rackStore, selection, id]);

  const handleKeyPress = useCallback((event) => {
    if (event?.key !== 'Tab' && !isExtenderPressed(event)) {
      handleClick(event, true);
    }
  }, [handleClick]);

  const eventsHandlers = useMemo(
    () => {
      return readonly ?
        {
          onClick: handleClick,
          onMouseOver: nodeTooltipHandler(node),
          onMouseOut: sharedTooltip.hide,
          onFocus: nodeTooltipHandler(node),
          onBlur: sharedTooltip.hide
        } :
        {
          onClick: handleClick,
          onKeyDown: handleKeyPress
        };
    },
    [node, readonly, handleClick, handleKeyPress, nodeTooltipHandler, sharedTooltip.hide]
  );

  // Toggles node settings popup visibility
  const toggleSettings = useCallback((event) => {
    rackStore.toggleOptions(true);
    rackStore.editNode(node);
    event.stopPropagation();
  }, [rackStore, node]);

  const stopPropagationHandler = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const isEsi = node.redundancyProtocol === REDUNDANCY.ESI;

  return {
    toggleSettings, stopPropagationHandler, handleRemoving, isEsi, classes, labelTruncated,
    showPorts, availableSpeedPorts, editedLinksGroupSpeed, targetRole, eventsHandlers
  };
};

const SvgPort = ({speedString, count, role, showEmpty}) => {
  const size = 20;
  const isShown = speedString && (count > 0 || showEmpty);

  return (
    <g className='re-svg-port'>
      {
        isShown ?
          <>
            <rect className={`port-${speedString}`} x={0} y={0} height={size} width={size} rx={2} ry={2} />
            <Text className='count' x={size / 2} y={22} verticalAnchor='middle' textAnchor='middle'>{count}</Text>
            <Text x={size + 8} y={22} verticalAnchor='middle' textAnchor='start'>
              {speedString + (role ? ` (${role})` : '')}
            </Text>
          </> :
          <Text x={4} y={22} verticalAnchor='middle' textAnchor='start'>{'N/A'}</Text>
      }
    </g>
  );
};
