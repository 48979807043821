import {FC} from 'react';

type RectProps = {
  width: number,
  height: number,
  r: number,
  left?: boolean,
}

export const Rect: FC<RectProps> = ({width, height, r, left}) => {
  const d = left ?
    `M${r},0h${width - r}v${height}h${r - width}q${-r},0,${-r},${-r}v${2 * r - height}q0,${-r},${r},${-r}` :
    `M0,0h${width - r}q${r},0,${r},${r}v${height - 2 * r}q0,${r},${-r},${r}h${r - width}v${-height}`;
  return <path d={d} />;
};
