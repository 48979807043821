import {FC} from 'react';
import ReactMarkdown, {Options} from 'react-markdown';
import remarkGfm from 'remark-gfm';

import './Markdown.less';

const defaultPlugins = [remarkGfm];

export const Markdown: FC<Options> = (props) => (
  <ReactMarkdown
    className='apstra-markdown'
    remarkPlugins={defaultPlugins}
    {...props}
  />
);
