import {useMemo} from 'react';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {keyPressClick} from 'apstra-ui-common';

import {ctrlLagStep, ctrlLagWidth} from '../../const';
import './Lag.less';

const Lag = ({x, y, size, behind, onClick, error, className}) => {
  const classNames = cx('ctrl-lag', className, {behind, error});
  const keyClickHandler = useMemo(() => keyPressClick(onClick), [onClick]);
  const props = onClick ? {
    onClick,
    onKeyDown: keyClickHandler
  } : {};
  const rectProps = {
    x: -ctrlLagWidth / 2,
    y: -ctrlLagStep / 2 + 2,
    width: ctrlLagWidth,
    height: ctrlLagStep * size - 4,
    rx: 100
  };
  if (behind && onClick) {
    rectProps.tabIndex = 0;
  }
  return (
    <g
      transform={`translate(${x} ${y})`}
      className={classNames}
      {...props}
    >
      {!behind &&
        <rect
          className='outline'
          clipPath='url(#lagClip)'
          {...rectProps}
        />
      }
      <rect
        clipPath={behind ? '' : 'url(#lagClip)'}
        {...rectProps}
      />
    </g>
  );
};

export default observer(Lag);
