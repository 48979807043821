import {QueryClient, QueryClientProvider as RQQueryClientProvider} from '@tanstack/react-query';
import {useMemo} from 'react';

const DEFAULT_POLLING_INTERVAL = 30000;

const queryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_POLLING_INTERVAL,
      refetchOnWindowFocus: false,
      throwOnError: false,
    },
  },
};

const QueryClientProvider = ({children, ...props}) => {
  const queryClient = useMemo(() => new QueryClient(queryClientConfig), []);

  return (
    <RQQueryClientProvider client={queryClient} {...props}>
      {children}
    </RQQueryClientProvider>
  );
};

export default QueryClientProvider;
