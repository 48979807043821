import React from 'react';
import {interpolateSinebow} from 'd3';
import {times} from 'lodash';

export function ColorEditor({steps = 20, width = 200, height = 20, onChange}) {
  const moveMouseHandler = React.useCallback((e) => {
    // eslint-disable-next-line no-bitwise
    if (e.buttons & 1) {
      const relativePos = e.nativeEvent.offsetX / width;
      onChange?.(interpolateSinebow(relativePos));
    }
  }, [onChange, width]);

  return (
    <div className='color-editor'>
      <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
        <defs>
          <linearGradient x1='0' y1='0' x2='100%' y2='0' id='color-rainbow-gradient'>
            {times(steps + 1, (step) => (
              <stop key={step} stopColor={interpolateSinebow(step / steps)} offset={`${100 / steps * step}%`} />
            ))}
          </linearGradient>
        </defs>
        <rect
          x={0}
          width='100%'
          y={0}
          height='100%'
          fill='url(#color-rainbow-gradient)'
          onMouseDown={moveMouseHandler}
          onMouseMove={moveMouseHandler}
        />
      </svg>
    </div>
  );
}
