import {Fragment} from 'react';
import {Icon, Label} from 'semantic-ui-react';
import {map} from 'lodash';

export default function Tags({value}) {
  return map(value, (tag) =>
    <Fragment key={tag}>
      <Label>
        <Icon name='tag' />
        {tag}
      </Label>
      &nbsp;
    </Fragment>
  );
}
