import {useRef} from 'react';
import cx from 'classnames';

import {find} from 'lodash';
import {Checkbox, onEnterKeyHandler} from 'apstra-ui-common';

import {nodePropTypes} from '../shared-prop-types';
import {Interface} from './Interface';
import {STATUS_CLASSES} from './constants';
import {useNodeTooltip, useTooltip} from '../../GraphTooltips';

import './Node.less';

const Node = (props) => {
  const nodeTooltipHandler = useNodeTooltip();
  const {sharedTooltip} = useTooltip();
  const {
    marginBottom, nodeWidth, interfaceWidth, align,
    hasOnClickNodeHandler, onClickNode,
    id, label, interfaces, status, notInCablingMap, blueprintId,
    sourceNode, interfaceSelection, onSelectInterface, highlightedInterfaces,
    onSelectNode, nodeSelected, setHoveredIf, hoveredIf, nodeMenuOpen,
    unusedInterfaceSelection
  } = props;

  const clickable = hasOnClickNodeHandler && !notInCablingMap;
  const nodeStyle = {marginBottom};
  const interfacesStyle = {width: interfaceWidth};
  const labelStyle = {width: nodeWidth - interfaceWidth};
  const labelPopupTriggerStyle = {cursor: clickable ? 'pointer' : null};

  const nodeClasses = cx('node', 'clear', align, STATUS_CLASSES[status]);

  const viewRef = useRef();

  const showTooltip = (e) => {
    e.stopPropagation();
    if (!nodeMenuOpen) {
      nodeTooltipHandler(sourceNode)(e);
    }
  };

  const hideTooltip = () => {
    sharedTooltip.hide();
  };

  if (nodeMenuOpen) {
    hideTooltip();
  }
  return (
    <div
      className={nodeClasses}
      style={nodeStyle}
      onMouseOver={showTooltip}
      onMouseOut={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
    >
      {onSelectNode &&
        <div ref={viewRef}>
          <Checkbox
            className='node-selector'
            aria-label='Select Node'
            checked={nodeSelected}
            onChange={() => onSelectNode(blueprintId, id, viewRef)}
          />
        </div>
      }
      <div
        className='label'
        style={labelStyle}
      >
        <span
          role='button'
          style={labelPopupTriggerStyle}
          onClick={clickable ? () => onClickNode(id) : null}
          onKeyDown={clickable ? onEnterKeyHandler(onClickNode, id) : null}
          tabIndex='0'
        >
          {label}
        </span>
      </div>

      <div className='interfaces' style={interfacesStyle}>
        {
          interfaces.map((interf, i) => {
            const selected = !!interfaceSelection?.[interf.id] ||
                             (unusedInterfaceSelection?.[interf.label] !== undefined);
            const highlighted = find(highlightedInterfaces, (el) => el.if_id === interf.id);
            const hovered = interf.id && (interf.id === hoveredIf);
            return (
              <Interface
                key={i}
                {...interf}
                intf={interf}
                nodeId={id}
                blueprintId={blueprintId}
                selected={selected}
                highlighted={highlighted}
                onSelect={onSelectInterface}
                hovered={hovered}
                setHoveredIf={setHoveredIf}
              />
            );
          })
        }
      </div>
    </div>
  );
};

Node.propTypes = nodePropTypes;

export default Node;
