export default function generateDashboardURI({blueprintId, dashboardId, action}) {
  let result = `/blueprints/${blueprintId}/analytics`;
  if (action === 'create') {
    result += '/create-dashboard';
  } else if (dashboardId) {
    result += '/dashboards/' + dashboardId;
    if (action) {
      result += '/' + action;
    }
  }
  return result;
}
