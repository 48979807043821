import {useCallback, useLayoutEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {Text} from '@visx/text';
import {trim, truncate, some} from 'lodash';
import {action, toJS} from 'mobx';

import Draggable from './Draggable';
import ConnectionPoint from './ConnectionPoint';
import {eptWidth, eptHeight, canvasWidth, canvasHeight, eptTitleShownChars} from '../settings';
import './Ept.less';

const getComparisonStyles = (sourceEpt, comparisonEpt, isComparisonDirect) => {
  if (comparisonEpt) {
    const parameters = comparisonEpt.parameters;
    const areDifferent = some(
      sourceEpt.parameters,
      ({value}, name) => (value?.id ?? value) !== (parameters[name].value?.id ?? parameters[name].value)
    );
    return {different: areDifferent};
  } else {
    return {added: isComparisonDirect, deleted: !isComparisonDirect};
  }
};

const Ept = ({disabled, showTooltip, compareWith, isComparisonDirect, ept: me, activeEpt}) => {
  const {id, position, isComplete, hasErrors, title, inputIsFlexible, inputTypes, outputTypes, outputIsFlexible} = me;
  const eptRef = useRef();

  // Primitive having no id means it is the entry (application
  // point) which must be rendered on the fixed position
  // top center of the canvas.
  const isStandalone = !id;

  const inPosition = isStandalone ?
        {x: canvasWidth / 2, y: canvasHeight - 20} :
        {x: position.x + eptWidth / 2, y: position.y};

  const outPosition = isStandalone ?
        {x: canvasWidth / 2, y: 20} :
        {x: position.x + eptWidth / 2, y: position.y + eptHeight};

  const classes = cx(
    'ct',
    {
      incomplete: !isComplete,
      disconnected: !activeEpt.hasConnections(id),
      active: trim(activeEpt.activePrimitiveId) === id,
      error: hasErrors,
      dimmed: !activeEpt?.eptsMatched?.[id],
      disabled,
      ...getComparisonStyles(me, compareWith, isComparisonDirect)
    }
  );

  useLayoutEffect(() => {
    if (activeEpt.activePrimitiveId === id) {
      eptRef?.current?.scrollIntoView();
    }
  }, [activeEpt.activePrimitiveId, id]);

  // Fires on mouse down over the draggable area.
  // Causes marking primitive as active (parameters block gets
  // highlighted) and reordering of all primitives to bring
  // this one on top of the others
  const handleStartDragging = useCallback(() => {
    activeEpt.bringEptOnTop(id);
    activeEpt.setActivePrimitive(id);
  }, [activeEpt, id]);

  // Switching to the parameters tab and expanding corresponding
  // primitive parameters section
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const switchToParameters = useCallback(action((e) => {
    e.stopPropagation();
    activeEpt.switchSidePanelTab?.(0);
    activeEpt.setActivePrimitive(me.id);
  }), [activeEpt, me.id]);

  // Shows tooltip over the hovered primitives block.
  // Tooltip contains type and full title information.
  const showMyTooltip = useCallback(() => {
    showTooltip(toJS(me));
  }, [me, showTooltip]);

  // Fired on dragging
  const handleMoving = useCallback((position) => {
    me.moveTo(position);
    showMyTooltip();
  }, [me, showMyTooltip]);

  // CT primitive removing from the canvas.
  // Must prevent dragging initiation (stopPropagation call)
  const handleRemoving = useCallback((event) => {
    event.stopPropagation();
    activeEpt.removeEpt(id);
  }, [activeEpt, id]);

  const actionButtons = [
    <text
      key='settings'
      className='ct-settings not-draggable'
      onMouseDown={switchToParameters}
    >
      {'\uf013'}
    </text>,
    <text
      key='remove'
      className='ct-remove not-draggable'
      onMouseDown={handleRemoving}
    >
      {'\uf00d'}
    </text>
  ];

  return [
    !isStandalone &&
      <Draggable
        key='ct'
        position={position}
        onStartDragging={handleStartDragging}
        onMove={handleMoving}
        disabled={disabled}
      >
        <g
          ref={eptRef}
          className={classes}
          onMouseOver={showMyTooltip}
          onMouseOut={() => showTooltip(null)}
          onDoubleClick={switchToParameters}
          onMouseDown={switchToParameters}
        >
          <rect className='container' rx='4' ry='4' />
          <Text
            className='title'
            x={eptWidth / 2}
            y={eptHeight / 2}
            width={eptWidth - 20}
            textAnchor='middle'
            verticalAnchor='middle'
          >
            {truncate(title, eptTitleShownChars)}
          </Text>
        </g>
        {!disabled && actionButtons}
      </Draggable>,
    (!isStandalone && (inputTypes || inputIsFlexible)) &&
      <ConnectionPoint
        key='in'
        isInput
        position={inPosition}
        types={inputTypes}
        payload={id}
        isMultiple={isStandalone}
        isAnyAccepted={inputIsFlexible}
        disabled={disabled}
        activeEpt={activeEpt}
      />,
    (outputTypes || outputIsFlexible) &&
      <ConnectionPoint
        key='out'
        isInput={false}
        isMultiple
        position={outPosition}
        types={outputTypes}
        payload={id}
        isAnyAccepted={outputIsFlexible}
        disabled={disabled}
        activeEpt={activeEpt}
      />
  ];
};

export default observer(Ept);
