import {Table} from 'semantic-ui-react';
import {isNil, map} from 'lodash';

import humanizeString from '../humanizeString';

const TableStats = ({stats}) => (
  <Table basic='very' compact size='small'>
    <Table.Body>
      {map(stats, (value, key) => (
        <Table.Row key={key}>
          <Table.Cell>
            <strong>
              {humanizeString(key)}
            </strong>
          </Table.Cell>
          <Table.Cell>
            {Number.isInteger(value) || isNil(value) ?
              value || 0 :
              Math.floor(value)
            }
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default TableStats;
