import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import {countBy, debounce, find, flatten, includes, keys, map, mapValues, filter, pick, pickBy,
  some, transform, uniq} from 'lodash';
import {action, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Grid, Icon, List, Segment, Label, Button, Modal, Popup} from 'semantic-ui-react';
import {wrap} from 'comlink';
import {Checkbox, FetchData, FormattedJSON, FuzzySearchBox, Slider,
  orderedKeysReplacer} from 'apstra-ui-common';

import {jsonPriorityKeys} from './GraphExplorer';
import GraphExplorerContext from './GraphExplorerContext';
import {ColorEditor} from './ColorEditor';
import {buildGraphNodesStyles} from './utils';

import './Graph.less';
import './GraphView.less';

const importGraphView = () => import(/* webpackChunkName: 'graph-view' */'./GraphView');

let ForceSimulation;

export class GraphStore {
  @observable transform = null;
  @observable filter = '';
  @observable traceMode = false;
  @observable traceStep = null;
  @observable tracePath = null;
  @observable.ref popupIds = {};
  @observable showInfoOnHover = false;
  @observable isShiftPressed = false;
  simulation = null;

  initSimulation = async () => {
    if (!this.simulation) {
      if (!ForceSimulation) {
        ForceSimulation = wrap(new Worker(
          new URL('./ForceSimulation', import.meta.url) /* webpackChunkName: 'force-simulation-worker' */
        ));
      }
      this.simulation = await new ForceSimulation();
    }
    return this.simulation;
  };

  @action
  updateFilter = (filter) => {
    this.filter = filter;
  };

  @action
  onTraceModeChange = () => {
    this.traceMode = !this.traceMode;
  };

  @action
  updateTraceStep = (value) => {
    this.traceStep = value;
    this.tracePath = null;
  };

  @action
  updateTracePath = (value) => {
    this.tracePath = value;
  };

  @action
  setTransform = (transform) => {
    this.transform = transform;
  };

  @action
  toggleShowInfoOnHover = () => {
    this.showInfoOnHover = !this.showInfoOnHover;
  };

  @action
  setIsShiftPressed = (isShiftPressed) => {
    this.isShiftPressed = isShiftPressed;
  };

  constructor() {
    makeObservable(this);
  }
}

async function fetchData({store}) {
  const [{default: GraphView}] = await Promise.all([
    importGraphView(),
    store.initSimulation(),
  ]);
  return {GraphView};
}

function matchFilter(entity, filter) {
  return some(entity, (value) => includes(String(value), filter));
}

export default observer(Graph);

const PREFERENCES_KEY = 'GraphExplorerCustomPallette';

function Graph({
  nodes, store, traceResult, links, TooltipComponent, showUseFullBlueprintCheckbox, useFullBlueprint,
  onUseFullBlueprintChange, showInfoOnHover
}) {
  const {hiddenNodeTypes, referenceDesignSchema, fetchContextualData, hideNodeTypes,
    showAllNodeTypes, toggleNodeTypeVisibility, preferences, updateUserPreferences} = useContext(GraphExplorerContext);

  const [highlightNodeType, setHighlightNodeType] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [fullViewJSON, setFullViewJSON] = useState(null);
  const [customPallette, setCustomPallette] = useState(() => (find(preferences, {key: PREFERENCES_KEY})?.value || {}));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(debounce(() => {
    updateUserPreferences(PREFERENCES_KEY, pickBy(customPallette));
  }, 500), [customPallette, updateUserPreferences]);

  const nodeTypeColors = useMemo(
    () => mapValues(buildGraphNodesStyles(map(referenceDesignSchema.nodes, 'nodeType')), 'color'),
    [referenceDesignSchema]
  );

  const filteredNodeTypeColors = useMemo(() => {
    const usedNodeTypes = uniq(map(nodes, 'nodeType')).sort();
    return pick(nodeTypeColors, usedNodeTypes);
  }, [nodes, nodeTypeColors]);

  const nodeTypeVisibility = useMemo(() => {
    return mapValues(
      filteredNodeTypeColors,
      (color, nodeTypeName) => !hiddenNodeTypes[nodeTypeName]
    );
  }, [filteredNodeTypeColors, hiddenNodeTypes]);

  const nodeVisibility = useMemo(() => {
    return transform(
      nodes,
      (acc, node) => {acc[node.id] = nodeTypeVisibility[node.nodeType];},
      {}
    );
  }, [nodes, nodeTypeVisibility]);

  const traceIdSet = useMemo(() => {
    const ids = !store.traceMode ? [] : transform(
      traceResult?.steps[store.traceStep]?.paths,
      (acc, path, pathIndex) => {
        if (store.tracePath === null || store.tracePath === pathIndex) {
          acc.splice(acc.length, 0, ...map(flatten(path), 'id'));
        }
      },
      []
    );
    return new Set(ids);
  }, [store.traceMode, store.traceStep, store.tracePath, traceResult?.steps]);

  const nodeModifiers = useMemo(() => {
    return transform(nodes, (acc, node) => {
      acc[node.id] = {
        color: customPallette[node.nodeType] ?? filteredNodeTypeColors[node.nodeType],
        highlighted: node.nodeType === highlightNodeType || store.popupIds[node.id],
        trace: traceIdSet.has(node.id),
        filterMatch: store.filter && matchFilter(node.sourceEntity, store.filter)
      };
    }, {});
  },
  [store.filter, store.popupIds, filteredNodeTypeColors, highlightNodeType, nodes, traceIdSet, customPallette]);

  const linkModifiers = useMemo(() => {
    return transform(links, (acc, link) => {
      acc[link.id] = {
        highlighted: link.source === hoveredNode?.id || link.target === hoveredNode?.id ||
          store.popupIds[link.source] || store.popupIds[link.target],
        trace: traceIdSet.has(link.id),
        filterMatch: store.filter && matchFilter(link.sourceEntity, store.filter),
        hovered: link.id === hoveredLink?.id,
      };
    }, {});
  }, [links, hoveredNode, hoveredLink, traceIdSet, store.filter, store.popupIds]);

  const nodeCountByType = useMemo(() => {
    return countBy(nodes, 'nodeType');
  }, [nodes]);

  const onNodeHover = useCallback((node) => {
    setHoveredNode(node);
    setHighlightNodeType(node?.nodeType);
  }, [setHoveredNode, setHighlightNodeType]);

  const unfixNodesByType = useCallback((type) => {
    const nodesOfType = filter(nodes, {nodeType: type});
    store.simulation.unfixNodesByIds(map(nodesOfType, 'id'));
    store.simulation.restart();
  }, [nodes, store.simulation]);

  const allNodeTypesVisible = useMemo(() => {
    return !some(filteredNodeTypeColors, (color, nodeType) => hiddenNodeTypes[nodeType]);
  }, [filteredNodeTypeColors, hiddenNodeTypes]);

  const someNodeTypesVisible = useMemo(() => {
    return !allNodeTypesVisible &&
      some(filteredNodeTypeColors, (color, nodeType) => !hiddenNodeTypes[nodeType]);
  }, [allNodeTypesVisible, filteredNodeTypeColors, hiddenNodeTypes]);

  const tooltipProps = useMemo(() => {
    return {highlightText: store.filter};
  }, [store.filter]);

  return (
    <div className='graph-view-wrapper'>
      <FetchData
        pollingInterval={null}
        fetchParams={{store}}
        fetchData={fetchData}
      >
        {({GraphView}) =>
          <GraphView
            nodes={nodes}
            links={links}
            customPallette={customPallette}
            nodeTypeColors={nodeTypeColors}
            nodeModifiers={nodeModifiers}
            nodeVisibility={nodeVisibility}
            linkModifiers={linkModifiers}
            TooltipComponent={TooltipComponent}
            tooltipProps={tooltipProps}
            onNodeHover={onNodeHover}
            onLinkHover={setHoveredLink}
            setFullViewJSON={setFullViewJSON}
            width={500}
            height={500}
            store={store}
          />
        }
      </FetchData>
      {fetchContextualData && store.traceMode && traceResult &&
        <TracePanel
          traceResult={traceResult}
          traceStep={store.traceStep}
          tracePath={store.tracePath}
          onTraceStepChange={store.updateTraceStep}
          onTracePathChange={store.updateTracePath}
        />
      }
      <Segment.Group className='legend'>
        <Segment>
          <List>
            <List.Item
              content={
                <FuzzySearchBox
                  size='small'
                  initialValue={store.filter}
                  onChange={store.updateFilter}
                />
              }
            />
            {fetchContextualData && !!traceResult &&
              <List.Item
                content={
                  <Checkbox
                    label='Trace'
                    checked={store.traceMode}
                    onChange={store.onTraceModeChange}
                  />
                }
              />
            }
            {fetchContextualData && showUseFullBlueprintCheckbox &&
              <List.Item
                content={
                  <Checkbox
                    label='Show full blueprint'
                    checked={useFullBlueprint}
                    onChange={onUseFullBlueprintChange}
                  />
                }
              />
            }
            <List.Item
              content={
                <Checkbox
                  label='Show info on hover'
                  checked={showInfoOnHover}
                  onChange={store.toggleShowInfoOnHover}
                />
              }
            />
          </List>
        </Segment>
        <Segment>
          <Button
            fluid
            basic
            content='Show all'
            icon={someNodeTypesVisible ? 'dot circle outline'
              : allNodeTypesVisible ? 'check circle outline' : 'circle outline'}
            size='mini'
            onClick={allNodeTypesVisible ? () => hideNodeTypes(keys(filteredNodeTypeColors)) : showAllNodeTypes}
          />
          <GraphLegend
            nodeTypeColors={filteredNodeTypeColors}
            customPallette={customPallette}
            nodeTypeVisibility={nodeTypeVisibility}
            onNodeTypeClick={toggleNodeTypeVisibility}
            onNodeTypeShiftClick={unfixNodesByType}
            onNodeTypeHover={setHighlightNodeType}
            highlightNodeType={highlightNodeType}
            nodeCountByType={nodeCountByType}
            setCustomPallette={(type, color) => setCustomPallette((pallette) => ({...pallette, [type]: color}))}
          />
        </Segment>
      </Segment.Group>
      <Modal
        closeIcon
        open={!!fullViewJSON}
        onClose={() => setFullViewJSON(null)}
        size='fullscreen'
        content={
          fullViewJSON ?
            <Modal.Content>
              <FormattedJSON
                json={fullViewJSON}
                replacer={orderedKeysReplacer(jsonPriorityKeys)}
              />
            </Modal.Content>
          : null
        }
      />
    </div>
  );
}

function TracePanel({traceResult, traceStep, onTraceStepChange, tracePath, onTracePathChange}) {
  return (
    <Segment className='trace-panel'>
      <Grid>
        <Grid.Row>
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Label content='Step' detail={traceStep} />
          </Grid.Column>
          <Grid.Column verticalAlign='middle' width={6}>
            <Slider
              min={-1}
              max={traceResult?.steps.length - 1 ?? 0}
              onChange={(value) => onTraceStepChange(value === -1 ? null : value)}
              value={traceStep ?? -1}
            />
          </Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Label content='Path' detail={tracePath === null ? 'all' : tracePath} />
          </Grid.Column>
          <Grid.Column verticalAlign='middle' width={6}>
            <Slider
              min={-1}
              max={traceResult?.steps[traceStep]?.paths.length - 1 ?? 0}
              onChange={(value) => onTracePathChange(value === -1 ? null : value)}
              value={tracePath ?? -1}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

function GraphLegend({
  nodeTypeColors, nodeTypeVisibility, onNodeTypeClick, highlightNodeType, onNodeTypeHover,
  nodeCountByType, onNodeTypeShiftClick, customPallette, setCustomPallette
}) {
  return (
    <List>
      {map(nodeTypeColors, (colorName, nodeType) => (
        <List.Item
          key={nodeType}
          onClick={({shiftKey}) => (shiftKey ? onNodeTypeShiftClick : onNodeTypeClick)(nodeType)}
          onMouseEnter={() => onNodeTypeHover(nodeType)}
          onMouseLeave={() => onNodeTypeHover(null)}
        >
          <Label
            className={cx('type-label', {
              [`brand-palette-label-${colorName}`]: !customPallette[nodeType],
              highlighted: highlightNodeType === nodeType,
              unchecked: !nodeTypeVisibility[nodeType]
            })}
            {...(customPallette[nodeType] ? {style: {
              border: `1px solid ${customPallette[nodeType]}`,
              background: nodeTypeVisibility[nodeType] ? customPallette[nodeType] : undefined
            }} : {})}
          >
            {nodeType}
            <Popup
              hoverable
              inverted={false}
              offset={[14, 0]}
              trigger={(
                <Icon
                  className='custom-color-icon'
                  name={customPallette[nodeType] ? 'circle' : 'circle outline'}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
              onClick={(e) => e.stopPropagation()}
              onMouseEnter={(e) => e.stopPropagation()}
              onMouseLeave={(e) => e.stopPropagation()}
            >
              <ColorEditor onChange={(color) => setCustomPallette(nodeType, color)} />
              {<Button
                basic
                compact
                size='mini'
                content='Set default'
                onClick={() => setCustomPallette(nodeType, undefined)}
              />}
            </Popup>
            <Label
              circular
              className='type-count-label'
              size='mini'
              content={nodeCountByType[nodeType]}
            />
          </Label>
        </List.Item>
      ))}
    </List>
  );
}
