import {filter, get, last, values} from 'lodash';

import PythonExpressionParser from './PythonExpressionParser';
import parseStringLiteralToken from './parseStringLiteralToken';
import {namedNodesFunctions} from './consts';

let visitorInstance = null;

const PythonExpressionParserCSTVisitor = PythonExpressionParser.instance.getBaseCstVisitorConstructorWithDefaults();

export default class NamedNodeFinder extends PythonExpressionParserCSTVisitor {
  static get instance() {
    if (!visitorInstance) visitorInstance = new this();
    return visitorInstance;
  }

  static run(cst) {
    const instance = this.instance;
    instance.result = []; // {functionName, nodeType, nodeName}
    instance.functionNames = [];
    instance.visit(cst);
    instance.result = filter(instance.result, 'nodeName');
    return instance.result;
  }

  constructor() {
    super();
    this.validateVisitor();
  }

  functionCall(ctx) {
    const functionName = ctx.functionName[0].image;
    this.functionNames.push(functionName);
    if (namedNodesFunctions.has(functionName)) {
      this.result.push({functionName});
    }
    this.visit(ctx.functionArguments);
    this.functionNames.pop();
  }

  keywordArgument(ctx) {
    const currentFunctionName = last(this.functionNames);
    const key = ctx.key[0].image;
    if (namedNodesFunctions.has(currentFunctionName)) {
      if (key === 'name') {
        last(this.result).nodeName = this.getArgumentValue(ctx);
      } else if (key === 'type') {
        last(this.result).nodeType = this.getArgumentValue(ctx);
      }
    }
    this.visit(ctx.value);
  }

  getArgumentValue(ctx) {
    const stringChildren = get(ctx, ['value', 0, 'children', 'string', 0, 'children']);
    if (stringChildren) {
      const stringToken = get(values(stringChildren), [0, 0]);
      return stringToken ? parseStringLiteralToken(stringToken) : null;
    }
  }

  positionalArgument(ctx) {
    const currentFunctionName = this.functionNames[this.functionNames.length - 1];
    if (namedNodesFunctions.has(currentFunctionName)) {
      const child0 = get(ctx, ['expression', 0, 'children', 'string', 0, 'children']);
      const nodeType = get(
        child0,
        ['ShortSingleQuoteStringLiteral', 0],
        get(child0, ['ShortDoubleQuoteStringLiteral', 0])
      );
      last(this.result).nodeType = nodeType ? parseStringLiteralToken(nodeType) : null;
    }
    return super.positionalArgument(ctx);
  }
}
