import {map} from 'lodash';
import {observer} from 'mobx-react';
import {Message, Step} from 'semantic-ui-react';

import {FC} from 'react';

import {getStageByName} from '../../../stageUtils';
import {StageStep} from './StageStep';
import {ProbeHighlights} from '../types';
import {Probe} from '../../../types';

export type FilteredProbeGraphProps = {
  currentStageName: string | null;
  editable: boolean;
  highlights: ProbeHighlights;
  highlightCurrentEntity: () => void;
  highlightStageAndRelatedProcessors: (stageName: string) => void;
  matchedStages: string[];
  probe: Probe;
  setCurrentStageName: (stageName: string) => void;
  stageStepHeight: number;
  warningCountByStageName: Record<string, number>;
};

export const FilteredProbeGraph: FC<FilteredProbeGraphProps> = observer(({
  matchedStages,
  warningCountByStageName, currentStageName,
  highlights, highlightStageAndRelatedProcessors, highlightCurrentEntity,
  setCurrentStageName, editable,
  stageStepHeight, probe
}) => {
  return (
      matchedStages.length ?
        <div className='navigation-container'>
          <Step.Group vertical fluid>
            {map(matchedStages, (stageName) => {
              const stage = getStageByName({probe, stageName});
              return (
                <StageStep
                  key={stageName}
                  stageStepHeight={stageStepHeight}
                  stageName={stageName}
                  active={stageName === currentStageName}
                  editable={editable}
                  highlights={highlights}
                  highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
                  highlightCurrentEntity={highlightCurrentEntity}
                  setCurrentStageName={setCurrentStageName}
                  anomalyCount={stage.anomaly_count}
                  warningCount={warningCountByStageName[stageName]}
                />
              );
            })}
          </Step.Group>
        </div>
      :
        <Message
          info
          icon='info circle'
          content='No match.'
        />
  );
});
