import {useEffect, useState} from 'react';
import {forEach, includes, isArray, isEmpty, isPlainObject} from 'lodash';
import {Input} from 'semantic-ui-react';
import {Checkbox, FormattedJSON, Loader} from 'apstra-ui-common';

import {DeviceContextTree} from './DeviceContextTree';

import './DeviceContext.less';

const DeviceContext = ({deviceContext: deviceContextJSON, loaderVisible = false}) => {
  const [searchText, setSearchText] = useState('');
  const [tree, setTree] = useState({});
  const [isPlainView, setIsPlainView] = useState(false);
  const [deviceContext, setDeviceContext] = useState(null);

  useEffect(() => {
    setDeviceContext(JSON.parse(deviceContextJSON));
  }, [deviceContextJSON]);
  useEffect(() => {
    if (!searchText) {
      setTree(deviceContext);
      return;
    }
    const filterFn = (object) => {
      if (isPlainObject(object)) {
        const result = {};
        forEach(object, (value, key) => {
          if (includes(key, searchText) || includes(value, searchText)) {
            result[key] = value;
          } else {
            const filteredObject = filterFn(value);
            if (!isEmpty(filteredObject)) {
              result[key] = filteredObject;
            }
          }
        });
        return result;
      }
      if (isArray(object)) {
        const result = [];
        forEach(object, (value) => {
          if (isPlainObject(value) || isArray(value)) {
            const filteredArray = filterFn(value);
            if (!isEmpty(filteredArray)) result.push(filteredArray);
          } else if (includes(value, searchText)) {
            result.push(value);
          }
        });
        return result;
      }
      return {};
    };
    setTree(filterFn(deviceContext));
  }, [searchText, deviceContext]);

  return (
    <div className='device-context'>
      <Checkbox
        label='Show Plain View'
        checked={isPlainView}
        onChange={() => setIsPlainView(!isPlainView)}
        toggle
      />
      <Input
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        icon='search'
      />
      {loaderVisible ?
        <Loader /> :
        isPlainView ?
          <FormattedJSON data={tree} space={4} /> :
          <DeviceContextTree tree={tree} isSearch={!!searchText} isTextView={isPlainView} isRoot />}
    </div>
  );
};

export default DeviceContext;
