import {FC} from 'react';
import {map} from 'lodash';

import {LinkData} from '../types';
import {Link} from './Link';

type LinksProps = {
  links: Record<string, LinkData>,
  onClickLink?: (id: string) => void,
  showTooltipHandler?: (link) => any;
  hideTooltip?: () => void;
}

export const Links: FC<LinksProps> = ({links, onClickLink, showTooltipHandler, hideTooltip}) => {
  return (
    <>
      {map(links, ({point1, point2, sourceLink}, linkId) => (
        <Link
          key={linkId}
          x1={point1.x}
          x2={point2.x}
          y1={point1.y}
          y2={point2.y}
          down={sourceLink.endpoints[0].operation_state !== 'up' || sourceLink.endpoints[1].operation_state !== 'up'}
          onClick={() => onClickLink?.(linkId)}
          onMouseOut={hideTooltip}
          onBlur={hideTooltip}
          onMouseOver={showTooltipHandler?.(sourceLink)}
          onFocus={showTooltipHandler?.(sourceLink)}
        />
      ))}
    </>
  );
};
