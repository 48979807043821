import {Message} from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {split} from 'lodash';
import {Field, createValueRenderer} from 'apstra-ui-common';

import './descriptionRenderer.less';

export const descriptionRenderer = createValueRenderer({
  condition: ({name}) => name === 'description',
  renderValue({value}) {
    const chunks = split(value, '\n').map((chunk, index) => <p key={index}>{chunk}</p>);
    return chunks.length ? <Message className='description-message'>{chunks}</Message> : [];
  },
  renderValueInput({name, value, schema, required, disabled, errors, onChange}) {
    return (
      <Field
        className='description-field'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
      >
        <TextareaAutosize
          cacheMeasurements
          value={value}
          rows={1}
          minRows={1}
          maxRows={10}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
        />
      </Field>
    );
  }
});
