import {map, isEmpty} from 'lodash';

import FormattedPythonExpression from '../../pythonExpression/components/FormattedPythonExpression';

const ValueMap = ({value}) => {
  if (isEmpty(value)) {
    return 'Empty';
  } else {
    return map(value, (value, key) =>
      <div key={key}>
        {key}{' ⟶ '}<FormattedPythonExpression value={value} />
      </div>
    );
  }
};

export default ValueMap;
