import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Label, Popup} from 'semantic-ui-react';
import {scalePow} from 'd3';
import {round, includes} from 'lodash';

import './ResourceUsage.less';

const POOL_ELEMENT_AVAILABLE = 'pool_element_available';
const POOL_ELEMENT_IN_USE = 'pool_element_in_use';

export class ResourceUsage extends PureComponent {
  static propTypes = {
    status: PropTypes.string,
    total: PropTypes.string,
    used: PropTypes.string,
    popupDisabled: PropTypes.bool,
    percent: PropTypes.number,
    rangeColor: PropTypes.arrayOf(PropTypes.string),
    range: PropTypes.arrayOf(PropTypes.number),
    exponent: PropTypes.number,
    poolElement: PropTypes.bool,
  };

  static defaultProps = {
    statusPoolElementNameMap: {
      pool_element_absent: ['ABSENT', 'trash', 'orange'],
      pool_element_available: ['AVAILABLE', 'circle thin', 'green'],
      pool_element_in_use: ['IN USE', 'heartbeat', 'blue'],
      pool_element_invalid: ['INVALID', 'bug', 'red'],
    },
    total: '1',
    used: '0',
    popupDisabled: false,
    percent: 0,
    rangeColor: ['#84b135', '#ffb71b', '#e43d2f'],
    range: [0, 35, 100],
    exponent: 0.5,
    poolElement: false,
  };

  constructor(props) {
    super(props);

    const {rangeColor, range, exponent} = this.props;
    this.colorFn = scalePow().exponent(exponent).domain(range).clamp(true).range(rangeColor);
  }

  render() {
    const {
      props: {
        status,
        poolElement,
        statusPoolElementNameMap,
        total = '1',
        used = '0',
        popupDisabled = false,
        percent = 0
      }
    } = this;

    if (poolElement && !includes([POOL_ELEMENT_AVAILABLE, POOL_ELEMENT_IN_USE], status)) {
      return (
        <div className='resource-usage'>
          <ResourceUsageStatus status={status} statusNameMap={statusPoolElementNameMap} />
        </div>
      );
    }

    const tooltip = `${used ?? '0'} out of ${total ?? '1'}`;
    const isNotUsed = used ? used === '0' : true;
    const backgroundColor = isNotUsed ? null : this.colorFn(percent || 0);

    const barStyle = {
      backgroundColor,
      width: `${percent || 0}%`,
      minWidth: percent ? null : '1.8em',
    };

    const rounded = round(percent || 0, 2);
    const count = (!isNotUsed && rounded === 0) ? '<0.01%' : `${rounded}%`;

    return (
      <div className='resource-usage'>
        {popupDisabled &&
          <div className='info'>{tooltip}</div>}
        <Popup
          content={tooltip}
          disabled={popupDisabled}
          trigger={
            <div className='progress'>
              <div className='bar' style={barStyle}>
                <div className='count'>{count}</div>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export class ResourceUsageStatus extends PureComponent {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  static defaultProps = {
    statusNameMap: {
      creation_in_progress: ['CREATION IN PROGRESS', 'configure', 'warning'],
      marked_for_deletion: ['MARKED FOR DELETION', 'trash', 'warning'],
      not_in_use: ['NOT IN USE', 'circle thin', 'grey'],
      in_use: ['IN USE', 'heartbeat', 'green'],
    },
  };

  render() {
    const {props: {status, statusNameMap}} = this;
    const [title, icon, labelColor] = statusNameMap[status] || ['N/A', 'asterisk', null];
    return (
      <div className='resource-async-status'>
        <Label
          icon={icon}
          content={title}
          color={labelColor}
        />
      </div>
    );
  }
}
