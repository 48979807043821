import {castArray} from 'lodash';

import {Padding} from '../types';

export const getPadding = (padding: number | number[]): Padding => {
  const paddings = castArray(padding);
  return {
    top: paddings[0],
    right: paddings[1] ?? paddings[0],
    bottom: paddings[2] ?? paddings[0],
    left: paddings[3] ?? paddings[1] ?? paddings[0],
  };
};
