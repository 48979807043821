import {isEqual} from 'lodash';
import ReactJson from 'react-json-view';
import cx from 'classnames';

const WebconsData = ({
  basePath,
  document,
  updateFilters,
  simpleStyle,
}) => {
  const partitions = document?.partitions;
  const type = document?.info?.type;

  const clickHandler = ({namespace, value}) => {
    if (partitions) {
      if (!isEqual(namespace, ['partitions'])) {
        return;
      }
      updateFilters({path: `${basePath}/${value}`});
    } else if (type === 'Tac::Dir') {
      if (!isEqual(namespace, ['data'])) {
        return;
      }
      updateFilters({path: `${basePath}${document?.info?.path}/${value}`});
    }
  };

  return (
    <div className={cx('data', {partitions: !!partitions, directory: type === 'Tac::Dir'})}>
      <ReactJson
        name={false}
        src={document}
        displayObjectSize={false}
        displayDataTypes={false}
        onSelect={clickHandler}
        enableClipboard={!simpleStyle}
      />
    </div>
  );
};

export default WebconsData;
