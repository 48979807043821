import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {materialDark} from 'react-syntax-highlighter/dist/esm/styles/prism';

import {Markdown} from './Markdown';

const MarkdownFormatter = ({markdown, className}) => {
  const preStyle = materialDark['pre[class*="language-"]'];
  const codeStyle = materialDark['code[class*="language-"]'];
  return (
    <Markdown
      children={markdown}
      className={className}
      components={{
        pre({children}) {
          return (
            <pre style={preStyle}>
              {children}
            </pre>
          );
        },
        code({className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '');
          return !match ? (
            <code {...props} style={codeStyle} className={className}>
              {children}
            </code>
          ) : (
            <SyntaxHighlighter
              {...props}
              children={String(children).replace(/\n$/, '')}
              style={materialDark}
              language={match[1]}
            />
          );
        }
      }}
    />
  );
};

export default MarkdownFormatter;
