import {Component} from 'react';
import {observer} from 'mobx-react';
import {Grid, Breadcrumb} from 'semantic-ui-react';
import {Value} from 'apstra-ui-common';

import DashboardActions from './DashboardActions';
import {descriptionRenderer} from '../descriptionRenderer';

@observer
export default class DashboardCaption extends Component {
  render() {
    const {
      dashboard, breadcrumbSections, action, nextToBreadcrumbContent,
      setDashboardDeletionModalProps, setDashboardExportModalProps,
    } = this.props;

    return [
      <Grid.Row key='actions'>
        <Grid.Column width={13} verticalAlign='middle'>
          <Breadcrumb
            size='big'
            icon='right caret'
            sections={breadcrumbSections}
          />
          {nextToBreadcrumbContent}
        </Grid.Column>
        <Grid.Column width={3} textAlign='right'>
          {!action &&
            <DashboardActions
              dashboard={dashboard}
              setDashboardDeletionModalProps={setDashboardDeletionModalProps}
              setDashboardExportModalProps={setDashboardExportModalProps}
            />
          }
        </Grid.Column>
      </Grid.Row>,
      !action && dashboard.description &&
        <Grid.Row key='description'>
          <Grid.Column>
            <Value name='description' value={dashboard.description} renderers={[descriptionRenderer.renderValue]} />
          </Grid.Column>
        </Grid.Row>
    ];
  }
}
