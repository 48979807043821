import {PureComponent} from 'react';
import {scaleTime} from 'd3';
import {Axis} from '@visx/axis';
import {Group} from '@visx/group';
import {map, constant} from 'lodash';
import {formatDateAsLocalTime} from 'apstra-ui-common';

import './EventTimeline.less';

export default class EventTimeline extends PureComponent {
  static defaultProps = {
    mode: 'compact',
    eventCircleRadius: 4,
    maxHeaderLength: 140,
    dimensions: {
      compact: {
        height: 40,
        margin: {top: 1, right: 5, bottom: 1, left: 5}
      },
      expanded: {
        height: 100,
        margin: {top: 1, right: 20, bottom: 30, left: 20}
      },
    }

  };

  xScale = scaleTime();

  formatValue = (value) => {
    const {maxHeaderLength} = this.props;
    return value.length > maxHeaderLength ? value.slice(0, maxHeaderLength) + '...' : value;
  };

  render() {
    const {
      xScale,
      props: {
        mode, dimensions,
        samples, sampleTimes,
        width: chartWidth, eventCircleRadius, numTicksColumns,
        showPopup, hidePopup,
        timelineStartTime, timelineEndTime,
        valueKeyName,
      }
    } = this;
    const {height: chartHeight, margin} = dimensions[mode];
    const xMax = chartWidth - margin.left - margin.right;
    const yMax = chartHeight - margin.top - margin.bottom;
    xScale.domain([timelineStartTime, timelineEndTime]).rangeRound([0, xMax]);
    const y0 = margin.top + yMax / 2;
    return (
      <svg className='event-timeline' width={chartWidth} height={chartHeight}>
        {mode === 'expanded' && chartWidth > 0 &&
          <Axis
            axisClassName='timeline-axis axis-bottom'
            orientation='bottom'
            top={margin.top}
            left={margin.left}
            scale={xScale}
            tickFormat={formatDateAsLocalTime}
            tickLabelProps={constant({})}
            tickLength={yMax}
            numTicks={numTicksColumns}
            hideAxisLine
          />
        }
        <Group left={margin.left}>
          <line
            x1={0}
            y1={y0}
            x2={xMax}
            y2={y0}
          />
          {chartWidth > 0 && map(samples, (sample, sampleIndex) => {
            const x = xScale(sampleTimes[sampleIndex]);
            return (
              <circle
                key={sample.timestamp}
                r={eventCircleRadius}
                cx={x}
                cy={y0}
                onMouseEnter={(e) => showPopup({
                  node: e.target,
                  timestamp: sample.timestamp,
                  header: this.formatValue(sample[valueKeyName]),
                })}
                onMouseLeave={hidePopup}
              />
            );
          })}
        </Group>
      </svg>
    );
  }
}
