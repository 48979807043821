import {chain, defer, keyBy, isNil, isPlainObject} from 'lodash';

import Ept from './store/Ept';
import Link from './store/Link';
import {generateId} from './utils';

export const serviceEpts = ['batch', 'pipeline'];

const blankUserData = {
  positions: {},
  isSausage: false
};

class Sausager {
  epts;
  schemas;
  coordinates;
  nodeGetByType;

  constructor(epts = [], schemas = [], nodeGetByType = () => []) {
    this.coordinates = {};
    this.creationOrders = {};

    this.epts = keyBy(epts, 'id');
    this.schemas = keyBy(schemas, 'name');
    this.nodeGetByType = nodeGetByType;
  }

  // Destructures sausages of batches'n'pipelines into
  // the set of EPTs and links
  desausage(sausage, ept, appliedTimes = 0, ignorePositions = false) {
    let orders = 100;
    const {positions} = this.deserializeUserData(sausage.user_data);
    this.coordinates = Object.entries(positions)
      .reduce((result, [eptId, [x, y, order]]) => {
        if (!ignorePositions) result[eptId] = {x, y};
        this.creationOrders[eptId] = order || orders++;
        return result;
      }, {});
    this._connectEpts('', sausage.id, ept.epts, ept.links, appliedTimes);
  }

  // EPT's blocks coordinates are expected to be kept in user data
  // in the following format (json-deserializable):
  // {
  //   positions: {[eptId]: [x,y]},
  //   isSausage: boolean
  // }
  deserializeUserData(userData) {
    try {
      const result = Object.assign({}, blankUserData, JSON.parse(userData));
      return result;
    } catch {
    }
    return blankUserData;
  }

  // Creates related EPTs and links between them recursively
  _connectEpts(sourceId, destinationId, epts, links, appliedTimes, meta = {}) {
    if (destinationId === null) {
      // "Noop" types have been replaced with just nulls
      return;
    }

    const ept = this.epts[destinationId];
    if (!ept) throw new Error(`Unable to find the EPT id=${destinationId}`);

    switch (ept.policy_type_name) {
    case 'batch':
      // For the batch - iterate through all its subpolicies
      ept.attributes.subpolicies.forEach((id) => {
        this._connectEpts(sourceId, id, epts, links, appliedTimes);
      });
      break;
    case 'pipeline': {
      // For the pipeline:
      // * connect source to the first subpolicy
      // * proceed from the second subpolicy
      // All IDs (for batch, pipeline) must be preserved in the EPT
      // for the pre-save sausaging

      // Batch is usually goes as a second subpolicy of pipeline
      // unless there is dead end and there comes null.
      const secondEpt = this.epts[ept.attributes.second_subpolicy];

      meta = {
        pipeline: ept.id,
        batch: secondEpt?.id ?? generateId()
      };
      this._connectEpts(sourceId, ept.attributes.first_subpolicy, epts, links, appliedTimes, meta);
      if (secondEpt) {
        // If second policy is not null, process it as well
        this._connectEpts(ept.attributes.first_subpolicy, ept.attributes.second_subpolicy, epts, links, appliedTimes);
      }
      break;
    }
    default:
      // Create an EPT for any other type
      this._createConnection(sourceId, ept, epts, links, appliedTimes, meta);
    }
  }

  // Adds the EPT, converted from a sausage and links it to the source
  _createConnection(sourceId, ept, epts, links, appliedTimes, meta = {}) {
    ept.app_points_count = appliedTimes;
    const newEpt = Ept.fromSausage(ept, this);
    Object.assign(
      newEpt,
      {
        meta,
        creationOrder: this.creationOrders[ept.id] || 0
      }
    );

    if (this.coordinates[ept.id]) newEpt.moveTo(this.coordinates[ept.id], false);
    epts[ept.id] = newEpt;

    const link = new Link({from: sourceId, to: ept.id});
    links[link.id] = link;
  }

  // Make structure to fill enum ValueInputs
  _getEnumValues(isMultiple, values) {
    return isMultiple ?
      {
        type: 'array',
        items: {
          anyOf: values
        }
      } :
      {
        type: 'node',
        oneOf: values
      };
  }

  // For enumerable type controls there is a possibility to provide
  // labels list to make it displayed more user-friendly
  _makeEnumSchema(values, labels) {
    if (!values) return {};
    const titles = (labels && labels.length === values.length) ? labels : values;

    return {
      oneOf: values.map((value, index) => ({
        const: value,
        title: titles[index]
      }))
    };
  }

  // Map attribute schema into the ValueInput's one
  _mapSchemas(
    {
      name, description, type, subtype, label: title,
      enum_values: enumValues, enum_display_values: enumLabels, dict_attrs: dictSchema, node_type: nodeType,
      minimum, maximum
    },
    ept, forcedValues
  ) {
    const isMultiple = type === 'list';
    const trueType = subtype || type;

    if (nodeType) {
      // Graph node selector must be rendered as DDL:
      // nodeGetByType is async thus, return empty set at first.

      if (!forcedValues) {
        // Sometimes nodes set must be limited to the nodes chosen by user.
        // No fetching must take place in that case.
        defer(() => {
          // As soon as data is fetched, replace empty set with it
          this.nodeGetByType(nodeType)
            .then((values) => ept.setParameterSchema(
              name,
              {
                title,
                description,
                subtype,
                ...this._getEnumValues(isMultiple, values)
              }
            ));
        });
      }

      return {
        title,
        description,
        subtype,
        ...this._getEnumValues(isMultiple, forcedValues || [])
      };
    } else if (isMultiple) {
      // Render as list of primitive types or list of
      // dicts (collections of primitive types)
      const newDictSchema = (dictSchema || []).map((value) => (
        {
          ...value,
          required: !!value.mandatory
        }
      ));
      return {
        title,
        description,
        type,
        subtype,
        dictSchema: newDictSchema
      };
    } else {
      // Default type is Input or Dropdown List with single selection
      return {
        title,
        description,
        type: trueType,
        minimum,
        maximum,
        dictSchema,
        ...this._makeEnumSchema(enumValues, enumLabels)
      };
    }
  }

  // Extends template's arguments with the schema information
  combineParametersWithSchema(
    {policy_type_name: schemaName, attributes: values},
    ept, useDefaults
  ) {
    if (serviceEpts.includes(schemaName)) {
      // Service policies cannot be user-parametrised
      return {};
    }

    const isApplied = ept.appliedTimes > 0;
    const schema = this.schemas[schemaName] || {};
    ept.schemaLabel = schema.label || schema.name;
    ept.parameters = (schema?.arguments ?? [])
      .reduce((result, argument) => {
        const {
          name, type, default_value: defaultValue, immutable,
          mandatory: required, placeholder, on, state = ''
        } = argument;
        const isMultiple = type === 'list';
        const disabled = isApplied && immutable;

        const parameter = values[argument.name];
        const [storedValue, forcedValues] = isPlainObject(parameter) ?
          [parameter.value, parameter.values] :
          [parameter, undefined];

        const valueIsEmpty = isNil(storedValue);

        // Default values can only be applied to newly added primitives.
        // If a user decides to leave parameter unset, no default value must apply.
        let value;
        switch (type) {
        case 'boolean':
          value = valueIsEmpty ? !!defaultValue : !!storedValue;
          break;
        case 'integer':
        case 'number':
          value = valueIsEmpty ?
            ((useDefaults && !isNil(defaultValue)) ? defaultValue : (isMultiple ? [] : '')) :
            storedValue;
          break;
        default:
          value = valueIsEmpty ?
            (useDefaults && defaultValue) || (isMultiple ? [] : '') :
            storedValue;
        }

        result[name] = {
          name,
          value,
          required,
          disabled,
          placeholder,
          schema: this._mapSchemas(argument, ept, forcedValues),
          on,
          state
        };
        return result;
      }, {});
  }

  // Generates json out of the EPTs and links suitable for the API call
  sausage(ept) {
    const result = [];
    Object.entries(ept.epts).forEach(([id, subEpt]) => {
      if (!id || ept.hasConnections(id)) result.push(...this._sausageEpt(id, subEpt, ept));
    });
    return result;
  }

  // Converts a single EPT into the EPT-batch-pipeline triplet
  // restoring IDs information from Ept.meta property
  _sausageEpt(id, ept, activeEpt) {
    const {epts, links} = activeEpt;
    if (id) {
      // Endpoint template: create EPT+Batch+Pipeline triplet
      const {batch, pipeline} = ept.meta;

      const outgoingLinks = this._getOutgoingLinks(id, epts, links);
      const pipelinedEpt = outgoingLinks.length > 0 ?
        // Batch if there are outgoing links
        this._createBatch(
          batch,
          `${ept.title} (batch)`,
          ept.description,
          this._getOutgoingLinks(ept.id, epts, links)
        ) :
        // Noop (null) otherwise
        null;
      return [
        // An EPT
        {
          id: ept.id,
          label: ept.title,
          description: ept.description,
          policy_type_name: ept.dbType,
          attributes: this._cleanParameters(ept.parameters),
          visible: false
        },
        // Pipeline
        this._createPipeline(
          pipeline,
          `${ept.title} (pipeline)`,
          ept.description,
          ept.id,
          pipelinedEpt?.id ?? null
        ),
        ...(pipelinedEpt ? [pipelinedEpt] : [])
      ];
    } else {
      // Application point: only top-level batch must be created
      const applicationPoint = this._createBatch(
        activeEpt.id,
        activeEpt.title,
        activeEpt.description,
        this._getOutgoingLinks('', epts, links),
        true
      );
      // Collect all block coordinates and serialize them to json
      applicationPoint.user_data = this._serializeUserData(activeEpt);
      applicationPoint.tags = activeEpt.tags;
      return [applicationPoint];
    }
  }

  // Serializes user data:
  // * positions - coordinates of the every single EPT block on the canvas
  // * isSausage - flag that distinguishes EPTs created by EPT builder from ones
  //               created via API (non-sausages)
  _serializeUserData({epts}) {
    return JSON.stringify({
      isSausage: true,
      positions: chain(epts)
        .sortBy('creationOrder')
        .transform((result, {id, position: {x, y}}, index) => {
          if (id) result[id] = [x, y, index + 1];
          return result;
        }, {})
        .value()
    });
  }

  // Remove schema information from the parameters
  // leaving key: value only
  _cleanParameters(parameters) {
    return Object.keys(parameters).reduce((result, key) => {
      const {value, schema: {type}} = parameters[key];
      result[key] = type === 'boolean' ?
        value :
        ((value || value === 0) ? value : null);
      return result;
    }, {});
  }

  // Retrieving meta information for all sibling EPTs for the given one
  _getOutgoingLinks(id, epts, links) {
    return Object.values(links)
      .filter((link) => link.from === id)
      .map((link) => epts[link.to].meta.pipeline);
  }

  // Batch template creation
  _createBatch(id, label, description, siblingsIds, visible = false) {
    return {
      id,
      label,
      description,
      policy_type_name: 'batch',
      attributes: {
        subpolicies: [...siblingsIds]
      },
      visible
    };
  }

  // Pipeline template creation
  _createPipeline(id, label, description, firstId, secondId) {
    return {
      id,
      label,
      description,
      policy_type_name: 'pipeline',
      attributes: {
        first_subpolicy: firstId,
        second_subpolicy: secondId
      },
      visible: false
    };
  }
}

export default Sausager;
