import {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import {observable, action, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import xmlLang from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import {Modal, Button, Message, Segment} from 'semantic-ui-react';
import {
  request, interpolateRoute,
  FormattedJSON, FetchDataError, ActionsMenu, DropdownControl, CodeEditorControl
} from 'apstra-ui-common';
import {map, defer} from 'lodash';
import cx from 'classnames';
import copy from 'copy-to-clipboard';

import './ExecuteCommandModal.less';

SyntaxHighlighter.registerLanguage('xml', xmlLang);

const routes = {
  fetchCommand: '/api/telemetry/fetchcmd',
  fetchCommandResult: '/api/telemetry/fetchcmd/<request_id>'
};

@observer
export default class ExecuteCommandModal extends Component {
  static propTypes = {
    systemId: PropTypes.string.isRequired,
    pollingInterval: PropTypes.number.isRequired,
  };

  static defaultProps = {
    header: 'Execute CLI Command',
    pollingInterval: 3000,
  };

  @observable command = '';
  @observable mode = 'text';
  @observable loading = false;
  @observable error = null;
  @observable result = null;
  currentRequestId = null;
  commandInputRef = createRef();

  @action
  resetState = () => {
    this.command = '';
    this.mode = 'text';
    this.loading = false;
    this.error = null;
    this.result = null;
  };

  @action
  onCommandChange = (value) => {
    this.command = value;
  };

  @action
  onModeChange = (value) => {
    this.mode = value;
  };

  @action
  execute = () => {
    if (this.loading || !this.command) return;
    this.loading = true;
    this.error = null;
    this.result = null;
    (async () => {
      this.currentRequestId = null;
      try {
        const result = await request(routes.fetchCommand, {
          method: 'POST',
          body: JSON.stringify({
            system_id: this.props.systemId,
            output_format: this.mode,
            command_text: this.command
          })
        });
        this.currentRequestId = result.request_id;
      } catch (e) {
        runInAction(() => {
          this.loading = false;
          this.error = e;
        });
      }
      if (this.currentRequestId) this.fetchResult();
    })();
  };

  fetchResult = async () => {
    try {
      const result = await request(
        interpolateRoute(routes.fetchCommandResult, {requestId: this.currentRequestId}),
        {method: 'GET', queryParams: {keep: true}}
      );
      runInAction(() => {
        this.loading = false;
        if (result.result === 'processingError') {
          this.error = new Error(result.output);
        } else {
          this.result = result;
          this.result.mode = this.mode;
        }
      });
    } catch (e) {
      if (e.response?.status === 404) {
        this.fetchResultTimeout = setTimeout(this.fetchResult, this.props.pollingInterval);
        return;
      } else {
        runInAction(() => {
          this.loading = false;
          if (e.response?.status === 410) {
            this.error = new Error('Device is not reachable');
          } else {
            this.error = e;
          }
        });
      }
    }
    this.cleanupResult();
    defer(() => this.commandInputRef.current.focus());
  };

  cleanupResult = () => {
    if (this.currentRequestId) {
      request(interpolateRoute(routes.fetchCommandResult, {requestId: this.currentRequestId}), {method: 'DELETE'});
      this.currentRequestId = null;
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {header, systemId, managementIp, hostname, ...props} = this.props;
    delete props.pollingInterval;
    return (
      <Modal
        className='execute-command-modal'
        closeIcon={!this.loading}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onMount={this.resetState}
        onClose={this.cleanupResult}
        size='large'
        {...props}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <div className='system-info'>
            {map(
              {'S/N': systemId, 'Management IP': managementIp, Hostname: hostname},
              (value, name) =>
                value ? <div key={name}><div>{name}{': '}</div>{' '}<div>{value}</div>{' '}</div> : null
            )}
          </div>
          <div className='command-line'>
            <CodeEditorControl
              ref={this.commandInputRef}
              mode='junos-cli'
              disabled={this.loading}
              value={this.command}
              onChange={this.onCommandChange}
              commands={[{
                name: 'submit',
                bindKey: 'Enter',
                exec: this.execute,
              }]}
              enableCompletion
              completerParams={{systemId}}
            />
            <DropdownControl
              disabled={this.loading}
              options={[
                {key: 'text', value: 'text', text: 'Text Mode'},
                {key: 'xml', value: 'xml', text: 'XML Mode'},
                {key: 'json', value: 'json', text: 'JSON Mode'},
              ]}
              value={this.mode}
              onChange={this.onModeChange}
            />
            <Button
              primary
              content='Execute'
              icon='play'
              onClick={this.execute}
              disabled={this.loading || !this.command}
            />
          </div>
          {this.error ?
            <FetchDataError error={this.error} />
          : (this.loading || this.result) ?
            <div className='execution-result'>
              <Segment
                className={cx({error: !this.loading && this.result?.result !== 'success'})}
                inverted={this.result?.mode === 'text'}
                loading={this.loading}
                content={
                  !this.loading ?
                    <CommandOutput mode={this.result.mode} output={this.result.output} />
                  : ''
                }
              />
            </div>
          :
            <Message
              info
              icon='info circle'
              content='Only "show" commands are supported'
            />
          }
        </Modal.Content>
      </Modal>
    );
  }
}

@observer
export class CommandOutput extends Component {
  static propTypes = {
    output: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['text', 'xml', 'json']),
  };

  static defaultProps = {
    mode: 'text',
  };

  render() {
    const {output, mode} = this.props;
    return (
      <>
        {
          mode === 'json' ?
            <FormattedJSON json={output} />
          : mode === 'xml' ?
            <SyntaxHighlighter language='xml'>{output}</SyntaxHighlighter>
          : output
        }
        {output &&
          <ActionsMenu
            items={[{
              icon: 'copy',
              title: 'Copy To Clipboard',
              popupPosition: 'bottom center',
              onClick: () => copy(output)
            }]}
          />
        }
      </>
    );
  }
}
