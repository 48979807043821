import {observer} from 'mobx-react';

import ConnectionPoint from './ConnectionPoint';

const ApplicationPoint = ({data, disabled, activeEpt}) => {
  return [
    <ConnectionPoint
      key='cp'
      isInput={false}
      position={data.position}
      types={data.outputTypes}
      payload=''
      isMultiple
      isAnyAccepted
      disabled={disabled}
      activeEpt={activeEpt}
    />,
    <text
      key='label'
      x={data.position.x + 15}
      y={data.position.y - 3}
    >
      {'Application Point'}
    </text>
  ];
};

export default observer(ApplicationPoint);
