import {observer} from 'mobx-react';
import {Button} from 'semantic-ui-react';
import cx from 'classnames';

import './HoverableButton.less';

// Standard semantic-ui button does not generate onHover events in disabled state
// and that's why no popup-based tooltips are visible.
// Given wrapper just removes onClick handler and applies corresponding styles for
// disabled state without actual disabling of the button
const HoverableButton = ({onClick, disabled, className, ...props}) => {
  const classNames = cx(className, 'still-hoverable', {disabled});
  const handler = disabled ? undefined : onClick;

  return <Button {...props} onClick={handler} className={classNames} disabled={false} />;
};

export default observer(HoverableButton);
