import {Text} from '@visx/text';
import {observer} from 'mobx-react';

import Draggable from '../../eptBuilder/components/Draggable';

import './Zone.less';

const Zone = ({zone, x, y}) => {
  const {label, labelY} = useZone(zone, y);

  return (
    <g className='rack-editor-zone'>
      {
        !zone.isLast &&
          <Draggable
            relative
            onMove={({y}) => zone.resizeBy(y)}
          >
            <line
              className='outline'
              x1={-10000}
              x2={10000}
              y1={y}
              y2={y}
            />
            <line
              x1={-10000}
              x2={10000}
              y1={y}
              y2={y}
            />
          </Draggable>
      }
      <Text
        x={20 - x}
        y={labelY}
      >
        {label}
      </Text>
    </g>
  );
};

export default observer(Zone);

const useZone = (zone, y) => {
  const labelY = (zone.isEmpty || (!zone.isFirst && !zone.isLast)) ?
    (y + 5 - zone.size / 2) :
    zone.isLast ? zone.y + 30 : y - 30;

  const label = zone.label + (zone.isEmpty ? '' : `,  (${zone.nodesCount})`);

  return {label, labelY};
};
