import {map, some} from 'lodash';
import {FC} from 'react';
import {Divider, Header, Icon, Label, Popup} from 'semantic-ui-react';

import {ProcessorDefinition} from '../../types';
import {ProcessorExample} from './ProcessorExample';

import './ProcessorExamplesPanel.less';

type ProcessorExamplesPanelProps = {
  processorDefinition: ProcessorDefinition;
};

export const ProcessorExamplesPanel: FC<ProcessorExamplesPanelProps> = ({
  processorDefinition
}) => {
  return some(processorDefinition.examples) ?
    <div>
      <Header as='h5'>{'Examples:'}</Header>
      <div className='processor-examples-panel'>
        {map(processorDefinition.examples,
          (example) => <Popup
            key={example.name}
            className='processor-example-popup'
            content={<ProcessorExample processorDefinition={processorDefinition} processorExample={example} />}
            inverted={false}
            position='bottom center'
            trigger={
              <Label size='tiny'>
                <Icon name='eye' />
                {example.name}
              </Label>
            }
          />
        )}
      </div>
      <Divider />
    </div>
    : null;
};
