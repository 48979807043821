import {useContext} from 'react';
import {isEmpty, last, map, transform} from 'lodash';
import {DropdownControl} from 'apstra-ui-common';

import QueryBuilderContext from './QueryBuilderContext';
import {GRAPH_QUERY_METHODS, GRAPH_QUERY_METHODS_MAP} from '../pythonExpression/consts';

const QueryMethod = ({query}) => {
  const {addNode} = useContext(QueryBuilderContext);
  const {name, path} = query;
  const lastId = isEmpty(path) ? name : last(path).name;
  const descriptionMap = transform(GRAPH_QUERY_METHODS, (result, {name, description}) => {
    result[name] = description;
  }, {});
  const methodOptions = map(GRAPH_QUERY_METHODS_MAP[lastId], (method) => ({
    key: method, value: method, text: method, description: descriptionMap[method]
  }));
  return (
    <div className='query-brick'>
      <DropdownControl
        value=''
        options={methodOptions}
        onChange={(name) => {
          const value = {name};
          if (name === 'having') {
            value.query = {name: null};
          } else if (name === 'where') {
            value.predicate = '';
          } else if (name !== 'ensure_different') {
            value.type = '';
          }
          addNode(query, 'path', value);
        }}
        placeholder='Add Method'
        fluid
      />
    </div>
  );
};

export default QueryMethod;
