import {Header} from 'semantic-ui-react';
import {FormFragment, DateFromNow} from 'apstra-ui-common';
import {useMemo, useState} from 'react';
import {find, forEach, transform} from 'lodash';

import {
  renderCombineTimeInput, renderDatetimeInput, renderDurationInput, renderProbeInput
} from './reportFormRenderers';
import {EllipsisPopup} from '../../../components/EllipsisPopup';
import PredefinedProbeModal from '../PredefinedProbeModal';
import ProbeExternalLink from './ProbeExternalLink';

import './ReportFilters.less';

const ReportFilters = ({
  predefinedProbeIds, probeList, blueprintId, loaderVisible, onChange, parametersFormSchema,
  probeFormSchema, useCombineTime, errors, values,
}) => {
  const probeOptionsByName = useMemo(() => {
    return transform(predefinedProbeIds, (result, probeIds, probeName) => {
      forEach(probeIds, (date, probeId) => {
        const probe = find(probeList, ({id}) => probeId === id);
        const option = {key: probeId, text: probeId, value: probeId};
        const dateSince = new Date(date);
        const updatedAt = new Date(probe?.updated_at);
        if (probe) {
          option.text = probe.label;
          option.content = (
            <>
              <span className='description'>
                {'Data since: '}
                <DateFromNow date={dateSince} />
                {' / Updated at: '}
                <DateFromNow date={updatedAt} />
              </span>
              <span className='text'>
                <EllipsisPopup
                  content={probe.label}
                  trigger={
                    <span>{probe.label}</span>
                  }
                />
              </span>
              <ProbeExternalLink blueprintId={blueprintId} probeId={probeId} />
            </>
          );
        }
        result[probeName] = result[probeName] || [];
        result[probeName].push(option);
      });
    }, []);
  }, [predefinedProbeIds, probeList, blueprintId]);

  const [predefinedProbeModalProps, setPredefinedProbeModalProps] = useState(false);
  const openPredefinedProbeModal = (predefinedProbeName) => {
    setPredefinedProbeModalProps({open: true, defaultPredefinedProbeName: predefinedProbeName});
  };

  return (
    <div className='report-filters'>
      <Header as='h4' content='Parameters' />
      <FormFragment
        schema={parametersFormSchema}
        values={values}
        errors={errors?.parameters}
        renderers={[
          renderCombineTimeInput,
          renderDatetimeInput,
          renderDurationInput,
        ]}
        onChange={onChange}
        useCombineTime={useCombineTime}
        disabled={loaderVisible}
      />
      <Header as='h4' content='Predefined Probes' />
      <FormFragment
        schema={probeFormSchema}
        values={values}
        errors={errors?.predefined_probes}
        renderers={[
          renderProbeInput,
        ]}
        onChange={onChange}
        probeOptionsByName={probeOptionsByName}
        disabled={loaderVisible}
        openPredefinedProbeModal={openPredefinedProbeModal}
      />
      <PredefinedProbeModal
        open={false}
        onClose={() => setPredefinedProbeModalProps({open: false})}
        {...predefinedProbeModalProps}
      />
    </div>
  );
};

export default ReportFilters;
