import {concat, every, filter, forEach, isFinite, last, map, mapValues, max, orderBy, sum, toPairs, transform,
  union, isEmpty} from 'lodash';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Component} from 'react';
import {withResizeDetector} from 'react-resize-detector';

import GraphCanvas from './GraphCanvas';
import {nodeWidth, processorXPadding, processorYPadding} from './consts';

@withResizeDetector
@observer
export default class TreeGraphCanvas extends Component {
  static defaultProps = {
    width: 0,
    height: 0,
    buttonHeight: 30,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get graphLines() {
    const {children, parents} = this.props.graph;
    const nodes = mapValues(this.props.graph.nodes, (node) => ({...node}));
    const roots = filter(nodes, ({id}) => !parents[id]);
    const subgraphLength = {};

    let graphId = 0;
    const markNodeById = (nodeId) => {
      const node = nodes[nodeId];
      if (isFinite(node.graphId)) return;
      subgraphLength[graphId] = (subgraphLength[graphId] || 0) + 1;
      node.graphId = graphId;
      const neighborIds = concat(children[node.id] ?? [], parents[node.id] ?? []);
      forEach(neighborIds, markNodeById);
    };

    // mark all unlinked subgraph nodes with id
    forEach(roots, (root) => {
      if (isFinite(root.graphId)) return;
      markNodeById(root.id);
      graphId++;
    });

    // mark cyclic graphs
    forEach(nodes, (node, nodeId) => {
      if (isFinite(node.graphId)) return;
      markNodeById(nodeId);
      graphId++;
    });

    const subGraphIdLengths = orderBy(toPairs(subgraphLength), ['1'], ['desc']);
    const lines = [];
    const visited = new Set();
    let y = processorYPadding;
    forEach(subGraphIdLengths, ([id]) => {
      let line = filter(roots, {graphId: +id});

      // for cyclic graphs
      if (!line.length) line = filter(nodes, {graphId: +id});
      while (line.length) {
        forEach(line, ({id}) => visited.add(id));
        const width = sum(map(line, ({size}, i) => size.width + (i ? processorXPadding : 0)));
        const height = max(map(line, ({size}) => size.height));
        lines.push({nodes: line, width, height, y});
        y += height + processorYPadding;
        line = filter(
          map(union(...map(line, ({id}) => children[id] ?? [])), (id) => nodes[id]),
          ({id}) => every(parents[id], (p) => visited.has(p) && !visited.has(id))
        );
        if (!line.length) line = filter(nodes, (node) => node.graphId === +id && !visited.has(node.id));
      }
    });
    return lines;
  }

  @computed
  get graphWidth() {
    return max(map(this.graphLines, 'width')) + 2 * processorXPadding;
  }

  @computed
  get graphHeight() {
    const lastLine = last(this.graphLines);
    return this.props.buttonHeight +
      (lastLine ? lastLine.y + lastLine.height + processorYPadding : 2 * processorYPadding);
  }

  @computed
  get graphNodes() {
    const {graphLines, graphWidth} = this;
    const maxLineNodes = max(map(graphLines, ({nodes}) => nodes.length));
    return transform(graphLines, (acc, line) => {
      const {nodes, y, width} = line;
      const nodesCopy = map(nodes, ({content, connectors, id, isDraggable, size}) => (
        {
          content,
          connectors,
          id,
          size,
          isDraggable
        }
      ));
      let x = (graphWidth - width) / 2;
      // eslint-disable-next-line no-bitwise
      if ((nodes.length ^ maxLineNodes) & 1) {
        x -= 0.5 * (nodeWidth + processorXPadding);
      }
      forEach(nodesCopy, (node) => {
        node.y = y;
        node.x = x;
        x += node.size.width + processorXPadding;
      });
      acc.push(...nodesCopy);
    }, []);
  }

  render() {
    const {targetRef} = this.props;
    return !isEmpty(this.props.graph.nodes) &&
      <GraphCanvas
        nodes={this.graphNodes}
        links={this.props.graph.links}
        width={this.graphWidth}
        height={this.graphHeight}
        targetRef={targetRef}
        onConnectLinks={this.props.onConnectLinks}
        onLinkClose={this.props.onLinkClose}
        editable={this.props.editable}
      />;
  }
}
