import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {HashRouter as Router} from 'react-router-dom';
import {clamp, get, map, max, min} from 'lodash';
import {select, zoom, zoomIdentity, ZoomTransform} from 'd3';
import cx from 'classnames';
import pluralize from 'pluralize';

import wrapWithComponent from '../../../wrapWithComponent';
import {ZoomArea, usePanAreaSize, zoomConstrain} from '../../../cablingMapEditor/components/ZoomArea';
import {GraphPreview} from '../../../cablingMapEditor/components/GraphPreview';
import {usePreviewNodes, useTopologyData, useTopologyViewModel} from './hooks';
import {CollapsedGenerics, CollapsedGenericsPattern, Interfaces, Links, Node, Planes, Vlans} from './drawables';
import {withTopologySettings} from './components';
import {COLLAPSED_EXTERNAL_GENERICS_ID} from './consts';
import {TooltipPopup, TooltipProvider, useInterfaceTooltip, useLinkTooltip, useNodeTooltip,
  useTooltip} from '../GraphTooltips';
import {IAccessGroup, ILink, INode, IPlane, IPod, IRack, ISecurityZone, IVlan, TopologySettingsType} from './types';

import './DatacenterTopology.less';

export type DatacenterTopologyProps = {
  userStoreKey?: string;
  zoomStep?: number,
  minZoom?: number,
  maxZoom?: number,
  minHeight?: number,
  maxHeight?: number;
  panPadding?: number,
  isCollapsed?: boolean,
  isFiveStage?: boolean,
  showLinks?: boolean,
  expandNodes?: boolean,
  expandExternalGenerics?: boolean;
  toggledNodes?: string[],
  showSpines?: boolean,
  showSuperspines?: boolean,
  showLeafs?: boolean,
  shouldRenderRackDevices?: boolean,
  basicSettings?: boolean,
  hideSettings?: boolean,
  persistSettings?: boolean,
  hideExpandNodes?: boolean,
  selectedPodId?: string,
  selectedPlaneId?: string,
  selectedRackId?: string,
  selectedAccessGroupId?: string,
  selectedNodeId?: string,
  links: ILink[],
  nodes: INode[],
  planes: IPlane[],
  pods: IPod[],
  racks: IRack[],
  securityZones?: ISecurityZone[],
  vlans?: IVlan[];
  accessGroups: IAccessGroup[],
  labelKey?: string,
  layer?: string,
  rackPreview?: boolean,
  onClickLink?: (id: string) => void,
  onClickNode?: (node: INode) => void,
  onClickPlane?: (id: string) => void,
  onClickPod?: (id: string) => void,
  onClickRack?: (id: string) => void,
  onClickAccessGroup?: (id: string) => void,
};

const DatacenterTopology: FC<DatacenterTopologyProps> = ({
  zoomStep = 1.1, minZoom = 0.2, maxZoom = 1, labelKey = 'label', maxHeight = 780, minHeight = 200,
  planes,
  pods,
  racks,
  accessGroups,
  nodes,
  securityZones, vlans,
  isFiveStage, showLinks, expandNodes, toggledNodes, showSuperspines, showSpines, showLeafs, shouldRenderRackDevices,
  expandExternalGenerics,
  selectedPodId,
  selectedRackId,
  selectedPlaneId,
  links: rawLinks,
  rackPreview,
  onClickLink,
  onClickNode,
  onClickPlane,
  onClickPod,
  onClickRack,
  onClickAccessGroup,
}) => {
  const canvasZoom = useMemo(() => zoom(), []);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const transformGroupRef = useRef<HTMLDivElement | null>(null);
  const {sharedTooltip} = useTooltip();
  const showLinkTooltipHandler = useLinkTooltip();
  const showNodeTooltipHandler = useNodeTooltip();
  const showInterfaceTooltipHandler = useInterfaceTooltip();
  const {width, ref} = useResizeDetector();
  const [zoomTransition, setZoomTransition] = useState(false);
  const topologySettings: TopologySettingsType = {
    showLinks, expandNodes, expandExternalGenerics, toggledNodes, showSuperspines, showSpines, showLeafs
  };
  const topologyData = useTopologyData({
    accessGroups, nodes, racks, planes, pods, links: rawLinks, securityZones, vlans, topologySettings,
    selectedPlaneId,
  });
  const canSelectShowSpines = !!selectedRackId;
  const canSelectShowSuperspines = !!selectedPodId && !selectedRackId && !!isFiveStage;
  const topologyViewModel = useTopologyViewModel(topologyData, topologySettings, {
    showSuperspines: (!canSelectShowSuperspines && !canSelectShowSpines) ||
      (!!topologySettings.showSuperspines),
    showSpines: !canSelectShowSpines ||
      (topologySettings.showSpines || ((!isFiveStage || selectedPodId) && !selectedRackId) || !!showSpines),
    shouldRenderRackDevices,
  });

  const height = clamp(
    Math.ceil(max([
      topologyViewModel.layout.height * clamp(width! / topologyViewModel.layout.width!, 0, 1),
      topologyViewModel.layout.height
    ])!),
    minHeight,
    maxHeight
  ) || minHeight;
  const previewNodes = usePreviewNodes(topologyViewModel.nodes, topologyViewModel.collapsedGenerics);

  const setZoomTransformation = useCallback((transform) => {
    select(ref.current)
      .call(canvasZoom.transform, transform);
  }, [canvasZoom, ref]);

  const zoomIn = useCallback(() => {
    select(ref.current)
      .call(canvasZoom.scaleBy, zoomStep);
  }, [canvasZoom, ref, zoomStep]);

  const zoomOut = useCallback(() => {
    select(ref.current)
      .call(canvasZoom.scaleBy, 1 / zoomStep);
  }, [canvasZoom, ref, zoomStep]);

  const resetZoom = useCallback(() => {
    const centerX = 0.5 * (width! - topologyViewModel.layout.width);
    setZoomTransformation(new ZoomTransform(1, centerX ?? 0, 0));
  }, [setZoomTransformation, topologyViewModel.layout.width, width]);

  const {panConstraints, minZoom: graphMinZoom} = usePanAreaSize({
    viewWidth: width, viewHeight: height,
    graphLeft: topologyViewModel.layout.left,
    graphWidth: topologyViewModel.layout.width,
    graphTop: topologyViewModel.layout.top,
    graphHeight: topologyViewModel.layout.height,
  });

  const zoomFullGraph = useCallback(() => {
    const transform = zoomIdentity
      .scale(graphMinZoom! < 1 ? graphMinZoom : 1)
      .translate(-panConstraints[0][0], -panConstraints[0][1]);
    setZoomTransformation(transform);
  }, [graphMinZoom, panConstraints, setZoomTransformation]);

  const enableZoom = (panConstraints[1][0] - panConstraints[0][0] > width!) ||
    (panConstraints[1][1] - panConstraints[0][1] > height!);
  useEffect(() => {
    if (!width) return;
    canvasZoom
      .scaleExtent([min([minZoom, graphMinZoom]), maxZoom])
      .translateExtent(panConstraints)
      .constrain(zoomConstrain)
      .filter(() => enableZoom)
      .on('zoom', (event) => {
        const {transform} = event;
        select(transformGroupRef.current)
          .style('transform', `translate(${transform.x}px,${transform.y}px) scale(${transform.k})`);
        sharedTooltip.hide();
      });
    select(ref.current)
      .call(canvasZoom);
    resetZoom();
  }, [canvasZoom, maxZoom, minZoom, graphMinZoom, panConstraints, ref,
    width, resetZoom, enableZoom, sharedTooltip]);

  const showInterfaces = topologySettings.showLinks && topologySettings.expandNodes;

  return (
    <div
      className='datacenter-topology'
    >
      <div
        ref={ref}
        className='datacenter-topology-container'
        style={{height}}
      >
        <TooltipPopup />
        <div
          ref={transformGroupRef}
          className={cx('topology-transformer', {'zoom-transition': zoomTransition})}
        >
          <svg
            ref={svgRef}
            width={topologyViewModel.layout.width}
            height={topologyViewModel.layout.height}
          >
            <CollapsedGenericsPattern />
            {!rackPreview && (
              <>
                <Planes planeRole='plane' planes={topologyViewModel.planes} onClick={onClickPlane} />
                <Planes planeRole='pod' planes={topologyViewModel.pods} onClick={onClickPod} />
                <Planes planeRole='rack' planes={topologyViewModel.racks} onClick={onClickRack} />
                <Planes planeRole='access-group' planes={topologyViewModel.accessGroups} onClick={onClickAccessGroup} />
                <Planes planeRole='simple-group-box' planes={topologyViewModel.simpleGroupBoxes} />
              </>
            )}
            {topologySettings.showLinks && <Links
              links={topologyViewModel.links}
              onClickLink={onClickLink}
              showTooltipHandler={showLinkTooltipHandler}
              hideTooltip={sharedTooltip.hide}
            />}
            <Vlans vlans={topologyViewModel.vlans} />
            {map(topologyViewModel.nodes, ({sourceNode, className, testDataAttributes, icon, ...layout}, id) => (
              <Node
                key={id}
                label={get(sourceNode, labelKey)}
                fallbackLabel={sourceNode?.label || sourceNode?.role}
                className={className}
                onClick={() => onClickNode?.(topologyData.indexedRawNodes[id])}
                onMouseOut={sharedTooltip.hide}
                onBlur={sharedTooltip.hide}
                onMouseOver={showNodeTooltipHandler(sourceNode)}
                onFocus={showNodeTooltipHandler(sourceNode)}
                testDataAttributes={testDataAttributes}
                icon={icon}
                {...layout}
              />))}
            {map(topologyViewModel.collapsedGenerics, ({count, testDataAttributes, ...layout}, id) => (
              <CollapsedGenerics
                key={id}
                label={
                  `${count} ${pluralize(id === COLLAPSED_EXTERNAL_GENERICS_ID ? 'external generic' : 'generic', count)}`
                }
                testDataAttributes={testDataAttributes}
                {...layout}
              />))}
            {showInterfaces && <Interfaces
              nodeInterfaces={topologyViewModel.interfaces}
              showTooltipHandler={showInterfaceTooltipHandler}
              hideTooltip={sharedTooltip.hide}
            />}
          </svg>
        </div>
      </div>
      <ZoomArea
        zoom={canvasZoom}
        minX={panConstraints[0][0]}
        minY={panConstraints[0][1]}
        maxX={panConstraints[1][0]}
        maxY={panConstraints[1][1]}
        width={width}
        height={height}
        onTransformChange={setZoomTransformation}
        setZoomTransition={setZoomTransition}
        resetZoom={resetZoom}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomFullGraph={zoomFullGraph}
      >
        <GraphPreview
          nodes={previewNodes}
          minX={panConstraints[0][0]}
          minY={panConstraints[0][1]}
          maxX={panConstraints[1][0]}
          maxY={panConstraints[1][1]}
        />
      </ZoomArea>
    </div>
  );
};

const DatacenterTopologyWithSettings = wrapWithComponent(TooltipProvider, {}, true)(
  withTopologySettings(DatacenterTopology, {userStoreKey: 'topology'})
);

export const DatacenterTopologyWithRouter = wrapWithComponent(Router)(DatacenterTopologyWithSettings);

export {DatacenterTopologyWithSettings as DatacenterTopology};
