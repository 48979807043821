import {Children, cloneElement, useEffect, useMemo, useState} from 'react';
import {Grid} from 'semantic-ui-react';
import Draggable from 'react-draggable';
import {forEach, range, fill, isEqual, map, filter} from 'lodash';
import {useResizeDetector} from 'react-resize-detector';
import {usePrevious} from 'react-use';

import './ResizableColumns.less';

const ResizableColumns = ({
  children, minWidth = 200, initialWidth, gridProps = {}, header, rowProps = {},
}) => {
  const filteredChildren = useMemo(() => {
    return Children.toArray(children).filter(Boolean);
  }, [children]);
  const count = Children.count(filteredChildren);
  const [widths, setWidths] = useState([]);
  const {width: containerWidth, ref: containerRef} = useResizeDetector();
  const prevInitialWidth = usePrevious(initialWidth);

  const resizeColumn = (indexKey, deltaX) => {
    const nextIndexKey = indexKey + 1;
    const updated = [...widths];
    const percentDelta = deltaX / containerWidth * 100;
    const minPercentWidth = minWidth / containerWidth * 100;
    if (
      (updated[indexKey] + percentDelta > minPercentWidth) &&
      (updated[nextIndexKey] - percentDelta > minPercentWidth)
    ) {
      updated[indexKey] += percentDelta;
      updated[nextIndexKey] += -percentDelta;
      setWidths(updated);
    }
  };
  useEffect(() => {
    if (!initialWidth || initialWidth.length !== count) {
      setWidths(fill(Array(count), containerWidth ? (100 / count) : 0));
    } else {
      const segment = 100 / 16;
      const widths = [];
      forEach(range(count), (index) => {
        widths.push(initialWidth[index] * segment);
      });
      if (!isEqual(prevInitialWidth, initialWidth)) setWidths(widths);
    }
  }, [initialWidth, prevInitialWidth, containerWidth, count]);

  return count ? (
    <div ref={containerRef} className='resizable-columns'>
      <Grid {...gridProps}>
        {header && (
          <Grid.Row className='header'>
            {map(filter(header), ({label, options = {}}, index) => index < count ? [
              <Grid.Column
                key={index}
                style={{width: widths[index] ? `${widths[index]}%` : undefined}}
                {...options}
              >
                <span>{label}</span>
              </Grid.Column>,
              index + 1 < count &&
                <DraggableCellResizer
                  key={`resizer-${index}`}
                  resizeColumn={resizeColumn}
                  index={index}
                />
            ] : null)}
          </Grid.Row>
        )}
        <Grid.Row {...rowProps}>
          {Children.map(filteredChildren, (element, index) => {
            const elements = [
              cloneElement(element, {
                style: {width: widths[index] ? `${widths[index]}%` : undefined, overflow: 'auto'}
              })
            ];
            if (index + 1 < count) {
              elements.push(
                <DraggableCellResizer
                  key={`resizer-${index}`}
                  resizeColumn={resizeColumn}
                  index={index}
                />
              );
            }
            return elements;
          })}
        </Grid.Row>
      </Grid>
    </div>
  ) : null;
};

const DraggableCellResizer = ({index, resizeColumn}) => (
  <Draggable
    axis='x'
    onDrag={(event, {deltaX}) => resizeColumn(index, deltaX)}
    position={{x: 0}}
  >
    <Grid.Column
      className='column-resizer'
    />
  </Draggable>
);

export default ResizableColumns;
