import {Form} from 'semantic-ui-react';
import {Field} from 'apstra-ui-common';

import RangeControl from '../../components/RangeControl';

import './RangeInput.less';

export default function RangeInput({
  name, value, schema, required, disabled, errors, onChange, fieldProps, knownPythonExpressionVariables
}) {
  return (
    <Field
      className='range-input'
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      {...fieldProps}
    >
      {(inputProps) =>
        <Form.Group>
          <RangeControl
            inputType='expression'
            value={value}
            errors={errors}
            onChange={onChange}
            knownPythonExpressionVariables={knownPythonExpressionVariables}
            {...inputProps}
          />
        </Form.Group>
      }
    </Field>
  );
}
