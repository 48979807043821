import {observer} from 'mobx-react';
import {Segment, Input, Dropdown} from 'semantic-ui-react';
import {map, without, keys, size} from 'lodash';
import cx from 'classnames';
import {Field} from 'apstra-ui-common';

import TagsControl from '../../components/TagsControl';
import {LAG_MODE_LABELS} from '../../components/graphs/Neighbors/components/constants';
import {useCablingMapStore} from '../store/useCablingMapStore';

import './EndpointProperties.less';

const lagModeOptions = map(
  without(keys(LAG_MODE_LABELS), 'no_lag'),
  (value) => ({text: LAG_MODE_LABELS[value], value})
);

const validate = (endpoint, version, link, usedIps) => {
  const result = [];
  if (!endpoint?.[`ipv${version}IsValid`]) result.push(`Invalid IPv${version} format`);

  const subnetErrors = link?.[`ipsv${version}SubnetErrors`];
  if (size(subnetErrors)) result.push(...subnetErrors);

  const {range} = (endpoint?.[`ipv${version}Parsed`] ?? {});
  if ((usedIps?.[range] ?? 0) > 1) result.push(`IP ${range} is used more than once`);
  return result;
};

const EndpointProperties = ({endpoint, link}) => {
  const {cablingMap} = useCablingMapStore();
  const {usedIps, tags: knownTags} = cablingMap;

  const isAggregate = link.isAggregate;

  const {endpointGroup, ipv4Addr, ipv6Addr, tags} = endpoint;

  const ipv4Errors = validate(endpoint, 4, link, usedIps);
  const ipv6Errors = validate(endpoint, 6, link, usedIps);

  const classNames = cx('ctrl-endpoint-data', {
    aggregate: isAggregate,
    'ipv4-error': !!ipv4Errors.length,
    'ipv6-error': !!ipv6Errors.length
  });

  const content = (
    <>
      <Field label='IPv4' errors={!ipv4Addr ? [] : ipv4Errors}>
        <Input
          fluid
          value={ipv4Addr ?? ''}
          onChange={(event) => endpoint.setProperty('ipv4Addr', event.target.value)}
          error={!!ipv4Addr && !!ipv4Errors.length}
        />
      </Field>
      <Field label='IPv6' errors={!ipv6Addr ? [] : ipv6Errors}>
        <Input
          fluid
          value={ipv6Addr ?? ''}
          onChange={(event) => endpoint.setProperty('ipv6Addr', event.target.value)}
          error={!!ipv6Addr && !!ipv6Errors.length}
        />
      </Field>
      <Field label='Tags'>
        <TagsControl
          className='endpoint-tags'
          fluid
          knownTags={knownTags}
          onChange={(tags) => endpoint.setProperty('tags', [...tags])}
          value={tags}
        />
      </Field>
      {
        isAggregate &&
          <Field label='LAG Mode'>
            <Dropdown
              fluid
              selection
              onChange={(event, {value}) => endpoint.setProperty('lagMode', value)}
              options={lagModeOptions}
              value={endpoint.lagMode}
            />
          </Field>
      }
      {
        endpointGroup &&
          <Field label='Endpoint Group Label'>
            <Input
              fluid
              value={endpointGroup.label ?? ''}
              onChange={(event) => endpointGroup?.setLabel(event.target.value)}
              placeholder={`Default: ${endpointGroup.index}`}
            />
          </Field>

      }
    </>
  );

  return isAggregate ?
    <div className={classNames}>
      {content}
    </div> :
    <Segment
      secondary
      size='tiny'
      basic
      raised={false}
      className={classNames}
    >
      {content}
    </Segment>;
};

export default observer(EndpointProperties);
