import {FC, MutableRefObject, ReactElement, createContext, useCallback, useContext, useEffect, useMemo,
  useRef} from 'react';

import {SharedTooltip} from './SharedTooltip';
import {InterfaceTooltipContent} from './InterfaceTooltipContent';
import {LinkTooltipContent} from './LinkTooltipContent';
import {NodeTooltipContent} from './NodeTooltipContent';
import {ALL_NODE_ROLES} from '../../../roles';

type TooltipContextValue = {
  sharedTooltip: any;
  containerRef: MutableRefObject<any>;
  layer: string;
};

const TooltipContext = createContext<TooltipContextValue>({
  containerRef: {current: null},
  layer: '',
  sharedTooltip: null,
});

type TooltipProviderProps = {
  layer: string;
  children: ReactElement;
}

export const TooltipProvider: FC<TooltipProviderProps> = ({layer, children}) => {
  const sharedTooltip = useMemo(() => new SharedTooltip(), []);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const followHandler = (e) => sharedTooltip.followThePointer(e, containerRef);
    const container = containerRef.current;
    container?.addEventListener('mousemove', followHandler);
    container?.addEventListener('mouseover', followHandler);
    return () => {
      container?.removeEventListener('mousemove', followHandler);
      container?.removeEventListener('mouseover', followHandler);
    };
  }, [sharedTooltip]);

  return (
    <TooltipContext.Provider value={{containerRef, layer, sharedTooltip}}>
      <div ref={containerRef}>
        {children}
      </div>
    </TooltipContext.Provider>
  );
};

export const useTooltip = () => {
  return useContext(TooltipContext);
};

export const useLinkTooltip = () => {
  const {sharedTooltip} = useContext(TooltipContext);
  return useCallback((link) => (e: any) => {
    e.stopPropagation();
    sharedTooltip.show(<LinkTooltipContent data={link} />);
  }, [sharedTooltip]);
};

export const useNodeTooltip = () => {
  const {layer, sharedTooltip} = useContext(TooltipContext);
  return useCallback((node) => (e: any) => {
    e.stopPropagation();
    const isRemoteGateway = node.role === ALL_NODE_ROLES.REMOTE_GATEWAY;
    const tooltip = isRemoteGateway ? (
      <section>
        <div><b>{'Name'}</b>{node.label}</div>
        <div><b>{'IP'}</b>{node.ip}</div>
      </section>
    ) : (
      <NodeTooltipContent
        node={node}
        layer={layer}
      />
    );
    sharedTooltip.show(tooltip, isRemoteGateway);
    e.stopPropagation();
  }, [layer, sharedTooltip]);
};

export const useInterfaceTooltip = () => {
  const {sharedTooltip} = useContext(TooltipContext);
  return useCallback((intf) => (e: any) => {
    e.stopPropagation();
    if (!intf.fakeInterface && intf.if_name) {
      const tooltip = <InterfaceTooltipContent
        label={intf.if_name}
        ipv4Addr={intf.if_ipv4}
        ipv6Addr={intf.if_ipv6}
        speed={intf.if_speed}
        tags={intf.tags}
        operation_state={intf.operation_state}
        CT={[]}
      />;
      sharedTooltip.show(tooltip);
    }
  }, [sharedTooltip]);
};

// In cases when it is not possible to wrap hoverable area with extra div to track mouse movement
// this hook allows attach mouse movement handlers to the existing component (e.g. modal).
// In order to do so,
// 1. target component must be supplied with the ref, that this hook provides
// 2. findTargetFn helps find an HTML element to add event listeners within the React component (e.g. Modal)
export const useAttachTooltipHandler = (findTargetFn, layer) => {
  const targetRef = useRef<any>(null);
  const handlerRef = useRef<any>(null);

  const sharedTooltip = useMemo(() => new SharedTooltip(), []);

  // Tooltip context provider with the storage attached
  const TooltipContextProvider = useMemo(
    () => ({children}) => (
      <TooltipContext.Provider value={{sharedTooltip, containerRef: targetRef, layer}}>
        {children}
      </TooltipContext.Provider>
    ),
    [layer, sharedTooltip]
  );

  // Manage mouse events attacment/removal
  const attachHandler = useCallback(
    (targetNode) => {
      targetRef.current = targetNode;
      handlerRef.current = (e) => sharedTooltip.followThePointer(e, targetRef);

      targetRef.current.addEventListener?.('mousemove', handlerRef.current);
      targetRef.current.addEventListener?.('mouseover', handlerRef.current);
      sharedTooltip.setMountRef(targetRef);
    },
    [sharedTooltip]
  );

  // Manage mouse events attacment/removal
  const removeHandler = useCallback(
    () => {
      if (!handlerRef.current) return;

      targetRef.current?.removeEventListener?.('mousemove', handlerRef.current);
      targetRef.current?.removeEventListener?.('mouseover', handlerRef.current);
      handlerRef.current = null;
      targetRef.current = null;
    },
    []
  );

  // Remove handlers on component unmount
  useEffect(() => () => removeHandler(), [removeHandler]);

  // Ref function to determine which element to attach mouse event handlers to
  const ref = (node) => {
    // Remove existing handlers if any
    if (targetRef.current) removeHandler();

    // Determine the HTML element within the reffed component
    const targetNode = findTargetFn?.(node) ?? node;
    // Attach the new handlers
    if (targetNode) attachHandler(targetNode);
  };

  return {ref, TooltipContextProvider};
};
