import {forwardRef, Fragment} from 'react';
import {observer} from 'mobx-react';
import {action} from 'mobx';
import {Icon, Accordion, Grid} from 'semantic-ui-react';
import cx from 'classnames';
import {keyBy, chain, some} from 'lodash';

import DiffLine from './DiffLine';
import './EptParametersDiff.less';

const EptParametersDiff = forwardRef(
  (
    {eptA = {}, eptB = {}, activePrimitiveId, setActivePrimitive, onlyShowDifferent = false},
    ref
  ) => {
    const id = eptA.id || eptB.id;
    const title = eptA.title || eptB.title;
    const isSelected = [eptA.id, eptB.id].includes(activePrimitiveId);

    // Fires when some primitives parameters form field gets focus.
    // Makes corresponding primitive block highlighted (stroked)
    // on the canvas.
    const handleActivePrimitive = () => {
      setActivePrimitive(id);
    };

    // Toggles parameters section collapsing/expansion
    const toggleExpansion = action(() => {
      setActivePrimitive(isSelected ? null : id);
    });

    const parametersA = keyBy(eptA?.parameters ?? {}, 'name');
    const parametersB = keyBy(eptB?.parameters ?? {}, 'name');

    const different = eptA.parameters && eptB.parameters &&
      some(parametersA, ({value}, name) => {
        const value2 = parametersB[name]?.value;
        return (value?.id ?? value) !== (value2?.id ?? value2);
      });
    const added = !eptA.parameters && eptB.parameters;
    const deleted = eptA.parameters && !eptB.parameters;

    const titleClassNames = cx('ct-parameters-diff-title', {
      selected: isSelected,
      different,
      added,
      deleted
    });

    const contentClassNames = cx('ct-parameters-diff-content', {
      selected: isSelected,
      added,
      deleted
    });

    return (
      <Fragment>
        <Accordion.Title
          className={titleClassNames}
          active={isSelected}
          onClick={toggleExpansion}
        >
          <div ref={ref}>
            <Icon name='dropdown' />
            <span>{title}</span>
          </div>
        </Accordion.Title>
        <Accordion.Content
          className={contentClassNames}
          active={isSelected}
          onFocus={handleActivePrimitive}
        >
          <Grid>
            {
              // For CT parameters
              chain((eptA.id ? eptA : eptB).parameters)
                // make them sorted by 'advanced' (latter go to the bottom)
                .sortBy('advanced')
                // and iterate them through
                .map(({name, schema}) => <DiffLine
                  key={name}
                  title={schema.title || name}
                  value1={parametersA?.[name]?.value}
                  value2={parametersB?.[name]?.value}
                  onlyShowDifferent={onlyShowDifferent}
                />)
                .value()
            }
          </Grid>
        </Accordion.Content>
      </Fragment>
    );
  }
);

export default observer(EptParametersDiff);
