/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {convertChevrotainErrors} from 'apstra-ui-common';
import {compact, escape, map} from 'lodash';

import PythonExpressionParser from './PythonExpressionParser';
import PythonExpressionPlainTextFormatter from './PythonExpressionPlainTextFormatter';
import buildPropertyDocHTMLFromSchema from './buildPropertyDocHTMLFromSchema';
import {
  PYTHON_EXPRESSION_KEYWORDS,
  PYTHON_EXPRESSION_ALLOWED_BUILTIN_FUNCTIONS,
  PYTHON_EXPRESSION_HELPER_FUNCTIONS,
} from './consts';

import './ace-mode-python-expression.less';

const stringPrefix = '[uUbB]?';
const stringRawPrefix = '[rR]';
const stringEscape = '\\\\(x[0-9A-Fa-f]{2}|[0-7]{3}|[\\\\abfnrtv\'"]|U[0-9A-Fa-f]{8}|u[0-9A-Fa-f]{4})';
const identifier = '[a-zA-Z_]\\w*\\b';

export function makeStringState(stateName, encloser, multiLine = false, raw = false, token = 'string') {
  const rules = [];
  if (!raw) {
    rules.push({
      token: 'constant.language.escape',
      regex: stringEscape
    });
  }
  if (multiLine) {
    rules.push({
      token,
      regex: encloser + '{3}',
      next: 'start'
    });
  } else {
    rules.push({
      token,
      regex: '\\\\$',
      next: stateName
    }, {
      token,
      regex: encloser + '|$',
      next: 'start'
    });
  }
  rules.push({
    defaultToken: token
  });
  return {[stateName]: rules};
}

ace.define(
  'ace/mode/python-expression',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
    const {Mode: TextMode} = require('ace/mode/text');
    const {BaseCompleter} = require('ace/base_completer');

    const KEYWORDS_COMPLETIONS = map(PYTHON_EXPRESSION_KEYWORDS, (name, index) => ({
      caption: name,
      snippet: name,
      meta: 'keyword',
      score: 100 - index,
    }));

    const BUILTIN_FUNCTIONS_COMPLETIONS = map(PYTHON_EXPRESSION_ALLOWED_BUILTIN_FUNCTIONS, (name, index) => ({
      caption: name,
      snippet: name + '($0)',
      meta: 'function',
      className: 'completion-function ace_',
      score: 100 - index,
    }));

    const HELPER_FUNCTIONS_COMPLETIONS = map(PYTHON_EXPRESSION_HELPER_FUNCTIONS, ({name, description}, index) => ({
      caption: 'functions.' + name,
      snippet: 'functions.' + name + '($0)',
      docText: description,
      meta: 'function',
      className: 'completion-function ace_',
      score: 100 - index,
    }));

    class PythonHighlightRules extends TextHighlightRules {
      constructor() {
        super();

        const keywordMapper = this.createKeywordMapper({
          'keyword.operator': PYTHON_EXPRESSION_KEYWORDS.join('|'),
        }, 'identifier');

        this.$rules = {
          start: [
            {
              token: 'comment',
              regex: /#.*$/
            },
            {
              token: 'string',
              regex: stringPrefix + '"{3}',
              next: 'long-double-quote-string'
            },
            {
              token: 'string',
              regex: stringPrefix + '"(?=.)',
              next: 'short-double-quote-string'
            },
            {
              token: 'string',
              regex: stringPrefix + "'{3}",
              next: 'long-single-quote-string'
            },
            {
              token: 'string',
              regex: stringPrefix + "'(?=.)",
              next: 'short-single-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + '"{3}',
              next: 'raw-long-double-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + '"(?=.)',
              next: 'raw-short-double-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + "'{3}",
              next: 'raw-long-single-quote-string'
            },
            {
              token: 'string',
              regex: stringRawPrefix + "'(?=.)",
              next: 'raw-short-single-quote-string'
            },
            {
              token: 'keyword.operator',
              regex: /(?:==|!=|>=|<=|<>|>>|<<|<|>|\/\/?|%|\||&|\^|\*|\+|-)/,
            },
            {
              token: ['keyword-argument', 'punctuation'],
              regex: '(' + identifier + ')(=)'
            },
            {
              token: ['entity.function.name', 'paren.lparen'],
              regex: '(' + identifier + ')(\\()'
            },
            {
              token: keywordMapper,
              regex: identifier
            },
            {
              token: 'punctuation',
              regex: ',|:|;|\\.'
            },
            {
              token: 'paren.lparen',
              regex: /[[({]/
            },
            {
              token: 'paren.rparen',
              regex: /[\])}]/
            },
            {
              token: 'text',
              regex: /\s+/
            },
            {
              include: 'constants'
            }
          ],
          ...makeStringState('long-double-quote-string', '"', true, false),
          ...makeStringState('long-single-quote-string', "'", true, false),
          ...makeStringState('short-double-quote-string', '"', false, false),
          ...makeStringState('short-single-quote-string', "'", false, false),
          ...makeStringState('raw-long-double-quote-string', '"', true, true),
          ...makeStringState('raw-long-single-quote-string', "'", true, true),
          ...makeStringState('raw-short-double-quote-string', '"', false, true),
          ...makeStringState('raw-short-single-quote-string', "'", false, true),
          constants: [
            {
              token: 'constant.numeric',
              regex: /-?(0|[1-9]\d*)(\.\d+)?([eE][+-]?\d+)?/
            },
            {
              token: ['punctuation', 'function.support', 'paren.lparen'],
              regex: '(\\.)(' + identifier + ')(\\()'
            }
          ]
        };
        this.normalizeRules();
      }
    }

    class PythonExpressionCompleter extends BaseCompleter {
      getKnownVariablesCompletions(knownVariables) {
        return map(knownVariables, ({propertyName, propertySchema, color, shape}, name, index) => ({
          caption: name,
          snippet: name,
          docHTML: '<div><b>' + escape(propertyName) + '</b></div>' + buildPropertyDocHTMLFromSchema(propertySchema),
          className: map(
            compact([
              'variable',
              shape ? 'completion_icon_' + shape : null,
              color ? 'completion_icon_color_' + color : null,
            ]),
            (className) => className + ' ace_'
          ).join(''),
          score: 2000 - index,
        }));
      }

      async getCustomCompletions(state, session, pos, prefix) {
        const token = session.getTokenAt(pos.row, pos.column);
        const result = [];

        if (token.type === 'string' || token.type === 'constant.language.escape') return [];

        if (token.type === 'identifier') {
          result.push(
            ...KEYWORDS_COMPLETIONS,
            ...BUILTIN_FUNCTIONS_COMPLETIONS,
            ...HELPER_FUNCTIONS_COMPLETIONS,
            ...this.getKnownVariablesCompletions(session.completerParams?.knownVariables),
            ...await this.getTextCompletions(state, session, pos, prefix)
          );
        }
        return result;
      }
    }

    class PythonExpressionMode extends TextMode {
      $id = 'ace/mode/python-expression';
      $behaviour = this.$defaultBehaviour;
      HighlightRules = PythonHighlightRules;
      completer = new PythonExpressionCompleter();

      lineCommentStart = '#';

      getNextLineIndent(state, line, tab) {
        let indent = this.$getIndent(line);
        if (state === 'start') {
          if (line.match(/^.*[{([:]\s*$/)) indent += tab;
        }
        return indent;
      }

      format(text, multiLine) {
        try {
          const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(text);
          if (!lexErrors.length && !parseErrors.length) text = PythonExpressionPlainTextFormatter.run(cst, {multiLine});
        } catch {}
        return text;
      }

      validate(text) {
        try {
          const {lexErrors, parseErrors} = PythonExpressionParser.parse(text);
          return convertChevrotainErrors(lexErrors, parseErrors);
        } catch {}
        return [];
      }
    }

    exports.Mode = PythonExpressionMode;
    exports.HighlightRules = PythonHighlightRules;
  }
);

ace.require(['ace/mode/python-expression']);
