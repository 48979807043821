import {observer} from 'mobx-react';
import {useCallback} from 'react';
import {Container, Grid, Input, Message} from 'semantic-ui-react';
import {Field, Checkbox} from 'apstra-ui-common';

import {useRackEditorStore} from '../hooks/useRackEditorStore';
import './Summary.less';
import {DESIGN} from '../const';

const Summary = () => {
  const {name, description, design, setProperty, hasMlagLeafPairs} = useSummary();

  return (
    <Grid.Row className='re-summary'>
      <Grid.Column width={10}>
        <h4>{'Summary'}</h4>
        <Field required label='Name'>
          <Input fluid value={name} error={!name} onChange={(event, {value}) => setProperty('name', value)} />
        </Field>
        <Field label='Description'>
          <Input fluid value={description} onChange={(event, {value}) => setProperty('description', value)} />
        </Field>
      </Grid.Column>
      <Grid.Column width={6}>
        <h4>{'Fabric Connectivity Design'}</h4>
        <Checkbox
          radio
          name='design'
          label='L3 Clos'
          checked={design === DESIGN.L3CLOS}
          onClick={() => setProperty('design', DESIGN.L3CLOS)}
        />
        <Container>
          {'Applicable when designing rack types used in 3-stage and 5-stage fabric template'}
        </Container>

        <Checkbox
          radio
          name='design'
          label='L3 Collapsed'
          checked={design === DESIGN.L3COLLAPSED}
          onClick={() => setProperty('design', DESIGN.L3COLLAPSED)}
          disabled={hasMlagLeafPairs}
        />
        <Container className='last'>
          {'Applicable when designing rack types used in a collapsed template (spineless)'}
        </Container>
        {hasMlagLeafPairs &&
          <Message warning content='MLAG leaf pairs are not allowed in L3 Collapsed design' />
        }
      </Grid.Column>
    </Grid.Row>
  );
};

const useSummary = () => {
  const {rackStore} = useRackEditorStore();
  const {name, description, design, mlagLeafPairsCount} = rackStore;

  const setProperty = useCallback(
    (property, value) => rackStore.setProperty(property, value),
    [rackStore]
  );

  return {
    name: name || '',
    description: description || '',
    design: design,
    hasMlagLeafPairs: mlagLeafPairsCount > 0,
    setProperty
  };
};

export default observer(Summary);
