export const canvasWidth = 100;
export const canvasHeight = 100;
export const aplicationPointWidth = 190;

export const eptWidth = 200;
export const eptHeight = 40;
export const connectionPointRadius = 7;
export const proximity = 30;
export const eptTitleMaxLength = 128;
export const eptTitleShownChars = 48;

export const eptRequiredParametersError = 'Please check primitives parameters for errors';

export const eptErrorTitles = {
  application_points: 'Application Point validation error:',
  nodes: 'Validation (nodes):',
  relationships: 'Validation (relationships):'
};

export const eptMaxSize = 64;
