import {
  DataFilteringContainerWithRouter, FetchData, FetchDataError, interpolateRoute, Loader, request,
} from 'apstra-ui-common';
import {Fragment, memo, useContext, useEffect, useMemo, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {has, get, transform, keys, isEqual} from 'lodash';
import {Breadcrumb, Button, Grid, Message} from 'semantic-ui-react';

import Report from './Report';
import IBAContext from '../../IBAContext';
import {getDefaultValues, isCombineTime, useReportForm} from './useReportForm';
import ReportFilters from './ReportFilters';
import {PARAMETER_SCHEMA, PREDEFINED_PROBE_SCHEMA} from './const';
import ReportData from './ReportData';
import ReportQueryBox from './ReportQueryBox';
import {handleTimeRange, addPathToItem, walkSections} from './reportUtils';

import {ReactComponent as Collapse} from '../../../../styles/icons/iba/collapse.svg';
import {ReactComponent as Expand} from '../../../../styles/icons/iba/expand.svg';

import './ReportContainer.less';

const fetchData = async ({blueprintId, reportName, filters, routes, reportSchema, signal}) => {
  const collectProperties = (schema, path) => {
    const properties = keys(get(schema, [path, 'properties']));
    return transform(properties, (result, propertyName) => {
      if (has(filters, propertyName)) {
        result[propertyName] = filters[propertyName];
      }
    }, {});
  };

  const useCombineTime = isCombineTime(reportSchema);
  const predefinedProbes = collectProperties(reportSchema, PREDEFINED_PROBE_SCHEMA);
  const parameters = handleTimeRange(
    collectProperties(reportSchema, PARAMETER_SCHEMA),
    useCombineTime,
    true
  );

  const report = await request(
    interpolateRoute(routes.predefinedProbeReportQuery, {blueprintId, reportName}),
    {
      signal,
      method: 'POST',
      body: JSON.stringify({parameters, predefined_probes: predefinedProbes}),
      maxRetryCount: 0,
    }
  );

  return {report};
};

const ReportContainer = () => {
  const {blueprintId, routes} = useContext(IBAContext);
  const {reportName} = useParams();

  return (
    <ReportData reportName={reportName}>
      <ReportFilteringContainer
        blueprintId={blueprintId}
        reportName={reportName}
        routes={routes}
      />
    </ReportData>
  );
};

const ReportFilteringContainer = memo(({reportSchema, blueprintId, reportName, routes, probeList}) => {
  const defaultFilters = useMemo(() => getDefaultValues(reportSchema), [reportSchema]);
  return (
    <Grid className='report-filtering-container'>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={4}>
          <Breadcrumb
            size='big'
            icon='right caret'
            sections={[
              {key: 'home', content: 'Reports', as: Link, to: `/blueprints/${blueprintId}/analytics/reports`},
              {key: 'label', active: true, content: reportSchema?.label || reportName}
            ]}
          />
        </Grid.Column>
      </Grid.Row>
      <DataFilteringContainerWithRouter
        stateQueryParam='report-filter'
        defaultFilters={defaultFilters}
      >
        {({filters, updateFilters}) => (
          <FetchData
            fetchData={fetchData}
            fetchParams={{blueprintId, filters, reportName, reportSchema, routes}}
            pollingInterval={null}
            customLoader
          >
            <ReportFetchingData
              filters={filters}
              reportSchema={reportSchema}
              updateFilters={updateFilters}
              probeList={probeList}
              blueprintId={blueprintId}
            />
          </FetchData>
        )}
      </DataFilteringContainerWithRouter>
    </Grid>
  );
});

const ReportFetchingData = ({
  report, loaderVisible, fetchDataError, reportSchema, filters, updateFilters, probeList,
  blueprintId, loaderText, errorText, refetchData,
}) => {
  const {
    values, parametersFormSchema, probeFormSchema, useCombineTime, onChange,
    onUpdate, errors, onError,
  } = useReportForm(reportSchema, filters);

  useEffect(() => {
    if (!isEqual(filters, values)) onUpdate(filters);
  }, [filters, onUpdate]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (fetchDataError) onError(fetchDataError);
  }, [fetchDataError, onError]);

  const itemsWithPath = useMemo(() => {
    const items = get(report, ['report', 'items'], []);
    return addPathToItem(items);
  }, [report]);

  const [collapsedSectionState, setCollapsedSectionState] = useState({});
  const onCollapseAll = () => {
    const collapsedSectionState = {};
    walkSections(itemsWithPath, collapsedSectionState);
    setCollapsedSectionState(collapsedSectionState);
  };
  const onExpandAll = () => setCollapsedSectionState({});
  const onCollapseToggle = (path) =>
    setCollapsedSectionState({...collapsedSectionState, [path]: !collapsedSectionState[path]});

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column width={10}>
          <ReportQueryBox
            fetchDataError={fetchDataError}
            apply={() => {
              updateFilters(values);
              refetchData({showLoader: true});
            }}
            filters={filters}
            parameterSchema={reportSchema?.parameter_schema}
            useCombineTime={useCombineTime}
          >
            <ReportFilters
              predefinedProbeIds={reportSchema?.predefined_probes}
              probeList={probeList}
              blueprintId={blueprintId}
              loaderVisible={loaderVisible}
              onChange={onChange}
              parametersFormSchema={parametersFormSchema}
              probeFormSchema={probeFormSchema}
              useCombineTime={useCombineTime}
              errors={errors}
              values={values}
            />
          </ReportQueryBox>
        </Grid.Column>
        <Grid.Column width={6} textAlign='right' className='no-print'>
          <Button
            secondary
            icon='print'
            content='Print'
            onClick={() => window.print()}
          />
          <Button
            secondary
            icon={
              <i className='icon expand-icon'>
                <Expand />
              </i>
            }
            content='Expand All'
            onClick={onExpandAll}
          />
          <Button
            secondary
            icon={
              <i className='icon collapse-icon'>
                <Collapse />
              </i>
            }
            content='Collapse All'
            onClick={onCollapseAll}
          />
        </Grid.Column>
      </Grid.Row>
      {loaderVisible ?
        <Loader text={loaderText} /> :
      !fetchDataError ?
        <Report
          itemsWithPath={itemsWithPath}
          onCollapseToggle={onCollapseToggle}
          collapsedSectionState={collapsedSectionState}
        />
      : errors.http ?
        <FetchDataError error={errors.http} />
      :
        <Message error icon='warning sign' header='Error' content={errorText} />
      }
    </Fragment>
  );
};

ReportFetchingData.defaultProps = {
  loaderText: 'Report generation in progress, this may take a while...',
  errorText: 'Errors occurred while generating the report, see the form above',
};

export default ReportContainer;
