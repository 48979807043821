
import BaseGauge from '../../../components/graphs/Gauge';

import './Gauge.less';

export default function Gauge(props) {
  return (
    <BaseGauge className='iba-gauge' {...props} />
  );
}
