import {map} from 'lodash';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {Popup} from 'semantic-ui-react';

import RackTypeStructure, {useRackTypeStructure} from './RackTypeStructure';
import LogicalDevices from './LogicalDevices';
import Legend from './Legend';

import './TemplateStructure.less';

const TemplateStructure = ({template, deviceProfiles, interfaceMaps, rtOnClick, ldOnClick, dpOnClick, showLegend}) => {
  const {
    display_name: label,
    rack_types: rackTypes,
    rack_based_templates: rackTemplates,
    spine,
    superspine,
    type
  } = template;
  const spineType = superspine ? 'superspine' : 'spine';
  const spineLogicalDevice = spine?.logical_device ?? superspine?.logical_device;
  const clickHandlers = {rtOnClick, ldOnClick, dpOnClick};

  const {relationships} = useRackTypeStructure({deviceProfiles, interfaceMaps});
  const className = cx('template-structure', type);
  return (
    <>
      {
        showLegend &&
          <Legend />
      }
      <div className={className}>
        <div className='template arrowed'>
          <Popup
            position='top center'
            trigger={<label>{label}</label>}
          >
            {label}
          </Popup>

        </div>
        <div className='rack-types'>
          {
            spineLogicalDevice &&
              <div className='logical-devices'>
                <LogicalDevices
                  logicalDevices={[spineLogicalDevice]}
                  relationships={relationships}
                  spine={spineType}
                  {...clickHandlers}
                />
              </div>
          }
          {
            map(
              rackTypes,
              (rackType) => <RackTypeStructure
                key={rackType?.id}
                rackType={rackType}
                interfaceMaps={interfaceMaps}
                deviceProfiles={deviceProfiles}
                {...clickHandlers}
              />
            )
          }
          {
            rackTemplates &&
              <div className='rack-templates'>
                {
                  map(
                    rackTemplates,
                    (rackTemplate) => <TemplateStructure
                      key={rackTemplate?.id}
                      template={rackTemplate}
                      deviceProfiles={deviceProfiles}
                      interfaceMaps={interfaceMaps}
                      {...clickHandlers}
                    />
                  )
                }
              </div>
          }
        </div>
      </div>
    </>
  );
};

export default observer(TemplateStructure);
