import {makeObservable, observable, computed, action} from 'mobx';

import {ctrlHalfNodeHeight} from '../../cablingMapEditor/const';
import {NODE_ROLES, reZonesTitles} from '../const';

class Zone {
  @observable label;
  @observable _size;
  @observable role;
  @observable.ref topZone = null;
  @observable.ref rackStore = null;

  @observable maxY;

  @computed
  get y() {
    return (this.topZone?.y ?? 0) + (this.topZone?.size ?? 0);
  }

  @computed
  get isFirst() {
    return !this.topZone;
  }

  @computed
  get isLast() {
    return this.role === NODE_ROLES.GENERIC;
  }

  @action
  setSize(value) {
    this._size = value;
  }

  set size(value) {
    this.setSize(value);
  }

  @computed
  get size() {
    return this.isEmpty ? 30 : (this.isLast ? 1000 : this._size);
  }

  @computed
  get nodesCount() {
    return this.rackStore?.rolesCount[this.role] ?? 0;
  }

  @computed
  get isEmpty() {
    return !this.nodesCount;
  }

  @action
  resizeBy(dy) {
    if (this.isEmpty) return this.topZone?.resizeBy(dy);
    if (this.size + dy >= +this.maxY) {
      this.size += dy;
    }
  }

  @action
  adoptNode(node) {
    const y = ctrlHalfNodeHeight + node.position?.y ?? 0;
    if (y > this.y && y < this.y + this.size) {
      node.zone = this;
      node.role = this.role;
      node.moveTo({x: node.position.x, y: y - this.y - ctrlHalfNodeHeight});
      return true;
    }
    return false;
  }

  constructor(role, topZone, rackStore) {
    makeObservable(this);

    this.label = reZonesTitles[role];
    this.role = role;
    this._size = 150;
    this.topZone = topZone;
    this.rackStore = rackStore;

    this.maxY = this.size;
  }
}

export default Zone;
