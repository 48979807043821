import {Card, List, Button, Popup} from 'semantic-ui-react';
import {map, countBy} from 'lodash';

import './PrimitiveSelector.less';

const makeMainPropertiesSchema = (allTags) => ({
  label: 'CT Properties (title, tags, status)',
  name: '',
  arguments: [
    {
      name: 'label',
      label: 'Title',
      type: 'string'
    },
    {
      name: 'tags',
      label: 'Tags',
      type: 'list',
      subtype: 'enum-radiobutton',
      enum_values: allTags
    },
    {
      name: 'status',
      label: 'Status',
      type: 'enum-radiobutton',
      enum_values: [
        'incomplete',
        'ready',
        'assigned'
      ],
      enum_display_values: [
        'Incomplete',
        'Ready',
        'Assigned'
      ]
    }
  ],
  description: 'Main properties of Connectivity Template'
});

const PrimitiveSelector = ({eptSchemas, selection, allTags = [], update}) => {
  const selectionCount = countBy(selection, 'schema.name');

  return (
    <Card className='ct-primitive-selector'>
      <Card.Header>
        <h4>{'Add item to query:'}</h4>
      </Card.Header>
      <Card.Content>
        <List verticalAlign='middle'>
          {
            map({
              'Main Properties': [makeMainPropertiesSchema(allTags)],
              'Primitive Types': eptSchemas
            }, (schemas, section) => [
              <List.Item key={section}><h5>{section}</h5></List.Item>,
              map(schemas, (schema, index) => {
                if (!schema.label) return null;
                const count = selectionCount[schema.name];
                return (
                  <List.Item key={index}>
                    <List.Content floated='right'>
                      <strong>{count}</strong>
                    </List.Content>
                    <List.Content>
                      <Popup
                        content={`Add ${schema.label}`}
                        trigger={<Button
                          onClick={() => update(schema)}
                          content={schema.label}
                        />}
                      />
                    </List.Content>
                  </List.Item>
                );
              })
            ])
          }
        </List>
      </Card.Content>
    </Card>
  );
};

export default PrimitiveSelector;
export {makeMainPropertiesSchema};
