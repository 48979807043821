
import {Layout} from '../types';
import {LayoutAlgorithm} from './LayoutAlgorithm';
import {MIN_NODE_WIDTH, MIN_NODE_HEIGHT} from './NodeLayout';

const MIN_NODE_PAIR_WIDTH = 2 * MIN_NODE_WIDTH;
const MIN_NODE_PAIR_HEIGHT = MIN_NODE_HEIGHT;

export class NodePairLayout extends LayoutAlgorithm {
  recalculateOwn = (own: Layout) => {
    let changed = false;
    if (own.width < MIN_NODE_PAIR_WIDTH) {
      own.width = MIN_NODE_PAIR_WIDTH;
      changed = true;
    }
    if (own.height < MIN_NODE_PAIR_HEIGHT) {
      own.height = MIN_NODE_PAIR_HEIGHT;
      changed = true;
    }
    return changed;
  };

  recalculateChildren = () => false;
}
