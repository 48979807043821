import {isEmpty, uniqueId, assign} from 'lodash';
import {observable, makeObservable, action} from 'mobx';

const beforeUnloadFn = (e) => {
  const message = 'Some requests are still pending. Are you sure you want to leave this page?';
  e.returnValue = message;
  return message;
};

class UploadStore {
  constructor() {
    makeObservable(this);
  }

  @observable items = {};

  @action
  addUploadItem(item) {
    if (isEmpty(this.items)) window.addEventListener('beforeunload', beforeUnloadFn);
    const id = uniqueId();
    this.items[id] = {
      uploadProgress: null,
      ...item
    };
    return id;
  }

  @action
  updateUploadItemProgress(id, update = {}) {
    assign(this.items[id], update);
  }

  @action
  deleteUploadItem(id) {
    delete this.items[id];
    if (isEmpty(this.items)) window.removeEventListener('beforeunload', beforeUnloadFn);
  }
}

const uploadStore = new UploadStore();

export default uploadStore;
