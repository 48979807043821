import {
  flattenDeep, forEach, get, invertBy, isFinite, filter, isNumber, isNull,
  last, map, max, minBy, pick, some, sumBy, toUpper, transform, uniqBy
} from 'lodash';

import {DATA_SOURCE} from '../StageDataSourceSelectors';
import {ALL_NODE_ROLES} from '../../../roles';

export const topologyEntityLevels = {
  [ALL_NODE_ROLES.GENERIC]: 0,
  [ALL_NODE_ROLES.ACCESS]: 1,
  [ALL_NODE_ROLES.LEAF]: 2,
  [ALL_NODE_ROLES.SPINE]: 3,
  [ALL_NODE_ROLES.SUPERSPINE]: 4,
  [ALL_NODE_ROLES.EXTERNAL_GENERIC]: 5,
};

export const COLORED_FROM_NEIGHBORS_ROLES = invertBy(topologyEntityLevels)[0];
export const TRAFFIC_PROBE_STAGE = {
  averageInterfaceCounters: 'Average Interface Counters',
  systemInterfaceCounters: 'System Interface Counters'
};
export const TRAFFIC_HEAT_LAYER = 'traffic_heat';
// Decided with backend team to hardcode this percentage value on UI until backend will provide that in API
export const HOT_LINK_UTILIZATION = 81;

function getDirection(srcRole, dstRole) {
  return topologyEntityLevels[dstRole] -
  topologyEntityLevels[srcRole];
}

export function getLinkTrafficAndSpeed(link, nodeInterfaces) {
  const {sourceIntf, targetIntf, sourceId, targetId} = link;
  return (nodeInterfaces[sourceId]?.interfaces[sourceIntf]) ?
    {
      // eslint-disable-next-line camelcase
      traffic: nodeInterfaces[sourceId]?.interfaces[sourceIntf]?.tx_bps_average,
      speed: nodeInterfaces[sourceId]?.interfaces[sourceIntf]?.speed || speedToInt(link.speed)
    } :
    {
      // eslint-disable-next-line camelcase
      traffic: nodeInterfaces[targetId]?.interfaces[targetIntf]?.rx_bps_average,
      speed: nodeInterfaces[targetId]?.interfaces[targetIntf]?.speed || speedToInt(link.speed),
    };
}

export function getLinkProperties(link, nodeInterfaces) {
  const {sourceIntf, targetIntf, sourceId, targetId} = link;
  const linkProperties = nodeInterfaces[sourceId]?.interfaces[sourceIntf] ?
    nodeInterfaces[sourceId]?.interfaces[sourceIntf]
    : nodeInterfaces[targetId]?.interfaces[targetIntf];
  return pickOrderedLinkProperties(linkProperties);
}

export const pickOrderedLinkProperties = (linkProperties) => pick(linkProperties, [
  'alignment_errors_per_second_average',
  'fcs_errors_per_second_average',
  'giants_per_second_average',
  'runts_per_second_average',
  'rx_bps_average',
  'tx_bps_average',
  'rx_broadcast_pps_average',
  'tx_broadcast_pps_average',
  'rx_discard_pps_average',
  'tx_discard_pps_average',
  'rx_error_pps_average',
  'tx_error_pps_average',
  'rx_multicast_pps_average',
  'tx_multicast_pps_average',
  'rx_unicast_pps_average',
  'tx_unicast_pps_average',
  'rx_utilization_average',
  'tx_utilization_average',
  'symbol_errors_per_second_average',
  'speed',
]);

export function getNodeProperties({node, nodeInterfaces}) {
  return pick(nodeInterfaces[node.id], [
    'aggregate_rx_bps',
    'aggregate_tx_bps',
    'aggregate_rx_utilization',
    'aggregate_tx_utilization',
    'max_ifc_rx_utilization',
    'max_ifc_tx_utilization'
  ]);
}

export function parseSpeed(s) {
  const multipliers = {
    K: 1_000,
    M: 1_000_000,
    G: 1_000_000_000,
    T: 1_000_000_000_000,
  };
  const value = parseInt(s) * (multipliers[toUpper(last(s))] ?? 1);
  return value;
}

export function speedToInt(speed) {
  if (isNumber(speed)) return speed;
  if (isNull(speed)) return null;
  const mags = {K: 3, M: 6, G: 9};
  const [, num, mag] = speed.match(/(\d+)([GMK]?)/i);
  return +num * (10 ** (mags[mag] ?? 0));
}

export function getGraphBfs({isFreeform}) {
  return isFreeform ? freeformGraphBfs : filteredGraphBfs;
}

function filteredGraphBfs(args) {
  return filterPathsByLength(graphBfs(args));
}

function graphBfs({graph, sourceId, destinationId, depth = 8, path = [], direction = 0}) {
  if (sourceId === destinationId) return path.length ? [path] : [];
  if (depth === 0 || some(path, ({srcId}) => srcId === sourceId)) {
    return [];
  }
  const nodeLinks = transform(graph[sourceId], (acc, link) => {
    const linkDirection = getDirection(link.source.role, link.destination.role);
    if (
      (linkDirection === 0 && link.source.role !== ALL_NODE_ROLES.LEAF) ||
      (direction < 0 && linkDirection > 0)
    ) {
      return;
    }
    const linksItem = {
      speed: link.speed,
      src_intf_name: link.source.interfaceName,
      dst_intf_name: link.destination.interfaceName,
      aggregateId: link.aggregate_link_id,
    };
    if (acc[link.destination.id]) {
      acc[link.destination.id].links.push(linksItem);
      return;
    }
    acc[link.destination.id] = {
      dst_node: link.destination.nodeLabel,
      src_node: link.source.nodeLabel,
      srcRole: link.source.role,
      dstRole: link.destination.role,
      srcId: sourceId,
      dstId: link.destination.id,
      links: [linksItem],
      direction: linkDirection
    };
  }, {}
  );

  return transform(nodeLinks, (acc, pathPart, nextId) => {
    const paths = graphBfs({
      graph,
      sourceId: nextId,
      destinationId,
      depth: depth - 1,
      path: [...path, pathPart],
      direction: pathPart.direction || direction
    });
    acc.push(...paths);
  }, []);
}

function freeformGraphBfs({graph, sourceId, destinationId, depth = -1, path = []}) {
  if (sourceId === destinationId) return path.length ? [path] : [];
  if (depth === 0 || some(path, ({srcId}) => srcId === sourceId)) {
    return [];
  }
  const nodeLinks = transform(graph[sourceId], (acc, link) => {
    const linksItem = {
      speed: link.speed,
      src_intf_name: link.source.interfaceName,
      dst_intf_name: link.destination.interfaceName,
      aggregateId: link.aggregate_link_id,
    };
    if (acc[link.destination.id]) {
      acc[link.destination.id].links.push(linksItem);
      return;
    }
    acc[link.destination.id] = {
      dst_node: link.destination.nodeLabel,
      src_node: link.source.nodeLabel,
      srcRole: link.source.systemType,
      dstRole: link.destination.systemType,
      srcId: sourceId,
      dstId: link.destination.id,
      links: [linksItem],
    };
  }, {}
  );

  return transform(nodeLinks, (acc, pathPart, nextId) => {
    const paths = freeformGraphBfs({
      graph,
      sourceId: nextId,
      destinationId,
      depth: depth - 1,
      path: [...path, pathPart],
    });
    acc.push(...paths);
  }, []);
}

function filterPathsByLength(paths) {
  if (!paths?.length) return paths;
  const minLength = minBy(paths, 'length').length;
  return filter(paths, ({length}) => length === minLength);
}

export function getNeighborsPaths({graph, sourceId}) {
  const nodeLinks = transform(graph[sourceId], (acc, link) => {
    const linksItem = {
      speed: link.speed,
      src_intf_name: link.source.interfaceName,
      dst_intf_name: link.destination.interfaceName,
      aggregateId: link.aggregate_link_id,
    };
    if (acc[link.destination.id]) {
      acc[link.destination.id].links.push(linksItem);
      return;
    }
    acc[link.destination.id] = {
      dst_node: link.destination.nodeLabel,
      src_node: link.source.nodeLabel,
      srcRole: link.source.role,
      dstRole: link.destination.role,
      srcId: sourceId,
      dstId: link.destination.id,
      links: [linksItem],
    };
  }, {}
  );
  return map(nodeLinks, (pathPart) => [pathPart]);
}

export function buildGraphFromCablingMap({cablingMap, isFreeform = false}) {
  return transform(cablingMap, (acc, link) => {
    const node0 = getNode(link, 0);
    const node1 = getNode(link, 1);
    if (!node0.id || !node1.id || (!isFreeform && node0.operationState !== 'up')) return;
    acc[node0.id] = acc[node0.id] || [];
    acc[node0.id].push({
      source: node0, destination: node1, speed: link.speed, aggregate_link_id: link.aggregate_link_id
    });
    acc[node1.id] = acc[node1.id] || [];
    acc[node1.id].push({
      source: node1, destination: node0, speed: link.speed, aggregate_link_id: link.aggregate_link_id
    });
  }, {});
}

function getNode(link, id) {
  return ({
    id: get(link, ['endpoints', id, 'id']),
    nodeLabel: get(link, ['endpoints', id, 'label']),
    interfaceName: get(link, ['endpoints', id, 'if_name']) || 'UNKNOWN',
    role: get(link, ['endpoints', id, 'role']),
    systemType: get(link, ['endpoints', id, 'system_type']),
    operationState: get(link, ['endpoints', id, 'operation_state']),
  });
}

export function getNodeLinkMap(paths, nodeInterfaces, getSystemNameById) {
  const pathParts = uniqBy(flattenDeep(paths), ({srcId, dstId}) => `${srcId}:${dstId}`);
  const nodeLinkMap = transform(pathParts, (acc, pathPart) => {
    const {srcId, dstId, srcRole, dstRole, src_node: srcNode, dst_node: dstNode} = pathPart;
    const srcName = getSystemNameById(get(nodeInterfaces, [srcId, 'properties', 'system_id'])) || srcNode;
    const dstName = getSystemNameById(get(nodeInterfaces, [dstId, 'properties', 'system_id'])) || dstNode;
    if (!acc[srcId]) acc[srcId] = {id: srcId, inputs: [], outputs: [], role: srcRole, name: srcName};
    if (!acc[dstId]) acc[dstId] = {id: dstId, inputs: [], outputs: [], role: dstRole, name: dstName};
    const extendedLinks = map(pathPart.links, ({speed, src_intf_name: sourceIntf, dst_intf_name: targetIntf}) => {
      const link = {
        speed,
        sourceId: srcId,
        targetId: dstId,
        sourceIntf,
        targetIntf,
        sourceName: srcName,
        targetName: dstName,
      };
      return {...link, ...getLinkTrafficAndSpeed(link, nodeInterfaces)};
    });
    acc[srcId].outputs.push(...extendedLinks);
    acc[dstId].inputs.push(...extendedLinks);
  }, {});
  forEach(nodeLinkMap, (node, nodeId) => {
    if (nodeInterfaces[nodeId]) {
      // eslint-disable-next-line camelcase
      node.utilization = nodeInterfaces[nodeId]?.aggregate_tx_utilization;
    } else {
      const nodeLinks = filter([...node.inputs, ...node.outputs],
        ({speed, traffic}) => isFinite(speed) && isFinite(traffic));
      const traffic = sumBy(nodeLinks, 'traffic');
      const speed = sumBy(nodeLinks, 'speed');
      node.utilization = Math.floor(100 * traffic / speed);
    }
  });
  return nodeLinkMap;
}

export function getMaxLinkSpeed(nodeLinkMap) {
  return max(map(nodeLinkMap, ({inputs, outputs}) => max(map([...inputs, ...outputs], 'speed'))));
}

export function getTrafficDataFromSource(item, dataSource) {
  if (dataSource === DATA_SOURCE.timeSeries) {
    // eslint-disable-next-line camelcase
    const persisted = item?.persisted_samples;
    return persisted?.length ? {...item, ...last(persisted)} : pick(item, ['id', 'properties']);
  }
  return item;
}

export function generateDateTicks({beginTime, endTime, aggregation}) {
  if (!beginTime || !endTime || !aggregation) {
    return [];
  }
  const aggregationMs = aggregation * 1000;
  const beginTimeMs = +beginTime;
  const endTimeMs = +endTime;
  const ticks = [];
  let dateMs = beginTimeMs - (beginTimeMs % aggregationMs);

  while (dateMs < endTimeMs) {
    if (dateMs > beginTimeMs) {
      ticks.push(new Date(dateMs));
    }
    dateMs += aggregationMs;
  }
  ticks.push(new Date(endTimeMs));
  return ticks;
}
