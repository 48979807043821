import {isEqual, map, noop, includes} from 'lodash';
import {action, comparer, observable, reaction, makeObservable} from 'mobx';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Icon} from 'semantic-ui-react';
import {CodeEditorControl} from 'apstra-ui-common';

@observer
export default class PlainQueryInput extends Component {
  static propTypes = {
    schema: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    filters: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    applyOnChange: PropTypes.bool,
    highlightPropertyColumn: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    filters: '',
    applyOnChange: false,
    highlightPropertyColumn: noop,
  };

  @observable filters = '';

  disposeFiltersUpdater = reaction(
    () => this.props.filters,
    (filters) => {
      this.filters = filters ?? '';
    },
    {equals: comparer.structural, fireImmediately: true}
  );

  @action
  apply = () => {
    this.applyFilters(this.filters);
  };

  @action
  setQueryFilterValue = (query) => {
    this.filters = query;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  onValueChange = (newValue) => {
    this.setQueryFilterValue(newValue);
    if (this.props.applyOnChange) {
      this.apply();
    }
  };

  applyFilters(filters) {
    if (!isEqual(this.props.filters, filters)) {
      this.props.onChange(filters);
    }
  }

  onCursorChange = ({session, cursor, isSelectionEmpty}) => {
    const {schema, highlightPropertyColumn} = this.props;
    if (!session || !isSelectionEmpty) {
      highlightPropertyColumn(null);
      return;
    }
    const properties = map(schema, ({name}) => name);
    const {getCurrentProperty} = session.getMode();
    if (getCurrentProperty) {
      const token = getCurrentProperty(session, cursor);
      highlightPropertyColumn(includes(properties, token?.value) ? token?.value : null);
    }
  };

  componentWillUnmount() {
    this.disposeFiltersUpdater();
  }

  render() {
    const {filters, props: {schema, disabled, applyOnChange, 'aria-label': ariaLabel}} = this;
    return (
      <CodeEditorControl
        mode='matcher-filter-string'
        onChange={this.onValueChange}
        onCursorChange={this.onCursorChange}
        value={filters}
        disabled={disabled}
        completerParams={{schema}}
        commands={[{
          name: 'submit',
          bindKey: 'Enter',
          exec: this.apply,
        }]}
        icon={applyOnChange || disabled ? null : (
          <Icon name='search' className='link' onClick={this.apply} aria-label='Apply' />
        )}
        validate
        enableCompletion
        aria-label={ariaLabel}
      />
    );
  }
}
