import {Grid} from 'semantic-ui-react';
import cx from 'classnames';
import {toString, isArray, isEqual, some} from 'lodash';

import './DiffLine.less';

const getPrintableItem = (source) => {
  return source?.id ?
    `${source.label} (${source.id})` :
    toString(source);
};

const swapUndefined = (text) => (
  text === undefined ? '…' : (
    isArray(text) ? (
      text.length ?
        <ul>{text.map((line, index) => <li key={index}>{getPrintableItem(line)}</li>)}</ul> :
        '…'
    ) :
    getPrintableItem(text)
  )
);

const DiffLine = ({title, value1, value2, onlyShowDifferent = false}) => {
  const value1isSet = value1 !== undefined;
  const value2isSet = value2 !== undefined;
  const diff = {
    added: !value1isSet && value2isSet,
    deleted: value1isSet && !value2isSet,
    different: value1isSet && value2isSet && !isEqual(value1, value2)
  };

  const classNames = cx('ct-diff', diff);
  return (!onlyShowDifferent || some(diff)) && (
    <>
      <Grid.Column width={16} className={classNames}>{title}</Grid.Column>
      <Grid.Column width={8} className={classNames}>{swapUndefined(value1)}</Grid.Column>
      <Grid.Column width={1} verticalAlign='middle' className='ct-diff arrow'>
        <div>{'→'}</div>
      </Grid.Column>
      <Grid.Column width={8} className={classNames}>{swapUndefined(value2)}</Grid.Column>
    </>
  );
};

export default DiffLine;
