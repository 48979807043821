
export default function wrapWithComponent(WrapperComponent, wrapperComponentProps = {}, addPropsToWrapper) {
  return function(WrappedComponent) {
    return function(wrappedComponentProps) {
      const wrapperProps = addPropsToWrapper ?
        {...wrappedComponentProps, ...wrapperComponentProps} : wrapperComponentProps;
      return (
        <WrapperComponent {...wrapperProps}>
          <WrappedComponent {...wrappedComponentProps} />
        </WrapperComponent>
      );
    };
  };
}
