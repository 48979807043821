import {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import {map} from 'lodash';

import LinkProperties from './LinkProperties';
import {useCablingMapStore} from '../store/useCablingMapStore';

export const LinkPropertiesAdapter = observer(({link}) => {
  const {cablingMap: {deviceProfiles, links, nodes}} = useCablingMapStore();
  const linkNodes = useMemo(
    () => map(link.endpoints, ({nodeId}) => nodes[nodeId]),
    [link.endpoints, nodes]
  );

  const linkSpeed = useMemo(() => link.endpoint1.speed, [link]);

  const nodesDeviceProfiles = useMemo(() => map(
    linkNodes, ({isExternal, deviceProfileId}) => {
      if (isExternal) {
        return {deviceProfile: null, portGroups: null};
      }

      const deviceProfile = deviceProfiles[deviceProfileId];
      return deviceProfile ?
        {deviceProfile, portGroups: deviceProfile.portGroups} :
        {deviceProfile: null, portGroups: null};
    }
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPortSelect = useCallback((portId, transformationId, ifc, endpoint) => {
    endpoint.fillWith({
      nodeId: endpoint.nodeId,
      portId,
      transformationId,
      ifc,
      ipv4Addr: endpoint.ipv4Addr,
      ipv6Addr: endpoint.ipv6Addr
    });
  }, []);

  return (
    <LinkProperties
      link={link}
      links={links}
      nodesDeviceProfiles={nodesDeviceProfiles}
      linkNodes={linkNodes}
      linkActions={{portSelect: onPortSelect}}
      lockedSpeed={linkSpeed}
    />);
});
