import {Component} from 'react';
import {Link} from 'react-router-dom';
import {Message, Table} from 'semantic-ui-react';
import {map, find, some, omit, isEmpty, isMatch, size} from 'lodash';
import {
  Checkbox, DataFilter, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, DateFromNow, FetchData, Value,
  interpolateRoute, parseTimestamp, request, stringifyState, withRouter,
  stringFilterer, objectFilterer
} from 'apstra-ui-common';

import generateProbeURI from '../generateProbeURI';
import {anomalyPropertiesFilterRenderer} from './AnomalyPropertiesFilterInput';
import getCellColor from '../getCellColor';
import AnomalyValues from './AnomalyValues';
import IBAContext from '../IBAContext';
import userStore from '../../userStore';

import './AnomalyList.less';

function formatProbeLink({blueprintId, probeId, probes}) {
  const probe = find(probes, {id: probeId});
  return probe ? <Link to={generateProbeURI({blueprintId, probeId})}>{probe.label}</Link> : probeId;
}

function formatStageLink({blueprintId, probeId, stageName, probes}) {
  const probe = find(probes, {id: probeId});
  return probe
    ? <Link to={generateProbeURI({blueprintId, probeId, stageName})}>{stageName}</Link>
    : stageName;
}

function getProbeLabel({probeId, probes}) {
  const probe = find(probes, {id: probeId});
  return probe ? probe.label : null;
}

const nonGroupedTableSchema = [
  {
    name: 'probe',
    label: 'Probe',
    value: ['identity', 'probe_id'],
    formatter: ({value: probeId, params: {blueprintId, probes}}) => formatProbeLink({blueprintId, probeId, probes}),
    sortable: true,
  },
  {
    name: 'stage',
    label: 'Stage',
    value: ['identity'],
    formatter: ({value: {probe_id: probeId, stage_name: stageName}, params: {blueprintId, probes}}) =>
      formatStageLink({blueprintId, probeId, stageName, probes}),
    sortable: true,
  },
  {
    name: 'properties',
    label: 'Properties',
    value: ['identity', 'properties'],
    formatter: ({value}) =>
      <Table definition basic='very' collapsing size='small'>
        <Table.Body>
          {map(value, ({key, value}) =>
            <Table.Row key={key}>
              <Table.Cell>{key}</Table.Cell>
              <Table.Cell><Value value={value} /></Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
  },
  {
    name: 'values',
    label: 'Values',
    formatter: ({item}) => <AnomalyValues anomaly={item} />,
  },
  {
    name: 'updated',
    label: 'Updated',
    value: ({item}) => parseTimestamp(item.last_modified_at),
    formatter: ({value}) => <DateFromNow date={value} />,
    sortable: true,
  },
];

const groupedTableSchema = [
  {
    name: 'probe',
    label: 'Probe',
    value: ['probe_id'],
    formatter: ({value: probeId, params: {blueprintId, probes}}) => formatProbeLink({blueprintId, probeId, probes}),
    sortable: true,
  },
  {
    name: 'stage',
    label: 'Stage',
    value: ['stage_name'],
    formatter: ({item: {probe_id: probeId, stage_name: stageName}, params: {blueprintId, probes}}) =>
      formatStageLink({blueprintId, probeId, stageName, probes}),
    sortable: true,
  },
  {
    name: 'anomalies',
    label: 'Anomalies',
    value: ({item: {items, anomalies}}) => [items ? anomalies / items : 0, anomalies],
    formatter: ({
      item: {probe_id: probeId, stage_name: stageName, items, anomalies},
      params: {probes, blueprintId}
    }) => {
      const probe = find(probes, {id: probeId});
      const filters = {stage_name: stageName};
      if (probe) filters.probe_label = probe.label;
      const stringifiedFilters = stringifyState({filters});
      return (
        <Link
          style={{color: getCellColor({anomalies, items}).foreground}}
          to={`/blueprints/${blueprintId}/analytics/anomalies?probe-anomalies-filter=${stringifiedFilters}`}
        >
          {`${anomalies} / ${items}`}
        </Link>
      );
    },
    sortable: true,
  },
];

const nonGroupedFilterers = [
  stringFilterer(
    ({identity: {probe_id: probeId}}, {probes}) => getProbeLabel({probeId, probes}),
    ['probe_label']
  ),
  stringFilterer(
    ['identity', 'stage_name'],
    ['stage_name']
  ),
  objectFilterer(
    ['identity', 'properties'],
    ['properties', 'properties'],
    ['properties', 'strict'],
    (itemValue, value, key) => some(itemValue, (propertyPair) => isMatch(propertyPair, {key, value}))
  ),
];

const groupedFilterers = [
  stringFilterer(
    ({probe_id: probeId}, {probes}) => getProbeLabel({probeId, probes}),
    ['probe_label']
  ),
  stringFilterer(
    ['stage_name'],
    ['stage_name']
  ),
];

const commonSearchBoxSchema = [
  {
    name: 'probe_label',
    schema: {
      type: 'string',
      title: 'Probe Label',
    }
  },
  {
    name: 'stage_name',
    schema: {
      type: 'string',
      title: 'Stage Name',
    }
  },
];

const nonGroupedSearchBoxSchema = [
  ...commonSearchBoxSchema,
  {
    name: 'properties',
    schema: {
      type: 'object',
      title: 'Properties',
      default: {properties: {}, strict: true},
    }
  },
];

const groupedSearchBoxSchema = [
  ...commonSearchBoxSchema,
  {
    name: 'showStagesWithoutAnomalies',
    schema: {
      type: 'boolean',
      title: 'Show stages without anomalies',
    }
  },
];

@withRouter
export default class AnomalyList extends Component {
  static contextType = IBAContext;

  static async fetchData({
    blueprintId,
    grouped = false,
    showStagesWithoutAnomalies,
    routes, signal
  }) {
    const requests = [request(interpolateRoute(routes.probeList, {blueprintId}), {signal})];
    if (grouped) {
      const queryParams = new URLSearchParams();
      if (!showStagesWithoutAnomalies) queryParams.append('filter', 'anomalies>0');
      requests.push(
        request(interpolateRoute(routes.anomalousStageList, {blueprintId}), {queryParams, signal}),
        {items: []}
      );
    } else {
      requests.push(
        {items: []},
        request(interpolateRoute(routes.anomalyList, {blueprintId}), {queryParams: {anomaly_type: 'probe'}, signal})
      );
    }
    const [{items: probes}, {items: anomalousStages}, {items: anomalies}] = await Promise.all(requests);
    return {probes, anomalousStages, anomalies};
  }

  static pollingInterval = 30000;
  static userStoreKey = 'anomalyList';

  toggleGrouping = ({filters}) => {
    const {blueprintId} = this.context;
    const {grouped, navigate} = this.props;

    let url = `/blueprints/${blueprintId}/analytics/anomalies${grouped ? '' : '?grouped=true'}`;
    filters = omit(filters, 'properties');
    if (!isEmpty(filters)) url += `${!grouped ? '&' : '?'}probe-anomalies-filter=` + stringifyState({filters});
    navigate(url, {replace: true});
  };

  render() {
    const {blueprintId, routes} = this.context;
    const {grouped} = this.props;
    const defaultPageSize = userStore.getStoreValue([AnomalyList.userStoreKey, 'pageSize']);
    return (
      <DataFilteringContainer
        stateQueryParam='probe-anomalies-filter'
        defaultPageSize={defaultPageSize}
        defaultSorting={{probe: 'asc'}}
        setUserStoreProps={userStore.setStoreValueFn(AnomalyList.userStoreKey)}
      >
        {({activePage, pageSize, updatePagination, filters, updateFilters, sorting, updateSorting}) =>
          <FetchData
            customLoader
            fetchData={AnomalyList.fetchData}
            fetchParams={{
              blueprintId,
              grouped,
              showStagesWithoutAnomalies: grouped ? filters.showStagesWithoutAnomalies : undefined,
              routes
            }}
            pollingInterval={AnomalyList.pollingInterval}
          >
            {({probes, anomalousStages, anomalies, loaderVisible, fetchDataError}) => {
              const allItems = loaderVisible ? [] : grouped ? anomalousStages : anomalies;
              const tableSchema = grouped ? groupedTableSchema : nonGroupedTableSchema;
              const searchBoxSchema = grouped ? groupedSearchBoxSchema : nonGroupedSearchBoxSchema;
              const filterers = grouped ? groupedFilterers : nonGroupedFilterers;
              return (
                <DataFilter
                  items={allItems}
                  schema={tableSchema}
                  params={{probes}}
                  activePage={activePage}
                  pageSize={pageSize}
                  filterers={filterers}
                  filters={filters}
                  sorting={sorting}
                >
                  {({items, totalCount}) =>
                    <DataFilteringLayout
                      loaderVisible={loaderVisible}
                      fetchDataError={fetchDataError}
                      hideActionsAndPagination={!size(allItems) && isEmpty(filters)}
                      paginationProps={{
                        totalCount,
                        activePage,
                        pageSize,
                        onChange: updatePagination
                      }}
                      searchProps={{
                        filters,
                        schema: searchBoxSchema,
                        renderers: [anomalyPropertiesFilterRenderer],
                        onChange: updateFilters
                      }}
                      extraContent={{
                        buttonsPanel: (
                          <div className='anomalies-grouping'>
                            <Checkbox
                              label='Group by stage'
                              checked={grouped}
                              onChange={() => this.toggleGrouping({filters})}
                            />
                          </div>
                        )
                      }}
                    >
                      {
                        isEmpty(filters) && !items.length ?
                          <Message
                            success
                            icon='check circle'
                            header='No anomalies!'
                          />
                        :
                          <DataTable
                            items={items}
                            schema={tableSchema}
                            params={{blueprintId, probes}}
                            sorting={sorting}
                            updateSorting={updateSorting}
                            className='anomaly-list'
                            noItemsMessage='No anomalies'
                            getCellProps={
                              ({name, item: {items, anomalies}}) => name === 'anomalies' && {
                                className: 'anomalies',
                                style: {backgroundColor: getCellColor({anomalies, items}).background},
                              }
                            }
                          />
                      }
                    </DataFilteringLayout>
                  }
                </DataFilter>
              );
            }}
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}
