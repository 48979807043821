import {Layout} from '../types';
import {ALL_NODE_ROLES} from '../../../../roles';

const MAX_NODE_WIDTH: Partial<Record<ALL_NODE_ROLES, number>> = {
  [ALL_NODE_ROLES.SUPERSPINE]: 400,
  [ALL_NODE_ROLES.EXTERNAL_GENERIC]: 260,
  [ALL_NODE_ROLES.LEAF]: 300,
  [ALL_NODE_ROLES.SPINE]: 300,
  [ALL_NODE_ROLES.ACCESS]: 300,
  [ALL_NODE_ROLES.REMOTE_GATEWAY]: 260,
};

export const limitNodeWidth = (nodeLayout: Layout, role: ALL_NODE_ROLES) => {
  const maxWidth = MAX_NODE_WIDTH[role];
  if (maxWidth && nodeLayout.width > maxWidth) {
    const dx = Math.round(0.5 * (nodeLayout.width - maxWidth));
    nodeLayout.width = maxWidth;
    nodeLayout.left += dx;
  }
};
