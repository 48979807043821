import {Component} from 'react';
import PropTypes from 'prop-types';
import {action, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Group} from '@visx/group';
import {Circle} from '@visx/shape';
import {Text} from '@visx/text';
import {isNil, defer} from 'lodash';

@observer
export default class AnomalyCircle extends Component {
  static propTypes = {
    innerTextMin: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    scale: PropTypes.number,
    rowHeight: PropTypes.number.isRequired,
    rowTextdy: PropTypes.string.isRequired,
    value: PropTypes.number
  };

  @observable animationTriggered = this.props.current;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    if (!this.animationTriggered) {
      defer(action(() => {
        this.animationTriggered = true;
      }));
    }
  }

  render() {
    const {
      scale, innerTextMin, onClick, onMouseEnter, onMouseLeave,
      rowHeight, rowTextdy, left, value
    } = this.props;

    if (isNil(value)) {
      return null;
    }

    const rowHeightHalf = rowHeight / 2;
    const isSmall = value < innerTextMin;
    const textColorClass = isSmall ? 'black' : 'white';
    const dy = isSmall ? -4 : rowTextdy;
    const y = isSmall ? rowHeightHalf - rowHeightHalf * scale : rowHeightHalf;

    return (
      <Group
        className='anomaly'
        left={left}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Circle
          r={rowHeightHalf}
          style={{transform: `translate(0px, ${rowHeightHalf}px) scale(${this.animationTriggered ? scale : 0})`}}
        />
        <Text
          className={textColorClass}
          dy={dy}
          y={y}
        >
          {value}
        </Text>
      </Group>
    );
  }
}
