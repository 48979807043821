import PropTypes from 'prop-types';

import {STATE, STATUS} from './constants';

export const statePropTypes = PropTypes.oneOf(
  Object.values(STATE)
);

export const statusPropTypes = PropTypes.oneOf(
  Object.values(STATUS)
);

export const nodePropTypes = {
  hostname: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  role: PropTypes.string,
  system_id: PropTypes.string,
};

export const cablingMapItemPropTypes = {
  endpoints: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    if_id: PropTypes.string,
    if_name: PropTypes.string,
    if_type: PropTypes.string,
    ipv4_addr: PropTypes.string,
    ipv6_addr: PropTypes.string,
    ipv6_enabled: PropTypes.bool,
    label: PropTypes.string,
    role: PropTypes.string,
  })),
  id: PropTypes.string,
  label: PropTypes.string,
  role: PropTypes.string,
  speed: PropTypes.string,
};

export const interfaceTelemetryItemPropTypes = {
  actual: PropTypes.shape({
    value: PropTypes.string,
  }),
  expected: PropTypes.shape({
    value: PropTypes.string,
  }),
  identity: PropTypes.shape({
    interface_name: PropTypes.string,
  }),
  last_fetched_at: PropTypes.string,
  last_modified_at: PropTypes.string,
  role: PropTypes.string,
  status: PropTypes.string,
  system_id: PropTypes.string,
  type: PropTypes.string,
};

export const lldpTelemetryItemPropTypes = {
  actual: PropTypes.shape({
    chassis_id: PropTypes.string,
    neighbor_interface_name: PropTypes.string,
    neighbor_system_id: PropTypes.string,
  }),
  expected: PropTypes.shape({
    neighbor_interface_name: PropTypes.string,
    neighbor_system_id: PropTypes.string,
  }),
  identity: PropTypes.shape({
    interface_name: PropTypes.string,
    system_description: PropTypes.string,
  }),
  last_fetched_at: PropTypes.string,
  last_modified_at: PropTypes.string,
  role: PropTypes.string,
  status: PropTypes.string,
  system_id: PropTypes.string,
  type: PropTypes.string,
};
