import {invert, map} from 'lodash';

import {NODE_ROLES as ALL_NODE_ROLES} from '../roles.ts';

export const NODE_ROLES = {
  GENERIC: ALL_NODE_ROLES.GENERIC,
  ACCESS_SWITCH: ALL_NODE_ROLES.ACCESS,
  LEAF: ALL_NODE_ROLES.LEAF
};

export const NODE_TYPES_ROLES = {
  'generic-system': NODE_ROLES.GENERIC,
  'access-switch': NODE_ROLES.ACCESS_SWITCH,
  leaf: NODE_ROLES.LEAF
};

export const ROLES_NODE_TYPES = invert(NODE_TYPES_ROLES);

export const reZonesTitles = {
  [NODE_ROLES.GENERIC]: 'Generic Systems',
  [NODE_ROLES.ACCESS_SWITCH]: 'Access switches',
  [NODE_ROLES.LEAF]: 'Leafs'
};

export const rolesOrder = [NODE_ROLES.LEAF, NODE_ROLES.ACCESS_SWITCH, NODE_ROLES.GENERIC];

export const SINGLE_ATTACHMENT_TYPE = 'singleAttached';
export const DUAL_ATTACHMENT_TYPE = 'dualAttached';

export const FIRST_SWITCH_PEER = 'first';
export const SECOND_SWITCH_PEER = 'second';

export const NO_REDUNDANCY = 'no_redundancy';
export const REDUNDANCY = {
  MLAG: 'mlag',
  ESI: 'esi',
  NO_LAG: 'no_lag'
};

export const REDUNDANCY_NAMES = {
  MLAG: REDUNDANCY.MLAG,
  ESI: REDUNDANCY.ESI
};

export const LAG_MODES = {
  LACP_ACTIVE: 'lacp_active',
  LACP_PASSIVE: 'lacp_passive',
  STATIC: 'static_lag',
  NO_LAG: 'no_lag'
};

export const LAG_MODES_NAMES = {
  [LAG_MODES.LACP_ACTIVE]: 'LACP (Active)',
  [LAG_MODES.LACP_PASSIVE]: 'LACP (Passive)',
  [LAG_MODES.STATIC]: 'Static LAG (No LACP)',
  [LAG_MODES.NO_LAG]: 'No LAG'
};

/*  eslint-disable */
export const LAG_MODES_TITLES = {
  [LAG_MODES.LACP_ACTIVE]: 'Link Aggregation Control Group (LACP) in Active mode - This mode actively advertises LACP BPDU even when the neighbor does not',
  [LAG_MODES.LACP_PASSIVE]: 'Link Aggregation Control Group (LACP) in Passive mode - This mode will not generate LACP BPDU until it sees one from a neighbor',
  [LAG_MODES.STATIC]: 'Static LAGs do not participate in LACP and will unconditionally operate in forwarding mode',
  [LAG_MODES.NO_LAG]: 'This link is not part of a LAG'
};
/*  eslint-disable */

export const PEER_LINKS_TYPES = {
  L3_PEER: 'L3',
  L2_PEER: 'L2'
};

export const PEER = 'PEER';

export const LAG_MODES_OPTIONS = map(LAG_MODES_NAMES, (text, value) => ({text, value}));

export const HOMING_TYPES = {
  LEFT_RIGHT: 'lr',
  DOUBLE_SINGLE: 'ds'
};

export const DESIGN = {
  L3CLOS: 'l3clos',
  L3COLLAPSED: 'l3collapsed'
};

export const PORT_ROLES = {
  ...NODE_ROLES,
  PEER: 'peer',
  SPINE: 'spine',
  SUPERSPINE: 'superspine'
};

export const nodeRadius = 4;

export const draftLinksGroupId = '0';

export const maxPortChannelId = 4096;
export const maxVlanId = 4094;
