import {Component} from 'react';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {isArray, filter, map, merge} from 'lodash';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {DurationInput, interpolateRoute, request} from 'apstra-ui-common';

import generateProbeURI from '../generateProbeURI';
import InterfacesInput from './InterfacesInput';
import {environmentExpectationsRenderer} from './EnvironmentExpectationsInput';
import PredefinedEntityModal from './PredefinedEntityModal';
import IBAContext from '../IBAContext';
import TagsInput from '../../components/TagsInput';

const DURATION_FIELDS = [
  'first_summary_average_period',
  'first_summary_total_duration',
  'second_summary_average_period',
  'second_summary_total_duration',
  'duration',
  'threshold_duration',
  'average_period',
  'history_duration',
  'history_total_duration',
  'interface_counters_average_period',
  'interface_counters_history_retention_period',
  'system_counters_history_retention_period',
  'collection_period'
];

const TAGS_FIELDS = new Set([
  'external_router_tags',
  'server_tags',
  'switch_tags',
  'no_anomaly_interface_tags',
  'alarm_anomaly_interface_tags',
  'warn_anomaly_interface_tags',
]);

@observer
export default class PredefinedProbeModal extends Component {
  static defaultProps = {
    mode: 'create'
  };

  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get isCreateMode() {
    return this.props.mode === 'create';
  }

  @computed get entities() {
    const {predefinedProbes} = this.context;
    return this.isCreateMode ? filter(predefinedProbes, (probe) => !probe.experimental) : predefinedProbes;
  }

  renderInterfacesInput = ({name, value, schema, required, disabled, errors, onChange, fieldProps}) => {
    if (name === 'interfaces' && isArray(value)) {
      return (
        <InterfacesInput
          name={name}
          value={value}
          schema={schema}
          required={required}
          disabled={disabled}
          errors={errors}
          onChange={onChange}
          fieldProps={fieldProps}
        />
      );
    }
    return null;
  };

  renderDurationInput = ({name, value, schema, required, disabled, errors, onChange, fieldProps}) => {
    if (DURATION_FIELDS.includes(name)) {
      return (
        <DurationInput
          name={name}
          value={value}
          schema={schema}
          required={required}
          disabled={disabled}
          errors={errors}
          customValueType='duration'
          textPrefix={null}
          onChange={onChange}
          fieldProps={fieldProps}
        />
      );
    }
    return null;
  };

  renderTagsInput = ({name, value, schema, required, disabled, errors, onChange, fieldProps}) => {
    const {blueprintTags} = this.context;
    const knownTags = map(blueprintTags, ({label}) => label);

    if (TAGS_FIELDS.has(name)) {
      return (
        <TagsInput
          name={name}
          value={value}
          schema={schema}
          required={required}
          disabled={disabled}
          errors={errors}
          onChange={onChange}
          knownTags={knownTags}
          fieldProps={fieldProps}
        />
      );
    }
    return null;
  };

  generateResourceHref = ({blueprintId, entityId}) => {
    return generateProbeURI({blueprintId, probeId: entityId});
  };

  fetchPredefinedProbeParameters = async ({routes, blueprintId, entityName, entityId, signal}) => {
    const route = routes.predefinedProbeParameters;
    const result = await request(
      interpolateRoute(route, {blueprintId, predefinedProbe: entityName, probeId: entityId}),
      {signal}
    );
    return merge(
      result,
      this.props.mode === 'clone' && {label: `${result.label} copy`}
    );
  };

  render() {
    const {routes, blueprintId} = this.context;
    const {probe, mode, processorName, stageName, defaultPredefinedProbeName, helpPageId, ...props} = this.props;
    const {
      isCreateMode, entities, generateResourceHref,
      renderInterfacesInput, renderDurationInput, renderTagsInput,
    } = this;

    return (
      <PredefinedEntityModal
        helpPageId={helpPageId}
        mode={mode}
        resourceName='Predefined Probe'
        entities={entities}
        entityName={defaultPredefinedProbeName ?
          defaultPredefinedProbeName :
          probe ?
            probe.predefined_probe :
            undefined
        }
        entityId={probe ? probe.id : undefined}
        fetchEntityParameters={probe ? this.fetchPredefinedProbeParameters : undefined}
        baseRoute={routes.predefinedProbeList}
        generateResourceHref={generateResourceHref}
        renderers={[
          renderInterfacesInput,
          renderDurationInput,
          renderTagsInput,
          environmentExpectationsRenderer.renderValueInput,
        ]}
        extraActions={
          !isCreateMode &&
            <Button
              as={Link}
              to={generateProbeURI({blueprintId, probeId: probe.id, action: mode, processorName, stageName})}
              secondary
              icon='edit'
              size='large'
              content='Edit Processors'
            />
        }
        {...props}
      />
    );
  }
}
