import {isObject, keys, map, orderBy, reject, truncate} from 'lodash';
import {useContext, useMemo} from 'react';
import {Header, List, Popup} from 'semantic-ui-react';

import HighlightText from '../components/HighlightText';

import GraphExplorerContext from './GraphExplorerContext';

import buildPropertyDocHTMLFromSchema from '../pythonExpression/buildPropertyDocHTMLFromSchema';
import {createLinkId} from './GraphExplorer';

const Property = ({propName, propValue, propertyDescription, highlightText, maxValueLength}) => {
  if (propValue === null) return null;
  const propValueString = truncate(String(propValue), {length: maxValueLength});
  const propertyItem = (
    <List.Item key={propName}>
      <span className='property-name'>
        {propName}
      </span>
      {' '}
      {
        isObject(propValue) ? '[...]'
          : highlightText ? <HighlightText text={propValueString} highlight={highlightText} /> : propValueString
      }
    </List.Item>
  );
  return propertyDescription ? (
    <Popup
      key={propName}
      trigger={propertyItem}
      position='right center'
      size='mini'
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{__html: buildPropertyDocHTMLFromSchema(propertyDescription)}}
      />
    </Popup>
  ) : propertyItem;
};

const GraphQueryTooltip = ({
  maxValueLength = 100,
  entity,
  highlightText,
}) => {
  const {referenceDesignSchema} = useContext(GraphExplorerContext);
  const {linkType, nodeType, sourceEntity} = entity;

  const propertyDescriptions = useMemo(() => {
    const path = entity.nodeType ? entity.nodeType
      : createLinkId({
        name: entity.linkType,
        source: entity.source.nodeType,
        target: entity.target.nodeType
      });
    return referenceDesignSchema.properties[path];
  }, [entity, referenceDesignSchema]);

  const propertyNames = useMemo(() => orderBy(
    reject(keys(sourceEntity), (propName) => propName === 'type'),
    [
      (propName) => propName !== 'id',
      (propName) => propName !== 'label',
      (propName) => propName
    ]
  ), [sourceEntity]);

  return (
    <List size='mini'>
      <List.Item>
        <Header as='h5'>
          <HighlightText
            text={nodeType || linkType}
            highlight={highlightText}
          />
        </Header>
      </List.Item>
      {map(propertyNames, (propName) => <Property
        key={propName}
        maxValueLength={maxValueLength}
        propName={propName}
        propValue={sourceEntity[propName]}
        propertyDescription={propertyDescriptions?.[propName]}
        highlightText={highlightText}
      />)}
    </List>
  );
};

export default GraphQueryTooltip;
