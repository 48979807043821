import {noop} from 'lodash';
import {useResizeDetector} from 'react-resize-detector';
import {CodeEditorControl, Field} from 'apstra-ui-common';

import './ConfigletInput.less';

export const ConfigletEditor = ({
  value, onChange = noop, ariaLabel = 'Configlet Code Editor', disabled,
  configContext = {}, minLines = 11, lineHeight = 15, configTemplateNames,
  defaultMaxLines = 30,
}) => {
  const {height, ref} = useResizeDetector({handleWidth: false});
  const maxLines = isNaN(height / lineHeight) || height / lineHeight < minLines ?
    defaultMaxLines :
    Math.floor(height / lineHeight) - 1;
  return (
    <div ref={ref} className='ui form configlet-input'>
      <CodeEditorControl
        mode='jinja'
        multiLine
        showGutter
        value={value}
        onChange={onChange}
        aria-label={ariaLabel}
        disabled={disabled}
        validate={!disabled}
        enableCompletion={!disabled}
        useWorker={!disabled}
        completerParams={{configContext, configTemplateNames}}
        minLines={minLines}
        maxLines={maxLines}
        height={`${lineHeight}px`}
        aceEditorOptions={{
          tabSize: 4,
          autoScrollEditorIntoView: true,
        }}
      />
    </div>
  );
};

const ConfigletInput = ({
  label = 'Template Text', readOnly = false, value, onChange, disabled, errors, required,
  configContext,
}) => {
  return readOnly ? (
    <ConfigletEditor value={value} ariaLabel='Configlet Viewer' disabled />
  ) : (
    <Field required={required} label={label} errors={errors} disabled={disabled}>
      <ConfigletEditor configContext={configContext} value={value} onChange={onChange} disabled={disabled} />
    </Field>
  );
};

export default ConfigletInput;
