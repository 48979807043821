import {cloneElement, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';
import {saveAs} from 'file-saver';
import copy from 'copy-to-clipboard';
import {isEmpty, omit} from 'lodash';
import {AsyncActionsModal, CodeEditorControl, FetchData, Loader, FetchDataError} from 'apstra-ui-common';

export default function ExportModal({
  mode = 'json', mimeType = 'text/plain', fileName,
  extraTopContent, extraBottomContent, actions, ...props
}) {
  const exportCallback = props.export;
  const [contents, setContents] = useState('');

  const runExport = useCallback(async () => {
    setContents(await exportCallback());
  }, [exportCallback]);

  const copyContents = useCallback(() => {
    copy(contents);
  }, [contents]);

  const saveAsFile = useCallback(() => {
    saveAs(new Blob([contents], {type: mimeType}), fileName);
  }, [contents, fileName, mimeType]);

  const modalProps = omit(props, ['export', 'resetState']);

  return (
    <FetchData
      customLoader
      fetchData={runExport}
      fetchParams={{exportCallback}}
      pollingInterval={null}
    >
      {({loaderVisible, fetchDataError}) =>
        <AsyncActionsModal
          closeIcon
          content={
            () =>
              <>
                {extraTopContent}
                <Form>
                  {loaderVisible ?
                    <Loader />
                  : fetchDataError ?
                    <FetchDataError error={fetchDataError} />
                  :
                    <CodeEditorControl
                      mode={mode}
                      value={contents}
                      useWorker={false}
                      multiLine
                      showGutter
                      maxLines={20}
                      disabled
                    />
                  }
                </Form>
                {extraBottomContent &&
                  cloneElement(extraBottomContent, {contents, exportError: fetchDataError})
                }
              </>
          }
          actions={isEmpty(actions) ? [
            {
              key: 'copy',
              secondary: true,
              content: 'Copy',
              icon: 'copy',
              onClick: copyContents,
              disabled: !contents
            },
            {
              key: 'save',
              primary: true,
              content: 'Save As File',
              icon: 'save',
              onClick: saveAsFile,
              disabled: !contents
            },
          ] : actions}
          {...modalProps}
        />
      }
    </FetchData>
  );
}

ExportModal.propTypes = {
  export: PropTypes.func.isRequired,
  mimeType: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  actions: PropTypes.array,
  mode: PropTypes.string,
  extraTopContent: PropTypes.node,
  extraBottomContent: PropTypes.node,
};
