import {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import {Ref, Accordion, Icon, List, Menu, Button} from 'semantic-ui-react';
import {Checkbox} from 'apstra-ui-common';
import cx from 'classnames';
import {filter, map, isUndefined, groupBy, xor} from 'lodash';
import PropTypes from 'prop-types';

import humanizeString from '../humanizeString';

import './DropdownWithCheckboxes.less';

const DROPDOWN_ACTIONS = {
  SELECT_ALL: 'select_all',
  DESELECT_ALL: 'deselect_all',
  APPLY: 'apply',
};

const ACTION_TYPE = {
  SELECTION_CONTROL: 'selection-control',
  PRIMARY: 'primary',
};

const DropdownWithCheckboxes = ({
  dropdownLabel,
  items,
  mandatoryItems = [],
  currentSelection = [],
  addControlButtons = false,
  unlimitedHeight = false,
  widthScale,
  style,
  onApplySelection,
}) => {
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [localCurrentSelection, setLocalCurrentSelection] = useState(currentSelection);

  useEffect(() => {
    const onClickOutside = (e) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        onClose();
        dropdownContentRef.current.scrollTop = 0;
      }
    };

    window.addEventListener('click', onClickOutside);
    return (() => {
      window.removeEventListener('click', onClickOutside);
    });
  });

  const sortedItems = useMemo(() =>
    [
      ...filter(items, ({value}) => mandatoryItems.includes(value)),
      ...filter(items, ({value}) => !mandatoryItems.includes(value)),
    ]
  , [items, mandatoryItems]);

  const selection = addControlButtons ? localCurrentSelection : currentSelection;

  const onClose = useCallback(() => {
    setIsOpen(false);
    // reset not applied selection when close the dropdown, otherwise, it's unclear which selection is applied in fact
    if (addControlButtons && xor(currentSelection, localCurrentSelection).length !== 0) {
      setLocalCurrentSelection(currentSelection);
    }
  }, [addControlButtons, currentSelection, localCurrentSelection]);

  const onTitleClick = useCallback((isOpen) => {
    if (isOpen) {
      setIsOpen(true);
    } else {
      onClose();
    }
  }, [onClose]);

  const onSelectAll = useCallback(() => {
    if (localCurrentSelection.length !== sortedItems.length) {
      setLocalCurrentSelection(map(sortedItems, 'value'));
    }
  }, [sortedItems, localCurrentSelection, setLocalCurrentSelection]);

  const onDeselectAll = useCallback(() => {
    if (localCurrentSelection.length !== 0) {
      setLocalCurrentSelection([]);
    }
  }, [localCurrentSelection]);

  const onApply = useCallback(() => {
    onApplySelection(localCurrentSelection);
  }, [onApplySelection, localCurrentSelection]);

  const onSelect = useCallback((value) => {
    const newSelection = xor(selection, [value]);
    if (addControlButtons) {
      setLocalCurrentSelection(newSelection);
    } else {
      onApplySelection(newSelection);
    }
  }, [onApplySelection, addControlButtons, selection, setLocalCurrentSelection]);

  const actionButtons = useMemo(() => {
    return groupBy(
      [
        {
          key: DROPDOWN_ACTIONS.SELECT_ALL,
          label: humanizeString(DROPDOWN_ACTIONS.SELECT_ALL),
          actionType: ACTION_TYPE.SELECTION_CONTROL,
          actionFn: onSelectAll,
        },
        {
          key: DROPDOWN_ACTIONS.DESELECT_ALL,
          label: humanizeString(DROPDOWN_ACTIONS.DESELECT_ALL),
          actionType: ACTION_TYPE.SELECTION_CONTROL,
          actionFn: onDeselectAll,
        },
        {
          key: DROPDOWN_ACTIONS.APPLY,
          label: humanizeString(DROPDOWN_ACTIONS.APPLY),
          actionType: ACTION_TYPE.PRIMARY,
          actionFn: onApply,
        },
      ],
      'actionType'
    );
  }, [onSelectAll, onDeselectAll, onApply]);

  const panels = [
    {
      key: 'title',
      title: {
        role: 'button',
        tabIndex: 0,
        children: (
          <>
            <span className='label'>
              {dropdownLabel && `${dropdownLabel} `}
              {`(${selection.length}/${items.length})`}
            </span>
            <Icon name={`triangle ${isOpen ? 'down' : 'left'}`} />
          </>
        )
      },
      content: {
        content: (
          <>
            <Ref innerRef={dropdownContentRef}>
              <List
                key='options'
                selection
                items={
                  map(sortedItems, ({key, value, text}) => ({
                    key,
                    children: (
                      <Checkbox
                        label={text}
                        checked={selection.includes(value) || mandatoryItems.includes(value)}
                        disabled={mandatoryItems.includes(value)}
                        onChange={() => onSelect(value)}
                      />
                    ),
                    className: cx({disabled: mandatoryItems.includes(value)}),
                  }))}
              />
            </Ref>
            {addControlButtons && (
              <div className='actions-section'>
                {map(actionButtons, (buttons, actionType) =>
                  buttons.length !== 0 && (
                    <div key={actionType} className={actionType}>
                      {map(buttons, ({key, label, actionType, actionFn}) =>
                        actionType === ACTION_TYPE.PRIMARY
                          ? (
                            <Button
                              key={key}
                              compact
                              size='mini'
                              className={actionType}
                              content={label}
                              onClick={actionFn}
                            />
                          )
                          : (
                            <button
                              key={key}
                              className={actionType}
                              onClick={actionFn}
                            >
                              <span>{label}</span>
                            </button>
                          )
                      )}
                    </div>
                  )
                )}
              </div>
            )}
          </>
        )
      }
    },
  ];

  return (
    <Ref innerRef={dropdownRef}>
      <Accordion
        styled
        vertical
        as={Menu}
        panels={panels}
        activeIndex={isOpen ? 0 : -1}
        onTitleClick={() => onTitleClick(!isOpen)}
        className={cx(
          'dropdown-with-checkboxes',
          style,
          {
            open: isOpen,
            'unlimited-height': unlimitedHeight,
            'with-controls': addControlButtons,
            [`width-scale-${widthScale}`]: !isUndefined(widthScale),
          }
        )}
      />
    </Ref>
  );
};

DropdownWithCheckboxes.propTypes = {
  dropdownLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  mandatoryItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  currentSelection: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  addControlButtons: PropTypes.bool,
  unlimitedHeight: PropTypes.bool,
  widthScale: PropTypes.number,
  style: PropTypes.string,
  onApplySelection: PropTypes.func,
};

export default DropdownWithCheckboxes;
