import {Component} from 'react';
import {Grid, Label, Popup, Table} from 'semantic-ui-react';
import {action, computed, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {chain, compact, find, findKey, groupBy, keys, mapValues} from 'lodash';
import {onEnterKeyHandler} from 'apstra-ui-common';

import './SystemPortMapView.less';

const ASSIGNMENT_STATUS = {
  AVAILABLE: 'AVAILABLE',
  TO_CURRENT: 'TO_CURRENT',
  TO_ANOTHER: 'TO_ANOTHER',
  UNAVAILABLE: 'UNAVAILABLE',
  PARTIAL: 'PARTIAL',
};

@observer
export default class SystemPortMapView extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get slotsMap() {
    const {mapPorts} = this;
    const {ports} = this.props;

    const mappedPorts = mapPorts(ports);

    const slots = groupBy(mappedPorts, 'slotId');
    return mapValues(slots, (ports) => groupBy(ports, 'panelId'));
  }

  mapPorts = (ports) => {
    const {mapTransformations} = this;

    return ports.map((port) => {
      const {
        slot_id: slotId, panel_id: panelId,
        row_id: rowId, port_id: portId,
        transformations
      } = port;

      const mappedTransformations = mapTransformations(transformations);

      const interfaceStatuses = chain(mappedTransformations)
        .map(({interfaces}) => interfaces)
        .flatten()
        .map(({status}) => status)
        .uniq()
        .value();

      let status;
      if (interfaceStatuses.length === 1) {
        status = interfaceStatuses[0];
      } else {
        status = ASSIGNMENT_STATUS.PARTIAL;
      }

      return {
        slotId,
        panelId,
        rowId,
        portId,
        status,
        transformations: mappedTransformations
      };
    });
  };

  mapTransformations = (transformations) => {
    const {mapInterfaces} = this;
    const {interfaceMap} = this.props;

    return transformations
      .map((transformation) => {
        const {is_default: isDefault, transformation_id: transformationId, interfaces} = transformation;

        const {speed} = interfaces.find(({speed}) => speed) || {};
        const speedLabel = speed ? `${speed.value}${speed.unit}` : null;

        return {
          transformationId,
          isDefault,
          interfaces: mapInterfaces(interfaces),
          speedLabel
        };
      })
      .filter(({transformationId, interfaces: transformationInterfaces}) => {
        return interfaceMap.interfaces
          .filter(({mapping}) => mapping[1] === transformationId)
          .some(({name}) =>
            transformationInterfaces.some(
              ({name: transformationInterfaceName}) => name === transformationInterfaceName
            )
          );
      });
  };

  mapInterfaces = (interfaces) => {
    const {policy, interfaceAssignments} = this.props;
    const {id: policyId, label: policyLabel} = policy;

    return interfaces.map((ifc) => {
      const {name, interface_id: interfaceId} = ifc;

      const assignmentId = findKey(interfaceAssignments, {if_name: name});
      const assignment = assignmentId && interfaceAssignments[assignmentId];

      let status = ASSIGNMENT_STATUS.AVAILABLE;
      let assignedTo;

      if (!assignment) {
        status = ASSIGNMENT_STATUS.UNAVAILABLE;
      } else {
        const {policy_id: assignmentPolicyId, policy_label: assignmentPolicyLabel} = assignment;

        if (assignmentPolicyId === policyId) {
          status = ASSIGNMENT_STATUS.TO_CURRENT;
          assignedTo = policyLabel || policyId;
        } else if (assignmentPolicyId !== null) {
          status = ASSIGNMENT_STATUS.TO_ANOTHER;
          assignedTo = assignmentPolicyLabel || assignmentPolicyId;
        }
      }

      return {
        interfaceId,
        name,
        status,
        assignmentId,
        assignedTo
      };
    });
  };

  render() {
    const {slotsMap} = this;
    const {onSelectInterface} = this.props;

    return (
      <div className='system-port-map-view'>
        <SlotsList
          {...{
            slotsMap,
            onSelectInterface
          }}
        />
      </div>
    );
  }
}

function SlotsList({slotsMap, onSelectInterface}) {
  const slots = keys(slotsMap);

  return (
    <div className='slots-list'>
      {
        slots
          .sort((a, b) => Number(a) - Number(b))
          .map((slotId) => {
            const panelsMap = slotsMap[slotId];

            return (
              <Slot
                key={slotId}
                {...{
                  id: slotId,
                  panelsMap,
                  hasLabel: slots.length > 1,
                  onSelectInterface
                }}
              />
            );
          })
      }
    </div>
  );
}

function Slot({id, panelsMap, hasLabel, onSelectInterface}) {
  return (
    <div className={cx('slot', {'with-label': hasLabel})}>
      {hasLabel &&
        <span className='slot-title'>
          {`Slot #${id}`}
        </span>
      }

      <PanelsList
        {...{
          panelsMap,
          onSelectInterface
        }}
      />
    </div>
  );
}

function PanelsList({panelsMap, onSelectInterface}) {
  const panels = keys(panelsMap);

  return (
    <div className='panels-list'>
      {panels
        .sort((a, b) => Number(a) - Number(b))
        .map((panelId) => {
          const portsMap = panelsMap[panelId];

          return (
            <Panel
              key={panelId}
              {...{
                id: panelId,
                portsMap,
                hasLabel: panels.length > 1,
                onSelectInterface
              }}
            />
          );
        })}
    </div>
  );
}

function Panel({id, portsMap, hasLabel, onSelectInterface}) {
  return (
    <div className={cx('panel', {'with-label': hasLabel})}>
      {hasLabel &&
        <span className='panel-title'>
          {`Panel #${id}`}
        </span>
      }

      <PanelContent
        {...{
          portsMap,
          onSelectInterface
        }}
      />
    </div>
  );
}

@observer
class PanelContent extends Component {
  @observable selectedPortId = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get selectedPort() {
    return find(this.props.portsMap, {portId: this.selectedPortId});
  }

  @action
  onSelectPort = (portId) => {
    if (this.selectedPortId === portId) {
      this.selectedPortId = null;
    } else {
      this.selectedPortId = portId;
    }
  };

  render() {
    const {selectedPortId, selectedPort, onSelectPort} = this;
    const {portsMap, onSelectInterface} = this.props;

    return (
      <Grid className='panel-content'>
        <Grid.Row>
          <Grid.Column>
            <PortsMap
              {...{
                portsMap,
                selectedPortId,
                onSelectPort
              }}
            />
          </Grid.Column>
        </Grid.Row>
        {this.selectedPort &&
          <Grid.Row>
            <Grid.Column>
              <PortDetails
                {...{
                  port: selectedPort,
                  onSelectInterface,
                }}
              />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    );
  }
}

class PortsMap extends Component {
  renderPort = (port) => {
    const {selectedPortId, onSelectPort} = this.props;
    const {portId, status} = port;

    const selected = portId === selectedPortId;

    return (
      <Port
        key={portId}
        {...{
          selected,
          portId,
          status,
          onSelectPort,
        }}
      />
    );
  };

  render() {
    const {renderPort} = this;
    const {portsMap} = this.props;

    const rows = groupBy(portsMap, 'rowId');

    return (
      <table className='ports-map' role='grid'>
        <tbody>
          {keys(rows).map((rowId) =>
            <tr key={rowId} role='row'>
              {rows[rowId].map(renderPort)}
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

@observer
class Port extends Component {
  @observable hovered = false;

  @action
  hover = (hovered) => {
    this.hovered = hovered;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {hovered, hover} = this;
    const {portId, selected, status, onSelectPort} = this.props;

    const isDisabled = !(status === ASSIGNMENT_STATUS.AVAILABLE || status === ASSIGNMENT_STATUS.TO_CURRENT);
    const canClick = !isDisabled;

    return (
      <td
        role='gridcell'
        tabIndex={portId}
        className={cx(
          'port',
          {selected: selected || hovered},
          {
            teal: status === ASSIGNMENT_STATUS.TO_CURRENT,
            violet: status === ASSIGNMENT_STATUS.TO_ANOTHER,
            grey: status === ASSIGNMENT_STATUS.UNAVAILABLE,
            blue: status === ASSIGNMENT_STATUS.PARTIAL,
            disabled: isDisabled,
          }
        )}
        onClick={canClick && (() => onSelectPort(portId))}
        onKeyDown={canClick && onEnterKeyHandler(onSelectPort, portId)}
        onMouseEnter={() => hover(true)}
        onMouseLeave={() => hover(false)}
        onFocus={() => hover(true)}
        onBlur={() => hover(false)}
      >
        {portId}
      </td>
    );
  }
}

function PortDetails({port, onSelectInterface}) {
  return (
    <div className='port-details'>
      <Table definition size='small'>
        <Table.Body>
          {port.transformations
            .map(({transformationId: id, isDefault, interfaces, speedLabel}) => {
              return (
                <Table.Row key={id}>
                  <Table.Cell width={4}>
                    {`Port #${port.portId} Tr. #${id}` + ((isDefault || speedLabel) ?
                      ` (${compact([
                        speedLabel,
                        isDefault ? 'default' : ''
                      ]).join(', ')})` : '')
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {interfaces.map((ifc) => {
                      const {interfaceId: id, name, status, assignmentId, assignedTo} = ifc;

                      return (
                        <Interface
                          key={id}
                          {...{
                            name,
                            status,
                            assignedTo,
                            onClick: () => {
                              if (onSelectInterface && (status === ASSIGNMENT_STATUS.AVAILABLE ||
                                status === ASSIGNMENT_STATUS.TO_CURRENT)) {
                                onSelectInterface(assignmentId);
                              }
                            }
                          }}
                        />
                      );
                    })}
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
}

function Interface({name, status, assignedTo, onClick}) {
  return (
    <Popup
      trigger={
        <Label
          basic={status === ASSIGNMENT_STATUS.AVAILABLE}
          size='small'
          className={
            cx(
              'interface',
              {
                teal: status === ASSIGNMENT_STATUS.TO_CURRENT,
                violet: status === ASSIGNMENT_STATUS.TO_ANOTHER,
                grey: status === ASSIGNMENT_STATUS.UNAVAILABLE
              }
            )
          }
          onClick={onClick}
        >
          {name}
        </Label>
      }
      wide
      content={
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{'Interface Name'}</Table.Cell>
              <Table.Cell>{name || 'N/A'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{'Assigned Policy'}</Table.Cell>
              <Table.Cell>
                {assignedTo || <Label size='mini'>{'Not assigned'}</Label>}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      }
    />
  );
}
