import {FC, useCallback, useContext, useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {Link, useNavigate} from 'react-router-dom';
import {Grid, Form, Button, Icon, Message} from 'semantic-ui-react';
import {
  map, find, some, forEach,
  chunk, get, isEmpty
} from 'lodash';
import pluralize from 'pluralize';
import {ActionsMenu, FetchDataError, FormFragment, GenericErrors, Value,
  interpolateRoute, request} from 'apstra-ui-common';

import generateProbeURI from '../../generateProbeURI';
import ProbeDeletionModal from '../ProbeDeletionModal';
import {descriptionRenderer} from '../../descriptionRenderer';
import ProbeActions from '../ProbeActions';
import ProbeGraph from './ProbeGraph';
import ProbeExportModal from '../ProbeExportModal';
import ProbeImportModal from '../ProbeImportModal';
import PredefinedProbeModal from '../PredefinedProbeModal';
import IBAContext from '../../IBAContext';
import PredefinedDashboardsLinks from '../PredefinedDashboardsLinks';
import {ProbeBreadcrumb} from './ProbeBreadcrumb';
import {useProbeDetailsStore} from './ProbeDetailsStore';

import {Probe} from '../../types';
import {ProbeItems} from './ProbeItems';
import {withScrollProvider, withScrollContext, ProcessorContextValue} from './ScrollContext';

import './ProbeDetails.less';

export type ProbeDetailsProps = {
  action?: 'create' | 'update' | 'clone';
  diskUsage: {
    available: string[];
    is_managed: boolean;
    size: number;
    size_purged: number;
  };
  probe: Probe;
  probeId?: string;
  probePropertiesSchema: any[];
  refetchData: () => void;
  telemetryServiceRegistryItems: Array<{
    application_schema: {
      properties: {
        key: {
          properties: Record<string, any>;
        },
        value: {
          properties: any;
          required?: string[];
        }
      };
      required?: any[];
      type: string;
    };
    builtin: boolean;
    description: string;
    service_name: string;
    storage_schema_path: string;
    version: string;
  }>;
  scrollContext?: ProcessorContextValue;
};

export const ProbeDetails: FC<ProbeDetailsProps> = withScrollProvider(withScrollContext(observer((props) => {
  const {blueprintId} = useContext(IBAContext);
  const navigate = useNavigate();
  const formAnchorRef = useRef<HTMLAnchorElement>(null);
  const scrollToForm = useCallback(
    () => formAnchorRef.current?.scrollIntoView({block: 'start', behavior: 'smooth'}),
    []
  );

  const store = useProbeDetailsStore({...props, scrollToForm});

  useEffect(() => {
    if (store.currentProcessorName) {
      props.scrollContext?.scrollToElement('processor step', store.currentProcessorName, 'instant');
      props.scrollContext?.scrollToElement('processor details', store.currentProcessorName);
    }
  }, [props.scrollContext, store.currentProcessorName]);

  useEffect(() => {
    if (store.currentStageName) {
      props.scrollContext?.scrollToElement('stage step', store.currentStageName, 'instant');
      props.scrollContext?.scrollToElement('stage details', store.currentStageName);
    }
  }, [props.scrollContext, store.currentStageName]);

  return (
    <Grid className='probe-details'>
      <Grid.Row>
        <Grid.Column width={12} verticalAlign='middle'>
          <ProbeBreadcrumb
            action={props.action}
            diskUsage={props.diskUsage}
            probe={store.probe}
            probeId={props.probeId}
            refetchData={props.refetchData}
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          {!props.action ?
            <ProbeActions
              probe={store.probe}
              processorName={store.currentProcessorName}
              stageName={store.currentStageName}
              setProbeDeletionModalProps={store.setProbeDeletionModalProps}
              setProbeExportModalProps={store.setProbeExportModalProps}
              setPredefinedProbeModalProps={store.setPredefinedProbeModalProps}
            />
          :
            <ActionsMenu
              items={[{
                icon: 'share square',
                title: 'Export',
                disabled: !!store.currentActionInProgress || !store.isProbeValid,
                onClick: () => store.setProbeExportModalProps({open: true, probe: store.probe}),
              }]}
            />
          }
        </Grid.Column>
      </Grid.Row>
      {!props.action && store.probe.description &&
        <Grid.Row>
          <Grid.Column>
            <Value name='description' value={store.probe.description} renderers={[descriptionRenderer.renderValue]} />
          </Grid.Column>
        </Grid.Row>
      }
      {!props.action && store.probe.state === 'error' && (store.probe.last_error || store.probe.task_error) &&
        <Grid.Row>
          <Grid.Column>
            {store.probe.last_error &&
              <GenericErrors errors={store.probe.last_error.message}>
                <ProbeTelemetryWarningServiceStageLink probe={store.probe} blueprintId={blueprintId} />
              </GenericErrors>
            }
            {store.probe.task_error && <GenericErrors errors={store.probe.task_error} />}
          </Grid.Column>
        </Grid.Row>
      }
      {props.action &&
        <Grid.Row>
          <Grid.Column>
            <a ref={formAnchorRef} />
            <Form className='probe-properties'>
              {chunk(props.probePropertiesSchema, 2).map((schemaChunk, index) =>
                <Form.Group key={index} widths='equal'>
                  <FormFragment
                    schema={schemaChunk}
                    values={store.probeProperties}
                    renderers={[descriptionRenderer.renderValueInput]}
                    errors={store.propertyErrorMessages}
                    disabled={!!store.currentActionInProgress}
                    onChange={store.setProbePropertyValue}
                  />
                </Form.Group>
              )}
            </Form>
          </Grid.Column>
        </Grid.Row>
      }
      {store.probeHasUnsupportedProcessorTypes ? (
        <Grid.Row>
          <Grid.Column>
            <Message icon warning>
              <Icon name='warning sign' />
              <Message.Content>
                <Message.Header>
                  {'This probe contains processors with unsupported types and cannot be rendered'}
                </Message.Header>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row>
          <Grid.Column
            className='probe-nav probe-sticky'
            width={store.probeNavigationExpanded ? 10 : 4}
          >
            <ProbeGraph
              probe={store.probe}
              currentProcessor={store.currentEntities.processor}
              currentStageName={store.currentStageName}
              setCurrentProcessorName={store.setCurrentProcessorName}
              setCurrentStageName={store.setCurrentStageName}
              highlights={store.highlights}
              highlightProcessorAndRelatedStages={store.highlightProcessorAndRelatedStages}
              highlightStageAndRelatedProcessors={store.highlightStageAndRelatedProcessors}
              highlightCurrentEntity={store.highlightCurrentEntity}
              actionInProgress={!!store.currentActionInProgress}
              errors={store.errors}
              editable={!!props.action}
              processorDefinitionsByName={store.processorDefinitionsByName}
              toggleProbeNavigationExpanded={store.toggleProbeNavigationExpanded}
              probeNavigationExpanded={store.probeNavigationExpanded}
            />
          </Grid.Column>
          <Grid.Column className='probe-contents' width={store.probeNavigationExpanded ? 6 : 12}>
            <ProbeItems
              editable={!!props.action}
              store={store}
              telemetryServiceRegistryItems={props.telemetryServiceRegistryItems}
            />
            {isEmpty(store.probe.processors) &&
              <EmptyProbeScreen
                isNew={!props.probeId}
                probe={store.probe}
                setCurrentProcessorName={store.setCurrentProcessorName}
              />
            }
          </Grid.Column>
        </Grid.Row>
      )}
      {!!store.processorsErrors.length &&
        <Grid.Row>
          <Grid.Column>
            <GenericErrors errors={map(store.processorsErrors, 'message')} />
          </Grid.Column>
        </Grid.Row>
      }
      {store.httpError &&
        <Grid.Row>
          <Grid.Column>
            <FetchDataError
              error={store.httpError.error}
            />
          </Grid.Column>
        </Grid.Row>
      }
      {props.action === 'update' && store.props.probe.predefined_probe && store.isProbeDirty &&
        <Grid.Row>
          <Grid.Column>
            <Message icon warning>
              <Icon name='warning sign' />
              <Message.Content>
                <Message.Header>{'Modification of Predefined Probe'}</Message.Header>
                <p>{'After saving the changes, it won\'t be possible to edit this probe as a predefined probe.'}</p>
                {store.predefinedDashboards.length > 0 && (
                  <p>
                    {'Also, the following predefined '}
                    {pluralize('dashboard', store.predefinedDashboards.length)}
                    {' will no longer be predefined:'}
                    <PredefinedDashboardsLinks dashboards={store.predefinedDashboards} />
                  </p>
                )}
                <Button
                  icon='undo'
                  content='Revert Probe Changes'
                  onClick={store.revertProbeGraph}
                />
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      }
      {props.action &&
        <Grid.Row>
          <Grid.Column textAlign='center'>
            {props.probeId ?
              <>
                <Button
                  primary size='large'
                  disabled={!!store.currentActionInProgress || !store.isProbeValid}
                  loading={store.currentActionInProgress === 'update'}
                  onClick={() => store.submit({action: props.action})}
                  content={props.action === 'update' ? 'Update Probe' : 'Create Probe'}
                />
                <Button
                  className='ghost'
                  size='large'
                  disabled={!!store.currentActionInProgress}
                  as={Link}
                  to={generateProbeURI(
                    {blueprintId, probeId: props.probeId, processorName: store.currentProcessorName!,
                      stageName: store.currentStageName!}
                  )}
                  content='Cancel'
                />
              </>
            :
              <Button
                primary size='large'
                disabled={!!store.currentActionInProgress || !store.isProbeValid}
                loading={store.currentActionInProgress === 'create'}
                onClick={() => store.submit({action: 'create'})}
                content='Create Probe'
              />
            }
          </Grid.Column>
        </Grid.Row>
      }
      <ProbeDeletionModal
        open={false}
        onClose={() => store.setProbeDeletionModalProps({open: false})}
        onSuccess={() => navigate(generateProbeURI({blueprintId}))}
        {...store.probeDeletionModalProps}
      />
      <ProbeExportModal
        open={false}
        onClose={() => store.setProbeExportModalProps({open: false})}
        {...store.probeExportModalProps}
      />
      <PredefinedProbeModal
        open={false}
        onClose={() => store.setPredefinedProbeModalProps({open: false})}
        {...store.predefinedProbeModalProps}
      />
    </Grid>
  );
})));

// @ts-expect-error TODO: react-query
ProbeDetails.pollingInterval = 10000;

ProbeDetails.defaultProps = {
  probePropertiesSchema: [
    {
      name: 'label',
      required: true,
      schema: {title: 'Name', type: 'string'}
    },
    {
      name: 'enabled',
      appearance: 'toggle',
      schema: {
        type: 'boolean',
        title: 'Enabled',
        description: "Disabled probes don't produce data and don't raise anomalies.",
      }
    },
    {
      name: 'description',
      schema: {title: 'Description', type: 'string'}
    },
  ]
};

// @ts-expect-error TODO: react-query
ProbeDetails.fetchTelemetryServiceRegistryItems = async ({routes, signal}) => {
  const {items: telemetryServiceRegistryItems} = await request(routes.telemetryServiceRegistry, {signal});
  return {telemetryServiceRegistryItems};
};

// @ts-expect-error TODO: react-query
ProbeDetails.fetchData = async ({blueprintId, probeId, routes, previousData, signal}) => {
  const {telemetryServiceRegistryItems} = previousData ?
    previousData :
    // @ts-expect-error TODO: react-query
    await ProbeDetails.fetchTelemetryServiceRegistryItems({routes, signal});

  const route = routes.probeDetails;
  const probe = await request(interpolateRoute(route, {
    blueprintId,
    probeId
  }), {signal});

  // Disk usage can be empty for a new probe. In this case response returns 404 status code.
  let diskUsage = null;
  try {
    diskUsage = some(probe?.stages, {enable_metric_logging: true}) ?
      await request(interpolateRoute(routes.diskSpaceUsageForProbe, {
        blueprintId,
        probeId
      }), {signal}) :
      null;
  } catch {
  }
  return {probe, diskUsage, telemetryServiceRegistryItems};
};

type ProbeTelemetryWarningServiceStageLinkProps = {
  blueprintId: string;
  probe: Probe;
}

export const ProbeTelemetryWarningServiceStageLink: FC<ProbeTelemetryWarningServiceStageLinkProps> = ({
  probe, blueprintId
}) => {
  const telemetryServiceWarningState = get(probe, ['probe_state', 'telemetry_service', 'state']);
  if (telemetryServiceWarningState !== 'error') return null;
  const findStageWithWarnings = () => {
    let result: string | null = null;
    forEach(probe.processors, (processor) => {
      forEach(processor.outputs, (stageName) => {
        const stage = find(probe.stages, {name: stageName})!;
        if (stage?.warnings > 0) {
          result = stageName;
          return false;
        }
      });
      if (result) return false;
    });
    return result;
  };
  const stageName = findStageWithWarnings();
  return stageName ?
    <b>
      <Link to={generateProbeURI({blueprintId, probeId: probe.id, stageName})}>
        {'Navigate to stage'}
      </Link>
    </b> : null;
};

export const EmptyProbeScreen = ({probe, isNew, setCurrentProcessorName}) => {
  return (
    isNew ?
      <div className='empty-probe-screen'>
        <div>
          {
            'Start creation of a new probe by adding a processor. ' +
            'Alternatively, you can import a probe from JSON.'
          }
        </div>
        <ProbeImportModal
          trigger={
            <Button
              size='large'
              secondary
              icon='upload'
              content='Import Probe'
            />
          }
          probe={probe}
          onSuccess={() => {
            setCurrentProcessorName(probe.processors[0].name);
          }}
        />
      </div>
    :
      <>{'Add at least one processor to a probe.'}</>
  );
};
