import {BaseSyntheticEvent, FC} from 'react';
import cx from 'classnames';

import {PairRendering} from '../types';
import {Rect} from './Rect';

import './Node.less';

export type NodeProps = {
  rx?: number;
  baseWidth?: number;
  baseHeight?: number;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  label?: string;
  fallbackLabel?: string;
  className?: string;
  pairRendering?: PairRendering;
  testDataAttributes?: any;
  icon?: string;
  onClick?: () => void;
  onMouseOut?: (e: BaseSyntheticEvent) => void;
  onBlur?: (e: BaseSyntheticEvent) => void;
  onMouseOver?: (e: BaseSyntheticEvent) => void;
  onFocus?: (e: BaseSyntheticEvent) => void;
};

export const Node: FC<NodeProps> = ({
  baseWidth = 80, baseHeight = 30, rx = 4, testDataAttributes = {},
  width, height, top = 0, left = 0, label, fallbackLabel, className, pairRendering, icon,
  onClick, onMouseOut, onBlur, onMouseOver, onFocus,
}) => {
  const rectWidth = width ?? baseWidth;
  const rectHeight = height ?? baseHeight;

  const shape = pairRendering ? (
    <Rect
      width={rectWidth}
      height={rectHeight}
      r={rx}
      left={pairRendering === PairRendering.Left}
    />
    ) : (
      <rect
        width={rectWidth}
        height={rectHeight}
        rx={rx}
      />
    );

  return (
    <g
      className={cx('topology-node', className)}
      transform={`translate(${left},${top})`}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      onBlur={onBlur}
      onFocus={onFocus}
      {...testDataAttributes}
    >
      {shape}
      {(!!label || !!fallbackLabel) && (
        <foreignObject x={0} y={0} width={rectWidth} height={rectHeight}>
          <div className={cx('text', {'fallback-label': !label})}>
            {!!icon && <i className={cx('icon', icon)} />}
            {label || fallbackLabel}
          </div>
        </foreignObject>
      )}
    </g>
  );
};
