import {useContext, useState} from 'react';
import {Button} from 'semantic-ui-react';
import {has, includes, map, noop, forEach, sortBy, isEqual, last} from 'lodash';
import {observer} from 'mobx-react';
import cx from 'classnames';

import BrickIcon from './BrickIcon';
import QueryNode from './QueryNode';
import QueryStartPath from './QueryStartPath';
import QueryProperty from './QueryProperty';
import QueryMethod from './QueryMethod';
import QueryBuilderContext from './QueryBuilderContext';
import QueryDeleteButton from './QueryDeleteButton';
import QueryCollapseButton from './QueryCollapseButton';
import getAvailableTypesFromPath from '../pythonExpression/getAvailableTypesFromPath';
import {IN, namedNodesFunctions, OUT, NODE} from '../pythonExpression/consts';
import {calculateRange, getPathForNode, getPositions, getUniqNames} from './utils';

import './QueryBrick.less';

const QueryBrick = observer(({
  query = {}, canAddPath, deleteEl = null, queryPath = [],
  removePathAfterThisNode = noop, uniqNames = getUniqNames(query), isRoot = false,
  startRow = 0, queryKeyPath,
}) => {
  const {data, onHover, onLeave, removePathQuery} = useContext(QueryBuilderContext);
  const {name, path} = query;
  const [collapsed, setCollapsed] = useState(false);
  const availableTypes = [];
  forEach(getAvailableTypesFromPath(queryPath, name, data), (value) => {
    forEach(value, (type) => {
      availableTypes.push(type);
    });
  });
  const typeOptions = map(sortBy(availableTypes), (type) => ({
    key: type, value: type, text: type
  }));
  const className = cx('query-brick wrapper', {
    bordered: !isRoot,
    positive: !isRoot,
  });

  if (!has(query, 'name') || !query.name) {
    return (
      <QueryStartPath query={query} className={className} isRoot={isRoot} queryKeyPath={queryKeyPath} />
    );
  }
  const startRowForPath = calculateRange({...query, path: []});
  const pathPositions = getPositions(query.path, startRow + startRowForPath - 1, query.name === 'optional');
  const endRow = startRow + calculateRange(query) - 1;

  return (
    <>
      {includes(['optional', 'match'], name) ?
        <QueryNodeList
          query={query}
          deleteEl={deleteEl}
          className={className}
          startRow={startRow}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          queryKeyPath={queryKeyPath}
        /> :
        <>
          <div
            className={cx(className, {
              'query-relationship': includes([IN, OUT], name),
              'query-node-brick': name === NODE,
            })}
            onMouseMove={(e) => onHover(e, startRow, endRow)}
            onMouseLeave={onLeave}
          >
            <div className='title'>
              <span>
                <BrickIcon name={name} />
                {name}
              </span>
              <Button.Group size='tiny'>
                {deleteEl}
                <QueryCollapseButton collapsed={collapsed} onClick={setCollapsed} />
              </Button.Group>
            </div>
            {collapsed ?
              null :
              namedNodesFunctions.has(name) ?
                <QueryNode
                  query={query}
                  typeOptions={typeOptions}
                  removePathAfterThisNode={removePathAfterThisNode}
                  queryKeyPath={queryKeyPath}
                /> :
                <QueryProperty
                  query={query}
                  uniqNames={uniqNames}
                  startRow={startRow}
                  queryKeyPath={queryKeyPath}
                />
            }
          </div>
        </>
      }
      {!collapsed && map(path, (childQuery, index) =>
        <QueryBrick
          key={`${childQuery.name}${index}`}
          query={childQuery}
          deleteEl={index === path.length - 1 ?
            <QueryDeleteButton
              onClick={() => removePathQuery(queryKeyPath, index - 1)}
            /> :
            null
          }
          queryPath={getPathForNode(path, query.type ?? '', index)}
          removePathAfterThisNode={() => removePathQuery(queryKeyPath, index)}
          uniqNames={getUniqNames(query)}
          isRoot={isRoot}
          startRow={pathPositions[index]}
          queryKeyPath={[...queryKeyPath, 'path', index]}
        />
      )}
      {canAddPath &&
        <QueryMethod query={query} />
      }
    </>
  );
});

const QueryNodeList = observer(({
  query, deleteEl = null, className, startRow, collapsed, setCollapsed, queryKeyPath
}) => {
  const {onHover, onLeave, addNode, deleteNode, removePathQuery} = useContext(QueryBuilderContext);
  const positions = getPositions(query.value, startRow, query.name === 'optional');
  const endRow = startRow + calculateRange(query) - 1;
  const getChildPath = (index) => [...queryKeyPath, 'value', index];

  return (
    <div
      className={className}
      onMouseMove={(e) => onHover(e, startRow, endRow)}
      onMouseLeave={onLeave}
    >
      <div className='title'>
        <span>
          <BrickIcon name={query?.name} />
          {query?.name}
        </span>
        <Button.Group size='tiny'>
          {deleteEl}
          <QueryCollapseButton collapsed={collapsed} onClick={setCollapsed} />
        </Button.Group>
      </div>
      {!collapsed && map(query.value, (node, index) =>
        <QueryBrick
          key={`${query.name}${index}`}
          query={node}
          canAddPath
          deleteEl={
            <QueryDeleteButton
              onClick={() => deleteNode(query, 'value', index)}
            />
          }
          removePathAfterThisNode={() => removePathQuery(getChildPath(index))}
          startRow={positions[index]}
          queryKeyPath={getChildPath(index)}
        />
      )}
      {!collapsed && (query.name !== 'optional' || query.value?.length === 0) &&
        <Button
          size='tiny'
          content='Add Node'
          icon='plus'
          disabled={isEqual(last(query.value), {name: null})}
          onClick={() => addNode(query, 'value', {name: null})}
        />
      }
    </div>
  );
});

export default QueryBrick;
