import {generatePropertyFromSchema} from 'apstra-ui-common';
import {cloneDeep, filter, find, first, flatMap, forEach,
  intersection, isEmpty, isMatch, keys, map,
  pullAllWith, some, sortBy, toPairs, transform, uniq, values} from 'lodash';
import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {useContext, useState} from 'react';
import {ModalProps} from 'semantic-ui-react';

import IBAContext, {IBAContextValue} from '../../IBAContext';
import {ProcessorContextValue, useScrollContext} from '../ProbeDetails/ScrollContext';
import {ResourceModalMode} from './apstra-ui-common-types';
import humanizeString from '../../../humanizeString';
import {getStageFormSchema} from '../../stageUtils';
import {Probe, ProbeProcessorType, ProbeStageType, ProcessorDefinition} from '../../types';
import {ErrorType, PropertyValue} from './types';

export type AddProcessorModalProps = {
  errors?: ErrorType[];
  mode?: ResourceModalMode;
  onClose?: ModalProps['onClose'];
  onSuccess?: ModalProps['onSuccess'];
  open?: boolean;
  probe: Probe;
  processor?: ProbeProcessorType;
  helpPageId?: string;
};

type StoreProps = AddProcessorModalProps & {
  errors: NonNullable<AddProcessorModalProps['errors']>,
  mode: NonNullable<AddProcessorModalProps['mode']>;
}

export const useStore = (props: StoreProps): Store => {
  const context = useContext(IBAContext);
  const {scrollToElement} = useScrollContext();
  const [store] = useState(() => new Store(context, props));
  store.scrollToElement = scrollToElement;
  store.update(context, props);
  return store;
};

export class Store {
  @observable public processorName = '';
  @observable public processorType: string | null = null;
  @observable public processorOutputRenameMap: Record<string, string> = {};
  @observable.shallow private readonly modalErrors: ErrorType[] = [];
  private props: StoreProps;
  private context: IBAContextValue;
  public scrollToElement?: ProcessorContextValue['scrollToElement'];

  public update = (context: IBAContextValue, props: StoreProps) => {
    this.context = context;
    this.props = props;
  };

  @action
  public resetState = () => {
    this.modalErrors.length = 0;
    const {mode, processor} = this.props;
    if (mode === 'create') {
      this.setProcessorType(first(this.sortedProcessorDefinitions)!.name);
    } else if (mode === 'update') {
      this.setProcessorType(processor!.type);
      this.setProcessorName(processor!.name);
      forEach(this.processorDefinition!.outputs, (_, outputName) => {
        this.setProcessorOutputName(outputName, processor!.outputs[outputName]);
      });
    } else if (mode === 'clone') {
      this.setProcessorType(processor!.type);
    }
  };

  public constructor(context: IBAContextValue, props: StoreProps) {
    this.context = context;
    this.props = props;
    makeObservable(this);
  }

  @action
  public setProcessorName(processorName: string) {
    this.processorName = processorName;
    pullAllWith(this.modalErrors, [{type: 'processor'}], isMatch);
  }

  @action
  public setProcessorType(processorType: string) {
    this.processorType = processorType;
    this.generateNames();
  }

  @action
  public setProcessorOutputName(outputNameFromDefinition: string, newOutputName) {
    pullAllWith(this.modalErrors, [{type: 'output', outputName: outputNameFromDefinition}], isMatch);
    this.processorOutputRenameMap[outputNameFromDefinition] = newOutputName;
  }

  @action
  public submit = () => {
    const {mode} = this.props;
    this.validate();
    if (!this.isValid) throw new TypeError();
    if (mode === 'create') {
      this.createProcessor();
    } else if (mode === 'update') {
      this.updateProcessor();
    } else if (mode === 'clone') {
      this.createProcessor(true);
    }
    return this.processorName;
  };

  @action
  private createProcessor = (isClone = false) => {
    const {probe} = this.props;
    const {processor, stages} = this.buildProcessor(this.processorName, this.processorType!, isClone);
    probe.processors.push(processor);
    probe.stages.push(...stages);
    this.scrollToElement?.('processor details', processor.name);
  };

  @action
  private updateProcessor = () => {
    const {probe, processor, errors} = this.props;
    pullAllWith(errors, [{processorName: processor!.name}], isMatch);
    processor!.name = this.processorName;
    forEach(this.processorDefinition!.outputs, (_, outputNameFromDefinition) => {
      const oldOutputName = processor!.outputs[outputNameFromDefinition];
      const newOutputName = this.processorOutputRenameMap[outputNameFromDefinition];
      const wasOutputRenamed = oldOutputName !== newOutputName;
      if (wasOutputRenamed) {
        const stageToRename = find(probe.stages, {name: oldOutputName});
        if (stageToRename) {
          stageToRename.name = newOutputName;
        }
        processor!.outputs[outputNameFromDefinition] = newOutputName;
        forEach(probe.processors, ({inputs}) => {
          for (const inputName of keys(inputs) as string[]) {
            const isLinkedToRenamedOutput = inputs[inputName].column === oldOutputName;
            if (isLinkedToRenamedOutput) {
              inputs[inputName].column = newOutputName;
            }
          }
        });
      }
    });
  };

  private buildProcessor(name: string, type: string, cloneStages = false):
    {processor: ProbeProcessorType, stages: ProbeStageType[]} {
    const {probe, processor} = this.props;
    const inputs = transform(
      this.processorDefinition!.inputs,
      (result, metadata, inputName) => {
        if (inputName !== '*') result[inputName] = {};
      },
      {} as ProbeProcessorType['inputs']
    );
    const {outputs, stages} = transform(
      this.processorDefinition!.outputs,
      ({outputs, stages}, metadata, outputName) => {
        const stageName = outputs[outputName] = this.processorOutputRenameMap[outputName];
        if (cloneStages) {
          const sourceStage = find(
            probe.stages,
            {name: processor!.outputs[outputName]}
          );
          if (sourceStage) {
            stages.push({
              ...cloneDeep(toJS(sourceStage)),
              name: stageName
            });
            return;
          }
        }
        stages.push({
          name: stageName,
          ...this.buildStageProperties()
        });
      },
      {
        outputs: {} as ProbeProcessorType['outputs'],
        stages: [] as ProbeStageType[]
      }
    );
    const properties = cloneStages ?
      cloneDeep(toJS(processor!.properties))
    :
      transform(
        this.processorDefinition!.schema.properties,
        (result, propertySchema, propertyName) => {
          const defaultPropertyValue = generatePropertyFromSchema(propertySchema) as PropertyValue;
          result[propertyName] = defaultPropertyValue;
        },
        {}
      );
    return {processor: {type, name, inputs, outputs, properties}, stages};
  }

  private buildStageProperties = (): Omit<ProbeStageType, 'name'> => transform(
    getStageFormSchema(this.processorDefinition),
    (result, {name, schema}) => {
      const defaultPropertyValue = generatePropertyFromSchema(schema) as PropertyValue;
      result[name] = defaultPropertyValue;
    },
    {} as Omit<ProbeStageType, 'name'>
  );

  private validateProcessorName = (name: string): string[] => {
    const errors: string[] = [];
    if (!name) errors.push('Empty name');
    if (some(this.props.probe.processors,
      (processor) =>
        !(this.props.mode === 'update' && processor === this.props.processor) && processor.name === name
    )) errors.push('Duplicate processor name');
    return errors;
  };

  private validateStageName = (name: string): string[] => {
    const errors: string[] = [];
    if (!name) errors.push('Empty name');
    if (some(this.props.probe.processors, (processor) =>
      !(this.props.mode === 'update' && processor === this.props.processor) &&
      values(processor.outputs).some((outputName) => outputName === name)
    )) errors.push('Duplicate stage name');
    return errors;
  };

  @action
  private validate = () => {
    this.modalErrors.length = 0;
    const processorErrors = this.validateProcessorName(this.processorName);
    if (some(processorErrors)) {
      this.modalErrors.push({type: 'processor', message: processorErrors});
    }
    if (this.processorDefinition) {
      forEach(this.processorDefinition.outputs, (_, outputNameFromDefinition) => {
        const outputErrors = this.validateStageName(this.processorOutputRenameMap[outputNameFromDefinition]);
        if (some(outputErrors)) {
          this.modalErrors.push({type: 'output', outputName: outputNameFromDefinition, message: outputErrors});
        }
      });
    }
  };

  private generateName(name: string, validate: (name: string) => string[] = () => []) {
    let nameSuffix = 0;
    let result: string;
    do {
      result = nameSuffix ? `${name} ${nameSuffix}` : name;
      nameSuffix++;
    } while (some(validate(result)));
    return result;
  }

  @action
  private generateNames = () => {
    if (this.processorType && this.processorDefinition) {
      this.setProcessorName(this.generateName(this.processorDefinition.label, this.validateProcessorName));
      this.processorOutputRenameMap = {};
      const outputNamesFromDefinition = keys(this.processorDefinition.outputs);
      const hasOnlyOneOutput = outputNamesFromDefinition.length === 1;
      forEach(outputNamesFromDefinition, (outputName) => {
        const namePrefix = hasOnlyOneOutput ? this.processorName : humanizeString(outputName);
        this.setProcessorOutputName(
          outputName,
          this.generateName(namePrefix, this.validateStageName)
        );
      });
    }
  };

  @computed public get availableProcessorTypes(): Record<string, boolean> {
    const {processorDefinitions} = this.context;
    const {probe} = this.props;
    function getPossibleProcessorDataTypes(processorDefinition: ProcessorDefinition) {
      return {
        inputTypes: uniq(flatMap(
          processorDefinition.inputs,
          (stageDefinition) => map(stageDefinition.types, 'type')
        )),
        outputTypes: uniq(flatMap(
          processorDefinition.outputs,
          (stageDefinition) => map(stageDefinition.types, 'type')
        ))
      };
    }
    return transform(
      processorDefinitions,
      (result, processorDefinition) => {
        const {inputTypes, outputTypes} = getPossibleProcessorDataTypes(processorDefinition);
        if (isEmpty(inputTypes)) {
          // processor has no inputs - always available for addition
          result[processorDefinition.name] = true;
        } else {
          // try to match with existing processors
          for (const processor of probe.processors) {
            const {inputTypes: possibleInputTypes, outputTypes: possibleOutputTypes} =
              getPossibleProcessorDataTypes(find(processorDefinitions, {name: processor.type})!);
            if (
              some(intersection(inputTypes, possibleOutputTypes)) ||
              some(intersection(possibleInputTypes, outputTypes))
            ) {
              result[processorDefinition.name] = true;
              return;
            }
          }
        }
      },
      {}
    );
  }

  @computed public get sortedProcessorDefinitions(): ProcessorDefinition[] {
    const {processorCategories, processorDefinitions} = this.context;
    const {categoryOrder, subcategoryOrder} = transform(toPairs(processorCategories),
      ({categoryOrder, subcategoryOrder}, [category, categoryData], index) => {
        categoryOrder[category] = index;
        subcategoryOrder[category] = transform(toPairs(categoryData.subcategories),
          (acc, [subcategory], index) => {
            acc[subcategory] = index;
          },
          {}
        );
      },
      {categoryOrder: {}, subcategoryOrder: {}}
    );

    const sortedProcessorDefinitions = sortBy(
      processorDefinitions,
      (p) => categoryOrder[p.category?.[0]],
      (p) => subcategoryOrder[p.category?.[0]][p.category?.[1]],
      (p) => this.availableProcessorTypes[p.name],
      'label',
    );
    return sortedProcessorDefinitions;
  }

  @computed public get processorDefinition(): ProcessorDefinition | null {
    const {processorDefinitions} = this.context;
    return this.processorType && find(processorDefinitions, {name: this.processorType}) || null;
  }

  @computed public get isValid() {
    return this.processorType !== null && isEmpty(this.modalErrors);
  }

  @computed public get modalErrorsForProcessorName(): string[] {
    return flatMap(filter(this.modalErrors, {type: 'processor'}), 'message');
  }

  public getModalErrorsForProcessorOutput = (outputName: string): string[] => {
    return flatMap(filter(this.modalErrors, {type: 'output', outputName}), 'message');
  };
}
