import {useCallback, useRef} from 'react';
import {map} from 'lodash';
import classes from 'classnames';
import {Icon, Label} from 'semantic-ui-react';

import {useTooltip} from '../../GraphTooltips';
import {aggregateLinkPropTypes} from './shared-prop-types';
import {LAG_MODE_LABELS} from './constants';

import './AggregateLink.less';

const AggregateLink = ({
  cx, cy, rx, ry, lagMode, portChannelId, interfaceName, ct, hovered, onHover, aggregateLink, onSelect,
  interfaceTags
}) => {
  const {sharedTooltip} = useTooltip();
  const classNames = classes('aggregate-link', {hovered: hovered});
  const viewRef = useRef();
  const showTooltip = useCallback((e) => {
    e.stopPropagation();
    sharedTooltip.show(
      <section className='field-data-container'>
        <div><b>{'LAG Mode'}</b><span>{LAG_MODE_LABELS[lagMode] || lagMode}</span></div>
        {interfaceName &&
          <div><b>{'Port Channel Name'}</b><span>{interfaceName}</span></div>
        }
        <div><b>{'Port Channel ID'}</b><span>{portChannelId}</span></div>
        {!!interfaceTags?.length && (
          <div>
            <b>{'Tags'}</b>
            <div>
              {map(interfaceTags, (tag) => <Label key={tag} size='mini'><Icon name='tag' />{tag}</Label>)}
            </div>
          </div>
        )}
        {!!ct?.length && <div><b>{'Applied CTs'}</b><span>{ct.join(', ')}</span></div>}
      </section>
    );
  }, [sharedTooltip, lagMode, portChannelId, interfaceName, ct, interfaceTags]);

  const hideTooltip = useCallback(() => {
    sharedTooltip.hide();
  }, [sharedTooltip]);

  return (
    <ellipse
      ref={viewRef}
      {...{cx, cy, rx, ry}}
      className={classNames}
      onMouseOver={function(e) {
        showTooltip(e);
        if (onHover) onHover(aggregateLink);
      }}
      onMouseOut={function(e) {
        hideTooltip(e);
        if (onHover) onHover(null);
      }}
      onClick={(e) => {
        e.preventDefault();
        onSelect?.(aggregateLink.id, viewRef);
      }}
    />
  );
};

AggregateLink.propTypes = aggregateLinkPropTypes;

export default AggregateLink;
