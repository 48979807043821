import {map} from 'lodash';

import {NODE, GRAPH_QUERY_TAG_MATCHERS} from '../pythonExpression/consts';

export const START_QUERY_MAP = {
  [NODE]: {name: NODE, type: ''},
  match: {name: 'match', value: []},
  optional: {name: 'optional', value: []},
};

export const COMPARISON_OPERATORS = new Set(['gt', 'ge', 'lt', 'le']);
export const EXCLUDE_MATCHERS = new Set(['_not']);
export const MULTIPLE_MATCHERS = new Set(['is_in', 'not_in', 'has_keys', ...map(GRAPH_QUERY_TAG_MATCHERS, 'name')]);

export const QUERY_PROPERTIES = new Set(['name', 'uniqNames', 'value', 'path', 'type', 'predicate', 'query', 'kwargs']);
