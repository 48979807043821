import {Component} from 'react';
import PropTypes from 'prop-types';
import {observable, action, computed, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {forEach, transform, get, keys, sortBy, isPlainObject, has, castArray, isString} from 'lodash';
import {FetchData, FetchDataError, Loader, ResourceModal,
  generatePropertyFromSchema, interpolateRoute, request, withRouter} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import PredefinedEntityForm from './PredefinedEntityForm';
import {addError, searchForGenericErrors} from '../../processErrors';

@withRouter
@observer
export default class PredefinedEntityModal extends Component {
  static propTypes = {
    entities: PropTypes.array.isRequired,
    baseRoute: PropTypes.string.isRequired,
    generateResourceHref: PropTypes.func.isRequired,
    entityName: PropTypes.string,
    entityId: PropTypes.string,
    helpPageId: PropTypes.string
  };

  static contextType = IBAContext;

  @observable parameters = {};
  @observable currentEntityName = null;

  @action
  resetState = () => {
    this.setEntityName(this.props.entityName ?? keys(this.entitiesByName)[0]);
  };

  @action
  setEntityName = (entityName) => {
    this.currentEntityName = entityName;
    this.parameters = {};
    const entity = entityName ? this.entitiesByName[entityName] : null;
    if (entity) {
      forEach(get(entity, ['schema', 'properties'], {}), (schema, name) => {
        this.parameters[name] = generatePropertyFromSchema(schema);
      });
    }
  };

  @action
  setParameter = (name, value) => {
    this.parameters[name] = value;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get entitiesByName() {
    const sortedEntitiesByName = transform(
      sortBy(
        this.props.entities,
        ({name, schema}) => get(schema, ['properties', 'label', 'default'], name)
      ),
      (result, entity) => {
        result[entity.name] = entity;
      },
      {}
    );
    return sortedEntitiesByName;
  }

  @computed get entityName() {
    const {currentEntityName, props: {entityName}} = this;
    return entityName ?? currentEntityName;
  }

  @computed get entity() {
    const {entityName, entitiesByName} = this;
    return entityName ? entitiesByName[entityName] : null;
  }

  fetchEntityParameters = async (fetchParams) => {
    const {fetchEntityParameters} = this.props;
    if (fetchEntityParameters) {
      const parameters = await fetchEntityParameters(fetchParams);
      runInAction(() => {
        forEach(parameters, (value, name) => this.setParameter(name, value));
      });
    } else {
      return Promise.resolve();
    }
  };

  processErrors = ({errors: errorsProps}) => {
    const result = [];
    const properties = get(this.entity, ['schema', 'properties']);
    const errors = isString(errorsProps) ? castArray(errorsProps) : errorsProps;
    forEach(errors, (propertyErrors, propertyName) => {
      if (!has(properties, propertyName)) {
        searchForGenericErrors(result, propertyErrors, propertyName);
      } else if (isPlainObject(propertyErrors)) {
        result.push({type: 'property', propertyName, propertyErrors});
      } else {
        addError(result, propertyErrors, (message) => ({
          type: 'property',
          propertyName,
          message
        }));
      }
    });
    return result;
  };

  submit = async () => {
    const {blueprintId} = this.context;
    const {mode, entityId, baseRoute, generateResourceHref} = this.props;
    const route = interpolateRoute(baseRoute, {blueprintId}) +
      '/' + this.entityName +
      (mode === 'update' ? '/' + entityId : '');
    const method = mode === 'update' ? 'PUT' : 'POST';
    const {id: newEntityId} = await request(route, {method, body: JSON.stringify(this.parameters)});
    return {
      resourceLabel: this.parameters.label,
      resourceHref: generateResourceHref({blueprintId, entityId: mode === 'update' ? entityId : newEntityId})
    };
  };

  onSuccess = ({result: {resourceHref}, createAnother}) => {
    if (!createAnother) {
      location.href = `/#${resourceHref}`;
    }
  };

  render() {
    const {routes, blueprintPermissions, blueprintId} = this.context;
    const {mode, entityId, trigger, open, onClose, resourceName, renderers, columnWidths, extraActions,
      helpPageId} = this.props;
    const {entityName} = this;
    return (
      <FetchData
        fetchData={this.fetchEntityParameters}
        fetchParams={{routes, blueprintPermissions, blueprintId, entityName, entityId}}
        pollingInterval={null}
        customLoader
      >
        {({loaderVisible, fetchDataError}) =>
          <ResourceModal
            helpPageId={helpPageId}
            mode={mode}
            resourceName={resourceName}
            titlesByMode={{
              create: 'Instantiate',
              update: 'Edit',
              clone: 'Clone',
            }}
            open={open}
            trigger={trigger}
            onClose={onClose}
            resetState={this.resetState}
            submit={this.submit}
            submitAvailable={!loaderVisible && !fetchDataError}
            processErrors={this.processErrors}
            onSuccess={this.onSuccess}
            extraActions={extraActions}
          >
            {loaderVisible ?
              <Loader />
            : fetchDataError ?
              <FetchDataError error={fetchDataError} />
            :
              <PredefinedEntityForm
                mode={mode}
                label={resourceName}
                parameters={this.parameters}
                entityName={this.entityName}
                entity={this.entity}
                entitiesByName={this.entitiesByName}
                setEntityName={this.setEntityName}
                setParameter={this.setParameter}
                renderers={renderers}
                columnWidths={columnWidths}
              />
            }
          </ResourceModal>
        }
      </FetchData>
    );
  }
}
