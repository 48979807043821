import cx from 'classnames';
import {concat, filter, flatMap, flatten, isFunction, last, map, mapValues, pickBy, reject, uniqBy,
  values} from 'lodash';
import {action, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import React, {useContext, useMemo} from 'react';

import Graph, {GraphStore} from './Graph';
import GraphExplorerContext from './GraphExplorerContext';
import GraphQueryTooltip from './GraphQueryTooltip';

import {transformNode, transformLink} from './GraphExplorer';

class GraphQueryResultStore {
  @observable useFullBlueprint = false;

  @action
  onUseFullBlueprintChange = () => {
    this.useFullBlueprint = !this.useFullBlueprint;
  };

  graphStore = new GraphStore();

  constructor() {
    makeObservable(this);
  }
}

const isLinkItem = ({source_id: source, target_id: target}) => !!(source && target);

const GraphQueryResult = observer(({
  fullBlueprint, traceResult, queryResult
}) => {
  const {graphQueryResultStore} = useContext(GraphExplorerContext);
  const {onUseFullBlueprintChange, useFullBlueprint, traceMode, onTraceModeChange, graphStore} = graphQueryResultStore;

  const queryGraph = useMemo(() => {
    const queryResultItems = filter(uniqBy(flatMap(queryResult?.items, values), 'id'));
    return {
      nodes: map(reject(queryResultItems, isLinkItem), transformNode),
      links: map(filter(queryResultItems, isLinkItem), transformLink),
    };
  }, [queryResult]);

  const traceGraph = useMemo(() => {
    const ids = new Set(map(flatten(last(traceResult?.steps)?.paths), 'id'));
    return {
      nodes: filter(fullBlueprint.nodes, ({id}) => ids.has(id)),
      links: filter(fullBlueprint.links, ({id}) => ids.has(id)),
    };
  }, [traceResult, fullBlueprint]);

  return (
    <MergeGraphs
      graphs={{
        'query-result': queryGraph,
        'full-blueprint': useFullBlueprint ? fullBlueprint : undefined,
        'trace-result': traceGraph,
      }}
    >
      <Graph
        showUseFullBlueprintCheckbox
        showTrace={!!traceResult}
        useFullBlueprint={useFullBlueprint}
        onUseFullBlueprintChange={onUseFullBlueprintChange}
        traceMode={traceMode}
        onTraceModeChange={onTraceModeChange}
        TooltipComponent={GraphQueryTooltip}
        traceResult={traceResult}
        store={graphStore}
      />
    </MergeGraphs>
  );
});

const MergeGraphs = observer(({
  graphs,
  children,
}) => {
  const mergedGraph = useMemo(() => {
    const filteredGraphs = pickBy(graphs);
    const graphIds = mapValues(filteredGraphs, ({nodes, links}) => new Set(concat(map(nodes, 'id'), map(links, 'id'))));
    const nodes = uniqBy(concat(flatten(map(filteredGraphs, 'nodes'))), 'id');
    const links = uniqBy(concat(flatten(map(filteredGraphs, 'links'))), 'id');
    const copyClassified = (entity) => ({
      ...entity,
      className: cx(mapValues(filteredGraphs, (graph, className) => graphIds[className].has(entity.id)))
    });
    return {
      nodes: map(nodes, copyClassified),
      links: map(links, copyClassified)
    };
  }, [graphs]);

  return isFunction(children) ? children(mergedGraph) : React.cloneElement(children, mergedGraph);
});

export {GraphQueryResult as default, GraphQueryResultStore};
