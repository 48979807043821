import {Fragment} from 'react';
import {has, isFinite} from 'lodash';
import {Value, formatNumber} from 'apstra-ui-common';

import RangeValue from '../../components/RangeValue';

export default function AnomalyValues({anomaly}) {
  if (!anomaly) {
    return 'No anomaly';
  }

  const {actual, anomalous} = anomaly;
  const isRange = has(anomalous, 'value_min') || has(anomalous, 'value_max');

  const renderNumericAnomalyValue = ({value}) => isFinite(value) ? formatNumber(value) : null;
  const renderValue = ({value}) => (
    <Value value={value} renderers={[renderNumericAnomalyValue]} />
  );
  const renderAnomalousValue = () => (
    isRange ?
      <RangeValue
        min={anomalous.value_min}
        max={anomalous.value_max}
      />
    :
      renderValue(anomalous)
  );

  return (
    <Fragment>
      <div>{'Anomalous value: '}<b>{renderAnomalousValue()}</b></div>
      <div>{'Actual value: '}<b>{renderValue(actual)}</b></div>
    </Fragment>
  );
}
