import {LayoutContext} from 'apstra-ui-common';
import {Component, createRef, forwardRef, useEffect, useContext, useCallback} from 'react';
import {Button, Table, Message, Popup} from 'semantic-ui-react';
import ResizeDetector, {useResizeDetector} from 'react-resize-detector';
import {computed, action, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {map, max, reduce, isNumber, noop} from 'lodash';
import cx from 'classnames';
import {diffLines} from 'diff';
import bounds from 'binary-search-bounds';

import './ConfigDiff.less';

@observer
export default class ConfigDiff extends Component {
  static contextType = LayoutContext;

  static propTypes = {
    expectedConfig: PropTypes.string,
    actualConfig: PropTypes.string,
    expectedConfigTitle: PropTypes.string,
    actualConfigTitle: PropTypes.string,
    showNavigationButton: PropTypes.bool,
    headerOffset: PropTypes.number,
    withGlobalMessage: PropTypes.bool,
    globalMessageHeight: PropTypes.number,
  };

  static defaultProps = {
    expectedConfigTitle: 'Intended running configuration',
    actualConfigTitle: 'Actual running configuration',
    showNavigationButton: false,
    headerOffset: 113,
    globalMessageHeight: 29,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get diff() {
    const {expectedConfig, actualConfig} = this.props;
    return diffLines(expectedConfig, actualConfig);
  }

  @computed get diffLinePositions() {
    let lineNumber = 0;
    return reduce(this.diff, (positions, {added: empty, removed, count}) => {
      if (removed || empty) {
        positions.push(lineNumber);
      }
      lineNumber += count;
      return positions;
    }, []);
  }

  @observable configDiffPaneWidth = null;
  @observable rowHeight = 0;

  @action
  updateConfigDiffPaneWidth = () => {
    const expectedConfigPane = this.expectedConfigPaneRef.current;
    const actualConfigPane = this.actualConfigPaneRef.current;
    if (
      expectedConfigPane.scrollWidth > expectedConfigPane.clientWidth ||
      actualConfigPane.scrollWidth > actualConfigPane.clientWidth
    ) {
      this.configDiffPaneWidth = max([expectedConfigPane.scrollWidth, actualConfigPane.scrollWidth]);
    } else {
      this.configDiffPaneWidth = null;
    }
  };

  @action
  updateRowHeight = (value) => {
    if (isNumber(value) && value !== this.rowHeight) this.rowHeight = value;
  };

  ignoreScroll = new WeakMap();

  syncScroll = (triggerRef, targetRef, e) => {
    const scrollLeft = e.target.scrollLeft;
    if (this.ignoreScroll.has(triggerRef)) {
      this.ignoreScroll.delete(triggerRef);
    } else {
      this.ignoreScroll.set(targetRef, true);
      targetRef.current.scrollLeft = max([0, scrollLeft]);
    }
  };

  animationFrame = null;
  scrollTop = 0;

  onScroll = (e) => {
    if (this.animationFrame) {
      // eslint-disable-next-line no-undef
      cancelAnimationFrame(this.animationFrame);
    }
    this.animationFrame = requestAnimationFrame(() => {
      this.scrollTop = e.target.scrollingElement?.scrollTop ?? 0;
    });
  };

  scrollToDiff = (next) => {
    const {headerOffset, withGlobalMessage, globalMessageHeight} = this.props;
    const offset = headerOffset + (withGlobalMessage ? globalMessageHeight : 0);
    const {top} = this.expectedConfigPaneRef.current.getBoundingClientRect();
    const startRowOffset = this.scrollTop + top - offset;
    const startNode = Math.floor((max([this.scrollTop, startRowOffset]) - startRowOffset) / this.rowHeight);
    let positionIndex = next ?
      bounds.gt(this.diffLinePositions, startNode) :
      bounds.lt(this.diffLinePositions, startNode);
    if (positionIndex < 0) positionIndex = 0;
    if (positionIndex > this.diffLinePositions.length - 1) positionIndex = this.diffLinePositions.length - 1;
    const scrollPosition = this.diffLinePositions[positionIndex] * this.rowHeight + startRowOffset;
    this.context.mainContentElement?.scrollTo({top: scrollPosition, behavior: 'smooth'});
  };

  scrollToTop = () => {
    this.context.mainContentElement?.scrollTo({top: 0, behavior: 'smooth'});
  };

  componentDidMount() {
    this.updateConfigDiffPaneWidth();
    if (this.props.showNavigationButton) {
      this.context.mainContentElement?.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.showNavigationButton) {
      this.context.mainContentElement?.removeEventListener('scroll', this.onScroll);
      if (this.animationFrame) {
        // eslint-disable-next-line no-undef
        cancelAnimationFrame(this.animationFrame);
      }
    }
  }

  expectedConfigPaneRef = createRef();
  actualConfigPaneRef = createRef();

  render() {
    const {expectedConfigTitle, actualConfigTitle, showNavigationButton} = this.props;
    return (
      <Table className='config-diff' fixed celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{expectedConfigTitle}</Table.HeaderCell>
            <Table.HeaderCell>{actualConfigTitle}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <ResizeDetector
                handleWidth
                targetRef={this.expectedConfigPaneRef}
                onResize={this.updateConfigDiffPaneWidth}
              >
                <ConfigDiffPane
                  ref={this.expectedConfigPaneRef}
                  mode='expected'
                  diff={this.diff}
                  width={this.configDiffPaneWidth}
                  onScroll={(e) => this.syncScroll(this.expectedConfigPaneRef, this.actualConfigPaneRef, e)}
                  updateRowHeight={this.updateRowHeight}
                />
              </ResizeDetector>
            </Table.Cell>
            <Table.Cell>
              <ResizeDetector
                handleWidth
                targetRef={this.actualConfigPaneRef}
                onResize={this.updateConfigDiffPaneWidth}
              >
                <ConfigDiffPane
                  ref={this.actualConfigPaneRef}
                  mode='actual'
                  diff={this.diff}
                  width={this.configDiffPaneWidth}
                  onScroll={(e) => this.syncScroll(this.actualConfigPaneRef, this.expectedConfigPaneRef, e)}
                  updateRowHeight={this.updateRowHeight}
                />
              </ResizeDetector>
              {showNavigationButton && (
                <div className='navigation'>
                  <Button icon='angle up' content='Previous diff' onClick={() => this.scrollToDiff(false)} />
                  <Button icon='angle down' content='Next diff' onClick={() => this.scrollToDiff(true)} />
                  <Popup
                    trigger={
                      <Button icon='angle double up' onClick={() => this.scrollToTop()} />
                    }
                    content='Scroll to top'
                  />
                </div>
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const ConfigDiffPane = forwardRef(({onScroll, ...props}, ref) => (
  <div
    ref={ref}
    className='config-view config-diff-pane'
    onScroll={onScroll}
  >
    <ConfigDiffPaneTable {...props} />
  </div>
));

export const ConfigDiffPaneTable = ({diff, mode, width, updateRowHeight = noop}) => {
  const {height, ref: lineRef} = useResizeDetector();
  useEffect(() => {
    updateRowHeight(height);
  }, [height, updateRowHeight]);

  let lineNumber = 0;

  return (
    <table style={{width: width ?? '100%'}}>
      <tbody>
        {map(diff, ({value, added, removed, count}, fragmentIndex) => {
          const empty = mode === 'actual' && removed || mode === 'expected' && added;
          const lines = value.split('\n', count);
          return map(lines, (line, lineIndex) => {
            if (!empty) lineNumber++;
            return (
              <tr
                key={`${fragmentIndex} ${lineIndex}`}
                ref={(fragmentIndex === 0 && lineIndex === 0) ? lineRef : undefined}
              >
                <td data-line-number={!empty ? lineNumber : null} />
                <td
                  className={cx({
                    'config-diff-added': mode === 'actual' && added,
                    'config-diff-removed': mode === 'expected' && removed,
                    'config-diff-empty': empty,
                  })}
                >
                  {!empty && (line || '\n')}
                </td>
              </tr>
            );
          });
        })}
      </tbody>
    </table>
  );
};

ConfigDiffPaneTable.propTypes = {
  diff: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(['expected', 'actual']).isRequired,
  width: PropTypes.number,
  updateRowHeight: PropTypes.func,
};

export const ConfigView = ({config, className, showLineNumbers = true, showNavigationButton = false}) => {
  const {mainContentElement = window} = useContext(LayoutContext);
  const scrollToTop = useCallback(() => {
    mainContentElement.scrollTo({top: 0, behavior: 'smooth'});
  }, [mainContentElement]);
  if (!config) {
    return (
      <Message info icon='info circle' content='Config is empty.' />
    );
  }
  if (!showLineNumbers) {
    return (
      <div className={cx('config-view', className)}>
        <pre>{config}</pre>
      </div>
    );
  }
  let lineNumber = 0;
  const lines = config.split('\n');
  return (
    <div className={cx('config-view', className)}>
      <table>
        <tbody>
          {map(lines, (line, lineIndex) => {
            lineNumber++;
            return (
              <tr key={lineIndex}>
                <td data-line-number={lineNumber} />
                <td>{line || '\n'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showNavigationButton && (
        <div className='navigation'>
          <Popup
            trigger={
              <Button icon='angle double up' onClick={() => scrollToTop()} />
            }
            content='Scroll to top'
          />
        </div>
      )}
    </div>
  );
};

ConfigView.propTypes = {
  config: PropTypes.string.isRequired,
  showLineNumbers: PropTypes.bool,
};
