import {useContext} from 'react';

import UserStoreContext from '../UserStoreContext';

export default function useUserStore(key) {
  const {userStore} = useContext(UserStoreContext);
  const value = userStore.getStoreValue([key]);
  const setter = userStore.setStoreValueFn(key);
  return [value, setter];
}
