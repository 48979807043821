import {observer} from 'mobx-react';
import cx from 'classnames';
import {Button, Popup} from 'semantic-ui-react';

import {speedFromString} from '../utils';

import './Port.less';

const Port = ({speedString, amount, onClick, selected, tooltip}) => {
  const speed = speedFromString(speedString);

  const interactiveProps = (onClick && speedString) ?
    {
      onClick: () => onClick(speed, speedString),
      'aria-label': `Select ${speedString}`
    } : {};

  const empty = !speedString;

  const classNames = cx(
    're-port',
    `port-${speedString || 'na'}`,
    {
      interactive: !!onClick,
      empty,
      selected
    }
  );

  const button = (
    <Button
      key={speed}
      className={classNames}
      {...interactiveProps}
    >
      {empty ? '\u00A0' : amount}
    </Button>
  );

  return tooltip ?
    <Popup className='re-ports-title' size='tiny' trigger={button}>{tooltip}</Popup> :
    button;
};

export default observer(Port);
