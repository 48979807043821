import {useState} from 'react';
import {uniq, transform, map, compact, keys} from 'lodash';
import {Field, StringListDropdownControl} from 'apstra-ui-common';

import {getStageByName} from '../stageUtils';

const GroupByInput = ({probe, processor, name, value, required, schema, disabled, errors, onChange, fieldProps}) => {
  const [knownItems, setKnownItems] = useState([]);
  const updateKnownItems = () => {
    const stages = compact(map(processor?.inputs, (input) => {
      if (input?.stage) return getStageByName({probe, stageName: input.stage});
    }));
    const knownItems = uniq(compact(transform(stages, (result, {keys: stageKeys}) => {
      result.push(...keys(stageKeys));
    }, [])));
    setKnownItems(knownItems);
  };

  return (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <StringListDropdownControl
        allowAdditions
        noResultsMessage='Start typing to add a new property'
        placeholder='No properties specified, one output group will be produced'
        value={value}
        onChange={onChange}
        knownItems={knownItems}
        onOpen={updateKnownItems}
      />
    </Field>
  );
};

export default GroupByInput;
