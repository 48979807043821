import {createModelSchema, primitive, list, object, identifier, alias, custom} from 'serializr';
import {observable, computed, makeObservable} from 'mobx';
import {find, some, uniq, map, transform, compact, isEmpty, identity, reduce} from 'lodash';

import {PORT_SPEED_UNIT_LABELS, PORT_SPEED_COLORS, PORT_SPEEDS} from '../portConsts';

const SUPPORTED_FEATURES = [
  'copp_strict',
  'breakout_capable',
  'as_seq_num_supported'
];

export function getStringHashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    /* eslint-disable no-bitwise */
    hash = ((hash << 5) - hash) + str.charCodeAt(i);
    hash &= hash; // convert to 32bit integer
    /* eslint-enable no-bitwise */
  }
  return hash;
}

export class DeviceProfile {
  id = undefined;
  @observable label = '';
  @observable slotCount = 0;
  @observable hardwareCapabilities = new HardwareCapabilities();
  @observable softwareCapabilities = new SoftwareCapabilities();
  @observable selector = new Selector();
  @observable ports = [];

  constructor() {
    makeObservable(this);
  }

  @computed get hasSlots() {
    return some(this.ports, 'slotId');
  }

  @computed get connectorTypeColors() {
    const connectorTypes = compact(uniq(map(this.ports, 'connectorType'))).sort();

    return transform(connectorTypes, (result, connectorType) => {
      /* eslint-disable no-bitwise */
      result[connectorType] = '#' + ('00000' + (
        getStringHashCode(connectorType.repeat(6)) & 0x00FFFFFF).toString(16).toUpperCase()
      ).substr(-6);
      /* eslint-enable no-bitwise */
      return result;
    }, {});
  }

  isAutoNegPort(portId) {
    return find(this.ports, {portId})
      .transformations
      .some(({id: transformationId, interfaces}) =>
        some(interfaces, ({id: ifcId}) =>
          this.isAutoNegInterface(portId, transformationId, ifcId)
        )
      );
  }

  isAutoNegInterface(portId, transformationId, ifcId) {
    if (this.selector.os !== 'EOS' && this.selector.os !== 'NXOS') return false;

    return (find(
      (find(
        (find(this.ports, {portId}) || {}).transformations,
        {id: transformationId}
      ) || {}).interfaces,
      {id: ifcId}
    ) || {}).isAutonegotiation;
  }

  getInterface([portId, transformationId, interfaceId]) {
    const port = find(this.ports, {portId});
    if (port) {
      const transformation = find(port.transformations, {id: transformationId});
      if (transformation) {
        return find(transformation.interfaces, {id: interfaceId});
      }
    }
    return null;
  }
}

class HardwareCapabilities {
  asic = '';
  cpu = '';
  ecmpLimit = 0;
  formFactor = '';
  ram = 0;
  userland = 0;
}

class SoftwareCapabilities {
  lxcSupport = false;
  onie = false;
  configApplySupport = 'incremental';
}

class Selector {
  manufacturer = '';
  model = '';
  os = '';
  osVersion = '';
}

export class Port {
  portId = null;
  displayId = undefined;
  panelId = null;
  rowId = null;
  columnId = null;
  connectorType = '';
  transformations = [];
  failureDomainId = 1;
  slotId = 0;

  constructor(data) {
    Object.assign(this, data);
  }
}

export class Transformation {
  id = null;
  isDefault = false;
  @observable interfaces = [];

  @computed get speed() {
    return this.interfaces[0] ? this.interfaces[0].speed : null;
  }

  constructor(data) {
    makeObservable(this);
    Object.assign(this, data);
  }
}

export class Interface {
  id = null;
  name = null;
  speed = null;
  @observable state = 'active';
  @observable setting = '';

  @computed get isActive() {
    return this.state === 'active';
  }

  @computed get isAutonegotiation() {
    try {
      return this.isActive && !JSON.parse(this.setting).interface.speed;
    } catch (e) {
      return false;
    }
  }

  constructor(data) {
    makeObservable(this);
    Object.assign(this, data);
  }
}

export class InterfaceSpeed {
  value = null;
  unit = null;

  get label() {
    return this.value ? `${this.value} ${PORT_SPEED_UNIT_LABELS[this.unit]}` : '';
  }

  get color() {
    return PORT_SPEED_COLORS[
      PORT_SPEEDS.findIndex((speed) => speed.value === this.value && speed.unit === this.unit)
    ];
  }

  constructor(data) {
    Object.assign(this, data);
  }
}

createModelSchema(DeviceProfile, {
  id: identifier(),
  label: primitive(),
  slotCount: alias('slot_count', primitive()),
  hardwareCapabilities: alias('hardware_capabilities', object(HardwareCapabilities)),
  softwareCapabilities: alias('software_capabilities', object(SoftwareCapabilities)),
  selector: object(Selector),
  ports: list(object(Port))
});

createModelSchema(HardwareCapabilities, {
  asic: primitive(),
  cpu: primitive(),
  ecmpLimit: alias('ecmp_limit', primitive()),
  formFactor: alias('form_factor', primitive()),
  ram: primitive(),
  userland: primitive(),
  ...reduce(SUPPORTED_FEATURES, (result, name) => {
    result[name] = custom(
      (value) => isEmpty(value) ? null : value,
      identity
    );
    return result;
  }, {})
});

createModelSchema(SoftwareCapabilities, {
  lxcSupport: alias('lxc_support', primitive()),
  onie: primitive(),
  configApplySupport: alias('config_apply_support', primitive()),
});

createModelSchema(Selector, {
  manufacturer: primitive(),
  model: primitive(),
  os: primitive(),
  osVersion: alias('os_version', primitive())
});

createModelSchema(Port, {
  portId: alias('port_id', identifier()),
  displayId: alias('display_id', primitive()),
  panelId: alias('panel_id', primitive()),
  rowId: alias('row_id', primitive()),
  columnId: alias('column_id', primitive()),
  connectorType: alias('connector_type', primitive()),
  transformations: list(object(Transformation)),
  failureDomainId: alias('failure_domain_id', primitive()),
  slotId: alias('slot_id', primitive())
});

createModelSchema(Transformation, {
  id: alias('transformation_id', identifier()),
  isDefault: alias('is_default', primitive()),
  interfaces: list(object(Interface))
});

createModelSchema(Interface, {
  id: alias('interface_id', identifier()),
  name: primitive(),
  state: primitive(),
  speed: object(InterfaceSpeed),
  setting: primitive()
});

createModelSchema(InterfaceSpeed, {
  value: primitive(),
  unit: primitive()
});
