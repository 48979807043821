import {observer} from 'mobx-react';
import {Component} from 'react';
import {action, computed, makeObservable} from 'mobx';
import {castArray, filter, isMatch, pullAllWith, transform} from 'lodash';
import {Form, Table} from 'semantic-ui-react';
import {FormFragment, durationRenderer} from 'apstra-ui-common';

import {descriptionRenderer} from '../descriptionRenderer';
import {unitsRenderer} from './UnitsInput';
import {graphAnnotationPropertiesRenderer} from './GraphAnnotationPropertiesInput';
import {getOutputNameByStageName, getStageFormSchema} from '../stageUtils';
import {MarkdownDescription} from './MarkdownDescription';

@observer
export class StageEditor extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get schema() {
    const {stage, processor, processorDefinition} = this.props;
    const outputName = getOutputNameByStageName({processor, stageName: stage.name});
    const stageDefinition = processorDefinition.outputs[outputName];
    return getStageFormSchema(processorDefinition, stage, stageDefinition);
  }

  @computed get errorMessagesByProperty() {
    const {stage, errors} = this.props;
    const propertyErrors = filter(errors, {type: 'stageProperty', stageName: stage.name});
    return transform(propertyErrors, (result, {propertyName, message}) => {
      if (!result[propertyName]) result[propertyName] = [];
      result[propertyName].push(...castArray(message));
    }, {});
  }

  @action
  onPropertyChange = (propertyName, value) => {
    const {stage, errors} = this.props;
    pullAllWith(errors, [{type: 'stageProperty', stageName: stage.name, propertyName}], isMatch);
    stage[propertyName] = value;
  };

  render() {
    const {stage, actionInProgress} = this.props;
    return (
      <Table size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Properties'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Form>
                <FormFragment
                  schema={this.schema}
                  values={stage}
                  renderers={[
                    descriptionRenderer.renderValueInput,
                    durationRenderer.renderValueInput,
                    unitsRenderer.renderValueInput,
                    graphAnnotationPropertiesRenderer.renderValueInput,
                  ]}
                  errors={this.errorMessagesByProperty}
                  disabled={actionInProgress}
                  onChange={this.onPropertyChange}
                  fieldProps={{
                    DescriptionComponent: MarkdownDescription
                  }}
                />
              </Form>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
