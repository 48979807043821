import {observer} from 'mobx-react';
import {isArray, isString, get, compact} from 'lodash';
import {Button, Icon} from 'semantic-ui-react';
import {Field, ValueInput} from 'apstra-ui-common';

import DictInput from './DictInput';
import './ListInput.less';

const ListInput = (props) => {
  const {name, value, required, disabled, schema, errors, onChange, fieldProps} = props;

  const values = isArray(value) ? [...value] : [];
  const isDict = schema.subtype === 'dict';

  const insertEmpty = () => onChange([...values, isDict ? {} : '']);

  const removeItem = (index) => {
    values.splice(index, 1);
    onChange(values);
  };

  const onChangeOne = (index, value) => {
    values[index] = value;
    onChange(values);
  };

  const inputProps = {
    schema: {
      ...schema,
      title: null,
      description: null,
      type: schema.subtype
    },
    placeholder: schema.placeholder
  };

  const iconBaseProps = {
    name: 'delete',
    link: true
  };

  const label = [
    !disabled &&
      <Button
        key='add'
        primary
        size='mini'
        onClick={insertEmpty}
      >
        <Icon name='add circle' />
        {'Add'}
      </Button>,
    schema?.title ?? name
  ];

  const topLevelErrors = isString(errors);
  const globalErrors = topLevelErrors ? [errors] : [];
  const itemsErrors = topLevelErrors ? [] : errors;

  return (
    <Field
      className='ct-list-input'
      label={label}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={globalErrors}
      {...fieldProps}
    >
      {values.map((value, index) =>
        isDict ?
          <DictInput
            key={index}
            value={value}
            onChange={(value) => onChangeOne(index, value)}
            schema={schema}
            disabled={disabled}
            errors={get(itemsErrors, index, {})}
            onDelete={() => removeItem(index)}
          /> :
          <ValueInput
            key={index}
            value={value}
            onChange={(value) => onChangeOne(index, value)}
            disabled={disabled}
            errors={compact([get(itemsErrors, index)])}
            icon={!disabled && {
              ...iconBaseProps,
              onClick: () => removeItem(index)
            }}
            {...inputProps}
          />
      )}
    </Field>
  );
};

export default observer(ListInput);
