import {forEach, size, sortBy, some, isEmpty, isArray} from 'lodash';

/**
 * Finds an intersection of two intervals with start/end dates
 * @example: getIntervalsIntersect({start: date1, end: date2}, {start: date2, end: date3})
 */
export function getIntervalsIntersect(firstInterval, secondInterval, options = {startKey: 'start', endKey: 'end'}) {
  const asDate = (date) => date instanceof Date ? date : new Date(date);
  const firstIntervalStart = +asDate(firstInterval[options.startKey]);
  const firstIntervalEnd = +asDate(firstInterval[options.endKey]);
  const secondIntervalStart = +asDate(secondInterval[options.startKey]);
  const secondIntervalEnd = +asDate(secondInterval[options.endKey]);

  const formatResult = (start, end) => ({[options.startKey]: new Date(start), [options.endKey]: new Date(end)});

  if (firstIntervalEnd >= secondIntervalStart && secondIntervalEnd >= firstIntervalStart) {
    return formatResult(
      Math.max(firstIntervalStart, secondIntervalStart),
      Math.min(firstIntervalEnd, secondIntervalEnd)
    );
  }
  return null;
}

/**
 * Finds an intersection of intervals arrays with non-overlapping intervals in each array
 * @example: getIntervalsArraysIntersect([{start: date1, end: date2}], [{start: date2, end: date3}])
 */
export function getIntervalsArraysIntersect(intervalsArray, options = {startKey: 'start', endKey: 'end'}) {
  if (!isArray(intervalsArray) || isEmpty(intervalsArray) || some(intervalsArray, (intervals) => isEmpty(intervals))) {
    return [];
  }
  if (size(intervalsArray) === 1) {
    return intervalsArray[0];
  }

  const sortIntervals = (intervals) => sortBy(intervals, options.startKey);
  const length = intervalsArray.length;
  let intersects = [...sortIntervals(intervalsArray[0])];

  for (let i = 1; i < length; i++) {
    const intervals = sortIntervals(intervalsArray[i]);
    const newIntersects = [];
    forEach(intersects, (intersect) => {
      forEach(intervals, (interval) => {
        const newIntersect = getIntervalsIntersect(intersect, interval, options);
        if (newIntersect) {
          newIntersects.push(newIntersect);
        }
      });
    });
    intersects = newIntersects;
  }
  return intersects;
}
