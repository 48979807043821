import {Field} from 'apstra-ui-common';
import {map, some} from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {Input} from 'semantic-ui-react';

import {ReactComponent as ProcessorModalArrow} from '../../../../styles/icons/iba/processor-modal-arrow.svg';

import {Card, CardItem} from './Card';
import {Store} from './Store';

import ProcessorIcon from '../ProcessorIcon';

import './ProcessorEditor.less';

type ProcessorEditorProps = {
  store: Store;
}

export const ProcessorEditor: React.FC<ProcessorEditorProps> = observer(({store}) => {
  const shouldRenderInputs = some(store.processorDefinition?.inputs);

  return (
    <div className='processor-editor'>
      <div className='scrollable-content'>
        {shouldRenderInputs && (
          <>
            <Card
              className='processor-editor-input-card'
              title='Input'
            >
              {map(store.processorDefinition?.inputs, (_, input) =>
                <CardItem key={input} className='input-card-item'>
                  <div className='input-name'>
                    {input === '*' ? '[multiple inputs]' : input}
                  </div>
                </CardItem>
              )}
            </Card>
            <ProcessorModalArrow className='input-arrow' />
          </>
        )}
        <Card
          className='processor-editor-processor-card'
          title={store.processorDefinition?.label}
        >
          <Field errors={store.modalErrorsForProcessorName}>
            <CardItem withInput>
              <ProcessorIcon processorType={store.processorDefinition?.name} />
              <Input
                transparent
                className='processor-name'
                onChange={(e) => store.setProcessorName(e.target.value)}
                value={store.processorName}
                aria-label={store.processorDefinition?.label}
              />
            </CardItem>
          </Field>
        </Card>
        <ProcessorModalArrow className='output-arrow' />
        <Card
          className='processor-editor-output-card'
          title='Output'
        >
          {map(store.processorDefinition?.outputs, (_, output) =>
            <div key={output}>
              <div className='description'>
                {output}
              </div>
              <Field
                errors={store.getModalErrorsForProcessorOutput(output)}
              >
                <CardItem withInput>
                  <Input
                    transparent
                    className='output-name'
                    onChange={(e) => store.setProcessorOutputName(output, e.target.value)}
                    value={store.processorOutputRenameMap[output] ?? ''}
                    aria-label={output}
                  />
                </CardItem>
              </Field>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
});
