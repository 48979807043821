import {observer} from 'mobx-react';
import {filter, groupBy, map, size, transform} from 'lodash';

import {useRackEditorStore} from '../hooks/useRackEditorStore';
import {DUAL_ATTACHMENT_TYPE, FIRST_SWITCH_PEER, LAG_MODES, NODE_ROLES, PEER_LINKS_TYPES,
  SECOND_SWITCH_PEER, SINGLE_ATTACHMENT_TYPE} from '../const';

import './Description.less';

const Description = () => {
  const {leafs, accessSwitches, generics} = useDescription();
  return (
    <div className='rt-description'>
      <Section title='Leafs'>{leafs}</Section>
      <Section title='Access Switches'>{accessSwitches}</Section>
      <Section title='Generics'>{generics}</Section>
    </div>
  );
};

export default observer(Description);

const useDescription = () => {
  const {rackStore} = useRackEditorStore();
  const {nodes} = rackStore;

  const leafs = map(
    filter(nodes, {role: NODE_ROLES.LEAF}),
    (node) => describeLeaf(node, rackStore)
  );

  const accessSwitches = map(
    filter(nodes, {role: NODE_ROLES.ACCESS_SWITCH}),
    (node) => describeAccessSwitch(node, rackStore)
  );

  const generics = map(
    filter(nodes, {role: NODE_ROLES.GENERIC}),
    (node) => describeGeneric(node, rackStore)
  );

  return {leafs, accessSwitches, generics};
};

const Section = ({title, children}) => {
  return (
    <details key={title}>
      <summary>{title}</summary>
      {children}
    </details>
  );
};

const Property = ({name, value}) => {
  return (
    <dl key={name}>
      <dt>{name}</dt>
      <dd>{value}</dd>
    </dl>
  );
};

const BaseNodeDescription = ({node}) => {
  const {tags = [], redundancyProtocol, logicalDevice} = node;

  return (
    <>
      <Property name='Tags' value={tags.join(', ') || 'N/A'} />
      <Property name='Redundancy' value={redundancyProtocol || 'None'} />
      <Property name='Logical Device' value={logicalDevice?.display_name ?? 'N/A'} />
    </>
  );
};

const getPeerLinks = (linksGroupsByName, name) => {
  return transform(
    groupBy(
      filter(
        linksGroupsByName[name],
        {isPeer: true}
      ),
      'peeringType'
    ),
    (acc, links, peeringType) => {
      const count = size(links);
      acc[peeringType] = count ? `${count}×${links[0].speedString}` : 'N/A';
    },
    {}
  );
};

const getLinks = (fromName, {nodesByName, linksGroupsByName}, isFromAccessSwitch) => {
  const links = filter(
    linksGroupsByName[fromName],
    {fromName, isPeer: false}
  );

  const isFromPair = size(nodesByName[fromName]) > 1;

  return (
    <Section title='Links'>
      {
        map(
          links,
          ({toName, label, count, speedString, tags = [], isDualAttached, isAttachedToFirst,
            lagMode, hasAggregation}) => {
            const targetNodes = nodesByName[toName];
            const isConnectedToPair = size(targetNodes) > 1;

            const lagModeForced = hasAggregation ?
              lagMode :
              (isFromAccessSwitch ? LAG_MODES.LACP_ACTIVE : 'None');

            return (
              <Section key={label} title={`${count}×${speedString} → ${targetNodes[0]._label}`}>
                <Property name='Label' value={label} />
                {hasAggregation &&
                  <Property name='LAG Mode' value={lagModeForced} />
                }
                <Property
                  name='Attachement Type'
                  value={(isDualAttached ? DUAL_ATTACHMENT_TYPE : SINGLE_ATTACHMENT_TYPE).replace('Attached', '')}
                />
                {!isDualAttached && isConnectedToPair && !isFromPair &&
                  <Property
                    name='Switch Peer'
                    value={isAttachedToFirst ? FIRST_SWITCH_PEER : SECOND_SWITCH_PEER}
                  />
                }
                <Property name='Tags' value={tags.join(', ') || 'N/A'} />
              </Section>
            );
          })
      }
    </Section>
  );
};

const describeLeaf = (node, {linksGroupsByName, isL3Clos}) => {
  const {_label, name, isPaired, isFirstInPair, isMlagLeafPair, mlagVlanId, spineLinksCount, spineLinksSpeed} = node;

  if (isPaired && !isFirstInPair) return null;

  const peerLinks = getPeerLinks(linksGroupsByName, name);
  const l2Peers = peerLinks?.[PEER_LINKS_TYPES.L2_PEER];
  const l3Peers = peerLinks?.[PEER_LINKS_TYPES.L3_PEER];

  return (
    <Section key={_label} title={_label}>
      <BaseNodeDescription node={node} />
      {isMlagLeafPair &&
        <Property name='MLAG Vlan ID' value={+mlagVlanId} />
      }
      {isL3Clos &&
        <Property
          name='Links Per Spine'
          value={spineLinksCount ? `${spineLinksCount}×${spineLinksSpeed}` : 'No'}
        />
      }
      {l3Peers &&
        <Property name='L3 Peer Links' value={l3Peers} />
      }
      {l2Peers &&
        <Property name='L2 Peer Links' value={l2Peers} />
      }
    </Section>
  );
};

const describeAccessSwitch = (node, rackStore) => {
  const {_label, name, isPaired, isFirstInPair, portChannelId} = node;
  const {linksGroupsByName} = rackStore;

  if (isPaired && !isFirstInPair) return null;

  const peerLinks = getPeerLinks(linksGroupsByName, name);
  const l3Peers = peerLinks?.[PEER_LINKS_TYPES.L3_PEER];

  return (
    <Section key={_label} title={_label}>
      <BaseNodeDescription node={node} />
      {l3Peers &&
        <>
          <Property name='L3 Peer Links' value={l3Peers} />
          <Property name='Port Channel ID' value={portChannelId} />
          {getLinks(name, rackStore, true)}
        </>
      }
    </Section>
  );
};

const describeGeneric = (node, rackStore) => {
  const {_label, name, portChannelId} = node;

  return (
    <Section key={_label} title={_label}>
      <BaseNodeDescription node={node} />
      <Property name='Port Channel ID' value={portChannelId} />
      {getLinks(name, rackStore)}
    </Section>
  );
};
