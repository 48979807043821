import {forEach, map, some, sum, sortBy, uniqBy} from 'lodash';
import {makeObservable, observable, action} from 'mobx';

import {ctrlNodeWidth, ctrlNodeHeight, ctrlGridStep, ctrlRowSize} from './const';

class Positioner {
  @observable minX = 0;
  @observable minY = 0;
  @observable maxX = ctrlNodeWidth;
  @observable maxY = ctrlNodeWidth;

  #areaOf(nodes, fromZero = false) {
    let minX = fromZero ? 0 : 1000;
    let minY = fromZero ? 0 : 1000;
    let maxX = -1000;
    let maxY = -1000;

    forEach(nodes, ({position: {x, y}}) => {
      if (x < minX) minX = x;
      if (y < minY) minY = y;
      if (x > maxX) maxX = x;
      if (y > maxY) maxY = y;
    });

    return {
      minX,
      maxX: maxX + ctrlNodeWidth,
      minY: minY,
      maxY: maxY + ctrlNodeHeight
    };
  }

  @action
  update(nodes) {
    Object.assign(this, this.#areaOf(nodes, true));
  }

  constructor(nodes) {
    makeObservable(this);
    this.update(nodes);
  }

  belowOffset(nodes) {
    const minY = nodes ? this.#areaOf(nodes).minY : this.minY;
    return this.maxY - minY + ctrlGridStep;
  }

  placeNewAmong(nodes) {
    let [x, y] = [this.minX + 2, this.maxY - ctrlNodeHeight + 2];

    while (this.overlaps(x, y, nodes)) {
      x += ctrlNodeWidth + ctrlGridStep;
      if (x > 800) {
        x = this.minX;
        y += ctrlNodeHeight + ctrlGridStep;
      }
    }
    return {x, y};
  }

  overlaps(x, y, nodes) {
    const [start, end] = [
      {x, y},
      {x: x + ctrlNodeWidth, y: y + ctrlNodeHeight}
    ];
    return some(nodes, (node) => node.fallsWithin(start, end));
  }

  positionNodesGroup(nodesGroup, yOffset, nodeRelations) {
    let y = 0;

    const placingOrders = [0, -1, 1];

    const placedGroupNodes = [];
    const groupIds = map(nodesGroup, 'id');

    forEach(nodesGroup, (node) => {
      const relations = sortBy(nodeRelations[node.id], [({_linksCount}) => (-_linksCount), 'label']);

      // If there are related nodes already positioned
      // try to place the node under any of them
      if (relations.length) {
        if (some(relations, (relation) => {
          if (!relation.isPositioned) return false;

          const {position: {x: rx, y: ry}} = relation;
          const position = {
            y: groupIds.includes(relation.id) ? (ry + ctrlNodeHeight + ctrlGridStep) : yOffset
          };
          if (some(placingOrders, (xOffset) => {
            position.x = rx + xOffset * (ctrlNodeWidth + ctrlGridStep);
            if (!this.overlaps(position.x, position.y, placedGroupNodes)) {
              node.moveTo(position);
              placedGroupNodes.push(node);
              if (position.y > y) y = position.y;
              return true;
            }
          })) return true;
        })) return;
      }

      // Otherwise pick any position available for the group
      this.positionRandomly(node, placedGroupNodes, yOffset);
      placedGroupNodes.push(node);
      if (node.position.y > y) y = node.position.y;
    });
    return y + 4 * ctrlGridStep + ctrlNodeHeight;
  }

  // Pick the first non-overlapping position
  positionRandomly(node, nodes, yOffset = 0) {
    for (let yy = 0; yy < 100; yy++) {
      for (let xx = 0; xx < ctrlRowSize; xx++) {
        const position = {
          x: (ctrlNodeWidth + ctrlGridStep) * ((xx + 1) >> 1) * (xx % 2 ? -1 : 1), // eslint-disable-line no-bitwise
          y: yOffset + yy * (ctrlNodeHeight + ctrlGridStep)
        };
        if (!this.overlaps(position.x, position.y, nodes)) {
          node.moveTo(position);
          return node;
        }
      }
    }
    return node;
  }

  arrangeNodes(nodes, links) {
    forEach(nodes, (node) => node.resetPosition());

    const nodeRelations = {};
    const tagGroups = {};
    const tagGroupsLinksCount = [];

    forEach(links, (link) => {
      const [node1, node2] = [nodes[link.endpoint1.nodeId], nodes[link.endpoint2.nodeId]];
      if (node1 && node2) {
        node1._linksCount++;
        node2._linksCount++;
        (nodeRelations[node1.id] ??= []).push(node2);
        (nodeRelations[node2.id] ??= []).push(node1);
      }
    });

    // Remove duplicate relations in case of multiple links and LAGs
    forEach(nodeRelations, (relations, id) => {
      nodeRelations[id] = uniqBy(nodeRelations[id], 'id');
    });

    forEach(nodes, (node) => {
      if (node?.tags?.length) {
        forEach(node.tags, (tag) => (tagGroups[tag] ??= []).push(node));
      } else {
        (tagGroups[''] ??= []).push(node);
      }
    });

    forEach(tagGroups, (tagNodes, tag) => {
      tagGroups[tag] = sortBy(tagNodes, [({_linksCount}) => (-_linksCount)]);
      tagGroupsLinksCount.push({tag, count: sum(map(tagNodes, '_linksCount'))});
    });

    let y = 0;
    forEach(
      sortBy(tagGroupsLinksCount, (item) => (item.tag ? -item.count : 1)),
      ({tag}) => {
        const tagNodes = sortBy(tagGroups[tag], ({_linksCount}) => (-_linksCount));
        y = this.positionNodesGroup(tagNodes, y, nodeRelations);
      }
    );
  }
}

export default Positioner;
