import {isEmpty, isNil, compact, transform, isUndefined, get} from 'lodash';

const typeCleaner = /[^\w]/g;
const valueRequiredError = 'Value is required';

class ParametersValidator {
  validate(parameters) {
    return transform(
      parameters,
      (errors, parameter) => this.validateParameter(parameter, errors),
      {}
    );
  }

  validateParameter(parameter, errors) {
    const type = parameter?.schema?.type ?? 'undefined';
    const validator = this[`_${type.replace(typeCleaner, '')}`];
    if (validator) {
      validator.call(this, parameter, errors);
    } else {
      errors[parameter.name] = `Unable to validate parameter type "${type}"`;
    }
    return errors;
  }

  _notEmpty({name, value, required}, errors) {
    if (required && (value === '' || isNil(value))) {
      errors[name] = valueRequiredError;
    }
  }

  _boolean() {
    // Boolean is always valid
  }

  _string(parameter, errors) {
    // String must not be empty if required
    this._notEmpty(parameter, errors);
  }

  _integer({name, value, required, schema = {}}, errors) {
    if (value === '' || isNil(value)) {
      if (required) errors[name] = valueRequiredError;
    } else {
      const {minimum, maximum} = schema;
      const inRange =
        ((!isUndefined(minimum) && value < minimum) && `greater than or equal to ${minimum}`) ||
        ((!isUndefined(maximum) && value > maximum) && `less than or equal to ${maximum}`);

      if (inRange) errors[name] = `Value must be ${inRange}`;
    }
  }

  _node(parameter, errors) {
    // Node must be selected if required
    this._notEmpty(parameter, errors);
  }

  _array({name, value, required}, errors) {
    // Multiple selection enum must have at least one option selected if required
    if (required && isEmpty(compact(value))) {
      errors[name] = valueRequiredError;
    }
  }

  _enumradiobutton(parameter, errors) {
    // One of the options must be selected if required
    this._notEmpty(parameter, errors);
  }

  _dict({value, name, schema = {}}, errors) {
    const {dictSchema = []} = schema;
    const dictErrors = {};

    dictSchema.forEach((parameter) => {
      this.validateParameter({
        ...parameter,
        value: get(value, parameter.name),
        schema: {
          type: parameter.type
        }
      }, dictErrors);
    });
    if (!isEmpty(dictErrors)) {
      errors[name] = dictErrors;
    }
  }

  _list({name, value, required, schema = {}}, errors) {
    const {subtype, dictSchema = []} = schema;
    if (isEmpty(value)) {
      if (required) {
        // Value is required but none added
        errors[name] = valueRequiredError;
      }
    } else {
      const itemsErrors = new Array(value.length);
      value.forEach((itemValue, index) =>
        this.validateParameter(
          {
            value: itemValue,
            name: index,
            required,
            schema: {
              type: subtype,
              dictSchema
            }
          },
          itemsErrors
        )
      );

      if (!isEmpty(compact(itemsErrors))) {
        errors[name] = itemsErrors;
      }
    }
  }
}

export default ParametersValidator;
