import {Popup} from 'semantic-ui-react';
import {isArray, isFunction, isPlainObject, map} from 'lodash';
import {cloneElement, isValidElement} from 'react';

const ChartPopup = ({popupDescription}) => popupDescription && (
  <Popup
    size='tiny'
    position='top center'
    context={popupDescription.node}
    open
  >
    <Popup.Header>{popupDescription.header}</Popup.Header>
    {popupDescription.content &&
      <Popup.Content>
        {isValidElement(popupDescription.content) ?
          cloneElement(popupDescription.content) :
          isFunction(popupDescription.content) ?
            popupDescription.content() :
            isArray(popupDescription.content) || isPlainObject(popupDescription.content) ?
              map(popupDescription.content, (value, key) => (
                <div key={key}>
                  <strong>{key}{': '}</strong>
                  {`${value}`}
                </div>
              )) :
              popupDescription.content
        }
      </Popup.Content>
    }
  </Popup>
);

export default ChartPopup;
