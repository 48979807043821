import {useCallback, useState, useEffect} from 'react';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {intersection, isEmpty} from 'lodash';

import {useStore} from '../store/useStore';
import {connectionPointRadius, proximity} from '../settings';
import Draggable from './Draggable';
import Link from './Link';
import './ConnectionPoint.less';

function collectConnections(payload, isInput, links, epts) {
    // Collecting all connected EPTs in order to determine
    // * if further connections are possible (isMultiple === false)
    // * what are the accepted types if initial type is 'any'
  const connectionsTypes = [];
  const foundConnections = Object.values(links).reduce((result, {from, to}) => {
    if (!isInput && from === payload) {
      result.push(to);
      if (to && epts[to] && epts[to].inputTypes) {
        connectionsTypes.push(toJS(epts[to].inputTypes));
      }
    } else if (isInput && to === payload) {
      result.push(from);
      if (from && epts[from] && epts[from].outputTypes) {
        connectionsTypes.push(toJS(epts[from].outputTypes));
      }
    }
    return result;
  }, []);
  return [foundConnections, connectionsTypes];
}

const ConnectionPoint = ({position, isInput, types = null, isMultiple = false,
  payload = undefined, isAnyAccepted = false, disabled, activeEpt}) => {
  const store = useStore();
  const {connection = {}} = store || {};
  const {epts, links} = activeEpt;

  const [isDragging, setDragging] = useState(false);
  const [myPosition, setMyPosition] = useState({x: position.x, y: position.y});

  const [myConnections, connectionsTypes] = collectConnections(payload, isInput, links, epts);
  const hasConnections = myConnections.length > 0;

  let isPotentialMatch = false;
  if (connection.isSearched && // Connection candidate is being searched
        isInput !== connection.isInput && // only connect different types (in-out, out-in)
        payload !== connection.payload && // prevent connection to itself
        (isMultiple || !hasConnections) && // not connected or supports multiple connections
        !myConnections.includes(connection.payload) // no such connections exist already
  ) {
    const typesMatch =
            (isInput && !types && !hasConnections) ||
            (!isInput && !connection.types && !connection.hasConnections) ||
            (!types && !connection.types) ||
            (!types && !payload && !hasConnections) ||
            (!connection.types && !connection.payload && !connection.hasConnections) ||
            (types && connection.types && types.some(
              (type) => connection.types.includes(type)
            )); // acceptable types intersect

    if (typesMatch) {
            // Candidate is in close proximity
      const dx = position.x - connection.position.x;
      const dy = position.y - connection.position.y;
      isPotentialMatch = (dx || dy) ? (Math.sqrt(dx * dx + dy * dy) <= proximity) : true;
    }
  }

  const typesLabel = (types && types.length) ? types.join(', ') : (isAnyAccepted ? 'any' : '');

  useEffect(() => {
    const candidate = connection.candidate;
    if (isPotentialMatch && candidate !== payload) {
      // If connection candidate is searched in close proximity
      // register myself as a connection candidate
      connection.registerCandidate(payload);
    } else if (!isPotentialMatch && candidate === payload) {
      // If has already been registered but left later
      // cancel the registration
      connection.registerCandidate(undefined);
    }

    if (!isDragging) {
      // When no linker is dragged, is's position must be preserved
      // the same as the initial one
      setMyPosition(position);

      if (isDragging === null) {
        // Triggers when linker is dropped
        setDragging(false); // Stops dragging
        setMyPosition({x: position.x, y: position.y}); // Renews own position to the initial state
        if (candidate !== undefined) {
          // If dropped onto the connection candidate, create the link
          // to it in accordance with the isInput
          activeEpt.addLink(isInput ? candidate : payload, isInput ? payload : candidate);
        }
        // Stop searching for the connection candidate
        connection.stopSearching();
      }
    }

    if (isAnyAccepted && !payload) {
      // If point accepts any type it must change its type after connection
      if (hasConnections) {
        const intersectedTypes = intersection(...connectionsTypes);
        // If there are connections and
        if (!isEmpty(intersectedTypes)) {
          if (intersectedTypes.join(', ') !== typesLabel) {
            // the set differs from the currently registered -
            // register it in EPT
            activeEpt.setAcceptedTypes(payload, intersectedTypes, isInput);
          }
        } else if (typesLabel !== 'any') {
          // If there are connections but all of them are 'any'
          // reset type to 'any' as well
          activeEpt.setAcceptedTypes(payload, null, isInput);
        }
      } else if (types !== null) {
        // If no connections - reset to null to accept all types
        activeEpt.setAcceptedTypes(payload, null, isInput);
      }
    }
  }, [
    connection, isPotentialMatch, payload, isDragging, isAnyAccepted, position,
    activeEpt, isInput, hasConnections, types, connectionsTypes, typesLabel
  ]);

  const classes = cx(
    'connection-point',
    {
      in: isInput,
      out: !isInput,
      approached: isPotentialMatch,
      standalone: !payload,
      disabled
    }
  );

  const handleStartDragging = useCallback(() => {
    if (!disabled) setDragging(true);
  }, [disabled]);

  const handleMoving = useCallback((position) => {
    if (disabled) return;
    // When linker is being dragged:
    // * update search criteria (including current position)
    connection.startSearching({isInput, types, position, payload, isAnyAccepted, hasConnections});
    // * update linker position according to the mouse
    setMyPosition({x: position.x, y: position.y});
  }, [connection, disabled, hasConnections, isAnyAccepted, isInput, payload, types]);

  const onDropHandler = useCallback(() => setDragging(null), []);

  return [
    <g
      key='connection-point'
      transform={`translate(${position.x},${position.y})`}
      className={classes}
    >
      <circle radius={connectionPointRadius} />
      <text>{typesLabel}</text>
    </g>,

    (isMultiple || !hasConnections) && !disabled && [
      // Don't show dragger for single-connection points already connected
      <Draggable
        key='linker'
        position={myPosition}
        onStartDragging={handleStartDragging}
        onMove={handleMoving}
        onDrop={onDropHandler}
        disabled={disabled}
      >
        <circle className='ct-linker' />
      </Draggable>,

      isDragging &&
        // When linker is being dragged a temporary link to it must be shown
        (isInput ?
          <Link key='link' to={position} from={myPosition} /> :
          <Link key='link' from={position} to={myPosition} />)
    ]
  ];
};

export default observer(ConnectionPoint);
