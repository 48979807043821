import {FC} from 'react';
import {map} from 'lodash';

import {PlaneData} from '../utils';
import {Plane} from './Plane';

type PlanesProps = {
  planes: Record<string, PlaneData>;
  onClick?: (id: string) => void;
  planeRole: string;
};

export const Planes: FC<PlanesProps> = ({
  planes, onClick, planeRole
}) => {
  return (
    <>
      {map(planes, ({label, testDataAttributes, ...layout}, id) => (
        <Plane
          key={id}
          label={label}
          onClick={() => onClick?.(id)}
          planeRole={planeRole}
          testDataAttributes={testDataAttributes}
          {...layout}
        />))}
    </>
  );
};
