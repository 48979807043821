import {FC} from 'react';

import {Markdown} from '../../components/Markdown';

type MarkdownDescriptionProps = {
  description: string;
}

export const MarkdownDescription: FC<MarkdownDescriptionProps> = ({
  description
}) => {
  return <Markdown>{description}</Markdown>;
};
