import {Component} from 'react';
import {observer} from 'mobx-react';
import {Popup} from 'semantic-ui-react';
import {map} from 'lodash';

import './DashboardPreview.less';

@observer
export default class DashboardPreview extends Component {
  render() {
    const {grid} = this.props;
    return (
      <div className='iba-dashboard-preview'>
        {map(grid, (column, columnIndex) =>
          <div key={columnIndex} className='iba-dashboard-preview-column'>
            {map(column, (widget, widgetIndex) => {
              return (
                <Popup
                  key={widgetIndex}
                  trigger={
                    <div className='iba-widget-preview'>
                      <div>
                        <div className='widget-label'>
                          {widget.label}
                        </div>
                      </div>
                    </div>
                  }
                  size='small'
                  content={widget.label}
                  position='top center'
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
