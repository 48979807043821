import {Component, Fragment} from 'react';
import {observable, action, computed, comparer, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {filter, first, flattenDeep, invertBy, isFunction, map, reject, sortBy,
  transform} from 'lodash';
import {Container, Icon, Message} from 'semantic-ui-react';

import HeadroomDiagram from '../../iba/components/graphs/HeadroomDiagram';
import {filtersToQueryParam} from '../../queryParamUtils';
import {TrafficSearchBoxForm} from '../../iba/components/TrafficSearchBox';
import {TRAFFIC_PROBE_STAGE, buildGraphFromCablingMap, getNeighborsPaths,
  getGraphBfs} from '../../iba/components/graphs/trafficUtils';
import {NODE_ROLES} from '../../roles';

@observer
export default class NodeTrafficView extends Component {
  static defaultProps = {
    neighborsTargetValue: {
      value: 'neighbors',
      text: 'Neighbors',
    }
  };

  @observable.ref paths = [];
  @observable targetId = this.props.targetNodeId;
  usedSystemIds = [];

  @action
  setTargetId = (targetId) => {
    this.targetId = targetId;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get nodeList() {
    return sortBy(map(reject(this.props.nodes, {role: NODE_ROLES.LEAF_PAIR}), ({id, label}) => ({
      value: id,
      text: label
    })), 'text');
  }

  componentDidMount() {
    this.pathsDisposer = reaction(
      () => [this.props.cablingMap, this.props.sourceNodeId, this.targetId],
      this.calculatePaths,
      {equals: comparer.structural, fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.pathsDisposer();
  }

  @action
  calculatePaths = () => {
    const {props: {cablingMap, sourceNodeId: sourceId, isFreeform}, targetId} = this;
    const graph = buildGraphFromCablingMap({cablingMap, isFreeform});
    if (!sourceId) return null;
    this.paths = this.targetId ? getGraphBfs({isFreeform})({graph, sourceId, destinationId: targetId})
      : getNeighborsPaths({graph, sourceId});
    this.reportUsedSystemIds();
  };

  reportUsedSystemIds = () => {
    const {paths, props: {setFetchProbeParams, systemIdMap}} = this;
    if (!isFunction(setFetchProbeParams)) return;
    const pathParts = flattenDeep(paths);
    const ids = new Set([...map(pathParts, 'srcId'), ...map(pathParts, 'dstId')]);
    this.usedSystemIds = map(filter(systemIdMap, ({id}) => ids.has(id)), 'system_id');
    const systemIdById = invertBy(systemIdMap, 'id');
    const interfaceFilter = transform(pathParts, (filter, {dstId, srcId, links}) => {
      const srcSystemId = first(systemIdById[srcId]);
      const dstSystemId = first(systemIdById[dstId]);
      map(links, ({dst_intf_name: dstIntfName, src_intf_name: srcIntfName}) => {
        if (srcSystemId && srcIntfName) filter.push([srcSystemId, srcIntfName]);
        if (dstSystemId && dstIntfName) filter.push([dstSystemId, dstIntfName]);
      });
    }, []);
    setFetchProbeParams({
      [TRAFFIC_PROBE_STAGE.systemInterfaceCounters]: {
        filter: filtersToQueryParam({system_id: this.usedSystemIds}),
        'items-count': this.usedSystemIds.length,
      },
      [TRAFFIC_PROBE_STAGE.averageInterfaceCounters]: {
        filter: filtersToQueryParam({'[system_id, interface]': interfaceFilter}),
        'items-count': interfaceFilter.length,
      }
    });
  };

  onTargetChange = (value) => this.setTargetId(value === this.props.neighborsTargetValue.value ? null : value);

  render() {
    const {
      props: {dataSource, labelKey, sourceNodeId, neighborsTargetValue, systemIdMap,
        interfaceValuesSchema, systemValuesSchema, stageSystems, stageInterfaces},
      nodeList, paths, targetId, onTargetChange
    } = this;
    return (
      <Fragment>
        <Container fluid>
          <TrafficSearchBoxForm
            hideSource
            targetList={[neighborsTargetValue, ...nodeList]}
            onTargetChange={onTargetChange}
            targetId={targetId || neighborsTargetValue.value}
          />
        </Container>
        {paths.length ?
          <HeadroomDiagram
            key={`${sourceNodeId}:${targetId}`}
            dataSource={dataSource}
            paths={paths}
            systemIdMap={systemIdMap}
            systemItems={stageSystems?.items}
            interfaceItems={stageInterfaces?.items}
            labelKey={labelKey}
            interfaceValuesSchema={interfaceValuesSchema}
            systemValuesSchema={systemValuesSchema}
          />
          :
          <Message warning icon>
            <Icon name='warning sign' />
            <Message.Content>
              {'There is no neighbors for selected node'}
            </Message.Content>
          </Message>
        }
      </Fragment>
    );
  }
}
