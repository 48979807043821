import {observer} from 'mobx-react';
import {useState, useEffect, useCallback, useMemo} from 'react';
import {Modal, Button, Menu, Grid, Popup} from 'semantic-ui-react';
import {
  filter, values, forEach, size, map, some, reduce, every, keyBy, countBy, without, keys,
  transform, uniq, flatten, sortBy, find
} from 'lodash';

import {useCablingMapStore} from '../store/useCablingMapStore';
import Aggregator from './Aggregator/Aggregator';
import AggregateProperties from './Aggregator/AggregateProperties';
import Legend from './Aggregator/Legend';
import Step from '../store/Step';
import {useTooltip} from '../../components/graphs/GraphTooltips';
import {ctrlNodesPlacements, ctrlStages} from '../const';
import {useLinkEditor} from './LinkEditorModal';

import './AggregatorModal.less';

// Checks whether current grouping corresponds to endpoint groups of the
// aggregate link.
// Returns: true - order is correct; null - order is mirrored; false - incorrect order
const isGroupingAligned = (placement, link) => {
  let isInversed = false;
  const isAggregate = link.isAggregate;
  const result = every(link.endpoints, ({nodeId, endpointGroup}, index) => {
    const endpointGroupIndex = isAggregate ? endpointGroup.index : index;
    const nodeGroup = placement[nodeId] === ctrlNodesPlacements.LEFT ? 0 : 1;

    if ((nodeGroup === endpointGroupIndex && !isInversed) || (nodeGroup !== endpointGroupIndex && isInversed)) {
      return true;
    } else if (index) {
      return false;
    } else {
      isInversed = true;
      return true;
    }
  });
  return isInversed ? (result ? (isAggregate ? null : true) : false) : result;
};

// Detecting nodes order from the aggregate link's endpoints
const getAggregateNodesOrder = (aggregateLink, nodesLinks) => (
  transform(
    aggregateLink.endpointsByGroup[0],
    (acc, {nodeId}) => {
      acc.push(
        nodeId,
        ...map(
          sortBy(filter(nodesLinks[nodeId], {aggregateId: aggregateLink.id}), 'symmetricId'),
          (link) => (link.orderEndpointsFor(nodeId)[1].nodeId)
        )
      );
    },
    []
  )
);

const AggregatorModal = ({selectedNodes, selectedLink, close}) => {
  const {cablingMap} = useCablingMapStore();
  const {sharedTooltip} = useTooltip();
  const [editedEndpoint, editEndpoint] = useState(null);
  const [placement, setPlacement] = useState(selectedLink ?
    // If aggregate is selected - initial placement is determined by its groups
    reduce(selectedLink.endpoints, (result, {nodeId, endpointGroup}) => (
      {...result, [nodeId]: endpointGroup.index ? ctrlNodesPlacements.RIGHT : ctrlNodesPlacements.LEFT}
    ), {}) :
    // otherwise selected nodes considered to be group 0
    reduce(selectedNodes, (result, {id}) => ({...result, [id]: ctrlNodesPlacements.LEFT}), {})
  );

  // When aggregate link is passed - its links must be selected by default
  const [selectedLinks, setSelectedLinks] = useState(selectedLink ?
    reduce(
      filter(cablingMap.links, {aggregateId: selectedLink.id}),
      (result, link) => ({...result, [link.id]: link}),
      {}
    ) : {}
  );

  const [linkEditorContent, showLinkEditor] = useLinkEditor();

  // Only links of the equal speed could be aggregated
  const selectedSpeed = useMemo(() => find(map(selectedLinks, 'speedString')), [selectedLinks]);

  // Nodes to physical links reference
  const affectedNodesIds = useMemo(() => {
    const leftNodes = selectedLink ?
      map(selectedLink.endpointsByGroup[0], ({nodeId}) => cablingMap.nodes[nodeId]) :
      values(selectedNodes);
    const leftIds = map(leftNodes, 'id');

    return uniq(transform(
      filter(
        cablingMap.links,
        (link) => link.isAggregated ?
          (selectedLink ?
              link.aggregateId === selectedLink.id :
              cablingMap.aggregateLinks[link.aggregateId]?.originatesWithin(leftIds)
          ) :
          link.contains(leftNodes)
      ),
      (acc, link) => acc.push(...map(link.endpoints, 'nodeId')),
      []
    )).sort();
  }, [selectedNodes, selectedLink]); // eslint-disable-line react-hooks/exhaustive-deps

  // If an aggregate link supplied in props, affected nodes and their placement
  // is determined by its endpoints.
  // Otherwise selected nodes are considered to be group 0 and their linked nodes -
  // as group 1
  const affectedNodes = useMemo(() => (
    keyBy(map(affectedNodesIds, (nodeId) => cablingMap.nodes[nodeId]), 'id')
  ), [affectedNodesIds]); // eslint-disable-line react-hooks/exhaustive-deps

  // From links from selected nodes determine group 2 nodes (right side)
  useEffect(() => {
    // The rest of affected nodes considered to be group 1
    forEach(affectedNodes, ({id}) => {
      if (!placement[id]) placement[id] = ctrlNodesPlacements.RIGHT;
    });
    setPlacement({...placement});
  }, [affectedNodesIds]); // eslint-disable-line react-hooks/exhaustive-deps

  // Aggregate links between affected nodes (cannot memoise these)
  const aggregateLinks = keyBy(filter(
    values(cablingMap.aggregateLinks),
    (link) => link.fallsWithin(affectedNodesIds)
  ), 'id');

  // Links between affected nodes
  const links = useMemo(() => (
    filter(
      values(cablingMap.links),
      (link) => (
        link.isAggregated ?
          // Aggregated link only gets displayed if its aggregate falls within selected nodes
          aggregateLinks[link.aggregateId] :
          link.fallsWithin(affectedNodesIds)
      )
    )
  ), [affectedNodesIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const nodesLinks = useMemo(() => (
    transform(links, (acc, link) => {
      (acc[link.endpoint1.nodeId] ??= []).push(link);
      (acc[link.endpoint2.nodeId] ??= []).push(link);
    }, {})
  ), [links]);

  // Aggregate links display order must correspond to nodes placement
  const visibleAggregateLinks = filter(aggregateLinks, 'exists');
  const visibleAggregateLinksIds = map(visibleAggregateLinks, 'id').sort();
  const aggregateTrigger = map(visibleAggregateLinks, 'stateId').join();
  const haveErrors = some(visibleAggregateLinks, (link) => (
    !link.isValid || link.containsDuplicates(cablingMap.usedIps)
  ));

  // Nodes on the right must be ordered in accordance with aggregates endpoints
  const nodesOrder = useMemo(() => {
    const order = uniq([
      ...transform(
        visibleAggregateLinksIds,
        (result, linkId) => result.push(...getAggregateNodesOrder(aggregateLinks[linkId], nodesLinks)),
        []
      ),
      ...flatten(map(links, ({endpoints}) => map(endpoints, 'nodeId')))
    ]);
    return order;
  }, [aggregateTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Backing up affected links
    forEach([...links, ...values(aggregateLinks)], (link) => link.backup());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Calculate nodeId to aggregate endpoint references in order to manage
  // their properties
  const aggregateEnpoints = useMemo(() => (
    transform(visibleAggregateLinksIds, (result, aggregateLinkId) => {
      const aggregateLink = aggregateLinks[aggregateLinkId];
      result[aggregateLink.id] = keyBy(aggregateLink.endpoints, 'nodeId');
    }, {})
  ), [aggregateTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  // Toggles link selection
  const toggleLinkSelection = useCallback((link, forcedValue) => {
    if (!link) {
      return setSelectedLinks({});
    }
    const isAggregate = link.isAggregated;
    const currentValue = forcedValue === undefined ? selectedLinks[link.id] : !forcedValue;
    const affectedLinks = isAggregate ?
      keyBy(filter(links, {aggregateId: link.aggregateId}), 'id') :
      {[link.id]: link};

    if (isAggregate) {
      // Making it possible to select the only one aggregate link at once
      forEach(selectedLinks, ({isAggregated}, id) => {
        if (isAggregated) delete selectedLinks[id];
      });
    }

    const markerLink = isAggregate ? aggregateLinks[link.aggregateId] : link;
    // If one of aggregated links clicked it needs to assure current groupping
    // corresponds to link's endpoint groups:
    // * if they are the same - toggle link's selection
    // * if they differ - reset current selection, regroup nodes in accordance with
    //   links endpoint groups and then toggle aggregate's links
    const ordering = isGroupingAligned(placement, markerLink);

    if (ordering === false) {
      // If order is incorrect - make only aggregate's links selected
      setSelectedLinks(affectedLinks);
    } else {
      // toggle aggregate's links seleciton otherwise
      forEach(affectedLinks, (link) => {
        if (currentValue) {
          delete selectedLinks[link.id];
        } else {
          selectedLinks[link.id] = link;
        }
      });
      setSelectedLinks({...selectedLinks});
    }
    if (!ordering) {
      // If orderins was not correct - update according to aggregate's
      forEach(markerLink.endpoints, ({endpointGroup, nodeId}, index) => {
        const endpointGroupIndex = isAggregate ? endpointGroup.index : index;
        placement[nodeId] = endpointGroupIndex ?
          ctrlNodesPlacements.RIGHT : ctrlNodesPlacements.LEFT;
      });
      setPlacement({...placement});
    }
  }, [selectedLinks, links, placement, aggregateTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  // Detect is there any changes were made to apply
  const haveChanges = some(
    [...links, ...values(aggregateLinks)],
    'hasChanges'
  );

  // Commit changes
  const commitChanges = (andClose) => {
    const change = [];
    forEach([...links, ...values(aggregateLinks)], (link) => {
      // For each of links
      const operation = link.stage;
      const previousState = link.restore(true);
      if (operation === ctrlStages.DELETE) {
        // Only delete physical links if marked so
        if (!link.isAggregate) {
          link.commit();
          change.push(Step.deletion(previousState));
          cablingMap.deleteLink(link);
          link.markForDeletion(); // Mark as deleted to identify empty aggregates
        }
      } else if (link.hasChanges || operation === ctrlStages.ADD) {
        // ... and changes/additions
        link.commit();
        change.push(new Step(operation, link, previousState));
      }
    });
    // Removing empty and deleted aggregate links
    forEach(aggregateLinks, (link, aggregateId) => {
      if (!size(filter(links, {aggregateId, exists: true}))) {
        change.push(Step.deletion(link.restore()));
        cablingMap.deleteLink(link);
      }
    });
    cablingMap.changes.register(change);
    if (andClose) close();
  };

  // Reverts all changes user made to links
  const discardChanges = () => {
    // When user discards their changes, ...
    forEach([...links, ...values(aggregateLinks)], (link) => cablingMap.discardLinkChanges(link));
    close();
  };

  const aggregateSelected = (aggregateId = null) => {
    cablingMap.setAggregateFor(values(selectedLinks), aggregateId, placement);
    setSelectedLinks({});
  };

  const separateSelected = () => {
    forEach(selectedLinks, (link) => {
      if (link.isAggregated) aggregateLinks[link.aggregateId]?.markForDeletion();
    });
    cablingMap.setAggregateFor(values(selectedLinks));
    setSelectedLinks({});
  };

  const deleteSelected = () => {
    forEach(selectedLinks, (link) => {
      if (link.isAggregated) aggregateLinks[link.aggregateId]?.markForDeletion();
      link.markForDeletion();
    });
    setSelectedLinks({});
  };

  const selectionSize = size(selectedLinks);
  const aggregateIds = without(keys(countBy(selectedLinks, 'aggregateId')), 'null', 'undefined');
  const aggregatesCount = size(aggregateIds);
  const hasPlains = some(selectedLinks, {isAggregated: false});

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      onClose={discardChanges}
      size='large'
      className='node-links'
      onActionClick={() => editEndpoint(null)}
      onClick={() => editEndpoint(null)}
      mountNode={sharedTooltip.mountRef}
    >
      <Modal.Header>{'Aggregation Management'}</Modal.Header>
      <Modal.Content scrolling>
        {linkEditorContent}
        <Legend />
        <Grid stretched className='endpoint-groups'>
          <Grid.Row>
            <Grid.Column width={6}>{'Endpoint Group 1'}</Grid.Column>
            <Grid.Column width={4} textAlign='center'>
              {
                selectedSpeed &&
                  <>
                    <div className='speed-limit'>{selectedSpeed}</div>
                    <div>{'Links speed limit'}</div>
                  </>
              }
            </Grid.Column>
            <Grid.Column width={6} textAlign='right'>{'Endpoint Group 2'}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Aggregator
          nodes={affectedNodes}
          links={links}
          aggregateLinks={aggregateLinks}
          selectedLinks={selectedLinks}
          onLinkClick={toggleLinkSelection}
          placement={placement}
          setPlacement={setPlacement}
          editedEndpoint={editedEndpoint}
          editEndpoint={editEndpoint}
          aggregateEnpoints={aggregateEnpoints}
          nodesOrder={nodesOrder}
          nodesLinks={nodesLinks}
          selectedSpeed={selectedSpeed}
          usedIps={cablingMap.usedIps}
        />
      </Modal.Content>
      <Modal.Actions>
        {
          !!links.length &&
            <div className='bulk-actions'>
              <Menu secondary>
                {!!selectionSize &&
                  <>
                    <Menu.Item header>{`With ${selectionSize} selected:`}</Menu.Item>
                    {hasPlains && aggregatesCount < 2 &&
                      <Menu.Item
                        as='button'
                        role='button'
                        aria-label='Aggregate selected links'
                        tabIndex={0}
                        name='Aggregate'
                        onClick={() => aggregateSelected(aggregateIds?.[0])}
                      />
                    }
                    {aggregatesCount > 0 && !hasPlains &&
                      <Menu.Item
                        as='button'
                        role='button'
                        aria-label='Separate selected aggregated links'
                        tabIndex={0}
                        name='Separate'
                        onClick={separateSelected}
                      />
                    }
                    <Menu.Item
                      as='button'
                      role='button'
                      aria-label='Edit aggregated links'
                      tabIndex={0}
                      disabled={!!selectedLinks?.length}
                      name='Edit Links'
                      onClick={() => showLinkEditor(selectedLinks)}
                    />
                    {aggregatesCount === 1 && !hasPlains &&
                      <Popup
                        on={['click']}
                        inverted={false}
                        size='tiny'
                        basic
                        trigger={
                          <Menu.Item
                            as='button'
                            role='button'
                            aria-label='Manage aggregate link properties'
                            tabIndex={0}
                            name='Manage properties'
                          />
                        }
                        header='Aggregate Link Properties'
                        content={
                          <AggregateProperties aggregateLink={aggregateLinks[aggregateIds[0]]} />
                        }
                      />
                    }
                    <Menu.Item
                      as='button'
                      role='button'
                      aria-label='Delete selected links'
                      tabIndex={0}
                      name='Delete'
                      color='red'
                      onClick={deleteSelected}
                    />
                  </>
                }
              </Menu>
            </div>
        }
        <Button
          primary
          content='Apply Changes'
          onClick={commitChanges}
          disabled={!haveChanges || haveErrors}
        />
        <Button
          content='Discard'
          onClick={discardChanges}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default observer(AggregatorModal);
