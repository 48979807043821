import {LayoutContext} from 'apstra-ui-common';
import {useContext, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {replace} from 'lodash';

export function useScrollToAnchor(containerTop) {
  const scrolledRef = useRef(false);
  const {hash} = useLocation();
  const hashRef = useRef(hash);
  const {mainContentElement} = useContext(LayoutContext);

  useEffect(() => {
    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }
      if (!scrolledRef.current) {
        const id = replace(hash, '#', '');
        const element = document.getElementById(id);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          mainContentElement?.scrollTo({
            top: elementPosition + mainContentElement.scrollTop - containerTop,
            behavior: 'smooth'
          });
          scrolledRef.current = true;
        }
      }
    }
  });

  const resetScrolledRef = () => {
    scrolledRef.current = false;
  };
  return {resetScrolledRef};
}
