import {transform} from 'lodash';

import {ILink, ILinkEndpoint, Layout} from '../types';
import {TOPOLOGY_CONFIG} from '../consts';

type InterfaceData = {
  id: string,
  name: string,
  sourceInterface: ILinkEndpoint,
};

function arrangeInterfaces(interfaces: InterfaceData[], x1: number, y1: number, x2: number, y2: number, pad: number) {
  const count = interfaces.length;
  if (!count) return {};
  const length = ((x2 - x1) ** 2 + (y2 - y1) ** 2) ** 0.5;
  const dx = (x2 - x1) / length;
  const dy = (y2 - y1) / length;
  const cx = x1 + dx * length / 2;
  const cy = y1 + dy * length / 2;
  if (count === 1) {
    return {[interfaces[0].id]: {
      position: {x: Math.ceil(cx), y: Math.ceil(cy)},
      sourceInterface: interfaces[0].sourceInterface,
    }};
  }
  const interfacesStep = (length - 2 * pad) / (count - 1);
  return transform(interfaces, (acc, {id, sourceInterface}, index) => {
    const x = Math.ceil(x1 + (pad + index * interfacesStep) * dx);
    const y = Math.ceil(y1 + (pad + index * interfacesStep) * dy);
    acc[id] = {position: {x, y}, sourceInterface};
  }, {});
}

export const getNodeInterfaces = (links: ILink[], nodeLayouts: Record<string, Layout>) => {
  const nodeInterfaces = transform(links, (acc, {endpoints}) => {
    const node0Layout = nodeLayouts[endpoints[0].id];
    const node1Layout = nodeLayouts[endpoints[1].id];
    if (!node0Layout || !node1Layout) return;
    const isHorizontal = node0Layout.top === node1Layout.top;

    const interface0 = {id: endpoints[0].if_id, name: endpoints[0].if_name, sourceInterface: endpoints[0]};
    const interface1 = {id: endpoints[1].if_id, name: endpoints[1].if_name, sourceInterface: endpoints[1]};

    acc[endpoints[0].id] ??= {left: [], right: [], top: [], bottom: []};
    acc[endpoints[1].id] ??= {left: [], right: [], top: [], bottom: []};

    if (isHorizontal) {
      if (node0Layout.left < node1Layout.left) {
        acc[endpoints[0].id].right.push(interface0);
        acc[endpoints[1].id].left.push(interface1);
      } else {
        acc[endpoints[0].id].left.push(interface0);
        acc[endpoints[1].id].right.push(interface1);
      }
    } else if (node0Layout.top < node1Layout.top) {
      acc[endpoints[0].id].bottom.push(interface0);
      acc[endpoints[1].id].top.push(interface1);
    } else {
      acc[endpoints[0].id].top.push(interface0);
      acc[endpoints[1].id].bottom.push(interface1);
    }
  }, {});

  const interfacePositions = transform(nodeInterfaces, (acc, {left, right, top, bottom}, nodeId) => {
    const {interfaceSize} = TOPOLOGY_CONFIG;
    const interfaceOffset = interfaceSize / 2;
    const layout = nodeLayouts[nodeId];
    const x1 = layout.left + interfaceOffset;
    const x2 = layout.left + layout.width - interfaceOffset;
    const y1 = layout.top + interfaceOffset;
    const y2 = layout.top + layout.height - interfaceOffset;

    Object.assign(acc,
      arrangeInterfaces(left, x1, y1, x1, y2, TOPOLOGY_CONFIG.interfaceVerticalPadding),
      arrangeInterfaces(right, x2, y1, x2, y2, TOPOLOGY_CONFIG.interfaceVerticalPadding),
      arrangeInterfaces(top, x1, y1, x2, y1, TOPOLOGY_CONFIG.interfaceHorizontalPadding),
      arrangeInterfaces(bottom, x1, y2, x2, y2, TOPOLOGY_CONFIG.interfaceHorizontalPadding)
    );
  }, {});

  return interfacePositions;
};
