import {curveBasis} from '@visx/curve';
import {LinePath} from '@visx/shape';
import cx from 'classnames';
import {Component} from 'react';
import {Popup} from 'semantic-ui-react';

import './GraphLink.less';

export default class GraphLink extends Component {
  static defaultProps = {
    lineRadius: 5,
    offsetX: 15,
    offsetY: 15,
  };

  render() {
    const {aggregated, from, to, highlighted, isDragging, offsetX, offsetY, lineRadius, onClose, canClose} = this.props;
    const vDir = Math.sign(to.y - from.y - offsetY);
    const hDir = Math.sign(to.x - from.x + offsetX);
    const closeOnHorizontal = Math.abs(to.x - from.x) > Math.abs(to.y - from.y);
    const btnX = closeOnHorizontal ? 0.5 * (from.x + to.x - offsetX) : from.x - offsetX;
    const btnY = closeOnHorizontal ? to.y - offsetY : 0.5 * (from.y + to.y - offsetY);
    return (
      <g
        className={cx('graph-link', {highlighted, dragging: isDragging, aggregated})}
      >
        <LinePath
          className='link'
          data={[
            {x: from.x, y: from.y},
            {x: from.x - offsetX + lineRadius, y: from.y},
            {x: from.x - offsetX, y: from.y},
            {x: from.x - offsetX, y: from.y + vDir * lineRadius},
            {x: from.x - offsetX, y: to.y - offsetY - vDir * lineRadius},
            {x: from.x - offsetX, y: to.y - offsetY},
            {x: from.x - offsetX + hDir * lineRadius, y: to.y - offsetY},
            {x: to.x - hDir * lineRadius, y: to.y - offsetY},
            {x: to.x, y: to.y - offsetY},
            {x: to.x, y: to.y - offsetY + lineRadius},
            {x: to.x, y: to.y},
          ]}
          x={({x}) => x}
          y={({y}) => y}
          curve={curveBasis}
        />
        {canClose &&
          <Popup
            trigger={
              <g
                className='close-button'
                onClick={onClose}
              >
                <circle
                  cx={btnX}
                  cy={btnY}
                  r={5}
                />
                <path
                  d={`M${btnX - 2},${btnY - 2}l4,4M${btnX + 2},${btnY - 2}l-4,4`}
                />
              </g>
            }
            content='Delete'
            offset={[-14, 0]}
          />
        }
      </g>
    );
  }
}
