import {useContext} from 'react';
import {observer} from 'mobx-react';
import {includes} from 'lodash';

import PlainInputSearchBox from '../../components/PlainInputSearchBox';
import {
  convertPropertyType, convertValueType, getStageDataSchema, getStageRenderingStrategy,
  getStageValuesSchema, getTelemetryServiceSchema,
} from '../stageUtils';
import IBAContext from '../IBAContext';

import {STAGE_DATA_SOURCE} from '../consts';
import {renderRegexTextInput} from '../commonRenderers';

const StageSearchBox = (props) => {
  const {filters, disabled, onChange, applyOnChange, highlightPropertyColumn, 'aria-label': ariaLabel, schema,
    asAccordion = true} = props;
  const ibaContext = useContext(IBAContext);

  return (
    <PlainInputSearchBox
      filters={filters}
      schema={schema || getStageSearchSchema(props, ibaContext)}
      applyOnChange={applyOnChange}
      disabled={disabled}
      onChange={onChange}
      highlightPropertyColumn={highlightPropertyColumn}
      aria-label={ariaLabel}
      renderers={[renderRegexTextInput]}
      asAccordion={asAccordion}
      splitIntoColumns={2}
    />
  );
};

export default observer(StageSearchBox);

export const getStageSearchSchema = (props, context) => {
  const {processorDefinitions, isFreeform} = context;
  const {processor, stage, stageItems, dataSource} = props;
  const isTelemetryServiceWarningsDataSource = dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings;
  const renderingStrategy = getStageRenderingStrategy({processor, stage, dataSource});
  const stageDataSchema = (
    isTelemetryServiceWarningsDataSource ?
      getTelemetryServiceSchema(stageItems) :
      getStageDataSchema({stage, processor, renderingStrategy, processorDefinitions, showValue: false})
  )
    .filter(({name, type}) => !includes(['anomaly', 'warning', 'timestamp'], name) && type !== 'any')
    .reduce((result, {name, label, type}) => {
      const isRange = convertValueType(type) === 'number';
      result.push({
        name,
        schema: {type: convertPropertyType(type), title: label, default: isRange ? {} : undefined},
        isRange,
      });
      if (name === 'properties.system_id' && !isTelemetryServiceWarningsDataSource) {
        result.push({
          name: 'properties.system_hostname',
          schema: {type: 'string', title: 'System Hostname'}
        });
        if (!isFreeform) {
          result.push({
            name: 'properties.system_role',
            schema: {type: 'string', title: 'System Role'}
          });
        }
      }
      return result;
    }, []);

  if (!isTelemetryServiceWarningsDataSource) {
    stageDataSchema.push(...getStageValuesSchema(stage));
  }

  return stageDataSchema;
};
