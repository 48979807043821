export const REPORT_TYPES = {
  markdown: 'markdown',
  boxChart: 'box_chart',
  lineChart: 'line_chart',
  barChart: 'bar_chart',
  stackedBarChart: 'stacked_bar_chart',
  pieChart: 'pie_chart',
  sunburstChart: 'sunburst_chart',
  section: 'section',
  table: 'table',
};

export const CHART_TYPES = new Set([
  REPORT_TYPES.boxChart, REPORT_TYPES.lineChart, REPORT_TYPES.barChart, REPORT_TYPES.pieChart,
  REPORT_TYPES.sunburstChart,
]);

// eslint-disable-next-line max-len
export const timeDeltaPattern = /^([-+])([0-9]|[1-9][0-9]{1,7})(:([0-9]|[1-9][0-9]{1,3}|[1-7][0-9]{4}|8[0-5][0-9]{3}|86[0-3][0-9]{2}))?$/;

export const PARAMETER_SCHEMA = 'parameter_schema';
export const PREDEFINED_PROBE_SCHEMA = 'predefined_probe_schema';

export const START_TIME_FIELD = 'start_time';
export const BEGIN_TIME_FIELD = 'begin_time';
export const END_TIME_FIELD = 'end_time';

// eslint-disable-next-line max-len
export const NO_AVAILABLE_PREDEFINED_PROBES = 'There are no available predefined probe instances. Please, instantiate one first';
