import StackTrace from 'stacktrace-js';

const loggerApiEndpoint = '/api/ui-logging';

export default function captureErrors() {
  const handledErrors = new WeakSet();

  async function handleError(error) {
    if (!error) return;
    if (handledErrors.has(error)) return;
    if (error instanceof Error) handledErrors.add(error);

    let stackFrames = [];
    let traceError;
    try {
      stackFrames = await StackTrace.fromError(error);
    } catch (e) {
      traceError = e;
    }
    return log({message: error.message, stackFrames, url: window.location.href, traceError});
  }

  async function log(message) {
    await fetch(loggerApiEndpoint, {
      method: 'POST',
      body: JSON.stringify(message),
    });
  }

  window.onerror = function(message, file, line, column, error) {
    handleError(error);
  };

  window.onunhandledrejection = function({reason}) {
    handleError(reason);
  };
}
