import {useMemo} from 'react';
import {map, find, get, transform} from 'lodash';
import {Field, MapInput, DropdownControl} from 'apstra-ui-common';

import ValueMapInput from './ValueMapInput';

import './MultiValueMapInput.less';

const generateNewEntry = () => ['value', {0: ''}];

export default function MultiValueMapInput(props) {
  const {name, value, schema, required, disabled, errors, onChange, telemetryServiceRegistryItems, values,
    fieldProps} = props;
  const serviceName = values?.service_name;
  const columnDropdownOptions = useMemo(() => {
    const service = find(telemetryServiceRegistryItems, {service_name: serviceName});
    const possibleColumnNames =
      service?.storage_schema_path === 'aos.sdk.telemetry.schemas.iba_data' ?
        transform(
          get(service, ['application_schema', 'properties', 'value', 'properties']),
          (result, columnDefinition, column) => {
            if (columnDefinition?.type === 'integer') result.push(column);
          },
          []
        )
      :
        ['value'];
    return map(possibleColumnNames, (name) => ({key: name, value: name, text: name}));
  }, [serviceName, telemetryServiceRegistryItems]);

  return (
    <MapInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      generateNewEntry={generateNewEntry}
      maxItems={columnDropdownOptions.length}
      className='multi-value-map-input'
      buttonText='Add Column'
      noItemsMessage='Value map is empty.'
      fieldProps={fieldProps}
    >
      {({key, value, index, errors, setEntryKey, setEntryValue}) =>
        <>
          <Field key='key' width={3}>
            <label className='column-title'>{'Column'}</label>
            <DropdownControl
              disabled={disabled}
              value={key}
              options={columnDropdownOptions}
              onChange={(value) => setEntryKey(index, value)}
            />
          </Field>
          <Field key='value' errors={errors} width={13}>
            <label className='column-title'>{'Value Map'}</label>
            <ValueMapInput
              disabled={disabled}
              value={value}
              onChange={(value) => setEntryValue(index, value)}
              minItems={1}
            />
          </Field>
        </>
      }
    </MapInput>
  );
}
