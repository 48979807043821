import {createContext} from 'react';

import {ProcessorCategories, ProcessorDefinition} from './types';

export type IBAContextValue = {
  allActiveNodes: any[];
  anomalyRemediationUrl: string;
  blueprintDesign: string;
  blueprintId: string;
  blueprintPermissions: Record<string, any>;
  blueprintTags: any[];
  cablingMap: any[];
  deviceProfiles: any[];
  generateVnLink: () => void;
  isFreeform: boolean;
  predefinedProbes: Array<{
    description: string;
    experimental: boolean;
    name: string;
    schema: any;
  }>;
  blueprintReferenceDesignSchema: Record<string, any>;
  processorDefinitions: ProcessorDefinition[];
  processorCategories: ProcessorCategories;
  routes: Record<string, string>;
  systemIdMap: Record<string, any>;
  systemsHrefs: Record<string, string>;
};

const IBAContext = createContext<IBAContextValue>(null!);

export default IBAContext;
