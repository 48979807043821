import {map} from 'lodash';

import MatcherFilterStringParser from './MatcherFilterStringParser';

let visitorInstance = null;

const MatcherFilterStringParserCSTVisitor =
  MatcherFilterStringParser.instance.getBaseCstVisitorConstructorWithDefaults();

function markAsNotSimple() {
  this.simple = false;
}
export default class MatcherFilterStringToObjectConverter extends MatcherFilterStringParserCSTVisitor {
  static get instance() {
    if (!visitorInstance) visitorInstance = new this();
    return visitorInstance;
  }

  static run(cst, schema) {
    const {instance} = this;
    instance.result = {};
    instance.simple = true;
    instance.schema = schema;
    instance.visit(cst);
    return instance.simple ? instance.result : null;
  }

  isNone = markAsNotSimple;
  isNotNone = markAsNotSimple;
  notEquals = markAsNotSimple;

  expression(ctx) {
    if (ctx.Or) {
      markAsNotSimple.call(this);
    } else {
      ctx.conditionOrSubexpression.map((conditionOrSubexpression) => this.visit(conditionOrSubexpression));
    }
  }

  gt(ctx) {
    const property = ctx.Property[0].image;
    const value = Number(ctx.NumberLiteral[0].image);
    this.result[property] = this.result[property] || {};
    this.result[property].min = value;
  }

  lt(ctx) {
    const property = ctx.Property[0].image;
    const value = Number(ctx.NumberLiteral[0].image);
    this.result[property] = this.result[property] || {};
    this.result[property].max = value;
  }

  equals(ctx) {
    const property = ctx.Property[0].image;
    if (ctx.value) {
      this.result[property] = this.visit(ctx.value);
    } else {
      markAsNotSimple.call(this);
    }
  }

  regexMatch(ctx) {
    const property = ctx.Property[0].image;
    this.result[property] = {
      value: this.visit(ctx.string),
      type: 'regex',
    };
  }

  inList(ctx) {
    const property = this.visit(ctx.in);
    this.result[property] = this.visit(ctx.list);
  }

  in(ctx) {
    return ctx.Property[0].image;
  }

  list(ctx) {
    return map(ctx.value, (v) => this.visit(v));
  }

  value(ctx) {
    if (ctx.NumberLiteral) {
      return Number(ctx.NumberLiteral[0].image);
    } else if (ctx.Bool) {
      return ctx.Bool[0].image.toLowerCase() === 'true';
    } else if (ctx.string) {
      return this.visit(ctx.string);
    } else {
      markAsNotSimple.call(this);
    }
  }

  string(ctx) {
    if (ctx.SingleQuoteStringLiteral) {
      return ctx.SingleQuoteStringLiteral[0].image.slice(1, -1).replace(/\\'/g, "'");
    } else if (ctx.DoubleQuoteStringLiteral) {
      return ctx.DoubleQuoteStringLiteral[0].image.slice(1, -1).replace(/\\"/g, '"');
    }
  }
}
