export default {
  type: 'object',
  properties: {
    display_name: {
      type: 'string',
      minLength: 1,
    },
    created_at: {
      type: 'string',
    },
    last_modified_at: {
      type: 'string',
    },
    generators: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          config_style: {
            type: 'string',
            minLength: 1,
          },
          section: {
            type: 'string',
            minLength: 1,
          },
          template_text: {
            type: 'string',
            minLength: 1,
          },
          negation_template_text: {
            type: 'string',
          },
          filename: {
            type: 'string',
          },
        },
        required: ['config_style', 'section', 'template_text'],
        additionalProperties: false,
      },
      minItems: 1,
    },
    ref_archs: {
      type: 'array',
      items: {
        type: 'string',
      },
      minItems: 1,
    },
    id: {
      type: 'string',
    },
  },
  required: ['display_name', 'generators', 'ref_archs'],
  additionalProperties: false,
};
