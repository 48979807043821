import {useMemo} from 'react';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {Text} from '@visx/text';
import {keyPressClick} from 'apstra-ui-common';

import Port from '../Port';
import {ctrlPortSize, ctrlIfcLabelWidth, ctrlIfcLabelHeight} from '../../const';
import './Interface.less';

const Interface = ({x, y, onRight, endpoint, node = {}, label, onClick, selected, disabled}) => {
  const keyClickHandler = useMemo(() => keyPressClick(onClick), [onClick]);
  const {isExternal, color} = node;
  const classes = cx(
    'ctrl-node ctrl-ifc',
    {
      external: isExternal,
      [`color-${color}`]: color,
      interactive: !!onClick,
      selected,
      disabled
    }
  );
  const allyProps = onRight || disabled ? {} : {
    onKeyDown: keyClickHandler,
    role: 'button',
    tabIndex: 0,
    'aria-label': `Select link ${label ? `"${label}"` : ''}`
  };
  return (
    <g
      className={classes}
      onClick={onClick}
      {...allyProps}
    >
      <rect
        x={onRight ? x : x - ctrlIfcLabelWidth}
        y={y - ctrlIfcLabelHeight / 2}
        width={ctrlIfcLabelWidth}
        height={ctrlIfcLabelHeight}
      />
      <Text
        x={onRight ? x + 10 : x - 10}
        y={y + 4}
        textAnchor={onRight ? 'start' : 'end'}
      >
        {endpoint.interfaceName || '---'}
      </Text>
      {
        selected &&
          <circle cx={x} cy={y} r={ctrlPortSize + 3} className='selection' />
      }
      <Port x={x} y={y} />
    </g>
  );
};

export default observer(Interface);
