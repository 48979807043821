import {observable, makeObservable} from 'mobx';

import ActiveEptStore from './ActiveEptStore';
import ConnectionCandidateStore from './ConnectionCandidateStore';
import PermissionsStore from './PermissionsStore';

class EptBuilderStore {
  @observable activeEpt;
  @observable connection;
  @observable access;

  constructor() {
    makeObservable(this);
    this.activeEpt = new ActiveEptStore(this);
    this.connection = new ConnectionCandidateStore(this);
    this.access = new PermissionsStore(this);
  }
}

export default EptBuilderStore;
