import {Component} from 'react';
import {Accordion, Message, List, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {size, keys} from 'lodash';

@observer
export default class CSVHelpMessage extends Component {
  static propTypes = {
    headers: PropTypes.object,
  };

  static defaultProps = {
    headers: {},
  };

  @observable open = false;

  @action
  toggle() {
    this.open = !this.open;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {headers} = this.props;

    if (size(headers)) {
      return (
        <Accordion style={{marginBottom: '1em'}}>
          <Accordion.Title
            active={this.open}
            onClick={() => this.toggle()}
          >
            <Icon name='dropdown' />
            {'See help to get more information about allowed CSV headers and values.'}
          </Accordion.Title>
          <Accordion.Content active={this.open}>
            <Message info>
              <List>
                <List.Item style={{marginBottom: '0.5em'}}>
                  <i>{'Headers marked with an asterisk (*) are mandatory.'}</i>
                </List.Item>
                {keys(headers)
                  .sort((header1, header2) => headers[header1].index - headers[header2].index)
                  .map((header) =>
                    <List.Item key={header}>
                      <b>{`${header}${headers[header].mandatory ? '(*)' : ''}`}</b>
                      {' - '}
                      <span>{headers[header].description}</span>
                    </List.Item>
                  )}
              </List>
            </Message>
          </Accordion.Content>
        </Accordion>
      );
    } else {
      return null;
    }
  }
}
