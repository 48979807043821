import {nodeWidth} from './consts';

export default function Node({content, x, y}) {
  return (
    <div
      style={{
        transform: `translate(${x}px,${y}px)`,
        width: `${nodeWidth}px`,
      }}
    >
      {content}
    </div>
  );
}
