import {Field, GlobalHelpButton, formatBytes} from 'apstra-ui-common';
import {startCase} from 'lodash';
import pluralize from 'pluralize';
import {FC, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, Form, Icon, Label, Popup, StrictBreadcrumbProps} from 'semantic-ui-react';

import IBAContext from '../../IBAContext';
import PredefinedEntityIcon from '../PredefinedEntityIcon';
import ProbeToggle from '../ProbeToggle';
import UpdatedByLabel from '../UpdatedByLabel';
import generateProbeURI from '../../generateProbeURI';
import {ReactComponent as StagePersisted} from '../../../../styles/icons/iba/stage-persisted.svg';

type ProbeBreadcrumbProps = {
  action?: string;
  diskUsage;
  probe;
  probeId?: string;
  refetchData?: () => void;
}

export const ProbeBreadcrumb: FC<ProbeBreadcrumbProps> = ({
  action, diskUsage, probe, probeId, refetchData
}) => {
  const {blueprintId, predefinedProbes} = useContext(IBAContext);
  const sections: StrictBreadcrumbProps['sections'] = [
    {key: 'home', content: 'Probes', as: Link, to: generateProbeURI({blueprintId})}
  ];
  if (!probeId) {
    sections.push({key: 'label', active: true, content: 'New Probe'});
  } else if (action) {
    const currentLabel = probe.label;
    sections.push(
      {key: 'label', content: currentLabel, as: Link, to: generateProbeURI({blueprintId, probeId})},
      {key: 'action', active: true, content: action === 'update' ? 'Edit' : 'Clone'}
    );
  } else {
    sections.push({
      key: 'label',
      active: true,
      content: (
        <>
          {probe.label}
          &nbsp;
          {probe.predefined_probe &&
            <PredefinedEntityIcon
              predefinedEntities={predefinedProbes}
              predefinedEntityName={probe.predefined_probe}
              predefinedEntityType='Predefined probe'
              trigger={<Label icon='box' />}
            />
          }
          &nbsp;
          <ProbeStateLabel probe={probe} />
          &nbsp;
          {probe.state === 'operational' && !probe.disabled && (
            <>
              <ProbeAnomalyCountLabel anomalyCount={probe.anomaly_count} />
              &nbsp;
            </>
          )}
          <UpdatedByLabel updatedBy={probe.updated_by} timestamp={probe.updated_at} />
          &nbsp;
          {diskUsage && <DiskUsageLabel diskUsage={diskUsage} />}
          &nbsp;
          <Form className='probe-toggle-form'>
            <Field inline label='Enabled'>
              <ProbeToggle
                probe={probe}
                value={!probe.disabled}
                refetchData={refetchData}
              />
            </Field>
          </Form>
        </>
      )
    });
  }

  return (
    <>
      <Breadcrumb
        size='big'
        icon='right caret'
        sections={sections}
      />
      {' '}
      {action === 'create' && <GlobalHelpButton helpPageId='probe-create-xi691815' />}
      {action === 'update' && <GlobalHelpButton helpPageId='probe-edit-xi957883' />}
    </>
  );
};

export const ProbeStateLabel = ({probe: {state, disabled}}) => {
  return (
    <Label
      color={
        disabled ? undefined :
        state === 'error' ? 'red' :
        state === 'operational' ? 'green' :
        state === 'maintenance' ? 'orange' :
        undefined
      }
    >
      {disabled ?
        <>
          <Icon name='pause' />
          {'Disabled'}
        </>
      :
        <>
          {state === 'error' && <Icon name='warning sign' />}
          {state === 'operational' && <Icon name='check circle' />}
          {state === 'configuring' && <Icon name='hourglass half' />}
          {state === 'maintenance' && <Icon name='wrench' />}
          {startCase(state)}
        </>
      }
    </Label>
  );
};

export const ProbeAnomalyCountLabel = ({anomalyCount}) => {
  return (
    <Label color={anomalyCount ? 'red' : 'green'}>
      <Icon name={anomalyCount ? 'warning sign' : 'check circle'} />
      {anomalyCount ? pluralize('anomaly', anomalyCount, true) : 'No anomalies'}
    </Label>
  );
};

export const DiskUsageLabel = ({diskUsage}) => {
  return (
    <Popup
      content={
        <>
          <strong>{'Consumed disk size: '}</strong>
          {formatBytes(diskUsage.size || 0)}
        </>
      }
      trigger={
        <Label icon={<StagePersisted className='stage-icon' />} content={formatBytes(diskUsage.size)} />
      }
    />
  );
};
