import {useMemo} from 'react';
import {scaleLinear} from 'd3';
import cx from 'classnames';
import {FormattedNumber} from 'apstra-ui-common';

import './SingleHorizontalBar.less';

export default function SingleHorizontalBar({
  value = 0, units = '', extraContent,
  xMin = 0, xMax = 100,
  minValue = 0, maxValue = 0,
}) {
  const barScale = useMemo(
    () => scaleLinear().range([xMin, xMax]).domain([minValue, maxValue]),
    [xMin, xMax, minValue, maxValue]
  );
  return (
    <div className='single-horizontal-bar graph-container'>
      <div
        className={cx({negative: value <= 0})}
        style={{width: barScale(value) + '%'}}
      >
        <FormattedNumber value={value} units={units} />
        {extraContent}
      </div>
    </div>
  );
}
