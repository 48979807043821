import {observer} from 'mobx-react';
import {map} from 'lodash';

import {ctrlNodeWidth, ctrlNodeHeight} from '../const';

import './GraphPreview.less';

const defaultNodeDefId = 'default-graph-node-def';

const DefaultNodeDef = () => {
  return <rect
    id={defaultNodeDefId}
    width={1}
    height={1}
  />;
};

const NodeList = observer(({nodes, nodeDefId}) => (
  <>
    {map(nodes, ({position, id, width = ctrlNodeWidth, height = ctrlNodeHeight}) => (
      <use
        key={id}
        href={'#' + nodeDefId}
        opacity={0.5}
        transform={`translate(${position.x}, ${position.y}) scale(${width} ${height})`}
      />
    ))}
  </>
));

export const GraphPreview = observer(({
  NodeDef = DefaultNodeDef, nodeDefId = defaultNodeDefId,
  nodes, minX, maxX, minY, maxY
}) => {
  return (
    <div className='graph-preview'>
      <svg viewBox={`${minX} ${minY} ${maxX - minX} ${maxY - minY}`}>
        <defs>
          <NodeDef />
        </defs>
        <NodeList nodes={nodes} nodeDefId={nodeDefId} />
      </svg>
    </div>
  );
});

GraphPreview.displayName = 'GraphPreview';
