import {Component} from 'react';

import PropTypes from 'prop-types';

import DateInRangeSlider from './DateInRangeSlider';

export default class StageDataSourceRange extends Component {
  static props = {
    aggregation: PropTypes.number,
    maxRangeDuration: PropTypes.number,
    date: PropTypes.date,
    beginTime: PropTypes.date,
    endTime: PropTypes.date,
    retentionDuration: PropTypes.number,
    isLoading: PropTypes.bool,
    fetchedIntervals: PropTypes.object,
    onChange: PropTypes.func
  };

  render() {
    const {
      aggregation, maxRangeDuration, date, beginTime, endTime, retentionDuration, isLoading, onChange,
      shouldUpdateTimeRange, baseTime, fetchedIntervals
    } = this.props;

    return (
      <DateInRangeSlider
        date={date}
        beginTime={beginTime}
        endTime={endTime}
        aggregation={aggregation}
        maxRangeDuration={maxRangeDuration}
        period={retentionDuration}
        periodLabel='Retention Duration'
        isLoading={isLoading}
        onChange={onChange}
        shouldUpdateTimeRange={shouldUpdateTimeRange}
        fetchedIntervals={fetchedIntervals}
        baseTime={baseTime}
      />
    );
  }
}
