import {Component} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import PropTypes from 'prop-types';
import {Group} from '@visx/group';
import {Line} from '@visx/shape';
import {Text} from '@visx/text';
import cx from 'classnames';
import {scalePoint} from 'd3';
import {isFunction, sortBy, toLower} from 'lodash';
import {observable, computed, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import pluralize from 'pluralize';

import './AnomalyMatrix.less';
import {anomalyPropTypes} from './shared-prop-types';
import AnomalyCircleList from './components/AnomalyCircleList';
import AnomalyMatrixHeader from './components/AnomalyMatrixHeader';

@observer
export default class AnomalyMatrix extends Component {
  static propTypes = {
    allShiftLeft: PropTypes.number,
    anomalies: PropTypes.arrayOf(anomalyPropTypes),
    anomalyTypes: PropTypes.arrayOf(PropTypes.string),
    heightShiftTop: PropTypes.number,
    innerTextMin: PropTypes.number,
    lineIncRight: PropTypes.number,
    lineShiftRight: PropTypes.number,
    margin: PropTypes.shape({
      top: PropTypes.number,
      right: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number
    }),
    maxValue: PropTypes.number,
    noAnomaliesHeight: PropTypes.number,
    noAnomaliesY: PropTypes.number,
    nodeShiftRight: PropTypes.number,
    onClickAnomaly: PropTypes.func,
    onClickNode: PropTypes.func,
    rowHeight: PropTypes.number,
    rowTextDY: PropTypes.string,
    width: PropTypes.number
  };

  static defaultProps = {
    allShiftLeft: 40,
    anomalies: [],
    anomalyTypes: ['Deployment', 'BGP', 'Cabling', 'Config', 'Interface', 'Liveness', 'Route', 'Hostname'],
    heightShiftTop: 10,
    innerTextMin: 14,
    lineIncRight: 30,
    lineShiftRight: 20,
    margin: {
      bottom: 20,
      left: 160,
      right: 120,
      top: 24
    },
    maxValue: 64,
    noAnomaliesHeight: 200,
    noAnomaliesY: 135,
    nodeShiftRight: 30,
    rowHeight: 35,
    rowTextDY: '0.36em',
    width: 780
  };

  @observable hoveredRowIndex = null;
  @observable isCircleHover = null;

  xScale = (value) => {
    const xScale = scalePoint();
    xScale
      .domain((this.props.anomalyTypes ?? []).map((d) => d.toLowerCase()))
      .rangeRound([0, this.props.width - this.props.margin.left - this.props.margin.right]);
    return xScale(value);
  };

  @action
  setHoveredRowIndex = (hoveredRowIndex) => {
    this.hoveredRowIndex = hoveredRowIndex;
  };

  @action
  setCircleHover = (isCircleHover) => {
    this.isCircleHover = isCircleHover;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get sortedAnomalies() {
    return sortBy(this.props.anomalies, 'node');
  }

  @computed get hasAnomalies() {
    const {anomalies} = this.props;
    return Array.isArray(anomalies) && anomalies.length > 0;
  }

  onClickAnomaly = (event, anomaly, type) => {
    if (event && isFunction(event.stopPropagation)) {
      // to not trigger click on row
      event.stopPropagation();
    }

    if (!!anomaly && isFunction(this.props.onClickAnomaly)) {
      type = toLower(type);
      this.props.onClickAnomaly(anomaly.node, type);
    }
  };

  onClickNode = (anomaly) => {
    if (!!anomaly && isFunction(this.props.onClickNode)) {
      this.props.onClickNode(anomaly.node);
    }
  };

  render() {
    const {hasAnomalies, hoveredRowIndex, onClickNode, setCircleHover, setHoveredRowIndex, sortedAnomalies,
      isCircleHover, onClickAnomaly, xScale} = this;

    const {anomalyTypes, allShiftLeft, heightShiftTop, innerTextMin, lineIncRight, lineShiftRight, margin,
      maxValue, noAnomaliesHeight, noAnomaliesY, nodeShiftRight, rowHeight, rowTextDY, width} = this.props;

    const anomalies = sortedAnomalies;

    const height = hasAnomalies ?
      anomalies.length * rowHeight + margin.top + margin.bottom :
      noAnomaliesHeight;

    const anomalyAllX = width - margin.right;
    const rowHeightHalf = rowHeight / 2;

    const circleListProps = {
      anomalyTypes,
      innerTextMin,
      maxValue,
      onClick: onClickAnomaly,
      onMouseEnter: () => setCircleHover(true),
      onMouseLeave: () => setCircleHover(false),
      rowHeight,
      rowTextdy: rowTextDY,
      left: margin.left,
      xScale
    };

    const anomalyNodeProps = {
      className: 'row-name',
      x: margin.left - nodeShiftRight,
      y: rowHeightHalf,
      dy: rowTextDY
    };

    const anomalyAllProps = {
      className: 'row-sum',
      x: anomalyAllX + allShiftLeft,
      y: rowHeightHalf,
      dy: rowTextDY
    };

    const lineProps = {
      x1: margin.right + lineShiftRight,
      x2: anomalyAllX + lineIncRight,
      y1: rowHeightHalf,
      y2: rowHeightHalf
    };

    return (
      <svg width={width} height={height} className='anomaly-matrix'>
        <AnomalyMatrixHeader
          anomalyTypes={anomalyTypes}
          top={margin.top - heightShiftTop}
          left={margin.left}
          xScale={xScale}
        />
        <Group top={rowHeight}>
          <TransitionGroup component={null}>
            {anomalies.map((anomaly, index) =>
              <CSSTransition
                key={anomaly.node}
                timeout={200}
                classNames='fade'
              >
                <Group
                  top={index * rowHeight}
                  className={cx('row', {selected: index === hoveredRowIndex && !isCircleHover})}
                  onClick={() => onClickNode(anomaly)}
                  onMouseEnter={() => setHoveredRowIndex(index)}
                  onMouseLeave={() => setHoveredRowIndex(-1)}
                >
                  <rect width={width} height={rowHeight} style={{opacity: 0}} />
                  <Text {...anomalyNodeProps}>{anomaly.node}</Text>
                  <Line {...lineProps} />
                  <AnomalyCircleList {...circleListProps} anomaly={anomaly} />
                  <Text {...anomalyAllProps}>{pluralize('anomaly', anomaly.all, true)}</Text>
                </Group>
              </CSSTransition>
            )}
          </TransitionGroup>
        </Group>

        {!hasAnomalies &&
          <Group>
            <Text className='no-anomalies' x={width / 2} y={noAnomaliesY}>
              {'No anomalies'}
            </Text>
          </Group>
        }
      </svg>
    );
  }
}
