import {createModelSchema, primitive, identifier, alias, raw} from 'serializr';
import {observable, computed, makeObservable} from 'mobx';

import {TAG_TYPES_METADATA, UNASSIGNED_TAG_TYPE} from './consts';
import {ALL_NODE_ROLES} from '../roles';

export class Endpoint {
  id;
  @observable tagType;

  @computed get isAssigned() {
    return this.tagType !== UNASSIGNED_TAG_TYPE;
  }

  @computed get color() {
    return TAG_TYPES_METADATA[this.tagType].color;
  }

  @computed get title() {
    return TAG_TYPES_METADATA[this.tagType].title;
  }

  constructor(data) {
    makeObservable(this);
    Object.assign(this, data);
  }
}

export class Link {
  linkLabel;
  endpoints = [];
  uiData = {};

  get endpoint() {
    const {system: {label: serverLabel}, interface: {id, vn_endpoint: endpoint}} = this.endpoints.find(
      ({system = {}}) => system.role === ALL_NODE_ROLES.GENERIC
    );
    return {
      id,
      serverLabel,
      tagType: endpoint === null ? UNASSIGNED_TAG_TYPE : endpoint.tag_type
    };
  }

  get leafs() {
    // todo(vigonin) refactor property name
    return this.endpoints
      .filter(({system = {}}) => [ALL_NODE_ROLES.LEAF, ALL_NODE_ROLES.ACCESS].includes(system.role))
      .map(({system, interface: {if_name: ifcName}}) => Object.assign(system, {ifcName}));
  }
}

createModelSchema(Endpoint, {
  id: alias('interface_id', identifier()),
  tagType: alias('tag_type', primitive())
});

createModelSchema(Link, {
  linkLabel: alias('group_label', primitive()),
  endpoints: raw(),
  uiData: alias('ui-data', raw())
});
