import {useCallback, useContext} from 'react';
import {request, interpolateRoute} from 'apstra-ui-common';

import ExportModal from '../../components/ExportModal';
import IBAContext from '../IBAContext';

export default function DashboardExportModal({dashboard = null, ...props}) {
  const {blueprintId, routes} = useContext(IBAContext);

  const exportCallback = useCallback(async () => {
    const dashboardId = dashboard.id;
    const exportedDashboard = await request(interpolateRoute(routes.dashboardExport, {blueprintId, dashboardId}));
    return JSON.stringify(exportedDashboard, null, 2);
  }, [blueprintId, routes, dashboard]);

  if (!dashboard) return null;
  const label = dashboard.label ?? 'Dashboard';

  return (
    <ExportModal
      header={`Export ${label}`}
      fileName={`Dashboard - ${label}.json`}
      export={exportCallback}
      type='application/json'
      {...props}
    />
  );
}
