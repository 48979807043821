import {map, size} from 'lodash';
import {observer} from 'mobx-react';

import {useCablingMapStore} from '../store/useCablingMapStore';
import AggregateLink from './AggregateLink';
import Link from './Link';

const CanvasLinks = ({readonly, onClickAggregate}) => {
  const {cablingMap: {manageLinks, visibleLinks, lagLinks}} = useCablingMapStore();
  return map(visibleLinks, (link) => {
    const memberLinks = lagLinks[link.id];
    const lagSize = size(memberLinks);
    return (
      link.isAggregate ?
        <AggregateLink
          key={link.id}
          link={link}
          memberLinks={memberLinks}
          readonly={readonly}
          {...(onClickAggregate ? {onClick: () => onClickAggregate(link)} : {})}
        /> :
        <Link
          key={link.id}
          startPoint={link.endpoint1}
          endPoint={link.endpoint2}
          link={link}
          size={lagSize}
          readonly={readonly}
          onClick={readonly ? undefined : manageLinks}
        />
    );
  });
};

export default observer(CanvasLinks);
