import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {split as SplitEditor} from 'react-ace';
import {forEach, repeat, get, map} from 'lodash';
import {diffLines} from 'diff';
import {Button, Popup} from 'semantic-ui-react';
import bounds from 'binary-search-bounds';

import './CodeDiff.less';

const CodeDiff = ({value, ...props}) => {
  const [markers, setMarkers] = useState([]);
  const editorRef = useRef();
  const diff = useMemo(() => {
    const [lhString, rhString] = value || [];
    if (lhString.length === 0 && rhString.length === 0) {
      return [];
    }
    return diffLines(lhString, rhString);
  }, [value]);
  const handleDiffLines = useCallback((cb) => {
    forEach(diff, (line, index) => {
      if (index === diff.length - 2) {
        const nextLine = diff[diff.length - 1];
        const isNewLineToken = (line.count === 1 && nextLine.count === 1);
        if (isNewLineToken) {
          const diffLastLine = diffLines(line.value, nextLine.value, {newlineIsToken: true});
          forEach(diffLastLine, (internalLine) => {
            cb(internalLine, true);
          });
          return false;
        }
      }
      cb(line);
    });
  }, [diff]);
  const valueWithPositionMap = useMemo(() => {
    let lhString = '';
    let rhString = '';
    const positions = [[], []];
    const cursor = [0, 0];
    const getLinePositions = (line, count) => Array.from(Array(count), (_, i) => `${i + line + 1}`);
    handleDiffLines(({removed, count, added, value}) => {
      if (removed) {
        lhString += value;
        rhString += repeat('\n', count);
        positions[0].push(...getLinePositions(cursor[0], count));
        positions[1].push(...Array.from(Array(count), () => ''));
        cursor[0] += count;
      } else if (added) {
        lhString += repeat('\n', count);
        rhString += value;
        positions[0].push(...Array.from(Array(count), () => ''));
        positions[1].push(...getLinePositions(cursor[1], count));
        cursor[1] += count;
      } else {
        lhString += value;
        rhString += value;
        positions[0].push(...getLinePositions(cursor[0], count));
        positions[1].push(...getLinePositions(cursor[1], count));
        cursor[0] += count;
        cursor[1] += count;
      }
    });
    return {
      value: [lhString, rhString],
      positions,
    };
  }, [handleDiffLines]);
  const generateDiffedLines = useCallback(() => {
    const diffedLines = {
      left: [],
      right: []
    };
    let cursor = 1;
    handleDiffLines(({count, added, removed}, isNewLineToken) => {
      const marker = {startRow: cursor, endRow: cursor + count - 1};
      const type = isNewLineToken ? 'fullLine' : 'background';
      if (removed) {
        diffedLines.left.push({...marker, className: 'ace_diff-removed', type});
        diffedLines.right.push({...marker, className: 'ace_diff-empty', type});
      } else if (added) {
        diffedLines.right.push({...marker, className: 'ace_diff-added', type});
        diffedLines.left.push({...marker, className: 'ace_diff-empty', type});
      }
      cursor += count;
    });
    return diffedLines;
  }, [handleDiffLines]);
  const diffPositions = useMemo(() => {
    return map(generateDiffedLines().left, 'startRow');
  }, [generateDiffedLines]);
  const scrollToDiff = (next = false) => {
    const split = editorRef?.current?.split;
    if (!split) return;
    const editor = split.getEditor(0);
    const startNode = editor.renderer?.layerConfig?.firstRowScreen + 1;
    const lineHeight = editor.renderer?.lineHeight;
    let positionIndex = next ?
      bounds.gt(diffPositions, startNode) :
      bounds.lt(diffPositions, startNode);
    if (positionIndex < 0) positionIndex = 0;
    if (positionIndex > diffPositions.length - 1) positionIndex = diffPositions.length - 1;
    editor.session.setScrollTop(lineHeight * (diffPositions[positionIndex] - 1));
    editor.session.setScrollLeft(0);
  };
  const scrollToTop = () => {
    const split = editorRef?.current?.split;
    if (!split) return;
    const editor = split.getEditor(0);
    editor.session.setScrollTop(0);
    editor.session.setScrollLeft(0);
  };
  const onLoad = (split) => {
    const editorLeft = split.getEditor(0);
    const editorRight = split.getEditor(1);
    if (!editorLeft || !editorRight) return;
    const setGutterRenderer = (session, index) => {
      session.gutterRenderer = {
        getWidth(session, lastLineText, config) {
          const positions = valueWithPositionMap.positions[index];
          const text = positions ? positions.length.toString() : '';
          return text.length * config.characterWidth;
        },
        getText: (session, row) => {
          const text = get(valueWithPositionMap, ['positions', index, row]);
          return text ?? '';
        }
      };
    };
    editorLeft.session.on('changeScrollLeft', () => {
      editorRight.session.setScrollLeft(editorLeft.session.getScrollLeft());
    });
    editorRight.session.on('changeScrollLeft', () => {
      editorLeft.session.setScrollLeft(editorRight.session.getScrollLeft());
    });
    editorLeft.session.on('changeScrollTop', () => {
      editorRight.session.setScrollTop(editorLeft.session.getScrollTop());
    });
    editorRight.session.on('changeScrollTop', () => {
      editorLeft.session.setScrollTop(editorRight.session.getScrollTop());
    });
    setGutterRenderer(editorLeft.session, 0);
    setGutterRenderer(editorRight.session, 1);
  };
  const setCodeMarkers = useCallback((diffedLines) => {
    const codeEditorSettings = [[], []];
    const keysMap = {
      0: 'left',
      1: 'right'
    };

    editorRef?.current?.split.forEach((editor, index) => {
      forEach(diffedLines[keysMap[index]], ({className, startRow, endRow, type = 'background'}) => {
        codeEditorSettings[index].push({startRow: startRow - 1, endRow, className, type});
      });
    });
    return codeEditorSettings;
  }, []);

  useEffect(() => {
    const diffedLines = generateDiffedLines();
    const markers = setCodeMarkers(diffedLines);
    setMarkers(markers);
  }, [setCodeMarkers, generateDiffedLines]);

  return [
    <div key='navigation' className='code-diff navigation'>
      <Button.Group>
        <Popup
          trigger={<Button icon='angle up' onClick={() => scrollToDiff()} />}
          content='Previous diff'
        />
        <Popup
          trigger={<Button icon='angle down' onClick={() => scrollToDiff(true)} />}
          content='Next diff'
        />
        <Popup
          trigger={<Button icon='angle double up' onClick={scrollToTop} />}
          content='Scroll to top'
        />
      </Button.Group>
    </div>,
    <div key='editor' className='code-editor-control'>
      <SplitEditor
        ref={editorRef}
        name={props.name}
        className={props.className}
        width={props.width}
        minLines={props.minLines}
        maxLines={props.maxLines}
        orientation={props.orientation}
        splits={props.splits}
        mode={props.mode}
        theme={props.theme}
        readOnly={props.readOnly}
        tabSize={props.tabSize}
        setOptions={props.setOptions}
        wrapEnabled={props.wrapEnabled}
        onLoad={onLoad}
        value={valueWithPositionMap.value}
        markers={markers}
      />
    </div>
  ];
};

CodeDiff.propTypes = {
  height: PropTypes.number,
  width: PropTypes.string,
  minLines: PropTypes.number,
  maxLines: PropTypes.number,
  mode: PropTypes.string,
  name: PropTypes.string,
  orientation: PropTypes.string,
  readOnly: PropTypes.bool,
  setOptions: PropTypes.object,
  splits: PropTypes.number,
  tabSize: PropTypes.number,
  theme: PropTypes.string,
  value: PropTypes.array,
  wrapEnabled: PropTypes.bool,
  onlyDiff: PropTypes.bool,
};

CodeDiff.defaultProps = {
  className: 'ace_diff',
  width: '100%',
  minLines: 10,
  mode: 'text',
  name: 'ace-diff',
  orientation: 'beside',
  readOnly: true,
  setOptions: {
    useWorker: false,
    autoScrollEditorIntoView: true,
  },
  splits: 2,
  tabSize: 2,
  theme: 'apstra-light',
  value: ['', ''],
  wrapEnabled: false,
};

export default CodeDiff;
