import {observable, computed, makeObservable} from 'mobx';
import {createModelSchema, list, object, identifier, primitive, alias, custom} from 'serializr';

import {PORT_SPEED_UNIT_LABELS, PORT_SPEEDS, PORT_SPEED_COLORS} from '../portConsts';

export class InterfaceMap {
  id = undefined;
  @observable label = '';
  @observable interfaces = [];
  @observable deviceProfileId = null;
  @observable logicalDeviceId = null;

  constructor() {
    makeObservable(this);
  }

  @computed get unusedInterfaces() {
    return this.interfaces.filter((ifc) => !ifc.isMapped);
  }
}

export class Interface {
  name = null;
  state = null;
  roles = [];
  position = null;
  speed = null;
  setting = null;
  mapping = [];

  constructor(data) {
    Object.assign(this, data);
  }
}

class InterfaceSpeed {
  value = null;
  unit = null;

  get label() {
    return `${this.value} ${PORT_SPEED_UNIT_LABELS[this.unit]}`;
  }

  get color() {
    return PORT_SPEED_COLORS[
      PORT_SPEEDS.findIndex((speed) => speed.value === this.value && speed.unit === this.unit)
    ];
  }

  constructor(data) {
    Object.assign(this, data);
  }
}

createModelSchema(InterfaceMap, {
  id: identifier(),
  label: primitive(),
  interfaces: list(object(Interface)),
  deviceProfileId: alias('device_profile_id', primitive()),
  logicalDeviceId: alias('logical_device_id', primitive())
});

createModelSchema(Interface, {
  name: primitive(),
  state: primitive(),
  roles: list(primitive()),
  position: primitive(),
  speed: object(InterfaceSpeed),
  setting: custom(
    (param) => ({param}),
    (setting) => (setting || {}).param
  ),
  mapping: list(primitive())
});

createModelSchema(InterfaceSpeed, {
  value: primitive(),
  unit: primitive()
});
