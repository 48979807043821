import {Component} from 'react';
import {filter, find, forEach, isFunction, map, merge, transform} from 'lodash';
import {action, comparer, observable, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {Loader} from 'apstra-ui-common';

import {filtersToQueryParam} from '../../../queryParamUtils';
import {getNeighborsDataFromCablingMap} from './data';
import {TRAFFIC_PROBE_STAGE, getTrafficDataFromSource,
  pickOrderedLinkProperties} from '../../../iba/components/graphs/trafficUtils';
import UserStoreContext from '../../../UserStoreContext';

import {NeighborsBase} from './NeighborsBase';

import './NeighborsTraffic.less';

@observer
export class NeighborsTraffic extends Component {
  static contextType = UserStoreContext;
  static propTypes = {
    ...NeighborsBase.propTypes,
    blueprintId: PropTypes.string,
  };

  static defaultProps = {
    userStoreKey: 'neighbors',
  };

  @observable settings = {
    showUnusedPorts: false,
    showAggregateLinks: true
  };

  @action
  onShowUnusedPortsChange = (value) => {
    const {context: {userStore}, props: {userStoreKey}, settings} = this;
    settings.showUnusedPorts = value;
    userStore.setFor(userStoreKey, settings);
  };

  @action
  onShowAggregateLinksChange = (value) => {
    const {context: {userStore}, props: {userStoreKey}, settings} = this;
    settings.showAggregateLinks = value;
    userStore.setFor(userStoreKey, settings);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static processGraphData(data, stage, dataSource, interfaceValuesSchema) {
    const {processLink, processNode} = NeighborsTraffic;
    if (!stage?.items) return;
    forEach(data.links, (link) => processLink(link, stage, dataSource));
    processNode(data.node, data, stage, dataSource, interfaceValuesSchema);
    forEach(data.neighbors, (neighbor) => processNode(neighbor, data, stage, dataSource, interfaceValuesSchema));
  }

  static processLink(link, stage, dataSource) {
    const {getLinkData, getTrafficClassName} = NeighborsTraffic;
    const linkData = getLinkData(link.nodeInterfaceInfo, stage, dataSource) ||
      getLinkData(link.neighborInterfaceInfo, stage, dataSource);
    if (!linkData) return;
    link.className = getTrafficClassName(linkData);
  }

  static processNode(node, data, stage, dataSource, interfaceValuesSchema) {
    forEach(node.interfaces,
      (interf) => NeighborsTraffic.processInterface(interf, data, stage, dataSource, interfaceValuesSchema));
  }

  static processInterface(interf, data, stage, dataSource, interfaceValuesSchema) {
    const {getLinkData} = NeighborsTraffic;
    const relatedLink = find(data.links,
      (link) => link.nodeInterfaceInfo.interfaceId === interf.id ||
      link.neighborInterfaceInfo.interfaceId === interf.id
    );
    if (!relatedLink) return;
    interf.className = relatedLink.className;

    const linkData = (relatedLink.nodeInterfaceInfo.interfaceId === interf.id) ?
      getLinkData(relatedLink.nodeInterfaceInfo, stage, dataSource) ||
        getLinkData(relatedLink.neighborInterfaceInfo, stage, dataSource)
      : getLinkData(relatedLink.neighborInterfaceInfo, stage, dataSource) ||
        getLinkData(relatedLink.nodeInterfaceInfo, stage, dataSource);
    if (!linkData) return;
    const speed = linkData.properties.speed;
    interf.properties = pickOrderedLinkProperties({...linkData, speed});
    interf.propertiesSchema = merge(interfaceValuesSchema,
      {
        speed: {
          title: 'Speed',
          units: 'bps'
        },
      });
  }

  static getTrafficClassName(linkData) {
    const utilization = Math.max(linkData.rx_utilization_average, linkData.tx_utilization_average);
    if (utilization <= 20) {
      return 'traffic-0-20';
    } else if (utilization <= 40) {
      return 'traffic-20-40';
    } else if (utilization <= 60) {
      return 'traffic-40-60';
    } else if (utilization <= 80) {
      return 'traffic-60-80';
    } else if (utilization <= 100) {
      return 'traffic-80-100';
    } else if (utilization > 100) {
      return 'traffic-more-than-100';
    }
  }

  static getLinkData(interfaceInfo, {items}, dataSource) {
    if (!interfaceInfo) return null;
    const {nodeSystemId, label} = interfaceInfo;
    const interfaceTraffic = find(items,
      ({properties}) => properties.interface === label && properties.system_id === nodeSystemId);
    return interfaceTraffic ? getTrafficDataFromSource(interfaceTraffic, dataSource) : null;
  }

  @observable.ref neighborsData = null;

  @action
  calculateNeighborsData = () => {
    const {settings, props} = this;
    const {
      isFreeform, cablingMap, aggregateLinks, nodeId, labelKey, neighborsRoleFilter, lldpTelemetry,
      interfaceTelemetry, nodes, setFetchProbeParams, interfacesWithCts
    } = props;
    const data = getNeighborsDataFromCablingMap({
      isFreeform,
      cablingMap,
      aggregateLinks,
      nodeId,
      neighborsRoleFilter,
      labelKey,
      nodes,
      interfaceTelemetry,
      lldpTelemetry,
      interfacesWithCts,
      ...settings
    });
    const filters = transform(data.neighbors, (filters, {system_id: systemId, interfaces, id: neighborId}) => {
      if (systemId) { // not generic neighbor
        filters.push(...map(interfaces, ({label}) => [systemId, label]));
      } else { // generic neighbor
        const links = filter(data.links, {neighbor_id: neighborId});
        const nodeInterfaceIdSet = new Set(map(links, 'node_interface_id'));
        const labels = filter(map(filter(data.node.interfaces, ({id}) => nodeInterfaceIdSet.has(id)), 'label'));
        if (data.node.system_id) {
          filters.push(...map(labels, (label) => [data.node.system_id, label]));
        }
      }
    }, []);
    if (isFunction(setFetchProbeParams)) {
      setFetchProbeParams({[TRAFFIC_PROBE_STAGE.averageInterfaceCounters]: {
        filter: filtersToQueryParam({'[system_id, interface]': filters}),
        'items-count': filters.length,
      }});
    }
    this.neighborsData = data;
  };

  componentDidMount() {
    this.settings = this.context.userStore.getStoreValue([this.props.userStoreKey]) ?? this.settings;
    this.calculateNeighborsDataDisposer = reaction(
      () => [this.props.cablingMap, this.props.nodeId, this.props.neighborsRoleFilter, this.props.labelKey,
        this.props.nodes, this.props.interfaceTelemetry, this.props.lldpTelemetry, this.settings,
        this.props.showAggregateLinks, this.props.dataSource],
      this.calculateNeighborsData,
      {equals: comparer.structural, fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.calculateNeighborsDataDisposer();
  }

  processGraphData = (data) => NeighborsTraffic.processGraphData(
    data, this.props.stage, this.props.dataSource, this.props.interfaceValuesSchema);

  render() {
    const {props: {stage}, neighborsData, processGraphData, onShowUnusedPortsChange, onShowAggregateLinksChange,
      settings} = this;
    if (!neighborsData) return <Loader />;
    return (<NeighborsBase
      className='node-neighbors-traffic-graph'
      data={neighborsData}
      processGraphData={processGraphData}
      {...this.props}
      stage={stage}
      {...settings}
      onShowUnusedPortsChange={onShowUnusedPortsChange}
      onShowAggregateLinksChange={onShowAggregateLinksChange}
    />
    );
  }
}
