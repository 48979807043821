import cx from 'classnames';
import {useMemo, useState} from 'react';
import {Group} from '@visx/group';
import {Axis} from '@visx/axis';
import {scaleLinear, bin, range, sum} from 'd3';
import {clamp, constant, max, map, isUndefined, min, isInteger, isEmpty} from 'lodash';
import {Grid} from '@visx/grid';
import {useResizeDetector} from 'react-resize-detector';

import ChartPopup from './ChartPopup';

import './HistogramChart.less';

const HistogramChart = ({
  data, value, y, xLabel, yLabel, processPopupContent, processPopupHeader,
  mode, width: widthProp, className, dimensions, noDataMessage,
}) => {
  const [popupDescription, setPopupDescription] = useState(null);
  const hidePopup = () => setPopupDescription(null);

  const {width: parentWidth = widthProp || 500, ref: containerRef} = useResizeDetector({handleHeight: false});
  const chartWidth = isUndefined(widthProp) ? parentWidth : widthProp;

  const {height: chartHeight, margin, numTicksRows: chartNumTicksRows} = dimensions[mode];
  const marginLeft = margin.left + 15;

  const showTicks = chartWidth > 0;

  const xScale = scaleLinear();
  const yScale = scaleLinear();
  const xMax = chartWidth - marginLeft - margin.right || 0;
  const yMax = chartHeight - margin.top - margin.bottom;

  const [bins, yAxis, chartNumTicksColumns] = useMemo(() => {
    const xAxis = map(data, value);
    const y0Axis = map(data, y);
    const xRange = range(xAxis.length);

    const setValues = new Set(xAxis);
    const xMaxValue = max(xAxis) ?? 0;
    const xMinValue = min(xAxis) ?? 0;
    const chartNumTicksColumns = clamp(
      max([xMaxValue - xMinValue, setValues.size]),
      1,
      10
    );

    const bins = bin()
      .thresholds(xMaxValue - xMinValue)
      .value((i) => xAxis[i])(xRange)
      .filter(({x0, x1}) => !isUndefined(x0) && !isUndefined(x1));

    return [
      bins,
      Array.from(bins, (i) => sum(i, (j) => y0Axis[j])),
      chartNumTicksColumns,
    ];
  }, [data, value, y]);

  const yMaxValue = max(yAxis) ?? 0;
  const numTicksColumns = showTicks && mode === 'expanded' ? chartNumTicksColumns : 0;
  const numTicksRows = showTicks ? clamp(Math.abs(yMaxValue), 1, chartNumTicksRows) : 0;

  xScale.domain([bins[0]?.x0 - 0.5, bins[bins.length - 1]?.x0 + 0.5]).rangeRound([0, xMax]);
  yScale.domain([0, yMaxValue]).rangeRound([yMax, 0]);

  const barWidth = Math.ceil(
    (xScale(bins[bins.length - 1]?.x0) - xScale(bins[0]?.x0)) / (bins[bins.length - 1]?.x0 - bins[0]?.x0)
  ) || xScale(bins[0]?.x0);
  const xOffset = barWidth / 2.0;

  const filteredTicks = useMemo(() => {
    return xScale.ticks().filter((n) => isInteger(n) && n >= 0);
  }, [xScale]);

  if (isEmpty(data)) {
    return (
      <div className='histogram-no-data'>{noDataMessage}</div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={cx('histogram-chart', 'graph-container', {expandable: mode !== 'expanded'})}
    >
      <svg className={cx('histogram-chart-layout', className)} width={chartWidth} height={chartHeight}>
        <Group top={margin.top} left={marginLeft}>
          <Group onMouseLeave={hidePopup}>
            {map(bins, ({x0, x1, ...indexList}, index) => (
              <rect
                key={index}
                className='bar'
                x={xScale(x0) - xOffset}
                width={barWidth}
                y={yScale(yAxis[index])}
                height={yScale(0) - yScale(yAxis[index])}
                onMouseEnter={(e) => setPopupDescription({
                  node: e.target,
                  header: processPopupHeader(x0, yAxis[index]),
                  content: processPopupContent(indexList, x0, x1),
                })}
              />
            ))}
          </Group>
          <Axis
            axisClassName='timeline-axis axis-bottom'
            orientation='bottom'
            top={yScale(0)}
            scale={xScale}
            numTicks={numTicksColumns}
            tickFormat={(value) => Math.floor(value)}
            tickValues={filteredTicks}
            tickLabelProps={constant({})}
            label={xLabel}
          />
          <Axis
            axisClassName='timeline-axis axis-left'
            orientation='left'
            scale={yScale}
            numTicks={numTicksRows}
            label={yLabel}
            tickFormat={(value) => Math.floor(value)}
            labelProps={{textAnchor: 'middle', y: -margin.left}}
            tickLabelProps={constant({dx: '-0.25em', dy: '0.25em'})}
          />
        </Group>
        <ChartPopup popupDescription={popupDescription} />
        {showTicks &&
          <Grid
            className='timeline-grid'
            top={margin.top}
            left={marginLeft}
            width={xMax}
            height={yMax}
            xScale={xScale}
            yScale={yScale}
            numTicksRows={numTicksRows}
            numTicksColumns={numTicksColumns}
            stroke={null}
          />
        }
      </svg>
    </div>
  );
};

HistogramChart.defaultProps = {
  mode: 'compact',
  dimensions: {
    compact: {
      height: 60,
      margin: {top: 4, right: 10, bottom: 10, left: 40},
      numTicksRows: 4
    },
    expanded: {
      height: 300,
      margin: {top: 10, right: 10, bottom: 50, left: 50},
      numTicksRows: 10
    },
  },
  y: () => 1,
  yLabel: 'Frequency',
  processPopupHeader: (x, y) => `${x}, ${y}`,
  processPopupContent: (indexList) => indexList,
  noDataMessage: 'No Data',
};

export default HistogramChart;
