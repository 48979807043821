import {computed, makeObservable, observable, action} from 'mobx';
import {first, flatten, isEqual, map, reverse} from 'lodash';

import Step from './Step';

const historyLength = 100;

class ChangesStore {
  @observable changes;
  @observable pointer;

  @computed
  get canUndo() {
    return this.pointer >= 0;
  }

  @computed
  get canRedo() {
    return this.pointer < this.changes.length - 1;
  }

  @computed
  get current() {
    return this.changes?.length ? this.changes[this.pointer] : false;
  }

  @action
  splice() {
    this.changes.splice(this.pointer + 1);
  }

  @action
  register(change, injectBefore, checkDuplicates) {
    // If user undoed some steps before registering a new one
    // existing redoes must be removed
    if (this.pointer !== this.changes.length - 1) {
      this.splice();
    }

    if (checkDuplicates && isEqual(change, this.current)) {
      // If change is already registered, avoid duplication
      return;
    }

    const newChange = flatten([change]);
    if (injectBefore && this.canUndo) {
      // The change can be inserted before the pointer
      this.changes.splice(this.pointer, 0, newChange);
    } else {
      // or on top of the stack
      this.changes.push(newChange);
    }
    // If history becomes longer allowed limit
    if (this.changes.length > historyLength) {
      // ... shift it (pointer mustn't change)
      this.changes.shift();
    } else {
      // increase the pointer otherwise
      this.pointer++;
    }
  }

  @action
  add(item) {
    this.register(Step.creation(item));
  }

  @action
  delete(item) {
    this.register(Step.deletion(item));
  }

  @action
  change(fromItem, toItem) {
    this.register(Step.modification(toItem, fromItem));
  }

  @action
  undo() {
    if (!this.canUndo) return false;
    this.pointer--;
    return reverse(map(this.changes[this.pointer + 1], 'inverted'));
  }

  @action
  redo() {
    if (!this.canRedo) return false;
    this.pointer++;
    return this.changes[this.pointer];
  }

  @action
  reset() {
    this.changes = [];
    this.pointer = -1;
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.reset();
  }

  currentCorrespondsTo(id) {
    const firstStep = first(this.current);
    return this.current?.length === 1 && firstStep?.id === id && !firstStep.isDelete;
  }
}

export default ChangesStore;
