import {ActionsMenu, hasBlueprintPermissions} from 'apstra-ui-common';
import {observer} from 'mobx-react';
import {FC, useContext} from 'react';
import {ModalProps} from 'semantic-ui-react';

import IBAContext from '../IBAContext';
import generateProbeURI from '../generateProbeURI';

import {Probe} from '../types';
import './ProbeActions.less';

type ProbeActionsProps = {
  probe: Probe;
  processorName: string | null;
  size?: any;
  stageName: string | null;
  setProbeDeletionModalProps: (modalProps: ModalProps) => void;
  setProbeExportModalProps: (modalProps: ModalProps) => void;
  setPredefinedProbeModalProps: (modalProps: ModalProps) => void;
}

const ProbeActions: FC<ProbeActionsProps> = observer(({
  probe, processorName, stageName, size,
  setProbeDeletionModalProps, setProbeExportModalProps, setPredefinedProbeModalProps,
}) => {
  const {blueprintId, blueprintPermissions} = useContext(IBAContext);
  const {predefined_probe: predefinedProbe} = probe;
  const hasPermissions = hasBlueprintPermissions({blueprintPermissions, action: 'edit'});
  return (
    <div className='probe-actions'>
      <ActionsMenu
        size={size}
        items={[{
          icon: 'share square',
          title: 'Export',
          onClick: () => setProbeExportModalProps({open: true, probe}),
        }]}
      />
      <ActionsMenu
        size={size}
        items={[
          {
            icon: 'edit',
            title: 'Edit',
            hasPermissions,
            notPermittedMessage: 'You do not have permission to edit',
            ...(predefinedProbe ? {
              onClick: () =>
                setPredefinedProbeModalProps({open: true, mode: 'update', probe, processorName, stageName})
            } : {
              href: generateProbeURI({blueprintId, probeId: probe.id, action: 'update', processorName, stageName})
            })
          },
          {
            icon: 'clone',
            title: 'Clone',
            hasPermissions,
            notPermittedMessage: 'You do not have permission to clone',
            ...(predefinedProbe ? {
              onClick: () =>
                setPredefinedProbeModalProps({open: true, mode: 'clone', probe, processorName, stageName})
            } : {
              href: generateProbeURI({blueprintId, probeId: probe.id, action: 'clone', processorName, stageName})
            })
          },
          {
            icon: 'trash',
            title: 'Delete',
            hasPermissions,
            notPermittedMessage: 'You do not have permission to delete',
            onClick: () => setProbeDeletionModalProps({open: true, probes: [probe]}),
          },
        ]}
      />
    </div>
  );
});

export default ProbeActions;
