import {Icon, Popup} from 'semantic-ui-react';

import {getProbeUrl} from './reportUtils';

const ProbeExternalLink = ({blueprintId, probeId}) => (
  <Popup
    offset={[-12, 0]}
    trigger={
      <a href={getProbeUrl(blueprintId, probeId)} target='_blank' className='link'>
        <Icon name='external' />
      </a>
    }
    content='Open probe in new tab'
  />
);

export default ProbeExternalLink;
