import {observable, makeObservable} from 'mobx';

import RackStore from './RackStore';
import SelectionStore from '../../cablingMapEditor/store/SelectionStore';

class RackEditorStore {
  @observable rackStore;
  @observable selection;

  constructor() {
    makeObservable(this);
    this.rackStore = new RackStore(this);
    this.selection = new SelectionStore(this);
  }

  dispose() {
  }
}

export default RackEditorStore;
