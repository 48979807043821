import {CodeEditorInput, Field, MapInput} from 'apstra-ui-common';
import {filter, keys, map, omit, some, transform} from 'lodash';
import {observer} from 'mobx-react';
import {useEffect, useMemo, useState} from 'react';
import {Input} from 'semantic-ui-react';

import {ADDITIONAL_PROPERTIES_FIELD_NAME} from '../processorPropertiesRenderers';

export const AdditionalPropertiesEditor = observer(({
  value, onChange, processorDefinition,
  serviceRegistryProperties, knownPythonExpressionVariables,
  onError,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    const forbiddenKeys = [
      ...keys(processorDefinition.schema.properties),
      ...keys(serviceRegistryProperties),
      ADDITIONAL_PROPERTIES_FIELD_NAME
    ];
    const invalidKeys = filter(forbiddenKeys, (key) => key in internalValue);
    if (some(invalidKeys)) {
      onChange?.(omit(internalValue, invalidKeys));
      onError?.(props.name, map(invalidKeys, (key) => ({
        message: `${key} key can not be used`,
        key,
      })));
      return () => onError?.(props.name, null);
    } else {
      onChange?.(internalValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processorDefinition.schema.properties, internalValue]);

  const keyErrors = useMemo(() => transform(props.errors, (acc, item) => {
    const error = item[props.name];
    if (!error) return;
    (acc[error.key] ??= []).push(error.message);
  }, {}), [props.errors, props.name]);

  return (
    <MapInput
      onChange={setInternalValue}
      value={internalValue}
      {...props}
    >
      {({key, value, index, errors, setEntryKey, setEntryValue}) =>
        <>
          <Field
            key='key'
            errors={keyErrors[key]}
            width={4}
          >
            <Input
              placeholder='Key'
              disabled={props.disabled}
              value={key}
              onChange={(e) => setEntryKey(index, e.target.value)}
            />
          </Field>
          <CodeEditorInput
            key='value'
            mode='python-expression'
            enableCompletion
            completerParams={{knownVariables: knownPythonExpressionVariables}}
            value={value}
            fieldProps={{width: 12}}
            placeholder='Expression'
            disabled={props.disabled}
            errors={errors}
            onChange={(value) => setEntryValue(index, value)}
          />
        </>
      }
    </MapInput>
  );
});
