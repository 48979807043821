import {useState, useEffect, forwardRef, Fragment} from 'react';
import {observer} from 'mobx-react';
import {action} from 'mobx';
import {Icon, Accordion, Input, Button, Message, Popup} from 'semantic-ui-react';
import cx from 'classnames';
import {compact, castArray, chain, forEach, toString, map, size, flatten, toLower, isEmpty} from 'lodash';
import {ValueInput} from 'apstra-ui-common';

import ListInput from './ListInput';
import renderRadioButtonEnum from './renderRadioButtonEnum';
import renderDict from './renderDict';
import {eptTitleMaxLength} from '../settings';
import './EptParameters.less';
import renderTaggedNodes from './renderTaggedNodes';

const stopPropagation = (event) => event.stopPropagation();

// When CT' parameter gets changed its triggers must be checked
// and corresponding actions executed
const triggerArgumentChange = (ept, parameter, registerChange = true) => {
  const {value, on = {}} = parameter;

  // Try to find triggers for the current value if any or default triggers otherwise
  const valueTriggers = on[toString(value)] || on['*'] || {};
  // For each of parameter's value trigger actions
  forEach(valueTriggers, (affectedFields, action) => {
    let executeAction;

    switch (action) {
    case 'set':
      // Set parameter' value
      executeAction = (newValue, field) => {
        ept.setParameter(field, newValue, registerChange);
        triggerArgumentChange(ept, ept.parameters?.[field] ?? {}, registerChange);
      };
      break;
    case 'mandatory':
    case 'optional':
      // Set mandatory/optional
      executeAction = (field) => ept.setParameterRequired(field, action === 'mandatory');
      break;
    default:
      // Set visibility/disability state
      executeAction = (field) => ept.setParameterState(field, action);
    }
    // ... execute action for each affected parameter:
    forEach(affectedFields, executeAction);
  });
};

const EptParameters = forwardRef(({ept, activeEpt, readonly}, ref) => {
  useEffect(() => {
    // Initial set of arguments' states (disabled/hidden)
    forEach(ept.parameters, (parameter) => {
      // If parameter has triggers defined
      if (parameter?.on) {
        // execute their actions for the initial value
        triggerArgumentChange(ept, parameter, false);
      }
    });
  }, [ept]);

  // State of advanced options expansion
  const [isAdvancedExpanded, setAdvancedExpanded] = useState(false);

  // State of the title editing controls
  const [isEditing, setEditing] = useState(false);

  const {id, title, parametersErrors, missingAttributes = {}, hasErrors} = ept;

  const isSelected = activeEpt.activePrimitiveId === id;

  // Primitive title editing actions
  const handleTitleActions = (event) => {
    stopPropagation(event);
    if (isEditing) {
      if (event.key === 'Enter') {
        setEditing(false);
      }
    } else if (event.key === 'e') {
      setEditing(true);
    }
  };

  // Primitive title editing initiation/completion
  const handleEditing = (event, state) => {
    if (readonly) return;
    stopPropagation(event);
    setEditing(state);
  };

  // Fires when some primitives parameters form field gets focus.
  // Makes corresponding primitive block highlighted (stroked)
  // on the canvas.
  const handleActivePrimitive = () => {
    activeEpt.setActivePrimitive(id);
  };

  // Common handler for parameters value change
  const handleChange = (parameter, value) => {
    if (readonly) return;
    ept.setParameter(parameter.name, value);
    triggerArgumentChange(ept, parameter);
  };

  // Toggles parameters section collapsing/expansion
  const toggleExpansion = action(() => {
    activeEpt.setActivePrimitive(isSelected ? null : id);
  });

  const titleClassNames = cx({
    editing: isEditing,
    'has-errors': hasErrors
  });

  const contentClassNames = cx({
    'has-errors': hasErrors
  });

  const selectionClass = cx('ct-parameters', {
    selected: isSelected
  });

  let isAdvancedShown = false;
  const searchString = toLower(activeEpt.searchString);
  const isSearching = !isEmpty(searchString);

  const advancedExpander = <Button
    key='advanced'
    className='ct-expand-advanced'
    fluid
    as='a'
    icon={isAdvancedExpanded ? 'caret down' : 'caret right'}
    content='Advanced Options'
    onClick={() => setAdvancedExpanded(!isAdvancedExpanded)}
  />;

  return (
    <div className={selectionClass}>
      <Popup
        content={'Press "E" to edit title'}
        on='focus'
        disabled={readonly || isEditing}
        hideOnScroll
        trigger={
          <Accordion.Title
            className={titleClassNames}
            active={isSelected}
            onClick={toggleExpansion}
            {...(readonly ? {} : {onKeyDown: handleTitleActions})}
            as={isEditing ? null : 'button'}
          >
            <div ref={ref}>
              <Icon name='dropdown' />
              {isEditing ?
                <Input
                  autoFocus
                  maxLength={eptTitleMaxLength}
                  placeholder='Title'
                  value={title}
                  onChange={(event) => ept.setTitle(event.target.value)}
                  onClick={stopPropagation}
                  action
                >
                  <input />
                  <Button
                    icon
                    primary
                    onClick={(event) => handleEditing(event, false)}
                    aria-label='Save'
                  >
                    <Icon name='save' />
                  </Button>
                </Input> :
                <Fragment>
                  <span>{title}</span>
                  {hasErrors && !readonly &&
                    <Icon name='exclamation triangle' />
                  }
                  {!readonly &&
                    <Icon
                      name='pencil'
                      onClick={(event) => handleEditing(event, true)}
                      aria-label='Edit'
                    />
                  }
                </Fragment>
              }
            </div>
            {title !== ept.schemaLabel &&
              <h6>{`Type: ${ept.schemaLabel}`}</h6>
            }
          </Accordion.Title>
        }
      />
      <Accordion.Content
        className={contentClassNames}
        active={isSelected}
        onFocus={handleActivePrimitive}
      >
        {
          // For CT parameters
          chain(ept.parameters)
            // make them sorted by 'advanced' (latter go to the bottom)
            .sortBy('advanced')
            // and iterate them through
            .map((parameter) => {
              const {name, value, required, disabled, placeholder, schema, advanced, state} = parameter;
              if (state === 'hide') return null;

              const searchableValue = ept.parametersSearchData?.[name];

              const isList = schema.type === 'list';
              const childrenProps = {
                name,
                value,
                schema,
                required,
                disabled: readonly || disabled || state === 'disable',
                placeholder,
                onChange: (value) => handleChange(parameter, value),
                errors: isList ?
                  parametersErrors[name] :
                  compact(castArray(parametersErrors[name])),
                clearable: true,
                fieldProps: {
                  descriptionPosition: 'tooltip',
                  className: (isSearching && searchableValue.indexOf(searchString) < 0) ? 'not-found' : ''
                },
                ...(schema.type === 'boolean' &&
                  {
                    value: !!value,
                    appearance: 'toggle',
                    showLabelInline: true
                  }
                )
              };

              // Expand advanced options button is hidden by default
              let advancedExpanderButton = null;
              // When advanced parameter met
              if (advanced) {
                // and expand button has not yet been shown,
                if (!isAdvancedShown) {
                  isAdvancedShown = true;
                  // show it
                  advancedExpanderButton = advancedExpander;
                }
                // If advanced options section has not been expanded
                // the rest of parameters must be skipped from rendering
                if (!isAdvancedExpanded) return advancedExpanderButton;
              }

              const errors = map(
                compact(flatten([missingAttributes[name]])),
                (message, index) => (<li key={index}>{message}</li>)
              );

              const errorsCount = size(errors);

              return [
                advancedExpanderButton,
                isList ?
                  <ListInput key={name} {...childrenProps} /> :
                  <ValueInput
                    key={name}
                    {...childrenProps}
                    renderers={[
                      renderTaggedNodes, renderRadioButtonEnum, renderDict
                    ]}
                  />,
                !readonly && !!errorsCount &&
                  <Message key='message' warning>
                    <ul className={cx({'as-list': errorsCount > 1})}>{errors}</ul>
                  </Message>
              ];
            })
            .value()
        }
      </Accordion.Content>
    </div>
  );
});

export default observer(EptParameters);
