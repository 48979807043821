import {Button, Message, Icon, Menu as Nav} from 'semantic-ui-react';
import {map} from 'lodash';
import {useMemo} from 'react';
import {Checkbox, DropdownControl} from 'apstra-ui-common';
import {observer} from 'mobx-react';

import {algorithms} from '../store/NodeArrangerStore';
import {useCablingMapStore} from '../store/useCablingMapStore';
import Search from './Search';
import {nodeTextSources} from '../const';

import './Menu.less';

const nodeTextSourceOptions = [
  {
    key: nodeTextSources.LABEL,
    value: nodeTextSources.LABEL,
    text: 'Name',
  },
  {
    key: nodeTextSources.HOSTNAME,
    value: nodeTextSources.HOSTNAME,
    text: 'Hostname',
  },
  {
    key: nodeTextSources.SYSTEM_ID,
    value: nodeTextSources.SYSTEM_ID,
    text: 'S/N',
  },
  {
    key: nodeTextSources.IP,
    value: nodeTextSources.IP,
    text: 'Management IP',
  },
];

const Menu = ({snapped, setSnapping, arrangeType, setArrangeType, enterFullScreenFn, readonly, onNodesFound,
  onActivateNode}) => {
  const {cablingMap, nodeTextSource, setNodeTextSource} = useCablingMapStore();
  const {nodes} = cablingMap;

  const dropdownOptions = useMemo(() => {
    return [
      {
        key: 'user-defined',
        value: 'user-defined',
        text: 'User-defined',
      },
      ...map(algorithms, ({name, id}) => ({
        key: id,
        value: id,
        text: name,
      }))
    ];
  }, []);

  return (
    <Nav text className='ctrl-menu'>
      <Nav.Item>
        <Button basic icon='expand' content='Full screen' onClick={enterFullScreenFn} />
      </Nav.Item>
      <Nav.Item as='label' content='System Label' />
      <Nav.Item>
        <DropdownControl
          selection
          aria-label='System label'
          options={nodeTextSourceOptions}
          value={nodeTextSource}
          onChange={(value) => setNodeTextSource(value)}
        />
      </Nav.Item>
      {readonly ?
        <>
          <Nav.Item as='label' content='Arrangement' />
          <Nav.Item>
            <DropdownControl
              placeholder='Arrangement type'
              selection
              options={dropdownOptions}
              value={arrangeType}
              onChange={(value) => setArrangeType(value)}
            />
          </Nav.Item>
        </>
        :
        <>
          <Nav.Item>
            <Checkbox label='Snap to Grid' checked={snapped} onClick={() => setSnapping(!snapped)} />
          </Nav.Item>
          <Nav.Item
            content={
              <Message info>
                <Icon name='info circle' />
                {'Hold '}<strong>{'Shift'}</strong>{' for panning'}
              </Message>
            }
            position='right'
          />
        </>
      }
      <Nav.Item position='right'>
        <Search nodes={nodes} onNodesFound={onNodesFound} onActivateNode={onActivateNode} readonly={readonly} />
      </Nav.Item>
    </Nav>
  );
};

export default observer(Menu);
