import {map, isEmpty} from 'lodash';
import {Table} from 'semantic-ui-react';

import ValueMap from './ValueMap';

import './MultiValueMap.less';

const MultiValueMap = ({value: vultiValueMap}) => {
  if (isEmpty(vultiValueMap)) {
    return 'Empty';
  } else {
    return (
      <Table basic='very' collapsing className='multi-value-map'>
        <Table.Body>
          {map(vultiValueMap, (valueMap, column) => {
            return (
              <Table.Row key={column}>
                <Table.Cell>
                  {column}
                </Table.Cell>
                <Table.Cell>
                  <ValueMap value={valueMap} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
};

export default MultiValueMap;
