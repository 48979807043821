import {AsyncActionsModal, notifier} from 'apstra-ui-common';

import {deletePredefinedQuery} from './utils';

const QueryDeleteModal = ({open, queryProps, onSuccess, onClose}) => {
  const submit = async () => {
    const result = await deletePredefinedQuery(queryProps?.id);
    if (onSuccess) onSuccess({result});
    notifier.notify({message: 'Query has been successfully deleted'});
  };
  return (
    <AsyncActionsModal
      header='Delete this resource?'
      className='query-delete-modal'
      open={open}
      onClose={onClose}
      content={
        <p>
          {'Query '}
          <b>{queryProps?.label}</b>
          {' will be deleted from the system'}
        </p>
      }
      actions={[
        {
          key: 'delete',
          content: 'Delete',
          color: 'red',
          icon: 'trash',
          labelPosition: 'left',
          onClick: submit,
        },
      ]}
      closeIcon
    />
  );
};

export default QueryDeleteModal;
