import PropTypes from 'prop-types';
import {useMemo, useState, Fragment} from 'react';
import {slice, map, get, sortBy, isUndefined, forEach} from 'lodash';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import humanizeString from '../../humanizeString';
import generateProbeURI from '../generateProbeURI';

import './AnomalousMetrics.less';

const StatsItems = ({items}) =>
  map(items, ({link, label}, index) =>
    <div key={index} className='item'>
      <Link to={link}>{label}</Link>
    </div>
  );

const AnomalousMetrics = ({value = [], showMinItems = 3}) => {
  const [showMore, setShowMore] = useState(value?.length > showMinItems);
  const items = useMemo(() => {
    const sortedValue = sortBy(value, ({lane}) => isUndefined(lane) ? -1 : lane);
    return showMore ?
      slice(sortedValue, 0, showMinItems) :
      sortedValue;
  }, [value, showMore, showMinItems]);
  const [interfaceStats, laneStats] = useMemo(() => {
    const interfaceStats = [];
    const laneStats = {};
    forEach(items, ({lane, ...item}) => {
      if (isUndefined(lane)) {
        interfaceStats.push(item);
      } else {
        laneStats[lane] = laneStats[lane] || [];
        laneStats[lane].push(item);
      }
    });
    return [interfaceStats, laneStats];
  }, [items]);

  return (
    <div className='anomalous-metrics'>
      <div className='ui bulleted list'>
        <StatsItems items={interfaceStats} />
      </div>
      {map(laneStats, (items, lane) =>
        <Fragment key={lane}>
          <div>{`Lane ${lane}`}</div>
          <div className='ui bulleted list'>
            <StatsItems items={items} />
          </div>
        </Fragment>
      )}
      {showMore && <Button content='More...' size='tiny' compact onClick={() => setShowMore(false)} />}
    </div>
  );
};

AnomalousMetrics.propTypes = {
  value: PropTypes.array,
};

AnomalousMetrics.columnDefinition = {
  name: 'anomalous_metrics',
  label: 'Anomalous Metrics',
  value: ({item: {preceding_items: precedingItems} = {}, params: {blueprintId, probe}}) => {
    const metrics = [];
    forEach(precedingItems, (stageItems, stageName) => {
      forEach(stageItems, (properties) => {
        forEach(properties, (value, key) => {
          if (value === 'true') {
            const lane = get(properties, ['properties', 'lane']);
            metrics.push({
              lane,
              link: generateProbeURI({
                blueprintId,
                stageName,
                probeId: probe.id,
                queryParams: {
                  filters: {
                    filter: {
                      'properties.lane': lane,
                      'properties.interface': get(properties, ['properties', 'interface']),
                      'properties.system_id': get(properties, ['properties', 'system_id']),
                    }
                  }
                },
              }),
              label: humanizeString(key),
            });
          }
        });
      });
    });
    return metrics;
  },
  formatter: ({value}) => <AnomalousMetrics value={value} />
};

export default AnomalousMetrics;
