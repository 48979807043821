import {observer} from 'mobx-react';
import cx from 'classnames';
import {Text} from '@visx/text';

import {ctrlNodeWidth} from '../../const';
import './Node.less';

const Node = ({x, y, height, node, onRight, onClick, onReposition}) => {
  if (isNaN(x) || isNaN(y) || !node) return null;

  const {isExternal, color, label} = node;
  const radius = isExternal ? 0 : 4;

  const classes = cx(
    'ctrl-node',
    node.id,
    {
      external: isExternal,
      [`color-${color}`]: color,
      'on-right': onRight,
      interactive: !!onClick
    }
  );
  return (
    <g className={classes} onClick={() => onClick?.(node)}>
      <rect
        className='container'
        x={x}
        y={y}
        style={{height}}
        rx={radius}
        ry={radius}
      />
      <Text
        className='title'
        textAnchor={onRight ? 'end' : 'start'}
        x={onRight ? x + ctrlNodeWidth - 10 : x + 10}
        y={y + 25}
        label={label}
      >
        {label}
      </Text>
      <Text
        className='mover'
        textAnchor={onRight ? 'end' : 'start'}
        x={onRight ? x - 2 : x + ctrlNodeWidth + 2}
        y={y + 6}
        onClick={() => onReposition(node.id)}
      >
        {onRight ? '⇠' : '⇢'}
      </Text>
    </g>
  );
};

export default observer(Node);
