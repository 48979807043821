import {isEmpty, map, max, reject, size, transform} from 'lodash';

import {ALL_NODE_ROLES} from '../../../../roles';
import {ILinkEndpoint, INode, IVlan, Layout, LayoutType, LinkData, PairRendering, Point, TopologyData} from '../types';
import {COLLAPSED_EXTERNAL_GENERICS_ID, TOPOLOGY_CONFIG} from '../consts';
import {layout as l} from './layoutTree';

type CollapsedGenericData = Layout & {
  count: number;
  className?: string;
  testDataAttributes?: any;
}

type NodeData = Layout & {
  className?: string;
  pairRendering?: PairRendering;
  sourceNode?: INode;
  testDataAttributes?: any;
  icon?: string;
}

type PairData = Layout & {
  label?: string;
  className?: string;
  leftId?: string;
  rightId?: string;
}

export type PlaneData = Layout & {
  label?: string;
  testDataAttributes?: any;
}

export type VlanNodeLink = {
  nodeConnection: Point;
  busX: number;
};

export type VlanData = Layout & {
  label: string;
  colorName: string;
  index: number;
  nodeLinks: VlanNodeLink[];
}

type InterfaceData = {
  position: Point;
  sourceInterface?: ILinkEndpoint;
}

export type TopologyViewModel = {
  layout: Layout;
  collapsedGenerics: Record<string, CollapsedGenericData>;
  nodes: Record<string, NodeData>;
  nodePairs: Record<string, PairData>;
  planes: Record<string, PlaneData>;
  pods: Record<string, PlaneData>;
  racks: Record<string, PlaneData>;
  simpleGroupBoxes: Record<string, PlaneData>;
  vlans: Record<string, VlanData>;
  accessGroups: Record<string, PlaneData>;
  interfaces: Record<string, InterfaceData>;
  links: Record<string, LinkData>;
}

export class LayoutBuilder {
  private topologyViewModel: TopologyViewModel;
  private topologyData: TopologyData;

  constructor(topologyViewModel: TopologyViewModel, topologyData: TopologyData) {
    this.topologyViewModel = topologyViewModel;
    this.topologyData = topologyData;
  }

  public layoutNodes = (nodes, pairs?) => pairs?.length ?
  [
    ...map(pairs,
      (node) => l(LayoutType.NodePair, null, {id: node.id, layoutData: this.topologyViewModel.nodePairs})
    ),
    ...map(reject(nodes, 'part_of'),
      (node) => l(LayoutType.Node, null, {id: node.id, layoutData: this.topologyViewModel.nodes})
    )
  ] :
  map(nodes,
    (node) => l(LayoutType.Node, null, {id: node.id, layoutData: this.topologyViewModel.nodes})
  );

  public buildRemoteGatewaysLine = () => l(LayoutType.Column,
    this.layoutNodes(this.topologyData.nodeByRoles[ALL_NODE_ROLES.REMOTE_GATEWAY]),
    {columns: 8}
  );

  public buildExternalRoutersLine = (expandExternalGenerics: boolean) => {
    if (isEmpty(this.topologyData.externalRouters)) return null;
    return expandExternalGenerics ?
      l(LayoutType.Column,
        this.layoutNodes(this.topologyData.externalRouters),
        {columns: 8}
      )
      :
      l(LayoutType.Node, null, {
        id: COLLAPSED_EXTERNAL_GENERICS_ID,
        layoutData: this.topologyViewModel.collapsedGenerics,
      });
  };

  public buildSuperspinePlanes = () => l(
    LayoutType.Column,
    map(
      this.topologyData.indexedPlanes,
      ({id}) => l(
        LayoutType.Pad,
        [l(
          LayoutType.Column,
          this.layoutNodes(this.topologyData.superspinesByPlanes[id]),
          {columns: 8}
        )],
        {id, layoutData: this.topologyViewModel.planes},
      ),
    ),
    {columns: 8}
  );

  public buildRack = (rackId, expandNodes, shouldRenderRackDevices) => {
    const splittedRendering = expandNodes || shouldRenderRackDevices;
    const leafsLine = l(splittedRendering ? LayoutType.Column : LayoutType.Stack,
      this.layoutNodes(
        this.topologyData.leafsByRacks[rackId],
        this.topologyData.leafPairsByRacks[rackId]
      ),
      splittedRendering ? {} : {gap: 3}
    );
    const accessSwitchesLine = l(splittedRendering ? LayoutType.Column : LayoutType.Stack,
      this.layoutNodes(this.topologyData.accessSwitchesByRacks[rackId]),
      splittedRendering ? {} : {gap: 3}
    );

    const generics = this.topologyData.genericsByRacks[rackId] ?? [];
    const vlanSpace = this.topologyData.hasVlans ? this.topologyData.vlans.length * TOPOLOGY_CONFIG.vlanGap : 0;
    const rackGenerics = expandNodes ?
      l(LayoutType.Column,
        this.layoutNodes(generics),
        {columnRightPadding: vlanSpace}) :
        shouldRenderRackDevices ?
        l(LayoutType.Pad,
          [l(LayoutType.Column,
            this.layoutNodes(generics),
            {columns: 1, gap: 2, columnRightPadding: vlanSpace})],
          {padding: [0, 5]}) :
        !!generics.length && l(LayoutType.Node, null, {
          id: rackId,
          layoutData: this.topologyViewModel.collapsedGenerics,
        });

    return l(LayoutType.Pad,
      [l(LayoutType.Stack,
        [
          leafsLine,
          accessSwitchesLine,
          rackGenerics
        ]
      )],
      {id: rackId, layoutData: this.topologyViewModel.racks}
    );
  };

  public buildAccessRack = (rackId, expandNodes, shouldRenderRackDevices) => {
    const leafsLine = l(LayoutType.Column,
      this.layoutNodes(
        this.topologyData.leafsByRacks[rackId],
        this.topologyData.leafPairsByRacks[rackId]
      ),
      {columns: 8}
    );

    const accessGroupLayouts = map(this.topologyData.accessGroupsByRacks[rackId],
      ({access_group_id: accessGroupId}) => {
        const accessSwitchesLine = l(LayoutType.Column,
          this.layoutNodes(
            this.topologyData.accessSwitchesByAccessGroups[accessGroupId],
            this.topologyData.accessSwitchPairsByAccessGroups[accessGroupId]
          )
        );

        const generics = this.topologyData.genericsByAccessGroups[accessGroupId] ?? [];
        const vlanSpace = this.topologyData.hasVlans ? this.topologyData.vlans.length * TOPOLOGY_CONFIG.vlanGap : 0;
        const accessGroupGenerics = expandNodes ?
          l(LayoutType.Column,
            this.layoutNodes(generics),
            {columnRightPadding: vlanSpace}) :
            shouldRenderRackDevices ?
            l(LayoutType.Pad,
              [l(LayoutType.Column,
                this.layoutNodes(generics),
                {columns: 1, gap: 2, columnRightPadding: vlanSpace})],
              {padding: [0, 5]}) :
            !!generics.length && l(LayoutType.Node, null, {
              id: accessGroupId,
              layoutData: this.topologyViewModel.collapsedGenerics,
            });

        return l(LayoutType.Pad,
          [l(LayoutType.Stack,
            [
              accessSwitchesLine,
              accessGroupGenerics
            ]
          )],
          {id: accessGroupId, layoutData: this.topologyViewModel.accessGroups},
        );
      }
    );

    const genericsOutOfAccessGroups = reject(this.topologyData.genericsByRacks[rackId], 'access_group_id');
    const genericsOutOfAccessGroupsLayout = (expandNodes || genericsOutOfAccessGroups.length < 4) ?
    l(LayoutType.Column,
      this.layoutNodes(genericsOutOfAccessGroups)
    )
    :
    l(LayoutType.Node, null, {
      id: rackId,
      layoutData: this.topologyViewModel.collapsedGenerics,
    });

    const hasOnlyGenericsOutOfAccessGroups = isEmpty(this.topologyData.accessGroupsByRacks[rackId]);
    const genericsOutOfAccessGroupsContainer = hasOnlyGenericsOutOfAccessGroups ?
      genericsOutOfAccessGroupsLayout
      :
      l(LayoutType.Pad,
        [genericsOutOfAccessGroupsLayout],
        {id: rackId, layoutData: this.topologyViewModel.simpleGroupBoxes}
      );

    return l(LayoutType.Pad,
      [l(LayoutType.Stack,
        [
          leafsLine,
          l(LayoutType.Row, [
            l(LayoutType.Column, accessGroupLayouts),
            genericsOutOfAccessGroupsContainer
          ])
        ]
      )],
      {id: rackId, layoutData: this.topologyViewModel.racks},
    );
  };

  public buildRacksLayout = ({racks, expandNodes, shouldRenderRackDevices, rackLayoutBuilder}) => l(
    this.topologyData.hasVlans ? LayoutType.Row : LayoutType.Column,
    map(racks, ({rack_id: rackId}) => rackLayoutBuilder(rackId, expandNodes, shouldRenderRackDevices)),
    {columns: max([4, Math.ceil(size(racks) ** 0.5)])}
  );

  public buildPod = ({
    podId,
    showSpines,
    expandNodes,
    shouldRenderRackDevices,
    rackLayoutBuilder,
  }) => {
    const spinesLine = l(LayoutType.Column,
      this.layoutNodes(
        podId ? this.topologyData.spinesByPods[podId] :
          this.topologyData.nodeByRoles[ALL_NODE_ROLES.SPINE]
      ),
      {columns: 8}
    );

    const rackLayout = this.buildRacksLayout({
      racks: podId ? this.topologyData.rackByPods[podId] : this.topologyData.indexedRacks,
      expandNodes,
      shouldRenderRackDevices,
      rackLayoutBuilder
    });

    return l(LayoutType.Stack, [
      showSpines && spinesLine,
      rackLayout
    ]);
  };

  public buildPodColumns = ({
    showSpines,
    expandNodes,
    shouldRenderRackDevices,
    rackLayoutBuilder,
  }) => l(
    this.topologyData.hasVlans ? LayoutType.Row : LayoutType.Column,
    map(this.topologyData.indexedPods, ({id: podId}) => {
      return l(LayoutType.Pad,
        [
          this.buildPod({
            podId,
            showSpines,
            expandNodes,
            shouldRenderRackDevices,
            rackLayoutBuilder
          })
        ],
        {id: podId, layoutData: this.topologyViewModel.pods}
      );
    }),
    {columns: max([4, Math.ceil(size(this.topologyData.indexedPods) ** 0.5)])}
  );

  public buildVlanBases = () => {
    const allVlans = this.topologyData.securityZones.length ? transform(
      this.topologyData.securityZones,
      (acc, sz) => {
        acc.push(...this.topologyData.vlansBySecurityZone[sz.id]);
      },
      [] as IVlan[]
    ) : this.topologyData.vlans;
    return l(
      LayoutType.Stack,
      map(allVlans,
        (vlan) => l(LayoutType.VlanBus,
          null,
          {id: vlan.id, layoutData: this.topologyViewModel.vlans}
        )
      ),
      {gap: 0}
    );
  };
}
