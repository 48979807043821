import {observable, makeObservable, action} from 'mobx';

class Link {
  get id() {
    return `${this.from}-${this.to}`;
  }
  @observable
  from = null;

  @observable
  to = null;

  @observable
  touched = false;

  @action
  touch() {
    this.touched = true;
  }

  constructor(data = {}) {
    makeObservable(this);
    Object.assign(this, data);
  }
}

export default Link;
