import {Component} from 'react';
import {observer} from 'mobx-react';
import {Form} from 'semantic-ui-react';
import {DropdownInput} from 'apstra-ui-common';

import './DashboardsDisplayMode.less';

export const DASHBOARDS_DISPLAY_MODE = {
  summary: 'Summary',
  preview: 'Preview',
  expanded: 'Expanded'
};

@observer
export default class DashboardsDisplayMode extends Component {
  render() {
    const {value, modes, onChange} = this.props;

    const options = modes.map((mode) => ({
      key: mode,
      value: mode,
      text: mode
    }));

    return (
      <Form className='dashboards-display-mode' size='tiny'>
        <DropdownInput
          schema={{title: 'Display mode'}}
          options={options}
          value={value}
          onChange={onChange}
        />
      </Form>
    );
  }
}
