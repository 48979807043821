import {createContext, useMemo} from 'react';

import userStore from './userStore';

const UserStoreContext = createContext();

export const UserStoreContextProvider = ({userPreferences, updateUserPreferences, children}) => {
  useMemo(() => {
    userStore.init(userPreferences, updateUserPreferences);
  }, [userPreferences, updateUserPreferences]);

  return <UserStoreContext.Provider value={{userStore}}>{children}</UserStoreContext.Provider>;
};

export default UserStoreContext;
