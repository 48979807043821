import {useMemo} from 'react';
import {map, sortBy} from 'lodash';

import DonutChart from '../DonutChart';

import './FilterChart.less';

const FilterChart = ({
  title,
  definitions,
  onClick,
  selectedId
}) => {
  const chartProps = useMemo(() => {
    const sortedDefinitions = sortBy(definitions, 'index');
    return {
      values: map(sortedDefinitions, ({color, tooltip, value, 'query-value': queryValue}) => ({
        color, id: queryValue, tooltip: `${tooltip}: ${value}`, value,
        faded: !!selectedId && (selectedId !== queryValue),
      })),
      onSectorClick: (id) => onClick?.(selectedId === id ? null : id),
      showPieLabels: false
    };
  }, [definitions, onClick, selectedId]);

  return (
    <DonutChart
      className='blueprint-filter-chart'
      {...chartProps}
    >
      <div className='chart-title'>{title}</div>
    </DonutChart>
  );
};

export default FilterChart;
