import {map, transform, forEach, get} from 'lodash';
import {Button, Grid, Header, Segment} from 'semantic-ui-react';
import {createRef, useMemo, useState} from 'react';
import pluralize from 'pluralize';
import html2canvas from 'html2canvas';
import moment from 'moment';
import {saveAs} from 'file-saver';

import TableStats from './TableStats';
import SummaryInfo from './SummaryInfo';
import CirclePackChart from '../components/graphs/CirclePackChart';
import HistogramChart from '../components/graphs/HistogramChart';
import ExplanationChart from './ExplanationChart';

import './VirtualStatistics.less';

const types = {
  HISTOGRAM: 'histogram',
  MAPPING: 'mapping',
};
const metricExplanationDiagramProps = {
  vn_per_sz_stats: {
    transformMetricItem: (children, id, {vns, sZones}) => {
      const item = castLabelAndId(id, sZones);
      return {
        ...item,
        children: map(children, ({id, ...childProps}) => {
          const childItem = castLabelAndId(id, vns);
          return {
            ...childItem,
            ...childProps,
          };
        }),
      };
    },
  },
  vni_per_vn_stats: {
    nodeLabel: ['Leaf 1', 'Leaf 2', 'Leaf 3', 'Access-Switch'],
    offset: 40,
    popupHeader: (x, y) => `${y} VN instance(s) stretch across ${x} switch(es)`,
    noDataMessage: 'No VN instances stretch across any switch.',
  },
  vni_per_leaf_stats: {
    nodeLabel: 'Leaf',
    isSingleNode: true,
    popupHeader: (x, y) => `${y} leaf switch(es) hosts ${x} VN instance(s) each`,
    noDataMessage: 'No VN instances present on any leaf switch.',
  },
  vni_per_access_stats: {
    nodeLabel: 'Access-Switch',
    isSingleNode: true,
    popupHeader: (x, y) => `${y} Access switch(es) hosts ${x} VN instance(s) each`,
    noDataMessage: 'No VN instances present on any Access switch.',
  },
  vne_per_vn_stats: {
    nodeLabel: 'Generic System',
    offset: 40,
    popupHeader: (x, y) => `${y} VN(s) spans across ${x} Generic System(s) each`,
    noDataMessage: 'No VN endpoints includes any Generic system.',
  },
  vne_per_intf_stats: {
    nodeLabel: 'Generic System',
    isSingleNode: true,
    popupHeader: (x, y) => `${y} Generic system(s) are member of ${x} VN endpoint(s) each`,
    noDataMessage: 'No VN endpoints includes any Generic system.',
  }
};
const isL3Network = ({ipv4_enabled: ipv4, ipv6_enabled: ipv6}) => ipv4 || ipv6;
const castLabelAndId = (id, data) => {
  const item = {};
  const label = get(data, [id, 'label']);
  item.id = id;
  if (label) {
    item.label = label;
  }
  return item;
};

const VirtualStatistics = ({data, staged, blueprintId, ...meta}) => {
  const mappingChartsSummary = useMemo(() => {
    return transform(data, (result, {data, type}, index) => {
      if (type === types.MAPPING) {
        let parentCount = 0;
        let childrenCount = 0;
        forEach(data, (value) => {
          parentCount++;
          childrenCount += value.length;
        });
        result[index] = {parentCount, childrenCount};
      }
    }, {});
  }, [data]);
  const metrics = useMemo(() => {
    return transform(data, (result, {data, type, id: metricId}) => {
      if (type === types.MAPPING) {
        result.push(map(data, (children, id) => {
          const transformMetricItem = metricExplanationDiagramProps[metricId]?.transformMetricItem ?
            metricExplanationDiagramProps[metricId].transformMetricItem :
            (children, id) => ({id, children});
          return transformMetricItem(children, id, meta);
        }));
      } else if (type === types.HISTOGRAM) {
        result.push(
          transform(data, (values, value, key) => {
            if (`${key}` !== '0') {
              values.push(...new Array(value).fill(Number(key)));
            }
          }, [])
        );
      } else {
        result.push(data);
      }
    }, []);
  }, [data, meta]);

  const [actionInProgress, setActionInProgress] = useState(false);
  const processPopupHeader = (label) => (x, y) => `${x} ${pluralize(label, x)} ${y} ${pluralize('time', y)}`;
  const statisticsRef = createRef();
  const saveAsImage = async () => {
    if (!statisticsRef.current) return null;
    setActionInProgress(true);
    try {
      const canvas = await html2canvas(statisticsRef.current);
      const name = `${blueprintId}_${staged ? 'staged' : 'active'}_${moment().toISOString()}.png`;
      canvas.toBlob((blob) => {
        saveAs(blob, name);
      }, 'image/png');
    } catch {}
    setActionInProgress(false);
  };
  return [
    <div key='action' className='virtual-statistics-action'>
      <Button
        primary
        content='Export'
        onClick={saveAsImage}
        loading={actionInProgress}
        icon='file image outline'
      />
    </div>,
    <div key='virtual-statistics' ref={statisticsRef} className='virtual-statistics'>
      {map(data, ({id, label, description, stats, type, x_axis: xAxis, y_axis: yAxis}, index) => (
        <Segment key={id} piled>
          <Header>{label}</Header>
          <Grid verticalAlign='middle'>
            <Grid.Column width={4}>
              {type === types.HISTOGRAM ?
                <ExplanationChart
                  description={description}
                  {...metricExplanationDiagramProps[id]}
                /> :
                type === types.MAPPING ?
                  <SummaryInfo
                    rzCount={mappingChartsSummary[index].parentCount}
                    vnCount={mappingChartsSummary[index].childrenCount}
                  /> :
                  false
              }
            </Grid.Column>
            <Grid.Column width={8}>
              {type === types.HISTOGRAM ?
                <HistogramChart
                  data={metrics[index]}
                  value={(item) => item}
                  mode='expanded'
                  xLabel={xAxis?.label}
                  yLabel={yAxis?.label}
                  processPopupHeader={
                    metricExplanationDiagramProps[id]?.popupHeader || processPopupHeader(xAxis?.label || 'VN Instance')
                  }
                  processPopupContent={() => null}
                  noDataMessage={metricExplanationDiagramProps[id]?.noDataMessage}
                /> :
                type === types.MAPPING ?
                  <CirclePackChart
                    data={metrics[index]}
                    mode='expanded'
                    dimensions={{
                      compact: {height: 100},
                      expanded: {height: 300},
                    }}
                    circleClassName={(data, isLeaf) => !isL3Network(data) && isLeaf ? 'unfilled' : null}
                  /> :
                  false
              }
            </Grid.Column>
            <Grid.Column width={4}>
              <TableStats
                stats={stats}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      ))}
    </div>
  ];
};

export default VirtualStatistics;
