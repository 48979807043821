import {tokenMatcher} from 'chevrotain';

import {
  ShortSingleQuoteStringLiteral,
  ShortDoubleQuoteStringLiteral,
  LongSingleQuoteStringLiteral,
  LongDoubleQuoteStringLiteral
} from './PythonExpressionParser';

// TODO(vkramskikh): process \n, \r, \t, \xXXXX, other stuff
export default function parseStringLiteralToken(token) {
  let result = token.image;
  result = result.replace(/^\w+/, ''); // remove modifiers
  result = result.replace(/\\\\/g, '\\'); // unescape backslashes

  if (tokenMatcher(token, ShortSingleQuoteStringLiteral)) {
    result = result.slice(1, -1);
    result = result.replace(/\\'/g, "'");
  } else if (tokenMatcher(token, ShortDoubleQuoteStringLiteral)) {
    result = result.slice(1, -1);
    result = result.replace(/\\"/g, '"');
  } else if (tokenMatcher(token, LongSingleQuoteStringLiteral)) {
    result = result.slice(3, -3);
    result = result.replace(/\\'/g, "'");
  } else if (tokenMatcher(token, LongDoubleQuoteStringLiteral)) {
    result = result.slice(3, -3);
    result = result.replace(/\\"/g, '"');
  } else {
    return null;
  }
  return result;
}
