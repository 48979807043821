import {FC} from 'react';
import {map} from 'lodash';

import {VlanData} from '../utils';

import './Vlans.less';

type VlansProps = {
  vlans: Record<string, VlanData>;
};

const Vlan = ({label, colorName, width, top, left, nodeLinks}) => {
  return (
    <g
      className={`topology-vlan-bus tp-vlan-${colorName}`}
    >
      <line
        x1={left}
        x2={left + width}
        y1={top + 5}
        y2={top + 5}
      />
      {map(nodeLinks, ({nodeConnection: {x, y}, busX}, i) =>
        <path
          key={i}
          d={`M${x} ${y}H${busX - 5}q5 0 5 5V${top + 5}`}
        />
      )}
      <foreignObject x={left} y={top} width={width} height={10}>
        <span>
          {label}
        </span>
      </foreignObject>
    </g>
  );
};

export const Vlans: FC<VlansProps> = ({vlans}) => {
  return (
    <g className='topology-vlan-bus-group'>
      {map(vlans, (vlan, id) => <Vlan key={id} {...vlan} />)}
    </g>
  );
};
