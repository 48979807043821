import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Form, Icon, Message} from 'semantic-ui-react';
import {wrap, releaseProxy, proxy} from 'comlink';
import {
  transform, map, find, filter, first, flatMap, mapValues, get, castArray, groupBy, without,
  isPlainObject, isString, isMatch, differenceWith, isEmpty,
} from 'lodash';
import {
  FetchDataError, FormFragment, FormValidationError, RadioGroupInput, ResourceModal, SingleFileInput,
  generatePropertyFromSchema, interpolateRoute, notifier, request, requestProgress,
} from 'apstra-ui-common';

import uploadStore from '../uploadStore';
import {initUploadNotifier, UploadError} from './UploadNotifier';

import './DeviceOSImageModal.less';

const routes = {
  deviceOSImageUpload: '/api/device-os/images',
  deviceOSImageValidate: '/api/device-os/validate-image',
  deviceOSImageExternal: '/api/device-os/images-external',
  deviceOSImageDetails: '/api/device-os/images/<image_id>',
};

const IMAGE_TYPE_UPLOADED = 'uploaded';

const DeviceOSImageModal = ({
  mode = 'create', open, trigger,
  platforms, stats, osImage,
  onSuccess, onClose, helpPageId,
  warningThreshold = 5, error: initialError
}) => {
  const [properties, setProperties] = useState({});

  const platformDescription = useMemo(
    () => find(platforms, {platform: properties.platform}),
    [platforms, properties.platform]
  );

  const formSchema = useMemo(() => {
    const checksumTypes = platformDescription?.checksum ?? [];
    const editingUploadedImage = mode === 'update' && osImage?.type === IMAGE_TYPE_UPLOADED;
    return [
      {
        name: 'platform',
        required: true,
        disabled: editingUploadedImage,
        schema: {
          type: 'string',
          title: 'Platform',
          enum: map(platforms, 'platform'),
        }
      },
      {
        name: 'description',
        required: true,
        schema: {
          type: 'string',
          title: 'Description',
          minLength: 1,
        }
      },
      ...(mode === 'create' ? [
        {
          name: 'external',
          as: RadioGroupInput,
          schema: {
            type: 'boolean',
            default: false,
            oneOf: [
              {const: false, title: 'Upload Image'},
              {const: true, title: 'Provide Image URL'},
            ],
          },
        },
        properties.external ? {
          name: 'image_url',
          required: true,
          fieldProps: {description: 'HTTP and HTTPS URLs are supported', descriptionPosition: 'tooltip'},
          schema: {
            type: 'string',
            title: 'Image URL',
            minLength: 1,
          }
        } : {
          name: 'image',
          required: true,
          as: SingleFileInput,
          cancellationAvailable: true,
          schema: {title: 'Image'}
        }
      ] : []),
      {
        name: 'checksum',
        disabled: editingUploadedImage,
        fieldProps: {description: CHECKSUM_TOOLTIP, descriptionPosition: 'tooltip'},
        schema: {
          type: 'string',
          title: 'Checksum',
        }
      },
      {
        name: 'checksum_type',
        as: RadioGroupInput,
        disabled: editingUploadedImage,
        hidden: !properties.checksum || !checksumTypes.length,
        schema: {
          type: 'string',
          title: 'Checksum Type',
          default: first(checksumTypes),
          oneOf: map(checksumTypes, (type) => ({
            const: type,
            title: type
          })),
        },
      },
      {
        name: 'verify_checksum',
        hidden: mode !== 'create' || properties.external || !properties.checksum || !checksumTypes.length,
        schema: {
          type: 'boolean',
          title: 'Verify checksum before uploading',
          default: true,
        },
      },
    ];
  }, [
    platforms, platformDescription, mode, osImage,
    properties.checksum, properties.external
  ]);

  useEffect(() => {
    const checksumTypes = platformDescription?.checksum ?? [];
    if (checksumTypes.length && !checksumTypes.includes(properties.checksum_type)) {
      setProperties({...properties, checksum_type: first(checksumTypes)});
    }
  }, [properties, platformDescription]);

  const withDefaultValues = useCallback((properties) => {
    const result = {};
    for (const {name, schema} of formSchema) result[name] = properties[name] ?? generatePropertyFromSchema(schema);
    return result;
  }, [formSchema]);

  const resetState = useCallback(() => {
    const properties = {};
    if (osImage) {
      for (const {name} of formSchema) properties[name] = osImage[name];
    } else {
      Object.assign(properties, withDefaultValues(properties));
    }
    setProperties(properties);
  }, [formSchema, osImage, withDefaultValues]);

  // set default values when schema updated
  useEffect(
    () => setProperties(withDefaultValues(properties)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formSchema]
  );

  const submit = useCallback(async () => {
    const controller = new AbortController();

    const payloadPropertyNames = without(
      map(formSchema, 'name'),
      'external',
      'verify_checksum',
      ...(properties.checksum ? [] : ['checksum', 'checksum_type'])
    );

    if (mode === 'create' && !properties.external) {
      const body = transform(payloadPropertyNames, (result, propertyName) => {
        if (propertyName === 'image') {
          result.image_name = properties.image.name;
          result.image_size = properties.image.size;
        } else {
          result[propertyName] = properties[propertyName];
        }
      }, {});
      await request(
        routes.deviceOSImageValidate,
        {method: 'POST', body: JSON.stringify(body), signal: controller.signal}
      );
    }

    let body;
    if (mode === 'create' && !properties.external) {
      body = new FormData();
      for (const name of payloadPropertyNames) body.append(name, properties[name]);
    } else {
      body = {};
      for (const name of payloadPropertyNames) body[name] = properties[name];
      body = JSON.stringify(body);
    }
    const url =
      mode !== 'create' ? interpolateRoute(routes.deviceOSImageDetails, {imageId: osImage.id}) :
      properties.external ? routes.deviceOSImageExternal : routes.deviceOSImageUpload;

    if (mode === 'create' && !properties.external) {
      initUploadNotifier();
      const uploadId = uploadStore.addUploadItem({
        payload: {...properties},
        UploadErrorComponent: DeviceOSImageUploadError,
        modalProps: {platforms, stats, onSuccess, onClose: () => uploadStore.deleteUploadItem(uploadId)}
      });

      (async () => {
        if (properties.checksum && properties.verify_checksum) {
          const checksumCalculatorWorker = new Worker(
            new URL('../ChecksumCalculator', import.meta.url) /* webpackChunkName: 'checksum-worker' */
          );
          const checksumCalculator = wrap(checksumCalculatorWorker);
          const terminateWorker = () => {
            checksumCalculator[releaseProxy]();
            checksumCalculatorWorker.terminate();
          };
          uploadStore.updateUploadItemProgress(uploadId, {
            progressLabel: 'Verifying checksum',
            onCancelRequest: () => {
              terminateWorker();
              uploadStore.updateUploadItemProgress(uploadId, {error: new Error('Checksum verification aborted')});
            }
          });

          const actualChecksum = await checksumCalculator.calculate(
            properties.image,
            properties.checksum_type,
            proxy((uploadProgress) => uploadStore.updateUploadItemProgress(uploadId, {uploadProgress}))
          );
          terminateWorker();
          if (actualChecksum !== properties.checksum) {
            const error = new FormValidationError([{
              type: 'property',
              propertyName: 'checksum',
              message: `Checksum mismatch. The actual ${properties.checksum_type} checksum is ${actualChecksum}`,
            }]);
            error.toMessage = () => ({title: 'Checksum Verification Error', content: 'Checksum mismatch'});
            uploadStore.updateUploadItemProgress(uploadId, {error});
            return;
          }
        }

        uploadStore.updateUploadItemProgress(uploadId, {
          progressLabel: 'Uploading image',
          onCancelRequest: () => controller.abort(),
        });
        const onUploadProgress = (uploadProgress) => {
          const update = {uploadProgress};
          if (uploadProgress?.progress === 100) update.progressLabel = 'Processing image';
          uploadStore.updateUploadItemProgress(uploadId, update);
        };

        try {
          await requestProgress(url, {
            method: 'POST', body, maxRetryCount: 0, onProgress: onUploadProgress, signal: controller.signal
          });
          uploadStore.deleteUploadItem(uploadId);
          notifier.notify({message: 'Device OS Image has been successfully created'});
          onSuccess?.();
        } catch (error) {
          uploadStore.updateUploadItemProgress(uploadId, {error});
        }
      })();
      return {message: 'Device OS image has started to upload'};
    } else {
      return request(url, {method: mode === 'create' ? 'POST' : 'PATCH', body, signal: controller.signal});
    }
  }, [formSchema, mode, osImage, platforms, stats, properties, onSuccess]);

  const processErrors = useCallback(({errors}) => {
    if (isPlainObject(errors)) {
      return flatMap(errors, (propertyErrors, propertyName) => {
        return castArray(propertyErrors).map((message) => ({type: 'property', propertyName, message}));
      });
    } else if (isString(errors)) {
      return [{type: 'generic', message: errors}];
    } else {
      return [];
    }
  }, []);

  const submitAvailable =
    mode !== 'create' ||
    !!properties.external ||
    properties.image instanceof File;
  const freeSpace = get(stats, ['free'], 0);
  const partitionName = get(stats, ['partition_name']);

  return (
    <ResourceModal
      helpPageId={helpPageId}
      mode={mode}
      className='device-os-iamge-modal'
      resourceName='Device OS Image'
      resourceLabel={get(osImage, ['image_name'])}
      titlesByMode={{
        create: 'Register',
        update: 'Edit',
      }}
      actionsByMode={{
        create: properties.external ? 'Register' : 'Upload',
        update: 'Update',
      }}
      trigger={trigger}
      size='small'
      showCreateAnother={false}
      submitAvailable={submitAvailable}
      open={open}
      onClose={onClose}
      resetState={resetState}
      submit={submit}
      processErrors={processErrors}
    >
      {({actionInProgress, errors, setErrors}) =>
        <>
          {freeSpace < warningThreshold &&
            <Message icon warning>
              <Icon name='warning sign' />
              <Message.Content>
                <Message.Header>
                  {'The partition '}
                  <b>{partitionName}</b>
                  {` has under ${warningThreshold}GB of free space.`}
                </Message.Header>
              </Message.Content>
            </Message>
          }
          <DeviceOSImageForm
            schema={formSchema}
            properties={properties}
            actionInProgress={actionInProgress}
            errors={errors}
            initialError={initialError}
            setErrors={setErrors}
            setProperties={setProperties}
          />
        </>
      }
    </ResourceModal>
  );
};

export default DeviceOSImageModal;

export const DeviceOSImageForm = ({
  properties, setProperties, errors, setErrors, initialError, actionInProgress,
  ...props
}) => {
  const setPropertyValue = useCallback((name, value) => {
    setProperties({...properties, [name]: value});
    setErrors(differenceWith(errors, [{type: 'property', propertyName: name}], isMatch));
  }, [errors, properties, setErrors, setProperties]);

  useEffect(() => {
    if (!isEmpty(initialError?.errors)) setErrors([...errors, ...initialError.errors]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const errorByGroup = useMemo(() => {
    return {
      form: mapValues(
        groupBy(filter(errors, {type: 'property'}), 'propertyName'),
        (errorGroup) => flatMap(errorGroup, 'message')
      ),
      generic: find(errors, {type: 'generic'}),
      http: find(errors, {type: 'http'}),
    };
  }, [errors]);

  return (
    <Fragment>
      <Form>
        <FormFragment
          {...props}
          disabled={actionInProgress}
          values={properties}
          errors={errorByGroup.form}
          onChange={setPropertyValue}
        />
      </Form>
      {errorByGroup.http &&
        <FetchDataError
          error={errorByGroup.http.error}
          titlesByStatus={{422: 'Validation Error'}}
        />
      }
      {errorByGroup.generic &&
        <Message error icon='warning sign' header='Error' content={errorByGroup.generic.message} />
      }
    </Fragment>
  );
};

const DeviceOSImageUploadError = ({item}) => {
  return (
    <>
      <UploadError item={item} />
      <DeviceOSImageModal
        {...item.modalProps}
        osImage={item.payload}
        error={item.error}
        trigger={<Button
          primary
          size='tiny'
          icon='wrench'
          content='View and fix errors'
        />}
      />
    </>
  );
};

const CHECKSUM_TOOLTIP = 'This information is typically available on the support website where this image was' +
  ' downloaded from. It is used to verify the OS image integrity after it’s uploaded to a device during an OS' +
  ' upgrade operation';
