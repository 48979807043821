import {Component, Fragment, cloneElement} from 'react';
import PropTypes from 'prop-types';
import {observable, action, makeObservable, runInAction, computed} from 'mobx';
import {observer} from 'mobx-react';
import {Form} from 'semantic-ui-react';
import ExtendableError from 'es6-error';
import {castArray, flatten, isEmpty, map, uniq, values} from 'lodash';
import {CodeEditorControl, GenericErrors, ResourceModal, SingleFileInput} from 'apstra-ui-common';

export class ImportError extends ExtendableError {
  constructor(errors = []) {
    super('Error');
    Object.assign(this, {errors: castArray(errors)});
  }

  toMessage() {
    return {title: this.message, content: this.errors};
  }
}

@observer
export default class ImportModal extends Component {
  static propTypes = {
    import: PropTypes.func.isRequired,
    description: PropTypes.node,
    accept: PropTypes.object,
    mode: PropTypes.string,
    extraTopContent: PropTypes.node,
    extraBottomContent: PropTypes.node,
    codeEditorProps: PropTypes.object,
    defaultContents: PropTypes.string,
    defaultImportError: PropTypes.object,
    hideEditor: PropTypes.bool
  };

  static defaultProps = {
    description: 'Drag and drop file here, choose it by clicking the button or paste its contents in the field below.',
    mode: 'json'
  };

  @observable file = null;
  @observable contents = this.props.defaultContents || '';
  @observable importError = this.props.defaultImportError || null;

  @computed get errors() {
    if (!this.importError?.toMessage) {
      return [];
    }
    const validatorErrors = this.importError?.responseBody?.errors?.nodes;
    if (isEmpty(validatorErrors)) {
      return castArray(this.importError.toMessage().content);
    }
    const errors = flatten(values(validatorErrors));
    return uniq(map(errors, 'message'));
  }

  @action
  resetState = () => {
    this.contents = this.props.defaultContents || '';
    this.importError = this.props.defaultImportError || null;
  };

  onFileChange = (file) => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = () => this.onContentsChange(reader.result);
      reader.readAsText(file);
    }
  };

  @action
  onContentsChange = (newContents) => {
    this.contents = newContents;
    this.importError = null;
  };

  @action
  submit = async () => {
    try {
      await this.props.import(this.contents);
    } catch (error) {
      runInAction(() => {
        this.importError = error;
      });
      throw error;
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {
      description, accept, mode,
      extraTopContent, extraBottomContent,
      codeEditorProps, helpPageId, hideEditor, ...props
    } = this.props;
    delete props.import;
    delete props.defaultContents;
    delete props.defaultImportError;

    return (
      <ResourceModal
        helpPageId={helpPageId}
        closeIcon
        showCreateAnother={false}
        submitAvailable={this.contents.length !== 0 && this.errors.length === 0}
        actionsByMode={{create: 'Import'}}
        {...props}
        mode='create'
        resetState={this.resetState}
        submit={this.submit}
      >
        {() =>
          <Fragment>
            {extraTopContent}
            <Form>
              <SingleFileInput
                description={description}
                onChange={this.onFileChange}
                accept={accept}
              />
              {!hideEditor &&
                <Form.Field>
                  <CodeEditorControl
                    mode={mode}
                    maxLines={15}
                    value={this.contents}
                    multiLine
                    showGutter
                    onChange={this.onContentsChange}
                    {...codeEditorProps}
                  />
                </Form.Field>
              }
            </Form>
            {!!this.errors.length &&
              <GenericErrors errors={[...this.errors]} />
            }
            {extraBottomContent &&
              cloneElement(extraBottomContent, {contents: this.contents, importError: this.importError})
            }
          </Fragment>
        }
      </ResourceModal>
    );
  }
}
