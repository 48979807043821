import {keys, map, size, unset} from 'lodash';
import {observer} from 'mobx-react';
import cx from 'classnames';

import Port from './Port';
import './PortsCount.less';

const PortsCount = ({portsBySpeed, onClick, selectedSpeed, error, showEmpty, usedCount = 0}) => {
  const classNames = cx('re-ports-count', {error});

  const ports = size(portsBySpeed) ? {...portsBySpeed} : (showEmpty ? {'': 0} : {});
  if (selectedSpeed && !ports[selectedSpeed]) {
    ports[selectedSpeed] = 0;
    unset(ports, '');
  }

  return (
    <div className={classNames}>
      {
        map(
          keys(ports).sort(),
          (speedString) => {
            const amount = ports[speedString];
            const portsAvailable = usedCount > 0 || amount > 0;
            const selected = selectedSpeed && selectedSpeed === speedString && portsAvailable;
            return (showEmpty || !!amount) &&
              <Port
                key={speedString}
                speedString={portsAvailable ? speedString : null}
                amount={amount}
                onClick={amount ? onClick : undefined}
                selected={selected}
              />;
          }
        )
      }
    </div>
  );
};

export default observer(PortsCount);
