import {useCallback, useState, useMemo} from 'react';
import {Button, Popup} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {map, some, keyBy} from 'lodash';

import AggregatorModal from './AggregatorModal';
import NodesLinks from './NodesLinks';
import NodeProperties from './NodeProperties';
import SystemAssigner from './SystemAssigner';
import {useCablingMapStore} from '../store/useCablingMapStore';
import {useTooltip} from '../../components/graphs/GraphTooltips';
import HoverableButton from '../../rackEditor/components/HoverableButton';

import './Actions.less';

const Actions = ({availableDevices}) => {
  const [isAggregatorModalOpen, showAggregatorModal] = useState(false);
  const [isAssignModalOpen, showAssignModal] = useState(false);
  const {cablingMap, selection} = useCablingMapStore();
  const {sharedTooltip} = useTooltip();
  const {nodes, links, linksIds, optionsAreShown, nodesLinksProps, manageLinks} = cablingMap;

  const showActionTooltip = useCallback((title) => (e) => {
    sharedTooltip.show(title);
    e.stopPropagation();
  }, [sharedTooltip]);

  const selectionLength = selection.selectedNodesIds?.length ?? 0;
  const nothingSelected = selectionLength === 0;

  const hasNodesWithLinks = useMemo(
    () => !nothingSelected && some(
      links,
      (link) => link.containsNodesIds(selection.selectedNodesIds)
    ),
    [selection.selectedNodesIds, linksIds] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const linksManagementDisallowed = some(
    selection.selectedNodesIds,
    (nodeId) => !nodes?.[nodeId]?.allowLinksManagement
  );

  return (
    <>
      <div className='cabling-map-editor-actions' onMouseOut={sharedTooltip.hide} onBlur={sharedTooltip.hide}>
        <Button.Group size='large' primary>
          <Button
            icon='square full'
            aria-label='Create internal system'
            onClick={() => {
              cablingMap.hidePopups();
              cablingMap.addNode({});
            }}
            onMouseOver={showActionTooltip('Create internal system')}
          />
          <Button
            icon='chess board'
            aria-label='Create external system'
            onClick={() => {
              cablingMap.hidePopups();
              cablingMap.addNode({systemType: 'external'});
            }}
            onMouseOver={showActionTooltip('Create external system')}
          />
        </Button.Group>
        <Button.Group size='large' primary>
          <HoverableButton
            icon='linkify'
            aria-label='Manage links between selected nodes'
            disabled={selectionLength !== 2 || linksManagementDisallowed}
            onClick={() => {
              cablingMap.hidePopups();
              sharedTooltip.hide();
              manageLinks(selection.selectedNodesIds);
            }}
            onMouseOver={showActionTooltip('Manage links between selected nodes')}
          />
          <HoverableButton
            icon='code branch'
            aria-label='Manage LAGs'
            disabled={!hasNodesWithLinks}
            onClick={() => {
              cablingMap.hidePopups();
              sharedTooltip.hide();
              showAggregatorModal(true);
            }}
            onMouseOver={showActionTooltip('Manage LAGs')}
          />
          <HoverableButton
            icon='clone' disabled={nothingSelected}
            aria-label='Clone selected nodes'
            onClick={() => {
              cablingMap.hidePopups();
              selection.select(...cablingMap.cloneSelected(selection.selectedNodesIds));
            }}
            onMouseOver={showActionTooltip('Clone selected nodes')}
          />
          <Popup
            inverted={false}
            size='tiny'
            basic
            hoverable
            on={[]}
            position='top center'
            open={optionsAreShown && !nothingSelected}
            mountNode={sharedTooltip.mountRef}
            trigger={
              <HoverableButton
                active={optionsAreShown}
                aria-label='Manage selected nodes properties'
                icon='wrench'
                disabled={nothingSelected}
                onClick={() => {
                  sharedTooltip.hide();
                  cablingMap.hidePopups(true, false);
                  cablingMap.toggleOptions();
                }}
                onMouseOver={showActionTooltip('Manage selected nodes properties')}
              />
            }
          >
            <NodeProperties
              nodes={map(selection.selectedNodesIds, (id) => nodes[id])}
              availableDevices={availableDevices}
            />
          </Popup>
          <HoverableButton
            icon='bullseye'
            aria-label='Batch assign systems to selected nodes'
            disabled={nothingSelected}
            onClick={() => {
              cablingMap.hidePopups();
              sharedTooltip.hide();
              showAssignModal(true);
            }}
            onMouseOver={showActionTooltip('Batch assign systems to selected nodes')}
          />
          <HoverableButton
            icon='trash'
            aria-label='Delete selected nodes'
            disabled={nothingSelected}
            onClick={() => {
              const nodes = [...selection.selectedNodesIds];
              selection.clear();
              cablingMap.deleteSelected(nodes);
            }}
            onMouseOver={showActionTooltip('Delete selected nodes')}
          />
        </Button.Group>
        <Button.Group size='large' primary>
          <HoverableButton
            icon='undo'
            aria-label='Undo'
            disabled={!cablingMap.changes.canUndo}
            onClick={() => {
              cablingMap.hidePopups();
              selection.select(...cablingMap.undo());
            }}
            onMouseOver={showActionTooltip('Undo')}
          />
          <HoverableButton
            icon='redo'
            aria-label='Redo'
            disabled={!cablingMap.changes.canRedo}
            onClick={() => {
              cablingMap.hidePopups();
              selection.select(...cablingMap.redo());
            }}
            onMouseOver={showActionTooltip('Redo')}
            onMouseOut={sharedTooltip.hide}
          />
        </Button.Group>
      </div>
      {nodesLinksProps &&
        <NodesLinks
          close={manageLinks}
          data={nodesLinksProps}
        />}
      {isAggregatorModalOpen &&
        <AggregatorModal
          close={() => showAggregatorModal(false)}
          selectedNodes={keyBy(map(selection.selectedNodesIds, (nodeId) => nodes[nodeId]), 'id')}
        />}
      {isAssignModalOpen &&
        <SystemAssigner
          close={() => showAssignModal(false)}
          nodes={map(selection.selectedNodesIds, (nodeId) => nodes[nodeId])}
          availableDevices={availableDevices}
        />}
    </>
  );
};

export default observer(Actions);
