import {Component} from 'react';
import {scaleOrdinal} from 'd3';
import {map} from 'lodash';

import {discreteStateColors} from '../../../graphColors';

import './DiscreteStateLegend.less';

export default class DiscreteStateLegend extends Component {
  static defaultProps = {
    colors: discreteStateColors
  };

  colorScale = scaleOrdinal(this.props.colors);

  render() {
    const {colorScale, props: {possibleValues = []}} = this;
    colorScale.domain(possibleValues);
    return (
      <div className='iba-ds-legend'>
        <ul>
          {map(possibleValues, (value) => {
            const colorName = colorScale(value);
            return (
              <li key={value}>
                <div className={colorName ? `graph-color-${colorName}` : ''} />
                <span>{value}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
