import {Component, PureComponent, createRef} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Message, Grid, Header, Label, Segment} from 'semantic-ui-react';
import {DataFilteringContainerWithRouter, DropdownControl, FetchData} from 'apstra-ui-common';
import {map, values} from 'lodash';

import wrapWithComponent from '../wrapWithComponent';
import './GraphQLApiExplorer.less';

const importGraphiQLConsole = () => import(/* webpackChunkName: 'graphiql-console' */'./GraphiQLConsole');

const blueprintTypes = {
  'ql-readonly': ['staging', 'config', 'deployed', 'operation'],
  patch: ['config']
};
const actionTypes = {
  QL_READONLY: 'ql-readonly',
  PATCH: 'patch',
};

@wrapWithComponent(Router)
export default class GraphQLApiExplorer extends Component {
  static propTypes = {
    blueprints: PropTypes.array,
    defaultBlueprintType: PropTypes.oneOf(blueprintTypes['ql-readonly']),
    defaultAction: PropTypes.oneOf(values(actionTypes)),
    defaultQuery: PropTypes.string,
    defaultVariables: PropTypes.string,
  };

  static defaultProps = {
    blueprints: [],
    defaultBlueprintType: 'operation',
    defaultAction: actionTypes.QL_READONLY,
    defaultQuery: '{\n\tversion\n}',
    defaultVariables: '', // to prevent picking up of old variables from LocalStorage
  };

  graphiQLConsoleRef = createRef();

  render() {
    const {
      props: {
        blueprints, defaultBlueprintId, defaultBlueprintType, defaultAction,
        defaultQuery, defaultVariables
      },
      graphiQLConsoleRef
    } = this;

    return !blueprints?.length ?
      <Message
        info
        icon='info circle'
        content='GraphQL API Explorer requires a blueprint to operate'
      />
    : (
      <DataFilteringContainerWithRouter
        defaultFilters={{
          blueprintId: defaultBlueprintId ?? blueprints[0]?.id ?? '',
          blueprintType: defaultBlueprintType,
          action: defaultAction,
        }}
        stateQueryParam='params'
      >
        {({filters, updateFilters}) => {
          return (
            <Grid className='graphql-api-explorer'>
              <Grid.Row>
                <GraphQLAPIExplorerHeader
                  filters={filters}
                  blueprints={blueprints}
                  onParamsChange={(filters) => updateFilters(filters)}
                />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <FetchData
                    pollingInterval={null}
                    fetchData={importGraphiQLConsole}
                  >
                    {({GraphiQLConsole}) =>
                      <Segment>
                        <GraphiQLConsole
                          graphiQLConsoleRef={graphiQLConsoleRef}
                          params={filters}
                          query={defaultQuery}
                          variables={defaultVariables}
                        />
                      </Segment>
                    }
                  </FetchData>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </DataFilteringContainerWithRouter>
    );
  }
}

class GraphQLAPIExplorerHeader extends PureComponent {
  render() {
    const {filters, blueprints, onParamsChange} = this.props;

    return (
      <>
        <Grid.Column verticalAlign='middle' width={4}>
          <Header as='h3'>{'GraphQL API Explorer'}</Header>
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={1}>
          <Label className='dropdown-label'>{'Blueprint:'}</Label>
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={4}>
          <DropdownControl
            aria-label='Blueprint'
            fluid
            value={filters.blueprintId}
            onChange={(v) => onParamsChange({...filters, blueprintId: v})}
            options={blueprints.map(({id, label}) => ({key: id, value: id, text: label}))}
          />
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={1}>
          <Label className='dropdown-label'>{'Action:'}</Label>
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={3}>
          <DropdownControl
            aria-label='Action'
            fluid
            value={filters.action}
            onChange={(v) => {
              onParamsChange({
                ...filters, action: v,
                blueprintType: v === 'patch' ? 'config' : filters.blueprintType
              });
            }}
            options={map(actionTypes, (it) => ({key: it, value: it, text: it}))}
          />
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={1}>
          <Label className='dropdown-label'>{'Type:'}</Label>
        </Grid.Column>
        <Grid.Column textAlign='right' verticalAlign='middle' width={2}>
          <DropdownControl
            aria-label='Type'
            fluid
            value={filters.blueprintType}
            disabled={filters.action === 'patch'}
            onChange={(v) => onParamsChange({...filters, blueprintType: v})}
            options={blueprintTypes[filters.action].map((type) => ({key: type, value: type, text: type}))}
          />
        </Grid.Column>
      </>
    );
  }
}
