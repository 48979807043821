import {
  keys, map, transform, find, get, has, includes, head, flatMapDeep,
  sortBy, findKey, values, filter, isEmpty, isString, isFunction, reduce, forEach,
  isNil,
} from 'lodash';
import {stringifyState} from 'apstra-ui-common';

import {queryParamToSorting} from '../queryParamUtils';
import humanizeString from '../humanizeString';
import MatcherFilterStringToObjectConverter from '../matcherFilterString/MatcherFilterStringToObjectConverter';
import MatcherFilterStringParser from '../matcherFilterString/MatcherFilterStringParser';
import checkForPatterns from './checkForPatterns';
import {
  DEFAULT_STAGE_DATA_SOURCE,
  DEFAULT_AGGREGATION_TYPE_BY_VALUE_TYPE,
  STAGE_DATA_SOURCE,
  VALUE_NUMBER_TYPES,
  accumulateTextExtraColumns,
  accumulateNumberExtraColumns,
  AGGREGATION_TYPE_BY_VALUE_TYPE,
} from './consts';

export function sortStagePropertyNames(propertyNames) {
  return sortBy(propertyNames, (propertyName) => propertyName === 'system_id' ? '' : propertyName);
}

export function getStageSorting({sortingOverrides, propertyNames}) {
  return transform(sortStagePropertyNames(propertyNames), (result, propertyName) => {
    const name = `properties.${propertyName}`;
    if (!has(sortingOverrides, [name])) result[name] = 'asc';
  }, {...sortingOverrides});
}

export function processorCanRaiseAnomalies(processor) {
  return processor.type === 'anomaly' || processor.properties.raise_anomaly;
}

export function processorHasRaiseAnomaliesFlag(processor) {
  return has(processor.properties, ['raise_anomaly']);
}

export function processorCanRaiseWarnings(processor) {
  const enableTelemetryService = get(processor, ['properties', 'enable_telemetry_service']);
  return includes(
    ['generic_data_collector', 'extensible_data_collector', 'evpn3', 'evpn5', 'vxlan_floodlist'],
    processor.type
  ) || enableTelemetryService;
}

export function convertPropertyType(type) {
  if (type === 'bool') {
    return 'boolean';
  } else if (type === 'float' || type === 'integer') {
    return 'number';
  }
  return 'string';
}

export function convertValueType(type) {
  if (type === 'bool') {
    return 'boolean';
  } else if (VALUE_NUMBER_TYPES.has(type)) {
    return 'number';
  }
  return 'string';
}

export function getBaseRenderingStrategy(stage, dataSource) {
  const values = keys(stage.values);
  if (values.length === 0) return {};

  // Multi-value stage
  if (values.length > 1) {
    if (dataSource === STAGE_DATA_SOURCE.real_time) {
      return {
        sortableByValue: true,
        showTimestamp: true,
        noSpotlightMode: true,
        renderValueAs: ({type, possible_values: possibleValues}) => type === 'string' && possibleValues?.length ?
          'discreteStateValue' : type === 'string' ?
            'textValue' : 'primitiveValue',
      };
    } else if (dataSource === STAGE_DATA_SOURCE.time_series) {
      return {
        renderValueAs: ({type, possible_values: possibleValues}) => type === 'string' && possibleValues?.length ?
          'discreteStateTimeline' : type === 'string' ?
            'eventTimeline' : 'lineChart',
        // in the current type system, not a string means a number, which is rendered as a lineChart, which
        // makes the combining possible
        graphCombiningAvailable: ({type}) => type !== 'string',
      };
    }
  }
  const valueColumnName = values[0];
  const {values: {[valueColumnName]: {possible_values: possibleValues, type}}} = stage;
  const convertedType = convertValueType(type);

  // Discrete stage
  if (convertedType === 'string' && possibleValues?.length) {
    if (dataSource === STAGE_DATA_SOURCE.real_time) {
      return {
        renderValueAs: 'discreteStateValue',
        sortableByValue: true,
        showTimestamp: true,
      };
    }
    if (dataSource === STAGE_DATA_SOURCE.time_series) {
      return {
        renderValueAs: 'discreteStateTimeline',
      };
    }
    return {};
  }

  // Text stage
  if (convertedType === 'string') {
    if (dataSource === STAGE_DATA_SOURCE.real_time) {
      return {
        renderValueAs: 'textValue',
        sortableByValue: true,
        showTimestamp: true,
      };
    }
    if (dataSource === STAGE_DATA_SOURCE.time_series) {
      return {
        renderValueAs: 'eventTimeline',
      };
    }
    return {};
  }

  // Number stage
  if (convertedType === 'number') {
    if (dataSource === STAGE_DATA_SOURCE.real_time) {
      return {
        renderValueAs: 'horizontalBar',
        sortableByValue: true,
        showTimestamp: true,
      };
    }
    if (dataSource === STAGE_DATA_SOURCE.time_series) {
      return {
        renderValueAs: 'lineChart',
        graphCombiningAvailable: true,
      };
    }
    return {};
  }
  return null;
}

export function getRenderingStrategyOverrides(processorType, outputName, {values}) {
  if (processorType !== 'accumulate' || outputName !== 'out') return null;
  if (keys(values).length !== 1 || !('value' in values)) return null;
  const {type} = values.value;
  return convertValueType(type) === 'number' ?
    {extraColumns: accumulateNumberExtraColumns} :
    {extraColumns: accumulateTextExtraColumns};
}

export function getStageRenderingStrategy({
  processor, stage,
  dataSource = DEFAULT_STAGE_DATA_SOURCE,
  usePattern, probe,
}) {
  if (!stage) return null;
  const baseRenderingStrategy = getBaseRenderingStrategy(stage, dataSource);
  if (!baseRenderingStrategy) return null;
  const outputName = getOutputNameByStageName({processor, stageName: stage.name});
  const renderingStrategyOverrides = getRenderingStrategyOverrides(processor.type, outputName, stage);
  const stageRenderingStrategy = renderingStrategyOverrides ?
    {...baseRenderingStrategy, ...renderingStrategyOverrides} : baseRenderingStrategy;
  if (!usePattern) return stageRenderingStrategy;
  const patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource});
  if (patternDescription) {
    const patternRenderingStrategy = patternDescription.renderingStrategy;
    const combinedRenderValueAs = (valueSchema) => {
      for (let renderValueAs of [
        patternRenderingStrategy.renderValueAs,
        stageRenderingStrategy.renderValueAs,
      ]) {
        if (isFunction(renderValueAs)) renderValueAs = renderValueAs(valueSchema);
        if (renderValueAs) return renderValueAs;
      }
      return null;
    };
    return {
      ...stageRenderingStrategy,
      ...patternRenderingStrategy,
      renderValueAs: combinedRenderValueAs
    };
  } else {
    return stageRenderingStrategy;
  }
}

export function getTelemetryServiceSchema(items) {
  if (isEmpty(items)) return [];
  const properties = keys(head(items)?.properties);
  const schema = map(properties, (propertyName) => ({
    propertyName,
    label: humanizeString(propertyName),
    name: `properties.${propertyName}`,
    value: ['properties', propertyName],
    sortable: true,
  }));
  schema.push({
    name: 'warning',
    label: 'Telemetry Service Status',
    value: ['warning'],
  });
  return schema;
}

export function getStageDataSchema({
  stage, processor, renderingStrategy, processorDefinitions, showValue = true, valueColumnName,
}) {
  const {
    sortableByValue = false,
    showTimestamp = false,
    extraColumns = []
  } = renderingStrategy;
  const properties = map(sortStagePropertyNames(keys(stage.keys)), (propertyName) => {
    const propertyType = stage.keys[propertyName];
    let label = null;
    // FIXME(vkramskikh): why do we need to iterate over all processor definitions?
    for (const processorDefinition of processorDefinitions) {
      const humanizedPropertyName = get(processorDefinition, ['schema', 'properties', propertyName, 'title']);
      if (humanizedPropertyName) {
        label = humanizedPropertyName;
        break;
      }
    }
    if (label === null) label = humanizeString(propertyName);
    return {propertyName, name: `properties.${propertyName}`, label, propertyType};
  }, []);
  const stageDataSchema = properties.map(({name, label, propertyName, propertyType}) => ({
    name,
    label,
    value: ['properties', propertyName],
    key: true,
    sortable: true,
    type: propertyType,
  }));
  if (!isEmpty(extraColumns)) {
    stageDataSchema.push(...extraColumns);
  }
  if (processorCanRaiseAnomalies(processor)) {
    stageDataSchema.push({
      name: 'anomaly',
      label: 'Anomaly',
    });
  }
  if (showValue) {
    values(stage.values)
      .filter((value) => !valueColumnName || valueColumnName === value.name)
      .forEach(({name, title, type}) => {
        stageDataSchema.push({
          name,
          label: title || humanizeString(name),
          value: [name],
          sortable: sortableByValue,
          type,
        });
      });
  }
  if (showTimestamp) {
    stageDataSchema.push({
      name: 'timestamp',
      label: 'Updated',
      sortable: true,
      value: ['timestamp'],
    });
  }
  return stageDataSchema;
}

export function getStageValuesSchema(stage) {
  const stageValuesSchema = [];
  values(stage?.values).forEach(({name, title, type: rawType, possible_values: possibleValues, description}) => {
    if (rawType) {
      const type = convertValueType(rawType);
      const isRange = (type === 'number');
      const isDiscreteState = (type === 'string' && !isEmpty(possibleValues));
      stageValuesSchema.push({
        name,
        schema: {
          type,
          description,
          title: title || humanizeString(name),
          enum: isDiscreteState ? possibleValues : null,
          default: isRange ? {} : undefined,
        },
        isRange,
        units: get(stage, ['units', name]),
      });
    }
  });
  return stageValuesSchema;
}

export function getStageFormSchema(processorDefinition, stage, stageDefinition) {
  const stageFormSchema = [
    {
      name: 'enable_metric_logging',
      schema: {
        type: 'boolean',
        title: 'Enable Metric Logging',
        description: 'Save changes in this stage to MetricDB time series database.'
      }
    },
    {
      name: 'retention_duration',
      hidden: !stage?.enable_metric_logging, // eslint-disable-line camelcase
      schema: {
        type: 'number',
        default: 86400,
        title: 'Retention Duration',
        description: 'Retain data in MetricDB for specified duration.'
      }
    },
    {
      name: 'description',
      schema: {
        type: 'string',
        title: 'Description',
      }
    },
    {
      name: 'graph_annotation_properties',
      schema: {
        type: 'object',
        title: 'Graph Annotation Properties',
        description: 'Annotate graph nodes with properties associated with stage values.',
      }
    }
  ];
  const unitsProperties = stageDefinition ? reduce(stageDefinition.types, (result, {values}) => {
    forEach(values, ({title}, key) => {
      result[key] = {type: 'string', title};
    });
    return result;
  }, {}) : {};
  stageFormSchema.push({
    name: 'units',
    schema: {
      type: 'object',
      title: 'Units',
      description: 'Units for values.',
      properties: unitsProperties,
    }
  });
  return stageFormSchema;
}

export function castFilterConverter(filter) {
  if (isString(filter)) {
    const keyValueFilters = MatcherFilterStringToObjectConverter
      .run(MatcherFilterStringParser.parse(filter).cst);
    return isEmpty(keyValueFilters) && !isEmpty(filter) ? filter : keyValueFilters;
  }
  return filter;
}

export function getStageQueryParamsFromWidget({
  widget, stage, alwaysUseContext, filterDeserializer = castFilterConverter
}) {
  const stageDataSourceIsTimeSeries = widget.data_source === STAGE_DATA_SOURCE.time_series;
  const filter = filterDeserializer(widget.filter);
  const filters = {
    dataSource: widget.data_source,
  };
  if (!isEmpty(filter)) {
    filters.filter = filter;
  }
  if (!stageDataSourceIsTimeSeries) {
    filters.anomalousOnly = widget.anomalous_only;
  }
  if (!stageDataSourceIsTimeSeries || alwaysUseContext) {
    filters.showContextInfo = widget.show_context;
  }
  if (stageDataSourceIsTimeSeries) {
    filters.valueColumnName = getValueColumnNameFromWidget({widget, stage});
  }
  if (!isNil(widget.time_series_duration)) {
    filters.timeSeriesDuration = widget.time_series_duration;
  }
  if (!isNil(widget.aggregation_period)) {
    filters.timeSeriesAggregation = widget.aggregation_period;
  }
  if (widget.combine_graphs) {
    filters.combineGraphs = widget.combine_graphs;
  }
  if (widget.aggregation_type) {
    filters.aggregationType = widget.aggregation_type;
  }

  const stageFilter = {};
  const sorting = queryParamToSorting(widget.orderby);
  if (!isEmpty(sorting)) {
    stageFilter.sorting = sorting;
  }
  if (!isEmpty(filters)) {
    stageFilter.filters = filters;
  }

  return isEmpty(stageFilter) ? null : `?stage-filter=${stringifyState(stageFilter)}`;
}

export function getValueColumnNameFromWidget({widget, stage}) {
  if (widget.value_column_name) return widget.value_column_name;
  if (!stage || widget.data_source === STAGE_DATA_SOURCE.real_time) return null;
  return find(widget.visible_columns, (name) => name in stage.values) ?? head(keys(stage.values)) ?? null;
}

export function getStageComponentPropsFromWidget({widget, probes}) {
  const {probe_id: probeId, stage_name: stageName} = widget;
  const probe = find(probes, {id: probeId});
  const processor = getProcessorByStageName({probe, stageName});
  const stage = getStageByName({probe, stageName});
  return {probe, processor, stage};
}

export function generateStageQueryParams(params) {
  if (params) {
    const filter = stringifyState({
      ...params.filters && {filters: {
        filter: params.filters.filter,
        anomalousOnly: params.filters.anomalousOnly,
        showContextInfo: params.filters.showContextInfo,
        dataSource: params.filters.dataSource,
        combineGraphs: params.filters.combineGraphs,
      }},
      ...params.sorting && {sorting: queryParamToSorting(params.sorting)},
    });
    return `?stage-filter=${filter}`;
  }
  return null;
}

export function getStageByName({probe, stageName}) {
  if (!probe) return null;
  return find(probe.stages, {name: stageName}) || null;
}

export function getProcessorByStageName({probe, stageName}) {
  if (!probe) return null;
  return find(probe.processors, ({outputs}) => find(outputs, (outputName) => outputName === stageName)) || null;
}

export function getOutputNameByStageName({processor, stageName}) {
  if (!processor || !processor.outputs || !stageName) {
    return null;
  }
  return findKey(processor.outputs, (outputStageName) => outputStageName === stageName) || null;
}

export function getInputStages({probe, processor}) {
  const stageNames = getInputStageNames(processor);
  return filter(map(stageNames, (stageName) => getStageByName({probe, stageName})));
}

export function getInputStageNames(processor) {
  return map(processor.inputs, ({stage}) => stage);
}

export function getPossibleStageValues(processorDefinition, stagesKey, withTransformedType = true) {
  return transform(processorDefinition[stagesKey], (result, stageDefinition, stage) => {
    result[stage] = flatMapDeep(stageDefinition.types, ({dynamic, values}) => {
      return map(values, (valueArray, key) => map(valueArray, (value) => ({
        dynamic,
        name: key,
        possible_values: value.possible_values,
        type: withTransformedType ? convertValueType(value.type) : value.type,
      })));
    });
  }, {});
}

export function generateNewInputName(processor, from = 1) {
  const result = 'in' + from;
  if (!(result in processor.inputs)) return result;
  return generateNewInputName(processor, from + 1);
}

export function getPossibleAggregationTypes(stage, valueColumnName) {
  const type = get(stage, ['values', valueColumnName, 'type']);
  return transform(AGGREGATION_TYPE_BY_VALUE_TYPE, (result, types, aggregationType) => {
    if (types.has(type)) {
      result.push(aggregationType);
    }
  }, []);
}

export function getValueAggregationTypes(stage, valueColumnName, patternDescription) {
  if (patternDescription?.renderingStrategy.aggregationType) {
    return patternDescription.renderingStrategy.aggregationType;
  }
  const type = convertValueType(get(stage, ['values', valueColumnName, 'type']));
  return DEFAULT_AGGREGATION_TYPE_BY_VALUE_TYPE[type];
}
