import PropTypes from 'prop-types';

export const anomalyPropTypes = PropTypes.shape({
  node: PropTypes.string.isRequired,
  all: PropTypes.number.isRequired,
  bgp: PropTypes.number,
  cabling: PropTypes.number,
  config: PropTypes.number,
  deployment: PropTypes.number,
  hostname: PropTypes.number,
  interface: PropTypes.number,
  liveness: PropTypes.number,
  route: PropTypes.number
});
