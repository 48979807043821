import {Component} from 'react';
import {observable, action, comparer, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Message, Icon} from 'semantic-ui-react';

import IBAContext from '../../IBAContext';

import HeadroomDiagram from './HeadroomDiagram';
import {buildGraphFromCablingMap, getGraphBfs} from './trafficUtils';

@observer
export default class TrafficDiagramContainer extends Component {
  static contextType = IBAContext;

  @observable.ref paths = [];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.pathsDisposer = reaction(
      () => [this.context.cablingMap, this.props.destinationNodeId, this.props.sourceNodeId],
      this.calculatePaths,
      {equals: comparer.structural, fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.pathsDisposer();
  }

  @action
  calculatePaths = () => {
    const {props: {destinationNodeId, sourceNodeId},
      context: {isFreeform}} = this;
    const graph = buildGraphFromCablingMap({cablingMap: this.context.cablingMap, isFreeform});
    if (!destinationNodeId || !sourceNodeId) return null;
    this.paths = getGraphBfs({isFreeform})({graph, sourceId: sourceNodeId, destinationId: destinationNodeId});
  };

  render() {
    const {
      context: {systemIdMap},
      props: {sourceNodeId, destinationNodeId, systemValuesSchema, interfaceValuesSchema, stageItems, patternData},
      paths,
    } = this;
    return paths.length ? (
      <HeadroomDiagram
        key={`${sourceNodeId}:${destinationNodeId}`}
        paths={paths}
        systemIdMap={systemIdMap}
        systemItems={stageItems}
        interfaceItems={patternData?.items}
        systemValuesSchema={systemValuesSchema}
        interfaceValuesSchema={interfaceValuesSchema}
      />
    ) : (
      <Message warning icon>
        <Icon name='warning sign' />
        <Message.Content>
          {!sourceNodeId || !destinationNodeId
            ? 'Please select a node pair'
            : 'There is no valid route between selected nodes'}
        </Message.Content>
      </Message>
    );
  }
}
