import {Component} from 'react';
import {Form} from 'semantic-ui-react';
import {computed, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {isEmpty, isPlainObject, isNil, keys, values, castArray, transform} from 'lodash';
import {CodeEditorInput, Field} from 'apstra-ui-common';

import RangeControl from '../../components/RangeControl';

import './StateRangeInput.less';

@observer
export default class StateRangeInput extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get value() {
    const value = !isEmpty(this.props.value) ? this.props.value : {'': []};
    const state = keys(value)[0];
    const range = value[state][0] || {};
    return {state, range};
  }

  @action
  onChange = (state, range) => {
    this.props.onChange({[state]: castArray(range)});
  };

  @action
  setStateValue = (state) => {
    this.onChange(state, this.value.range);
  };

  @action
  setRangeValue = (newRange) => {
    const {range} = this.value;
    for (const key of ['min', 'max']) {
      if (isNil(newRange[key])) {
        delete range[key];
      } else {
        range[key] = newRange[key];
      }
    }
    this.onChange(this.value.state, range);
  };

  @computed get errors() {
    return this.props.errors.reduce((result, error) => {
      if (isPlainObject(error)) {
        const errors = values(error)[0];
        if (isPlainObject(errors)) {
          result.range = castArray(transform(errors, (result, value, key) => {
            result[key] = value;
          }, {}));
        } else {
          result.state = castArray(errors);
        }
      } else {
        result.generic.push(...castArray(error));
      }
      return result;
    }, {state: [], range: [], generic: []});
  }

  render() {
    const {name, schema, required, disabled, fieldProps, knownPythonExpressionVariables} = this.props;
    const {state, range} = this.value;
    return (
      <Field
        className='state-range-input multi-items-input'
        label={schema.title || name}
        description={schema.description}
        required={required}
        disabled={disabled}
        errors={this.errors.generic}
        errorsPosition='above'
        {...fieldProps}
      >
        {(inputProps) => [
          <CodeEditorInput
            key='state'
            value={state}
            mode='python-expression'
            enableCompletion
            completerParams={{knownVariables: knownPythonExpressionVariables}}
            placeholder='State value'
            errors={this.errors.state}
            onChange={(value) => this.setStateValue(value)}
            {...inputProps}
          />,
          <Form.Group key='range'>
            <RangeControl
              inputType='expression'
              value={range}
              errors={this.errors.range}
              onChange={(newRange) => this.setRangeValue(newRange)}
              knownPythonExpressionVariables={knownPythonExpressionVariables}
              {...inputProps}
            />
          </Form.Group>
        ]}
      </Field>
    );
  }
}
