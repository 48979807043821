import {Label, Icon} from 'semantic-ui-react';
import {DateFromNow, parseTimestamp} from 'apstra-ui-common';

export default function UpdatedByLabel({updatedBy, timestamp}) {
  return (
    <Label>
      <Icon name={updatedBy ? 'user' : 'setting'} />
      {updatedBy ?? 'System'}
      {timestamp && <Label.Detail><DateFromNow date={parseTimestamp(timestamp)} /></Label.Detail>}
    </Label>
  );
}
