import {observer} from 'mobx-react';
import {Message, Grid, List} from 'semantic-ui-react';
import cx from 'classnames';

import Lag from './Lag';

import './Aggregator.less';
import './Legend.less';

const samplePath = 'M 0 3 L 20 3';

const AsSvg = ({children, width = 20, height = 7}) => {
  return (
    <svg
      className='svg-sample'
      xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.2'
      baseProfile='full'
      width={width}
      height={height}
    >
      {children}
    </svg>
  );
};

const LinkSample = ({className}) => {
  return (
    <AsSvg>
      <path className={cx(className, 'outline')} d={samplePath} />
      <path className={className} d={samplePath} />
    </AsSvg>
  );
};

const Legend = () => {
  return (
    <div className='cme-legend'>
      <Message
        info
        icon='info circle'
        header='Legend'
        content={
          <Grid stretched>
            <Grid.Row className='cme-aggregate'>
              <Grid.Column width={5}>
                <List>
                  <List.Item>
                    <LinkSample />
                    {'physical link'}
                  </List.Item>
                  <List.Item>
                    <LinkSample className='aggregated' />
                    {'aggregated link'}
                  </List.Item>
                  <List.Item>
                    <LinkSample className='deleted disabled' />
                    {'deleted link'}
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6}>
                <List>
                  <List.Item>
                    <LinkSample className='selected' />
                    {'selected physical link'}
                  </List.Item>
                  <List.Item>
                    <LinkSample className='aggregated selected' />
                    {'selected aggregated link'}
                  </List.Item>
                  <List.Item>
                    <LinkSample className='disabled' />
                    {'non-selectable link'}
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={5}>
                <List>
                  <List.Item className='fixed'>
                    <AsSvg width={25} height={45}>
                      <Lag x={10} y={15} size={1.5} className='selected' behind />
                      <Lag x={10} y={15} size={1.5} className='selected' />
                    </AsSvg>
                    <div>{'aggregate endpoint (click to edit properties)'}</div>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      />
      <Message
        icon='warning sign'
        warning
        header='Please note, that the following physical links cannot be part of the same aggregate link:'
        content={
          <ul>
            <li>{'Links of with both ends residing in the same endpoint group'}</li>
            <li>{'Links of the different speed'}</li>
          </ul>
        }
      />
    </div>
  );
};

export default observer(Legend);
