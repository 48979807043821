import {Label} from 'semantic-ui-react';

import {TRAFFIC_HEAT_LAYER, HOT_LINK_UTILIZATION} from '../../../iba/components/graphs/trafficUtils';

import './NodeAnomalyInfo.less';

export default function NodeAnomalyInfo({layer}) {
  return (
    <section>
      <Label icon='warning sign' color='red' size='mini' />
      {layer === TRAFFIC_HEAT_LAYER && `At least one link is > ${HOT_LINK_UTILIZATION}%`}
    </section>
  );
}
