import {Component} from 'react';
import {action, observable, makeObservable, computed} from 'mobx';
import {observer} from 'mobx-react';
import {Button} from 'semantic-ui-react';
import {isEmpty, transform, isPlainObject, isString, keyBy, some, forEach, trim} from 'lodash';
import {GenericErrors, ResourceModal, request} from 'apstra-ui-common';

import EptBuilderStore from '../store/EptBuilderStore';
import {StoreProvider} from '../store/useStore';
import {Ept} from '../store/Ept';
import Sausager from '../Sausager';

import Canvas from './Canvas';
import SidePanel from './SidePanel';
import {eptRequiredParametersError, eptErrorTitles} from '../settings';
import './EptBuilder.less';

@observer
export default class EptBuilder extends Component {
  store = new EptBuilderStore();
  usedPrimitivesIds = [];

  @observable sausager;
  @observable mode = 'create';

  constructor(props) {
    super(props);
    makeObservable(this);

    this.componentDidUpdate({eptsHash: null});
  }

  @computed
  get hasChanges() {
    return this.store?.activeEpt?.hasChanges;
  }

  @action
  componentDidUpdate(prevProps) {
    const {epts, eptSchemas, nodeGetByType, eptsHash, permissions} = this.props;
    if (eptsHash !== prevProps.eptsHash) {
      // If EPTs set has been refetched from the server - builder must
      // rebuild and rerender its content correspondingly
      this.sausager = new Sausager(epts, eptSchemas, nodeGetByType);
      this.store.access.permissions = permissions;
      if (this.props.readonly) this.resetState();
    }
  }

  _getEndpoint() {
    return `/api/blueprints/${this.props.blueprintId}/obj-policy-import`;
  }

  updateEpt = (sausage) => request(
    this._getEndpoint(),
    {
      method: 'PUT',
      body: JSON.stringify(sausage)
    }
  );

  @action
  resetState = (keepTab) => {
    const {epts, activeEptId, readonly, initialState} = this.props;
    const {activeEpt} = this.store;

    activeEpt.hasDeletions = false;

    // Initial state represents initial structure of parameterised
    // primitives on the canvas.
    if (initialState) {
      // Initialise new CT with the set of parameterised primitives
      this.reset(keepTab);

      forEach(initialState, ({name, attributes}) => {
        if (name) {
          // Adds parameterised primitive
          const primitive = this.sausager.schemas[name];
          if (!primitive) {
            throw `Primitive '${name}' does not exist`;
          }
          const ept = Ept.fromPrimitive(primitive, this.sausager, attributes);
          ept.touch(false);
          activeEpt.addEpt(ept);
        } else {
          // Sets global properties (name, description, tags)
          activeEpt.setProperties(attributes);
        }
      });
    } else if (activeEptId) {
      // Reset to the initial state of CT by given id
      const activeSausage = epts.find((ept) => ept.id === activeEptId);
      if (activeSausage) {
        activeEpt.activate(activeSausage, this.sausager, keepTab);
        this.mode = readonly ? 'view' : 'update';
      } else {
        this.reset(keepTab);
      }
    } else {
      // Reset to empty canvas if building from scratch
      this.reset(keepTab);
    }
  };

  @action
  submit = () => {
    const {epts, activeEptId} = this.props;
    const {activeEpt} = this.store;

    this.usedPrimitivesIds = Object.entries(activeEpt.epts)
      .map(([id, ept]) => {
        ept.parametersErrors = {};
        return id;
      });

    if (!activeEptId) {
      // Prefix CT label if not unique
      const usedLabels = keyBy(epts, 'label');
      let index = 2;
      const baseLabel = activeEpt.title;
      while (usedLabels[activeEpt.title]) {
        activeEpt.title = `${baseLabel} (${index++})`;
      }
    }

    const sausage = this.sausager.sausage(activeEpt);
    return this.updateEpt({policies: sausage});
  };

  reset = (keepTab) => this.store.activeEpt.reset(keepTab);

  @action
  onClose = () => {
    this.reset();
  };

  _processAttributesErrors = (eptId, contents, isAttributesCommonErrorShown) => {
    const {activeEpt: {epts}} = this.store;
    epts[eptId].setParametersErrors(contents?.attributes ?? {});

    return !isAttributesCommonErrorShown
      ? [eptRequiredParametersError]
      : [];
  };

  processErrors = ({errors}) => {
    if (isPlainObject(errors)) {
      let isAttributesCommonErrorShown = false;
      return transform(errors, (result, contents, errorKey) => {
        if (this.usedPrimitivesIds.includes(errorKey)) {
          // All errors listed under primitive's guid must be displayed
          // under corresponding parameter
          result.push(
            ...this._processAttributesErrors(
              errorKey,
              contents,
              isAttributesCommonErrorShown
            )
          );
          isAttributesCommonErrorShown = true;
        } else {
          // Otherwise display error in place as plain text or json if needed
          const title = eptErrorTitles[errorKey] || errorKey;
          result.push([
            <h5 key='title'>{title}</h5>,
            isPlainObject(contents) ?
              <code key='contents'>{JSON.stringify(contents, null, ' ')}</code> :
              <p key='contents'>{contents}</p>
          ]);
        }
        return result;
      }, []);
    } else if (isString(errors)) {
      return [errors];
    } else {
      return [];
    }
  };

  render() {
    const {
      processErrors, resetState, submit, mode,
      props: {
        open, onClose, onSuccess, activeEptId,
        epts, eptSchemas, globalCatalog, allTags, readonly, helpPageId
      }
    } = this;
    const {activeEpt, access: {vnAccessOnly: vnOnly}} = this.store;

    const showIntro = Object.keys(activeEpt.epts).length === 1;

    const localErrors = [];
    if (some(activeEpt.epts, ({id}) => id && !activeEpt.hasConnections(id))) {
      localErrors.push('Please make sure all primitives are reachable from the Application Point');
    }
    if (!trim(activeEpt.title)) {
      localErrors.push('Please specify the Name');
    }
    const submitAvailable = !localErrors.length && this.hasChanges;
    const locked = readonly || (vnOnly && activeEpt.hasNonVnPrimitives);

    const handleReset = () => resetState(true);

    const extraActions = (
      <Button
        secondary
        size='large'
        onClick={handleReset}
        disabled={!this.hasChanges}
      >
        {'Revert Changes'}
      </Button>
    );

    const builderRenderer = ({errors}) => {
      const combinedErrors = [...localErrors, ...(errors ?? [])];
      return (
        <>
          {
            !isEmpty(combinedErrors) &&
              <GenericErrors errors={combinedErrors} />
          }
          <div className='ct-builder'>
            <SidePanel
              {...{
                epts, eptSchemas, globalCatalog, allTags, readonly: locked, activeEptId, vnOnly,
                sausager: this.sausager
              }}
            />
            <Canvas {...{ept: activeEpt, showIntro, readonly: locked}} />
          </div>
          {
          }
        </>
      );
    };

    return (
      <StoreProvider value={this.store}>
        {readonly ?
          builderRenderer({errors: []}) :
          <ResourceModal
            helpPageId={helpPageId}
            resourceName='Connectivity Template'
            size='fullscreen'
            submit={submit}
            resetState={handleReset}
            onKeyDown={() => {}}
            className='ct-builder-modal'
            notifyOnSuccess={!readonly}
            closeOnEscape={false}
            scrolling
            {...{mode, open, onClose, onSuccess, processErrors, extraActions, submitAvailable}}
          >
            {builderRenderer}
          </ResourceModal>
        }
      </StoreProvider>
    );
  }
}
