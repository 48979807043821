import {observer} from 'mobx-react';

import PeerLinkForm from './PeerLinkForm';
import usePeerLinker from '../hooks/usePeerLinker';
import {PEER_LINKS_TYPES} from '../const';

import './LeafPeerLinker.less';

const LeafPeerLinker = ({portsSpeeds, selectedNodes}) => {
  const {node, linksGroups, portChannelIdsErrors, trackChange} = usePeerLinker(selectedNodes);

  return (
    <div className='re-leaf-pair-linker'>
      <h5>{'Leaf Pair Peer Links'}</h5>
      {
        node.isMlagLeafPair && (
          <>
            <PeerLinkForm
              title='L2 Links'
              node={node}
              link={linksGroups[PEER_LINKS_TYPES.L2_PEER]}
              portsSpeeds={portsSpeeds}
              required
              error={!!portChannelIdsErrors?.portChannelId}
              trackChange={trackChange}
            />
            <PeerLinkForm
              title='L3 Links'
              node={node}
              link={linksGroups[PEER_LINKS_TYPES.L3_PEER]}
              portsSpeeds={portsSpeeds}
              isExt
              trackChange={trackChange}
              error={!!portChannelIdsErrors?.portChannelIdExt}
            />
          </>
        )
      }
    </div>
  );
};

export default observer(LeafPeerLinker);

