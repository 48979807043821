import {transform, map, uniq} from 'lodash';

import {NODE, OUT, IN} from './consts';

export default function getAvailableTypesFromPath(path, currentFunctionName, referenceDesignSchema) {
  const allNodes = new Set(map(referenceDesignSchema.nodes, (schema, type) => type));
  const allRelationships = new Set(map(referenceDesignSchema.relationships, ({name}) => name));
  let nodes = allNodes;
  let inRelationships = allRelationships;
  let outRelationships = allRelationships;
  if (path) {
    for (const {name: functionName, type: functionType} of path) {
      if (functionName === NODE) {
        [inRelationships, outRelationships] = transform(
          referenceDesignSchema.relationships,
          ([inRelationships, outRelationships], relationship) => {
            for (const nodeType of (functionType ? [functionType] : nodes)) {
              if (nodeType === relationship.source_type) outRelationships.push(relationship);
              if (nodeType === relationship.target_type) inRelationships.push(relationship);
            }
          },
          [[], []]
        );
      } else if (functionName === OUT || functionName === IN) {
        const nodeTypeKey = functionName === OUT ? 'target_type' : 'source_type';
        const relationships = functionName === OUT ? outRelationships : inRelationships;
        const relationshipTypes = functionType ? [functionType] : uniq(map(relationships, ({name}) => name));
        nodes = transform(relationships, (result, relationship) => {
          for (const relationshipType of relationshipTypes) {
            if (relationshipType === relationship.name) result.add(relationship[nodeTypeKey]);
          }
        }, new Set());
      }
    }
  }
  if (currentFunctionName === NODE) {
    return {nodes: [...nodes]};
  } else if (currentFunctionName === OUT) {
    return {relationships: uniq(map(outRelationships, ({name}) => name))};
  } else if (currentFunctionName === IN) {
    return {relationships: uniq(map(inRelationships, ({name}) => name))};
  }
}
