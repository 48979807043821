import {map, flattenDeep, groupBy, min, max, clamp,
  transform} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {Popup} from 'semantic-ui-react';
import {useResizeDetector} from 'react-resize-detector';
import cx from 'classnames';
import {select, zoom} from 'd3';

import {useCablingMapStore} from '../store/useCablingMapStore';
import Grid from './Grid';
import Selection from './Selection';
import NodeProperties from './NodeProperties';
import {ZoomArea, zoomConstrain} from './ZoomArea';
import {GraphPreview} from './GraphPreview';
import AggregatorModal from './AggregatorModal';
import {useTooltip} from '../../components/graphs/GraphTooltips';
import Actions from './Actions';
import CanvasNodes from './CanvasNodes';
import CanvasLinks from './CanvasLinks';
import {ctrlGridStep, ctrlNodeHeight, ctrlNodeWidth} from '../const';

import {ReactComponent as Definitions} from '../../../styles/icons/cabling-map-editor/definitions.svg';
import './Canvas.less';

const Canvas = ({onClickNode, readonly, tags, snapped, availableDevices, fullscreen, zoomNode,
  minZoom = 0.2, maxZoom = 1, panPadding = 100, margin = 10, zoomStep = 1.1}) => {
  const canvasRef = useRef();
  const zoomGroupRef = useRef();
  const {cablingMap, selection} = useCablingMapStore();
  const [zoomTransition, setZoomTransition] = useState(false);
  const [aggregateToEdit, editAggregate] = useState(null);
  const [shiftIsPressed, trackShift] = useState(false);
  const [searchTransition, setSearchTransition] = useState(false);
  const searchTransitionTimeout = useRef();

  const [zoomFactor, setZoomFactor] = useState(1);

  const draggingRenewal = useRef();

  const {width = 800, height = 600, ref} = useResizeDetector();
  const {sharedTooltip} = useTooltip();

  const {selectedNodesIds, isSelecting} = selection;

  // All nodes are split into selected/not selected groups.
  // Selected get wrapped into Draggable and rendered on top of the others.
  const nodesGroups = useMemo(() => {
    return isSelecting ?
      {} :
      groupBy(
        cablingMap.nodesSorted,
        ({id}) => selectedNodesIds.includes(id)
      );
  }, [selectedNodesIds, cablingMap.nodesSorted, isSelecting]);

  // Affected nodes - ones that are linked to the selected set.
  // Their interfaces rendering positions must be recalculated upon selected group moving.
  const affectedNodes = useMemo(() => {
    return isSelecting ? [] : transform(
      cablingMap.linksSorted,
      (result, link) => {
        if (selectedNodesIds.includes(link.endpoint1.nodeId)) {
          result[link.endpoint2.nodeId] = cablingMap.nodes[link.endpoint2.nodeId];
        } else if (selectedNodesIds.includes(link.endpoint2.nodeId)) {
          result[link.endpoint1.nodeId] = cablingMap.nodes[link.endpoint1.nodeId];
        }
      },
      transform(selectedNodesIds, (acc, nodeId) => {
        acc[nodeId] = cablingMap.nodes[nodeId];
      }, {})
    );
  }, [selectedNodesIds, cablingMap.nodes, isSelecting, cablingMap.linksSorted]);

  const screenToPoint = useCallback((x, y) => {
    const pt = canvasRef.current.createSVGPoint();
    pt.x = x; pt.y = y;
    const transformed = pt.matrixTransform(zoomGroupRef.current.getScreenCTM().inverse());
    return {x: transformed.x, y: transformed.y};
  }, []);

  const pointToContainer = useCallback((x, y) => {
    const pt = canvasRef.current.createSVGPoint();
    pt.x = x; pt.y = y;
    const transformed = pt.matrixTransform(zoomGroupRef.current.getCTM());
    return {x: transformed.x, y: transformed.y};
  }, []);

  const shiftPress = useCallback((event) => trackShift(event?.shiftKey), []);

  // When shift is pressed canvas must switch to the pan mode. Add and remove handlers in a side effect.
  useEffect(() => {
    window.addEventListener('keydown', shiftPress);
    window.addEventListener('keyup', shiftPress);
    return () => {
      window.removeEventListener('keydown', shiftPress);
      window.removeEventListener('keyup', shiftPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // In full screen components gets rendered in a different container so the ref is needed
  // to properly display the popups
  useEffect(() => {
    sharedTooltip.setMountRef(fullscreen ? ref : undefined);
  }, [sharedTooltip, ref, fullscreen]);

  const {editedNodeId, nodes, positioner: {minX, minY, maxX, maxY}} = cablingMap;
  const editedNode = nodes[editedNodeId];

  const panConstraints = useMemo(() => [
    [min([minX, 0]) - panPadding, min([minY, 0]) - panPadding],
    [max([maxX, minX + width]) + panPadding, max([maxY + panPadding, minY + height - margin])],
  ], [height, margin, maxX, maxY, minX, minY, panPadding, width]);

  // Node clicking in readonly mode navigates to the neighbors view
  // In editing mode - toggles node selection state
  const handleNodeClick = useCallback(
    (event, node) => {
      if (readonly) return onClickNode?.(node.id);
      selection.toggleNodeSelection(event, node);
    },
    [onClickNode, readonly, selection]
  );

  // Mouse events handling and dotted background are exceeding in readonly mode
  // and must be removed
  const interactionProps = useMemo(
    () => {
      return readonly ?
        {} :
        {
          onMouseDown: (event) => {
            cablingMap.hidePopups();

            selection.start(event, screenToPoint);
            selection.change(event, cablingMap.nodesSorted, screenToPoint, true);

            if (selection.selectedNodesIds.length === 1) {
              const id = selection.selectedNodesIds[0];
              selection.end();
              // node is remounted after selection.
              // this hack is needed to initialize dragging on newly selected
              // remounted node
              draggingRenewal.current = setTimeout(() => {
                const node = document.getElementById(id);
                node?.dispatchEvent(new MouseEvent('mousedown', event));
              }, 0);
            }
          },
          onMouseMove: (event) => selection.change(event, cablingMap.nodesSorted, screenToPoint),
          onMouseUp: () => {
            clearTimeout(draggingRenewal.current);
            selection.end();
          }
        };
    },
    [cablingMap, readonly, screenToPoint, selection]
  );

  const classNames = cx(
    'cabling-map-editor-canvas',
    {
      readonly,
      'zoom-transition': zoomTransition,
      pannable: shiftIsPressed || readonly,
      'search-transition': searchTransition
    }
  );

  const canvasZoom = useMemo(() => zoom(), []);

  useEffect(() => {
    canvasZoom
      .scaleExtent([minZoom, maxZoom])
      .translateExtent(panConstraints)
      .constrain(zoomConstrain)
      .filter((event) => {
        return readonly ? true : event?.shiftKey;
      })
      .on('zoom', (event) => {
        select(zoomGroupRef.current)
          .attr('transform', event.transform);
        sharedTooltip.hide();
        setZoomFactor(event.transform.k);
      });

    select(canvasRef.current)
      .call(canvasZoom)
      // force to apply constraints
      .call(canvasZoom.translateBy, 0, 0);
  }, [canvasRef, readonly, minZoom, maxZoom, panConstraints, canvasZoom, sharedTooltip]);

  const resetZoom = useCallback(() => {
    const horizontalZoom = width / (maxX - minX);
    const verticalZoom = height / (maxY - minY);
    const zoom = readonly ? clamp(max([horizontalZoom, verticalZoom]), minZoom, maxZoom) : 1;
    select(canvasRef.current)
      .call(canvasZoom.scaleTo, zoom)
      .call(
        canvasZoom.translateTo,
        0.5 * (panConstraints[0][0] + panConstraints[1][0]),
        0.5 * (panConstraints[0][1] + panConstraints[1][1])
      );
  }, [canvasZoom, height, maxX, maxY, maxZoom, minX, minY, minZoom, panConstraints, readonly, width]);

  const zoomIn = useCallback(() => {
    cablingMap.hidePopups();
    select(canvasRef.current).call(canvasZoom.scaleBy, zoomStep);
  }, [canvasZoom, zoomStep, cablingMap]);

  const zoomOut = useCallback(() => {
    cablingMap.hidePopups();
    select(canvasRef.current).call(canvasZoom.scaleBy, 1 / zoomStep);
  }, [canvasZoom, zoomStep, cablingMap]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetZoom, []);

  const onTransformChangeHandler = useCallback((transform) => {
    cablingMap.hidePopups();
    select(canvasRef.current)
      .call(canvasZoom.transform, transform);
  }, [canvasZoom, cablingMap]);

  useEffect(() => {
    if (zoomNode) {
      setSearchTransition(true);
      setTimeout(() => {
        select(canvasRef.current)
          .call(
            canvasZoom.translateTo,
            zoomNode.position.x,
            zoomNode.position.y
          );
      }, 0);
      const timeout = searchTransitionTimeout.current = setTimeout(() => setSearchTransition(false), 1000);
      return () => clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomNode]);

  const gridSize = max(map(flattenDeep(panConstraints), Math.abs)) / minZoom;

  const editedNodeScreenPosition = editedNode && pointToContainer(editedNode.position.x, editedNode.position.y);

  const hideSmallDetails = zoomFactor < 0.5;

  return (
    <div ref={ref} className={cx('ctrl-canvas', {fullscreen})}>
      <ZoomArea
        zoom={canvasZoom}
        minX={panConstraints[0][0]}
        minY={panConstraints[0][1]}
        maxX={panConstraints[1][0]}
        maxY={panConstraints[1][1]}
        width={width}
        height={height}
        onTransformChange={onTransformChangeHandler}
        setZoomTransition={setZoomTransition}
        parentRef={ref}
        fullscreen={fullscreen}
        resetZoom={resetZoom}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
      >
        <GraphPreview
          nodes={nodes}
          minX={panConstraints[0][0]}
          minY={panConstraints[0][1]}
          maxX={panConstraints[1][0]}
          maxY={panConstraints[1][1]}
        />
      </ZoomArea>
      <svg
        ref={canvasRef}
        id='ct-canvas'
        className={classNames}
        xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
        version='1.2'
        baseProfile='full'
        width={width}
        height={height - margin}
        {...interactionProps}
      >
        <g ref={zoomGroupRef}>
          {!readonly && <Grid className='background-grid' step={ctrlGridStep} size={gridSize} />}
          <Definitions />

          <CanvasLinks
            {...{readonly, onClickAggregate: editAggregate}}
          />

          <CanvasNodes
            nodes={isSelecting ? cablingMap.nodesSorted : nodesGroups.false}
            {...{onClick: handleNodeClick, readonly, snapped, hideSmallDetails}}
          />
          {!isSelecting && <CanvasNodes
            nodes={nodesGroups.true}
            {...{onClick: handleNodeClick, readonly, snapped, affectedNodes, hideSmallDetails}}
          />}

          {!readonly &&
            <Selection selection={selection} />}
        </g>
      </svg>
      {!readonly &&
        <Actions
          tags={tags}
          availableDevices={availableDevices}
        />
      }
      {editedNode &&
        <Popup
          mountNode={ref?.current}
          context={canvasRef.current}
          style={{width: ctrlNodeWidth}}
          offset={[
            editedNodeScreenPosition.x,
            editedNodeScreenPosition.y + ctrlNodeHeight - height
          ]}
          size='tiny'
          position='bottom left'
          inverted={false}
          pinned
          open
          basic
        >
          <NodeProperties
            nodes={editedNode}
            tags={tags}
            availableDevices={availableDevices}
          />
        </Popup>
      }
      {aggregateToEdit &&
        <AggregatorModal
          close={() => editAggregate(null)}
          selectedLink={aggregateToEdit}
        />
      }
    </div>
  );
};

export default observer(Canvas);
