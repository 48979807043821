import {observable, makeObservable, action, computed, toJS} from 'mobx';
import {map, max, forEach, values, keyBy, uniq, flatten, filter, transform, range,
  compact, groupBy, size, sortBy, min, every, omit, without, countBy, set, find, first} from 'lodash';

import {Message} from 'semantic-ui-react';

import Node from './Node';
import {generateLocalId, makeUniqueNodeLabel} from '../../cablingMapEditor/utils';
import Positioner from '../../cablingMapEditor/Positioner';

import Zone from './Zone';
import ChangesStore from '../../cablingMapEditor/store/ChangesStore';
import Step from '../../cablingMapEditor/store/Step';
import LinksGroup from './LinksGroup';
import {ctrlEntityTypes, ctrlNodeWidth, ctrlGridStep, ctrlNodeHeight} from '../../cablingMapEditor/const';
import {
  rolesOrder, NODE_ROLES, REDUNDANCY, PEER_LINKS_TYPES, NO_REDUNDANCY,
  DESIGN, draftLinksGroupId, PORT_ROLES, ROLES_NODE_TYPES
} from '../const';
import Port from './Port';
import {makeSafeName, randomPrefix, speedFromString} from '../utils';
import {request} from '../../exports';

const entities = {
  [ctrlEntityTypes.NODE]: Node,
  [ctrlEntityTypes.LINKS_GROUP]: LinksGroup
};

const collections = {
  [ctrlEntityTypes.NODE]: 'nodes',
  [ctrlEntityTypes.LINKS_GROUP]: 'linksGroups'
};

const noLinks = {
  count: 0,
  speed: null
};

class RackStore {
  @observable changes;

  @observable nodes = {};
  @observable linksGroups = {};

  @observable id = null;
  @observable name = '';
  @observable description = '';
  @observable design = DESIGN.L3CLOS;

  @observable editedNode = null;
  @observable optionsAreShown = false;

  @observable positioner = new Positioner([]);

  @observable zones = {};
  @observable devices = {};

  @observable openedLinksGroupId;
  @observable isSaving = false;

  #tags = [];

  @observable error = null;

  @computed.struct
  get linksGroupsSorted() {
    return sortBy(this.linksGroups, 'id');
  }

  @computed
  get isLinkerOpened() {
    return this.openedLinksGroupId !== undefined;
  }

  @computed
  get tags() {
    return compact(uniq([
      ...this.#tags,
      ...this.usedTags
    ]));
  }

  @computed
  get usedTags() {
    return compact(uniq([
      ...flatten(map(this.nodes, 'tags')),
      ...flatten(map(this.linksGroups, 'tags'))
    ]));
  }

  @computed
  get isL3Clos() {
    return this.design === DESIGN.L3CLOS;
  }

  @computed
  get portChannelsPools() {
    // For all switch nodes
    const result = transform(
      filter(this.nodes, {isGeneric: false}),
      (duplicates, switchNode) => {
        const {isPaired, isFirstInPair, portChannelId: pcL2, portChannelIdExt: pcL3} = switchNode;
        if (isPaired && !isFirstInPair) return;

        // Same nodes might have multiple links between them - in that case it should not be
        // considered as a duplicate
        const seenNames = {};

        // Iterate through all their links (peer and to generics)
        const portChannels = transform(
          switchNode.myLinksGroups,
          (acc, linksGroup) => {
            const {count, isPeer, isL3Peer} = linksGroup;
            // Empty links does not affect counts
            if (!count) return;

            // Take into account peer links port channel Ids
            if (isPeer) {
              const pcId = isL3Peer ? pcL3 : pcL2;
              if (pcId) (acc[pcId] ??= []).push(switchNode.name);
            }

            // If link is to generic - count its port channel Id in
            const {name, isGeneric, portChannelId} = linksGroup.oppositeToNode(switchNode);
            if (isGeneric && portChannelId && !seenNames[name]) {
              (acc[portChannelId] ??= []).push(name);
              seenNames[name] = true;
            }
          },
          {}
        );

        // Check if some of PCIDs are duplicated
        forEach(portChannels, (names, portChannelId) => {
          if (size(names) > 1) {
            // and then add them to the resulting collection
            forEach(names, (name) => {
              (duplicates[name] ??= []).push(+portChannelId);
            });
          }
        });
      },
      {}
    );

    // Remove duplicated IDs for each of nodes
    return transform(
      result,
      (acc, ids, nodeId) => {
        acc[nodeId] = uniq(ids);
      },
      {}
    );
  }

  @computed
  get usedLabels() {
    return transform(
      this.nodes,
      (acc, {_label, label, isPaired, isFirstInPair}) => {
        if (isPaired) {
          set(acc, label, (acc?.[label] ?? 0) + 1);
          // Avoiding duplication of _label for paired nodes
          if (!isFirstInPair) return;
        }
        set(acc, _label, (acc?.[_label] ?? 0) + 1);
      },
      {}
    );
  }

  @computed.struct
  get leafNodes() {
    return sortBy(filter(this.nodes, 'isLeaf'), 'id');
  }

  @computed
  get leafPairsCount() {
    return countBy(filter(this.leafNodes, 'isPaired'), 'redundancyProtocol');
  }

  @computed
  get mlagLeafPairsCount() {
    return (this.leafPairsCount?.[REDUNDANCY.MLAG] || 0) / 2;
  }

  @computed
  get esiLeafPairsCount() {
    return (this.leafPairsCount?.[REDUNDANCY.ESI] || 0) / 2;
  }

  pickUniqueLabel(baseLabel, hasRedundancy, usedLabels = this.usedLabels, doNotClean) {
    const cleanBaseLabel = doNotClean ?
      `${baseLabel}_` :
      baseLabel.replace(/\d+$/g, '');
    let result;
    let index = 1;
    do {
      result = `${cleanBaseLabel}${index++}`;
      // eslint-disable-next-line no-unmodified-loop-condition
    } while (usedLabels[result] || (hasRedundancy && (usedLabels[`${result}_1`] || usedLabels[`${result}_2`])));
    return result;
  }

  @computed
  get zoneNodes() {
    return groupBy(this.nodes, 'role');
  }

  @computed
  get nodesByName() {
    return groupBy(sortBy(this.nodes, ({name, pairOrder}) => `${name}:${pairOrder}`), 'name');
  }

  @computed
  get rolesCount() {
    return countBy(this.nodes, 'role');
  }

  @computed.struct
  get nodeLinksGroups() {
    return transform(
      this.linksGroupsSorted,
      (acc, {fromNode, peeringType, count}) => {
        forEach(
          fromNode?.nodesInvolved,
          ({id}) => {
            if (!acc[id]) acc[id] = {[peeringType]: 0};
            acc[id][peeringType] = count + (acc[id]?.[peeringType] ?? 0);
          }
        );
      },
      {}
    );
  }

  @computed
  get nodesUsedPorts() {
    // For each of link groups
    const result = transform(
      this.linksGroupsSorted,
      (acc, {
        speed, count, isPeer,
        isAttachedToFirst, isAttachedToSecond, isDualAttached,
        fromRole, toRole, fromNode, toNode
      }) => {
        if (!count || !fromNode || !toNode) return;

        const fromNodes = isPeer ? [fromNode] : fromNode.nodesInvolved;
        const toNodes = isPeer ? [fromNode.pairedWith] : toNode.nodesInvolved;

        const fromPair = size(fromNodes) === 2;
        const toPair = size(toNodes) === 2;
        const isBetweenPairs = fromPair && toPair;
        const doubleCount = 2 * count;

        // Count taken ports depending on how many nodes on the ends of the link and
        // whether these are single- or double-attached
        const portsTaken = isBetweenPairs ?
          (isDualAttached ?
              [[doubleCount, doubleCount], [doubleCount, doubleCount]] :
              [[count, count], [count, count]]) :
          (fromPair ?
            [[count, count], [doubleCount]] :
            (toPair ?
              [
                [isDualAttached ? doubleCount : count],
                [isAttachedToFirst ? count : 0, isAttachedToSecond ? count : 0]
              ] :
              [[count], [count]]));

        // Collect used ports nodeId/speed/role : count
        forEach([fromNodes, toNodes], (nodes, index) => {
          const counts = portsTaken[index];
          const role = index ? fromRole : toRole;
          forEach(nodes, ({id}, nodeIndex) => {
            if (id) {
              if (!acc[id]) acc[id] = [];
              acc[id].push(...Port.generate(counts[nodeIndex], speed, role));
            }
          });
        });
      },
      {}
    );

    // For L3 Clos Design links per spine setting must be taken into account
    if (this.isL3Clos) {
      forEach(
        // For all LEAF nodes
        this.leafNodes,
        // mark corresponding amount of SPINE ports as taken
        ({id, spineLinksCount, spineLinksSpeed}) => {
          (result[id] ??= []).push(...Port.generate(
            spineLinksCount, speedFromString(spineLinksSpeed), PORT_ROLES.SPINE
          ));
        }
      );
    }

    return result;
  }

  @computed
  get linksGroupsByName() {
    return transform(
      this.linksGroupsSorted,
      (acc, linkGroup) => {
        (acc[linkGroup.fromName] ??= []).push(linkGroup);
        if (!linkGroup.isPeer) (acc[linkGroup.toName] ??= []).push(linkGroup);
      },
      {}
    );
  }

  @action
  calculateZonesSizes() {
    forEach(
      this.zones,
      (zone, role) => {
        zone.maxY = max(
          [ctrlGridStep, ...map(this.zoneNodes[role], 'relativePosition.y')]
        ) + ctrlNodeHeight + ctrlGridStep;
        if (zone.size < zone.maxY) zone.size = zone.maxY;
      }
    );
  }

  @action
  highlightNode = (nodeId) => {
    this.highlightedId = nodeId;
  };

  // Initialize the new links group with selected nodes and label
  newLinksGroup(selection) {
    const selectedNames = uniq(map(selection.selectedNodesIds, (id) => this.nodes[id].name));
    const namesCount = size(selectedNames);
    const isPair = namesCount === 1 && selection.size === 2 && size(this.nodesByName[selectedNames[0]]) === 2;
    if (!isPair && namesCount !== 2) throw 'Attempt to create links between incorrect # of nodes';

    // Links are always oriented from lower zone to the uppers
    const [firstNode, secondNode] = isPair ?
      this.nodesByName[selectedNames[0]] :
      sortBy(
        map(selectedNames, (name) => this.nodesByName[name][0]),
        ({role}) => -rolesOrder.indexOf(role)
      );

    const oneToPair = !firstNode.isPaired && secondNode.isPaired;
    const pairToOne = firstNode.isPaired && !secondNode.isPaired;

    return LinksGroup.createFilledWith({
      id: draftLinksGroupId,
      label: makeSafeName(`${firstNode?.label}_${secondNode?.label}_${randomPrefix()}`),
      fromName: firstNode.name,
      toName: secondNode.name,
      isAttachedToFirst:
        // If one to pair and selection contains first node in pair
        (oneToPair && selection.isSelected(secondNode.nodesInvolved[0].id)) ||
        // In all other cases always on
        !oneToPair,
      isAttachedToSecond:
        pairToOne ||
        (oneToPair && selection.isSelected(secondNode.nodesInvolved[1].id))
    }, this);
  }

  @action
  toggleLinker(linksGroupId, selection, arePeers) {
    if (linksGroupId === undefined || !!linksGroupId) {
      if (this.openedLinksGroupId !== linksGroupId) {
        // Close linker
        if (this.linksGroups[draftLinksGroupId]) {
          this.linksGroups = omit(this.linksGroups, draftLinksGroupId);
        }
        this.openedLinksGroupId = linksGroupId;
      }
    } else if (!linksGroupId) {
      // Open linker
      if (!arePeers) {
        this.addLinksGroup(this.newLinksGroup(selection));
      }
      this.openedLinksGroupId = draftLinksGroupId;
    }
  }

  @action
  setProperty(property, value = null) {
    this[property] = value;
  }

  @action
  toggleOptions(forceHide = false) {
    this.optionsAreShown = forceHide ? false : !this.optionsAreShown;
  }

  @action
  reset(id, name, description, design, devices, tags = []) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.design = design;

    // Removes all existing nodes and links
    this.nodes = {};
    this.linksGroups = {};

    this.#tags = [...tags];
    this.editedNode = null;
    this.changes.reset();

    // Precalculate list of ports for every LD
    this.devices = keyBy(
      map(devices, (logicalDevice) => ({
        ...logicalDevice,
        ports: sortBy(
          transform(logicalDevice?.panels, (acc, {port_groups: groups}) => {
            forEach(groups, (groupData) => acc.push(...Port.parseGroup(groupData)));
          }, []),
          'rolesSize'
        )
      })),
      'id'
    );

    let previousZone;
    this.zones = transform(rolesOrder, (acc, role) => {
      const zone = new Zone(role, previousZone, this);
      acc[role] = zone;
      previousZone = zone;
    }, {});
  }

  @action
  createNode(nodeData, role, usedPositions, switchesByName, preferences) {
    const {
      label,
      links,
      instance_count: instanceCount,
      count,
      redundancy_protocol: redundancy,
    } = nodeData;

    const isLeaf = role === NODE_ROLES.LEAF;
    const isGeneric = role === NODE_ROLES.GENERIC;

    const hasRedundancy = redundancy && redundancy !== NO_REDUNDANCY;
    const instances = instanceCount || count || 1;
    const multipleInstances = instances > 1;

    const switches = switchesByName[first(links)?.target_switch_label];
    const firstSwitch = first(switches);
    const isConnectedToAccess = firstSwitch?.role === NODE_ROLES.ACCESS_SWITCH;
    const isGenericConnectedToAccess = isGeneric && isConnectedToAccess;

    let node = null;
    const nodePreferences = preferences?.[label];

    forEach(
      // If generic is connected to an access - amount of nodes depends on
      // amount of switches
      isGenericConnectedToAccess ? switches : [firstSwitch],
      (switchNode) => {
        const switchPosition = switchNode?.relativePosition;

        // Wnen too many generics get created, they might be distributed evenly
        // in 4 columns
        const verticalLimit = instances > 7 ? instances / 4 : instances;
        let index = 0;
        let _x = switchPosition?.x ?? 0;

        forEach(range(0, instances), () => {
          const id = generateLocalId();
          let x, y;
          if (!multipleInstances && nodePreferences) {
            x = nodePreferences?.x ?? 0;
            y = nodePreferences?.y ?? 0;
          } else if (switchPosition && isGeneric) {
            // Generic nodes must be placed under their switches.
            // They also might be positioned diagonally to reduce links intersection.
            x = _x + index++ * ctrlGridStep * 0;
            const _y = +usedPositions[_x] || 0;
            y = ctrlGridStep + _y * (ctrlNodeHeight + ctrlGridStep);
            usedPositions[_x] = _y + 1;
            if (index === verticalLimit) {
              index = 0;
              _x += ctrlNodeWidth + ctrlGridStep;
            }
          } else {
            // Switches are placed horizontally by default
            x = 400 + (ctrlNodeWidth + ctrlGridStep) * usedPositions['']++;
            y = ctrlGridStep;
          }

          if (!node || !isGeneric || !switchNode?.pairOrder) {
            const uniqueLabel = multipleInstances ?
              this.pickUniqueLabel(label, hasRedundancy, switchesByName, true) :
              label;

            // A single generic node must be created for the switch pair, so
            // using the same node for the second peer
            node = Node.deserialize({...nodeData, 'node-type': ROLES_NODE_TYPES[role]}, this);
            node.fillWith({
              id,
              name: id,
              label: uniqueLabel,
              relativePosition: {x, y}
            });

            this.nodes = {...this.nodes, [node.id]: node};

            switchesByName[uniqueLabel] = [];
            (switchesByName[label] ??= []).push(node);
            if (hasRedundancy) {
              switchesByName[`${uniqueLabel}_1`] = [];
              switchesByName[`${uniqueLabel}_2`] = [];
            }

            if (isGeneric) {
              // Access switches generate generics, leafs - don't
              this.createLinksGroups(
                node,
                links,
                isGenericConnectedToAccess ? {[links[0].target_switch_label]: [switchNode]} : switchesByName
              );
            }
          }

          if (hasRedundancy) {
            this.createPeerNodeFor(node, redundancy);
            if (isLeaf) {
              this.createLeafPeerLinks(node, nodeData);
            } else {
              this.createAccessPeerLinks(node, nodeData);
            }
            usedPositions['']++;
          }

          if (!isGeneric) this.createLinksGroups(node, links, switchesByName);
        });
      }
    );
  }

  @action
  createLeafPeerLinks(node, nodeData) {
    const {
      redundancy_protocol: redundancy,
      leaf_leaf_l3_link_count: l3Count,
      leaf_leaf_l3_link_speed: l3Speed,
      leaf_leaf_l3_link_port_channel_id: portChannelL3,
      leaf_leaf_link_port_channel_id: portChannelL2,
      leaf_leaf_link_count: count,
      leaf_leaf_link_speed: speed
    } = nodeData;

    if (redundancy === REDUNDANCY.MLAG) {
      this.addLinksGroup(LinksGroup.createPeerLinks(node.name, l3Speed, l3Count, this, PEER_LINKS_TYPES.L3_PEER));
      this.addLinksGroup(LinksGroup.createPeerLinks(node.name, speed, count, this, PEER_LINKS_TYPES.L2_PEER));
      node.setProperty('portChannelId', portChannelL2, true);
      node.setProperty('portChannelIdExt', portChannelL3, true);
    }
  }

  @action
  createAccessPeerLinks(node, nodeData) {
    const {
      redundancy_protocol: redundancy,
      access_access_link_count: count,
      access_access_link_port_channel_id_min: portChannelMin,
      access_access_link_port_channel_id_max: portChannelMax,
      access_access_link_speed: speed
    } = nodeData;
    if (redundancy === REDUNDANCY.ESI) {
      this.addLinksGroup(LinksGroup.createPeerLinks(node.name, speed, count, this, PEER_LINKS_TYPES.L3_PEER));
      node.setProperty('portChannelId', portChannelMin, true);
      node.setProperty('portChannelIdExt', portChannelMax, true);
    }
  }

  @action
  createPeerNodeFor(node, redundancy = REDUNDANCY.NO_LAG, pair = node.clone()) {
    // Switch pair creation
    pair.fillWith({
      _label: node._label,
      name: node.name,
      pairedWith: node,
      relativePosition: {x: node.relativePosition.x + ctrlNodeWidth + ctrlGridStep, y: node.relativePosition.y}
    });
    node.isFirstInPair = true;
    this.nodes = {...this.nodes, ...{[pair.id]: pair}};
    node.pairedWith = pair;
    node.redundancyProtocol = redundancy;
    return pair;
  }

  @action
  createLinksGroups(fromNode, links, switchesByName) {
    // Return if no links data
    if (!links) return;

    // A single generic could either be connected to an access switch OR
    // to [different] leafs:
    forEach(links, (jsonValue) => {
      // Create links group
      const linksGroup = LinksGroup.deserialize({...jsonValue, node_label: fromNode.name}, this);
      const firstSwitch = first(switchesByName?.[linksGroup.toName]);
      if (firstSwitch) {
        linksGroup.fillWith({toName: firstSwitch.name});
        this.addLinksGroup(linksGroup);
      }
    });
  }

  @action
  parseNodes(data, role, switchesByName, preferences) {
    const usedPositions = {'': 0};
    forEach(data, (nodeData) => this.createNode(nodeData, role, usedPositions, switchesByName, preferences));

    this.calculateZonesSizes();
    this.updateArea();
  }

  @action
  updateArea() {
    this.positioner.update(values(this.nodes));
  }

  @action
  addNode(role) {
    // New node is added to generics zone by default
    const zone = this.zones[role];
    const node = new Node({
      id: generateLocalId(),
      label: this.pickUniqueLabel(role),
      zone,
      role: zone.role,
      relativePosition: {
        x: 200 + Math.random() * 100,
        y: ctrlGridStep + Math.random() * min([zone.size, 200])
      },
      _order: size(this.nodes) + 1,
    }, this);
    this.nodes = {...this.nodes, ...{[node.id]: node}};
    this.updateArea();

    this.changes.add(node);
    return node;
  }

  // Updates the item and returns corresponding Step
  #changeItem(item, update) {
    const step = Step.modification(null, item);
    item.fillWith(update);
    step.setResult(item);
    return step;
  }

  @action
  deleteNode(id, batchChange) {
    const node = this.nodes[id];
    let affectedNodesIds = [];

    // Collect all changes into one batch
    const change = batchChange || [];

    const linksAffected = filter(this.linksGroups, (linksGroup) => linksGroup.contains([node]));

    let linksIdsToBeRemoved = [];
    if (node.isPaired) {
      // Add pair for revalidation
      affectedNodesIds.push(node.pairedWith.id);

      // If node is paired, some of pairs existing links
      // must be validated (whether the remaining pair node
      // is capable of adopting them). Links must be removed if not.
      linksIdsToBeRemoved = transform(
        linksAffected,
        (acc, linksGroup) => {
          const {id, isPeer, isDualAttached, isBetweenPairs} = linksGroup;
          // All peer links are always deleted
          if (isPeer) {
            // Peer links must be deleted
            acc.push(id);
            change.push(Step.deletion(linksGroup));
          } else if (isBetweenPairs) {
            // Single-homed links between switch pairs must be modified
            if (!isDualAttached) {
              change.push(this.#changeItem(linksGroup, {
                isAttachedToFirst: !node.isFirstInPair,
                isAttachedToSecond: node.isFirstInPair
              }));
            }
          } else {
            // Links from pair to switch
            // if dual-attached - remove node's attachment
            change.push(this.#changeItem(linksGroup, {
              isAttachedToFirst: true,
              isAttachedToSecond: false
            }));
          }
        },
        []
      );

      // The reference to the node must be removed from the paired one along with all other
      // pairing properties
      change.push(this.#changeItem(
        node.pairedWith,
        {
          isFirstInPair: false,
          pairedWith: null,
          portChannelId: 0,
          portChannelIdExt: 0,
          redundancyProtocol: null
        })
      );
    } else {
      // Otherwise just remove all the links given node is a part of.
      linksIdsToBeRemoved = map(linksAffected, 'id');

      // Add links removal to the change
      forEach(linksAffected, (linksGroup) => {
        change.push(Step.deletion(linksGroup));
      });

      affectedNodesIds = without(
        uniq(
          transform(linksAffected, (acc, {fromName, toName}) => {
            acc.push(...this.nodesByName[fromName], ...this.nodesByName[toName]);
          }, [])
        ),
        id
      );
    }
    // Remove related links if any
    if (size(linksIdsToBeRemoved)) {
      this.linksGroups = omit(this.linksGroups, linksIdsToBeRemoved);
    }

    // Track node deletion
    change.push(Step.deletion(node));

    // Rebuild nodes collection
    this.nodes = omit(this.nodes, id);

    // Update the area
    this.updateArea();

    if (!batchChange) this.changes.register(change);
  }

  @action
  editNode(node) {
    // Toggles node properties popup
    this.editedNode = node?.id === this.editedNode?.id ? null : node;
  }

  // Checks if two nodes can have a link between them based on their roles and existing links
  getLinkingRoleRestrictionFor(sourceNode, destinationNode) {
    // If one of the nodes is not provided - no restrictions
    if (!sourceNode || !destinationNode) return;

    const firstLink = find(sourceNode?.myLinksGroups, {
      isPeer: false,
      isDraft: false,
      fromName: sourceNode.name
    });

    const isPeer = sourceNode === destinationNode || sourceNode.isPairedWith(destinationNode.id);

    if (sourceNode.isGeneric) {
      if (destinationNode.isGeneric) {
        return 'Generic systems cannot be linked';
      }
      if (firstLink) {
        if (firstLink.toRole !== destinationNode.role) {
          return 'Generic system cannot be linked to Leaf and Access switch at the same time';
        } else if (destinationNode.isAccessSwitch && destinationNode.name !== firstLink.toName) {
          return 'Generic system cannot be linked with different Access switches';
        }
      } else {
        // Generic has no links - it can be connected to any switch with
        // sufficient ports/roles
        return;
      }
    } else if (!isPeer) {
      if (sourceNode.isPaired && destinationNode.isMlagLeafPair) {
        // Forbidden to create links between pairs with different redundancy
        return 'Unable to create links between switch pairs with different redundancy protocols (ESI ↔ MLAG)';
      } else if (firstLink) {
        // Source node is Access switch with existing link to Leaf
        return 'Access switch can only have one logical link to Leaf';
      }
    }
  }

  @action
  cloneSelected(selectedNodesIds, withoutLinks, registerChanges = true) {
    // Clones selected nodes on the canvas
    const selectedNodes = map(selectedNodesIds, (nodeId) => this.nodes[nodeId]);
    const batchChange = [];

    // Clone nodes
    const newNodesIds = [];
    const nodeNamesMap = transform(
      selectedNodes,
      (acc, node) => {
        const newNode = node.clone();
        newNodesIds.push(newNode.id);

        // Cloned node positioning: single generic is always cloned below its origin,
        // everything else gets cloned one position right
        if (newNode.isGeneric && size(selectedNodesIds) === 1) {
          // Generic get cloned below the origin
          newNode.moveBy({x: 0, y: ctrlNodeHeight + ctrlGridStep});
        } else {
          // ... while switches - on the right
          newNode.moveBy({x: ctrlNodeWidth + ctrlGridStep, y: 0});
        }

        // Check if cloned node is a switch-pair
        if (node.isPaired) {
          // Check if cloned yet
          const clonedPair = first(this.nodesByName?.[acc[node.pairedWith?.name]]);
          if (clonedPair) {
            // ... and pair clones too
            newNode.pairWith(clonedPair);
            newNode.isFirstInPair = node.isFirstInPair;
            clonedPair.isFirstInPair = !node.isFirstInPair;
            clonedPair.redundancyProtocol = node.redundancyProtocol;
            newNode.name = clonedPair.name;
            newNode._label = clonedPair._label;

            // Cloned pair might not be a part of cloning set - so, redundancy protocol
            // and pairing must only be set if both part of a pair are being cloned
            const cloneStep = find(batchChange, {id: clonedPair.id});
            cloneStep?.setResult(clonedPair);
          }
        }

        acc[node.name] = newNode.name;

        this.nodes = {...this.nodes, ...{[newNode.id]: newNode}};
        batchChange.push(Step.creation(newNode));
      },
      {}
    );

    // Links should not be cloned for split switch pairs
    const nodesWithoutSplitPairs = filter(selectedNodes, ({isPaired, pairedWith}) => (
      !isPaired || selectedNodesIds.includes(pairedWith?.id)
    ));

    if (!withoutLinks) {
      // Clone links groups
      forEach(
        filter(
          values(this.linksGroups),
          (link) => link.contains(nodesWithoutSplitPairs)
        ),
        (linksGroup) => {
          // Checking if linksGroup cloning is technically possible
          const [sourceNode, destinationNode] = [
            first(this.nodesByName[nodeNamesMap?.[linksGroup.fromName] ?? linksGroup.fromName]),
            first(this.nodesByName[nodeNamesMap?.[linksGroup.toName] ?? linksGroup.toName]),
          ];
          const restriction = this.getLinkingRoleRestrictionFor(sourceNode, destinationNode);
          if (!restriction) {
            const clone = linksGroup.cloneFor(nodeNamesMap);
            clone.label = makeUniqueNodeLabel(this.linksGroups, clone.label, true);

            batchChange.push(Step.creation(clone));
            this.addLinksGroup(clone);
          }
        }
      );
    }

    this.updateArea();
    if (registerChanges) this.changes.register(batchChange);

    return newNodesIds;
  }

  @action
  deleteNodes(selectedNodesIds) {
    const batchChange = [];
    forEach(selectedNodesIds, (id) => {
      this.deleteNode(id, batchChange);
    });
    this.updateArea();
    this.changes.register(batchChange);
  }

  @action
  applyChange(change) {
    // Applies change from the history (undo/redo operations)
    if (!change?.length) return [];

    // For each step in the change
    const affectedNodesIds = transform(change, (acc, step) => {
      const collectionName = collections[step.type];
      const collection = this[collectionName];

      // Preserving the target item
      let item = collection?.[step.id] ?? {};
      if (step.isDelete) {
        this[collectionName] = omit(collection, step.id);
      } else {
        item = (entities[step.type]).deserialize(step.item, this);
        // Update the item + rebuild the collection
        this[collectionName] = {...collection, [item.id]: item};
        if (step.isNode) {
          acc.push(item.id);
        }
      }
    }, []);
    this.updateArea();
    // Returns Ids of affected nodes for selection
    return affectedNodesIds;
  }

  @action
  addLinksGroup(linksGroup) {
    this.linksGroups = {...this.linksGroups, ...{[linksGroup.id]: linksGroup}};
  }

  @action
  commitLinksGroup() {
    const linksGroup = this.linksGroups[draftLinksGroupId];
    if (linksGroup) {
      const id = generateLocalId();
      linksGroup.id = id;
      this.openedLinksGroupId = id;
      this.linksGroups = {
        ...omit(this.linksGroups, draftLinksGroupId),
        [id]: linksGroup
      };
      this.changes.add(linksGroup);
    }
  }

  @action
  deleteLinksGroup(id) {
    this.linksGroups = omit(this.linksGroups, id);
  }

  canLinkNodes(nodes, linksGroup, forcedCount) {
    return every(nodes, (node) => node.canConnectTo(linksGroup, forcedCount));
  }

  @action
  undo() {
    return this.applyChange(this.changes.undo());
  }

  @action
  redo() {
    return this.applyChange(this.changes.redo());
  }

  @computed
  get isValid() {
    return this.name && every(this.nodes, 'isValid');
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    this.changes = new ChangesStore(rootStore);
  }

  makePayload() {
    return toJS({
      id: this.id,
      display_name: this.name,
      description: this.description,
      fabric_connectivity_design: this.design,
      tags: this.#serializeTags(),
      leafs: this.#serializeLeafs(),
      access_switches: this.#serializeAccessSwitches(),
      generic_systems: this.#serializeGenerics(),
      logical_devices: this.#serializeLogicalDevices(),
      servers: [],
      preferences: this.#gatherPreferences()
    });
  }

  #serializeTags() {
    return map(this.usedTags, (label) => ({label, description: ''}));
  }

  #serializeLeafs() {
    return map(
      filter(this.nodes, {isLeaf: true, isFirstInPair: false}),
      (node) => {
        const peerLinks = groupBy(
          filter(
            this.linksGroupsByName[node.name],
            {isPeer: true}
          ),
          'peeringType'
        );

        const l2Peers = first(peerLinks?.[PEER_LINKS_TYPES.L2_PEER]) || noLinks;
        const l3Peers = first(peerLinks?.[PEER_LINKS_TYPES.L3_PEER]) || noLinks;

        return toJS({
          ...node.serialize(false),

          leaf_leaf_link_count: l2Peers.count,
          leaf_leaf_link_speed: l2Peers.count ? l2Peers.speed : null,

          leaf_leaf_l3_link_count: l3Peers.count,
          leaf_leaf_l3_link_speed: l3Peers.count ? l3Peers.speed : null
        });
      }
    );
  }

  #serializeAccessSwitches() {
    return map(
      filter(this.nodes, {isAccessSwitch: true, isFirstInPair: false}),
      (node) => {
        const peerLinks = groupBy(
          filter(
            this.linksGroupsByName[node.name],
            {isPeer: true}
          ),
          'peeringType'
        );

        const l3Peers = first(peerLinks?.[PEER_LINKS_TYPES.L3_PEER]) || noLinks;

        return toJS({
          ...node.serialize(false),
          access_access_link_count: l3Peers.count,
          access_access_link_speed: l3Peers.count ? l3Peers.speed : null,
          instance_count: 1,

          links: this.#serializeLinks(node.name, true)
        });
      }
    );
  }

  #serializeGenerics() {
    return map(
      filter(this.nodes, {isGeneric: true}),
      (node) => {
        return toJS({
          ...node.serialize(false),
          loopback: 'disabled',
          asn_domain: 'disabled',
          management_level: 'unmanaged',
          count: 1,

          links: this.#serializeLinks(node.name)
        });
      }
    );
  }

  #serializeLinks(fromName) {
    const links = filter(
      this.linksGroupsByName[fromName],
      {fromName, isPeer: false}
    );

    return map(links, (linkGroup) => linkGroup.serialize(false));
  }

  #serializeLogicalDevices() {
    return map(
      compact(uniq(map(this.nodes, ({logicalDevice}) => logicalDevice?.id))),
      (logicalDeviceId) => toJS(omit(this.devices[logicalDeviceId], 'custom'))
    );
  }

  #gatherPreferences() {
    return {
      positions: transform(
        this.nodes,
        (acc, {_label, isPaired, isFirstInPair, relativePosition: {x, y}}) => {
          if (!isPaired || isFirstInPair) acc.push({label: _label, x, y});
        },
        [])
    };
  }

  @action
  setError(header, content, asJson) {
    this.setProperty(
      'error',
      header ?
        <>
          <Message.Header>{header}</Message.Header>
          <Message.Content>{asJson ?
            <pre>{JSON.stringify(content, null, 2)}</pre> :
            content}
          </Message.Content>
        </> : null
    );
  }

  @action
  async submit(onClose) {
    this.isSaving = true;
    this.setError();
    try {
      // eslint-disable-next-line
      await request(
        `/api/design/rack-types/${this.id || ''}`,
        {
          method: this.id ? 'PUT' : 'POST',
          body: JSON.stringify(this.makePayload())
        }
      );
      onClose();
    } catch (e) {
      this.setError(
        'Saving Error',
        e.responseBody?.errors ?? e.responseBody,
        true
      );
      this.isSaving = false;
    }
  }
}

export default RackStore;
