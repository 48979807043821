import {observer} from 'mobx-react';
import {useCallback} from 'react';
import {Field} from 'apstra-ui-common';

import {maxPortChannelId} from '../const';
import './PortChannelIdRange.less';
import NumberInput from './NumberInput';

const PortChannelIdRange = ({node, disabled, asRange, isExt, onChange, error, applyToBoth}) => {
  const {firstLabel, secondLabel, setValue} = usePortChannelIdRange(asRange, onChange, applyToBoth);

  return (
    <>
      {
        (asRange || !isExt) &&
          <Field label={firstLabel} disabled={disabled} className='re-port-channel-id'>
            <NumberInput
              type='number'
              size='small'
              fluid
              value={node?.portChannelId || 0}
              min={0}
              max={maxPortChannelId}
              onChange={(event, {value}) => setValue(node, value)}
              error={error}
            />
          </Field>
      }
      {
        (asRange || isExt) &&
          <Field label={secondLabel} disabled={disabled} className='re-port-channel-id'>
            <NumberInput
              type='number'
              size='small'
              fluid
              value={node?.portChannelIdExt || 0}
              min={0}
              max={maxPortChannelId}
              onChange={(event, {value}) => setValue(node, value, true)}
              error={error}
            />
          </Field>
      }
    </>
  );
};

export default observer(PortChannelIdRange);

const usePortChannelIdRange = (asRange, onChange, applyToBoth) => {
  const firstLabel = asRange ? 'Min' : 'Port Channel ID';
  const secondLabel = asRange ? 'Max' : 'Port Channel ID';

  const setValue = useCallback(
    (node, value, isExt) => {
      if (!node) return;

      const validValue = +value < 0 ? 0 : (
        +value > maxPortChannelId ? maxPortChannelId : +value
      );

      const [primaryProp, secondaryProp] = isExt ?
        ['portChannelIdExt', 'portChannelId'] : ['portChannelId', 'portChannelIdExt'];
      const secondaryValue = node?.[secondaryProp];
      const isSecondAffected = applyToBoth || (
        asRange &&
        (
          (!validValue !== !secondaryValue) ||
          (isExt ? secondaryValue > validValue : secondaryValue < validValue)
        )
      );

      if (onChange) {
        onChange(primaryProp, validValue);
        if (isSecondAffected) onChange(secondaryProp, validValue > 0 ? +value : 0);
      } else {
        node.setProperty(primaryProp, validValue, true);
        if (isSecondAffected) node.setProperty(secondaryProp, validValue > 0 ? +value : 0, true);
      }
    },
    [asRange, onChange, applyToBoth]
  );

  return {firstLabel, secondLabel, setValue};
};
