import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Message} from 'semantic-ui-react';
import {isEmpty, isFunction, pick} from 'lodash';

import {NeighborsView} from './components/NeighborsView';
import {
  NODE_ALIGN,
  STATUS, DEFAULT_STATUS,
  STATE, DEFAULT_STATE,
  NOT_AVAILABLE
} from './constants';
import {
  nodePropTypes, cablingMapItemPropTypes, lldpTelemetryItemPropTypes, interfaceTelemetryItemPropTypes
} from './shared-prop-types';

export class NeighborsBase extends PureComponent {
  static propTypes = {
    blueprintId: PropTypes.string,
    nodeId: PropTypes.string,
    nodes: PropTypes.arrayOf(PropTypes.shape(nodePropTypes)),
    cablingMap: PropTypes.arrayOf(PropTypes.shape(cablingMapItemPropTypes)),
    lldpTelemetry: PropTypes.arrayOf(PropTypes.shape(lldpTelemetryItemPropTypes)),
    interfaceTelemetry: PropTypes.arrayOf(PropTypes.shape(interfaceTelemetryItemPropTypes)),

    neighborsRoleFilter: PropTypes.string,
    labelKey: PropTypes.string,

    options: PropTypes.shape({
      chartWidth: PropTypes.number,

      nodeDimensions: PropTypes.shape({
        width: PropTypes.number,
        marginBottom: PropTypes.number
      }),

      interfaceDimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        marginBottom: PropTypes.number
      }),

      aggregateLinkDimensions: PropTypes.shape({
        leftMargin: PropTypes.number,
        rx: PropTypes.number,
      })
    }),

    styles: PropTypes.object,

    onNeighborsRoleFilterChange: PropTypes.func,
    onClickNode: PropTypes.func,
    processGraphData: PropTypes.func,
    onShowUnusedPortsChange: PropTypes.func,
    showUnusedPorts: PropTypes.bool,
    showUnusedPortsAvailable: PropTypes.bool,
  };

  static defaultProps = {
    options: {
      chartWidth: 812,
      nodeDimensions: {
        width: 240,
        marginBottom: 6
      },
      interfaceDimensions: {
        width: 90,
        height: 23,
        marginBottom: 6
      },
      aggregateLinkDimensions: {
        leftMargin: 6,
        rx: 5
      }
    },
    styles: {margin: '0 auto'}
  };

  getValidStatusOrDefault = (status) => {
    const isValid = Object.values(STATUS).includes(status);

    return isValid
      ? status
      : DEFAULT_STATUS;
  };

  getValidStateOrDefault = (state) => {
    const isValid = Object.values(STATE).includes(state);

    return isValid
      ? state
      : DEFAULT_STATE;
  };

  getInterfaceInfo = (interf, node, link) => {
    return {
      label: interf.label ?? NOT_AVAILABLE,
      ipv4Addr: interf.ipv4_addr,
      ipv6Addr: interf.ipv6_addr,
      nodeSystemId: node.system_id,
      operation_state: interf.operation_state,
      tags: interf.tags,
      nodeId: node.id,
      interfaceId: interf.id,
      speed: link?.speed,
      mapping: interf.mapping,
      CT: interf.CT,
    };
  };

  getInterfacesInfo = (node, neighbors, link) => {
    const nodeInterface = node.interfaces.find((interf) => interf.id === link.node_interface_id);
    if (!nodeInterface) {
      throw new Error(`Can't find node interface with id=${link.node_interface_id}`);
    }

    const neighbor = neighbors.find((neighbor) => neighbor.id === link.neighbor_id);
    if (!neighbor) {
      throw new Error(`Can't find neighbor with id=${link.neighbor_id}`);
    }

    const neighborInterface = neighbor.interfaces.find((interf) => interf.id === link.neighbor_interface_id);
    if (!neighborInterface) {
      throw new Error(`Can't find neighbor interface with id=${link.neighbor_interface_id}`);
    }

    return {
      nodeInterfaceInfo: this.getInterfaceInfo(nodeInterface, node, link),
      neighborInterfaceInfo: this.getInterfaceInfo(neighborInterface, neighbor, link),
    };
  };

  getNodeCoordsMap = (interfaces, interfaceDimensions, initialOffset = 0) => {
    const interfaceMargin = interfaceDimensions.marginBottom;
    const interfaceHeight = interfaceDimensions.height;

    return interfaces.reduce((acc, cur, i) => {
      const previousMarginsCount = i + 1;

      const interfaceTopOffset = initialOffset + (previousMarginsCount * interfaceMargin) + (i * interfaceHeight);

      acc[cur.id] = interfaceTopOffset + (interfaceHeight / 2);
      return acc;
    }, {});
  };

  getPreviousNodesHeightWithMargins = (previousNodes, nodeMargin, interfaceDimensions) => {
    const interfaceMargin = interfaceDimensions.marginBottom;
    const interfaceHeight = interfaceDimensions.height;

    const previousNodesHeight = previousNodes
      .map((node) => {
        const interfacesCount = node.interfaces.length;
        const interfacesHeight = (interfacesCount * interfaceHeight);
        const marginsHeight = (interfacesCount + 1) * interfaceMargin;

        return interfacesHeight + marginsHeight;
      })
      .reduce((acc, cur) => acc + cur);

    const margins = previousNodes.length * nodeMargin;

    return previousNodesHeight + margins;
  };

  getNeighborsCoordsMap = (neighbors, nodeMargin, interfaceDimensions) => {
    return neighbors.reduce((acc, cur, i) => {
      const nodeTopOffset = (i > 0)
        ? this.getPreviousNodesHeightWithMargins(neighbors.slice(0, i), nodeMargin, interfaceDimensions)
        : 0;

      acc[cur.id] = this.getNodeCoordsMap(cur.interfaces, interfaceDimensions, nodeTopOffset);
      return acc;
    }, {});
  };

  getLinksProps = (links, node, neighbors, linksWidth, nodeDimensions, interfaceDimensions) => {
    const nodeMargin = nodeDimensions.marginBottom;

    const nodeCoordsMap = this.getNodeCoordsMap(node.interfaces, interfaceDimensions);
    const neighborsCoordsMap = this.getNeighborsCoordsMap(
      neighbors,
      nodeMargin,
      interfaceDimensions
    );

    const maxX = linksWidth;

    return links.map((link) => {
      const state = this.getValidStateOrDefault(link.state);
      const status = this.getValidStatusOrDefault(link.status);

      const nodeY = nodeCoordsMap[link.node_interface_id];
      if (isNaN(parseFloat(nodeY))) {
        throw new Error(`Can't find nodeY for link=${link}`);
      }

      const neighborData = neighborsCoordsMap[link.neighbor_id];
      if (!neighborData) {
        throw new Error(`Can't find neighborData for link=${link}`);
      }

      const neighborY = neighborData[link.neighbor_interface_id];
      if (isNaN(parseFloat(neighborY))) {
        throw new Error(`Can't find neighborY for link=${link}`);
      }

      const nodeCoords = {
        x: 0,
        y: nodeY
      };

      const neighborCoords = {
        x: maxX,
        y: neighborY
      };

      const midX = maxX / 2;

      const coordinates = [
        nodeCoords,
        {
          x: midX,
          y: nodeY
        },
        {
          x: midX,
          y: neighborY
        },
        neighborCoords
      ];

      const {nodeInterfaceInfo, neighborInterfaceInfo} = this.getInterfacesInfo(node, neighbors, link);

      return {
        state,
        status,
        coordinates,
        nodeInterfaceInfo,
        neighborInterfaceInfo,
        sourceLink: link.sourceLink,
      };
    });
  };

  getAggregateLinksProps = (aggregateLinks, aggregateLinkDimensions, interfaceDimensions) => {
    const {leftMargin, rx} = aggregateLinkDimensions;
    const {marginBottom, height} = interfaceDimensions;

    let previousLinksCount = 0;
    return aggregateLinks.map((aggregateLink) => {
      const {linkIds} = aggregateLink;

      const linksCount = linkIds.length;
      const isFirst = previousLinksCount === 0;

      const internalMargins = (linksCount - 1) * marginBottom;
      const linksHeight = linksCount * height;
      const ry = (linksHeight + internalMargins) / 2;

      const cx = leftMargin + rx;

      const marginTop = isFirst ? marginBottom : 0;
      const previousLinksHeight = (previousLinksCount * (height + marginBottom)) + (isFirst ? 0 : marginBottom);
      const topOffset = marginTop + previousLinksHeight;
      const cy = ry + topOffset;

      previousLinksCount += linksCount;

      return {cx, cy, rx, ry,
        ...pick(aggregateLink, ['ct', 'lagMode', 'portChannelId',
          'interfaceName', 'interfaceTags', 'id', 'linkIds', 'tags'])};
    });
  };

  getInterfacesProps = (interfaces, interfaceDimensions, node) => {
    const {marginBottom, height} = interfaceDimensions;

    return interfaces.map((interf, i) => {
      const {label, ipv4Addr, ipv6Addr, CT, mapping, operation_state: operationState, tags} =
        this.getInterfaceInfo(interf, node);

      const state = this.getValidStateOrDefault(interf.state);
      const status = this.getValidStatusOrDefault(interf.status);

      const isFirst = i === 0;

      return {
        height,
        marginBottom,
        marginTop: isFirst ? marginBottom : null,
        mapping,
        operation_state: operationState,
        label,
        state,
        tags,
        status,
        ipv4Addr,
        ipv6Addr,
        id: interf.id,
        CT,
      };
    });
  };

  hasOnClickNodeHandler = () => {
    return !!this.props.onClickNode;
  };

  onClickNode = (id) => {
    if (this.hasOnClickNodeHandler()) {
      const clickedNode = this.props.nodes.find((node) => node.id === id);
      this.props.onClickNode(clickedNode);
    }
  };

  getNodeProps = (node, align, interfaceDimensions, nodeDimensions) => {
    const {hasOnClickNodeHandler, onClickNode} = this;
    const {id, label, hostname, interfaces, notInCablingMap, sourceNode} = node;

    const status = this.getValidStatusOrDefault(node.status);

    return {
      marginBottom: nodeDimensions.marginBottom,
      nodeWidth: nodeDimensions.width,
      interfaceWidth: interfaceDimensions.width,
      align,

      id,
      label,
      hostname,
      status,
      notInCablingMap,
      interfaces: this.getInterfacesProps(interfaces, interfaceDimensions, node),

      hasOnClickNodeHandler: hasOnClickNodeHandler(),
      onClickNode,
      sourceNode
    };
  };

  getChartProps = (props, data) => {
    const {
      options, styles, showUnusedPortsAvailable,
      neighborsRoleFilter, onNeighborsRoleFilterChange,
      showUnusedPorts, onShowUnusedPortsChange,
      showAggregateLinks, onShowAggregateLinksChange, blueprintId,
      interfaceSelection, onSelectInterface, linkSelection, highlightedInterfaces,
      onSelectNode, nodeSelected, onSelectAggregate, NeighborsBase,
      unusedInterfaceSelection
    } = props;
    const {chartWidth, nodeDimensions, interfaceDimensions, aggregateLinkDimensions} = options;
    const {links, neighbors, neighborsRoles, node, aggregateLinks} = data;

    const nodeWidth = nodeDimensions.width;
    const linksWidth = chartWidth - (nodeWidth * 2);

    return {
      blueprintId,
      styles,
      chartWidth,
      linksWidth,
      nodeWidth,

      neighborsRoleFilter,
      neighborsRoles,
      onNeighborsRoleFilterChange,

      showUnusedPortsAvailable,
      showUnusedPorts,
      onShowUnusedPortsChange,

      showAggregateLinksAvailable: !isEmpty(aggregateLinks),
      showAggregateLinks,
      onShowAggregateLinksChange,

      linkSelection,
      interfaceSelection,
      unusedInterfaceSelection,
      onSelectInterface,
      highlightedInterfaces,
      onSelectNode,
      nodeSelected,
      onSelectAggregate,

      NeighborsBase,
      links: this.getLinksProps(links, node, neighbors, linksWidth, nodeDimensions, interfaceDimensions),
      neighbors: neighbors.map((node) =>
        this.getNodeProps(node, NODE_ALIGN.RIGHT, interfaceDimensions, nodeDimensions)
      ),
      node: this.getNodeProps(node, NODE_ALIGN.LEFT, interfaceDimensions, nodeDimensions),
      aggregateLinks: this.getAggregateLinksProps(aggregateLinks, aggregateLinkDimensions, interfaceDimensions)
    };
  };

  render() {
    const {className, processGraphData, data, nodeMenuOpen} = this.props;
    if (!data.node) {
      return <Message
        error
        header='Error'
        content={`Node with id ${this.props.nodeId} not found`}
      />;
    }

    const chartProps = this.getChartProps(this.props, data);
    if (isFunction(processGraphData)) processGraphData(chartProps);

    return (
      <NeighborsView
        className={className}
        nodeMenuOpen={nodeMenuOpen}
        {...chartProps}
      />
    );
  }
}
