import {size} from 'lodash';
import {observer} from 'mobx-react';
import {Field} from 'apstra-ui-common';

import PortChannelIdRange from './PortChannelIdRange';
import PortsCount from './PortsCount';
import NumberInput from './NumberInput';

import './PeerLinkForm.less';

const PeerLinker = ({
  title, portsSpeeds, node, link, required, asPortChannelRange, isExt, error, applyToBoth,
  trackChange
}) => {
  const {count = 0, speedString} = link;
  const portsBySpeed = size(portsSpeeds) ? {...portsSpeeds} : {[speedString]: 0};

  const portsAvailable = portsSpeeds?.[speedString] ?? 0;
  const maxCount = portsAvailable + count;

  const noSpeed = !speedString || (!count && !maxCount);

  return (
    <div className='re-peer-linker'>
      <div className='re-peer-links'>
        <h6 className={required && 'required'}>{title}</h6>
        <div className='re-inputs'>
          <PortsCount
            portsBySpeed={portsBySpeed}
            selectedSpeed={noSpeed ? null : speedString}
            onClick={(speed) => {
              trackChange('speed', speed, link);
              trackChange('count', 1, link);
            }}
            error={required && noSpeed}
            showEmpty
            usedCount={count}
          />
          <Field label='Count' disabled={noSpeed} required={required} className='count'>
            <NumberInput
              type='number'
              size='small'
              fluid
              value={count}
              min={0}
              max={maxCount}
              onChange={(event, {value}) => trackChange('count', value, link)}
              error={required && !count}
            />
          </Field>
        </div>
      </div>
      <div className='re-port-channels'>
        <h6 className={asPortChannelRange ? '' : 'none'}>{'Port Channel ID'}</h6>
        <div className='re-inputs'>
          {
            <PortChannelIdRange
              node={node}
              disabled={!count}
              asRange={asPortChannelRange}
              isExt={isExt}
              error={!!error}
              applyToBoth={applyToBoth || asPortChannelRange}
              onChange={(property, value) => trackChange(property, value, node)}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default observer(PeerLinker);
