import React from 'react';
import {Label} from 'semantic-ui-react';

type ConfigTemplateProps = {
  configTemplate: {
    label: string;
  }
};

export const ConfigTemplate: React.FC<ConfigTemplateProps> = ({configTemplate}) => {
  return configTemplate.label ?
    <>{configTemplate.label}</> : <Label color='red' content='Not assigned' />;
};
