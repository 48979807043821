import {Component, Fragment} from 'react';
import {scaleLinear, scaleBand} from 'd3';
import {observer} from 'mobx-react';
import {Group} from '@visx/group';
import {Axis} from '@visx/axis';
import {GridRows} from '@visx/grid';
import {Popup} from 'semantic-ui-react';
import {map, constant, last, head, get} from 'lodash';
import {withResizeDetector} from 'react-resize-detector';

import './UtilizationChart.less';
@observer
class UtilizationChart extends Component {
  static defaultProps = {
    units: '',
    minHeight: 2,
    margin: {top: 30, right: 100, bottom: 190, left: 50},
    legendOffset: {x: 25, y: 5},
    widthToHeightRatio: 1.3,
    width: 0
  };

  xScale = scaleBand();
  yScale = scaleLinear();

  formatValue = (value) => {
    const {units, roundFormatter} = this.props;
    const result = value.toFixed(1);
    return (roundFormatter ? Math.round(result) : result) + units;
  };

  renderBars = ({yMax, legendKey, barWidth}) => {
    const {
      xScale, yScale, formatValue,
      props: {
        margin, minHeight, samples: samplesWithMaxUsage
      }
    } = this;

    const {samples} = samplesWithMaxUsage;

    return map(samples, (sample, sampleIndex) => {
      const barY = yScale(sample.used);
      const barHeight = Math.max(yMax - barY, minHeight);
      const x = xScale(sample.consumer) + margin.left;
      const y = yMax - barHeight;
      return (
        <Fragment key={`bar ${sample.consumer}-${sampleIndex}`}>
          <defs>
            <clipPath id={`round-corner-${legendKey}-${sampleIndex}`}>
              <rect x={x} y={y} width={barWidth} height={barHeight + 10} rx='10' />
            </clipPath>
          </defs>
          <defs>
            <linearGradient id='bar-gradient' x1='0%' y1='0' x2='0' y2='100%'>
              <stop className='first-stop' offset='0%' />
              <stop className='second-stop' offset='100%' />
            </linearGradient>
          </defs>
          <Popup
            key={`bar ${sample.consumer}-${sampleIndex}`}
            content={formatValue(sample.used)}
            position='top center'
            inverted
            trigger={
              <rect
                className='utilization-chart-bar'
                x={x}
                y={y}
                width={barWidth}
                height={barHeight}
                clipPath={`url(#round-corner-${legendKey}-${sampleIndex})`}
                fill='url(#bar-gradient)'
              />
            }
          />
        </Fragment>
      );
    });
  };

  render() {
    const {
      xScale, yScale, renderBars,
      props: {
        margin, minHeight, width,
        samples: samplesWithMaxUsage, domainRange, units, legendText,
        legendOffset, widthToHeightRatio, targetRef,
      }
    } = this;
    const {samples} = samplesWithMaxUsage;
    const legendKey = head(Object.keys(legendText));

    const chartWidth = Math.floor(width);
    const chartHeight = Math.floor(width) / widthToHeightRatio;
    const xMax = chartWidth - margin.left - margin.right;
    const yMax = chartHeight - margin.top - margin.bottom;

    yScale.domain(domainRange).rangeRound([yMax, 0]).nice();
    xScale.domain(map(samples, 'consumer')).rangeRound([0, xMax]).padding(0.1);
    const barWidth = xScale.bandwidth();
    const legendLabel = head(get(legendText, legendKey));
    const legendLineLength = barWidth / 1.5;
    const legendX = chartWidth - margin.right + legendLineLength / 3;
    const lastBarHeight = Math.max(yMax - yScale(last(samples).used), minHeight);
    const legendY = yScale(last(samples).used) + margin.top + lastBarHeight / 2;

    return (
      <div ref={targetRef}>
        <svg className='utilization-chart' width={chartWidth} height={chartHeight}>
          <GridRows
            className='utilization-chart-grid'
            scale={yScale}
            top={margin.top}
            left={margin.right}
            width={xMax}
          />
          <Group top={margin.top} left={margin.left}>
            {renderBars({yMax, legendKey, barWidth})}
            <Axis
              hideTicks
              hideAxisLine
              axisClassName='utilization-axis-left'
              orientation='left'
              scale={yScale}
              tickFormat={(value) => (value += units)}
              tickLabelProps={constant({dx: '-0.3em', dy: '0.25em'})}
              left={margin.left / 2}
            />
            <Axis
              hideTicks
              hideAxisLine
              axisClassName='utilization-axis-bottom'
              orientation='bottom'
              scale={xScale}
              top={yMax}
              left={margin.left}
              tickLabelProps={constant({
                angle: -90,
                dy: '0.5em',
                dx: '0.25em'
              })}
            />
          </Group>
          {legendText &&
            <Group className='utilization-legend'>
              <line x1={legendX} x2={legendX + legendLineLength} y1={legendY} y2={legendY} />
              <text x={legendX + legendOffset.x} y={legendY - legendOffset.y}>{legendLabel}</text>
            </Group>
          }
        </svg>
      </div>
    );
  }
}

export default withResizeDetector(UtilizationChart, {handleWidth: true});
