import {createRoot} from 'react-dom/client';
import {Icon, Message, Popup, Progress, Table} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {isEmpty, map} from 'lodash';
import {formatNumber} from 'apstra-ui-common';

import uploadStore from '../uploadStore';

import './UploadNotifier.less';

let notifierContainer = null;
function getNotifierContainer() {
  if (notifierContainer) return notifierContainer;
  notifierContainer = document.createElement('div');
  document.body.appendChild(notifierContainer);
  return notifierContainer;
}

let notifierRoot = null;
export function initUploadNotifier() {
  if (notifierRoot) return notifierRoot;
  notifierRoot = createRoot(getNotifierContainer());
  notifierRoot.render(<UploadNotifier />);
  return notifierRoot;
}

const formatBytesNumber = (size) => formatNumber(size, {units: 'B', short: true});

const UploadNotifier = observer(() => {
  return (
    <div className='upload-notifier'>
      {!isEmpty(uploadStore.items) && (
        <Message className='notification'>
          <Message.Content>
            <Message.Header>{'Uploads'}</Message.Header>
            <Table basic='very'>
              <Table.Body>
                {map(uploadStore.items, (item, id) =>
                  <UploadItem key={id} id={id} item={item} />
                )}
              </Table.Body>
            </Table>
          </Message.Content>
        </Message>
      )}
    </div>
  );
});

const UploadItem = observer(({id, item}) => {
  const {payload: {image}, onCancelRequest, error, UploadErrorComponent = UploadError} = item;
  const StatusComponent = error ? UploadErrorComponent : UploadProgress;
  return (
    <Table.Row>
      <Table.Cell className='name' collapsing>
        <Popup
          content={
            <>
              <div><b>{'Name: '}</b>{image.name ?? ''}</div>
              <div><b>{'Size: '}</b>{formatBytesNumber(image.size)}</div>
            </>
          }
          trigger={<div>{image.name ?? ''}</div>}
        />
      </Table.Cell>
      <Table.Cell className='status'>
        <StatusComponent item={item} />
      </Table.Cell>
      <Table.Cell collapsing>
        <Icon
          name='delete'
          link
          onClick={() => {
            if (!error) {
              if (onCancelRequest) onCancelRequest();
            } else {
              uploadStore.deleteUploadItem(id);
            }
          }}
          aria-label='Delete'
        />
      </Table.Cell>
    </Table.Row>
  );
});

const UploadProgress = observer(({item: {uploadProgress, progressLabel}}) => {
  const {progress = 0, loaded = 0, total = 0} = uploadProgress ?? {};
  return (
    <Popup
      content={`${formatBytesNumber(loaded)} / ${formatBytesNumber(total)}`}
      disabled={!loaded || !total || progress === 100}
      trigger={
        <Progress
          key={progressLabel ?? 'progress'}
          size='small'
          percent={progress}
          color={progress < 100 ? 'blue' : 'green'}
          label={progressLabel}
          progress={progress < 100}
          active
        />
      }
    />
  );
});

export const UploadError = observer(({item: {error}}) => {
  const message = error.toMessage?.()?.content ?? error.message ?? 'Error';
  return (
    <span className='error'>
      <Icon name='warning circle' color='red' />
      <span className='text'>{message}</span>
    </span>
  );
});
