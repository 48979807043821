import {Fragment} from 'react';
import {isNil} from 'lodash';

export default function RangeValue({min, equals, max, strictMode}) {
  const hasMin = !isNil(min);
  const hasEquals = !isNil(equals);
  const hasMax = !isNil(max);

  if (hasMin && hasMax) {
    return <Fragment key={`${min} - ${max}`}>{min}<strong>{' — '}</strong>{max}</Fragment>;
  } else if (hasMin && !hasMax) {
    return <Fragment key={`> ${min}`}><strong>{strictMode ? '> ' : '≥ '}</strong>{min}</Fragment>;
  } else if (!hasMin && hasMax) {
    return <Fragment key={`< ${max}`}><strong>{strictMode ? '< ' : '≤ '}</strong>{max}</Fragment>;
  } else if (hasEquals) {
    return <Fragment key={`= ${equals}`}><strong>{'= '}</strong>{equals}</Fragment>;
  } else {
    return null;
  }
}
