import {first, intersection, isEmpty, map, range, size, sortBy} from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';

import {stringFromSpeed} from '../utils';
import {PORT_ROLES} from '../const';

class Port {
  @observable roles = [];
  @observable speed = {};
  @observable isTaken = false;

  @observable.ref origin = null;

  @computed
  get rolesCount() {
    return size(this.roles);
  }

  @computed
  get rolesList() {
    return sortBy(this.roles).join(', ');
  }

  @computed
  get toString() {
    return `[${this.isTaken ? '✘' : '✓'}${this.speedString}:${this.rolesList}]`;
  }

  @computed
  get summary() {
    return `${this.speedString}:${this.rolesList}`;
  }

  @computed
  get role() {
    return first(this.roles) || '';
  }

  @computed
  get speedString() {
    return stringFromSpeed(this.speed);
  }

  @computed
  get hasSpineRole() {
    return this.matchesRole(PORT_ROLES.SPINE);
  }

  @action
  release() {
    this.isTaken = false;
  }

  clone(noLinkToOrigin) {
    const clone = new Port(this.speed, this.roles);
    if (!noLinkToOrigin) clone.origin = this;
    return clone;
  }

  @action
  init(speed, roles) {
    this.speed = speed;
    this.roles = roles;
  }

  correspondsTo({speedString, roles}, freeOnly) {
    return this.speedString === speedString && this.matchesRole(roles[0], freeOnly);
  }

  matchesRole(role, freeOnly) {
    return (!freeOnly || !this.isTaken) && this.roles.includes(role);
  }

  matchesAnyOfRoles(roles) {
    if (isEmpty(roles)) return true;
    return size(intersection(roles, this.roles)) > 0;
  }

  constructor(speed, roles) {
    this.init(speed, roles);
    makeObservable(this);
  }

  static parseGroup({speed, roles, count}) {
    return map(range(count), () => new Port(speed, roles));
  }

  static generate(count, speed, role) {
    if (!count) return [];
    return Port.parseGroup({speed, count, roles: [role]});
  }
}

export default Port;
