import {isNumber, forEach, join, transform} from 'lodash';

import {END_TIME_FIELD, START_TIME_FIELD, CHART_TYPES, REPORT_TYPES} from './const';

export const handleTimeRange = (filters, useCombineTime, stringifyInterval) => {
  const result = {...filters};
  if (!useCombineTime) return result;
  const timeRange = filters[START_TIME_FIELD];
  if (isNumber(timeRange)) {
    if (stringifyInterval) {
      const days = Math.floor(timeRange / 86400);
      const seconds = timeRange % 86400;
      result[START_TIME_FIELD] = '-' + days + (seconds ? ':' + seconds : '');
    } else {
      result[START_TIME_FIELD] = -timeRange;
    }
    result[END_TIME_FIELD] = null;
  } else {
    const {start, end} = timeRange || {};
    if (start) result[START_TIME_FIELD] = start;
    if (end) result[END_TIME_FIELD] = end;
  }
  return result;
};

export const walkSections = (items, collapsedSectionState) => {
  forEach(items, (item) => {
    const isSection = (item.type === REPORT_TYPES.section);
    const isChart = (CHART_TYPES.has(item.type) && item.title);
    if (isSection || isChart) {
      collapsedSectionState[item.path] = true;
    }
    walkSections(item.items, collapsedSectionState);
  });
};

export const addPathToItem = (items, path = [], tocLevels = []) => {
  let tocCurrentLevel = 0;
  return transform(items, (result, item, i) => {
    const isSection = (item.type === REPORT_TYPES.section);
    const currentPath = [...path, i];
    const joinedPath = join(currentPath, '.');
    if (isSection) {
      tocCurrentLevel++;
      const levels = [...tocLevels, tocCurrentLevel];
      result.push({
        ...item,
        path: joinedPath,
        tocLevels: levels,
        items: addPathToItem(item.items, currentPath, levels),
      });
    } else {
      result.push({...item, path: joinedPath});
    }
  });
};

export const getProbeUrl = (blueprintId, probeId) => `/#/blueprints/${blueprintId}/analytics/probes/${probeId}`;
