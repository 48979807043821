import {Component, Fragment} from 'react';
import {Table, Form} from 'semantic-ui-react';
import {map, filter, isArray, isEmpty, transform, isPlainObject, forEach, compact, castArray} from 'lodash';
import {action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import isNumeric from 'is-number';
import {Field, ListInput, ValueInput, createValueRenderer} from 'apstra-ui-common';

import IBAContext from '../IBAContext';

import './EnvironmentExpectationsInput.less';

@observer
export default class EnvironmentExpectationsInput extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    value: [],
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get value() {
    const {value} = this.props;
    if (isEmpty(value)) return [{device_profile_label: 'default', power_supply_count: 2, fan_tray_count: 2}];
    return value;
  }

  @computed get deviceProfileLabelDropdownOptions() {
    return map(
      filter(
        this.context.deviceProfiles,
        ({os}) => os === 'Junos'
      ),
      ({label}) => ({const: label, title: label})
    );
  }

  @computed get errorsByItems() {
    return transform(this.props.errors, (result, error, itemIdx) => {
      if (isPlainObject(error)) {
        forEach(error, (entryError, key) => {
          if (isNumeric(key)) {
            result.byIndex[key] = entryError;
          } else {
            result.byIndex[itemIdx] = error;
          }
        });
      } else if (!isEmpty(error)) {
        result.generic.push(...castArray(error));
      }
    }, {byIndex: {}, generic: []});
  }

  @action
  onFieldChange = (index, field, value) => {
    const newValue = [...this.value];
    newValue[index][field] = value;
    this.props.onChange(newValue);
  };

  generateNewEntry = () => ({device_profile_label: null, power_supply_count: null, fan_tray_count: null});

  isItemRemovable = (item) => item.device_profile_label !== 'default';

  render() {
    const {name, schema, required, disabled, onChange, fieldProps} = this.props;
    const {value, errorsByItems} = this;
    return (
      <Fragment>
        <ListInput
          name={name}
          value={value}
          schema={schema}
          required={required}
          disabled={disabled}
          errors={errorsByItems.generic}
          onChange={onChange}
          generateNewEntry={this.generateNewEntry}
          isItemRemovable={this.isItemRemovable}
          minItems={1}
          buttonText='Add Environment Expectations'
          header={<EnvironmentExpectationsInputHeader />}
          fieldProps={fieldProps}
        >
          {({value, disabled, index}) =>
            <Fragment>
              <ValueInput
                value={value.device_profile_label}
                schema={{
                  oneOf: value.device_profile_label === 'default' ?
                    [{const: 'default', title: 'default'}]
                  :
                    this.deviceProfileLabelDropdownOptions
                }}
                disabled={
                  disabled ||
                  value.device_profile_label === 'default' ||
                  !this.deviceProfileLabelDropdownOptions.length
                }
                placeholder={
                  !this.deviceProfileLabelDropdownOptions.length ? 'No available device profiles' : undefined
                }
                fieldProps={{width: 7}}
                errors={compact(castArray(errorsByItems.byIndex[index]?.device_profile_label))}
                onChange={(inputValue) => this.onFieldChange(index, 'device_profile_label', inputValue)}
              />
              <ValueInput
                value={value.power_supply_count}
                placeholder='Power Supply Count'
                schema={{type: 'integer', minimum: 1}}
                disabled={disabled}
                fieldProps={{width: 4}}
                errors={compact(castArray(errorsByItems.byIndex[index]?.power_supply_count))}
                onChange={(inputValue) => this.onFieldChange(index, 'power_supply_count', inputValue)}
              />
              <ValueInput
                value={value.fan_tray_count}
                placeholder='Fan Tray Count'
                schema={{type: 'integer', minimum: 1}}
                disabled={disabled}
                fieldProps={{width: 4}}
                errors={compact(castArray(errorsByItems.byIndex[index]?.fan_tray_count))}
                onChange={(inputValue) => this.onFieldChange(index, 'fan_tray_count', inputValue)}
              />
            </Fragment>
          }
        </ListInput>
      </Fragment>
    );
  }
}

const EnvironmentExpectationsInputHeader = () => (
  <Field className='environment-expectations-input'>
    <Form.Group>
      <Field width={7} label='Device Profile Label' />
      <Field width={4} label='Power Supply Count' />
      <Field width={4} label='Fan Tray Count' />
    </Form.Group>
  </Field>
);

export const EnvironmentExpectations = ({value}) => (
  <Table basic='very' collapsing size='small' className='environment-expectations'>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>{'Device Profile Label'}</Table.HeaderCell>
        <Table.HeaderCell>{'Power Supply Count'}</Table.HeaderCell>
        <Table.HeaderCell>{'Fan Tray Count'}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {isEmpty(value) ?
        <Table.Row>
          <Table.Cell colSpan='3'>{'No items.'}</Table.Cell>
        </Table.Row>
      :
        map(
          value,
          ({
            device_profile_label: deviceProfileLabel,
            power_supply_count: powerSupplyCount,
            fan_tray_count: fanTrayCount,
          }, index) => (
            <Table.Row key={index}>
              <Table.Cell>{deviceProfileLabel}</Table.Cell>
              <Table.Cell>{powerSupplyCount}</Table.Cell>
              <Table.Cell>{fanTrayCount}</Table.Cell>
            </Table.Row>
          )
        )
      }
    </Table.Body>
  </Table>
);

export const environmentExpectationsRenderer = createValueRenderer({
  condition: ({name, value}) => name === 'expectation' && isArray(value),
  renderValue: (props) => <EnvironmentExpectations {...props} />,
  renderValueInput: (props) => <EnvironmentExpectationsInput {...props} />
});
