import {map} from 'lodash';

import {TOPOLOGY_CONFIG} from '../consts';

export const Interfaces = ({nodeInterfaces, showTooltipHandler, hideTooltip}) => {
  return (
    <>
      <defs>
        <rect
          id='interface-port'
          x={-TOPOLOGY_CONFIG.interfaceSize / 2}
          y={-TOPOLOGY_CONFIG.interfaceSize / 2}
          rx={1}
          width={TOPOLOGY_CONFIG.interfaceSize}
          height={TOPOLOGY_CONFIG.interfaceSize}
        />
      </defs>
      {map(nodeInterfaces, ({position, sourceInterface}, id) => <use
        key={id}
        className='topology-interface'
        href='#interface-port'
        fill={sourceInterface.operation_state === 'up' ? '#fff' : '#BABABA'}
        x={position.x}
        y={position.y}
        onMouseOut={hideTooltip}
        onBlur={hideTooltip}
        onMouseOver={showTooltipHandler?.(sourceInterface)}
        onFocus={showTooltipHandler?.(sourceInterface)}
      />)}
    </>
  );
};
