import {HashRouter} from 'react-router-dom';

import Dashboard from './Dashboard';
import IBAContext from '../IBAContext';
import {routes} from './IBA';

function DefaultDashboard({
  dashboard, probes,
  blueprintId, processorDefinitions, allActiveNodes, systemIdMap,
  blueprintPermissions, anomalyRemediationUrl, systemsHrefs
}) {
  return (
    <HashRouter>
      <IBAContext.Provider
        value={{
          blueprintId, allActiveNodes, systemIdMap, processorDefinitions,
          anomalyRemediationUrl, routes, blueprintPermissions, systemsHrefs
        }}
      >
        <Dashboard dashboard={dashboard} probes={probes} />
      </IBAContext.Provider>
    </HashRouter>
  );
}

export default DefaultDashboard;
