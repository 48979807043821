import {isObject, head, keys, get, includes, has} from 'lodash';
import {toJS} from 'mobx';
import isNumeric from 'is-number';

import {PROCESSOR_DYNAMIC_DATA_TYPES, PROCESSOR_STATIC_DATA_TYPES} from './consts';

export const timeInStateProcessorUtils = {
  getState: (processor, item) => {
    // state_range is always a map with one key
    const state = get(item, ['properties', 'state']);
    return state ? `"${state}"` : head(keys(processor.properties.state_range));
  },
  getMinMax: (processor, item) => {
    let min, max;
    const stateRange = toJS(processor.properties.state_range);
    if (!isObject(stateRange)) {
      return {min, max};
    }

    // state_range is always a map with one key
    const stateKey = head(keys(stateRange));
    const rangeMapValue = stateRange[stateKey];
    if (!Array.isArray(rangeMapValue) || !rangeMapValue.length) {
      return {min, max};
    }
    ({min, max} = rangeMapValue[0]);
    max = isNumeric(max) ? Number(max) : item.properties.range_max;
    min = isNumeric(min) ? Number(min) : item.properties.range_min;
    return {min, max};
  },
  getTimeWindow: (processor, item) => {
    let timeWindow = processor.properties.time_window;
    timeWindow = isNumeric(timeWindow) ?
      Number(timeWindow) :
      item.properties.time_window;
    return timeWindow;
  }
};

export const rangeProcessorUtils = {
  getMinMax: (processor, item) => {
    let min = get(processor, ['properties', 'range', 'min']);
    let max = get(processor, ['properties', 'range', 'max']);
    min = isNumeric(min) ? Number(min) : item.properties.range_min;
    max = isNumeric(max) ? Number(max) : item.properties.range_max;
    return {min, max};
  }
};

export function isHiddenProperty(propertyName, processor) {
  const dataType = get(processor, ['properties', 'data_type']);
  return ((propertyName === 'keys' && processor.type === 'extensible_data_collector' &&
      PROCESSOR_DYNAMIC_DATA_TYPES.has(dataType)) ||
    (propertyName === 'ingestion_filter' && processor.type === 'extensible_data_collector' &&
      PROCESSOR_STATIC_DATA_TYPES.has(dataType)));
}

export function isDisabledProperty(propertyName, processor, processorDefinition) {
  const hasEnableTelemetryService = has(processorDefinition, ['schema', 'properties', 'enable_telemetry_service']);
  if (hasEnableTelemetryService) {
    return includes(['service_input', 'execution_count', 'service_interval'], propertyName) &&
      !get(processor, ['properties', 'enable_telemetry_service']);
  }
  return false;
}
