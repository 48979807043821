import {useCallback, useMemo, useState} from 'react';
import {
  forEach, get, isEmpty, map, transform, filter, has, concat, castArray, assign, isUndefined, head, keys,
} from 'lodash';

import {
  START_TIME_FIELD, END_TIME_FIELD, PARAMETER_SCHEMA, PREDEFINED_PROBE_SCHEMA,
  timeDeltaPattern, NO_AVAILABLE_PREDEFINED_PROBES
} from './const';
import humanizeString from '../../../humanizeString';

export const isCombineTime = (schema) => {
  return (
    has(schema, [PARAMETER_SCHEMA, 'properties', START_TIME_FIELD]) &&
    has(schema, [PARAMETER_SCHEMA, 'properties', END_TIME_FIELD])
  );
};

export const getErrorsByPropertyName = (fetchDataError) => {
  const {response, responseBody} = fetchDataError;
  if (response && response.status === 422 && responseBody && responseBody.errors) {
    return transform(responseBody.errors, (result, propertyErrors, propertyName) => {
      result[propertyName] = transform(propertyErrors, (result, error, field) => {
        result[field] = castArray(error);
      }, {});
    }, {});
  }
  return {http: fetchDataError};
};

export const getDefaultValues = (reportSchema) => {
  const parametersProperties = getParametersProperties(reportSchema);
  const probeProperties = getPredefinedProbeProperties(reportSchema);
  const predefinedProbeIds = get(reportSchema, ['predefined_probes']);

  const properties = assign({}, parametersProperties, probeProperties);
  return transform(properties, (result, {default: defaultValue}, key) => {
    if (has(probeProperties, key) && !isEmpty(predefinedProbeIds[key])) {
      result[key] = head(keys(predefinedProbeIds[key]));
    } else if (!isUndefined(defaultValue)) {
      if (timeDeltaPattern.test(defaultValue)) {
        const groups = defaultValue.match(timeDeltaPattern);
        const days = Number(groups[2]);
        const seconds = Number(groups[4]) || 0;
        result[key] = days * 24 * 60 * 60 + seconds;
      } else {
        result[key] = defaultValue;
      }
    }
  }, {});
};

const getParametersProperties = (schema) =>
  get(schema, [PARAMETER_SCHEMA, 'properties'], {});
const getPredefinedProbeProperties = (schema) =>
  get(schema, [PREDEFINED_PROBE_SCHEMA, 'properties'], {});

const COMBINE_TIME_TITLE = 'Time Interval';

export function useReportForm(schema, initialValues = {}) {
  const {parametersFormSchema, probeFormSchema} = useMemo(() => {
    const transformSchema = (schema) => {
      const requiredFields = new Set(schema?.required);
      return map(get(schema, ['properties'], {}), (schema, name) => ({
        name,
        schema: {...schema, title: schema.title || humanizeString(name)},
        required: requiredFields.has(name),
      }));
    };

    const parametersSchema = get(schema, [PARAMETER_SCHEMA], {});
    const parametersFormSchema = transformSchema(parametersSchema);
    const probeSchema = get(schema, [PREDEFINED_PROBE_SCHEMA], {});
    const probeFormSchema = transformSchema(probeSchema);

    let beginTimeIndex = -1;
    let endTimeIndex = -1;
    forEach(parametersFormSchema, ({name}, i) => {
      if (name === START_TIME_FIELD) beginTimeIndex = i;
      if (name === END_TIME_FIELD) endTimeIndex = i;
    });
    if (beginTimeIndex >= 0 && endTimeIndex >= 0) {
      parametersFormSchema[beginTimeIndex].schema = {
        ...parametersFormSchema[beginTimeIndex].schema,
        title: COMBINE_TIME_TITLE,
      };
      return {
        parametersFormSchema: filter(parametersFormSchema, ({name}) => name !== END_TIME_FIELD),
        probeFormSchema,
      };
    }

    return {parametersFormSchema, probeFormSchema};
  }, [schema]);
  const useCombineTime = useMemo(() => {
    return isCombineTime(schema);
  }, [schema]);
  const [values, setValues] = useState({...initialValues});
  const [errors, setErrors] = useState({});

  const onReset = () => {
    setValues({});
    setErrors({});
  };

  const onValidate = () => {
    const probeSchemaProperties = getPredefinedProbeProperties(schema);
    const predefinedProbeIds = get(schema, ['predefined_probes']);
    const errors = transform(concat(parametersFormSchema, probeFormSchema), (result, {name, required}) => {
      const isProbeSchemaProperty = has(probeSchemaProperties, name);
      const schemaName = isProbeSchemaProperty ? 'predefined_probes' : 'parameters';
      result[schemaName] = result[schemaName] || {};
      if (required && !values[name]) {
        result[schemaName][name] = ['This field is required'];
      }
      if (isProbeSchemaProperty && isEmpty(predefinedProbeIds[name])) {
        result[schemaName][name] =
          [NO_AVAILABLE_PREDEFINED_PROBES];
      }
    }, {predefined_probes: {}, parameters: {}});
    if (!isEmpty(errors.predefined_probes) || !isEmpty(errors.parameters)) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  const onChange = (name, value) => {
    setErrors({});
    setValues({...values, [name]: value});
  };

  const onUpdate = useCallback((values) => {
    setErrors({});
    setValues(values);
  }, [setErrors, setValues]);

  const onError = useCallback((fetchDataError) => {
    setErrors(getErrorsByPropertyName(fetchDataError));
  }, [setErrors]);

  return {
    values, errors, parametersFormSchema, probeFormSchema, useCombineTime,
    onChange, onReset, onValidate, onUpdate, onError,
  };
}
